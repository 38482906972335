
import React, { useState, useMemo, useEffect } from "react";
import { Form } from 'antd';
import snsWebSdk from '@sumsub/websdk';
import CustomInput from 'components/onboarding/formComponents/CustomInput';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { countryCodes } from "utils/appData/countryCodes";
import moment from 'moment';
import useToolkit from "utils/hooks/misc/useToolkit";
import AddDirectorShareHolder from './AddDirectorShareHolder';
import useLocation from "utils/hooks/dashboard/useLocation";
import useOnboarding from "utils/hooks/onboarding/useOnboarding";
import DocumentList from "./DocumentList";
import SumSubInitModal from "components/auth/SumSubInitModal";
const SummaryWrapper = ({
  setSwitchTab,
  switchPayload,
  title,
  switchTab,
  addOpen,
  setAddOpen,
}: any) => {
  // Assuming switchPayload contains user data

  const { useAppSelector, dispatch, toastInfo, userEmail, axiosInstance } = useToolkit();
  const [isKycModalOpen, setIsKycModalOpen] = useState<boolean>(false)
  const accessToken = 'sbx:v8tY0wvqpZhKqERazDd5msLN.YR46ErgwHqpwo44TZJV8Wz4IHQzlGuNK'
  const [form] = Form.useForm();
  const { capitalizeFirstLetters, countryCodeFromName } = useOnboarding('personal')

  const {
    ctrLoading,
    ctrData,
    getDynCountries,
    getDynStates,
    statesLoading,
    stateData,
    getDynCities,
    citiesData,
    citiesLoading,
  } = useLocation();


  let mode: string;

  switch (switchTab) {
    case 0:
      mode = "identity";
      break;
    case 1:
      mode = "address";
      break;
    case 2:
      mode = "verification";
      break;
    default:
      mode = "";
      break;
  }

  const getNewAccessToken = async (userEmail: string, mode: string, metadata: any) => {
    try {
      const response = await axiosInstance.post(`sumsub/sdk-token/${userEmail}`, {
        mode,
        metadata,
      });

      const { token } = response.data.content.data;
      return token;
    } catch (error) {
      console.error("Failed to fetch new access token:", error);
      return null;
    }
  };


  function launchWebSdk(accessToken: string, userEmail: string, mode: string, metadata: any) {
    let snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => getNewAccessToken(userEmail, mode, metadata))
      .withConf({
        lang: "en",
        email: userEmail,
        uiConf: {
          customCss: 'https://url.com/styles.css'
        }
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on('idCheck.stepCompleted', (payload) => {
        // listen for 'Application completed'
        // listen for applicantReviewed
      })
      .on("idCheck.onError", (error) => {
      })

      .onMessage((type, payload) => {
      })
      .build();
    snsWebSdkInstance.launch("#sumsub-websdk-container");
  }




  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [selectedState, setSelectedState] = useState<any>();
  useEffect(() => {
    if (switchPayload) {
      const initialValues = {
        first_name: switchPayload?.First_name,
        last_name: switchPayload?.Last_name,
        dob: moment(switchPayload?.Date_of_birth, 'DD-MM-YYYY'),
        email_address: switchPayload?.Email_address,
        nationality: switchPayload?.Nationality,
        employment: switchPayload?.Employment,
        phone_number: switchPayload?.Phone_number,
        country_of_residence: switchPayload?.Country_of_residence,
        address: switchPayload?.Address,
        state: switchPayload?.State,
        city: switchPayload?.City,
        postal_code: switchPayload?.Postal_code,
        type_of_ID: switchPayload?.Type_of_ID,
        identity_no: switchPayload?.Identity_no,
        id_issuing_country: switchPayload?.Issuing_country,
        iD_issue_date: moment(switchPayload?.ID_issue_date, 'DD-MM-YYYY'),
        iD_expiry_date: moment(switchPayload?.ID_expiry_date, 'DD-MM-YYYY'),
        business_registration_number: switchPayload?.Business_reg_num,
        tax_identification_number: switchPayload?.Tax_number,
        Country_of_registration: switchPayload?.Country_of_registration,
        tax_issuing_country: switchPayload?.Tax_issuing_country,
        industry_Sector: switchPayload?.Industry_sector,
        trade_Business: switchPayload?.Business_Name,
        Business_type: switchPayload?.Business_type,
      };
      form.setFieldsValue(initialValues);
    }
  }, [switchPayload, form]);

  const employmentOptions: any = [
    { value: 'employed', title: 'Employed' },
    { value: 'unemployed', title: 'Unemployed' },
  ];

  const handleFormSubmit = (values: any) => {
    setIsKycModalOpen(true)
    const metadata = [
      { firstName: values?.first_name },
      { lastName: values?.last_name },
      { dob: values?.dob },
      { nationality: values?.nationality },
      { employmentStatus: values?.employment },
      { house_number: "6" },
      { address: values?.address },
      { city: values?.city },
      { state: values?.state },
      { zipcode: values?.postal_code },
      { country: values?.country_of_residence },
      { countryCode: "NG" }
    ]
    setTimeout(() => {
      launchWebSdk(accessToken, userEmail, mode, metadata);
    }, 1500);

  };

  const handleButtonClick = () => {
    form.submit();
  };
  const nationalityOptions = useMemo(() => countryCodes.map(nationality => ({
    title: nationality.nationality,
    value: nationality.nationality,
    Id: nationality.code as any,
  })), []);
  const newCountryData: DropdownProps[] = useMemo(() => {
    return ctrData.map(({ title }) => {
      return {
        title: capitalizeFirstLetters(title as string),
        value: capitalizeFirstLetters(title as string)
      }

    })
  }, [ctrData])
  useEffect(() => {
    getDynCountries();

  }, []);


  const payload = { country: selectedCountry, state: selectedState };



  useEffect(() => {

    getDynStates(countryCodeFromName(selectedCountry));
  }, [selectedCountry]);
  const newStateData = useMemo(() => {
    if (stateData) {
      return stateData.map((item) => {
        return {
          title: item.title,
          value: item.title
        }
      }) as DropdownProps[]
    }
  }, [stateData, selectedCountry])


  useEffect(() => {

    const payload = { country: selectedCountry, state: selectedState };

    getDynCities(payload);
  }, [selectedState]);
  const handleCountryChange = (e: any) => {
    setSelectedCountry(e)
    const updatePayload: Record<string, string | undefined> = {};
    if (form.getFieldValue('state')) {
      let message = "Select a new State";
      updatePayload.state = undefined;
      if (form.getFieldValue('city')) {
        updatePayload.city = undefined;
        message = 'Select a new State and City';
      }
      form.setFieldsValue(updatePayload);
      toastInfo(message);
    }

  }

  const handleSelectedState = (e: any) => {
    setSelectedState(e)
    if (form.getFieldValue('city')) {
      form.setFieldsValue({
        city: undefined
      });
      toastInfo('Select a new City');
    }
  }



  const getDocumentListProps = (tab: number) => {
    switch (tab) {
      case 0:
        return {
          note: "NOTE",
          documents: ['Valid ID - International Passport, NIN, or Driver’s License'],
          buttonText: "Continue upload document ",
          docsItems: "The documents you’ll be providing are;",
        };
      case 1:
        return {
          note: "NOTE",
          documents: ['Proof of address ( utility bill, bank statement (with current address)'],
          buttonText: "Continue upload document",
          docsItems: "The documents you’ll be providing are;",
        };
      case 2:
        return {
          note: "NOTE: For Nigerians",
          documents: ['Valid ID - International Passport, NIN, or Driver’s License', 'NIN slip (National Identity Number)', 'Voters card'],
          buttonText: "Continue upload document",
          docsItems: "Any of this documents are accepted",
        };
      case 3:
        return {
          note: "NOTE: for Tab 3",
          documents: ['Doc 1 for Tab 3', 'Doc 2 for Tab 3'],
          buttonText: "Continue for Tab 3",
          docsItems: "docsItems3",
        };
      default:
        return {
          note: "NOTE: for Nigerians",
          documents: ['Certificate of Incorporation', 'Memorandum and articles of association/document that show the objects of the company', 'Proof of Business address', 'Proof of ID of directors & shareholders', 'Proof of address directors & shareholders', 'Proof of ID and address of appointed representative'],
          buttonText: "Continue upload document",
          docsItems: "defaultDocsItems",
        };
    }
  };

  const documentListProps = getDocumentListProps(switchTab);

  return (
    <div className="bg-white rounded-xl p-8">
      <div
        onClick={() => {
          setSwitchTab(null);
          setAddOpen(false);
        }}
        className="text-primary cursor-pointer flex gap-1 items-center"
      >
        <HiOutlineArrowLeft /> Back
      </div>

      {addOpen ? (
        <>
          <AddDirectorShareHolder />
        </>
      ) : (
        <>
          <p className="font-semibold text-[16px] my-[2rem]">Edit {title}</p>
          <Form
            form={form}
            className="onb-form-wrapper"
            onFinish={handleFormSubmit}
          >
            {switchTab === 0 && (
              <div>
                <div className="onb-form-item my-6">
                  <CustomInput label="First name" name="first_name" />
                  <CustomInput label="Last name" name="last_name" />
                </div>

                <div className="onb-form-item my-6">
                  <CustomInput
                    label="Email address"
                    name="email_address"
                    isEmailValidated
                    nonEditable
                  />
                  <CustomInput
                    label="Date of birth"
                    isDob
                    isDatePicker
                    name="dob"
                  />
                </div>
                <div className="onb-form-item my-6">
                  <CustomInput name='nationality' label='Nationality' isSelector
                    options={nationalityOptions} />   <CustomInput
                    isSelector
                    label="Employment"
                    name="employment"
                    options={employmentOptions}
                  />
                </div>
              </div>
            )}
            {switchTab === 1 && (
              <div>
                <div className="onb-form-item my-6">
                  <CustomInput name="phone_number" label="Phone Number" />
                  <CustomInput
                    isSelector
                    required
                    label="Country of Residence"
                    name="country_of_residence"
                    options={newCountryData}
                    placeholder={ctrLoading ? "Fetching...." : ""}
                    onChange={handleCountryChange}
                  />
                </div>
                <div className="onb-form-item my-6">
                  <CustomInput name="address" label="Address" />
                  <CustomInput
                    isSelector={!statesLoading && !!newStateData?.length && Array.isArray(newStateData)}
                    label="State"
                    name="state"
                    // required
                    options={newStateData}
                    placeholder={statesLoading ? "Fetching...." : ""}
                    onChange={(e) => handleSelectedState(e)}
                    nonEditableText="select a resident country first"
                  // nonEditable={selectedCountry}
                  // nonEditable={selectedCountry? false: editData?.nationality ? false: true}

                  />   </div>
                <div className="onb-form-item my-6">
                  <CustomInput
                    isSelector={(!citiesLoading && Array.isArray(citiesData) && !!citiesData?.length) && !!newStateData?.length}
                    label='City'
                    name='city'
                    // nonEditable={!!!selectedState && !!!editData?.state}
                    // nonEditableText="select a state first"
                    // required
                    placeholder={citiesLoading ? "Fetching..." : ""}
                    loading={citiesLoading}
                    options={citiesData}
                  />    <CustomInput name="postal_code" label="Postal code" />
                </div>
              </div>
            )}
            {switchTab === 2 && (
              <div>
                <div className="onb-form-item my-6">
                  <CustomInput name="type_of_ID" label="Type of ID" />
                  <CustomInput name="identity_no" label="Identity no" />
                </div>
                <div className="onb-form-item my-6">
                  <CustomInput
                    isSelector
                    label="ID-issuing country"
                    name="id_issuing_country"
                    options={ctrData}

                  />      <CustomInput
                    label="ID issue date"
                    isDob
                    isDatePicker
                    name="iD_issue_date"
                  />
                </div>
                <div className="onb-form-item my-6">
                  <CustomInput
                    label="ID expiry date"
                    isDob
                    isDatePicker
                    name="iD_expiry_date"
                  />
                </div>
              </div>
            )}
            {switchTab === 3 && (
              <div>
                <div className="onb-form-item my-6">
                  <CustomInput
                    name="trade_Business"
                    label="Trade/Business name"
                  />

                  <CustomInput
                    name="business_registration_number"
                    label="Business registration number"
                  />
                </div>
                <div className="onb-form-item my-6">
                  <CustomInput
                    name="tax_identification_number"
                    label="Tax identification number"
                  />
                  <CustomInput
                    isSelector
                    label="Country of registration"
                    name="Country_of_registration"
                    options={newCountryData}
                  // options={employmentOptions}
                  />
                </div>
                <div className="onb-form-item my-6">
                  <CustomInput
                    isSelector
                    label="Tax issuing country"
                    name="tax_issuing_country"
                    options={ctrData}
                  // options={employmentOptions}
                  />
                  <CustomInput name="Business_type" label="Business Type" />
                </div>
                <div className="onb-form-item my-6">
                  <CustomInput name="industry_Sector" label="Industry/Sector" />
                </div>
              </div>
            )}
          </Form>
        </>
      )}

      <DocumentList
        note={documentListProps.note}
        documents={documentListProps.documents}
        buttonText={documentListProps.buttonText}
        onButtonClick={handleButtonClick}
        docsItems={documentListProps.docsItems}
      />

      <SumSubInitModal
        control={isKycModalOpen}
        onClose={() => setIsKycModalOpen(!isKycModalOpen)}
      />
    </div>
  );
};

export default SummaryWrapper;