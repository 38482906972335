const radioOptions : DropdownProps[] = [
    {value: 'director', title: 'Director'},
    {value: 'shareholder', title: 'Shareholder'},
    {value: 'both', title: 'Both'},
    {value: 'no', title: 'None'}
]

const employmentStatus : DropdownProps[] = [
    {value: 1, title: 'Employed'},
    {value: 2, title: 'Unemployed'},
    {value: 3, title: 'Self-employed'},
    {value: 4, title: 'Student'},
]

const pepOptions : DropdownProps[] = [
    {value: 'yes', title: 'Yes'},
    {value: 'no', title: 'No'},

]
const areYouA : DropdownProps[] = [
    {value: 'yes', title: 'Yes'},
    {value: 'no' , title: 'No'},

]
interface BtypevalueProp {
    SOLE_PROPRIETOR: string;
    CORPORATION: string;
    PARTNERSHIP: string;
    COORPERATIVE_SOCIETY: string;
    PUBLIC_LLC: string;
    PRIVATE_LLC : string;
    LLC : string;
    FREE_TRADE : string;
    NG0 : string;
}
export const businessTypeValues : BtypevalueProp = {
    SOLE_PROPRIETOR : '0',
    CORPORATION :'1',
    PARTNERSHIP : '2',
    COORPERATIVE_SOCIETY : '3',
    PUBLIC_LLC : '4',
    PRIVATE_LLC : '5',
    LLC : '6',
    FREE_TRADE : '7',
    NG0 : '8'

}
// Sole proprietorship 
// Public limited liability 
// Limited Liability
const businessTypes = [
    { title: "Sole Proprietorship", value: businessTypeValues.SOLE_PROPRIETOR },
    { title: "Corporation", value: businessTypeValues.CORPORATION },
    { title: "Partnership", value: businessTypeValues.PARTNERSHIP },
    { title: "Cooperative Society", value: businessTypeValues.COORPERATIVE_SOCIETY },
    { title: "Public Limited Liability Company", value: businessTypeValues.PUBLIC_LLC },
    { title: "Private Limited Liability Company", value: businessTypeValues.PRIVATE_LLC },
    { title: "Limited Liability Company", value: businessTypeValues.LLC },
    { title: "Free Trade Zone Company", value: businessTypeValues.FREE_TRADE },
    {
        title: "Incorporated trustee / Non-governmental organisation",
        value: businessTypeValues.NG0,
    },
];


const partnerDocs = [
    `Certificate of Incorporation`,
    `Memorandum and articles of association/document that show the objects of the company`,
    `Proof of Business address`,
    `Proof of ID of partners`,
    `Proof of address of partners`,
    `Proof of ID and address of appointed representative`
]

const soleProtrietorDocs = [
    `Certificate of registration`,
    `Proof of address of business`,
    `Proof of address of sole proprietor`,
    `Proof of ID for Sole proprietor`
]

const coorperativeDocs = [
    `Certificate of Incorporation`,
    `Memorandum and articles of association/document that show the objects of the company`,
    `Proof of cooperative address`,
    `Proof of ID of members`,
    `Proof of address of members`,
    `Proof of ID and address of appointed representative`
]

const publicDocs = [
    `Certificate of Incorporation`,
    `Memorandum and articles of association/document that show the objects of the company`,
    `Proof of business address`,
    `Proof of ID of directors and shareholders`,
    `Proof of address of directors and shareholders`,
    `Proof of ID and address of appointed representative`,
    `Statement of share capital`,
    `Any applicable licenses`
]

const ngoDocs = [
    `Certificate of Incorporation`,
    `Memorandum and articles of association/document that show the objects of the company`,
    `Proof of Business address`,
    `Proof of ID of trustees`,
    `Proof of address of trustees`,
    `Proof of ID and address of appointed representative`,
    `SCUML certificate`
]



const industries: DropdownProps[] = [
    {title: 'Agriculture' , value: 'Agriculture'},
    {title: 'Arts, Entertainment and Recreation' , value: 'Arts-Entertainment-Recreation'},
    {title: 'Education' , value: 'Education'},
    {title: 'Financial Services' , value: 'Financial-Services'},
    {title: 'Healthcare ' , value: 'Healthcare'},
    {title: 'Hospitality' , value: 'Hospitality'},
    {title: 'Media' , value: 'Media'},
    {title: 'Textiles' , value: 'Textiles'},
    {title: 'Transport' , value: 'Transport'},
    {title: 'Utilities' , value: 'Utilities'},
    {title: 'Other' , value: 'Other'},
    
]
const pepText = `A politically exposed person (PEP) is someone
    who has been appointed by a community institution, 
    an international body or a state to a high-profile
     position within the last 12 months.`


export {
    radioOptions,
    employmentStatus,
    pepOptions,
    businessTypes,
    partnerDocs,
    soleProtrietorDocs,
    coorperativeDocs,
    publicDocs,
    ngoDocs,
    industries,
    pepText,
    areYouA
}