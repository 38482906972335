import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface virtualsState {
	isInitVacOpen: boolean;
	isCardDetailsShown: boolean;
	amountConverted: string;
	conversionValue: number;
	virtualCards: VirtualCard[] | [];
	selectedVCard: VirtualCard | null;
	cardInfo: CardInfoProps;
	isFundCardModalOpen: boolean;
	isDisableCardModalOpen: boolean;
	isCardWdlModalOpen: boolean;
	isCardSecModalOpen: boolean;
	isCurrencyDrwOpen: boolean;
}

const initialState: virtualsState = {
	isInitVacOpen: false,
	amountConverted: "",
	conversionValue: 0,
	virtualCards: [],
	selectedVCard: null,
	cardInfo: {
		cardDetails: null,
		cardTransactions: null,
	},
	isCardDetailsShown: false,
	isFundCardModalOpen: false,
	isDisableCardModalOpen: false,
	isCardWdlModalOpen: false,
	isCardSecModalOpen: false,
	isCurrencyDrwOpen: false,
};

const virtualsSlice = createSlice({
	name: "virtuals",
	initialState,
	reducers: {
		toggleInitVac: (state) => {
			return {
				...state,
				isInitVacOpen: !state.isInitVacOpen,
			};
		},

		toggleCurrencyDrawer: (state) => {
			return {
				...state,
				isCurrencyDrwOpen: !state.isCurrencyDrwOpen,
			};
		},

		closeCurrencyDrawer: (state) => {
			return {
				...state,
				isCurrencyDrwOpen: false,
			};
		},

		toggleCardDetails: (state) => {
			return {
				...state,
				isCardDetailsShown: !state.isCardDetailsShown,
			};
		},

		toggleFundCardModal: (state) => {
			return {
				...state,
				isFundCardModalOpen: !state.isFundCardModalOpen,
			};
		},

		toggleCardSecModal: (state) => {
			return {
				...state,
				isCardSecModalOpen: !state.isCardSecModalOpen,
			};
		},

		toggleDisableCardModal: (state) => {
			return {
				...state,
				isDisableCardModalOpen: !state.isDisableCardModalOpen,
			};
		},

		toggleCardWdlModal: (state) => {
			return {
				...state,
				isCardWdlModalOpen: !state.isCardWdlModalOpen,
			};
		},

		setVirtualCard: (state, { payload }: PayloadAction<VirtualCard>) => {
			return {
				...state,
				selectedVCard: payload,
			};
		},

		setCardInfo: (state, { payload }: PayloadAction<CardInfoProps>) => {
			return {
				...state,
				cardInfo: payload,
			};
		},
	},
});

export const {
	toggleInitVac,
	setVirtualCard,
	toggleCardDetails,
	toggleCardWdlModal,
	toggleFundCardModal,
	toggleDisableCardModal,
	toggleCardSecModal,
	toggleCurrencyDrawer,
	closeCurrencyDrawer,
	setCardInfo,
} = virtualsSlice.actions;

export default virtualsSlice.reducer;
