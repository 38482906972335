import { Result } from "antd";
import { motion } from "framer-motion";
import React from "react";

type Props = {
	status: "success" | "error" | "info";
	title: string;
	subtitle?: string;
};

const Feedback = ({ status, title, subtitle }: Props) => {
	return (
		<motion.div
			initial={{ opacity: 0, x: "-100", y: "100" }}
			whileInView={{
				opacity: 1,
				x: 0,
				y: 0,
				transition: {
					type: "spring",
					stiffness: 120,
					duration: 0.3,
				},
			}}
			viewport={{ once: true }}
			className="flexed py-5">
			<Result
				status={status}
				title={
					<span className="t6 text-green-500 med lg:text-xl">{title}!</span>
				}
			/>
		</motion.div>
	);
};

export default Feedback;
