import {
	useSendFunds,
	useToolkit,
	useBeneficiary,
	Spinner,
	AddBankDetailsForm,
} from "../../components";
import { Modal } from "antd";
import { toggleCreateBankModal } from "redux/slices/settingsSlice";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";

type Props = {};

const AddBankDetailsModal = ({}: Props) => {
	const {
		useState,
		useEffect,
		useRef,
		isMobile,
		useAppSelector,
		dispatch,
		clearFlag,
		router,
	} = useToolkit();

	const {
		useBankParams,
		useAddBank,
		paramsData,
		setParamsData,
		getNgnBanks,
		verifyData,
		accountNameFound,
		bankNameFound,
	} = useBeneficiary();

	const { mutate: getBankParams } = useBankParams();

	const { isLoading: addLoading, mutate: addBank } = useAddBank();
	const { isCreateBankModalOpen } = useAppSelector((state) => state.settings);
	const { isWithdrawalModalOpen } = useAppSelector((state) => state.dashboard);

	const [rcvCountry, setRcvCountry] = useState("");
	const [rcvCurrency, setRcvCurrency] = useState("");

	const { getAllRecipientCountries } = useSendFunds();

	const submitRef = useRef<HTMLButtonElement>(null);
	const handleModalClose = () => dispatch(toggleCreateBankModal());

	useEffect(() => {
		getAllRecipientCountries();
		getNgnBanks();
		clearFlag();
	}, []);

	useEffect(() => {
		if (accountNameFound) {
			const newParams = paramsData?.filter(
				(item: any) => item?.fieldName !== "accountName"
			);
			setParamsData(newParams);
		}

		if (bankNameFound) {
			const newParams = paramsData?.filter(
				(item: any) => item?.fieldName !== "bankName"
			);
			setParamsData(newParams);
		}
	}, [verifyData.current]);

	useEffect(() => {
		if (rcvCountry?.length && rcvCurrency?.length) {
			const payload = {
				cur: rcvCurrency,
				country: rcvCountry,
			};
			getBankParams(payload);
		}
	}, [rcvCountry, rcvCurrency]);

	return (
		<Modal
			footer={null}
			title="Add New Bank Account"
			centered
			visible={isCreateBankModalOpen}
			onOk={() => submitRef?.current?.click()}
			onCancel={handleModalClose}
			okText={<span className="px-3">Add Bank Account</span>}
			cancelText="Cancel"
			closeIcon={<div></div>}
			className="my-10"
			width={isMobile ? "100%" : "80%"}>
			{addLoading ? <Spinner msg="Processing" /> : <AddBankDetailsForm />}
		</Modal>
	);
};

export default AddBankDetailsModal;
