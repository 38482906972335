import { Skeleton } from "antd";
import useUser from "utils/hooks/settings/useUser";
import { QwidButton, Spinner, useFileUploader, useToolkit } from "components";

import { BiUserCircle } from "react-icons/bi";
import { toggleBusinessUpdateModal } from "redux/slices/settingsSlice";

const BusinessTab = () => {
	const { dispatch, useEffect, user } = useToolkit();
	const { detailsLoading, picLoading, updateProfilePic, getUserDetails } =
		useUser();

	const {
		handleFileChange,
		profilePic,
		fileRef,
		onClick,
		fileProps,
		btnActive,
		setBtnActive,
	} = useFileUploader("prop");

	const updatePic = () => {
		const payload = {
			pix: fileProps.string,
			extension: fileProps.extension,
		};
		updateProfilePic(payload);
	};

	const ProfilePicContainer = () => (
		<div>
			<input
				ref={fileRef}
				style={{ display: "none" }}
				type="file"
				onChange={handleFileChange}
			/>
			{user?.enduser && profilePic ? (
				<div onClick={onClick} className="flexed h-20 w-20 rounded-full curp">
					<img
						src={profilePic}
						className="object-cover min-h-full rounded-full"
						alt="profile-pic"
					/>
				</div>
			) : (
				<div
					onClick={onClick}
					className="bg-[#ECE9E9] flexed h-20 w-20 rounded-full curp">
					<BiUserCircle className="text-3xl" />
				</div>
			)}

			<div className="flexed mt-6">
				<QwidButton
					onClick={updatePic}
					disabled={!btnActive}
					text={<span className="px-2">Save</span>}
				/>
			</div>
		</div>
	);

	useEffect(() => {
		getUserDetails();
	}, []);

	return detailsLoading ? (
		<Skeleton />
	) : picLoading ? (
		<Spinner msg="Saving image" />
	) : (
		<div>
			Business Tab
			<QwidButton
				onClick={() => dispatch(toggleBusinessUpdateModal())}
				darkBlueBg
				text={<span className="px-3 lg:t6">Edit Business Info</span>}
			/>
		</div>
	);
};

export default BusinessTab;
