import {
	completeBusinessDetailsUpdate,
	completePersonalProfileUpdate,
	showProfileCancelBtn,
} from "redux/slices/settingsSlice";
import { setPlainUser, setUserDetails } from "redux/slices/userSlice";
import { DASHBOARD_ROUTES, ONBOARDING_ROUTES } from "utils/appData/appRoutes";
import useToolkit from "../misc/useToolkit";
import useAuth from "../auth/useAuth";
import { AxiosError } from "axios";

const useUser = () => {
	const {
		router,
		dispatch,
		useState,
		axiosInstance,
		userId,
		toastSuccess,
		useAppSelector,
		isBusinessAccount,
		userObj,
		useMutation,
		handleRequestError,
		toastError
	} = useToolkit();
	const { setupFCM } = useAuth();
	const [detailsLoading, setDetailsLoading] = useState(false);
	const { isProfileCancelBtnShown: cancelShown } = useAppSelector(
		(state) => state.settings
	);

	const allowReroute = router.pathname?.includes("user");
	const userEmail = userObj?.email;

	const beginUserVerification = () => router.push(ONBOARDING_ROUTES.KYC);

	const getUserDetails = async () => {
		setDetailsLoading(true);
		try {
			const { data } = await axiosInstance.get(`endUsers/${userId}`);
			await setupFCM(data?.content?.data?.[0]?.userId);
			dispatch(setPlainUser(data?.content?.data[0]));
			setDetailsLoading(false);
			return data?.content?.data[0];
		} catch (error) {
			handleRequestError(error);
			setDetailsLoading(false);
			return error;
		}
	};

	const [updLoading, setUpdLoading] = useState(false);
	const updateMessage = "Details updated successfully";

	const updateFirstTimeUserDetails = async (payload: any) => {
		setUpdLoading(true);
		try {
			dispatch(completeBusinessDetailsUpdate());
			await axiosInstance.patch(`endUsers/profile/${userEmail}`, payload);
			toastSuccess(updateMessage);
			beginUserVerification();
			setUpdLoading(false);
		} catch (error) {
			handleRequestError(error);
			setUpdLoading(false);
			return error;
		}
	};

	const updateFirstTimePersonalDetails = async (payload: any) => {
		setUpdLoading(true);
		try {
			await axiosInstance.patch(`endUsers/profile/${userEmail}`, payload);
			dispatch(completePersonalProfileUpdate());
			toastSuccess(updateMessage);
			beginUserVerification();
			setUpdLoading(false);
		} catch (error) {
			handleRequestError(error);
			setUpdLoading(false);
			return error;
		}
	};

	const updateUserDetails = async (payload: any, custom?: string) => {
		setUpdLoading(true);
		try {
			dispatch(completePersonalProfileUpdate());
			const {
				data: {
					content: { data },
				},
			} = await axiosInstance.patch(`endUsers/profile/${userEmail}`, payload);
			toastSuccess(updateMessage);
			const response = data[0];
			dispatch(setPlainUser(response));
			if (cancelShown === false) {
				dispatch(showProfileCancelBtn());
			}
			if (allowReroute) {
				setTimeout(() => {
					router.push(DASHBOARD_ROUTES.DASHBOARD);
				}, 2000);
			}
			setUpdLoading(false);
		} catch (error) {
			handleRequestError(error);
			setUpdLoading(false);
			return error;
		}
	};

	// updating user's profile pic
	const [picLoading, setPicLoading] = useState(false);
	const updateProfilePic = async (values: any) => {
		setPicLoading(true);
		try {
			await axiosInstance.patch(`endUsers/pix/${userId}`, values);
			toastSuccess(
				"Profile picture updated. Details will reflect on next sign in"
			);
			setPicLoading(false);
		} catch (error) {
			handleRequestError(error);
			setPicLoading(false);
			return error;
		}
	};

	// add director / shareholder
	const updateDirInfoFn = async (payload: any) => {
		try {
			const {
				data: {
					content: { data },
				},
			} = await axiosInstance.post(`director-info/${userId}`, payload);
			toastSuccess("Details updated successfully");
			if (payload?.mode === "director") {
				//dispatch(setDirectorsInfo(data));
			} else {
				// dispatch(setShareholderInfo(data));
			}
		} catch (error) { }
	};
	const useSaveDirInfo = () => useMutation(updateDirInfoFn);

	// update business director / shareholder info
	const getDirInfoFn = async (mode: string) => {
		const isDirector = mode === "director";
		const isShareholder = mode === "shareholder";

		try {
			const {
				data: {
					content: { data },
				},
			} = await axiosInstance.get(`director-info/${userId}?mode=${mode}`);
			if (isDirector) {
				// dispatch(setDirectorsInfo(data));
			} else if (isShareholder && data?.length > 0) {
				// dispatch(setShareholderInfo(data[0]));
			}
		} catch (error) { }
	};
	const useDirInfo = () => useMutation(getDirInfoFn);

	// update business director / shareholder info
	const [isIdValidated, setIsIdValidated] = useState<any>(null);
	const [verifyError, setVerifyError] = useState(null);

	const verifyTierId = async (payload: any) => {
		setIsIdValidated(null);
		setVerifyError(null);

		try {
			const data = await axiosInstance.post('tier-kyc/level-one', payload);
			setIsIdValidated(true);
			return data?.data;
		} catch (error: any) {
			const errText = error?.response?.data?.message

			if (errText?.includes('You are already on Tier')) {
				toastError(errText)
			} else {
				if (error && errText?.length > 0) {
					setIsIdValidated(false);
					setVerifyError(errText)
				}
			}
		}
	};
	const useVerifyTierId = () => useMutation(verifyTierId);

	const getTierIdentityFormsFn = async () => {

		try {
			const data = await axiosInstance.post('verification-tool/form', {
				country: userObj?.countryCode,
				userType: isBusinessAccount ? 2 : 1
			});
			const response = data?.data?.content?.data?.data;

			return response;
		} catch (error: any) {
			handleRequestError(error)
		}
	};
	const useTierIdForms = () => useMutation(getTierIdentityFormsFn);

	// initializing seamfix photo verification
	const [photoIdLoading, setPhotoIdLoading] = useState(false);
	const [photoidData, setPhotoidData] = useState(null);

	const getSdkToken = async () => {
		setPhotoIdLoading(true);
		setPhotoidData(null);

		try {
			const { data } = await axiosInstance.post('tier-kyc/token', {
				projectType: 'smartselfie'
			});
			if (data?.message?.includes('Tiered SDK parameters generated')) {
				setPhotoidData(data.content.data);
			}
			setPhotoIdLoading(false);
		} catch (error: any) {
			setPhotoIdLoading(false);
			handleRequestError(error)
		}
	};

	const checkForTier1 = (notifications: NotificationItem[]): boolean => {
		return notifications.some(notification =>
			notification.notyDesc.toLowerCase().includes('congratulations, you are now on tier 1')
		);
	};

	const [alerts, setAlerts] = useState([]);

	const getTierAlerts = async () => {
		const { data } = await axiosInstance.get(`notification/${userId}?pageSize=20&pageNum=1&sortBy=id&sortOrder=desc`);
		const response = data?.content?.data?.notifications?.data;

		setAlerts(response);
		return response;
	}

	return {
		getUserDetails,
		picLoading,
		updateProfilePic,
		detailsLoading,
		updateUserDetails,
		updateFirstTimeUserDetails,
		updLoading,
		updateFirstTimePersonalDetails,
		useSaveDirInfo,
		useDirInfo,
		useVerifyTierId,
		isIdValidated,
		useTierIdForms,
		verifyError,
		setVerifyError,
		getSdkToken,
		photoIdLoading,
		photoidData,
		getTierAlerts,
		checkForTier1,
		alerts
	};
};

export default useUser;