import { useFramer, useToolkit } from "../../..";
import { motion } from "framer-motion";

type Props = {};

const BankDetails = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();
	const { PryText } = useToolkit();

	return (
		<div className="bwite py-10 lg:py-12">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper">
				<motion.div
					variants={flashIn}
					className="w-full  md:w-2/4 lg:w-2/4 p-3 lg:pl-6">
					<p className="mb-0 med text-center mx-auto relative md:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						<PryText text="Flat processing fees," /> no unpleasant surprises.
					</p>
					<p className="my-5 text-center lg:t6 md:text-left lg:w-3/4">
						With our processing fee set in stone, we are fully transparent to
						prevent unpleasant surprises. You only pay what you see.
					</p>
				</motion.div>
				<motion.div
					variants={flashIn}
					className="rounded-lg w-full md:w-2/4 lg:w-2/4  lg:h-[500px] md:h-[400px] flex items-start justify-center relative">
					<img
						alt="how-to-send"
						src="/assets/features/bank-details.png"
						className="object-cover h-[250px] rounded-lg  md:h-[300px] lg:h-[430px]"
					/>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default BankDetails;
