import { toggleLogoutModal } from "redux/slices/layoutSlice";
import {
	useToolkit,
	CenterModal,
	QwidButton,
	Countdown,
} from "../../components";

type Props = {};

const AutoLogoutModal = (props: Props) => {
	const { useAppSelector, dispatch, logout } = useToolkit();
	const { isLogoutModalOpen } = useAppSelector((state) => state.layout);

	const logoutFn = () => {
		dispatch(toggleLogoutModal());
		logout();
	};

	return (
		<CenterModal control={isLogoutModalOpen} onClose={() => {}}>
			<div className="w-full flexed flex-col">
				<div>
					<img src="/assets/dashboard/logout.png" alt="logout" />
				</div>
				<div>
					<p className="mb-0 med lg:text-xl tcent">Logging you out in a bit</p>
					<div className="tcent py-5">
						<Countdown minutes={2} onExpiry={() => logoutFn()} />
					</div>
					<p className="mb-0 lg:text-lg text-center med mx-auto">
						Due to inactivity on your account, we'll be logging you out shortly.
						Let us know if you'd like to continue
					</p>
				</div>
				<div className="flexed space-x-5 mt-7">
					<QwidButton
						onClick={() => dispatch(toggleLogoutModal())}
						text={<span className="px-1">Stay logged in</span>}
					/>
					<QwidButton
						onClick={() => logoutFn()}
						isDarkBordered
						text={<span className="px-1">Log me out</span>}
					/>
				</div>
			</div>
		</CenterModal>
	);
};

export default AutoLogoutModal;
