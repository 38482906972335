import { AiOutlineCheckCircle } from "react-icons/ai";
import styled from "styled-components";
import {
	AUTH_ROUTES,
	DASHBOARD_ROUTES,
	ONBOARDING_ROUTES,
} from "utils/appData/appRoutes";
import { AuthNav, Spinner, useToolkit } from "../../components";
import useOnboarding from "../../utils/hooks/onboarding/useOnboarding";
import {
	PersonalOnboardingPageViews,
	PersonalState,
} from "redux/slices/onboardingSlice";

type Props = {
	children: React.ReactNode;
	header: string;
	subheader: string;
	permission?: boolean;
};

type SidebarItemProps = {
	title: string;
	subtitle: string;
	path: string;
	tab: number;
	permission?: boolean;
	isDone?: boolean;
	onFormSubmit?: () => void;
};
const OnboardingLayout = ({
	children,
	header,
	subheader,
	permission,
}: Props) => {
	const { HeadEl, router, isBusinessAccount, isValidUser, useEffect } =
		useToolkit();

	const { setOnboardingView, state } = useOnboarding<PersonalState>("personal");

	const handleClick = (tab: number) => {
		if (
			state[
				state.currentTab as PersonalOnboardingPageViews.PERSONAL_INFORMATION
			]?.modified
		) {
			// Todo: show modal asking user to either cancel or discard changes
		} else if (tab === PersonalOnboardingPageViews.PERSONAL_INFORMATION || state[
			(tab - 1) as PersonalOnboardingPageViews.PERSONAL_INFORMATION
		]?.completed){
			setOnboardingView(tab);
		}
	};

	const sidebarItems: SidebarItemProps[] = [
		{
			title: "Personal Information",
			subtitle:
				"Your account is almost ready: we just need a few details about your company to validate your account.",
			path: ONBOARDING_ROUTES.PERSONAL.PERSONAL_INFO,
			tab: PersonalOnboardingPageViews.PERSONAL_INFORMATION,
			permission: true,
			// isDone: personalInfoComplete,
			onFormSubmit: () => {},
		},
		{
			title: "Contact Information",
			subtitle: "",
			path: DASHBOARD_ROUTES.DASHBOARD,
			permission: isBusinessAccount,
			// isDone: businessInfoComplete,
			onFormSubmit: () => {},
			tab: PersonalOnboardingPageViews.CONTACT_INFORMATION,
		},
		{
			title: "Verify Identity",
			subtitle: "",
			path: DASHBOARD_ROUTES.DASHBOARD,
			permission: isBusinessAccount,
			onFormSubmit: () => {},
			tab: PersonalOnboardingPageViews.VERIFY_IDENTITY,
		},
		{
			title: "Summary",
			subtitle: "",
			path: DASHBOARD_ROUTES.DASHBOARD,
			permission: isBusinessAccount,
			onFormSubmit: () => {},
			tab: PersonalOnboardingPageViews.SUMMARY,
		},
		{
			title: "Upload Documents",
			subtitle: "",
			path: DASHBOARD_ROUTES.DASHBOARD,
			permission: isBusinessAccount,
			onFormSubmit: () => {},
			tab: PersonalOnboardingPageViews.UPLOAD_DOCS,
		},
	];

	

	return  (
		<>
			<HeadEl isDashboard />
			<AuthNav hasDashboardButton={true} />
			<Main className="flex flex-wrap lg:flex-nowrap">
				<section className="flex flex-col min-h-full bg-[#f6f6f6] w-full lg:w-1/5 side-pad pt-7 lg:pt-20">
					{sidebarItems.map(({ title, tab }: SidebarItemProps) => {
						const isActive = tab === state.currentTab;
						const doneAlready =
							state[tab as PersonalOnboardingPageViews.CONTACT_INFORMATION]
								?.completed;

						return (
							<div
								onClick={() => handleClick(tab)}
								className="curp flex items-start mb-12"
								key={title}>
								<div
									className={`text-[20px] mr-3 ${
										doneAlready
											? "text-[#19B946]"
											: isActive
											? "text-[#01161F]"
											: "text-[#888888]"
									}`}>
									<AiOutlineCheckCircle />
								</div>
								<div>
									<p
										className={`text-sm mb-2 ${
											isActive ? "text-[#01161F] med" : "text-[#888888]"
										}`}>
										{title}
									</p>
								</div>
							</div>
						);
					})}
				</section>
				<section className="flex flex-col items-center bwite min-h-full w-full lg:w-4/5 pt-7 lg:pt-10">
					<div className="flexed flex-col mb-5">
						<p className="mb-2 text-lg lg:text-2xl smb twide text-[#01161F]">
							{header}
						</p>
						<p className="text-base twide text-[#667085] lg:w-4/5 tcent">
							{subheader}
						</p>
					</div>
					<div className="w-full lg:w-4/5 mx-auto">{children}</div>
				</section>
			</Main>
			{/* <SumSubInitModal
				control={isKycModalOpen}
				onClose={() => dispatch(toggleKycModal())}
			/> */}
		</>
	);
};

export default OnboardingLayout;

const Main = styled.main`
	min-height: calc(100vh - 70px);
`;
