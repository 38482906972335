import { toggleInitVac } from "redux/slices/virtualsSlice";
import { useToolkit } from "../../../components";
import { useMutation } from "react-query";

const useVirtualAccounts = () => {
	const {
		useState,
		axiosInstance,
		userId,
		toastSuccess,
		dispatch,
		handleRequestError,
	} = useToolkit();

	const [acctsLoading, setAcctsLoading] = useState(false);
	const [acctsData, setAcctsData] = useState([]);
	const [limitReached, setLimitReached] = useState(false);
    
	const getAvailableVirtualAccts = async () => {
		setAcctsLoading(true);

		try {
			const { data } = await axiosInstance.get(
				`virtualAccount/available/${userId}`
			);
			const response = data?.content?.data;
			if (response?.length === 0) {
				setLimitReached(true);
			} else {
				setLimitReached(false);
				setAcctsData(response);
			}

			setAcctsLoading(false);
		} catch (error) {
			handleRequestError(error);
			setAcctsLoading(false);
			return error;
		}
	};

	const requestVirtualAcctApi = async (currency: string) => {
		const {data} = await axiosInstance.post(`virtualAccount`, {
			userId,
			cur: currency,
		});
		
		return data?.content?.data;
	}

	const requestVirtualAcct = async (cur: string) => {
		setAcctsLoading(true);
		try {
			await requestVirtualAcctApi(cur);
			dispatch(toggleInitVac());
			toastSuccess(`${cur} Account Requested`);
			setAcctsLoading(false);
		} catch (error) {
			handleRequestError(error);
			dispatch(toggleInitVac());
			setAcctsLoading(false);
			return error;
		}
	};

	const useRequestVirtualAcct = useMutation({
		mutationFn: requestVirtualAcctApi,
	})

	return {
		getAvailableVirtualAccts,
		acctsData,
		acctsLoading,
		limitReached,
		requestVirtualAcct,
		useRequestVirtualAcct
	};
};

export default useVirtualAccounts;
