import React from "react";
import { motion } from "framer-motion";

type Props = {};

type FeatureProps = {
	iconPath: string;
	title: string;
	subtitle: string;
};

const featureItems: FeatureProps[] = [
	{
		iconPath: "/assets/business/partners/cool-banking.svg",
		title: "Cool banking",
		subtitle:
			"Enjoy multi-currency banking, get an IBAN, routing number, sort code, and more.",
	},
	{
		iconPath: "/assets/business/partners/mng-funds.svg",
		title: "Convenient",
		subtitle:
			"Move money between accounts in seconds and manage all transactions in one place.",
	},
	{
		iconPath: "/assets/business/partners/trusted.svg",
		title: "Trusted",
		subtitle:
			"We support payments to every continent and businesses across the globe put their faith in Qwid",
	},
	{
		iconPath: "/assets/business/partners/quick-trans.svg",
		title: "Quick",
		subtitle: "Payments are processed within record time.",
	},
	// {
	// 	iconPath: "/assets/business/partners/affordable.svg",
	// 	title: "Affordable",
	// 	subtitle:
	// 		"Enjoy multi-currency banking, get an IBAN, routing number, sort code, and more.",
	// },
];

const Partners = (props: Props) => {
	return (
		<div className="">
			<div className="bg-[#f4fafd] side-pad h-pad py-12 lg:py-16">
				<h2 className="mx-auto text-lg lg:text-5xl font-semibold mb-16 text-center">
					Check out the benefits of qwid
				</h2>

				<div className="flex flex-wrap items-center justify-center md:justify-around lg:justify-start lg:items-start lg:flex-nowrap overflow-x-scroll scrollbar-hide lg:space-x-7 space-y-9 md:space-y-7 lg:space-y-0 w-full">
					{featureItems.map(({ iconPath, title, subtitle }, idx) => (
						<motion.div
							initial={{ opacity: 0, x: "-100" }}
							whileInView={{
								opacity: 1,
								x: 0,
								transition: {
									type: "spring",
									stiffness: 120,
									duration: 0.3,
									delay: idx * 0.12,
								},
							}}
							viewport={{ once: true }}
							key={title}
							className="bwite rounded-3xl p-5 border border-[#eeeeee] w-full md:w-2/5 lg:w-[52%] h-[220px] lg:h-[240px] tcleft lg:mb-0">
							<div className="flex items-center justify-center lg:justify-start w-full">
								<div className="mb-2 h-8 w-8 rounded-full mt-4 flexed bg-[#CDD1D6]">
									<img src={iconPath} alt="title" width={16} className="" />
								</div>
							</div>
							<p className="mb-2 mt-4 lg:mt-3 smb text-lg lg:text-xl blk">
								{title}
							</p>
							<p className="blk text-sm bottom-0">{subtitle}</p>
						</motion.div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Partners;
