import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Message {
  content?: unknown;
  isRead?: string;
  status?: boolean;
  event: string;
  sourceId?: string;
  messageId?: string;
}
interface NotificationState {
  kyc: Message;
}

const initialState: NotificationState = {
  kyc: {} as Message
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setKYCNotification: (state, { payload }: PayloadAction<Message>) => ({
      ...state,
      kyc: payload
    }),
    resetKycNotification: (state) => ({
      ...state,
      kyc: {} as Message
    })
  }
});

export const { setKYCNotification, resetKycNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
