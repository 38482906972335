// assets
export { default as qwidLogo } from "../../public/assets/landing/qwidLogo.svg";
export { default as cyhowtoSend } from "../../public/assets/landing/howtosend.svg";
export { default as zigline } from "../../public/assets/landing/zigline.png";
export { default as qrcode } from "../../public/assets/landing/qrcode.png";


//product update components
export { default as ProductUpdate } from './product-update';

// dashboard page components
export { default as PinSetupUI } from "./dashboard/PinSetupUI";
export { default as AddBeneficiaryModal } from "./dashboard/remittance/AddBeneficiaryModal";
export { default as UserWalletCard } from "./dashboard/UserWalletCard";
export { default as SendFundOptions } from "./dashboard/SendFundOptions";
export { default as TopupOptions } from "./dashboard/TopupOptions";
export { default as ConversionTable } from "./dashboard/ConversionTable";
export { default as NonNgnBenefForm } from "./dashboard/NonNgnBenefForm";
export { default as VirtualAccountCard } from "./dashboard/VirtualAccountCard";
export { default as TopupModal } from "./dashboard/TopupModal";
export { default as WithdrawalModal } from "./dashboard/WithdrawalModal";
export { default as ConfirmOffshoreModal } from "./dashboard/ConfirmOffshoreModal";
export { default as TopupForm } from "./dashboard/TopupForm";
export { default as ConfirmTopup } from "./dashboard/ConfirmTopup";
export { default as VirtualShortcuts } from "./dashboard/VirtualShortcuts";
export { default as AddBeneficiaryInline } from "./dashboard/AddBeneficiaryInline";
export { default as QwidTransferModal } from "./dashboard/QwidTransferModal";
export { default as EpayModal } from "./dashboard/EpayModal";
export { default as EpayCancellationModal } from "./dashboard/EpayCancellationModal";
export { default as WalletActivityTable } from "./app-redesign/dashboard/WalletActivityTable";
export { default as WalletActionsCard } from "./app-redesign/dashboard/WalletActionsCard";
export { default as WalletConvert } from "./app-redesign/dashboard/WalletConvert";
export { default as CountryAmount } from "./app-redesign/dashboard/send-flow/withdrawal/CountryAmount";
export { default as WalletSendingFrom } from "./app-redesign/dashboard/send-flow/withdrawal/WalletSendingFrom";
export { default as PayoutChannel } from "./app-redesign/dashboard/send-flow/withdrawal/PayoutChannel";
export { default as SelectSavedAccount } from "./app-redesign/dashboard/send-flow/withdrawal/SelectSavedAccount";
export { default as ReviewTransaction } from "./app-redesign/dashboard/send-flow/withdrawal/ReviewTransaction";
export { default as ErrorPage } from "./app-redesign/dashboard/send-flow/ErrorPage";
export { default as SuccessPage } from "./app-redesign/dashboard/send-flow/SuccessPage";

// conversions page components
export { default as useCurrencySelector } from "./conversions/useCurrencySelector";
export { default as ReceivingCurrencyDrawer } from "./conversions/ReceivingCurrencyDrawer";

// conversions  components
export { default as InitVacRequestModal } from "./virtual-accts/InitVacRequestModal";

// transactions page components
export { default as DetailedTransactionView } from "./transactions/DetailedTransactionView";
export { default as StatementGeneratorModal } from "./wallet-balance/StatementGeneratorModal";

// virtuals components
export { default as VirtualCardWrapper } from "./virtual-cards/VirtualCardWrapper";
export { default as EmptyCardsUI } from "./virtual-cards/EmptyCardsUI";
export { default as CardBalanceActions } from "./virtual-cards/CardBalanceActions";
export { default as CardTransDetails } from "./virtual-cards/CardTransDetails";
export { default as FundCardModal } from "./virtual-cards/FundCardModal";
export { default as DisableCardModal } from "./virtual-cards/DisableCardModal";
export { default as CardSecurityModal } from "./virtual-cards/CardSecurityModal";

// settings page components
export { default as AddBankDetailsForm } from "./settings/AddBankDetailsForm";
export { default as AddBankDetailsModal } from "./settings/AddBankDetailsModal";
export { default as UpdateBusinessDetModal } from "./settings/UpdateBusinessDetModal";
export { default as KycKyb } from "./settings/KycKyb";
export { default as PersonnelDetailsForm } from "./settings/PersonnelDetailsForm";
export { default as BusinessRegInformation } from "./settings/BusinessRegInformation";
export { default as OtherRelevantInfo } from "./settings/OtherRelevantInfo";
export { default as StakeholderInfo } from "./settings/StakeholderInfo";
export { default as TwoFactorAuth } from "./settings/TwoFactorAuth";
export { default as Notifications } from "./settings/Notifications";
export { default as BankAccountsTab } from "./settings/BankAccountsTab";
export { default as ApiKeys } from "./settings/ApiKeys";
export { default as Profile } from "./settings/Profile";
export { default as Security } from "./settings/Security";
export { default as BusinessTab } from "./settings/BusinessTab";

// beneficiaries page components
export { default as DetailedBeneficiaryView } from "./beneficiaries/DetailedBeneficiaryView";
export { default as DeleteBeneficiaryModal } from "./beneficiaries/DeleteBeneficiaryModal";

// landing page components
export { default as LandingNav } from "./landing/LandingNav";
export { default as LandingFooter } from "./landing/LandingFooter";
export { default as FeaturesDropdown } from "./landing/FeaturesDropdown";
export { default as HelpDropdown } from "./landing/HelpDropdown";
export { default as HeroSection } from "./landing/HeroSection";

// personal page components
export { default as DigitalBank } from "./landing/personal/DigitalBank";
export { default as PersonalBanner } from "./landing/personal/PersonalBanner";
export { default as SendAndReceiveAnywhere } from "./landing/personal/SendAndReceiveAnywhere";
export { default as ShopGlobalMarkets } from "./landing/personal/ShopGlobalMarkets";
export { default as ConnectingYou } from "./landing/personal/ConnectingYou";
export { default as ConvertMultiple } from "./landing/personal/ConvertMultiple";
export { default as WhyQwid } from "./landing/personal/WhyQwid";
export { default as Testimonials } from "./landing/personal/Testimonials";
export { default as MilitaryGradeEncryption } from "./landing/personal/MilitaryGradeEncryption";

// features - virtual wallets
export { default as SeamlessPayments } from "./landing/features/virtual-wallets/SeamlessPayments";
export { default as JacketLady } from "./landing/features/virtual-wallets/JacketLady";
export { default as OnlinePayment } from "./landing/features/virtual-wallets/OnlinePayment";

// features - conversion
export { default as Convert } from "./landing/features/conversion/Convert";
export { default as Payment } from "./landing/features/conversion/Payment";
export { default as Exchange } from "./landing/features/conversion/Exchange";
// features - virtual cards
export { default as VirtualCardsBanner } from "./landing/features/virtual-cards/VirtualCardsBanner";
export { default as PayAnywhere } from "./landing/features/virtual-cards/PayAnywhere";
export { default as SeamlessExplore } from "./landing/features/virtual-cards/SeamlessExplore";
// features - withdrawals
export { default as SeamlessWithdrawal } from "./landing/features/withdrawals/SeamlessWithdrawal";
export { default as SeamlessPayment } from "./landing/features/withdrawals/SeamlessPayment";
export { default as Transaction } from "./landing/features/withdrawals/Transaction";

// features - bank transfer
export { default as TransferFunds } from "./landing/features/bank-transfer/TransferFunds";
export { default as ReceiverGets } from "./landing/features/bank-transfer/ReceiverGets";
export { default as BankDetails } from "./landing/features/bank-transfer/BankDetails";
export { default as SecurityGuaranteed } from "./landing/features/bank-transfer/SecurityGuaranteed";
export { default as FinancialFreedom } from "./landing/features/bank-transfer/FinancialFreedom";
export { default as DynamicSpiralPhone } from "./landing/features/bank-transfer/DynamicSpiralPhone";

// business page components
export { default as BusinessBanner } from "./landing/business/BusinessBanner";
export { default as Partners } from "./landing/business/Partners";
export { default as SecureSolutions } from "./landing/business/SecureSolutions";
export { default as CheckoutPayments } from "./landing/business/CheckoutPayments";
export { default as DirectPaymentsWorldWide } from "./landing/business/DirectPaymentsWorldWide";
export { default as FinancialPossibilities } from "./landing/business/FinancialPossibilities";
export { default as SendToHundreds } from "./landing/business/SendToHundreds";
export { default as ReadyToStart } from "./landing/business/ReadyToStart";
export { default as IncreaseConversion } from "./landing/business/IncreaseConversion";
export { default as RangeOfServices } from "./landing/business/RangeOfServices";
export { default as GettingStarted } from "./landing/business/GettingStarted";

// auth components
export { default as AuthNav } from "./auth/AuthNav";

// misc components
export { default as SidebarLinkDropdown } from "./misc/SidebarLinkDropdown";
export { default as SignUpCountrySelector } from "./misc/SignUpCountrySelector";
export { default as OptionalRender } from "./misc/OptionalRender";
export { default as QwidButton } from "./misc/QwidButton";
export { default as TextTruncator } from "./misc/TextTruncator";
export { default as KycButton } from "./misc/KycButton";
export { default as SkeletonLoader } from "./misc/SkeletonLoader";
export { default as Tooltip } from "./misc/Tooltip";
export { default as Feedback } from "./misc/Feedback";
export { default as Countdown } from "./misc/Countdown";
// export { default as Countdown } from "./misc/Countdown";
export { default as AutoLogoutModal } from "./misc/AutoLogoutModal";
export { default as LandingMobileSidebar } from "./misc/LandingMobileSidebar";
export { default as CenterModal } from "./misc/CenterModal";
export { default as FormStep } from "./misc/FormStep";
export { default as Spinner } from "./misc/Spinner";
export { default as CustomSelector } from "./common/CustomSelector";
export { default as CustomInputField } from "./common/CustomInputField";
export { default as ProgressStepper } from "./common/StepDisplay";

// board components
export { default as BoardNav } from "./user/BoardNav";
export { default as BoardSidebar } from "./user/BoardSidebar";
export { default as BoardLayout } from "./user/BoardLayout";
export { default as NavlessBoardLayout } from "./user/NavlessBoardLayout";
export { default as OnboardingLayout } from "./user/OnboardingLayout";
export { default as NotificationsUI } from "./user/NotificationsUI";
export { default as DashboardMobileMenu } from "./user/DashboardMobileMenu";

// animation components & hooks
export { default as useFramer } from "../utils/animations/useFramer";
export { default as useTawk } from "../utils/hooks/misc/useTawk";

// layouts
export { default as LandingLayout } from "../layouts/LandingLayout";
export { default as AuthLayout } from "../layouts/AuthLayout";
export { default as KycLayout } from "../layouts/KycLayout";
export { default as DocsLayout } from "../layouts/DocsLayout";

// all hooks
export { default as useToolkit } from "../utils/hooks/misc/useToolkit";
export * from "../utils/hooks/misc/useToolkit";
export { default as useKyc } from "../utils/hooks/settings/useKyc";
export { default as useLocation } from "../utils/hooks/dashboard/useLocation";
export { default as useFileUploader } from "../utils/hooks/dashboard/useFileUploader";
export { default as useDashboard } from "../utils/hooks/dashboard/useDashboard";
export { default as useBeneficiary } from "../utils/hooks/dashboard/useBeneficiary";
export { default as useTransactions } from "../utils/hooks/transactions/useTransactions";
export { default as useAuth } from "../utils/hooks/auth/useAuth";
export { default as usePdf } from "../utils/hooks/misc/usePdf";
export { default as useSendFunds } from "../utils/hooks/dashboard/useSendFunds";
export { default as useSendFlow } from "../utils/hooks/dashboard/useSendFlow";
export { default as useVirtualAccounts } from "../utils/hooks/virtuals/useVirtualAccounts";
export { default as useVirtualCards } from "../utils/hooks/virtuals/useVirtualCards";
export { default as useDashboardRoutes } from "../utils/hooks/misc/useDashboardRoutes";
export { default as useUser } from "../utils/hooks/settings/useUser";

// misc exports
// export { dashboardRoutes } from "../utils/appData/dashboardRoutes";

//blog component
export { default as Stories } from "./blog/Stories";
