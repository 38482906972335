import { setCardInfo, toggleFundCardModal } from "redux/slices/virtualsSlice";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";
import useToolkit from "../misc/useToolkit";

const useVirtualCards = () => {
	const {
		toastSuccess,
		axiosInstance,
		router,
		useMutation,
		useQuery,
		userId,
		dispatch,
		useState,
		useAppSelector,
		handleRequestError,
	} = useToolkit();

	const { selectedVCard } = useAppSelector((state) => state.virtuals);

	// creating virtual cards
	const reqCardFn = async (payload: any) => {
		try {
			const { data } = await axiosInstance.post("virtualCard/create", payload);
			toastSuccess(`Card created successfully`);
			setTimeout(() => {
				router.push(DASHBOARD_ROUTES.VIRTUAL_CARDS_HOME);
			}, 3000);
			return data;
		} catch (error) {
			handleRequestError(error);
		}
	};
	const useRequestCard = () => useMutation(reqCardFn);
	const { mutate: requestCard, isLoading: requestLoading } = useRequestCard();

	// get card transactions
	const getTransFn = async (virtualCardId: string) => {
		const payload = {
			virtualCardId,
		};
		if (virtualCardId) {
			try {
				const {
					data: {
						content: {
							data: { transaction, cardDetails },
						},
					},
				} = await axiosInstance.post("virtualCard/retrieve", payload);
				dispatch(setCardInfo({ cardDetails, cardTransactions: transaction }));
				return transaction;
			} catch (error) {
				handleRequestError(error);
			}
		}
	};
	const useGetTrans = () => useMutation(getTransFn);

	const { mutate: getCardTransactions } = useGetTrans();

	// fetch all cards
	const [loadingCards, setLoadingCards] = useState(false);
	const getAllCards = async () => {
		setLoadingCards(true);
		try {
			const payload = {
				userId,
			};

			const {
				data: {
					content: { data },
				},
			} = await axiosInstance.post("virtualCard/user", payload);
			const formatted = data?.map((item: VirtualCard) => ({
				...item,
				detailsHidden: true,
			}));
			setLoadingCards(false);
			return formatted;
		} catch (error) {
			handleRequestError(error);
		}
	};

	const {
		isLoading: cardsLoading,
		isFetching,
		data: cards,
		refetch: fetchAllCards,
	} = useQuery("getAllCards", getAllCards, {
		refetchOnMount: false,
	});

	// fetch card currencies
	const [cardCurLoading, setCardCurLoading] = useState(false);
	const [cardCurrencies, setCardCurrencies] = useState([]);
	const getCardCurrencies = async () => {
		setCardCurLoading(true);
		try {
			const {
				data: {
					content: { data },
				},
			} = await axiosInstance.get("virtualCard/currency");
			setCardCurrencies(data);
			setCardCurLoading(false);
			return data;
		} catch (error) {
			handleRequestError(error);
			setCardCurLoading(false);
		}
	};

	// fund card
	const fundCardFn = async (payload: any) => {
		try {
			const {
				data: {
					content: { data },
				},
			} = await axiosInstance.post("virtualCard/topup", payload);
			dispatch(toggleFundCardModal());
			toastSuccess("Card funded successfully");
			setTimeout(() => {
				getCardTransactions(selectedVCard?.virtualCardId || "");
			}, 1500);
			return data;
		} catch (error) {
			handleRequestError(error);
		}
	};
	const useFundCard = () => useMutation(fundCardFn);

	return {
		requestCard,
		requestLoading,
		cardsLoading,
		isFetching,
		cards,
		useGetTrans,
		useFundCard,
		fetchAllCards,
		cardCurLoading,
		cardCurrencies,
		loadingCards,
		setLoadingCards,
		getCardCurrencies,
	};
};

export default useVirtualCards;
