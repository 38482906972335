import {
  clearAdminTopupPayload,
  epayTrxFailed,
  epayTrxSuccessful,
  saveAdminTopupPayload,
  saveEpayTransDetails,
  saveTrfDetails,
  saveUserWallets,
  saveVirtualAccounts,
  setRemittanceDetails,
  switchRemittancePage,
  togglePinAuth,
  toggleTopupModal,
  toggleWalletCreationModal
} from 'redux/slices/dashboardSlice';
import { useToolkit } from '../../../components';

type Props = {};
type NewWalletProps = {
  message: string;
};

const useDashboard = () => {
  const {
    useState,
    axiosInstance,
    userId,
    dispatch,
    toastSuccess,
    useAppSelector,
    useMutation,
    toastError,
    handleRequestError
  } = useToolkit();

  const { adminTopupPayload, isEpayModalOpen, topupCurrency, epayTransDetails, remittanceDetails } =
    useAppSelector((state) => state.dashboard);

  const [reqLoading, setReqLoading] = useState(false);
  const [initDone, setInitDone] = useState(false);

  // initializing topup request - old integration
  const initiateTopup = async (payload: any) => {
    setReqLoading(true);
    try {
      await axiosInstance.post(`transaction/initiate-bank-transfer/${userId}`, payload);
      toastSuccess('Transaction Initiated');
      setReqLoading(false);
      setInitDone(true);
      setTimeout(() => {
        dispatch(toggleTopupModal());
      }, 3000);
    } catch (error) {
      handleRequestError(error);
      setReqLoading(false);
      return error;
    }
  };

  // getting transfer details for topup
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [trfDetails, setTrfDetails] = useState<any>({});
  const [acctEmpty, setAcctEmpty] = useState(false);
  const [isVirtual, setIsVirtual] = useState(false);

  const getTrfDetails = async (currency: string) => {
    if (currency) {
      setDetailsLoading(true);
      try {
        const { data } = await axiosInstance.get(`adminbank/${userId}/${currency}`);
        const response = data?.content?.data[0];

        if (response?.source === 'virtual') {
          setIsVirtual(true);
          setAcctEmpty(false);
        } else {
          setAcctEmpty(false);
          setIsVirtual(false);
        }
        setTrfDetails(response);
        dispatch(saveTrfDetails(response));
        setDetailsLoading(false);
      } catch (error: any) {
        const msg = error?.response?.data?.message;
        if (msg?.includes('No Bank Available')) {
          setAcctEmpty(true);
          handleRequestError(error);
        }
        setDetailsLoading(false);
        return error;
      }
    }
  };

  const handleFetchUserWallets = async (returnPendingWallet?: boolean) => {
    const { data } = await axiosInstance.get(`wallet/${userId}`);
    const response = data?.content?.data;
    const wallet = response?.wallet?.length ? response?.wallet : [];
    return returnPendingWallet
      ? { data: wallet, hasPendingWallets: response?.pending_wallet?.length > 0 }
      : wallet;
  };

  // fetching user's wallets
  const [walletsLoading, setWalletsLoading] = useState(false);
  const [walletsData, setWalletsData] = useState([]);
  const [hasPendingWallets, setHasPendingWallets] = useState(false);
  const fetchUserWallets = async () => {
    setWalletsLoading(true);
    try {
      const response = await handleFetchUserWallets(true);
      dispatch(saveUserWallets(response?.data));
      setWalletsData(response?.data);
      setWalletsLoading(false);
      setHasPendingWallets(response.hasPendingWallets);
    } catch (error) {
      handleRequestError(error);
      setWalletsLoading(false);
      return error;
    }
  };

  // fetching virtual accounts
  const [accLoading, setAccLoading] = useState(true);

  const getVirtualAccounts = async () => {
    setAccLoading(true);
    try {
      const data = await axiosInstance.get(`virtualAccount/users/${userId}`);
      const accounts = data?.data?.content?.data;
      dispatch(saveVirtualAccounts(accounts));
      setAccLoading(false);
    } catch (error) {
      handleRequestError(error);
      setAccLoading(false);
      return error;
    }
  };

  // getting transaction Id for topup
  const [idLoading, setIdLoading] = useState(false);
  const [idData, setIdData] = useState('');
  const getTransId = async () => {
    setIdLoading(true);
    try {
      const { data } = await axiosInstance.get(`transId`);
      const response = data?.transId;
      setIdData(response);
      setIdLoading(false);
    } catch (error) {
      handleRequestError(error);
      setIdLoading(false);
      return error;
    }
  };

  // check for topup setup on admin
  const getAdminTopupFn = async () => {
    const {
      data: {
        content: { data }
      }
    } = await axiosInstance.get(`adminbank/${userId}/${topupCurrency}`);
    try {
      const response = data[0];
      dispatch(saveAdminTopupPayload(response));
    } catch (error) {
      handleRequestError(error);
      return error;
    }

    return data;
  };
  const useGetTopup = () =>
    useMutation(getAdminTopupFn, {
      onError: (error: any) => {
        const message = error?.response?.data?.message;
        if (message?.includes('No Bank Available')) {
          dispatch(clearAdminTopupPayload());
        }
      }
    });

  // get topup channels
  const getChannelsFn = async (paymentType: string) => {
    try {
      const source = adminTopupPayload?.payin_source;
      const {
        data: {
          content: { data }
        }
      } = await axiosInstance.get(`transaction-support/${source}/channel`);

      const response = data
        ?.filter(
          (item: any) =>
            item?.payment !== 'payout' && item?.category.toLowerCase() === paymentType.toLowerCase()
        )
        ?.map((item: any) => ({
          ...item,
          title: `${item.name} - ${item.category}`,
          value: item.id
        }));
      return response;
    } catch (error) {
      handleRequestError(error);
    }
  };
  const useGetChannels = () => useMutation(getChannelsFn);

  // get rate information
  const [rateInfo, setRateInfo] = useState({ fee: 0, toReceive: null });
  const getRatesFn = async (payload: any) => {
    try {
      const {
        data: {
          content: { data }
        }
      } = await axiosInstance.post('rate', payload);
      setRateInfo(data);
    } catch (error) {
      handleRequestError(error);
    }
  };
  const useGetRates = () => useMutation(getRatesFn);

  // initialize topup
  const initTopupFn = async (payload: any) => {
    try {
      const {
        data: {
          content: { data }
        }
      } = await axiosInstance.post('topup', payload);

      if (data?.paymentLink && !isEpayModalOpen) {
        dispatch(saveEpayTransDetails(data));
      }
    } catch (error) {
      handleRequestError(error);
    }
  };
  const useInitTopup = () => useMutation(initTopupFn);

  // cancel epay topup
  const cancelTopupFn = async () => {
    try {
      const payload = {
        userId,
        transId: epayTransDetails?.transaction?.transId
      };
      await axiosInstance.delete('topup', { data: payload });
    } catch (error) {
      handleRequestError(error);
    }
  };
  const useCancelTopup = () => useMutation(cancelTopupFn);

  // confirm epay topup
  const [isConfirming, setIsConfirming] = useState(false);
  const confirmTrxFn = async () => {
    setIsConfirming(true);
    try {
      const transId = epayTransDetails?.transaction?.transId;

      const {
        data: {
          content: { data }
        }
      } = await axiosInstance.get(`transaction/${transId}`);
      const resp = data[0];
      const isPending = resp?.status === 0;
      const isPaid = resp?.status === 1;
      const isDeclined = resp?.status === 2;

      if (isPaid) {
        dispatch(epayTrxSuccessful());
        setIsConfirming(false);
      }

      if (isDeclined) {
        dispatch(epayTrxFailed());
        setIsConfirming(false);
      }
    } catch (error) {
      handleRequestError(error);
    }
  };
  const useConfirmEpay = () => useMutation(confirmTrxFn);

  const { user, isUserPinSet } = useAppSelector((state) => state.user);
  const trxPinExists = user?.transactionPin || isUserPinSet;
  //initiate remittance
  const addRemittance = async (payload: any) => {
    try {
      const {
        data: {
          content: { data }
        }
      } = await axiosInstance.post(`initiate-remittance/${userId}`, payload);
      const transId = data?.transId;
      dispatch(setRemittanceDetails({ transId: transId }));
      dispatch(switchRemittancePage('success'));
      {
        trxPinExists && dispatch(togglePinAuth());
      }
      return data;
    } catch (error: any) {
      const message = error?.response?.data?.message || error?.message || '';
      if (message.toLowerCase().includes('pin')) {
        // toastError(message);
      } else {
        dispatch(setRemittanceDetails({ errorMsg: message }));
        {
          trxPinExists && dispatch(togglePinAuth());
        }
        dispatch(switchRemittancePage('error'));
      }
      handleRequestError(error);
      return error;
    }
  };
  const useRemittance = () => useMutation(addRemittance);

  // getting available wallets for user's wallet creation
  const getWallets = async () => {
    try {
      const {
        data: {
          content: { data }
        }
      } = await axiosInstance.get(`wallet/available/${userId}`);

      if (data?.length > 0) {
        const formattedWallets = data?.map((item: any) => ({
          ...item,
          title: item.currency,
          label: item.countryCode,
          value: item.cur
        }));
        return formattedWallets;
      } else if (data?.length === 0) {
        toastError('No additional wallets available');
      }
    } catch (error) {
      handleRequestError(error);
    }
  };
  const useAvailableWallets = () => useMutation(getWallets);

  // user's wallet creation
  const createWallet = async (cur: string) => {
    try {
      await axiosInstance
        .post(`wallet/${userId}`, {
          cur
        })
        .then((response) => {
          if (response?.data?.message?.includes('User Wallet Retrieved')) {
            toastSuccess(`${cur} wallet generated successfully`);
            dispatch(toggleWalletCreationModal());
            fetchUserWallets();
          }
        });
    } catch (error) {
      handleRequestError(error);
    }
  };
  const useCreateWallet = () => useMutation(createWallet);

  return {
    fetchUserWallets,
    walletsLoading,
    getTrfDetails,
    detailsLoading,
    setDetailsLoading,
    trfDetails,
    acctEmpty,
    isVirtual,
    initDone,
    getTransId,
    idLoading,
    idData,
    initiateTopup,
    reqLoading,
    accLoading,
    getVirtualAccounts,
    walletsData,
    useGetTopup,
    useGetChannels,
    useGetRates,
    rateInfo,
    useInitTopup,
    useConfirmEpay,
    isConfirming,
    useCancelTopup,
    useRemittance,
    handleFetchUserWallets,
    useAvailableWallets,
    useCreateWallet,
	hasPendingWallets
  };
};

export default useDashboard;
