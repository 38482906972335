import { toggleFirstTimeStatus } from 'redux/slices/settingsSlice';
import { AUTH_ROUTES, DASHBOARD_ROUTES, ONBOARDING_ROUTES } from 'utils/appData/appRoutes';
import { useToolkit } from '../../../components';
import {
  pinSetupComplete,
  setEleganza,
  setFcmDetails,
  setIsLoggedIn,
  setPlainUser,
  setUser
} from '../../../redux/slices/userSlice';
import { deleteReferralLink } from 'redux/slices/dashboardSlice';
import { getFCMToken } from '../../../firebase';
import { nanoid } from 'nanoid';
import { AuthTypes } from 'components/settings/queries/settingsQueries';
import { useMemo } from 'react';
import { AuthStates } from 'components/auth/TwoFactorAuth';

function useAuth() {
  const {
    dispatch,
    useState,
    axiosInstance,
    toastSuccess,
    userId,
    userEmail,
    router,
    useMutation,
    useAppSelector,
    toastError,
    fcmToken,
    deviceId,
    handleRequestError,
    isLoggedIn,
    encryptData,
    isPersonalAccount
  } = useToolkit();

  // handling authentication
  const [loginLoading, setLoginLoading] = useState(false);
  const [regLoading, setRegLoading] = useState(false);
  const { createPinNavigator } = useAppSelector((state) => state.dashboard);
  const { user } = useAppSelector((state) => state.user);

  const setupFCM = async (userId: string): Promise<string> => {
    if (!userId) return '';
    try {
      let token = fcmToken;
      if (!token && getFCMToken) {
        token = await getFCMToken();
        const newDeviceId = deviceId || nanoid();
        await axiosInstance.post('set-noty-token', {
          userId,
          platform: 'web',
          deviceId: newDeviceId,
          notificationId: token
        });
        dispatch(
          setFcmDetails({
            fcmToken: token!,
            deviceId: newDeviceId
          })
        );
      }
      return token!;
    } catch (error) {
      return '';
      // Todo: Add required logic
    }
  };

  const registerUser = async (values: { email: string; password: string; country?: string }) => {
    setRegLoading(true);
    try {
      const { data } = await axiosInstance.post(`endUsers`, values);
      const userObject = data?.content?.data;
      await setupFCM(userObject.userId);
      toastSuccess('Registration Successful');
      dispatch(setUser(userObject));
      setRegLoading(false);
      dispatch(toggleFirstTimeStatus());
      setTimeout(() => router.push(AUTH_ROUTES.VERIFY_EMAIL), 1500);
      dispatch(deleteReferralLink());
    } catch (error) {
      handleRequestError(error);
      setRegLoading(false);
      return error;
    }
  };

  const confirmVerification = async (code: string, userId: string) => {
    setRegLoading(true);
    try {
      const data = await axiosInstance.post(`confirmEmailVerify`, {
        code,
        userId
      });
      const response = await data?.data?.content?.data[0];
      toastSuccess(data?.data?.message);
      dispatch(setPlainUser(response));
      dispatch(setEleganza(encryptData(AuthStates.NO2FA)));

      setTimeout(() => {
        if (response?.country === 'Nigeria' && isPersonalAccount) {
          router.push(ONBOARDING_ROUTES.TIERED_ONBOARDING.CONTACT_INFORMATION)
        } else {
          router.push(DASHBOARD_ROUTES.DASHBOARD)
        }
      }, 1500);
      setRegLoading(false);
    } catch (error) {
      handleRequestError(error);
      setRegLoading(false);
      return error;
    }
  };

  const getIsLoggedIn = (): boolean => {
    const lsLoggedIn = localStorage.getItem('isLoggedIn') === 'yes' ? true : false;

    return isLoggedIn || lsLoggedIn;
  };

  const loginUser = async (values: any) => {
    setLoginLoading(true);
    try {
      const { data } = await axiosInstance.post('login', values);

      const response = data?.content?.data;
      const mainIsntVerified = response?.enduser[0]?.emailVerify === 0;
      const userInfo = response?.enduser[0];

      const contactIsComplete = userInfo?.address?.length > 0
        && userInfo?.city?.length > 0
        && userInfo?.state?.length > 0

      await setupFCM(response?.enduser[0]?.userId);
      dispatch(setUser(response));

      const userAuth = response?.authenticator
      const twoFaAvailable = userAuth?.find((item: any) => item.type === AuthTypes.TWO_FA)?.status === 1

      const emailFaAvailable = userAuth?.find((item: any) => item.type === AuthTypes.EMAIL)?.status === 1
      //if main is verified then set the local storage for eleganza
      if (!mainIsntVerified) {
        if (twoFaAvailable || emailFaAvailable) {
          dispatch(setEleganza(encryptData(AuthStates.HAS2FA)))
        }
        else {
          dispatch(setEleganza(encryptData(AuthStates.NO2FA)))
        }

      }
      toastSuccess('Login Successful');

      setTimeout(
        () => router.push(
          mainIsntVerified ? AUTH_ROUTES.VERIFY_EMAIL
            : (twoFaAvailable || emailFaAvailable) ? AUTH_ROUTES.TWOFA
              : !contactIsComplete ? ONBOARDING_ROUTES.TIERED_ONBOARDING.CONTACT_INFORMATION
                : DASHBOARD_ROUTES.DASHBOARD),
        1500
      );
      setLoginLoading(false);
      dispatch(deleteReferralLink());
    } catch (error) {
      const isSuspended = (error as any)?.response?.data?.message?.toLowerCase('suspended');
      handleRequestError(error, {
        useCustomMessage: !(error as any)?.response?.data?.message?.toLowerCase('suspended'),
        customMessage: 'Unable to Sign In'
      });
      setLoginLoading(false);
      return error;
    }
  };

  // generate password reset link
  const [linkLoading, setLinkLoading] = useState(false);

  const sendPwdResetLink = async (values: { email: string }) => {
    setLinkLoading(true);
    try {
      const data = await axiosInstance.post('forgetpassword', values);
      toastSuccess(data?.data?.message);
      setTimeout(() => router.push(AUTH_ROUTES.RESET_PASSWORD), 1500);
      setLinkLoading(false);
    } catch (error) {
      handleRequestError(error);
      setLinkLoading(false);
      return error;
    }
  };

  // save new password
  const [saveLoading, setSaveLoading] = useState(false);
  const saveNewPasword = async (values: { password: string; code: string }) => {
    setSaveLoading(true);
    try {
      const data = await axiosInstance.post('forgetpassword/confirm', values);
      toastSuccess(data?.data?.message);
      setTimeout(() => router.push(AUTH_ROUTES.SIGN_IN), 1500);
      setSaveLoading(false);
    } catch (error) {
      handleRequestError(error);
      setSaveLoading(false);
      return error;
    }
  };

  // resend verification email
  const [rsndLoading, setRsndLoading] = useState(false);
  const resendVerifMail = async (userId: any, ignoreToast?: boolean,) => {
    setRsndLoading(true);
    try {
      await axiosInstance.post('resendEmailVerify', {
        userId
      });
      if (!ignoreToast) toastSuccess(`Verification code resent to ${userEmail}`);
      setRsndLoading(false);
    } catch (error) {
      if (!ignoreToast) handleRequestError(error);
      setRsndLoading(false);
      return error;
    }
  };

  // create transaction pin
  const createPinFn = async (pin: string, hideState?: boolean) => {
    try {
      const payload = {
        userId,
        pin
      };
      const { data } = await axiosInstance.post('pin', payload);
      dispatch(pinSetupComplete());
      if (!hideState) {
        toastSuccess('Transaction pin setup');
        setTimeout(() => router.push(createPinNavigator.redirectRoute), 900);
      }
    } catch (error) {
      if (!hideState) {
        handleRequestError(error);
        return error;
      } else throw error;
    }
  };
  const useCreatePin = () => useMutation(createPinFn);

  // change transaction pin
  const changePinFn = async (payload: any) => {
    try {
      const { data } = await axiosInstance.patch('pin', payload);
      toastSuccess(data?.message);
      setTimeout(() => router.push(DASHBOARD_ROUTES.DASHBOARD), 1500);
    } catch (error) {
      handleRequestError(error);
      return error;
    }
  };
  const useChangePin = () => useMutation(changePinFn);

  return {
    saveLoading,
    saveNewPasword,
    sendPwdResetLink,
    linkLoading,
    resendVerifMail,
    rsndLoading,
    loginLoading,
    loginUser,
    regLoading,
    registerUser,
    confirmVerification,
    useCreatePin,
    useChangePin,
    setupFCM,
    createPinFn,
    getIsLoggedIn,
    isLoggedIn
  };
}

export default useAuth;
