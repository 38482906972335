import { Tooltip } from "antd";
import Flag from "components/common/Flag";
import { IoIosArrowDropright } from "react-icons/io";
import { toggleTopupModal } from "redux/slices/dashboardSlice";
import { useToolkit } from "../../components";

type Props = {
	acct: any;
};

const VirtualAccountCard = ({ acct }: Props) => {
	const { dispatch } = useToolkit();

	return (
		acct?.accountNo?.length && (
			<div className="w-full md:w-[48%] lg:w-1/4 bwite shadow-md rounded-lg border border-[#EEEEEE] mb-9 lg:mb-0 p-4 mx-auto lg:mx-0 flect justify-between space-x-4">
				<div className="flex flex-col">
					<div className="flect space-x-3 mb-4">
						<Flag currency={acct?.cur} />
						<p className="t2 twide mb-0">{acct?.cur} Account</p>
					</div>
					<div>
						<div className="flex-btw space-x-3 w-full mb-2">
							<Tooltip title={acct?.accountNo}>
								<p className="mb-0 found-blk t4 smb">
									{acct?.accountNo}
									{acct?.accountNo?.length > 10 && "***"}
									&nbsp;
								</p>
							</Tooltip>
							<IoIosArrowDropright
								onClick={() => dispatch(toggleTopupModal())}
								className="text-2xl curp text-[#6B737A]"
							/>
						</div>
						<p className="mb-2 found-blk t4 twide">{acct?.bankName}</p>
					</div>
				</div>
			</div>
		)
	);
};

export default VirtualAccountCard;
