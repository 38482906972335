import { Dropdown } from "antd";
import { GrDown } from "react-icons/gr";
import useToolkit from "../../utils/hooks/misc/useToolkit";
import { FEATURE_ROUTES, LANDING_ROUTES } from "utils/appData/appRoutes";
import { toggleMobileNav } from "redux/slices/layoutSlice";

type FeaturesDropdownProps = {
	hasIcon?: boolean;
	isForMobile?: boolean;
	darkText?: boolean;
};

const FeaturesDropdown = ({
	hasIcon,
	isForMobile,
	darkText,
}: FeaturesDropdownProps) => {
	const { router, toastInfo, isMobile, dispatch } = useToolkit();

	const featureItems: {
		title: string;
		subtitle: string;
		imgPath: string;
		route?: string;
	}[] = [
		{
			title: "Transfers",
			subtitle: "Send money to anyone, locally and globally",
			imgPath: "/assets/features/transfers.png",
			route: FEATURE_ROUTES.BANK_TRANSFER,
		},
		{
			title: "Withdrawals",
			subtitle: "Withdraw funds anytime, anywhere; easily and quickly",
			imgPath: "/assets/features/withdrawals.png",
			route: FEATURE_ROUTES.WITHDRAWALS,
		},
		{
			title: "Virtual Accounts",
			subtitle: "Withdraw funds anytime, anywhere easily and quickly",
			imgPath: "/assets/features/virtwallets.png",
			route: FEATURE_ROUTES.VIRTUAL_ACCOUNTS,
		},
		{
			title: "Virtual Cards",
			subtitle: "Withdraw funds anytime, anywhere easily and quickly",
			imgPath: "/assets/features/virtcards.png",
			route: FEATURE_ROUTES.VIRTUAL_CARDS,
		},
		{
			title: "Conversions",
			subtitle: "Money conversion made easy for you",
			imgPath: "/assets/features/conversions.png",
			route: FEATURE_ROUTES.CONVERSIONS,
		},
		// {
		// 	title: "Remittance",
		// 	subtitle: "Withdraw funds anytime, anywhere easily and quickly",
		// 	imgPath: "/assets/features/remittance.png",
		// },
	];

	const hoverMenu = () => (
		<div
			style={{ zIndex: "999" }}
			className={`hover-menu px-8 py-5 lg:mr-8 flex items-center justify-between flex-wrap space-y-4 lg:space-y-0 mx-auto bwite ${
				isForMobile
					? "w-full absolute mt-[20px]"
					: "mt-3 w-3/4 md:w-2/4 lg:w-[52%]  shadow-2xl rounded-lg"
			}`}>
			{featureItems.map(({ title, subtitle, imgPath, route }) => (
				<div
					onClick={() => {
						if (route) {
							if (isMobile) {
								dispatch(toggleMobileNav());
								router.push(route);
							} else {
								router.push(route);
							}
						} else {
							toastInfo();
						}
					}}
					className="flex items-center space-x-3.5 w-full md:w-2/5 lg:w-[46%] curp mys-2 py-2.5 group"
					key={imgPath}>
					<img
						src={imgPath}
						alt="icon"
						width={30}
						height={30}
						className="group-hover:scale-110 transition-all duration-250"
					/>
					<div className="">
						<h5 className="mb-0 t6 blk med group-hover:text-[#035C83] transition-colors duration-250">
							{title}
						</h5>
						<p className="mb-0 text-xs blk tracking-wide">{subtitle}</p>
					</div>
				</div>
			))}
		</div>
	);

	return (
		<Dropdown
			className={isForMobile ? "relative " : ""}
			overlay={hoverMenu}
			trigger={["click"]}
			placement="bottom">
			<span
				className={`curp  t6 tracking-wide hover:opacity-80 trall med ${
					darkText
						? "blk med"
						: isForMobile
						? "softext w-full flect"
						: "text-white"
				}`}>
				Features {isForMobile && <GrDown className="text-lg ml-auto" />}
			</span>
		</Dropdown>
	);
};

export default FeaturesDropdown;
