import { KycButton, QwidButton, Spinner, useKyc, useToolkit } from "components";
import React from "react";
import { AUTH_ROUTES, DASHBOARD_ROUTES } from "utils/appData/appRoutes";

type Props = {
	imagePath: string;
	title: string;
	subtitle?: string;
	list: string[];
};

const KycLayout = ({ imagePath, title, subtitle, list }: Props) => {
	const { HeadEl, router, useAppSelector, isValidUser, logout, isMobile } =
		useToolkit();
	const { kycLoading, kycMsg } = useKyc();
	const { isKycLoading } = useAppSelector((state) => state.layout);

	const wrapClass = "w-full md:w-2/4 flexed min-h-full flexed p-8 lg:p-12";

	const LogoDiv = () => (
		<div
			className="curp lg:p-7"
			onClick={() => router.push(DASHBOARD_ROUTES.DASHBOARD)}>
			<img src="/assets/landing/qwidLogo.svg" alt="logo" />
		</div>
	);

	if (!isValidUser) {
		router.push(AUTH_ROUTES.SIGN_IN);
	}

	return !isValidUser ? (
		<Spinner msg="Redirecting" />
	) : (
		<>
			<HeadEl isLanding isKycPage />
			<section className="flex flex-wrap md:flex-nowrap min-h-screen bg-white relative">
				<div
					style={{
						background:
							"linear-gradient(180deg, #D6EEF3 -22.9%, rgba(255, 255, 255, 0) 117.77%)",
					}}
					className="hidden lg:flex flex-col w-full md:w-2/4">
					<LogoDiv />
					<div className="h-full flexed">
						<img
							src={imagePath}
							alt="misc"
							className="object-cover"
							width={400}
						/>
					</div>
				</div>
				<div className={wrapClass}>
					{kycLoading || isKycLoading ? (
						<Spinner msg={kycMsg} />
					) : (
						<div className="w-full h-full lg:h-max">
							<div className="lg:hidden mb-20 flex justify-between">
								<LogoDiv />
								{isMobile && (
									<QwidButton text="Logout" onClick={() => logout()} />
								)}
							</div>
							{!isMobile && (
								<div className="absolute right-7 top-7">
									<QwidButton text="Logout" onClick={() => logout()} />
								</div>
							)}

							<p className="mb-5 smb twide text-xl lg:text-3xl">{title} </p>
							{subtitle && <p className="mb-3 text-justify">{subtitle}</p>}
							<div className="flex items-center justify-between lg:w-3/4 mx-auto mt-10">
								<img src="/assets/dashboard/kyc-left.svg" alt="left" />
								<img src="/assets/dashboard/kyc-mid.svg" alt="left" />
								<img src="/assets/dashboard/kyc-right.svg" alt="left" />
							</div>
							<div className="flex flex-col my-10">
								<ul>
									{list.map((item) => (
										<li key={item} className="med twide lg:text-[16px] mb-3">
											&#8226; &nbsp;{item}
										</li>
									))}
								</ul>
							</div>
							<div className="flexed w-full">
								<KycButton />
							</div>
						</div>
					)}
				</div>
			</section>
		</>
	);
};

export default KycLayout;
