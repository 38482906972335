import UpdateProfileModal from "components/settings/UpdateProfileModal";
import { IdleTimerProvider } from "react-idle-timer";
import { toggleLogoutModal } from "redux/slices/layoutSlice";
import { useDispatch } from "react-redux";
import {
	hideProfileCancelBtn,
	openProfileModal,
	toggleBusinessUpdateModal,
	toggleCreateBankModal,
} from "redux/slices/settingsSlice";
import styled from "styled-components";
import { AUTH_ROUTES, ONBOARDING_ROUTES } from "utils/appData/appRoutes";
import {
	BoardNav,
	BoardSidebar,
	useToolkit,
	Spinner,
	DashboardMobileMenu,
	AutoLogoutModal,
	UpdateBusinessDetModal,
	useKyc,
	AddBankDetailsModal,
	WithdrawalModal,
	QwidTransferModal,
	useUser,
} from "../../components";
import { Dispatch, useMemo } from "react";
import { setKycDocumentsStatus } from "redux/slices/userSlice";
import { KYCStatus } from "../../custom-types";
import { useCallback } from "react";
import React from "react";
import { AuthStates } from "components/auth/TwoFactorAuth";

type BoardLayoutProps = {
	children: React.ReactNode;
	title?: string | React.ReactNode;
	action?: string | React.ReactNode;
	noSidebar?: boolean;
	setKycState?: Dispatch<React.SetStateAction<KYCStatus>>;
	triggers?: any;
};

function BoardLayout({
	children,
	title,
	action,
	noSidebar,
	setKycState,
	triggers
}: BoardLayoutProps) {
	const {
		HeadEl,
		useAppSelector,
		router,
		isValidUser,
		dispatch,
		isStagingEnv,
		useEffect,
		userObj,
		isBusinessAccount,
		businessInfoObj,
		isLocalEnv,
		plainUserInfo,
		decryptData,
		logout,
		toastError
	} = useToolkit();

	const { verifyUserKyc, useGetNotifications, determineUserKycStatus } =
		useKyc();
	const { getUserDetails, detailsLoading } = useUser();
	const { isSidebarOpen, isLogoutModalOpen } = useAppSelector(
		(state) => state.layout
	);

	const { isProfileModalOpen, isBusinessDetModalOpen, isCreateBankModalOpen } =
		useAppSelector((state) => state.settings);

	const { refetch: getNotifications } = useGetNotifications();

	const { isWithdrawalModalOpen, isQwidTrfOpen } = useAppSelector(
		(state) => state.dashboard
	);
	const { eleganza, mollusca } = useAppSelector((state) => state.user)

	const detailsLoaded = detailsLoading === false;

	const verifyUserStatus = useCallback(async () => {
		if (detailsLoading === false && userObj?.emailVerify === 0)
			router.push(AUTH_ROUTES.VERIFY_EMAIL);
		if (
			(detailsLoading === false && userObj?.emailVerify === 0) ||
			(detailsLoading === false && userObj?.employmentStatus === null) ||
			(detailsLoading === false && userObj?.firstName === "") ||
			(detailsLoading === false && userObj?.dob === "")
		) {
			// dispatch(openProfileModal());
			// dispatch(hideProfileCancelBtn());
		}

		if (
			detailsLoaded &&
			isBusinessAccount &&
			!businessInfoObj?.businessProductType
		) {
			// dispatch(toggleBusinessUpdateModal());
		}

		if (isValidUser) {
			let verifyKycData = await verifyUserKyc();
			
			const kycState = determineUserKycStatus(verifyKycData);
			/* const documentsUploaded = verifyKycData?.kycList?.every(
				(doc: Record<string, boolean>) => doc?.upload === true
			); */
			// set kyc docs status
			dispatch(
				setKycDocumentsStatus({
					kycStatus: kycState,
				})
			);
			// set kyc status
			if (setKycState) {
				setKycState(kycState);
			}
		}
	}, [isValidUser]);

	useEffect(() => {
		verifyUserStatus();
	}, []);

	useEffect(() => {
		if (!plainUserInfo) getUserDetails();
	}, []);

	const onPrompt = () => {
		if (!isLocalEnv && !isStagingEnv && isCreateBankModalOpen === true) {
			dispatch(toggleCreateBankModal());
		}
		return isLocalEnv || isStagingEnv ? null : dispatch(toggleLogoutModal());
	};

	const onIdle = () => null;

	if (!isValidUser) {
		router.push(AUTH_ROUTES.SIGN_IN);
	}

	const has2fa = eleganza && decryptData(eleganza) === AuthStates.HAS2FA
	const no2fa = eleganza && decryptData(eleganza) === AuthStates.NO2FA
	const verified2fa = mollusca ? decryptData(mollusca) === AuthStates.VERIFIED2FA : undefined;

	const page = (
		<>
			<HeadEl isDashboard />
			<Main>
				{!noSidebar && <BoardSidebar />}
				<div className="w-full flex flex-col">
					<BoardNav noSidebar={noSidebar} />
					<section className="w-full ovs">
						<IdleTimerProvider
							timeout={180000}
							promptTimeout={120000}
							onPrompt={onPrompt}
							onIdle={onIdle}>
							<div className="body-wrap p-5 lg:p-7 w-full relative">
								<div className="mx-auto w-full relative">
									<div className="mb-5 flex justify-between items-center">
										<div className="mb-0 text-lg med twide w-full">{title}</div>
										<>{action}</>
									</div>
									{children}
								</div>
							</div>
						</IdleTimerProvider>
					</section>
				</div>
			</Main>
			{isWithdrawalModalOpen && <WithdrawalModal />}
			{isQwidTrfOpen && <QwidTransferModal />}
			{isProfileModalOpen && <UpdateProfileModal />}
			{isBusinessDetModalOpen && <UpdateBusinessDetModal />}
			{isCreateBankModalOpen && <AddBankDetailsModal />}
			{isSidebarOpen && <DashboardMobileMenu />}
			{isLogoutModalOpen && <AutoLogoutModal />}
		</>
	);
	const checkAndDisplay = useMemo(() => {
		if (has2fa) {
			//check verified
			if (verified2fa) {
				return page
			}
			else {
				router.push(AUTH_ROUTES.TWOFA);
				return ""
			}
		}
		else if (no2fa) {
			//render paage
			return page
		}
		else {
			//log user out but say there has been a data breach
			//toastError('Seems to be an error with your authentication')
			logout()
			return ""
		}
		// eslint-disable-next-line
	}, [has2fa, verified2fa, no2fa, triggers, isValidUser])

	return !isValidUser ? (
		<Spinner msg="Redirecting" />
	) : checkAndDisplay as JSX.Element
}

export default React.memo(BoardLayout);

const Main = styled.main`
	min-height: 100vh;
	display: flex;
	background: #fbfbfb;

	.body-wrap {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
`;
