import React from "react";

type Props = {
	number?: number;
	title: string;
	isActionButton?: boolean;
	onClick?: () => void;
};

const FormStep = ({ number, title, isActionButton, onClick }: Props) => {
	return isActionButton ? (
		<div onClick={onClick} className="wallet-tabs px-3">
			<img
				className="w-4 md:w-6"
				src="/assets/icons/wallet/add.svg"
				alt="add"
			/>
			<span className="text-[#000000] text-xs font-normal">{title}</span>
		</div>
	) : (
		<div className="my-8 flex items-center space-x-3">
			<div className="h-7 w-7 rounded-full flexed pry-bg t4 med twite">
				<p className="mb-0">{number}</p>
			</div>
			<p className="mb-0 med twide t6">{title}</p>
		</div>
	);
};

export default FormStep;
