import { QwidButton, useFramer, useToolkit } from "../../../components";
import { motion } from "framer-motion";
import { AUTH_ROUTES } from "utils/appData/appRoutes";

type Props = {};

const DirectPaymentsWorldWide = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();
	const { router, logout, isValidUser } = useToolkit();

	return (
		<div className="bwite py-8 lg:py-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper lg:items-start">
				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-9 btn-text">PAY-IN AND PAYOUT</p>
					<p className="mb-0 smb text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						Make direct payments to countries worldwide
					</p>

					<p className="my-7 text-center lg:t6 lg:text-left">
						Fund your Qwid wallet and convert your money to whatever currency
						you need to keep your business thriving. We help you make secure and
						convenient payments to anywhere from China to Brazil to Canada and
						much more
					</p>

					<QwidButton
						text={<span className="px-4">Get started</span>}
						onClick={() => {
							if (isValidUser) {
								logout();
							}
							router.push(AUTH_ROUTES.SIGN_UP);
						}}
					/>
				</motion.div>

				<motion.div
					variants={flashIn}
					className="bg-[#E3F3FA] rounded-lg w-full md:w-2/4 lg:w-2/4 h-[500px] md:h-[550px] flex items-start justify-center relative">
					<div className="w-3/4 -bottom-1.5 mx-auto flex justify-center items-end h-full">
						<img
							alt="how-to-send"
							src="/assets/business/direct-payments.svg"
							className="object-cover lg:h-[400px]"
						/>
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default DirectPaymentsWorldWide;
