import QwidButton from "components/misc/QwidButton";

const PinSetupUI = () => {
	return (
		<div className="my-7 lg:my-10 flex-btw lnwrap items-start w-full py-5">
			<div className="w-full lg:w-3/4">
				<p className="mb-2 med twide text-lg lg:text-xl">
					Introducing online payments with qwid!
				</p>

				<p className="twide text-[#626663] w-4/5">
					You can now make online payments using your qwid wallet on your
					favourite websites such as Peace Air, Ebonylife and lots more. All you
					have to do is set up your online payment PIN.
				</p>

				<div className="flect space-x-6 mt-8">
					<QwidButton
						onClick={() => {}}
						text={<span className="px-4">Set up PIN</span>}
					/>

					<h5 className="pry-text text-sm curp nowrap">How it works</h5>
				</div>
			</div>

			<div className="w-full lg:w-1/4 hidden lg:inline-flex justify-end">
				<img src="/assets/dashboard/setup-pin.svg" alt="pin" />
			</div>
		</div>
	);
};

export default PinSetupUI;
