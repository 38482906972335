import { Row, Skeleton } from 'antd';
import {
  QwidButton,
  Spinner,
  useFileUploader,
  useKyc,
  useToolkit,
  useUser,
} from 'components';
import { useState } from 'react';
import { AiFillEdit, AiOutlineEdit } from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';
import { toggleProfileModal } from 'redux/slices/settingsSlice';
import { ONBOARDING_ROUTES } from 'utils/appData/appRoutes';
import EditProfile from './EditProfile';
import React from 'react';

const Profile = () => {
  const {
    useAppSelector,
    dispatch,
    useEffect,
    user,
    userEmail,
    userObj,
    avail,
    isPersonalAccount,
    isBusinessAccount,
    router,
  } = useToolkit();

  const { detailsLoading, picLoading, updateProfilePic, getUserDetails } =
    useUser();
  const [editMode, setEditMode] = useState(false);
  const handleEditProfile = () => {
    const url = isPersonalAccount
      ? ONBOARDING_ROUTES.PERSONAL.PERSONAL_INFO
      : ONBOARDING_ROUTES.BUSINESS.BUSINESS_FORM;
    router.push(url);
  };
  const { isFirstTimeUser } = useAppSelector((state) => state.settings);
  const { kycLoading, getKycStatus, kycStatus: status } = useKyc();
 useEffect(()=>{
  getKycStatus()
 },[])
  
  const {
    handleFileChange,
    profilePic,
    fileRef,
    onClick,
    fileProps,
    btnActive,
  } = useFileUploader('prop');

  const updatePic = () => {
    const payload = {
      pix: fileProps.string,
      extension: fileProps.extension,
    };
    updateProfilePic(payload);
  };

  const ProfilePicContainer = (props: {
    buttonActive: boolean;
    shouldDisplayProfile: boolean;
  }) => (
    <div>
      <input
        ref={fileRef}
        style={{ display: 'none' }}
        type="file"
        accept=".jpg,.png,.jpeg"
        onChange={handleFileChange}
      />
      {props.shouldDisplayProfile ? (
        <div
          onClick={onClick}
          className="flexed h-14 w-14 rounded-full curp relative group"
        >
          <img
            src={profilePic}
            className="object-cover min-h-full rounded-full"
            alt="profile-pic"
          />
          <div className="absolute inset-0 items-center justify-center bg-opacity-70 hidden group-hover:flex cursor-pointer pointer-events-none bg-[#000] rounded-full text-white text-xl">
            <AiOutlineEdit className="text-base cursor-pointer pointer-events-none" />
          </div>
        </div>
      ) : (
        <div
          onClick={onClick}
          className="bg-[#ECE9E9] flexed h-14 w-14 rounded-full curp relative group"
        >
          <BiUserCircle className="text-3xl" />
          <div className="absolute inset-0 items-center justify-center bg-opacity-70 hidden group-hover:flex cursor-pointer pointer-events-none bg-[#000] rounded-full text-white text-xl">
            <AiOutlineEdit className="cursor-pointer pointer-events-none" />
          </div>
        </div>
      )}

      {/* <div className="flexed mt-6">
				<QwidButton
					onClick={updatePic}
					disabled={!props.buttonActive}
					text={<span className="px-2">Save</span>}
				/>
			</div> */}
    </div>
  );

  useEffect(() => {
    getUserDetails();
  }, []);

  //const WRAPPER_CLASS = 'w-full md:w-max flex flex-col space-y-5';
  const Details = ({
    value,
    title,
  }: {
    value?: string | null;
    title: string;
  }) =>
    value ? (
      <div>
        <p className="item-label">{title}</p>
        <p className="item-value capitalize">{value}</p>
      </div>
    ) : null;

  return detailsLoading /* || isFirstTimeUser */ || !userEmail?.length ? (
    <Skeleton active />
  ) : picLoading ? (
    <Spinner msg="Saving image" />
  ) : (
    <>
      <div className="flex flex-col bg-white rounded-[1rem] border-[#EDEDED] border-[1px] px-4 sm:px-8 py-5 sm:py-8">
        {editMode ? (
          <EditProfile userObj={userObj} backFxn={() => setEditMode(false)} />
        ) : (
          <section>
            <section className="flex justify-between w-full items-end mb-[2rem]">
              <div className="flex w-full flex-row-reverse sm:flex-row justify-between sm:justify-normal gap-4 items-center">
                <ProfilePicContainer
                  buttonActive={btnActive}
                  shouldDisplayProfile={user?.enduser && profilePic}
                />
                <div className="flex flex-col gap-1">
                  <p className="text-[1.5rem] font-medium m-0">
                    {`${userObj?.firstName} ${userObj?.lastName}`}{' '}
                  </p>
                  <div className="flex gap-5 items-center">
                    <p className="m-0">{userObj?.email}</p>
                    <p className="font-medium hidden sm:flex m-0 text-[#194153]">
                      {isPersonalAccount
                        ? 'Personal account'
                        : isBusinessAccount
                        ? 'Business account'
                        : 'N/A'}
                    </p>
                    {status === 'kyc done' &&
                    <div className="hidden sm:flex text-[0.8rem] text-[#336F2E] bg-[#E5F7E3] border-[1px] border-[#17C509] py-[0.1rem] px-2 rounded-[3rem]">
                      Verified
                    </div>}
                  </div>
                  <p className="font-medium flex w-min text-[0.8rem] mt-2 whitespace-nowrap bg-[#EBF7FF] rounded-[4rem] px-2 py-1 sm:hidden m-0 text-[#368CB2]">
                    {isPersonalAccount
                      ? 'Personal account'
                      : isBusinessAccount
                      ? 'Business account'
                      : 'N/A'}
                  </p>
                </div>
              </div>
              {Array.isArray(user?.enduser) &&
                user?.enduser.length > 0 &&
                !isBusinessAccount &&
                user?.enduser[0]?.country !== 'Nigeria' && (
                  <div
                    onClick={() => setEditMode(true)}
                    className="text-[#3A96BE] cursor-pointer gap-3 hidden sm:flex"
                  >
                    <AiFillEdit className="!w-5 !h-5" /> Edit
                  </div>
                )}
            </section>
            <p className="font-semibold hidden sm:flex text-[16px] mb-[2rem]">
              Personal Information
            </p>
            <div className="flex sm:hidden justify-between mb-6 items-center">
              <div className=" text-[0.8rem] text-[#336F2E] bg-[#E5F7E3] border-[1px] border-[#17C509] py-[0.1rem] px-2 rounded-[3rem]">
                Verified
              </div>
              {Array.isArray(user?.enduser) &&
                user?.enduser.length > 0 &&
                user?.enduser[0]?.country !== 'Nigeria' && (
                  <div
                    onClick={() => setEditMode(true)}
                    className="text-[#194153] p-[0.2rem] text-[0.8rem] rounded-[8px] whitespace-nowrap flex bg-[#E4F5FC] items-center px-2 cursor-pointer  gap-1 "
                  >
                    <AiFillEdit className="!w-4 !h-4 " /> Edit
                  </div>
                )}
            </div>
            <aside className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[1.2rem] gap-y-[1rem] sm:gap-y-[2.5rem] w-full [&>div]:flex [&>div]:flex-row [&>div]:sm:flex-col [&>div]:justify-between [&>div]:sm:gap-0 [&>div]:gap-4 ">
              <Details value={userObj?.dob} title="Date of Birth" />
              <Details
                value={`${userObj?.mobileCode}${userObj?.mobile}`}
                title="Mobile"
              />
              {(userObj?.city || userObj?.state) && (
                <Details
                  value={`${userObj?.city}, ${userObj?.state}`}
                  title="City / State"
                />
              )}
              <Details value={userObj?.zipcode} title="Zip / Postal code" />
              <Details value={userObj?.sex || userObj?.gender} title="Gender" />
              <Details
                value={`${
                  userObj?.house_number ? `${userObj?.house_number} ` : ''
                }${userObj?.address}`}
                title="Address"
              />
              <Details value={userObj?.country} title="Country" />
              <Details
                value={userObj?.employmentStatus}
                title="Employment Status"
              />
              <Details value={userObj?.occupation} title="Occupation" />
              <Details value={userObj?.sourceOfFund} title="Source of Funds" />
            </aside>
          </section>
        )}
      </div>
    </>
  );
};

export default Profile;
