import { Form, Popover, Steps, StepsProps } from "antd";
import CustomSelector from "../../../components/common/CustomSelector";
import {
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import CustomInputField from "../../../components/common/CustomInputField";
import QwidButton from "../../../components/misc/QwidButton";
import useSendFunds, {
	BankField,
} from "../../../utils/hooks/dashboard/useSendFunds";
import useToolkit, {
	BeneficiaryType,
} from "../../../utils/hooks/misc/useToolkit";
// Todo: Move after refactoring
export type Props = {
	formFields?: {
		fieldName: string;
		title: string;
		required: boolean;
		dependency: string[] | null;
	}[];
	onSubmit: (values: any) => void;
	goBack: () => void;
	bankList: Record<string, any>[];
	setBankList: (value: any) => void;
	country: string;
	cur: string;
	category: string;
	beneficiaryType: string;
	inputDump: any;
	setInputDump: (value: any) => void;
	setGetBanksFailed: (value: boolean) => void;
	getBankFailed: boolean;
	prevPage: number;
	currentPage: number;
	selectedBankCode: string;
	setSelectedBankCode: (value: any) => void;
};
const BeneficaryBankForm = ({
	formFields,
	onSubmit,
	goBack,
	bankList,
	country,
	cur,
	category,
	setBankList,
	beneficiaryType,
	inputDump,
	setInputDump,
	selectedBankCode,
	setSelectedBankCode,
	prevPage,
	currentPage,
}: Props) => {
	const accountTypes = [
		{ value: 1, title: "Checkings" },
		{ value: 2, title: "Savings" },
		{ value: 3, title: "Ordinary" },
	];
	const hasPaintedRef = useRef<boolean>(false);
	const { getTransactionType } = useToolkit();
	const {
		getBeneficiaryBanks,
		fetchingBanks,
		useVerifyBankDetail,
		useVerifyNigeriaAccountNo,
	} = useSendFunds();
	const { mutateAsync: verifyBankDetail } = useVerifyBankDetail;
	const { mutateAsync: verifyNGAccountNo } = useVerifyNigeriaAccountNo;
	const [form] = Form.useForm();
	const handleSubmit = (values: any) => {
		onSubmit(values);
	};
	const [validatedFields, setValidatedFields] = useState({
		accountNo: {
			loading: false,
			validationStatus: "",
		},
		iban: {
			loading: false,
			validationStatus: "",
		},
		swift: {
			loading: false,
			validationStatus: "",
		},
		routine: {
			loading: false,
			validationStatus: "",
		},
	});
	const onBlurChange = (e: any, fieldName: string) => {
		if (
			fieldName === "accountNo" &&
			country === "NG" &&
			cur === "NGN" &&
			inputDump["bankCode"] &&
			e.target.value
		) {
			setValidatedFields((v) => ({
				...v,
				accountNo: {
					validationStatus: "",
					loading: true,
				},
			}));
			verifyNGAccountNo({
				bankCode: inputDump["bankCode"] as string,
				accountNo: e.target.value,
			})
				.then((res) => {
					const name = res?.accountName;
					if (name) {
						setInputDump((val: Record<string, string>) => ({
							...val,
							accountName: name,
						}));
					}
					setValidatedFields((v) => ({
						...v,
						accountNo: {
							validationStatus: "success",
							loading: false,
						},
					}));
				})
				.catch(() => {
					setValidatedFields((v) => ({
						...v,
						accountNo: {
							validationStatus: "error",
							loading: false,
						},
					}));
				});
		} else if (fieldName === "iban" && e.target.value) {
			setValidatedFields((v) => ({
				...v,
				iban: {
					validationStatus: "",
					loading: true,
				},
			}));
			verifyBankDetail({ mode: BankField.IBAN, account: e.target.value })
				.then((res) => {
					setValidatedFields((v) => ({
						...v,
						iban: {
							validationStatus: "success",
							loading: false,
						},
					}));
				})
				.catch(() => {
					setValidatedFields((v) => ({
						...v,
						iban: {
							validationStatus: "warn",
							loading: false,
						},
					}));
				});
		} else if (fieldName === "swift" && e.target.value) {
			setValidatedFields((v) => ({
				...v,
				swift: {
					validationStatus: "",
					loading: true,
				},
			}));
			verifyBankDetail({ mode: BankField.SWIFT, account: e.target.value })
				.then((res) => {
					setValidatedFields((v) => ({
						...v,
						swift: {
							validationStatus: "success",
							loading: false,
						},
					}));
				})
				.catch(() => {
					setValidatedFields((v) => ({
						...v,
						swift: {
							validationStatus: "warn",
							loading: false,
						},
					}));
				});
		} else if (fieldName === "routine" && e.target.value) {
			setValidatedFields((v) => ({
				...v,
				routine: {
					validationStatus: "",
					loading: true,
				},
			}));
			verifyBankDetail({ mode: BankField.ROUTINE, account: e.target.value })
				.then((res) => {
					setValidatedFields((v) => ({
						...v,
						routine: {
							validationStatus: "success",
							loading: false,
						},
					}));
				})
				.catch(() => {
					setValidatedFields((v) => ({
						...v,
						routine: {
							validationStatus: "warn",
							loading: false,
						},
					}));
				});
		}
	};

	const handleBankSelection = (e: any) => {
		const bankCode = bankList.find(
			(bank) => bank.bankName === e.target.value
		)?.bankCode;
		const data: any = {
			bankName: e.target.value,
		};
		if (bankCode) {
			data.bankCode = bankCode;
		}
		if (
			country === "NG" &&
			cur === "NGN" &&
			inputDump["accountNo"] &&
			bankCode
		) {
			setValidatedFields((v) => ({
				...v,
				accountNo: {
					validationStatus: "",
					loading: true,
				},
			}));
			verifyNGAccountNo({
				bankCode: bankCode as string,
				accountNo: inputDump["accountNo"],
			})
				.then((_res) => {
					setValidatedFields((v) => ({
						...v,
						accountNo: {
							validationStatus: "success",
							loading: false,
						},
					}));
				})
				.catch(() => {
					setValidatedFields((v) => ({
						...v,
						accountNo: {
							validationStatus: "error",
							loading: false,
						},
					}));
				});
		}
		setInputDump((val: any) => ({
			...val,
			...data,
		}));
		if (bankCode) setSelectedBankCode(bankCode);
	};

	const formattedBankList = useMemo(() => {
		return (
			bankList?.map((bank: any) => {
				const res: { value: string; title: string; selected?: boolean } = {
					value: bank.bankName,
					title: bank.bankName,
				};
				if (bank.bankName === inputDump?.bankName) {
					res.selected = true;
				}
				return res;
			}) || []
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bankList]);

	useEffect(() => {
		if (!bankList?.length && prevPage <= currentPage) {
			getBeneficiaryBanks({
				country,
				cur,
				category,
				transactionType: 'C2C' || getTransactionType(beneficiaryType as BeneficiaryType) // set to C2C by default for now as required by Taofik,
			}).then((data) => {
				const uniqueBanks = data?.filter(
					(bank: any, index: number) =>
						index === data?.findIndex((v: any) => v.bankName === bank.bankName)
				);
				setBankList(uniqueBanks);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasPaintedRef, bankList?.length]);

	const groupNodes = (arr: ReactNode[]) => {
		let isOdd = false;
		const groupedArray = [];
		let subarray = [];
		let count = 0;
		for (const element of arr) {
			subarray.push(element);
			if (subarray.length === 2) {
				count++;
				const row = (
					<div
						key={count}
						className="flex flex-col xl:space-x-1 xl:gap-4 xl:flex-row justify-between">
						{subarray}
					</div>
				);
				groupedArray.push(row);
				subarray = [];
			}
		}
		if (subarray.length > 0) {
			isOdd = true;
			count++;
			const row = (
				<div
					key={count}
					className="flex flex-col xl:gap-4  xl:flex-row justify-between">
					{subarray}
				</div>
			);
			groupedArray.push(row);
		}
		return { groupedArray, isOdd };
	};
	const BankEl = useCallback(
		({
			loading,
			fieldName,
			fieldTitle,
			key,
			defaultValue,
		}: {
			loading: boolean;
			fieldName: string;
			fieldTitle: string;
			key: string;
			defaultValue: string;
		}) => {
			return (
				<div className="xl:w-[100%]">
					{loading === false && !formattedBankList?.length ? (
						<CustomInputField
							name={fieldName}
							label={fieldTitle}
							placeholder={fieldTitle}
							key={key}
							required
							value={defaultValue}
							onValChange={(e: any) =>
								setInputDump((val: any) => ({
									...val,
									bankName: e.target.value,
								}))
							}
						/>
					) : (
						<CustomSelector
							firstOption={loading ? "Loading..." : "Select Banks"}
							options={loading ? [] : formattedBankList}
							itemName={fieldName}
							itemLabel={fieldTitle}
							required
							onChange={handleBankSelection}
						/>
					)}
				</div>
			);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[formattedBankList]
	);

	const BankCodeEl = useCallback(
		({
			fieldName,
			required,
			value,
			country,
			currency
		}: {
			fieldName: string;
			required: boolean;
			value: string;
			country: string;
			currency: string;
		}) => {
			let className = 'xl:w-[100%]';
			let type = 'text';
			if (country === 'CN' && currency === 'CNY') {
				className = 'hidden';
				type = 'hidden'
			}
			return (
				<div className={className} key={fieldName}>
					<CustomInputField
					type={type}
						name={fieldName}
						label="Bank Code"
						placeholder="Bank Code"
						value={value}
						required={!!required}
						showRequired={!!required}
						nonEditable={!!selectedBankCode}
						onValChange={(e: any) =>
							setInputDump((val: any) => ({
								...val,
								[fieldName]: e.target.value,
							}))
						}
					/>
				</div>
			);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[selectedBankCode]
	);

	const formData = useMemo(() => {
		if (formFields?.length) {
			const data = [...formFields];
			// move the address to the end of
			const addressFieldDataIndex = formFields.findIndex(
				(field) => field.fieldName === "bankAddress"
			);

			if (addressFieldDataIndex !== -1) {
				const addressFieldData = formFields[addressFieldDataIndex];
				data.splice(addressFieldDataIndex, 1);
				data.push(addressFieldData);
			}
			const bankCodeFieldIndex = data.findIndex(
				(field) => field.fieldName === "bankCode"
			);
			const formInputs = data.reduce(
				(acc: ReactNode[], fieldData: Record<string, any>) => {
					let el: ReactNode;
					switch (fieldData.fieldName) {
						case "bankName":
							el = (
								<BankEl
									key={fieldData.fieldName}
									fieldName={fieldData.fieldName}
									fieldTitle={fieldData.title}
									defaultValue={inputDump[fieldData.fieldName]}
									loading={fetchingBanks}
								/>
							);
							break;
						case "accountType":
							el = (
								<div className="xl:w-[100%]" key={fieldData.fieldName}>
									<CustomSelector
										firstOption={"Select Account Type"}
										options={accountTypes}
										defaultValue={inputDump[fieldData.fieldName]}
										itemName={fieldData.fieldName}
										itemLabel={fieldData.fieldTitle || "Account Type"}
										required={
											!!fieldData.required && !!!inputDump[fieldData.fieldName]
										}
										onChange={(e) =>
											setInputDump((val: any) => ({
												...val,
												[fieldData.fieldName]: e.target.value,
											}))
										}
									/>
								</div>
							);
							break;
						case "bankAddress":
							el = (
								<CustomInputField
									name={fieldData.fieldName}
									label={fieldData.title}
									placeholder={fieldData.title}
									value={inputDump["bankAddress"]}
									required={!!fieldData.required}
									showRequired={!!fieldData.required}
									key={fieldData.fieldName}
									onValChange={(e: any) =>
										setInputDump((val: any) => ({
											...val,
											[fieldData.fieldName]: e.target.value,
										}))
									}
								/>
							);
							break;
						case "bankCode":
							el = (
								<BankCodeEl
								country={country}
									value={inputDump[fieldData.fieldName]}
									required={fieldData.required}
									fieldName={fieldData.fieldName}
									currency={cur}
								/>
							);
							break;
						case "swift":
						case "iban":
						case "routine":
						case "accountNo":
							el = (
								<div className="xl:w-[100%]" key={fieldData.fieldName}>
									<CustomInputField
										name={fieldData.fieldName}
										label={fieldData.title}
										placeholder={fieldData.title}
										required={!!fieldData.required}
										showRequired={!!fieldData.required}
										value={inputDump[fieldData.fieldName]}
										onBlurChange={(e) => onBlurChange(e, fieldData.fieldName)}
										loading={
											validatedFields[fieldData.fieldName as "accountNo"][
												"loading"
											]
										}
										validationStatus={
											validatedFields[fieldData.fieldName as "accountNo"][
												"validationStatus"
											] as "warn"
										}
										onValChange={(e: any) =>
											setInputDump((val: any) => {
												return {
													...val,
													[fieldData.fieldName]: e.target.value,
												};
											})
										}
									/>
								</div>
							);
							break;
						case "accountName":
							el = (
								<div className="xl:w-[100%]" key={fieldData.fieldName}>
									{" "}
									<CustomInputField
										name={fieldData.fieldName}
										label={fieldData.title}
										placeholder={fieldData.title}
										required={
											!!fieldData.required && !inputDump[fieldData.fieldName]
										}
										showRequired={!!fieldData.required}
										disabled={validatedFields?.accountNo?.loading}
										value={inputDump[fieldData.fieldName]}
										onValChange={(e: any) =>
											setInputDump((val: any) => {
												return {
													...val,
													[fieldData.fieldName]: e.target.value,
												};
											})
										}
									/>
								</div>
							);
							break;
						default:
							el = (
								<div className="xl:w-[100%]" key={fieldData.fieldName}>
									{" "}
									<CustomInputField
										name={fieldData.fieldName}
										label={fieldData.title}
										placeholder={fieldData.title}
										showRequired={!!fieldData.required}
										required={!!fieldData.required}
										value={inputDump[fieldData.fieldName]}
										onValChange={(e: any) =>
											setInputDump((val: any) => {
												return {
													...val,
													[fieldData.fieldName]: e.target.value,
												};
											})
										}
									/>
								</div>
							);
							break;
					}
					acc.push(el);
					return acc;
				},
				[] as ReactNode[]
			);
			let formattedAddressField: ReactNode;
			if (addressFieldDataIndex !== -1)
				formattedAddressField = formInputs.pop();
			if (bankCodeFieldIndex !== -1 && country === 'CN') {
				const [bankCodeNode] = formInputs.splice(bankCodeFieldIndex,1);
				formInputs.push(bankCodeNode);
			}
			const { groupedArray, isOdd } = groupNodes(formInputs);
			if (formattedAddressField) {
				if (isOdd) {
					const lastNode = groupedArray.pop();
					groupedArray.push(<>{formattedAddressField}</>, lastNode!);
				} else {
					groupedArray.push(<>{formattedAddressField}</>);
				}
			}
			hasPaintedRef.current = true;
			return groupedArray;
		}
		return <></>;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchingBanks, formFields, selectedBankCode, inputDump, validatedFields, country]);

	return Array.isArray(formData) ? (
		<Form form={form} initialValues={inputDump} onFinish={handleSubmit}>
			<p>Bank Details</p>
			<div className="space-y-5 max-h-[450px] overflow-y-auto">{formData}</div>
			<div className="mt-10 flect justify-end">
				<div className="mr-5">
					<QwidButton
						type="button"
						plainBordered
						onClick={goBack}
						text="Back"
					/>
				</div>
				<QwidButton
					type="submit"
					text="Continue"
					disabled={
						validatedFields.accountNo.loading ||
						validatedFields.iban.loading ||
						validatedFields.swift.loading ||
						validatedFields.routine.loading ||
						validatedFields.accountNo.validationStatus === "error"
					}
				/>
			</div>
		</Form>
	) : (
		<></>
	);
};

export default BeneficaryBankForm;
