export const countryCodes: CountryProps[] = [
  { name: 'Afghanistan', code: 'AF', phone: 93, nationality: 'Afghan' },
  { name: 'Aland Islands', code: 'AX', phone: 358, nationality: 'Åland Island' },
  { name: 'Albania', code: 'AL', phone: 355, nationality: 'Albanian' },
  { name: 'Algeria', code: 'DZ', phone: 213, nationality: 'Algerian' },
  { name: 'American Samoa', code: 'AS', phone: 1684, nationality: 'American Samoan' },
  { name: 'Andorra', code: 'AD', phone: 376, nationality: 'Andorran' },
  { name: 'Angola', code: 'AO', phone: 244, nationality: 'Angolan' },
  { name: 'Anguilla', code: 'AI', phone: 1264, nationality: 'Anguillan' },
  { name: 'Antarctica', code: 'AQ', phone: 672, nationality: 'Antarctic' },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    phone: 1268,
    nationality: 'Antiguan or Barbudan'
  },
  { name: 'Argentina', code: 'AR', phone: 54, nationality: 'Argentine' },
  { name: 'Armenia', code: 'AM', phone: 374, nationality: 'Armenian' },
  { name: 'Aruba', code: 'AW', phone: 297, nationality: 'Aruban' },
  { name: 'Australia', code: 'AU', phone: 61, nationality: 'Australian' },
  { name: 'Austria', code: 'AT', phone: 43, nationality: 'Austrian' },
  { name: 'Azerbaijan', code: 'AZ', phone: 994, nationality: 'Azerbaijani, Azeri' },
  { name: 'Bahamas', code: 'BS', phone: 1242, nationality: 'Bahamian' },
  { name: 'Bahrain', code: 'BH', phone: 973, nationality: 'Bahraini' },
  { name: 'Bangladesh', code: 'BD', phone: 880, nationality: 'Bangladeshi' },
  { name: 'Barbados', code: 'BB', phone: 1246, nationality: 'Barbadian' },
  { name: 'Belarus', code: 'BY', phone: 375, nationality: 'Belarusian' },
  { name: 'Belgium', code: 'BE', phone: 32, nationality: 'Belgian' },
  { name: 'Belize', code: 'BZ', phone: 501, nationality: 'Belizean' },
  { name: 'Benin', code: 'BJ', phone: 229, nationality: 'Beninese, Beninois' },
  { name: 'Bermuda', code: 'BM', phone: 1441, nationality: 'Bermudian, Bermudan' },
  { name: 'Bhutan', code: 'BT', phone: 975, nationality: 'Bhutanese' },
  { name: 'Bolivia', code: 'BO', phone: 591, nationality: 'Bolivian' },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    code: 'BQ',
    phone: 599,
    nationality: 'Bonaire'
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    phone: 387,
    nationality: 'Bosnian or Herzegovinian'
  },
  { name: 'Botswana', code: 'BW', phone: 267, nationality: 'Motswana, Botswanan' },
  { name: 'Bouvet Island', code: 'BV', phone: 55, nationality: 'Bouvet Island' },
  { name: 'Brazil', code: 'BR', phone: 55, nationality: 'Brazilian' },
  { name: 'British Indian Ocean Territory', code: 'IO', phone: 246, nationality: 'BIOT' },
  { name: 'Brunei Darussalam', code: 'BN', phone: 673, nationality: 'Bruneian' },
  { name: 'Bulgaria', code: 'BG', phone: 359, nationality: 'Bulgarian' },
  { name: 'Burkina Faso', code: 'BF', phone: 226, nationality: 'Burkinabé' },
  { name: 'Burundi', code: 'BI', phone: 257, nationality: 'Burundian' },
  { name: 'Cambodia', code: 'KH', phone: 855, nationality: 'Cambodian' },
  { name: 'Cameroon', code: 'CM', phone: 237, nationality: 'Cameroonian' },
  { name: 'Canada', code: 'CA', phone: 1, nationality: 'Canadian' },
  { name: 'Cape Verde', code: 'CV', phone: 238, nationality: 'Cabo Verdean' },
  { name: 'Cayman Islands', code: 'KY', phone: 1345, nationality: 'Caymanian' },
  {
    name: 'Central African Republic',
    code: 'CF',
    phone: 236,
    nationality: 'Central African'
  },
  { name: 'Chad', code: 'TD', phone: 235, nationality: 'Chadian' },
  { name: 'Chile', code: 'CL', phone: 56, nationality: 'Chilean' },
  { name: 'China', code: 'CN', phone: 86, nationality: 'Chinese' },
  { name: 'Christmas Island', code: 'CX', phone: 61, nationality: 'Christmas Island' },
  { name: 'Cocos (Keeling) Islands', code: 'CC', phone: 672, nationality: 'Cocos Island' },
  { name: 'Colombia', code: 'CO', phone: 57, nationality: 'Colombian' },
  { name: 'Comoros', code: 'KM', phone: 269, nationality: 'Comoran, Comorian' },
  { name: 'Congo', code: 'CG', phone: 242, nationality: 'Congolese' },
  {
    name: 'Congo, Democratic Republic of the Congo',
    code: 'CD',
    phone: 242,
    nationality: 'Congolese'
  },
  { name: 'Cook Islands', code: 'CK', phone: 682, nationality: 'Cook Island' },
  { name: 'Costa Rica', code: 'CR', phone: 506, nationality: 'Costa Rican' },
  { name: "Cote D'Ivoire", code: 'CI', phone: 225, nationality: 'Ivorian' },
  { name: 'Croatia', code: 'HR', phone: 385, nationality: 'Croatian' },
  { name: 'Cuba', code: 'CU', phone: 53, nationality: 'Cuban' },
  { name: 'Curacao', code: 'CW', phone: 599, nationality: 'Curaçaoan' },
  { name: 'Cyprus', code: 'CY', phone: 357, nationality: 'Cypriot' },
  { name: 'Czech Republic', code: 'CZ', phone: 420, nationality: 'Czech' },
  { name: 'Denmark', code: 'DK', phone: 45, nationality: 'Danish' },
  { name: 'Djibouti', code: 'DJ', phone: 253, nationality: 'Djiboutian' },
  { name: 'Dominica', code: 'DM', phone: 1767, nationality: 'Dominican' },
  { name: 'Dominican Republic', code: 'DO', phone: 1809, nationality: 'Dominican' },
  { name: 'Ecuador', code: 'EC', phone: 593, nationality: 'Ecuadorian' },
  { name: 'Egypt', code: 'EG', phone: 20, nationality: 'Egyptian' },
  { name: 'El Salvador', code: 'SV', phone: 503, nationality: 'Salvadoran' },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    phone: 240,
    nationality: 'Equatorial Guinean, Equatoguinean'
  },
  { name: 'Eritrea', code: 'ER', phone: 291, nationality: 'Eritrean' },
  { name: 'Estonia', code: 'EE', phone: 372, nationality: 'Estonian' },
  { name: 'Ethiopia', code: 'ET', phone: 251, nationality: 'Ethiopian' },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
    phone: 500,
    nationality: 'Falkland Island'
  },
  { name: 'Faroe Islands', code: 'FO', phone: 298, nationality: 'Faroese' },
  { name: 'Fiji', code: 'FJ', phone: 679, nationality: 'Fijian' },
  { name: 'Finland', code: 'FI', phone: 358, nationality: 'Finnish' },
  { name: 'France', code: 'FR', phone: 33, nationality: 'French' },
  { name: 'French Guiana', code: 'GF', phone: 594, nationality: 'French Guianese' },
  { name: 'French Polynesia', code: 'PF', phone: 689, nationality: 'French Polynesian' },
  {
    name: 'French Southern Territories',
    code: 'TF',
    phone: 262,
    nationality: 'French Southern Territories'
  },
  { name: 'Gabon', code: 'GA', phone: 241, nationality: 'Gabonese' },
  { name: 'Gambia', code: 'GM', phone: 220, nationality: 'Gambian' },
  { name: 'Georgia', code: 'GE', phone: 995, nationality: 'Georgian' },
  { name: 'Germany', code: 'DE', phone: 49, nationality: 'German' },
  { name: 'Ghana', code: 'GH', phone: 233, nationality: 'Ghanaian' },
  { name: 'Gibraltar', code: 'GI', phone: 350, nationality: 'Gibraltar' },
  { name: 'Greece', code: 'GR', phone: 30, nationality: 'Greek, Hellenic' },
  { name: 'Greenland', code: 'GL', phone: 299, nationality: 'Greenlandic' },
  { name: 'Grenada', code: 'GD', phone: 1473, nationality: 'Grenadian' },
  { name: 'Guadeloupe', code: 'GP', phone: 590, nationality: 'Guadeloupe' },
  { name: 'Guam', code: 'GU', phone: 1671, nationality: 'Guamanian, Guambat' },
  { name: 'Guatemala', code: 'GT', phone: 502, nationality: 'Guatemalan' },
  { name: 'Guernsey', code: 'GG', phone: 44, nationality: 'Channel Island' },
  { name: 'Guinea', code: 'GN', phone: 224, nationality: 'Guinean' },
  { name: 'Guinea-Bissau', code: 'GW', phone: 245, nationality: 'Bissau-Guinean' },
  { name: 'Guyana', code: 'GY', phone: 592, nationality: 'Guyanese' },
  { name: 'Haiti', code: 'HT', phone: 509, nationality: 'Haitian' },
  {
    name: 'Heard Island and Mcdonald Islands',
    code: 'HM',
    phone: 0,
    nationality: 'Heard Island or McDonald Islands'
  },
  { name: 'Holy See (Vatican City State)', code: 'VA', phone: 39, nationality: 'Vatican' },
  { name: 'Honduras', code: 'HN', phone: 504, nationality: 'Honduran' },
  { name: 'Hong Kong', code: 'HK', phone: 852, nationality: 'Hong Kong, Hong Kongese' },
  { name: 'Hungary', code: 'HU', phone: 36, nationality: 'Hungarian, Magyar' },
  { name: 'Iceland', code: 'IS', phone: 354, nationality: 'Icelandic' },
  { name: 'India', code: 'IN', phone: 91, nationality: 'Indian' },
  { name: 'Indonesia', code: 'ID', phone: 62, nationality: 'Indonesian' },
  {
    name: 'Iran, Islamic Republic of',
    code: 'IR',
    phone: 98,
    nationality: 'Iranian, Persian'
  },
  { name: 'Iraq', code: 'IQ', phone: 964, nationality: 'Iraqi' },
  { name: 'Ireland', code: 'IE', phone: 353, nationality: 'Irish' },
  { name: 'Isle of Man', code: 'IM', phone: 44, nationality: 'Manx' },
  { name: 'Israel', code: 'IL', phone: 972, nationality: 'Israeli' },
  { name: 'Italy', code: 'IT', phone: 39, nationality: 'Italian' },
  { name: 'Jamaica', code: 'JM', phone: 1876, nationality: 'Jamaican' },
  { name: 'Japan', code: 'JP', phone: 81, nationality: 'Japanese' },
  { name: 'Jersey', code: 'JE', phone: 44, nationality: 'Channel Island' },
  { name: 'Jordan', code: 'JO', phone: 962, nationality: 'Jordanian' },
  { name: 'Kazakhstan', code: 'KZ', phone: 7, nationality: 'Kazakhstani, Kazakh' },
  { name: 'Kenya', code: 'KE', phone: 254, nationality: 'Kenyan' },
  { name: 'Kiribati', code: 'KI', phone: 686, nationality: 'I-Kiribati' },
  {
    name: "Korea, Democratic People's Republic of",
    code: 'KP',
    phone: 850,
    nationality: 'North Korean'
  },
  { name: 'Korea, Republic of', code: 'KR', phone: 82, nationality: 'South Korean' },
  { name: 'Kosovo', code: 'XK', phone: 381, nationality: 'Kosovan' },
  { name: 'Kuwait', code: 'KW', phone: 965, nationality: 'Kuwaiti' },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    phone: 996,
    nationality: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz'
  },
  {
    name: "Lao People's Democratic Republic",
    code: 'LA',
    phone: 856,
    nationality: 'Lao, Laotian'
  },
  { name: 'Latvia', code: 'LV', phone: 371, nationality: 'Latvian' },
  { name: 'Lebanon', code: 'LB', phone: 961, nationality: 'Lebanese' },
  { name: 'Lesotho', code: 'LS', phone: 266, nationality: 'Basotho' },
  { name: 'Liberia', code: 'LR', phone: 231, nationality: 'Liberian' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY', phone: 218, nationality: 'Libyan' },
  { name: 'Liechtenstein', code: 'LI', phone: 423, nationality: 'Liechtenstein' },
  // { name: 'Lithuania', code: 'LT', phone: 370, nationality: 'Lithuanian' },
  { name: 'Luxembourg', code: 'LU', phone: 352, nationality: 'Luxembourg, Luxembourgish' },
  { name: 'Macao', code: 'MO', phone: 853, nationality: 'Macanese, Chinese' },
  {
    name: 'Macedonia, the Former Yugoslav Republic of',
    code: 'MK',
    phone: 389,
    nationality: 'Macedonian'
  },
  { name: 'Madagascar', code: 'MG', phone: 261, nationality: 'Malagasy' },
  { name: 'Malawi', code: 'MW', phone: 265, nationality: 'Malawian' },
  { name: 'Malaysia', code: 'MY', phone: 60, nationality: 'Malaysian' },
  { name: 'Maldives', code: 'MV', phone: 960, nationality: 'Maldivian' },
  { name: 'Mali', code: 'ML', phone: 223, nationality: 'Malian, Malinese' },
  { name: 'Malta', code: 'MT', phone: 356, nationality: 'Maltese' },
  { name: 'Marshall Islands', code: 'MH', phone: 692, nationality: 'Marshallese' },
  { name: 'Martinique', code: 'MQ', phone: 596, nationality: 'Martiniquais, Martinican' },
  { name: 'Mauritania', code: 'MR', phone: 222, nationality: 'Mauritanian' },
  { name: 'Mauritius', code: 'MU', phone: 230, nationality: 'Mauritian' },
  { name: 'Mayotte', code: 'YT', phone: 262, nationality: 'Mahoran' },
  { name: 'Mexico', code: 'MX', phone: 52, nationality: 'Mexican' },
  {
    name: 'Micronesia, Federated States of',
    code: 'FM',
    phone: 691,
    nationality: 'Micronesian'
  },
  { name: 'Moldova, Republic of', code: 'MD', phone: 373, nationality: 'Moldovan' },
  { name: 'Monaco', code: 'MC', phone: 377, nationality: 'Monégasque, Monacan' },
  { name: 'Mongolia', code: 'MN', phone: 976, nationality: 'Mongolian' },
  { name: 'Montenegro', code: 'ME', phone: 382, nationality: 'Montenegrin' },
  { name: 'Montserrat', code: 'MS', phone: 1664, nationality: 'Montserratian' },
  { name: 'Morocco', code: 'MA', phone: 212, nationality: 'Moroccan' },
  { name: 'Mozambique', code: 'MZ', phone: 258, nationality: 'Mozambican' },
  { name: 'Myanmar', code: 'MM', phone: 95, nationality: 'Burmese' },
  { name: 'Namibia', code: 'NA', phone: 264, nationality: 'Namibian' },
  { name: 'Nauru', code: 'NR', phone: 674, nationality: 'Nauruan' },
  { name: 'Nepal', code: 'NP', phone: 977, nationality: 'Nepali, Nepalese' },
  { name: 'Netherlands', code: 'NL', phone: 31, nationality: 'Dutch' },
  { name: 'Netherlands Antilles', code: 'AN', phone: 599, nationality: 'Dutch Antillean' },
  { name: 'New Caledonia', code: 'NC', phone: 687, nationality: 'New Caledonian' },
  { name: 'New Zealand', code: 'NZ', phone: 64, nationality: 'New Zealand, NZ' },
  { name: 'Nicaragua', code: 'NI', phone: 505, nationality: 'Nicaraguan' },
  { name: 'Niger', code: 'NE', phone: 227, nationality: 'Nigerien' },
  { name: 'Nigeria', code: 'NG', phone: 234, nationality: 'Nigerian' },
  { name: 'Niue', code: 'NU', phone: 683, nationality: 'Niuean' },
  { name: 'Norfolk Island', code: 'NF', phone: 672, nationality: 'Norfolk Island' },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    phone: 1670,
    nationality: 'Northern Marianan'
  },
  { name: 'Norway', code: 'NO', phone: 47, nationality: 'Norwegian' },
  { name: 'Oman', code: 'OM', phone: 968, nationality: 'Omani' },
  { name: 'Pakistan', code: 'PK', phone: 92, nationality: 'Pakistani' },
  { name: 'Palau', code: 'PW', phone: 680, nationality: 'Palauan' },
  {
    name: 'Palestinian Territory, Occupied',
    code: 'PS',
    phone: 970,
    nationality: 'Palestinian'
  },
  { name: 'Panama', code: 'PA', phone: 507, nationality: 'Panamanian' },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    phone: 675,
    nationality: 'Papua New Guinean, Papuan'
  },
  { name: 'Paraguay', code: 'PY', phone: 595, nationality: 'Paraguayan' },
  { name: 'Peru', code: 'PE', phone: 51, nationality: 'Peruvian' },
  { name: 'Philippines', code: 'PH', phone: 63, nationality: 'Philippine, Filipino' },
  { name: 'Pitcairn', code: 'PN', phone: 64, nationality: 'Pitcairn Island' },
  { name: 'Poland', code: 'PL', phone: 48, nationality: 'Polish' },
  { name: 'Portugal', code: 'PT', phone: 351, nationality: 'Portuguese' },
  { name: 'Puerto Rico', code: 'PR', phone: 1787, nationality: 'Puerto Rican' },
  { name: 'Qatar', code: 'QA', phone: 974, nationality: 'Qatari' },
  { name: 'Reunion', code: 'RE', phone: 262, nationality: 'Réunionese, Réunionnais' },
  { name: 'Romania', code: 'RO', phone: 40, nationality: 'Romanian' },
  { name: 'Russian Federation', code: 'RU', phone: 70, nationality: 'Russian' },
  { name: 'Rwanda', code: 'RW', phone: 250, nationality: 'Rwandan' },
  { name: 'Saint Barthelemy', code: 'BL', phone: 590, nationality: 'Barthélemois' },
  { name: 'Saint Helena', code: 'SH', phone: 290, nationality: 'Saint Helenian' },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    phone: 1869,
    nationality: 'Kittitian or Nevisian'
  },
  { name: 'Saint Lucia', code: 'LC', phone: 1758, nationality: 'Saint Lucian' },
  { name: 'Saint Martin', code: 'MF', phone: 590, nationality: 'Saint-Martinoise' },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    phone: 508,
    nationality: 'Saint-Pierrais or Miquelonnais'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    phone: 1784,
    nationality: 'Saint Vincentian, Vincentian'
  },
  { name: 'Samoa', code: 'WS', phone: 684, nationality: 'Samoan' },
  { name: 'San Marino', code: 'SM', phone: 378, nationality: 'Sammarinese' },
  { name: 'Sao Tome and Principe', code: 'ST', phone: 239, nationality: 'São Toméan' },
  { name: 'Saudi Arabia', code: 'SA', phone: 966, nationality: 'Saudi, Saudi Arabian' },
  { name: 'Senegal', code: 'SN', phone: 221, nationality: 'Senegalese' },
  { name: 'Serbia', code: 'RS', phone: 381, nationality: 'Serbian' },
  { name: 'Serbia and Montenegro', code: 'CS', phone: 381, nationality: 'Montenegrin and Serbian' },
  { name: 'Seychelles', code: 'SC', phone: 248, nationality: 'Seychellois' },
  { name: 'Sierra Leone', code: 'SL', phone: 232, nationality: 'Sierra Leonean' },
  { name: 'Singapore', code: 'SG', phone: 65, nationality: 'Singaporean' },
  { name: 'Sint Maarten', code: 'SX', phone: 1, nationality: 'Sint Maarten' },
  { name: 'Slovakia', code: 'SK', phone: 421, nationality: 'Slovak' },
  { name: 'Slovenia', code: 'SI', phone: 386, nationality: 'Slovenian, Slovene' },
  { name: 'Solomon Islands', code: 'SB', phone: 677, nationality: 'Solomon Island' },
  { name: 'Somalia', code: 'SO', phone: 252, nationality: 'Somali, Somalian' },
  { name: 'South Africa', code: 'ZA', phone: 27, nationality: 'South African' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    phone: 500,
    nationality: 'South Georgia or South Sandwich Islands'
  },
  { name: 'South Sudan', code: 'SS', phone: 211, nationality: 'South Sudanese' },
  { name: 'Spain', code: 'ES', phone: 34, nationality: 'Spanish' },
  { name: 'Sri Lanka', code: 'LK', phone: 94, nationality: 'Sri Lankan' },
  { name: 'Sudan', code: 'SD', phone: 249, nationality: 'Sudanese' },
  { name: 'Suriname', code: 'SR', phone: 597, nationality: 'Surinamese' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', phone: 47, nationality: 'Svalbard' },
  { name: 'Swaziland', code: 'SZ', phone: 268, nationality: 'Swazi' },
  { name: 'Sweden', code: 'SE', phone: 46, nationality: 'Swedish' },
  { name: 'Switzerland', code: 'CH', phone: 41, nationality: 'Swiss' },
  { name: 'Syrian Arab Republic', code: 'SY', phone: 963, nationality: 'Syrian' },
  {
    name: 'Taiwan',
    code: 'TW',
    phone: 886,
    nationality: 'Chinese, Taiwanese'
  },
  { name: 'Tajikistan', code: 'TJ', phone: 992, nationality: 'Tajikistani' },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZ',
    phone: 255,
    nationality: 'Tanzanian'
  },
  { name: 'Thailand', code: 'TH', phone: 66, nationality: 'Thai' },
  { name: 'Timor-Leste', code: 'TL', phone: 670, nationality: 'Timorese' },
  { name: 'Togo', code: 'TG', phone: 228, nationality: 'Togolese' },
  { name: 'Tokelau', code: 'TK', phone: 690, nationality: 'Tokelauan' },
  { name: 'Tonga', code: 'TO', phone: 676, nationality: 'Tongan' },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    phone: 1868,
    nationality: 'Trinidadian or Tobagonian'
  },
  { name: 'Tunisia', code: 'TN', phone: 216, nationality: 'Tunisian' },
  { name: 'Turkey', code: 'TR', phone: 90, nationality: 'Turkish' },
  { name: 'Turkmenistan', code: 'TM', phone: 7370, nationality: 'Turkmen' },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    phone: 1649,
    nationality: 'Turks and Caicos Island'
  },
  { name: 'Tuvalu', code: 'TV', phone: 688, nationality: 'Tuvaluan' },
  { name: 'Uganda', code: 'UG', phone: 256, nationality: 'Ugandan' },
  { name: 'Ukraine', code: 'UA', phone: 380, nationality: 'Ukrainian' },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    phone: 971,
    nationality: 'Emirati, Emirian, Emiri'
  },
  { name: 'United Kingdom', code: 'GB', phone: 44, nationality: 'British, UK' },
  { name: 'United States', code: 'US', phone: 1, nationality: 'American' },
  { name: 'Uruguay', code: 'UY', phone: 598, nationality: 'Uruguayan' },
  { name: 'Uzbekistan', code: 'UZ', phone: 998, nationality: 'Uzbekistani, Uzbek' },
  { name: 'Vanuatu', code: 'VU', phone: 678, nationality: 'Ni-Vanuatu, Vanuatuan' },
  { name: 'Venezuela', code: 'VE', phone: 58, nationality: 'Venezuelan' },
  { name: 'Viet Nam', code: 'VN', phone: 84, nationality: 'Vietnamese' },
  {
    name: 'Virgin Islands, British',
    code: 'VG',
    phone: 1284,
    nationality: 'British Virgin Island'
  },
  {
    name: 'Virgin Islands, U.s.',
    code: 'VI',
    phone: 1340,
    nationality: 'U.S. Virgin Island'
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
    phone: 681,
    nationality: 'Wallis and Futuna, Wallisian or Futunan'
  },
  {
    name: 'Western Sahara',
    code: 'EH',
    phone: 212,
    nationality: 'Sahrawi, Sahrawian, Sahraouian'
  },
  { name: 'Yemen', code: 'YE', phone: 967, nationality: 'Yemeni' },
  { name: 'Zambia', code: 'ZM', phone: 260, nationality: 'Zambian' },
  { name: 'Zimbabwe', code: 'ZW', phone: 263, nationality: 'Zimbabwean' }
];

// https://www.html-code-generator.com/javascript/json/country-names
