import Searchbar from "components/common/InputElements/Searchbar";
import { QwidButton, useToolkit, OptionalRender } from "components";
import { setTransitionDirection } from "redux/slices/transactionsSlice";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";
import Layout from "../internal-transfer/Layout";
import { countryCodes } from "utils/appData/countryCodes";
import { GoPrimitiveDot } from "react-icons/go";
import {
	setWithdrawalRecipient,
	toggleWithdrawalRecipientStatus,
	withdrawToNewAccount,
} from "redux/slices/sendflowSlice";
import WalletSendingFrom from "./WalletSendingFrom";

const SelectSavedAccount = ({
	pageIncrement,
	pageDecrement,
}: ComponentPageNavigator) => {
	const { dispatch, router, useAppSelector, useState, toastError } =
		useToolkit();

	const [recipient, setRecipient] = useState<number>();
	const [query, setQuery] = useState("");

	const {
		withdrawalInfo: { savedAccounts, isCreatingNewRecipient },
	} = useAppSelector((state) => state.sendflow);

	const filteredAccounts = savedAccounts?.filter((item: SavedAccountProps) => {
		if (query === "") return item;
		else {
			return (
				item.accountName?.toLowerCase()?.includes(query.toLowerCase()) ||
				item.bankName?.toLowerCase()?.includes(query.toLowerCase()) ||
				item?.accountNo?.includes(query)
			);
		}
	});

	const onProceed = () => {
		const selected = savedAccounts?.find(
			(account: SavedAccountProps) => account?.id === recipient
		);

		if (selected) {
			dispatch(setWithdrawalRecipient(selected));
			pageIncrement && pageIncrement();
		} else {
			toastError("Please select a recipient");
		}
	};

	return isCreatingNewRecipient ? (
		<WalletSendingFrom
			pageDecrement={pageDecrement}
			pageIncrement={pageIncrement}
		/>
	) : (
		<Layout>
			<div className="w-full">
				<Searchbar
					placeholder="Search recipients"
					searchFunction={(value: string) => setQuery(value)}
				/>

				<div className="flex justify-end">
					<div
						onClick={() => {
							dispatch(setTransitionDirection("right"));
							dispatch(toggleWithdrawalRecipientStatus());
							dispatch(withdrawToNewAccount());
						}}
						className="text-[#3A96BE] cursor-pointer text-[0.8rem] flex items-center gap-2 mt-2">
						<span className="text-[1.2rem]">+</span> Add New Recipient
					</div>
				</div>

				<OptionalRender condition={filteredAccounts?.length < 1}>
					<div className="w-full my-6 max-h-[200px] custom-scroll overflow-y-auto p-2">
						<div className="m-auto flex flex-col items-center">
							<img
								className="w-full m-auto max-w-[11rem]"
								src="/assets/common/noRecipient.svg"
								alt="no recipient"
							/>
							<p>No recipients found</p>
						</div>
					</div>
				</OptionalRender>

				<OptionalRender condition={filteredAccounts?.length > 0}>
					<div className="w-full my-6 max-h-[200px] custom-scroll overflow-y-auto p-2">
						{filteredAccounts?.map((account: SavedAccountProps) => {
							const isSelected = account?.id === recipient;
							const countryName = countryCodes?.find(
								(item: CountryProps) => item?.code === account?.country
							);

							return (
								<div
									key={account?.id}
									onClick={() => setRecipient(account?.id)}
									className={`w-full cursor-pointer flex items-center p-2 transition-colors border rounded-md mb-4 ${
										isSelected ? "border-[#6BABC6]" : "border-gray-200"
									}`}>
									<div className="flexed w-8 h-8 text-sm text-white rounded-full bg-[#3AB25C]">
										{account.accountName[0]}
									</div>

									<div className="flex items-center pl-3 space-x-1 text-[10px] lg:text-xs">
										<span
											className={`hidden lg:inline fi fi-${account?.country?.toLowerCase()} fis rounded-[50%]`}></span>

										<span>{countryName?.name}</span>

										<span className="text-[#DEE0E2] hidden lg:inline">
											<GoPrimitiveDot />
										</span>
										<span className="hidden lg:inline">{account?.cur}</span>

										<span className="text-[#DEE0E2]">
											<GoPrimitiveDot />
										</span>

										<span>{account?.accountName}</span>
									</div>
								</div>
							);
						})}
					</div>
				</OptionalRender>

				<div className="flex items-center justify-between mt-[1.5rem]">
					<div
						onClick={() => router.push(DASHBOARD_ROUTES.SEND)}
						className="text-[#2B708E] font-semibold cursor-pointer">
						Back
					</div>
					<div className="w-full max-w-[9rem]">
						<QwidButton text="Continue" isFull darkBlueBg onClick={onProceed} />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default SelectSavedAccount;
