import React, { useEffect } from 'react';
import { Spinner, useKyc, useToolkit } from 'components';
import { AiFillEdit } from 'react-icons/ai';
import { MdDeleteForever } from 'react-icons/md';
import { Spin } from 'antd';
interface SummaryContainerProp {
  title: string;
  editAction?: () => void;
  deleteAction?: () => void;
  children?: JSX.Element;
  deleteBtn?: boolean;
}

// accountDesignation

const SummaryContainer: React.FC<SummaryContainerProp> = ({
  title,
  editAction,
  children,
  deleteAction,
  deleteBtn,
}) => {
  const { kycLoading, getKycStatus, kycStatus: status } = useKyc();
  const { isPersonalAccount, user,useAppSelector ,isBusinessAccount} = useToolkit();


  const { userTier } = useAppSelector((state) => state.dashboard);

  useEffect(() => {
    getKycStatus();
  }, []);
  const getStatusText = (): string => {
    if (userTier?.level === 1) {
      if (title === 'Identify Information' || title === 'Personal Infomation' || title === "Business Infomation") {
        return 'Verified';
      } else if (title === 'Contact Information' ) {
        return 'Unverified';
      }
    }
    if (userTier?.level === 2) {
      if (title === 'Identify Information' || title === 'Personal Infomation' || title === "Business Infomation") {
        return 'Verified';
      } else if (title === 'Contact Information') {
        return 'Unverified';
      }
    }
    if (userTier?.level === 3) {
      if (title === 'Identify Information' || title === 'Personal Infomation' || title === 'Contact Information' || title === "Business Infomation") {
        return 'Verified';
      } 
    }
    return 'Error';
  };

  const getStatusStyles = (): string => {
    if (userTier?.level === 1) {
      if (title === 'Identify Information' || title === 'Personal Infomation' ){
        return 'text-[#336F2E] bg-[#E5F7E3] border-[1px] border-[#17C509]';
      } else if (title === 'Contact Information') {
        'bg-[#FFF3F2] text-[#E11C00] ';
      }
    }
    if (userTier?.level === 2) {
      if (title === 'Identify Information' || title === 'Personal Infomation' || title === "Business Infomation"){
        return 'text-[#336F2E] bg-[#E5F7E3] border-[1px] border-[#17C509]';
      } else if (title === 'Contact Information') {
        'bg-[#FFF3F2] text-[#E11C00] ';
      }
    }
    if (userTier?.level === 3) {
      if (title === 'Identify Information' || title === 'Personal Infomation' || title === 'Contact Information' || title === "Business Infomation"){
        return 'text-[#336F2E] bg-[#E5F7E3] border-[1px] border-[#17C509]';
      }
    }
    return 'bg-[#FFF3F2] text-[#E11C00]';
  };
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="items-center flex justify-between    py-2 ">
        <div className=" text-[0.8rem] md:text-[1rem] flex items-center h-full  font-semibold">
          {title}{' '}
          <span className="md:ml-4">
           
            <p className={`w-max mb-0 font-normal  py-[0.1rem] px-2  ml-[1rem] md:ml-0 rounded-[3rem] text-[0.8rem] ${getStatusStyles()}`}>

            {getStatusText()}
            </p>
          </span>
        </div>
        <div className="flex  ">
          {Array.isArray(user?.enduser) &&
            user?.enduser.length > 0 &&
            !isBusinessAccount  &&
            user?.enduser[0].country !== 'Nigeria' && (
              <>
                {editAction && (
                  <div
                    onClick={editAction}
                    className="text-[#3A96BE] cursor-pointer flex items-center text-[0.8rem] md:gap-2"
                  >
                    <AiFillEdit className="!w-5 !h-3 md:!h-4" /> Edit
                  </div>
                )}
              </>
            )}

          {deleteBtn && (
            <>
              <div
                onClick={deleteAction}
                className=" text-red-500 cursor-pointer hidden  md:flex items-center  ml-2"
              >
                {' '}
                <MdDeleteForever className="!w-4 !h-4 md:!w-5 md:!h-5 text-[#ED1000]" />{' '}
                Delete
              </div>
            </>
          )}
        </div>
      </div>
      <div className="border-[1.2px] bg-white  w-full rounded-[16px] border-[#E5E7EB] p-5">
        <div className="py-4">{children}</div>
        {deleteBtn && (
          <div className=" md:hidden">
            <div
              className="flex p-3 cursor-pointer bg-[#FF00000A] rounded items-center justify-center    "
              onClick={deleteAction}
            >
              {' '}
              <MdDeleteForever className="!w-4 !h-4 md:!w-5 md:!h-5 text-[#ED1000]" />{' '}
              Delete
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryContainer;
