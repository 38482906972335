import { Dispatch, SetStateAction } from "react";
import {
	closeCurrencyDrawer,
	toggleCurrencyDrawer,
} from "redux/slices/virtualsSlice";
import { useToolkit, Spinner, useFramer } from "components";
import { motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";

type Props = {
	currs: ConvCurr[] | undefined;
	currsLoading: boolean;
	setRcvCurObj: Dispatch<SetStateAction<ConvCurr | undefined>>;
	setRcvCur: Dispatch<SetStateAction<string>>;
};

const ReceivingCurrencyDrawer = ({
	currs,
	currsLoading,
	setRcvCurObj,
	setRcvCur,
}: Props) => {
	const { dispatch, useState, useRef, useEffect } = useToolkit();
	const { dropdown } = useFramer();

	const [query, setQuery] = useState("");
	const inputRef = useRef<HTMLInputElement>(null);

	const search = (data: ConvCurr[] | undefined) => {
		if (query === "") return data;

		return data?.filter((item: ConvCurr) =>
			item.label?.toLowerCase()?.includes(query.toLowerCase())
		);
	};

	const onSelect = (item: ConvCurr) => {
		setRcvCurObj(item);
		setRcvCur(item.cur);
		dispatch(toggleCurrencyDrawer());
	};

	useEffect(() => {
		inputRef?.current?.focus();
	}, []);

	return (
		<motion.div
			variants={dropdown}
			initial="hidden"
			animate="visible"
			className="absolute top-20 left-0 w-full shadow-lg bwite p-5">
			{currsLoading ? (
				<Spinner msg="Fetching" />
			) : (
				<div className="flex flex-col pt-5 pb-10">
					<div className="mb-5 flex justify-end items-center">
						<IoMdClose
							className="text-2xl text-opacity-40 curp"
							onClick={() => dispatch(closeCurrencyDrawer())}
						/>
					</div>
					<div className="w-full mb-3">
						<input
							ref={inputRef}
							value={query}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setQuery(e.target.value)
							}
							type="text"
							placeholder="Enter a country / currency"
							className="w-full outline-none bg-gray-200 h-10 pl-4"
						/>
					</div>
					{search(currs)?.map((item: ConvCurr) => (
						<div
							onClick={() => onSelect(item)}
							className="flex curp p-2 mb-3 rounded-md hover:bg-gray-200 transition-colors"
							key={item.cur}>
							<img src={item.flag} alt={item.label} width={35} />
							<p className="mb-0 twide ml-4">{item.label}</p>
						</div>
					))}
				</div>
			)}
		</motion.div>
	);
};

export default ReceivingCurrencyDrawer;
