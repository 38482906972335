import { QwidButton, useFramer, useToolkit } from "../..";
import { motion } from "framer-motion";
import { AUTH_ROUTES } from "utils/appData/appRoutes";

type Props = {};

const ReadyToStart = (props: Props) => {
	const { zoomWrap } = useFramer();
	const { router, logout, isValidUser } = useToolkit();

	return (
		<div className="bwite py-8 lg:py-24 side-pad">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}>
				<div className="w-full lg:ws-5/6 mx-auto rounded-3xl bg-[#032e41] text-white p-10 lg:p-20">
					<p className="text-lg mb-7 lg:text-5xl font-semibold">
						Ready to get started?
					</p>

					<p className="mb-16 lg:w-3/6">
						Create an account today and immediately start accepting payments and{" "}
						sending money
					</p>

					<div className="">
						<QwidButton
							whiteBg
							text={<span className="px-4">Create a free account</span>}
							onClick={() => {
								if (isValidUser) {
									logout();
								}
								router.push(AUTH_ROUTES.SIGN_UP);
							}}
						/>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default ReadyToStart;
