import { Form, message } from "antd";
import {
	CustomInputField,
	QwidButton,
	useToolkit,
	useFileUploader,
	CustomSelector,
} from "components";
import { Moment } from "moment";
import { updateBusinessDetails } from "redux/slices/settingsSlice";

type BusinessInput = {
	label?: string | undefined;
	name: string;
	required?: boolean;
	Component?: JSX.Element;
};

type Props = {
	countriesLoading: boolean;
	countriesData: {}[];

	setCurrent: (value: React.SetStateAction<number>) => void;
};

const PersonnelDetailsForm = ({
	countriesData,
	countriesLoading,
	setCurrent,
}: Props) => {
	const { useState, moment, useAppSelector, dispatch } = useToolkit();
	const { isFirstTimeUser } = useAppSelector((state) => state.settings);
	const [designation, setDesignation] = useState("");
	const [termination, setTermination] = useState("");
	const [idOptions, setIdOptions] = useState({
		issueDate: "",
		expiryDate: "",
	});
	const { UploadUI, fileProps } = useFileUploader();
	const disabledDate = (current: any) =>
		current > moment().subtract(1, "month");

	const dateFormat = "YYYY-MM-DD";

	const inputItems: BusinessInput[] = [
		{ label: "Full Name", name: "fullName" },
		{ label: "Designation", name: "designation" },
		{
			name: "designation",
			Component: (
				<CustomInputField
					label="Date of Appointment (Designation)"
					isDatePicker
					disabledDate={disabledDate}
					onDateChange={(value: Moment | null) =>
						setDesignation(moment(value).format("YYYY-MM-DD"))
					}
				/>
			),
		},
		{
			name: "termination",
			Component: (
				<CustomInputField
					label="Date of Termination (optional)"
					isDatePicker
					disabledDate={(current: Moment) => current.isBefore(moment(), "day")}
					onDateChange={(value: Moment | null) =>
						setTermination(moment(value).format(dateFormat))
					}
				/>
			),
		},

		{
			name: "idType",
			Component: (
				<CustomSelector
					isAnt
					required={isFirstTimeUser}
					options={[
						{ title: "International Passport", value: "passsport" },
						{ title: "Driver's License", value: "driver's license" },
						{ title: "National ID Card", value: "national ID card" },
					]}
					placeholder="Select ID Type"
					customLabel="Type of ID"
					customName="businessApplicantDocumentType"
				/>
			),
		},
		{ label: "Identity Number", name: "businessApplicantDocumentNumber" },
		{
			label: "Issuing Country",
			name: "issuingCountry",
			Component: (
				<CustomSelector
					customName="businessApplicantDocumentIssueCountry"
					customLabel="Issuing Country"
					isAnt
					required={isFirstTimeUser}
					options={countriesData}
					placeholder={
						countriesLoading ? "Fetching countries..." : "Select Country"
					}
				/>
			),
		},
		{
			name: "idIssueDate",
			Component: (
				<CustomInputField
					label="ID Issue Date"
					isDatePicker
					disabledDate={disabledDate}
					onDateChange={(value: Moment | null) =>
						setIdOptions({
							...idOptions,
							issueDate: moment(value).format(dateFormat),
						})
					}
				/>
			),
		},
		{
			name: "termination",
			Component: (
				<CustomInputField
					label="ID Expiry Date"
					isDatePicker
					disabledDate={(current: Moment) => current.isBefore(moment(), "day")}
					onDateChange={(value: Moment | null) =>
						setIdOptions({
							...idOptions,
							expiryDate: moment(value).format(dateFormat),
						})
					}
				/>
			),
		},
	];

	const onSubmit = (values: any) => {
		const countryVal = isFirstTimeUser ? "" : values?.country?.value;
		const idIssue = idOptions.issueDate;
		const idExp = idOptions.expiryDate;
		const idFile = fileProps.name;

		if (
			(isFirstTimeUser && !idIssue.length) ||
			(isFirstTimeUser && !idExp?.length)
		) {
			message.warning("Please provide Issue & Expiry Dates for ID");
		} else if (isFirstTimeUser && !designation?.length) {
			message.warning("Please select appointment start date");
		} else if (isFirstTimeUser && !idFile.length) {
			message.warning("Please upload an ID");
		} else {
			const payload = {
				...values,
				businessApplicantPositionStartDate: designation,
				businessApplicantPositionEndDate: termination,
				businessApplicantDocumentIssuedDate: idOptions.issueDate,
				businessApplicantDocumentExpiryDate: idOptions.expiryDate,
				businessApplicantDocument: fileProps.string,
				businessApplicantDocumentExtension: fileProps.extension,
			};
			dispatch(updateBusinessDetails(payload));
			setCurrent((prev) => prev + 1);
		}
	};

	return (
		<Form onFinish={onSubmit}>
			<div className="w-full flex items-end justify-center md:justify-between flex-wrap">
				{inputItems?.map(({ label, name, Component }: BusinessInput) =>
					Component ? (
						<div key={name} className="form-child3 mb-8">
							{Component}
						</div>
					) : (
						<div key={name} className="form-child3 mb-8">
							<CustomInputField
								required={isFirstTimeUser}
								label={label || ""}
								name={name}
								placeholder={`Enter ${label}`}
							/>
						</div>
					)
				)}

				<div className="w-full flex items-end justify-center md:justify-between flex-wrap">
					<div className="form-child relative pt-4 mb-6 md:mb-0">
						<CustomSelector
							isAnt
							required={isFirstTimeUser}
							customName="businessApplicantisPep"
							customLabel="Is the application staff a PEP?"
							options={[
								{ title: "Yes", value: "Yes" },
								{ title: "No", value: "no" },
							]}
							placeholder="Select"
						/>{" "}
					</div>

					<div className="form-child">
						<UploadUI
							isFull
							label={<span className="text-xs">Proof of ID - 3mb max</span>}
						/>
					</div>
				</div>
			</div>

			<div className="mt-10 flex justify-end">
				<QwidButton
					type="submit"
					text={<span className="px-5">Next</span>}
					darkBlueBg
				/>
			</div>
		</Form>
	);
};

export default PersonnelDetailsForm;
