import React from "react";

type Props = {};

type StepProps = {
	title: string;
	subtitle: string;
};

const steps: StepProps[] = [
	{
		title: "Create an account",
		subtitle:
			"Click on the signup button, select 'business', and fill out the necessary information to create an account.",
	},
	{
		title: "Upload important documents",
		subtitle:
			"To help us understand the nature and specific needs of your business, you will be required to upload certain documents.",
	},
	{
		title: "Perform your transactions",
		subtitle:
			"You are all set now. Perform all transactions with ease, and enjoy a safe and reliable experience on the Qwid business platform.",
	},
];

const GettingStarted = (props: Props) => {
	const [stepPosition, setStepPosition] = React.useState<number>(0);
	const isFirst = stepPosition === 0;
	const isSecond = stepPosition === 1;
	const isThird = stepPosition === 2;

	return (
		<>
			<div className="side-pad h-pad bwite lg:pb-28 hidden lg:flex flex-col">
				<p className="med mb-10 lg:mb-5 mt-3 lg:mt-0 text-lg tcent lg:text-3xl lg:text-left lg:w-2/4 lg:leading-normal">
					All you need to do is:
				</p>

				<div className="mt-10 w-11/12 mx-auto">
					<div className="flex items-end justify-between">
						{/* toggle steps */}
						<div className="flex space-x-5 w-[40%] h-[350px]">
							<div
								style={{ paddingTop: isFirst ? "0" : isSecond ? "24%" : "53%" }}
								className="w-2 h-full rounded-sm bg-[#D9D9D9] pts-[30%] overflow-hidden">
								<div className={`w-full h-[100px] pry-bg trall`} />
							</div>
							{/* steps wrapper */}
							<div className="flex flex-col py-3 justify-between">
								{steps.map(({ title, subtitle }, idx) => (
									<div
										onClick={() => setStepPosition(idx)}
										className={`curp`}
										key={title}>
										<p
											className={`mb-2.5 lg:t6 tracking-wide transition-colors duration-300 ${
												stepPosition === idx ? "text-black med" : "softext"
											}`}>
											{title}
										</p>
										<p
											className={`mb-0 tracking-wide text-sm transition-colors duration-300 ${
												stepPosition === idx ? "text-black" : "softext"
											}`}>
											{subtitle}
										</p>
									</div>
								))}
							</div>
						</div>

						{/* images */}
						<div className="w-[50%] px-4">
							<div className="h-[400px] bg-[#acdff5] flexed w-full p-5">
								<img
									src={
										isFirst
											? "/assets/business/slide-1.svg"
											: isSecond
											? "/assets/business/slide-2.svg"
											: "/assets/business/slide-3.svg"
									}
									alt="slide"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default GettingStarted;
