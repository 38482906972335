import { RiUpload2Fill } from 'react-icons/ri';
import useToolkit from '../misc/useToolkit';
import { AiFillDelete } from 'react-icons/ai';
import React from 'react';

const UploadIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'>
    <path
      d='M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM11.53 13.53C11.38 13.68 11.19 13.75 11 13.75C10.81 13.75 10.62 13.68 10.47 13.53L9.75 12.81V17C9.75 17.41 9.41 17.75 9 17.75C8.59 17.75 8.25 17.41 8.25 17V12.81L7.53 13.53C7.24 13.82 6.76 13.82 6.47 13.53C6.18 13.24 6.18 12.76 6.47 12.47L8.47 10.47C8.54 10.41 8.61 10.36 8.69 10.32C8.71 10.31 8.74 10.3 8.76 10.29C8.82 10.27 8.88 10.26 8.95 10.25C8.98 10.25 9 10.25 9.03 10.25C9.11 10.25 9.19 10.27 9.27 10.3C9.28 10.3 9.28 10.3 9.29 10.3C9.37 10.33 9.45 10.39 9.51 10.45C9.52 10.46 9.53 10.46 9.53 10.47L11.53 12.47C11.82 12.76 11.82 13.24 11.53 13.53Z'
      fill='#292D32'
    />
    <path
      d='M17.4299 8.80999C18.3799 8.81999 19.6999 8.81999 20.8299 8.81999C21.3999 8.81999 21.6999 8.14999 21.2999 7.74999C19.8599 6.29999 17.2799 3.68999 15.7999 2.20999C15.3899 1.79999 14.6799 2.07999 14.6799 2.64999V6.13999C14.6799 7.59999 15.9199 8.80999 17.4299 8.80999Z'
      fill='#292D32'
    />
  </svg>
);

const MemoizedUploadIcon = React.memo(UploadIcon);
const useFileUploader = (prop) => {
  const { useState, useRef, user } = useToolkit();
  const [propsLoading, setPropsLoading] = useState(false);
  const [fileProps, setFileProps] = useState({
    name: '',
    string: '',
    extension: ''
  });

  // optional - for previewing profile pic
  const [profilePic, setProfilePic] = useState(user?.enduser ? user?.enduser[0]?.pix : '');
  const [btnActive, setBtnActive] = useState(false);

  const fileRef = useRef(null);

  const handleFileChange = (e) => {
    setPropsLoading(!propsLoading);
    if (prop) {
      setProfilePic(URL.createObjectURL(e.target.files[0]));
      setBtnActive(true);
    }

    const file = e.target.files[0];
    const fileName = file?.name?.substring(0, 15);
    const extension = file?.type.split('/')[1];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
      setFileProps({
        ...fileProps,
        name: fileName,
        string: base64String,
        extension: extension,
        rawName: file?.name
      });
    };

    setPropsLoading(false);
  };
  const handleDeleteFile = (e) => {
    setFileProps({
      name: '',
      string: '',
      encoded: '',
      extension: ''
    });
  };

  const onClick = () => fileRef?.current?.click();

  const UploadUI = ({
    label,
    isFull,
    hasDeleteBtn = false,
    defaultValue = '',
    extraFunction = () => {}
  }) => {
    const handleDeleteFile = (e) => {
      setFileProps({
        name: '',
        string: '',
        encoded: '',
        extension: ''
      });
      extraFunction();
    };

    return (
      <div className={isFull ? 'w-full relative' : 'form-child relative'}>
        <p className='mb-[3px] text-sm text-[#384048]'>{label || 'Upload supporting documents'} </p>
        <div
          style={{ height: '44px' }}
          onClick={onClick}
          className='w-full px-3 rmd flexed curp border border-dashed border-[#2B708E]'>
          <RiUpload2Fill className='text-2xl w-full m-auto text-[#194153]' />

          <input
            onChange={handleFileChange}
            type='file'
            ref={fileRef}
            style={{ display: 'none' }}
          />
        </div>
        <p
          className={
            isFull ? 'absolute bottom-0 left-3 text-xs' : 'text-xs mb-0 absolute bottom-1 left-3'
          }>
          {(defaultValue.length > 0 || fileProps.name) && fileProps.name.length > 0
            ? `${fileProps?.name?.substring(0, 9)}${
                fileProps.name.length > 9
                  ? `...${fileProps?.name?.substring(fileProps?.name.length - 1)}.${extension}`
                  : ''
              }`
            : defaultValue.length > 0
            ? `${defaultValue?.substring(0, 9)}${
                defaultValue.length > 9
                  ? `...${fileProps?.name?.substring(fileProps?.name.length - 1)}.${extension}`
                  : ''
              }`
            : ''}
        </p>
        {(defaultValue || fileProps.string.length > 0) && hasDeleteBtn && (
          <div
            onClick={handleDeleteFile}
            className='flex z-[100] absolute cursor-pointer right-2 bottom-[0.53rem] '>
            <AiFillDelete className='text-2xl  text-red-500' />
          </div>
        )}
      </div>
    );
  };

  const UploadUIv2 = ({
    label,
    isFull,
    hasDeleteBtn = false,
    defaultValue = '',
    useRawFileName = false,
    extraFunction = () => {}
  }) => {
    const handleDeleteFile = (e) => {
		e.stopPropagation()
      setFileProps({
        name: '',
        string: '',
        encoded: '',
        extension: ''
      });
      extraFunction();
    };

    const showFileName = defaultValue?.length > 0 || fileProps.name;
    const truncatedName =
      (defaultValue.length > 0 || fileProps.name) && fileProps.name.length > 0
        ? `${fileProps?.name?.substring(0, 9)}${fileProps.name.length > 9 ? `...` : ''}`
        : defaultValue.length > 0
        ? `${defaultValue?.substring(0, 9)}${defaultValue.length > 9 ? `...` : ''}`
        : '';

    return (
      <div className={isFull ? 'w-full relative' : 'form-child relative'}>
        <div
          onClick={onClick}
          className='bg-[#F4F8FB] w-full p-5 min-h-[4rem]'>
          <div className='flex gap-5'>
            <span>
              <MemoizedUploadIcon />
            </span>
            <span
              className={`inline-flex ${
                showFileName
                  ? 'flex-row basis-[95%] justify-between items-center'
                  : 'flex-col gap-1'
              }`}>
              {showFileName ? (
                <>
                  <p className='text-xs !mb-0'>
                    {!useRawFileName ? truncatedName : defaultValue || fileProps.rawName}
                  </p>
                  <div
                    onClick={handleDeleteFile}
                    className='cursor-pointer'>
                    <AiFillDelete className='text-2xl  text-red-500' />
                  </div>
                </>
              ) : (
                <>
                  <span className='text-[#384048] text-sm'>
                    Click within the box to upload document or{' '}
                    <span className='text-[#48BBED] cursor-pointer'>Browse</span>
                  </span>
                  <span className='text-xs text-[#898C8E]'>
                    Supported files types: JPEG, PNG. Max file size: 2mb
                  </span>
                </>
              )}
            </span>
            <></>
          </div>
          <input
            onChange={handleFileChange}
            type='file'
            ref={fileRef}
            style={{ display: 'none' }}
          />
        </div>
      </div>
    );
  };

  return {
    UploadUI,
    fileProps,
    propsLoading,
    handleFileChange,
    profilePic,
    fileRef,
    onClick,
    btnActive,
    setBtnActive,
    UploadUIv2
  };
};

export default useFileUploader;
