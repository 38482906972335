import QwidButton from 'components/misc/QwidButton';
import Image from 'next/image';
import { useEffect, useState } from 'react';

type Props = {
  children?: React.ReactNode;
  imgUrl: string;
  vectorUrl?: string;
  bgColor: string;
  subtext: string | JSX.Element;
  buttonText: string;
  onProceed: () => void;
  onClose?: () => void;
  visible?: boolean;
  isAuth?: boolean;
  personalizedElement?: JSX.Element;
};
// To be moved soon
const CloseModalButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <span
      onClick={onClose}
      tabIndex={0}
      role='button'
      className='absolute right-2 top-2 group'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'>
        <path
          d='M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z'
          fill='none'
          className='fill-[#292D32] transition-colors duration-300 group-hover:fill-[#48BBED]'
        />
        <path
          d='M9.16986 15.5804C8.97986 15.5804 8.78986 15.5104 8.63986 15.3604C8.34986 15.0704 8.34986 14.5904 8.63986 14.3004L14.2999 8.64035C14.5899 8.35035 15.0699 8.35035 15.3599 8.64035C15.6499 8.93035 15.6499 9.41035 15.3599 9.70035L9.69986 15.3604C9.55986 15.5104 9.35986 15.5804 9.16986 15.5804Z'
          fill='none'
          className='fill-[#292D32] transition-colors duration-300 group-hover:fill-[#48BBED]'
        />
        <path
          d='M14.8299 15.5804C14.6399 15.5804 14.4499 15.5104 14.2999 15.3604L8.63986 9.70035C8.34986 9.41035 8.34986 8.93035 8.63986 8.64035C8.92986 8.35035 9.40986 8.35035 9.69986 8.64035L15.3599 14.3004C15.6499 14.5904 15.6499 15.0704 15.3599 15.3604C15.2099 15.5104 15.0199 15.5804 14.8299 15.5804Z'
          fill='none'
          className='fill-[#292D32] transition-colors duration-300 group-hover:fill-[#48BBED]'
        />
      </svg>
    </span>
  );
};

export const TransitionModal = ({
  buttonText,
  onProceed,
  subtext,
  onClose,
  imgUrl,
  visible,
  vectorUrl,
  isAuth,
  bgColor,
  personalizedElement
}: Props) => {
  const closeModal = () => {
    if (onClose) onClose();
  };
  return (
    <section
      className={`bg-[#00000066] fixed top-0 left-0 right-0 bottom-0 backdrop-blur-[2px] flex justify-center items-center w-[100%] h-[100%] z-50 ${
        !visible ? 'hidden' : ''
      }`}
      onClick={() => closeModal()}>
      <div
        className='bg-white md:w-[30rem] w-[25rem] p-0 m-0 rounded-t-[0.6rem] pb-[1rem] rounded-b-[0.6rem]'
        onClick={(e) => e.stopPropagation()}>
        <div className={`rounded-t-[0.6rem] ${bgColor} h-[9.5rem]  w-[100%] relative`} style={{...(vectorUrl && {backgroundImage: `url(${vectorUrl})`, backgroundSize: 'inherit'})}}>
          <CloseModalButton onClose={closeModal} />
          <div className='absolute left-[4rem] md:left-4 bottom-[-2rem] z-50 w-full    md:bottom-[-3rem]'>
            <div className='scale-[2.1] w-full max-w-[5.5rem] absolute left-[2.5rem] bottom-[1.9rem]'>
            <Image
              priority
              src={imgUrl}
              alt='....'
              width={'100%'}
              height={'100%'}
              className=' w-full  object-contain'
            />
            </div>
          </div>
        </div>
        {personalizedElement ? 
          personalizedElement
          : (
            <div className='p-3'>
         
          <div className='mt-8'>
          {isAuth && <h3 className='text-[0.8rem] md:text-[1rem] sm:text-left font-medium'>Set up Transaction Pin</h3>}
          <p className='text-[0.9rem] px-2'>{subtext}</p>
          </div>
          <div className='flex justify-end'>
            <QwidButton
              darkBlueBg= {isAuth === true}
              text={buttonText}
              onClick={onProceed}
            />
          </div>
        </div>
          )
         }
      </div>
    </section>
  );
};
