
import { Spinner, useKyc, useToolkit } from 'components';
import SummaryContainer from './summaryContainer/SummaryContainer';
import Info from './summaryContainer/SummaryInfo';
import { useEffect, useState } from 'react';
import SummaryWrapper from './summaryContainer/SummaryWrapper';
import CenterModal from 'components/misc/CenterModal';
import DeleteShareHolder from 'components/onboarding/business/DirectorsShareholders/DeleteShareHolder';
import useOnboarding from "utils/hooks/onboarding/useOnboarding";
function KycKyb() {
  const [switchArr, setSwitchArr] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [switchPayload, setSwitchPayload] = useState<any>(null);
  const [switchTab, setSwitchTab] = useState<number | null>(null);
  const [title, setTitle] = useState('');
  const [addOpen, setAddOpen] = useState(false);
  const [deleteProsp, setDeleteProsp] = useState();
  const [editDirector,setEditDirector] = useState();
  const { userObj , isPersonalAccount ,isBusinessAccount  } = useToolkit();
  const { countryNameFromISO } = useOnboarding('business')
 
 
  const personalDetails = {
    First_name: userObj?.firstName,
    Last_name: userObj?.lastName,
    Date_of_birth: userObj?.dob,
    Email_address: userObj?.email,
    Nationality: userObj?.nationality,
    Employment: userObj?.employmentStatus,
  };
  const contactDetails = {
    Phone_number: `${userObj?.mobileCode}${userObj?.mobile}`,
    Country_of_residence: userObj?.country,
    Address: userObj?.address,
    State: userObj?.state,
    City: userObj?.city,
    Postal_code: userObj?.zipcode,
  };
  const identificationDetails = {
    Type_of_ID: userObj?.verification?.identity_type,
    Identity_no: userObj?.verification?.identity_number,
    Issuing_country:countryNameFromISO(userObj?.verification?.country),
    ID_issue_date: userObj?.verification?.issue_date,
    ID_expiry_date: userObj?.verification?.expiry_date
    ,
  };

  const BusinessInformation  = {
    Business_reg_num: userObj?.businessinfo?.businessRegistrationNumber,
    Tax_number: userObj?.businessinfo?.businessTaxNumber,
    Country_of_registration: userObj?.businessinfo?.businessCountry,
    Tax_issuing_country: countryNameFromISO(userObj?.businessinfo?.businessTaxIssuingCountry),
    Business_type: userObj?.businessinfo?.businessProductType,
    Industry_sector: userObj?.businessinfo?.industrySector,
    Business_Name : userObj?.businessinfo?.businessName,
    
  };
  
  const identificationDetailsArrays : any = userObj?.directorinfo?.map( (details :any) => ({
    First_name: details?.first_name || '--',
    Last_name: details?.last_name || '--',
    Date_of_birth: details?.dob || '--',
    Email_address: details?.email || '--',
    Nationality: details?.nationality || '--',
    Phone_number: `${details?.mobileCode || '--'} ${details?.mobile || '--'}`,
    Country_of_residence: details?.country || '--',
    Address: `${details?.house_number || '--'} ${details?.address || '--'}`,
    State: details?.state || '--',
    City: details?.city || '--',
    Tax_number: details?.tax_number || '--',
    Tax_issuing_country:  countryNameFromISO(details?.tax_issuing_country) || '--',
    Bank_verification_number: details?.bvn || '--',
    Is_PEP: details?.pep || '--',
  })) || [];
  

  useEffect(() => {
    switch (switchTab) {
      case 0:
        setSwitchPayload(personalDetails);
        setTitle('Personal Information');
        break;
      case 1:
        setSwitchPayload(contactDetails);
        setTitle('Contact Information');
        break;
      case 2:
        setSwitchPayload(identificationDetails);
        setTitle('Identity Information');
        break;
      case 3:
        setSwitchPayload(BusinessInformation);
        setTitle('Business Information');
        break;
      default:
        setSwitchPayload(null);
        setTitle('');
    }
  }, [switchTab]);

  const openModal = (details: any) => {
    setDeleteModalOpen(true);
    setDeleteProsp(details);
  };
  const addDirector = () => {
    setAddOpen(true);
  };
  return (
    <>
      <CenterModal control={deleteModalOpen}>
        <DeleteShareHolder
          deleteProsp={deleteProsp}
          setDeleteModalOpen={setDeleteModalOpen}
          closeModal={() => setDeleteModalOpen(false)}
        />
      </CenterModal>
      {switchTab !== null || addOpen === true ? (
        <>
          <SummaryWrapper
            title={title}
            setSwitchTab={setSwitchTab}
            switchPayload={switchPayload}
            switchTab={switchTab}
            addOpen={addOpen}
            setAddOpen={setAddOpen}
          />
        </>
      ) : (
        <>
        {/* { isBusinessAccount  &&   <h2 className="text-[0.8rem] md:text-[1rem] font-semibold "> Personal Information</h2>} */}
          <div className="  flex flex-col">
		
            <SummaryContainer
              title="Personal Infomation"
              editAction={() => setSwitchTab(0)}
            >
              <div className="flex flex-col w-full gap-[1.2rem]">
                <div>
                  <div className="grid w-full sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {Object.entries(personalDetails).map(([key, value]) => (
                      <Info key={key} name={key} value={value} />
                    ))}
                  </div>
                </div>
              </div>
            </SummaryContainer>
            <div className="my-6">
              <SummaryContainer
                title="Contact Information"
                editAction={() => setSwitchTab(1)}
              >
                <div className="flex flex-col w-full gap-[1.2rem]">
                  <div>
                    <div className="grid w-full sm:grid-cols-2 md:grid-cols-3 gap-4">
                      {Object.entries(contactDetails).map(([key, value]) => (
                        <Info key={key} name={key} value={value} />
                      ))}
                    </div>
                  </div>
                </div>
              </SummaryContainer>
            </div>
            {
              !isBusinessAccount && (
            <div className="my-6">
              <SummaryContainer
                title="Identify Information"
                editAction={() => setSwitchTab(2)}
              >
                <div className="flex flex-col w-full gap-[1.2rem]">
                  <div>
                    <div className="grid w-full sm:grid-cols-2 md:grid-cols-3 gap-4">
                      {Object.entries(identificationDetails).map(
                        ([key, value]) =>{
                          if(key === 'ID_issue_date' || key === 'ID_expiry_date'){
                            return  (
                              value && <Info key={key} name={key} value={value} />
                            )
                            
                          }
                          return (<Info key={key} name={key} value={value} />)
                        }
                      )}
                    </div>
                  </div>
                </div>
              </SummaryContainer>
            </div>
                
              )
            }

          </div>
       {
        isBusinessAccount  && (
          <>
          <div className="my-6">
            {/* <h2 className="text-[0.8rem] md:text-[1rem] font-semibold"> Business Infomation</h2> */}
            <SummaryContainer
              title="Business Infomation"
              editAction={() => setSwitchTab(3)}
            >
              <div className="flex flex-col w-full gap-[1.2rem]">
                <div>
                  <div className="grid w-full sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {Object.entries(BusinessInformation).map(([key, value]) => (
                      <Info key={key} name={key} value={value} />
                    ))}
                  </div>
                </div>
              </div>
            </SummaryContainer>
          </div>

          <div className="my-6 ">
            {/* <div className="flex">
              <h2 className="text-[0.8rem] md:text-[1rem] font-semibold">Directors & Shareholders</h2>
              <p onClick={addDirector} className="flex ml-7 cursor-pointer">
                Add{' '}
                <span className="ml-1">
                  <img src="/assets/dashboard/add-square.png" />
                </span>{' '}
              </p>
            </div> */}

            {identificationDetailsArrays.map((details : any , index: any) => (
              <div className="mb-7" key={index}>
                <SummaryContainer
                  key={index}
                  deleteBtn
                  title={`${details?.First_name}`}
                  deleteAction={() => openModal(details)}
                  // editAction={() => setEditDirector(details)}
                >
                  <div className="flex flex-col w-full gap-[1.2rem]">
                    <div className="grid w-full sm:grid-cols-2 md:grid-cols-3 gap-4">
                      {Object.entries(details).map(([key, value]) => (
                        <Info
                          key={`${index}-${key}`}
                          name={key}
                          value={value}
                        />
                      ))}
                    </div>
                  </div>
                </SummaryContainer>
              </div>
            ))}
          </div>
          
          
          </>
        )
       }

        </>
      )}
    </>
  );
}

export default KycKyb;