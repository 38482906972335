import React, { useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const useTawk = () => {
	const tawkMessengerRef = useRef(null);

	const tawkPropertyID = process.env.NEXT_PUBLIC_TAWK_PROPERTY_ID;
	const tawkWidgetID = process.env.NEXT_PUBLIC_TAWK_WIDGET_ID;

	const Widget = () => (
		<TawkMessengerReact
			ref={tawkMessengerRef}
			propertyId={tawkPropertyID}
			widgetId={tawkWidgetID}
		/>
	);

	return {
		Widget,
		tawkMessengerRef,
	};
};

export default useTawk;
