import { QwidButton, useFramer, useToolkit } from "../../../components";
import { motion } from "framer-motion";
import { AUTH_ROUTES } from "utils/appData/appRoutes";

type Props = {};

const SecureSolutions = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();
	const { router, logout, isValidUser } = useToolkit();

	return (
		<div className="bwite pb-8 lg:pb-16">
			<p className="my-8 text-xl lg:text-4xl font-semibold text-[#333333] tcent">
				Secure payment solutions <br /> you can bank on
			</p>
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper lg:items-start">
				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-9 btn-text">CREATE A BUSINESS ACCOUNT</p>
					<p className="mb-0 smb text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						Simplify funding and settlements{" "}
					</p>

					<p className="my-7 text-center lg:t6 lg:text-left">
						Navigate global transactions with ease using qwid's wide range of
						payment solutions. Effortlessly complete transactions, eliminate
						currency barriers, and always stay one step ahead on your path to
						success.
					</p>

					<QwidButton
						text={<span className="px-4">Get started</span>}
						onClick={() => {
							if (isValidUser) {
								logout();
							}
							router.push(AUTH_ROUTES.SIGN_UP);
						}}
					/>
				</motion.div>

				<motion.div
					variants={flashIn}
					className="bg-[#A9E1F9] rounded-lg w-full md:w-2/4 lg:w-2/4 h-[300px] md:h-[350px] flex items-start justify-center relative">
					<div className="w-3/4 -bottom-1.5 mx-auto flex justify-center items-end h-full">
						<img
							alt="how-to-send"
							src="/assets/business/secure-solutions.svg"
							className="object-cover lg:h-[300px]"
						/>
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default SecureSolutions;
