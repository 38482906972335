const AUTH_ROUTES = {
	CHANGE_PASSWORD: "/auth/change-password",
	RESET_PASSWORD: "/resetPassword",
	SIGN_IN: "/auth/signin",
	SIGN_UP: "/auth/signup",
	VERIFY_EMAIL: "/auth/verify-email",
	PERSONAL_SIGN_UP: "/auth/signup/personal",
	BUSINESS_SIGN_UP: "/auth/signup/business",
	TWOFA: "/auth/two-factor-auth",
};

const LANDING_ROUTES = {
	PERSONAL_HOMEPAGE: "/",
	BUSINESS_HOMEPAGE: "/business",
	FAQS: "/faqs",
	PRIVACY_POLICY: "/privacy-policy",
	TERMS_OF_USE: "/terms",
	BLOG_HOME_PAGE: "/blog",
};

const FEATURE_ROUTES = {
	BANK_TRANSFER: "/features/bank-transfer",
	VIRTUAL_ACCOUNTS: "/features/virtual-wallets",
	VIRTUAL_CARDS: "/features/virtual-cards",
	CONVERSIONS: "/features/conversions",
	WITHDRAWALS: "/features/withdrawals",
};

const ONBOARDING_ROUTES = {
	KYC: "/onboarding/kyc",
	KYB: "/onboarding/kyb",
	PERSONAL: {
		PERSONAL_INFO: "/onboarding/personal/personal-information",
		CONTACT_INFO: "/onboarding/personal/contact-information",
		SUCCESS_PAGE: "/onboarding/personal/success",
	},
	BUSINESS: {
		BUSINESS_TYPE: "/onboarding/business",
		BUSINESS_FORM: "/onboarding/business/business-form",
		SUCCESS_PAGE: "/onboarding/business/success",
	},
	TIERED_ONBOARDING: {
		CONTACT_INFORMATION: '/onboarding/tier/contact-information',
		// tier 0-1
		ADDITIONAL_INFORMATION: '/onboarding/tier/additional-information',
		// tier 1-2
		IDENTITY_SUBMISSION: '/onboarding/tier/identity-submission',
		// tier 2-3
		ADDRESS_VERIFICATION: '/onboarding/tier/address-verification'
	}
};

const DASHBOARD_ROUTES = {
	DASHBOARD: "/user/dashboard",
	TOPUP_TRANSFER: "/user/dashboard/topup/bank-transfer",
	TOPUP_DIRECT: "/user/dashboard/topup/direct-pay-in",
	SEND: "/user/dashboard/send",
	ADD_FUND: "/user/dashboard/add-funds",
	SEND_INTERNAL: "/user/dashboard/send/internal-transfer",
	SEND_TO_OWN_ACCOUNT: "/user/dashboard/send/withdrawal",
	SEND_REMITTANCE: "/user/dashboard/send/remittance",
	SEND_BY_REMITTANCE: "/user/dashboard/send-funds/remittance",
	SEND_SAVED_BENEFICIARY: "/user/dashboard/send-funds/saved-beneficiary",
	WALLET_BALANCE: "/user/wallet-balance",
	BENEFICIARIES: "/user/beneficiaries",
	CONVERT: "/user/convert",
	CONVERSIONS: "/user/convert-funds",
	VIRTUAL_CARDS_HOME: "/user/virtual-cards",
	VIRTUAL_CARDS_CONFIGURE: "/user/virtual-cards/configure",
	SETTINGS: "/user/settings",
	CREATE_TRX_PIN: "/user/settings/pin/create",
	CHANGE_TRX_PIN: "/user/settings/pin/change",
	TRANSACTIONS: "/user/transactions",
	VIRTUAL_ACCOUNTS: "/user/virtual-wallets",
	TESTS: "/user/tests",
	RECIPIENTS: "/user/recipients",
	ADD_EXTERNAL_RECIPIENTS: '/user/recipients/add-external',
	REFERRAL: '/user/refer-and-earn',
	SECURITY_SUCCESS :'/user/settings/security-questions/success'
};

const MERCHANT_ROUTES = {
	MERCHANT_HOMEPAGE: "/user/merchant",
	MERCHANT_SETTINGS: "/user/merchant/0.7498013826150873/settings",
};

export {
	DASHBOARD_ROUTES,
	ONBOARDING_ROUTES,
	AUTH_ROUTES,
	FEATURE_ROUTES,
	LANDING_ROUTES,
	MERCHANT_ROUTES,
};
