type Props = {
	currency: string;
	sqFlags?: boolean;
};
const Flag = ({ currency, sqFlags }: Props) => {
	const CountryFlag = (name: string) => {
		switch (name) {
			case "NGN":
				return (
					<img
						src={
							sqFlags
								? "/assets/icons/wallet/nig-sq.svg"
								: "/assets/icons/wallet/nigeria.svg"
						}
						alt="ngn"
					/>
				);
			case "USD":
				return (
					<img
						src={
							sqFlags
								? "/assets/icons/wallet/usd-sq.svg"
								: "/assets/icons/wallet/usd.svg"
						}
						alt="usd"
					/>
				);
			case "GBP":
				return (
					<img
						src={
							sqFlags
								? "/assets/icons/wallet/gbp-sq.svg"
								: "/assets/icons/wallet/gbp.svg"
						}
						alt="gbp"
					/>
				);
			case "EUR":
				return (
					<img
						src={
							sqFlags
								? "/assets/icons/wallet/eur-sq.svg"
								: "/assets/icons/wallet/eur.svg"
						}
						alt="eur"
					/>
				);
			default:
				return (
					<img
						src={
							sqFlags
								? "/assets/icons/wallet/nig-sq.svg"
								: "/assets/icons/wallet/nigeria.svg"
						}
						alt="ngn"
					/>
				);
		}
	};
	return CountryFlag(currency);
};

export default Flag;
