import { useFramer } from "../../../../components";
import { motion } from "framer-motion";

type Props = {};

const Seamless = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();

	return (
		<div className="bwite lg:py-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper2">
				<motion.div
					variants={flashIn}
					className="bg-[#C4E6A9] rounded-lg w-full md:w-2/4 lg:w-2/4 h-[300px] lg:h-[500px] md:h-[400px] flex items-end justify-center relative">
					<div className="w-[98%] -bottom-1.5 mx-auto flexed">
						<img
							alt="how-to-send"
							src="/assets/features/transaction-withdrawal.png"
							className="object-cover  h-[250px] rounded-lg  md:h-[300px] lg:h-[400px]"
						/>
					</div>
				</motion.div>
				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-0 med text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						<span className="text-[#48bbed]">Flat processing fees, </span>no
						unpleasant surprises.{" "}
					</p>
					<p className="my-7 text-[14px] text-center leading-8  lg:t6 md:text-left">
						With our processing fee set in stone, we are fully transparent to
						prevent unpleasant surprises. You only pay what you see.{" "}
					</p>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default Seamless;
