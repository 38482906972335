import { Form } from "antd";
import FlaggedDropdown from "components/common/InputElements/FlaggedDropdown";
import TextInput from "components/common/InputElements/TextInput";
import {
	QwidButton,
	useToolkit,
	useLocation,
	OptionalRender,
	useSendFlow,
} from "components";
import Layout from "../internal-transfer/Layout";
import {
	CountryAmountProps,
	setWithdrawalCountryAmount,
} from "redux/slices/sendflowSlice";
import { ScaleLoader } from "react-spinners";
import { ImSpinner, ImSpinner9 } from "react-icons/im";
import { useQuery } from "react-query";
import { useMemo } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { currencySymbols } from "utils/hooks/misc/currencySymbols";

const CountryAmount = ({
	pageDecrement,
	pageIncrement,
	navigateToPage,
}: ComponentPageNavigator) => {
	const { useEffect, useState, useAppSelector, toastError, dispatch, axiosInstance, isPersonalAccount } =
		useToolkit();

	const [inputForm, setInputForm] = useState<CountryAmountProps>({
		country: "",
		amount: "",
	});

	const { getDynCountries, ctrData } = useLocation();
	const [form] = Form.useForm();
	const { withdrawalInfo } = useAppSelector((state: any) => state.sendflow);
	const {data: wdlFee, isLoading, isRefetching} = useQuery({
		queryKey:['getFee', inputForm.amount],
		queryFn: async()=>{
			const data = await axiosInstance.get(
				`fee?transType=withdraw&userType=${isPersonalAccount ? 1 : 2}&peer=${
				  withdrawalInfo.withdrawalCurrency
				}&amount=${inputForm.amount}`
			  );
			  const response = data?.data?.content?.data[0];
			return response;
		},
		enabled: !!inputForm.amount
	})
	const onProceed = () => {
		if (
			withdrawalInfo?.isCreatingNewRecipient === false &&
			!inputForm.amount?.length
		) {
			toastError("Please enter an amount");
		} else if (
			withdrawalInfo?.isCreatingNewRecipient === true &&
			withdrawalInfo?.withdrawalCurrency !== "NGN" &&
			(!inputForm?.country?.length || !inputForm.amount?.length)
		) {
			toastError("Please fill all fields");
		} else {
			if (withdrawalInfo?.isCreatingNewRecipient === false) {
				const payload = {
					country: withdrawalInfo?.withdrawalCountry,
					amount: inputForm?.amount,
				};
				dispatch(setWithdrawalCountryAmount(payload));
				navigateToPage && navigateToPage(4);
			} else {
				dispatch(setWithdrawalCountryAmount(inputForm));
				pageIncrement && pageIncrement();
			}
		}
	};

	const initialValues = {
		country: withdrawalInfo?.withdrawalCountry,
		amount: withdrawalInfo?.withdrawalAmount,
	};

	useEffect(() => {
		getDynCountries();
	}, []);

	useEffect(() => {
		if (withdrawalInfo?.withdrawalAmount || withdrawalInfo?.withdrawalCountry) {
			setInputForm({
				country: withdrawalInfo?.withdrawalCountry,
				amount: withdrawalInfo?.withdrawalAmount,
			});
		}
	}, []);

	const errorMessage = useMemo(()=>{
		if (isLoading) return undefined;
		else if(+inputForm.amount === 0){
		  return `amount must be greater than zero`
		}
		else if (
			wdlFee?.amount !== undefined &&
			wdlFee?.maxLimit !== undefined &&
			+wdlFee?.amount > +wdlFee?.maxLimit
		  ) {
			return `You cannot send above a maximum limit of ${(+wdlFee?.maxLimit)?.toFixed(2)} ${
			  wdlFee?.toPayCur
			}`;
		  } 
		  else if (
			wdlFee?.amount !== undefined &&
			wdlFee?.minLimit !== undefined &&
			+wdlFee?.amount < +wdlFee?.minLimit
		  ) {
			return `You cannot send below a minimum limit of ${(+wdlFee?.minLimit)?.toFixed(2)} ${
			  wdlFee?.peer
			}`;
		  }
		  return undefined;
	  },[wdlFee, inputForm.amount, isLoading])

	return (
		<Layout>
			<div className="w-full">
				<Form
					form={form}
					initialValues={initialValues}
					onFinish={onProceed}
					className="flex flex-col gap-7">
					<OptionalRender
						condition={
							withdrawalInfo?.isCreatingNewRecipient === true &&
							withdrawalInfo?.withdrawalCurrency !== "NGN"
						}>
						<FlaggedDropdown
							onChange={(e: any) => setInputForm({ ...inputForm, country: e })}
							name="country"
							options={ctrData?.slice(1)}
							noShowBalance
							isSearchable
							getFlagBy="value"
							label="Select Recipient's Country"
						/>
					</OptionalRender>
					<TextInput
						isNumberValidated
						isCommaSeperated
						label="Enter Amount"
						name="amount"
						placeholder="0.00"
						onChange={(e: any) =>
							setInputForm({ ...inputForm, amount: e !== null? e.toString() : ''})
						}
					/>
					 <OptionalRender condition={!!inputForm.amount && !!errorMessage}>
                <div className="w-full flex flex-col mt-[-0.5rem]">
                    <div className={`p-[0.6rem] w-full rounded-[4px] flex gap-2 items-center text-[0.85rem] text-white bg-[#ef4545] font-semibold `}><AiOutlineInfoCircle/> 
                    {errorMessage}
                     </div>
                </div>  
            </OptionalRender>
					<div className="w-full flex text-[0.8rem] justify-between gap-4">
						<div>Transaction fee</div>
						<div>
						<OptionalRender condition={isLoading}>
								<div className="text-[#194153] font-semibold"><ImSpinner9 className="animate-spin text-[1rem]" /></div>
							</OptionalRender>
							<OptionalRender condition={!isLoading && +inputForm.amount > 0}>
								<div className="text-[#194153] font-semibold">{currencySymbols[withdrawalInfo?.withdrawalCurrency]}{Math.ceil(((wdlFee?.toPay || 0) - (wdlFee?.amount || 0)) * 1000) / 1000} </div>
							</OptionalRender>
							<OptionalRender condition={!isLoading && +inputForm.amount <= 0}>
								<div className="text-[#194153] font-semibold">...</div>
							</OptionalRender>
						</div>
					</div>
					<div className="flex items-center justify-between mt-[1.2rem]">
						<div
							onClick={() => pageDecrement && pageDecrement()}
							className="text-[#194153] font-semibold cursor-pointer">
							Back
						</div>
						<div className="w-full max-w-[9rem]">
							<QwidButton disabled={!inputForm.amount || !!errorMessage || isLoading} text="Continue" isFull darkBlueBg />
						</div>
					</div>
				</Form>
			</div>
		</Layout>
	);
};

export default CountryAmount;
