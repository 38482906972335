export const vtnCountries = [
  { name: "ABKHAZIA", phone: "+995", code: "AB" },
  { name: "AFGHANISTAN", phone: "+93", code: "AF" },
  { name: "ALBANIA", phone: "+355", code: "AL" },
  { name: "ANDORRA", phone: "+376", code: "AD" },
  { name: "ANGOLA", phone: "+244", code: "AO" },
  { name: "ANTIGUA & BARBUDA", phone: "+1-268", code: "AG" },
  { name: "ARGENTINA", phone: "+54", code: "AR" },
  { name: "ARMENIA", phone: "+374", code: "AM" },
  { name: "AUSTRALIA", phone: "+61", code: "AU" },
  { name: "AUSTRIA", phone: "+43", code: "AT" },
  { name: "AZERBAIJAN", phone: "+994", code: "AZ" },
  { name: "BAHAMAS", phone: "+1-242", code: "BS" },
  { name: "BAHRAIN", phone: "+973", code: "BH" },
  { name: "BANGLADESH", phone: "+880", code: "BD" },
  { name: "BARBADOS", phone: "+1-246", code: "BB" },
  { name: "BELGIUM", phone: "+32", code: "BE" },
  { name: "BENIN", phone: "+229", code: "BJ" },
  { name: "BOLIVIA", phone: "+591", code: "BO" },
  { name: "BONAIRE, ST EUSTATIUS & SABA", phone: "+599", code: "BQ" },
  { name: "BOTSWANA", phone: "+267", code: "BW" },
  { name: "BRAZIL", phone: "+55", code: "BR" },
  { name: "BRITISH VIRGIN ISLANDS", phone: "+1-284", code: "VG" },
  { name: "BULGARIA", phone: "+359", code: "BG" },
  { name: "BURKINA FASO", phone: "+226", code: "BF" },
  { name: "BURUNDI", phone: "+257", code: "BI" },
  { name: "CAMEROON", phone: "+237", code: "CM" },
  { name: "CANADA", phone: "+1", code: "CA" },
  { name: "CANARY ISLANDS", phone: "+34", code: "IC" },
  { name: "CAYMAN ISLANDS", phone: "+1-345", code: "KY" },
  { name: "CENTRAL AFRICAN REPUBLIC", phone: "+236", code: "CF" },
  { name: "CHILE", phone: "+56", code: "CL" },
  { name: "CHINA", phone: "+86", code: "CN" },
  { name: "COLOMBIA", phone: "+57", code: "CO" },
  { name: "COSTA RICA", phone: "+506", code: "CR" },
  { name: "COTE D\"IVOIRE", phone: "+225", code: "CI" },
  { name: "CUBA", phone: "+53", code: "CU" },
  { name: "CYPRUS", phone: "+357", code: "CY" },
  { name: "CZECH REPUBLIC", phone: "+420", code: "CZ" },
  { name: "DENMARK", phone: "+45", code: "DK" },
  { name: "DOMINICAN REPUBLIC", phone: "+1-809, 1-829, 1-849", code: "DO" },
  { name: "ECUADOR", phone: "+593", code: "EC" },
  { name: "EGYPT", phone: "+20", code: "EG" },
  { name: "EL SALVADOR", phone: "+503", code: "SV" },
  { name: "EQUATORIAL GUINEA", phone: "+240", code: "GQ" },
  { name: "ERITREA", phone: "+291", code: "ER" },
  { name: "ESTONIA", phone: "+372", code: "EE" },
  { name: "ETHIOPIA", phone: "+251", code: "ET" },
  { name: "FINLAND", phone: "+358", code: "FI" },
  { name: "FRANCE", phone: "+33", code: "FR" },
  { name: "FRENCH GUIANA", phone: "+594", code: "GF" },
  { name: "GAMBIA", phone: "+220", code: "GM" },
  { name: "GEORGIA", phone: "+995", code: "GE" },
  { name: "GERMANY", phone: "+49", code: "DE" },
  { name: "GHANA", phone: "+233", code: "GH" },
  { name: "GREECE", phone: "+30", code: "GR" },
  { name: "GUADELOUPE", phone: "+590", code: "GP" },
  { name: "GUATEMALA", phone: "+502", code: "GT" },
  { name: "GUINEA", phone: "+224", code: "GN" },
  { name: "GUINEA-BISSAU", phone: "+245", code: "GW" },
  { name: "GUYANA", phone: "+592", code: "GY" },
  { name: "HAITI", phone: "+509", code: "HT" },
  { name: "HONDURAS", phone: "+504", code: "HN" },
  { name: "HONG KONG", phone: "+852", code: "HK" },
  { name: "HUNGARY", phone: "+36", code: "HU" },
  { name: "ICELAND", phone: "+354", code: "IS" },
  { name: "INDIA", phone: "+91", code: "IN" },
  { name: "INDONESIA", phone: "+62", code: "ID" },
  { name: "IRELAND", phone: "+353", code: "IE" },
  { name: "ISLE OF MAN", phone: "+44", code: "IM" },
  { name: "ISRAEL", phone: "+972", code: "IL" },
  { name: "ITALY", phone: "+39", code: "IT" },
  { name: "JAMAICA", phone: "+1-876", code: "JM" },
  { name: "JAPAN", phone: "+81", code: "JP" },
  { name: "JERSEY", phone: "+44-1534", code: "JE" },
  { name: "JORDAN", phone: "+962", code: "JO" },
  { name: "KAZAKHSTAN", phone: "+7", code: "KZ" },
  { name: "KENYA", phone: "+254", code: "KE" },
  { name: "KOSOVO", phone: "+383", code: "XK" },
  { name: "KUWAIT", phone: "+965", code: "KW" },
  { name: "KYRGYZSTAN", phone: "+996", code: "KG" },
  { name: "LATVIA", phone: "+371", code: "LV" },
  { name: "LEBANON", phone: "+961", code: "LB" },
  { name: "LESOTHO", phone: "+266", code: "LS" },
  { name: "LIBERIA", phone: "+231", code: "LR" },
  { name: "LIECHTENSTEIN", phone: "+423", code: "LI" },
  // { name: "LITHUANIA", phone: "+370", code: "LT" },
  { name: "LUXEMBOURG", phone: "+352", code: "LU" },
  { name: "MACAU", phone: "+853", code: "MO" },
  { name: "MADAGASCAR", phone: "+261", code: "MG" },
  { name: "MALAWI", phone: "+265", code: "MW" },
  { name: "MALAYSIA", phone: "+60", code: "MY" },
  { name: "MALDIVES", phone: "+960", code: "MV" },
  { name: "MALI", phone: "+223", code: "ML" },
  { name: "MALTA", phone: "+356", code: "MT" },
  { name: "MARTINIQUE", phone: "+596", code: "MQ" },
  { name: "MAURITANIA", phone: "+222", code: "MR" },
  { name: "MAURITIUS", phone: "+230", code: "MU" },
  { name: "MEXICO", phone: "+52", code: "MX" },
  { name: "MOLDOVA", phone: "+373", code: "MD" },
  { name: "MONACO", phone: "+377", code: "MC" },
  { name: "MONGOLIA", phone: "+976", code: "MN" },
  { name: "MONTENEGRO", phone: "+382", code: "ME" },
  { name: "MONTSERRAT", phone: "+1-664", code: "MS" },
  { name: "MOROCCO", phone: "+212", code: "MA" },
  { name: "MOZAMBIQUE", phone: "+258", code: "MZ" },
  { name: "MYANMAR", phone: "+95", code: "MM" },
  { name: "NAMIBIA", phone: "+264", code: "NA" },
  { name: "NEPAL", phone: "+977", code: "NP" },
  { name: "NETHERLANDS", phone: "+31", code: "NL" },
  { name: "NEW ZEALAND", phone: "+64", code: "NZ" },
  { name: "NICARAGUA", phone: "+505", code: "NI" },
  { name: "NIGER", phone: "+227", code: "NE" },
  { name: "NIGERIA", phone: "+234", code: "NG" },
  { name: "NORTH KOREA", phone: "+850", code: "KP" },
  { name: "NORWAY", phone: "+47", code: "NO" },
  { name: "OMAN", phone: "+968", code: "OM" },
  { name: "PAKISTAN", phone: "+92", code: "PK" },
  { name: "PALESTINE", phone: "+970", code: "PS" },
  { name: "PANAMA", phone: "+507", code: "PA" },
  { name: "PAPUA NEW GUINEA", phone: "+675", code: "PG" },
  { name: "PARAGUAY", phone: "+595", code: "PY" },
  { name: "PERU", phone: "+51", code: "PE" },
  { name: "PHILIPPINES", phone: "+63", code: "PH" },
  { name: "POLAND", phone: "+48", code: "PL" },
  { name: "PORTUGAL", phone: "+351", code: "PT" },
  { name: "PUERTO RICO", phone: "+1-787, 1-939", code: "PR" },
  { name: "QATAR", phone: "+974", code: "QA" },
  { name: "REPUBLIC OF THE CONGO", phone: "+242", code: "CG" },
  { name: "REUNION", phone: "+262", code: "RE" },
  { name: "ROMANIA", phone: "+40", code: "RO" },
  { name: "RUSSIA", phone: "+7", code: "RU" },
  { name: "RWANDA", phone: "+250", code: "RW" },
  { name: "SAINT KITTS & NEVIS", phone: "+1-869", code: "KN" },
  { name: "SAINT LUCIA", phone: "+1-758", code: "LC" },
  { name: "SAINT VINCENT & THE GRENADINES", phone: "+1-784", code: "VC" },
  { name: "SAMOA", phone: "+685", code: "WS" },
  { name: "SAN MARINO", phone: "+378", code: "SM" },
  { name: "SAO TOME & PRINCIPE", phone: "+239", code: "ST" },
  { name: "SAUDI ARABIA", phone: "+966", code: "SA" },
  { name: "SENEGAL", phone: "+221", code: "SN" },
  { name: "SERBIA", phone: "+381", code: "RS" },
  { name: "SEYCHELLES", phone: "+248", code: "SC" },
  { name: "SIERRA LEONE", phone: "+232", code: "SL" },
  { name: "SINGAPORE", phone: "+65", code: "SG" },
  { name: "SLOVAKIA", phone: "+421", code: "SK" },
  { name: "SLOVENIA", phone: "+386", code: "SI" },
  { name: "SOLOMON ISLANDS", phone: "+677", code: "SB" },
  { name: "SOMALIA", phone: "+252", code: "SO" },
  { name: "SOUTH AFRICA", phone: "+27", code: "ZA" },
  { name: "SOUTH KOREA", phone: "+82", code: "KR" },
  { name: "SOUTH SUDAN", phone: "+211", code: "SS" },
  { name: "SPAIN", phone: "+34", code: "ES" },
  { name: "SRI LANKA", phone: "+94", code: "LK" },
  { name: "SUDAN", phone: "+249", code: "SD" },
  { name: "SURINAME", phone: "+597", code: "SR" },
  { name: "SWAZILAND", phone: "+268", code: "SZ" },
  { name: "SWEDEN", phone: "+46", code: "SE" },
  { name: "SWITZERLAND", phone: "+41", code: "CH" },
  { name: "SYRIA", phone: "+963", code: "SY" },
  { name: "TAIWAN", phone: "+886", code: "TW" },
  { name: "TAJIKISTAN", phone: "+992", code: "TJ" },
  { name: "TANZANIA", phone: "+255", code: "TZ" },
  { name: "THAILAND", phone: "+66", code: "TH" },
  { name: "TOGO", phone: "+228", code: "TG" },
  { name: "TONGA", phone: "+676", code: "TO" },
  { name: "TRINIDAD & TOBAGO", phone: "+1-868", code: "TT" },
  { name: "TUNISIA", phone: "+216", code: "TN" },
  { name: "TURKEY", phone: "+90", code: "TR" },
  { name: "TURKMENISTAN", phone: "+993", code: "TM" },
  { name: "TURKS & CAICOS ISLANDS", phone: "+1-649", code: "TC" },
  { name: "UGANDA", phone: { code: "+256", exceptions: ["+256 41", "+256 41 41"] }, code: "UG" },
  { name: "UKRAINE", phone: "+380", code: "UA" },
  { name: "UNITED ARAB EMIRATES", phone: "+971", code: "AE" },
  { name: "UNITED KINGDOM", phone: "+44", code: "GB" },
  { name: "UNITED STATES", phone: "+1", code: "US" },
  { name: "URUGUAY", phone: "+598", code: "UY" },
  { name: "UZBEKISTAN", phone: "+998", code: "UZ" },
  { name: "VANUATU", phone: "+678", code: "VU" },
  { name: "VATICAN CITY", phone: "+379", code: "VA" },
  { name: "VENEZUELA", phone: "+58", code: "VE" },
  { name: "VIETNAM", phone: "+84", code: "VN" },
  { name: "YEMEN", phone: "+967", code: "YE" },
  { name: "ZAMBIA", phone: "+260", code: "ZM" },
  { name: "ZIMBABWE", phone: "+263", code: "ZW" }
];