import React from "react";
import {
  HeroSection,
  LandingFooter,
  LandingMobileSidebar,
  LandingNav,
  useToolkit,
} from "../components";

type Props = {
  BannerChildren?: JSX.Element;
  children: React.ReactNode;
  bgPath?: string;
  transparentNav?: boolean;
  darkFooter?: boolean;
};

const LandingLayout = ({
  BannerChildren,
  children,
  bgPath,
  transparentNav,
  darkFooter,
}: Props) => {
  const { HeadEl } = useToolkit();

  return (
    <>
      <HeadEl isLanding />
      <LandingNav transparentNav={transparentNav} />
      {BannerChildren && (
        <HeroSection bgPath={bgPath} BannerChildren={BannerChildren} />
      )}
      <main className="side-pads">{children}</main>
      <LandingFooter darkFooter={darkFooter} />
      <LandingMobileSidebar />
    </>
  );
};

export default LandingLayout;
