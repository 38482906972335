import { motion } from "framer-motion";
import { AUTH_ROUTES } from "utils/appData/appRoutes";
import { useFramer, useToolkit } from "../../../../components";

type FinancialFreedomProps = {
	steps?: StepProps[];
}

type StepProps = {
	title: string;
	subtitle: string;
};

const FinancialFreedom = ({steps}: FinancialFreedomProps) => {
	const { zoomWrap } = useFramer();
	const { router } = useToolkit();

	

	return (
		<div
			style={{
				background:
					"linear-gradient(99.99deg, #F5F8F8 2.65%, rgba(253, 242, 242, 0.34) 98.61%)",
			}}
			className="pb-8 p-6 lg:pb-16">
			<p className="smb mb-3 mt-3 lg:mt-0 text-2xl tcent md:text-3xl lg:text-4xl lg:text-left side-pad h-pad lg:w-2/4 lg:leading-normal">
			Never Be Stuck With One Currency{" "}
			</p>

			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="side-pad h-pad">
				<div className="flexed flex-col lg:flex-row space-y-8 lg:space-y-0 lg:justify-between lg:space-x-6">
					{steps?.map(({ title, subtitle }: StepProps, idx) => (
						<div key={title} className="w-full lg:w-[30%] flexed flex-col">
							<p className="pry-bg h-7 w-7 rounded-full flexed twite">
								{idx + 1}
							</p>
							<p className="med twide mb-3">{title}</p>
							<p className="tcent text-xs lg:text-sm">
								{subtitle}
							</p>
						</div>
					))}
				</div>

				<button
					onClick={() => router.push(AUTH_ROUTES.SIGN_UP)}
					className="my-7 outline-none smb twide text-[#0052B9] lg:text-lg">
					- Get started with qwid
				</button>
			</motion.div>
		</div>
	);
};

export default FinancialFreedom;
