import React from "react";
import { IoCheckboxOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { useFramer } from "../..";

type Props = {};

const ConvertMultiple = (props: Props) => {
	const { zoomContent, zoomWrap } = useFramer();

	const checkOptions: string[] = [
		"Amazing rates",
		"Super fast",
		"Smooth and seamless",
	];

	return (
		<div className="bwite pb-8 lg:pb-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper">
				<motion.div
					variants={zoomContent}
					className="bg-[#e3f3fa] rounded-lg w-full md:w-2/4 lg:w-2/4 h-[300px] md:h-[400px] flex items-end justify-center">
					<div className="w-3/4 -bottom-1.5 md:bottom-0 mx-auto flexed lg:pl-14">
						<img
							alt="how-to-send"
							src="/assets/landing/convert-shot.svg"
							className="object-cover h-[250px] lg:h-[350px]"
						/>
					</div>
				</motion.div>

				<motion.div
					variants={zoomContent}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-0 mt-5 lg:mt-0 smb text-center mx-auto lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						Convert To Multiple Currencies
					</p>

					<p className="my-5 text-center lg:t6 lg:text-left">
						Convert the currency you have to the currency you need! We offer
						conversions to major currencies such as USD, EUR, GBP, and much
						more.
					</p>

					<div className="flex flex-col space-y-4">
						{checkOptions.map((item, idx) => (
							<div className="flex items-center space-x-2" key={idx}>
								<IoCheckboxOutline className="text-xl text-[#1994ca]" />
								<p className="mb-0 med">{item}</p>
							</div>
						))}
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default ConvertMultiple;
