import { LandingMobileSidebar, LandingNav, useToolkit } from "components";

type Props = {
	children: React.ReactNode;
	title: string;
	hideHeader?: boolean;
};

const DocsLayout = ({ children, title, hideHeader }: Props) => {
	const { HeadEl } = useToolkit();

	return (
		<>
			<HeadEl isLanding />
			<main className="min-h-screen bg-[#e5e5e5] px-3 md:px-0 pb-20">
				<LandingNav transparentNav />
				<section className="w-full md:w-3/5 lg:w-2/4 mx-auto mt-10 md:mt-14 lg:mt-16">

					{!hideHeader &&
						<div
							className="py-6 md:py-8 pl-5 lg:pl-8 flex flex-col justify-center rounded-tl-lg rounded-tr-lg shadow-md"
							style={{
								background: "linear-gradient(90deg, #1994CA 0%, #48BBED 100%)",
							}}>
							<p className="mb-2 text-white smb twide text-lg md:text-xl lg:text-4xl">
								{title}
							</p>
							<span className="text-white text-opacity-80 text-sm tracking-tight">
								Last modified: June 5th, 2024
							</span>
						</div>
					}

					<div className="bwite p-7 rounded-bl-lg rounded-br-lg shadow-lg">
						{children}
					</div>
				</section>
			</main>
			<LandingMobileSidebar />
		</>
	);
};

export default DocsLayout;
