import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface productUpdateState {
    showProductUpdate: boolean
}

const initialState: productUpdateState = {
    showProductUpdate: false
};

export const productUpdateSlice = createSlice({
    name: 'transactions',
    initialState,

    reducers: {
        setShowProductUpdate: (state, { payload }: PayloadAction<boolean>) => {
            state.showProductUpdate = payload
        }
    }
});

export const {
    setShowProductUpdate
} = productUpdateSlice.actions;

export default productUpdateSlice.reducer;
