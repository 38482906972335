import { useFramer } from "../../../../components";
import { motion } from "framer-motion";

type Props = {};

const OnlinePayment = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();

	return (
		<div className="bwite py-10 lg:py-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper">
				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-0 med mt-[0rem]  md:mt-[5rem] text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						Enjoy Flexibility With Multiple Wallets
					</p>
					<p className="my-7 leading-8  text-center lg:t6 md:text-left">
						Why settle for one when you can enjoy all four? Enjoy more
						flexibility when receiving money, making purchases and paying online
						with virtual wallets. It’s our way of giving you more power.
					</p>
				</motion.div>
				<motion.div
					variants={flashIn}
					className="bg-[#C2BCFB] rounded-lg w-full md:w-2/4 lg:w-2/4 h-[300px] lg:h-[500px] md:h-[400px] flex items-end justify-center relative">
					<div className="w-[98%] -bottom-1.5 mx-auto flexed">
						<img
							alt="how-to-send"
							src="/assets/features/ready.jpg"
							className="object-cover h-[280px] rounded-lg  md:h-[300px] lg:h-[400px]"
						/>
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default OnlinePayment;
