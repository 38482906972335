import { Table } from "antd";
import { Spinner, useToolkit } from "components";
import Flag from "components/common/Flag";
import { useQuery } from "react-query";
import styled from "styled-components";

type Props = {};

const ConversionTable = (props: Props) => {
	const { axiosInstance, userId } = useToolkit();

	type IPairProps = {
		buy: number;
		sell: number;
		peer: string;
	};

	const getBuySell = async () => {
		const {
			data: {
				content: { data },
			},
		} = await axiosInstance.get(`buy-and-sell/${userId}`);

		const formatted = data?.map(({ peer, buy, sell }: IPairProps) => ({
			firstCur: peer.substring(0, 3),
			secondCur: peer.substring(3),
			buy_val: buy,
			sell_val: sell,
		}));
		return formatted;
	};

	const { isLoading, data: convData } = useQuery("getBuySell", getBuySell);

	const columns = [
		{
			title: "Currency",
			key: "currency",
			width: "30%",
			render: (_: any, { firstCur, secondCur }: any) => (
				<div className="flex items-center space-x-3">
					<Flag sqFlags currency={firstCur} />
					<p className="mb-0 t6 nowrap pr-10 md:pr-0 text-[#626262]">
						{firstCur} / {secondCur}
					</p>
				</div>
			),
		},
		{
			title: "We buy",
			key: "buy_val",
			width: "35%",
			render: (_: any, { buy_val }: any) => (
				<p className="mb-0 tcent t6 nowrap text-[#626262]">
					{buy_val.toFixed(6)}
				</p>
			),
		},
		{
			title: "We sell",
			key: "sell_val",
			width: "35%",
			render: (_: any, { sell_val }: any) => (
				<p className="mb-0 tcent t6 nowrap text-[#626262]">
					{sell_val.toFixed(6)}
				</p>
			),
		},
	];

	return (
		<div className="w-full ovs lg:w-3/5">
			{isLoading ? (
				<Spinner />
			) : (
				<StyledTable
					pagination={{
						defaultPageSize: 4,
						size: "small",
					}}
					columns={columns}
					dataSource={convData}
				/>
			)}
		</div>
	);
};

export default ConversionTable;

const StyledTable = styled(Table)`
	th {
		padding: 20px 20px !important;
		font-size: 16px !important;
	}

	th:nth-child(2),
	th:nth-child(3) {
		text-align: center !important;
	}

	tbody: {
		padding: 20px 15px !important;
	}
`;
