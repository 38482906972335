import React from "react";
import { motion } from "framer-motion";
import useFramer from "utils/animations/useFramer";

type Props = {};

const RangeOfServices = (props: Props) => {
	const { zoomWrap, zoomContent, flashIn } = useFramer();

	type ServiceProps = {
		bgColor: string;
		title: string;
		subtitle: string;
		imgPath: string;
		lightText?: boolean;
		imgClass?: string;
	};

	const services: ServiceProps[] = [
		{
			bgColor: "#48BBED",
			title: "Online Banking",
			subtitle:
				"Enjoy multi currency banking. Get an IBAN, routing number, sort code and more.",
			imgPath: "/assets/business/online-banking.svg",
			lightText: true,
			imgClass: "w-[150px] lg:w-[300px] mt-6",
		},
		{
			bgColor: "#fff",
			title: "Secure Transactions",
			subtitle:
				"Fortified with military-grade encryption protocols, we guarantee safe and secured transactions that allows you carry out business with peace of mind",
			imgPath: "/assets/business/verification.svg",
			imgClass: "w-[130px] lg:w-[220px]",
		},
		{
			bgColor: "#EDEDED",
			title: "Cross-border Transactions",
			subtitle:
				"Transact without borders on Qwid. With virtual accounts in major currencies around the world, you can make payments to more than 70 countries with amazing speed",
			imgPath: "/assets/business/easy-conversion.svg",
			imgClass: "w-[220px] lg:w-[450px]",
		},
		{
			bgColor: "#A9E1F9",
			title: "Conversions at Amazing Rates",
			subtitle:
				"Enjoy seamless conversions at amazing rates on Qwid. Convert at will and from any currency at no extra cost",
			imgPath: "/assets/business/funds-lock.svg",
			imgClass: "w-[200px] lg:w-[400px]",
		},
	];

	return (
		<div className="side-pad h-pad bg-[#FBFBFB]">
			<div
				className="
			 border-t border-[#666666] pt-12 pb-10">
				<p className="mb-10 lg:mb-14 med tcent text-xl lg:text-2xl">
					We offer a comprehensive range of services to help your business grow
				</p>

     



				<div className="flex flex-wrap justify-center items-start lg:justify-between mt-7">
					{services.map(
						(
							{ bgColor, title, subtitle, imgPath, lightText, imgClass },
							idx
						) => (
							<div
								style={{ backgroundColor: bgColor }}
								className="w-full md:w-[47%] lg:w-[49%] p-10 rounded-lg mb-10 lg:h-[450px] shadow-md"
								key={imgPath}>
								<div className="tcleft">
									<p
										className={`mb-6 med tracking-wide text-lg lg:text-xl ${
											lightText ? "text-white" : "blk"
										}`}>
										{title}
									</p>
									<p
										className={`mb-8 tracking-wide ${
											lightText ? "text-white" : "blk"
										}`}>
										{subtitle}
									</p>
								</div>
								<div className="w-full flex items-start justify-center">
									<img src={imgPath} alt={title} className={imgClass} />
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</div>
	);
};

export default RangeOfServices;
