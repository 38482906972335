import { motion } from "framer-motion";
import { useFramer } from "../../../../components";

type Props = {};

const ConnectingYou = (props: Props) => {
	const { zoomContent, zoomWrap, flashIn } = useFramer();

	return (
		<div className="bwite pb-8 lg:pb-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper2 ">
				<motion.div
					variants={flashIn}
					className="  md:w-2/4 lg:w-2/4 h-[300px] md:h-[300px] lg:h-[400px] sm:h-[200px]  flex  justify-center relative">
					<div className=" -bottom-1.5 mx-auto  h-full w-full lg:h-[400px] ">
						<img
							alt="how-to-send"
							src="/assets/features/jack.png"
							className="object-cover h-[300px] rounded-lg  md:h-[380px] lg:h-[450px]"
						/>
					</div>
				</motion.div>

				<motion.div
					variants={zoomContent}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-0 mt-[0rem] md:mt-5 lg:mt-0 med text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						Get money sent to your virtual account for{" "}
						<span className="text-[#48bbed]">free</span>
					</p>

					<p className="my-5 leading-8  text-center lg:t6 md:text-left">
						Avoid heavy charges on domiciliary accounts and get paid in foreign
						currency with your virtual account for free. No heavy charges or
						expensive sign up fee.
					</p>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default ConnectingYou;
