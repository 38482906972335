import React from "react";
import { motion } from "framer-motion";
import useToolkit from "../../../utils/hooks/misc/useToolkit";
import useFramer from "utils/animations/useFramer";

type Props = {};
type FigureProps = {
	value: string;
	title: string;
	subtitle: string;
};

const IncreaseConversion = (props: Props) => {
	const { isMobile } = useToolkit();
	const { zoomWrap, zoomContent, flashIn } = useFramer();

	const figures: FigureProps[] = [
		{
			value: "70%+",
			title: "Active Users",
			subtitle:
				"Enjoy multi currency banking. Get an IBAN, routing number, sort code and more...",
		},
		{
			value: "34%+",
			title: "Increased Sales",
			subtitle:
				"Enjoy multi currency banking. Get an IBAN, routing number, sort code and more...",
		},
		{
			value: "100%",
			title: "Conversion Rates",
			subtitle:
				"Enjoy multi currency banking. Get an IBAN, routing number, sort code and more...",
		},
		{
			value: "500k",
			title: "Complete Transactions",
			subtitle:
				"Enjoy multi currency banking. Get an IBAN, routing number, sort code and more...",
		},
	];

	return (
		<motion.div
			variants={zoomWrap}
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true }}
			className="bg-[#FBFBFB] side-pad h-pad">
			<motion.p
				variants={flashIn}
				style={{ lineHeight: isMobile ? "40px" : "70px" }}
				className="mt-8 lg:mt-14 blk mb-5 text-3xl lg:text-6xl lg:w-3/4 med tcleft">
				Facilitating business growth with the numbers to prove it{" "}
			</motion.p>

			<motion.div
				variants={flashIn}
				className="mt-10 py-10 lg:py-20 flex items-center justify-center lg:justify-between flex-wrap lg:flex-nowrap">
				{figures.map(({ value, title, subtitle }) => (
					<div
						className="w-full md:w-[45%] lg:w-[23%] mb-9 lg:mb-0 tcleft"
						key={value}>
						<p className="pry-text text-2xl lg:text-4xl med mb-0">{value}</p>
						<p className="my-3.5 med lg:text-lg">{title}</p>
						{/* <p className="mb-0 text-sm tracking-wide">{subtitle}</p> */}
					</div>
				))}
			</motion.div>
		</motion.div>
	);
};

export default IncreaseConversion;
