import {
	CenterModal,
	useToolkit,
	QwidButton,
	Spinner,
	VirtualCardWrapper,
} from "components";
import { toggleDisableCardModal } from "redux/slices/virtualsSlice";

type Props = {
	isDeleting?: boolean;
	cardDisabled: boolean;
	reloadFn: () => NodeJS.Timeout;
};

const DisableCardModal = ({ cardDisabled, reloadFn, isDeleting }: Props) => {
	const { useAppSelector, dispatch, useState, axiosInstance, toastSuccess } =
		useToolkit();
	const { isDisableCardModalOpen, selectedVCard: card } = useAppSelector(
		(state) => state.virtuals
	);
	const [statusLoading, setStatusLoading] = useState(false);

	const closeModal = () => dispatch(toggleDisableCardModal());

	const toggleStatus = async (payload: any) => {
		setStatusLoading(true);
		try {
			const { data } = await axiosInstance.put(
				"virtualCard/freeze-and-activate",
				payload
			);
			closeModal();
			toastSuccess(data?.message);
			setStatusLoading(false);
			reloadFn();
		} catch (error) {
			setStatusLoading(false);
		}
	};

	const onStatusToggle = () => {
		const payload = {
			virtualCardId: card?.virtualCardId,
			mode: "activate",
			status: cardDisabled ? 1 : 2,
		};
		toggleStatus(payload);
	};

	const cardDataObj = JSON.parse(card?.virtualCardData);

	const cardCur = card?.virtualCardCur;

	return (
		<CenterModal
			control={isDisableCardModalOpen}
			onClose={closeModal}
			isCustom
			className="w-full md:w-3/4 lg:w-2/4 min-h-[200px] p-5 pb-14 lg:pb-16 bg-[#FBFBFB]">
			<div className="w-full">
				{isDeleting ? (
					<p className="tcent text-lg lg:text-xl med my-5">
						Upon deleting this card, it would no longer be available for use,
						and its balance will be credited to your {cardCur} wallet.
					</p>
				) : (
					<p className="tcent text-lg lg:text-xl med my-5">
						Are you sure you want to {cardDisabled ? "enable" : "disable"} this
						card?{" "}
					</p>
				)}
				{statusLoading ? (
					<Spinner />
				) : (
					<>
						<div className="flexed flex-col mx-auto lg:w-4/5">
							<div className="w-full md:w-3/4 lg:w-3/5 py-5 flexed">
								<VirtualCardWrapper
									isListItem
									cardData={cardDataObj}
									bgColor={card?.virtualCardColor || ""}
									card={card}
								/>
							</div>

							<div className="tcent my-7">
								<p className="mb-2 twide text-lg lg:text-2xl">
									Available Balance
								</p>
								<h4 className="twide text-lg lg:text-2xl">
									{card?.virtualCardBalance} {cardCur}
								</h4>
							</div>
						</div>

						<div className="flexed space-x-6">
							{!cardDisabled && (
								<QwidButton
									isDarkBordered
									onClick={closeModal}
									text={<span className="px-7">Keep it</span>}
								/>
							)}
							<QwidButton
								darkBlueBg
								onClick={onStatusToggle}
								text={
									isDeleting ? (
										<span className="px-7">Yes, Delete</span>
									) : (
										<span className="px-7">
											{cardDisabled ? "Enable" : "Disable"}
										</span>
									)
								}
							/>
						</div>
					</>
				)}
			</div>
		</CenterModal>
	);
};

export default DisableCardModal;
