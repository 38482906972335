import ProgressStepper from 'components/common/StepDisplay';

export type ContainerProps = {
  title: string;
  subTitle?: string;
  stageIndicator?: {
    totalSteps: number;
    currentStep: number;
    completedSteps: number;
  };
  children: React.ReactNode;
};

export const BodyContainer = ({ title, subTitle, stageIndicator, children }: ContainerProps) => {
  return (
    <div className='w-full max-w-[56.125rem] sm:px-8 sm:py-8 px-5 py-6 border-[1px] border-[#E7E9EB] bg-white rounded-[0.875rem]'>
      <h1 className='text-[1.2rem] font-semibold sm:text-left text-center'>{title}</h1>
      {subTitle && <p className='text-[0.8rem] mb-1 sm:text-left text-center'>{subTitle}</p>}
      {!!Object.keys(stageIndicator! || {})?.length && (
        <div className='mt-[2rem] sm:mt-[1.5rem] w-[9rem] mb-[3rem] sm:mb-[1.5rem] mr-auto ml-auto'>
          <ProgressStepper
            current={stageIndicator?.currentStep!}
            steps={stageIndicator?.totalSteps!}
            completedSteps={stageIndicator?.completedSteps}
          />
        </div>
      )}
      {children}
    </div>
  );
};
