import { Form, message } from "antd";
import {
	CustomInputField,
	QwidButton,
	useToolkit,
	CustomSelector,
} from "components";
import { Moment } from "moment";
import { updateBusinessDetails } from "redux/slices/settingsSlice";
import { businessCategories, countryInfo } from "utils/appData/settingsMisc";

type BusinessInput = {
	label?: string | undefined;
	name?: string;
	placeholder?: string | undefined;
	required?: boolean;
	Component?: JSX.Element;
};

type Props = {
	PreviousButton: () => JSX.Element;
	countriesLoading: boolean;
	countriesData: {}[];
	setCurrent: (value: React.SetStateAction<number>) => void;
};

const BusinessRegInformation = ({
	PreviousButton,
	countriesData,
	countriesLoading,
	setCurrent,
}: Props) => {
	const { useState, moment, useAppSelector, dispatch, user } = useToolkit();
	const { isFirstTimeUser } = useAppSelector((state) => state.settings);
	const [regDate, setRegDate] = useState("");

	const disabledDate = (current: any) =>
		current > moment().subtract(1, "month");

	const dateFormat = "YYYY-MM-DD";

	const inputItems: BusinessInput[] = [
		{
			Component: (
				<CustomInputField
					label="Business Name"
					nonEditable
					value={
						user?.endUser
							? user?.endUser[0]?.companyName
							: user?.enduser[0]?.companyName
					}
				/>
			),
		},
		{
			label: "Business Reg. Number",
			placeholder: "Enter reg. number",
			name: "businessRegistrationNumber",
		},
		{
			name: "regDate",
			Component: (
				<CustomInputField
					label="Business Registered Date"
					isDatePicker
					disabledDate={disabledDate}
					onDateChange={(value: Moment | null) =>
						setRegDate(moment(value).format("YYYY-MM-DD"))
					}
				/>
			),
		},

		{
			Component: (
				<CustomSelector
					customName="businessRegisteredCountry"
					customLabel="Registered Country"
					isAnt
					required={isFirstTimeUser}
					options={countriesData}
					placeholder={
						countriesLoading ? "Fetching countries..." : "Select Country"
					}
				/>
			),
		},
		{
			name: "tradingName",
			required: false,
			label: "Trading Name (optional)",
			placeholder: "Enter trading name",
		},
		{
			name: "website",
			required: false,
			label: "Website (optional)",
			placeholder: "Enter website",
		},
		{
			name: "businessCategory",
			Component: (
				<CustomSelector
					isAnt
					required={isFirstTimeUser}
					options={businessCategories}
					placeholder="Select Category"
					customLabel="Business Category"
					customName="businessCategory"
				/>
			),
		},

		{
			name: "businessProduct",
			label: "Business Product",
			placeholder: "Enter value",
		},

		{
			label: "Business Product Type",
			name: "businessProductType",
			placeholder: "Enter Type",
		},

		{
			name: "businessProgram",
			Component: (
				<CustomSelector
					isAnt
					required={isFirstTimeUser}
					options={[
						{ title: "Program Manager", value: "program manager" },
						{ title: "SME", value: "sme" },
					]}
					placeholder="Select Program"
					customLabel="Business Program"
					customName="businessProgram"
				/>
			),
		},
		{
			label: "Business Address",
			name: "businessAddress",
			placeholder: "Enter address",
		},
		{
			label: "Business State",
			name: "businessState",
			placeholder: "Enter state",
		},
	];

	const countryCodes = countryInfo?.map(({ phone }) => ({
		title: `+${phone}`,
		value: phone,
	}));

	const onSubmit = (values: any) => {
		if (isFirstTimeUser && !regDate?.length) {
			message.warning("Please enter business registration date");
		} else {
			const payload = {
				...values,
				companyName: user?.endUser
					? user?.endUser[0]?.companyName
					: user?.enduser[0]?.companyName,
				businessRegisteredDate: regDate,
			};
			dispatch(updateBusinessDetails(payload));
			setCurrent((prev) => prev + 1);
		}
	};

	return (
		<Form onFinish={onSubmit}>
			<div className="w-full flex items-end justify-center md:justify-between flex-wrap">
				{inputItems?.map(
					({ label, name, required, Component, placeholder }: BusinessInput) =>
						Component ? (
							<div
								key={name || label || Math.random()}
								className="form-child3 mb-8">
								{Component}
							</div>
						) : (
							<div
								key={name || label || Math.random()}
								className="form-child3 mb-8">
								<CustomInputField
									required={required || isFirstTimeUser}
									label={label || ""}
									name={name}
									placeholder={placeholder || `Enter ${label}`}
								/>
							</div>
						)
				)}

				<div className="flex items-end justify-center md:justify-between w-full flex-wrap">
					<div className="form-child3 mbs-8">
						<CustomInputField
							required={isFirstTimeUser}
							label="Business City"
							name="businessCity"
							placeholder="Enter City"
						/>
					</div>
					<div className="flex items-end flex-wrap md:flex-nowrap w-full md:w-[65%] pt-4 mb-6 md:mb-0 mt-7 md:mt-0">
						<div className="w-full md:w-[250px] mb-7 md:mb-0">
							<CustomSelector
								customName="mobileCode"
								required={isFirstTimeUser}
								options={countryCodes}
								firstOption="Mobile code"
							/>
						</div>

						<div className="w-full">
							<CustomInputField
								required={isFirstTimeUser}
								label="Contact Phone Number"
								name="businessMobile"
								placeholder="Enter mobile number"
							/>
						</div>
					</div>{" "}
				</div>
			</div>

			<div className="mt-10 flex justify-end space-x-5">
				<PreviousButton />

				<QwidButton
					type="submit"
					text={<span className="px-5">Next</span>}
					darkBlueBg
				/>
			</div>
		</Form>
	);
};

export default BusinessRegInformation;
