import { toggleMobileNav } from "../../redux/slices/layoutSlice";
import {
	useToolkit,
	FeaturesDropdown,
	CenterModal,
	HelpDropdown,
	QwidButton,
} from "../../components";
import { MdClose } from "react-icons/md";
import {
	AUTH_ROUTES,
	DASHBOARD_ROUTES,
	LANDING_ROUTES,
} from "utils/appData/appRoutes";

type Props = {};

const LandingMobileSidebar = (props: Props) => {
	const {
		dispatch,
		useAppSelector,
		PersonalBtn,
		router,
		BusinessBtn,
		SignInBtn,
		isValidUser,
		BlogBtn
	} = useToolkit();

	const { isMobileNavOpen } = useAppSelector((state) => state.layout);

	const returnHome = () => {
		dispatch(toggleMobileNav());
		router.push(LANDING_ROUTES.PERSONAL_HOMEPAGE);
	};

	const openBusiness = () => {
		dispatch(toggleMobileNav());
		router.push(LANDING_ROUTES.BUSINESS_HOMEPAGE);
	};

	const openBlog = () => {
		dispatch(toggleMobileNav());
		router.push(LANDING_ROUTES.BLOG_HOME_PAGE);
	};

	const signIn = () => {
		dispatch(toggleMobileNav());
		router.push(isValidUser ? DASHBOARD_ROUTES.DASHBOARD : AUTH_ROUTES.SIGN_IN);
	};

	const signUp = () => {
		dispatch(toggleMobileNav());
		router.push(AUTH_ROUTES.SIGN_UP);
	};

	return (
		<CenterModal isMobileMenu control={isMobileNavOpen}>
			<div className="flex-btw mb-10 mt-3">
				<div className="curp" onClick={returnHome}>
					<img src="/assets/landing/qwidLogo.svg" width={53} alt="logo" />
				</div>
				<MdClose
					size="1.4rem"
					className="curp"
					onClick={() => dispatch(toggleMobileNav())}
				/>
			</div>

			<div className="space-y-6 flex flex-col">
				<PersonalBtn isForMobile onClick={returnHome} />
				<BlogBtn isForMobile onClick={openBlog} />
				<BusinessBtn isForMobile onClick={openBusiness} />
				<FeaturesDropdown isForMobile />
				<HelpDropdown isForMobile />
				<SignInBtn onClick={signIn} />
			</div>

			<div className="flexed mt-10">
				<QwidButton
					onClick={signUp}
					text={<span className="px-14 py-[2px]">Get Started</span>}
				/>
			</div>
		</CenterModal>
	);
};

export default LandingMobileSidebar;
