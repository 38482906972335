import { AiFillWarning } from "react-icons/ai";
import {
	toggleEpayCancellation,
	toggleEpayModal,
} from "redux/slices/dashboardSlice";
import { CenterModal, QwidButton, useDashboard, useToolkit } from "components";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";

const EpayCancellationModal = () => {
	const { useAppSelector, dispatch, router } = useToolkit();
	const { isEpayCancellationOpen } = useAppSelector((state) => state.dashboard);
	const { useCancelTopup } = useDashboard();
	const { mutate: cancelTransaction } = useCancelTopup();

	const closeModal = () => dispatch(toggleEpayCancellation());

	const onCancel = () => {
		cancelTransaction();
		closeModal();
		dispatch(toggleEpayModal());
		router.push(DASHBOARD_ROUTES.DASHBOARD);
	};

	return (
		<CenterModal control={isEpayCancellationOpen} onClose={closeModal} title="">
			<div className="flexed flex-col">
				<AiFillWarning className="tex-5xl text-red-500" size="5rem" />
				<p className="my-3 text-center lg:text-lg font-medium">
					Are you sure you wish to cancel this transaction?
				</p>
				<div className="flex items-center space-x-6">
					<QwidButton plainBordered text="Continue" onClick={closeModal} />
					<QwidButton danger text="Yes, Cancel" onClick={onCancel} />
				</div>
			</div>
		</CenterModal>
	);
};

export default EpayCancellationModal;
