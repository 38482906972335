import { Col, Row, Skeleton } from "antd";
import { QwidButton, useToolkit } from "components";
import useSecurity from "utils/hooks/settings/useSecurity";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AUTH_ROUTES, DASHBOARD_ROUTES } from "utils/appData/appRoutes";
import { setCreatePinNavigator } from "redux/slices/dashboardSlice";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type Props = {
	setIs2fa: React.Dispatch<React.SetStateAction<boolean>>;
};

const Security = ({ setIs2fa }: Props) => {
	const { useState, useEffect, router, trxPinExists, dispatch } = useToolkit();

	const {
		initiate2fa,
		loading,
		get2faStatus,
		gettingStatus,
		status,
		start2fa,
		Deactivate2fa,
		toastSuccess,
	} = useSecurity();

	const [isActive, setIsActive] = useState(
		status?.toLowerCase() === "active" ? true : false
	);

	const handleStart2fa = () => {
		initiate2fa();
	};

	useEffect(() => {
		get2faStatus();
	}, []);

	useEffect(() => {
		if (start2fa) {
			setIs2fa(true);
		}
	}, [start2fa]);
	useEffect(() => {
		setIsActive(status?.toLowerCase() === "active" ? true : false);
	}, [status]);
	const handleDeactivate2fa = () => {
		Deactivate2fa();
		setIsActive(false);
		toastSuccess("2FA enabled successfully");
	};

	const securityTabItems: SecurityTabProps[] = [
		{
			title: "Update Password",
			subtitle: "Change and create new password",
			buttonText: "Change Password",
			onBtnClick: () => router.push(AUTH_ROUTES.CHANGE_PASSWORD),
		},
		{
			title: trxPinExists ? "Change Transaction PIN" : "Create Transaction PIN",
			subtitle: trxPinExists
				? "This would require your previous PIN"
				: "Setup transaction PIN",
			buttonText: trxPinExists ? "Change PIN" : "Create PIN",
			onBtnClick: () =>{
			const payload: createPinNavigatorProps ={
				backRoute: DASHBOARD_ROUTES.SETTINGS,
				redirectRoute: DASHBOARD_ROUTES.DASHBOARD
			}
				dispatch(setCreatePinNavigator(payload))
				router.push(
					trxPinExists
						? DASHBOARD_ROUTES.CHANGE_TRX_PIN
						: DASHBOARD_ROUTES.CREATE_TRX_PIN
				)
			}
		 },
		// {
		// 	title: "Two-Factor Authentication",
		// 	isDivSubtitle: true,
		// 	subtitle: (
		// 		<>
		// 			<p className="text-[#626262] text-xs font-normal mb-2">
		// 				Enable two-factor authentication
		// 			</p>
		// 			<div className="text-[#650D00] text-xs bg-[#f09e9e] max-w-fit opacity-40 rounded-lg p-1.5">
		// 				{loading ? <Spin indicator={antIcon} /> : isActive ? "ON" : "OFF"}
		// 			</div>
		// 		</>
		// 	),
		// 	buttonText: isActive ? "Disable" : "Enable",
		// 	onBtnClick: () => (isActive ? handleDeactivate2fa() : handleStart2fa()),
		// },
	];

	return gettingStatus ? (
		<Skeleton />
	) : (
		<Row className="mt-20 flex gap-20">
			<Col span={24} md={12}>
				<div className="flex flex-col gap-12">
					{securityTabItems.map(
						({
							title,
							subtitle,
							buttonText,
							onBtnClick,
							isDivSubtitle,
						}: SecurityTabProps) => (
							<div
								key={title}
								className="flex gap-16 justify-between items-center">
								<div>
									<p className="font-medium text-[#090909] text-base mt-0 mb-4">
										{title}
									</p>
									{isDivSubtitle ? (
										<div>{subtitle}</div>
									) : (
										<p className="text-[#626262] text-xs font-normal mb-2">
											{subtitle}{" "}
										</p>
									)}
								</div>
								<QwidButton
									onClick={onBtnClick}
									isDarkBordered
									text={<span className="px-3 lg:t6">{buttonText}</span>}
								/>
							</div>
						)
					)}

					{/* <div className="flex gap-16 justify-between items-center">
            <QwidButton
              onClick={handleStart2fa}
              text={<span className="px-3 lg:t6">Setup 2FA</span>}
              loading={loading}
              disabled={loading}
            />
          </div> */}
				</div>
			</Col>
		</Row>
	);
};

export default Security;
