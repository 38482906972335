import {
  useToolkit,
  useSendFlow,
  useDashboard,
  useAuth,
  Spinner,
  OptionalRender,
} from 'components';
import { RiEdit2Fill } from 'react-icons/ri';
import TrxpinModal from '../TrxpinModal';
import { addFundsData, togglePinAuth } from 'redux/slices/dashboardSlice';
import Layout from '../internal-transfer/Layout';
import { setTransitionDirection } from 'redux/slices/transactionsSlice';
import { MutableRefObject } from 'react';
import { TransitionModal } from '../common/local-components';
import { DASHBOARD_ROUTES } from 'utils/appData/appRoutes';
import PinSetupModal from '../PinSetupModal';
import Detail from '../common/local-components/Detail';
import { EditIcon } from '../common/local-components/EditIcon';
import { useQuery } from 'react-query';
import { currencySymbols } from 'utils/hooks/misc/currencySymbols';
import { ImSpinner9 } from 'react-icons/im';

type ComponentPageNavigator = {
  pageIncrement?: () => void;
  pageDecrement?: () => void;
  navigateToPage?: (page: number) => void;
  narration?: MutableRefObject<string | null>;
  reviewBtnRef: any;
};

const ReviewTransaction = ({
  pageIncrement,
  navigateToPage,
  narration,
  reviewBtnRef,
}: ComponentPageNavigator) => {
  const {
    dispatch,
    useAppSelector,
    trxPinExists,
    getSymbol,
    useEffect,
    useState,
    router,
    handleRequestError,
    axiosInstance,
    isPersonalAccount
    // logout,
  } = useToolkit();
  const {
    initiateWithdrawal,
    wdlLoading,
    pinVerifyLoading,
    verifyTxnPin,
    addWithdrawalBankAccount,
    addBankLoading,
    loading,
    setLoading,
  } = useSendFlow();

  const { createPinFn } = useAuth();
  const { fetchUserWallets } = useDashboard();
  const { internalTransfer } = useAppSelector((state) => state.transactions);
  const { userWallets } = useAppSelector((state) => state.dashboard);
  const {
    withdrawalInfo: {
      withdrawalCountry,
      withdrawalAmount,
      withdrawalCurrency,
      selectedRecipient,
      isCreatingNewRecipient,
      dynamicFormValues,
      isBeneficiarySaved,
    },
  } = useAppSelector((state) => state.sendflow);
  const {data: wdlFee, isLoading, isRefetching} = useQuery({
		queryKey:['getFee', withdrawalAmount],
		queryFn: async()=>{
			const data = await axiosInstance.get(
				`fee?transType=withdraw&userType=${isPersonalAccount ? 1 : 2}&peer=${
				  withdrawalCurrency
				}&amount=${withdrawalAmount}`
			  );
			  const response = data?.data?.content?.data[0];
			return response;
		},
		enabled: !!withdrawalAmount
	})
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [openPinSetupModal, setOpenPinSetupModal] = useState(false);
  const [eventMessage, setEventMessage] = useState('Initializing....');

  const [showInsufficientFundModal, setShowInsufficientFundModal] =
    useState(false);

  const handleTransaction = async (pin: string) => {
    const tempPin =
      trxPinExists === true
        ? internalTransfer?.internalTransferDetails?.pin
        : pin;

    try {
      dispatch(setTransitionDirection('right'));
      // verify user's transaction pin
      setEventMessage('Verifying your pin');
      await verifyTxnPin(tempPin)
        .then(({ data }: any) => {
          // save bank details to backend
          if (data?.message?.toLowerCase()?.includes('verified successfully')) {
            // when it's a new bank account
            if (isCreatingNewRecipient) {
              const bankDetailsPayload = {
                cur: withdrawalCurrency,
                bankName: selectedRecipient?.bankName,
                bankCode: selectedRecipient?.bankCode || '',
                accountName: selectedRecipient?.accountName,
                accountNo: selectedRecipient?.accountNo,
                country: withdrawalCountry,
                address: dynamicFormValues?.address || '',
                zipcode: dynamicFormValues?.zipcode || '',
                bankRoutingNo: dynamicFormValues?.bankRoutingNo || '',
                accountType: dynamicFormValues?.accountType || '',
                is_beneficiary: isBeneficiarySaved,
              };
              setEventMessage('Saving recipient details');
              addWithdrawalBankAccount(bankDetailsPayload)
                .then((response: any) => {
                  if (response?.success === true) {
                    // initialize the withdrawal request
                    const withdrawalPayload = {
                      cur: withdrawalCurrency,
                      amount: withdrawalAmount,
                      pin: tempPin,
                      narration: narration?.current,
                      bankId: response?.content?.data?.id,
                    };
                    setEventMessage('Initializing transaction');
                    initiateWithdrawal(withdrawalPayload)
                      .then((response) => {
                        try {
                          if (
                            response?.message
                              ?.toLowerCase()
                              ?.includes('request failed')
                          ) {
                            return;
                          } else {
                            dispatch(togglePinAuth());
                            pageIncrement && pageIncrement();
                          }
                        } catch (error) {
                          setLoading(false);
                        }
                      })
                      .catch(() => {
                        setLoading(false);
                      });
                  }
                })
                .catch(() => {
                  setLoading(false);
                });
            } else {
              // initialize the withdrawal request when it's an existing bank account
              setEventMessage('Initializing transaction');
              const withdrawalPayload = {
                cur: withdrawalCurrency,
                amount: withdrawalAmount,
                pin: tempPin,
                narration: narration?.current,
                bankId: selectedRecipient?.id,
                is_beneficiary: isBeneficiarySaved,
              };

              initiateWithdrawal(withdrawalPayload)
                .then((response) => {
                  if (
                    response?.message?.toLowerCase()?.includes('request failed')
                  ) {
                    return;
                  } else {
                    dispatch(togglePinAuth());
                    pageIncrement && pageIncrement();
                  }
                })
                .catch(() => {
                  setLoading(false);
                });
            }
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    const selectedWallet = userWallets.find(
      (wallet: any) => withdrawalCurrency === wallet.cur
    );

    if (selectedWallet && wdlFee?.toPay) {
      if (Number(selectedWallet?.balance) < +wdlFee?.toPay) {
        setShowInsufficientFundModal(true);
      } else {
        setShowInsufficientFundModal(false);
        if (trxPinExists) {
          dispatch(togglePinAuth());
        } else if (trxPinExists === false) {
          setInfoModalVisible(true);
        }
      }
    }
  };

  const handleProceedWithPinSetup = () => {
    setInfoModalVisible(false);
    setOpenPinSetupModal(true);
  };

  const handleAddFunds = () => {
    const wallet = userWallets.find(
      (wallet: any) => withdrawalCurrency === wallet.cur
    );
    dispatch(addFundsData({ ...wallet, selectedWallet: withdrawalCurrency }));
    router.push(`${DASHBOARD_ROUTES.ADD_FUND}?cur=${withdrawalCurrency}`);
  };

  const internalCleanup = () => {
    setLoading(false);
    setEventMessage('Initializing...');
  };

  const handleSetupTransactionPin = async (pin: string | number) => {
    // hide pin modal
    setOpenPinSetupModal(false);
    // show loading state
    setLoading(true);
    // set event message
    setEventMessage('Setting up transaction pin...');
    // setup pin
    try {
      await createPinFn(pin as string, true);
    } catch (e) {
      internalCleanup();
      handleRequestError(e, {
        customMessage: 'Error setting up pin, kindly try again',
      });
      setLoading(false);
    }
    // call handle transaction
    handleTransaction(pin as string);
  };

  useEffect(() => {
    fetchUserWallets();
  }, []);

 

  return (
    <>
      {/* <button onClick={logout}>Loggie</button> */}
      <TransitionModal
        isAuth
        bgColor="bg-[#C5E6F5]"
        buttonText="Proceed to set up"
        onClose={() => setInfoModalVisible(false)}
        onProceed={handleProceedWithPinSetup}
        subtext="To confirm this transaction and subsequent transactions, you need to set up your transaction pin. You only need to do this once."
        visible={infoModalVisible}
        imgUrl="/assets/dashboard/pin-setup.svg"
        vectorUrl="/assets/dashboard/auth-vector.svg"
      />

      <PinSetupModal
        controller={openPinSetupModal}
        onClose={() => setOpenPinSetupModal(false)}
        actuatorFxn={handleSetupTransactionPin}
      />

      <TransitionModal
        bgColor="#C5E6F5"
        buttonText="Add funds"
        onClose={() => setShowInsufficientFundModal(false)}
        onProceed={handleAddFunds}
        subtext="Your wallet balance is insufficient, please fund this wallet or select a different wallet to complete your transaction"
        visible={showInsufficientFundModal}
        imgUrl="/assets/dashboard/insufficient-funds.png"
        vectorUrl="/assets/dashboard/spiral-vector-black.svg"
      />

      <Layout>
        <div className="w-full  flex flex-col  gap-6 ">
          <h1 className="font-medium mt-[-2.5rem] text-center text-[1.05rem] border-b-[1px] p-5 border-b-[#F0F2F4] md:text-[1.05rem] ">
            Review Transfer Information
          </h1>
          <div className="flex px-6 flex-col gap-4">
            <div className="flex justify-between gap-4">
              <h2 className="font-semibold">Recipient details</h2>
              <div
                onClick={() =>
                  navigateToPage &&
                  navigateToPage(isCreatingNewRecipient ? 3 : 1)
                }
                className="text-[#3A96BE] cursor-pointer flex items-center gap-2"
              >
                <EditIcon />
              </div>
            </div>
            <div className="flex flex-col gap-3 border-[1px] border-[#F0F2F4] py-2 px-4 rounded-[1rem]">
              <Detail
                title="Name"
                value={selectedRecipient?.accountName || 'N/A'}
              />
              <Detail
                title="Account Number"
                value={selectedRecipient?.accountNo || 'N/A'}
              />
              <Detail
                title="Bank Name"
                value={selectedRecipient?.bankName || 'N/A'}
              />
            </div>
          </div>
          <div className="flex flex-col mt-6 px-6 gap-4">
            <div className="flex justify-between gap-4">
              <h2 className="font-semibold">Payment details</h2>
              <div
                onClick={() => navigateToPage && navigateToPage(2)}
                className="text-[#3A96BE] cursor-pointer flex items-center gap-2"
              >
                <EditIcon />
              </div>
            </div>
            <div className="flex flex-col gap-3 border-[1px] border-[#F0F2F4] py-2 px-4 rounded-[1rem]">
              <Detail
                title={`You're paying`}
                value={`${getSymbol(withdrawalCurrency)}
                ${Number(withdrawalAmount)?.toLocaleString()}`}
              />
              <Detail
                title="Fee"
                value={
                  (<div>
                    <OptionalRender condition={isLoading}>
                        <div className="text-[#194153] font-semibold"><ImSpinner9 className="animate-spin text-[1rem]" /></div>
                      </OptionalRender>
                      <OptionalRender condition={!isLoading && +withdrawalAmount > 0}>
                        <div className="text-[#194153] font-semibold">{currencySymbols[withdrawalCurrency]}{Math.ceil(((wdlFee?.toPay || 0) - (wdlFee?.amount || 0)) * 1000) / 1000} </div>
                      </OptionalRender>
                      <OptionalRender condition={!isLoading && +withdrawalAmount <= 0}>
                        <div className="text-[#194153] font-semibold">...</div>
                      </OptionalRender>
                    </div>)
                }
              />

              <Detail
                title="Total"
                value={`${currencySymbols[withdrawalCurrency]}${Math.ceil(Number(wdlFee?.toPay) * 1000) / 1000}`}
                bold
              />
             
            </div>
          </div>
         

          <div className="mt-[2.5rem] flex flex-col w-full">
            <button
              onClick={handleConfirm}
              ref={reviewBtnRef}
              className="hidden"
            >
              Check here
            </button>
          </div>
        </div>
      </Layout>
      <TrxpinModal
        loading={pinVerifyLoading || addBankLoading || wdlLoading}
        continueFxn={handleTransaction}
      />
      {loading && (
        <div className="bg-[#00000066] fixed top-0 left-0 right-0 bottom-0 backdrop-blur-[2px] flex justify-center items-center w-[100%] h-[100%] z-50">
          <div className="bg-white md:w-[30rem] w-[20rem] p-0 m-0 rounded-[0.6rem] flex justify-center items-center">
            <Spinner msg={eventMessage} />
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewTransaction;
