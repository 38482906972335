import ReactCardFlip from "react-card-flip";
import useToolkit from "utils/hooks/misc/useToolkit";
import { FiCopy } from "react-icons/fi";
import { ImSpinner9 } from "react-icons/im";

type Props = {
	bgColor: string;
	textColor?: string;
	isWide?: boolean;
	card: VirtualCard | any;
	isFlipped?: boolean;
	isListItem?: boolean;
	setIsFlipped?: React.Dispatch<React.SetStateAction<boolean>>;
	handleFlip?: () => void;
	cardData?: any;
	detailsShown?: boolean;
	dataUnavailable?: boolean;
};

function VirtualCardWrapper({
	bgColor,
	textColor,
	isWide,
	card,
	isFlipped,
	handleFlip,
	isListItem,
	cardData,
	detailsShown,
	dataUnavailable,
}: Props) {
	const { userFullName, useAppSelector, toastSuccess, getCardExpiry } =
		useToolkit();
	const { selectedVCard: vcard } = useAppSelector((state) => state.virtuals);

	const spacifyTopCardNumber = (card: VirtualCard, number: string) => {
		if (number === undefined || !number?.length) {
			return "0000 0000 0000 0000";
		}

		if (card?.detailsHidden) {
			return `${number?.substring(0, 4)} **** **** ${number?.substring(12)}`;
		} else {
			return number?.length
				? number.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " - ")
				: "";
		}
	};

	const WRAPPER_CLASS = `rounded-xl relative overflow-hidden ${
		isWide ? "w-full shadow-2xl  md:w-[350px] mx-auto" : "w-full"
	}`;
	const WRAPPER_STYLE = {
		backgroundColor: bgColor,
		color: textColor || "white",
		height: isListItem ? "215px" : "210px",
		transition: "all 3s ease-out !important",
	};

	const SPIRAL_CLASS = "absolute -right-1 -top-3 object-cover";
	const isSelected = card?.id === vcard?.id;
	const copyCardNum = () => {
		const number = cardData?.cardNumber;
		navigator.clipboard.writeText(number);
		toastSuccess("Card Number Copied");
	};

	const allowReveal = card?.id === vcard?.id && detailsShown;

	const formattedCardNumber = cardData?.cardNumber
		?.toString()
		.replace(/\B(?=(\d{4})+(?!\d))/g, " - ");

	const UnavailableCardUI = () => (
		<div className="w-full h-full flexed flex-col twite">
			<ImSpinner9 className="mb-3 text-3xl animate-spin" />
			<p className="mb-0 text-sm tcent">
				Preparing your card... <br />
				this will take a moment.
			</p>
		</div>
	);

	const ListItemCard = () => (
		<div
			className={`${WRAPPER_CLASS} ${
				isSelected && "shadow-lg transition-shadow"
			} p-5 pr-0 ${dataUnavailable ? "cursor-not-allowed" : "curp"}`}
			style={WRAPPER_STYLE}>
			<img
				width={180}
				src="/assets/dashboard/card-spiral.svg"
				alt="spiral"
				className={SPIRAL_CLASS}
			/>
			<div className="flex-btw">
				<p className="mb-0 absolute top-4 text-xl med twide">qwid</p>
			</div>
			<div className="h-full flex flex-col">
				{dataUnavailable ? (
					<UnavailableCardUI />
				) : (
					<div className="w-full flex flex-col mt-10">
						<div className="w-full flex-btw pr-4 items-center pb-6">
							<p className="twide mb-0 med t6">
								{allowReveal
									? formattedCardNumber
									: spacifyTopCardNumber(card, cardData?.cardNumber)}
							</p>
							{allowReveal && (
								<div
									className="h-10 w-10 z-20 bg-gray-50 bg-opacity-25 rounded-lg border border-white flexed curp"
									onClick={copyCardNum}>
									<FiCopy className="text-white text-lg" />
								</div>
							)}
						</div>
						<p className="smb twide mb-0 text-sm mr-auto">
							EXP:&nbsp;{" "}
							{getCardExpiry(cardData?.expiryMonth, cardData?.expiryYear)}
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CVV:&nbsp;{" "}
							{allowReveal ? cardData?.cvv : "***"}
						</p>
						&nbsp;&nbsp;&nbsp;
					</div>
				)}

				<div className="flex items-end justify-between">
					<p className="mb-0 twide med">{card?.virtualCardName}</p>
					<p className="text-xl lg:text-2xl smb twide mb-0 upc pr-4">visa</p>
				</div>
			</div>
		</div>
	);

	const FrontView = () => (
		<div className={`${WRAPPER_CLASS} p-5`} style={WRAPPER_STYLE}>
			<img
				width={180}
				src="/assets/dashboard/card-spiral.svg"
				alt="spiral"
				className={SPIRAL_CLASS}
			/>
			<div className="flex-btw">
				<p className="mb-0 absolute top-4 text-xl med twide">qwid</p>
				<div className="absolute top-4 right-5 text-xl">
					<img
						onClick={handleFlip}
						width={28}
						src="/assets/dashboard/thumb.svg"
						alt="spiral"
						className="object-cover curp"
					/>
				</div>
			</div>
			<div className="h-full flex flex-col">
				<div className="w-full flex-btw mt-20">
					<p className="mb-0 twide med text-xl lg:text-2xl"> {card?.number} </p>
				</div>
				<div className="flex items-end justify-between h-full">
					<p className="mb-0 twide med">{userFullName || "Qwid User"}</p>
					<p className="text-2xl lg:text-3xl smb twide mb-0 upc">visa</p>
				</div>
			</div>
		</div>
	);

	const RearView = () => (
		<div className={WRAPPER_CLASS} style={WRAPPER_STYLE}>
			<img
				width={180}
				src="/assets/dashboard/card-spiral.svg"
				alt="spiral"
				className={SPIRAL_CLASS}
			/>
			<div className="absolute top-5 flex items-center justify-end bg-white w-full py-2 pr-4">
				<div className="text-xl" onClick={handleFlip}>
					<img
						width={28}
						src="/assets/dashboard/thumb-black.svg"
						alt="spiral"
						className="object-cover curp"
					/>
				</div>
			</div>

			<div className="absolute bottom-7 right-4 mt-auto px-2 py-1 bg-white text-black rounded-sm flex items-center">
				<img
					width={50}
					height={50}
					src="/assets/dashboard/card-dots.png"
					alt="dots"
					className="mr-3"
				/>
				<p className="mb-0 med twide">999</p>
			</div>
		</div>
	);

	return isListItem ? (
		<ListItemCard />
	) : (
		<ReactCardFlip
			containerClassName="w-full"
			isFlipped={isFlipped}
			flipDirection="horizontal">
			<FrontView />
			<RearView />
		</ReactCardFlip>
	);
}

export default VirtualCardWrapper;
