import React, { useEffect } from "react";
import useToolkit from "utils/hooks/misc/useToolkit";
import { onMessageListener } from "../../firebase";
import { setKYCNotification } from "redux/slices/notificationSlice";
import { setShowKycCompletedBanner } from "redux/slices/dashboardSlice";

const Notification = () => {
	const { dispatch } = useToolkit();
	useEffect(() => {
		const handleNotification = (payload: Record<string, any>) => {
			const dataJson = payload?.data?.payload;
			const data = JSON?.parse(dataJson);
			//Todo: Handle Firebase notifications here
			// For KYC
			if (data.event === "KYC") {
				alert(data.event)
				// trigger banner on dashboard if kyc was successful
				if (data.status === true) {
					dispatch(setShowKycCompletedBanner({ showKycCompletedBanner: true }))
				}
				dispatch(
					setKYCNotification({
						messageId: payload.messageId,
						event: data.event,
						content: data.reason,
						status: data.status,
						sourceId: data.transId,
					})
				);
			}
		}
		if(onMessageListener){
			onMessageListener(handleNotification);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <></>;
};

export default Notification;
