type DateProps = {
	startDate: string;
	endDate: string;
};

import { DatePicker, Radio, RadioChangeEvent, Space } from "antd";
import {
	CenterModal,
	CustomSelector,
	QwidButton,
	useToolkit,
} from "components";
import { clearWalletFlag, setWalletFlag } from "redux/slices/dashboardSlice";
import { toggleStatementModal } from "redux/slices/transactionsSlice";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";

const StatementGeneratorModal = () => {
	const { RangePicker } = DatePicker;

	const { useAppSelector, dispatch, useState, userId, isStagingEnv, router } =
		useToolkit();

	const { isStatementModalOpen } = useAppSelector(
		(state) => state.transactions
	);

	const { selectedWallet: wallet } = useAppSelector(
		(state: any) => state.dashboard
	);

	const isTransPage = router.pathname === DASHBOARD_ROUTES.TRANSACTIONS;
	const isWalletsPage = router.pathname === DASHBOARD_ROUTES.WALLET_BALANCE;

	const closeModal = () => dispatch(toggleStatementModal());

	const [dates, setDates] = useState<DateProps>({
		startDate: "",
		endDate: "",
	});

	const [value, setValue] = useState(null);
	const [walletsShown, setWalletsShown] = useState(false);
	const [customWallet, setCustomWallet] = useState("");

	const onRadioChange = (e: RadioChangeEvent) => {
		const value = e.target.value;

		if (value === 2) {
			setWalletsShown(true);
		} else {
			setWalletsShown(false);
		}
		setValue(value);
	};

	const onChange = (_: any, dateString: [string, string] | string) => {
		const startDate = dateString[0];
		const endDate = dateString[1];
		setDates({
			startDate,
			endDate,
		});
	};

	const onProceed = () => {
		const { startDate, endDate } = dates;
		const datesWithUserId = `${userId}|${startDate}|${endDate}`;

		const walletPageString = `${datesWithUserId}${
			isWalletsPage ? `|${wallet?.cur || "NGN"}` : ""
		}`;

		const transPageString = `${datesWithUserId}${
			value === 2 ? `|${customWallet}` : ""
		}`;

		const tempString = isWalletsPage ? walletPageString : transPageString;

		// const tempString = `${datesWithUserId}${
		// 	isWalletsPage ? `|${wallet?.cur || "NGN"}` : ""
		const actual = btoa(tempString);

		if (isStagingEnv) {
			window.open(`https://test.qwid.io/statement/${actual}`, "_blank");
		} else {
			window.open(`https://partners.qwid.io/statement/${actual}`, "_blank");
		}

		dispatch(clearWalletFlag());
		closeModal();
	};

	const disabledRules = isWalletsPage
		? !dates?.startDate || !dates.endDate
		: !dates?.startDate ||
		  !dates.endDate ||
		  !value ||
		  (value === 2 && !customWallet?.length);

	return (
		<CenterModal
			control={isStatementModalOpen}
			onClose={closeModal}
			title="Generate Statement">
			<div className="flexed flex-col py-8 space-y-8">
				<RangePicker
					size={"large"}
					className="w-full"
					format="MM/DD/YYYY"
					onChange={onChange}
				/>

				{isTransPage && (
					<div className="my-10 w-full">
						<div className="w-full py-4">
							<Radio.Group
								className="w-full"
								size="large"
								onChange={onRadioChange}
								value={value}>
								<Space direction="vertical" size={"large"}>
									<Radio value={1}>Download for all currencies</Radio>
									<Radio value={2}>Download for specific currency</Radio>
								</Space>
							</Radio.Group>
						</div>

						<div className="w-full md:w-3/4 py-4">
							{walletsShown && (
								<CustomSelector
									isUserWallets
									onChange={(e: any) => {
										const value = e.target.value;
										dispatch(setWalletFlag(value));
										setCustomWallet(value);
									}}
								/>
							)}
						</div>
					</div>
				)}

				<QwidButton
					disabled={disabledRules}
					darkBlueBg
					text={<span className="px-5">Proceed</span>}
					onClick={onProceed}
				/>
			</div>
		</CenterModal>
	);
};

export default StatementGeneratorModal;
