import React from "react";
import { useToolkit, useFramer, QwidButton } from "../../../components";
import { motion } from "framer-motion";
import { AUTH_ROUTES } from "utils/appData/appRoutes";

type Props = {};

const BusinessBanner = (props: Props) => {
	const { isMobile, router, isValidUser, logout } = useToolkit();
	const { empText, empWrap } = useFramer();

	const headerTxt = {
		hidden: {
			opacity: 0,
			x: "-100vw",
		},
		visible: {
			opacity: 1,
			x: 0,
			transition: { type: "spring", stiffness: 50, duration: 1, delay: 1.1 },
		},
	};
	return (
		<div className="flex flex-wrap lg:flex-nowrap h-full lg:pt-12">
			{/* hero text */}
			<div className="w-full lg:w-2/4 mx-auto h-fit lg:h-full blk">
				<motion.p
					variants={headerTxt}
					initial="hidden"
					animate="visible"
					style={{ lineHeight: isMobile ? "40px" : "70px" }}
					className="mb-2 pt-4 lg:pt-16 smb text-center lg:text-left tracking-wide text-3xl w-5/6 lg:w-full lg:text-6xl mx-auto">
					Global Payments, Made Easy
				</motion.p>
				<motion.div
					variants={empWrap}
					viewport={{ once: true }}
					initial="hidden"
					whileInView="visible"
					className="w-full">
					<motion.p
						variants={empText}
						className="text-center lg:text-left t6 mt-5 mb-5 lg:mb-10 tracking-wide lg:w-4/5 lg:pr-8">
						Unlock a world of simple payment solutions designed to <br /> give
						your business an edge.
					</motion.p>
					<div className="flex items-center justify-center lg:justify-start my-8">
						<QwidButton
							onClick={() => {
								if (isValidUser) {
									logout();
								}
								router.push(AUTH_ROUTES.BUSINESS_SIGN_UP);
							}}
							text={<span className="px-4">Create a free account</span>}
						/>
						{/* <div className="mx-2" />
						<QwidButton text="Learn more" businessCta /> */}
					</div>
				</motion.div>
			</div>

			{/* store buttons */}
			<div className="lg:hidden w-full mt-6 mb-10 flexed space-x-2">
				<div>
					<img
						onClick={() =>
							window.open(
								"https://play.google.com/store/apps/details?id=io.blinqpay.qwid",
								"_blank"
							)
						}
						width={120}
						src="/assets/landing/gplay-light.svg"
						alt="store-btn"
						className="store-btn"
					/>
				</div>
				<div>
					<img
						onClick={() =>
							window.open(
								"https://apps.apple.com/us/app/qwid/id6444584444",
								"_blank"
							)
						}
						width={120}
						src="/assets/landing/appstore-light.svg"
						alt="store-btn"
						className="store-btn"
					/>
				</div>
			</div>

			{/* screenshot wrapper */}
			<div className="w-full lg:w-2/4 mxs-auto flex items-center justify-center lg:h-full">
				<img
					src="/assets/business/deskman.png"
					alt="man"
					className="object-cover"
				/>
			</div>
		</div>
	);
};

export default BusinessBanner;
