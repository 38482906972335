import { Collapse } from "antd";
import {
	QwidButton,
	Spinner,
	Tooltip,
	useBeneficiary,
	useToolkit,
} from "components";
import Flag from "components/common/Flag";
import { AiOutlineDownCircle } from "react-icons/ai";
import { toggleCreateBankModal } from "redux/slices/settingsSlice";
import styled from "styled-components";

const BankAccountsTab = () => {
	const { Panel } = Collapse;

	const {
		useState,
		dispatch,
		EmptyDataUI,
		avail,
		moment,
		CopyIcon,
		useEffect,
	} = useToolkit();
	const {
		useDeleteBank,
		isLoading,
		isFetching,
		data: accounts,
		details,
	} = useBeneficiary();

	const { isLoading: delLoading, mutate: deleteDetails } = useDeleteBank();

	const [selected, setSelected] = useState("all");

	const CustomHeader = (cur: string, accountName: string, id: string) => (
		<div className="w-full flex-btw">
			<div className="flex items-center">
				<Flag currency={cur} />
				<p className="mb-0 med lg:text-lg pl-3">
					{cur} -&nbsp; <span className="uppercase">{accountName}</span>
				</p>
			</div>
			<AiOutlineDownCircle className="text-[#194153]" size="1.5rem" />
		</div>
	);

	// const getUserAccounts = async () => {
	// 	const {
	// 		data: {
	// 			content: { data },
	// 		},
	// 	} = await axiosInstance.get(`bank/${userId}`);
	// 	return data;
	// };

	// const {
	// 	isLoading,
	// 	isFetching,
	// 	data: accounts,
	// } = useQuery("getSavedAccounts", getUserAccounts, {
	// 	refetchOnMount: true,
	// 	onError: () => toastError("Unable to retrieve Accounts"),
	// 	onSuccess: (data) => setDetails(data),
	// });

	useEffect(() => {
		// getExistingBanks()
	}, []);

	const filtered = details?.filter((item: any) => {
		if (selected === "all") {
			return true;
		} else {
			return item?.cur === selected;
		}
	});

	const emptyAccounts = isLoading === false && accounts?.length === 0;

	const currencyTypes = [
		"all",
		...new Set(accounts?.map((item: any) => item.cur)),
	];

	return (
		<div className="flex flex-col">
			{isLoading || isFetching || delLoading ? (
				<Spinner />
			) : emptyAccounts ? (
				<EmptyDataUI />
			) : (
				<div className="flex flex-col">
					<div className="flex items-center mt-5 space-x-3 lg:space-x-5 border border-gray-400 rounded-md w-max">
						{currencyTypes?.map((item: any) => (
							<button
								onClick={() => setSelected(item)}
								key={item}
								className={`outline-none capitalize px-4 py-1 tcent twide ${
									selected === item
										? "text-white bg-[#194153] font-medium"
										: "text-black bg-transparent"
								}`}>
								{item}
							</button>
						))}
					</div>
					<div className="w-full mt-10 lg:mt-8 lg:w-[95%]">
						<Collapse
							accordion
							expandIconPosition="end"
							defaultActiveKey={["0"]}
							style={{
								border: 0,
								paddingBottom: "15px",
							}}>
							{filtered?.map(
								({
									status,
									accountName,
									accountNo,
									bankName,
									created_at,
									iban,
									routine,
									swift,
									cur,
									id,
								}: any) => (
									<Panel
										style={{
											backgroundColor: "#fff",
											marginBottom: "25px",
											border: "1px solid #bbb",
											borderTopLeftRadius: "8px",
											borderTopRightRadius: "8px",
										}}
										showArrow={false}
										header={CustomHeader(cur, accountName, id)}
										key={id}>
										<Wrapper className="flex flex-wrap lg:flex-nowrap">
											<div className="col-wrap">
												<div className="container top-item">
													<p className="label">Bank Name</p>
													<h3 className="value">
														{bankName?.substring(0, 10)}
														<span className="ml-2">
															<Tooltip text={bankName} />
														</span>
													</h3>
												</div>
												<div className="container">
													<p className="label">SWIFT</p>
													<h3 className="value">
														{swift?.length > 2 ? avail(swift) : "N/A"}
														{swift?.length > 2 && (
															<CopyIcon item={swift} title="Swift" />
														)}
													</h3>
												</div>
											</div>
											<div className="col-wrap">
												<div className="container top-item">
													<p className="label">Account Holder</p>
													<h3 className="value">{accountName}</h3>
												</div>
												<div className="container lg:pr-10">
													<p className="label">IBAN</p>
													<h3 className="value">
														{iban?.length
															? iban?.substring(0, 15) + "..."
															: "N/A"}
														{iban?.length && (
															<CopyIcon item={iban} title="Iban" />
														)}
													</h3>
												</div>
											</div>
											<div className="col-wrap">
												<div className="container top-item">
													<p className="label">Account Number</p>
													<h3 className="value">
														{accountNo}
														{accountNo?.length && (
															<CopyIcon
																item={accountNo}
																title="Account Number"
															/>
														)}
													</h3>
												</div>
												<div className="container lg:pr-10">
													<p className="label">Date Requested</p>
													<h3 className="value">
														{moment(created_at).format("ddd: Do MMM, YY")}
													</h3>
												</div>
											</div>
											<div className="col-wrap">
												<div className="container lg:pl-14 top-item">
													<p className="label">Sort Code</p>
													<h3 className="value">
														{avail(routine)}
														{routine?.length && (
															<span className="copy-icon">
																{/* <IoCopy
																	onClick={() => copyItem(routine, "Sort Code")}
																	className="pry-text"
																/> */}
															</span>
														)}
													</h3>
												</div>
												{/* <div className="container lg:pl-14">
													<p className="label">Status</p>
													status
													<h3 className="value">{getTransStatus(status)}</h3>
												</div> */}
											</div>
										</Wrapper>
										<div className="flex items-center justify-start mt-4">
											<QwidButton
												danger
												text="Delete"
												onClick={() => deleteDetails(id)}
											/>
										</div>
									</Panel>
								)
							)}
						</Collapse>
					</div>
					<div className="w-max">
						<QwidButton
							darkBlueBg
							onClick={() => dispatch(toggleCreateBankModal())}
							text="Add Bank account"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default BankAccountsTab;

const Wrapper = styled.div`
	.top-item {
		margin-bottom: 15px;
	}

	.container {
		display: flex;
		flex-direction: column;

		.label {
			margin-bottom: 5px;
		}

		.value {
			font-weight: 600;
			letter-spacing: 0.3px;
			display: flex;
			align-items: center;
		}

		.copy-icon {
			margin-left: 6px;
			font-size: 15px;
			cursor: pointer;
		}
	}
`;
