import { RemittanceDirective } from 'custom-types';
import { Dispatch, SetStateAction, createContext } from 'react';

type ProgressData = {
  currentStage: number;
  steps: number;
  completedSteps: number;
};
export type SendMoneyDetails = {
  recipientCountry: string;
  recipientCurrency: string;
  selectedWallet: any;
  amount?: number;
  feeData?: any
};

export type PayoutChannelDetails = {
  category: string;
  option: string;
};
export type DynamicFieldProps = {
  options: Record<string, string>[];
  fetchingOptions: boolean;
  validating?: boolean;
  fetched: boolean;
  isValid?: boolean;
};
export type TransactionDetails = {
  id?: string;
  amount?: number;
  name?: string;
}

export type RemittanceContext = {
  stage: number;
  goBack: () => void;
  goToPage: (page: number) => void;
  goToNextPage: () => void;
  selectedBeneficiary?: any;
  setBeneficiary: Dispatch<SetStateAction<any>>;
  progressData: ProgressData;
  setBeneficiaryType: (value: string) => void;
  beneficiaryType?: string;
  sendMoneyDetails: SendMoneyDetails;
  setSendMoneyDetails: (value: SendMoneyDetails) => void;
  payoutChannelDetails: PayoutChannelDetails;
  setPayoutChannelDetails: (data: PayoutChannelDetails) => void;
  fieldData: Record<string, DynamicFieldProps>;
  setFieldData: (data: Record<string, DynamicFieldProps>) => void;
  setRecipientInfo: (data: Record<string, string>) => void;
  recipientInfo: Record<string, string>;
  formatToReadableString: (amount: number) => string;
  transactionDetails: TransactionDetails;
  setTransactionDetails: (value: TransactionDetails) => void;
  transactionError: string;
  setTransactionError: (error: string) => void;
  remittanceDirective?: RemittanceDirective;
  staticParams: any;
  setStaticParams: Dispatch<SetStateAction<any>>;
};
export const INITIAL_STAGE = 0;
export const RemittanceContext = createContext<RemittanceContext>({
  stage: INITIAL_STAGE
} as RemittanceContext);
