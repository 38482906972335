import {
  closeKycModal,
  setKycStatus,
  stopKycLoading,
  setNotifications
} from 'redux/slices/layoutSlice';
import { kycComplete } from 'redux/slices/settingsSlice';
import { DASHBOARD_ROUTES, ONBOARDING_ROUTES } from 'utils/appData/appRoutes';
import useToolkit from '../misc/useToolkit';
import { KYCStatus, KycResponse } from '../../../custom-types';
import { useCallback } from 'react';
import { saveUserTierInfo } from 'redux/slices/dashboardSlice';

const useUser = () => {
  const {
    router,
    dispatch,
    useState,
    axiosInstance,
    toastSuccess,
    toastInfo,
    useAppSelector,
    toastError,
    userId,
    isPersonalAccount,
    handleRequestError,
    useQuery,
    isNigPersonalAccount
  } = useToolkit();
  const [kycLoading, setKycLoading] = useState(false);

  const { isKycLoading, kycStatus, isKycModalOpen } = useAppSelector((state) => state.layout);

  const isOnboardingRoute = router.pathname?.includes('onboarding');

  const isSettingsRoute = router.pathname?.includes('settings');

  const [kycMsg, setKycMsg] = useState(
    isKycLoading
      ? 'Please wait while we confirm the uploaded documents. This might take a while'
      : 'Confirming your verification'
  );

  const takeAction = (action: string) => {
    dispatch(setKycStatus(action));
    dispatch(kycComplete());
  };
  // make change to this function not to auto redirect if user is nigerian account.
  const getKycStatus = async () => {
    const payload = {
      userId
    };

    setKycLoading(true);
    try {
      const {
        data: {
          content: { data }
        }
      } = await axiosInstance.post('get-user-kyc', payload);
      const { kycList, kycStatus } = data;

      if (kycStatus === true) {
        takeAction('kyc done');
      }

      if (kycStatus === false && kycList[0]?.upload === true) {
        if (isOnboardingRoute) toastInfo('Documents are under review');
        takeAction('kyc pending');
        dispatch(stopKycLoading());
        if (router.pathname?.includes('onboarding')) {
          router.push(DASHBOARD_ROUTES.DASHBOARD);
        }
      }

      if (kycList[0]?.upload === false && kycStatus === false) {

        if (!isNigPersonalAccount) {
          toastError('No KYC documents uploaded');
          takeAction('no kyc');
          setTimeout(() => {
            router.push(
              isPersonalAccount
                ? ONBOARDING_ROUTES.PERSONAL.PERSONAL_INFO
                : ONBOARDING_ROUTES.BUSINESS.BUSINESS_TYPE // revise
            );
          }, 2000);
        }
        dispatch(stopKycLoading());
      }

      setKycLoading(false);
    } catch (error) {
      handleRequestError(error);
      setKycLoading(false);
      return error;
    }
  };

  const verifyUserKyc = useCallback(async () => {
    const payload = {
      userId
    };
    try {
      const {
        data: {
          content: { data }
        }
      } = await axiosInstance.post('get-user-kyc', payload);
      dispatch(saveUserTierInfo(data?.kycTier));

      return data;
    } catch (error) {
      handleRequestError(error);
      return error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const determineUserKycStatus = ({ kycList, kycStatus }: KycResponse): KYCStatus => {
    //seems like KycList is not treturning as an array but an object.
    const kycListIsArray = Array.isArray(kycList);

    // determine when kyc is rejected
    if (kycStatus && kycListIsArray && kycList.length > 0) {
      const isRejected = kycList?.some((doc) => !!doc?.fileError);
      // determine when kyc is not started or in-completed
      const isInComplete = kycList?.some((doc) => doc?.upload === false);
      if (kycStatus === true) return KYCStatus.COMPLETED;
      if (isRejected) return KYCStatus.REJECTED;
      if (isInComplete) return KYCStatus.INCOMPLETE;
      // Pending means KYC is in review
      return KYCStatus.PENDING;
    }
    //handle case where kycList comes as an object
    else if (kycStatus && !kycListIsArray) {
      const isRejected = !!(kycList as Record<string, string | boolean | null>)?.fileError
      const isInComplete = (kycList as Record<string, string | boolean | null>)?.upload === false
      if (kycStatus === true) return KYCStatus.COMPLETED;
      if (isRejected) return KYCStatus.REJECTED;
      if (isInComplete) return KYCStatus.INCOMPLETE;
      // Pending means KYC is in review
      return KYCStatus.PENDING;

    }
    else return KYCStatus.UNKNOWN;
  };

  const markNotificationRead = async (notyId: string) => {
    try {
      await axiosInstance.patch(`notification`, {
        status: 1,
        notyId
      });
    } catch (error) { }
  };

  const getNotificationsFn = async () => {
    if (userId?.length) {
      const {
        data: {
          content: { data }
        }
      } = await axiosInstance.post(`notification/unseen/${userId}`);

      const kycItem = data?.find((item: NotificationItem) => item.notyType === 'KYC');

      if (kycItem && router.pathname.includes('onboarding')) {
        // close kyc modal
        if (isKycModalOpen) {
          dispatch(closeKycModal());
        }

        // call function to check kyc status on backend
        getKycStatus();

        // mark specific notification as seen
        markNotificationRead(kycItem.notyId);
      }
      dispatch(setNotifications(data));
      router.push(DASHBOARD_ROUTES.DASHBOARD);
      return data;
    }
  };

  const useGetNotifications = () => useQuery('getNotifications', getNotificationsFn);

  return {
    kycLoading,
    getKycStatus,
    kycMsg,
    setKycLoading,
    setKycMsg,
    kycStatus,
    verifyUserKyc,
    useGetNotifications,
    markNotificationRead,
    determineUserKycStatus
  };
};

export default useUser;
