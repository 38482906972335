export const currencySymbols: Record<string, string> = {
    'USD': '$',      // US Dollar
    'EUR': '€',      // Euro
    'GBP': '£',      // British Pound
    'JPY': '¥',      // Japanese Yen
    'AUD': 'A$',     // Australian Dollar
    'CAD': 'C$',     // Canadian Dollar
    'CHF': 'CHF',    // Swiss Franc
    'CNY': '¥',      // Chinese Yuan
    'SEK': 'kr',     // Swedish Krona
    'NZD': 'NZ$',    // New Zealand Dollar
    'KRW': '₩',      // South Korean Won
    'SGD': 'S$',     // Singapore Dollar
    'NOK': 'kr',     // Norwegian Krone
    'MXN': 'Mex$',   // Mexican Peso
    'INR': '₹',      // Indian Rupee
    'RUB': '₽',      // Russian Ruble
    'ZAR': 'R',      // South African Rand
    'BRL': 'R$',     // Brazilian Real
    'TRY': '₺',      // Turkish Lira
    'HKD': 'HK$',    // Hong Kong Dollar
    'IDR': 'Rp',     // Indonesian Rupiah
    'PHP': '₱',      // Philippine Peso
    'DKK': 'kr',     // Danish Krone
    'THB': '฿',      // Thai Baht
    'MYR': 'RM',     // Malaysian Ringgit
    'HUF': 'Ft',     // Hungarian Forint
    'CZK': 'Kč',     // Czech Koruna
    'ILS': '₪',      // Israeli New Shekel
    'PLN': 'zł',     // Polish Zloty
    'TWD': 'NT$',    // New Taiwan Dollar
    'SAR': '﷼',      // Saudi Riyal
    'AED': 'د.إ',    // United Arab Emirates Dirham
    'CLP': 'CLP$',   // Chilean Peso
    'KWD': 'د.ك',    // Kuwaiti Dinar
    'QAR': '﷼',      // Qatari Riyal
    'COP': 'COL$',   // Colombian Peso
    'PEN': 'S/.',    // Peruvian Sol
    'RON': 'lei',    // Romanian Leu
    'HRK': 'kn',     // Croatian Kuna
    'VND': '₫',      // Vietnamese Dong
    'BDT': '৳',      // Bangladeshi Taka
    'PKR': '₨',      // Pakistani Rupee
    'EGP': '£',      // Egyptian Pound
    'NGN': '₦',      // Nigerian Naira
    'MAD': 'د.م.',    // Moroccan Dirham
    'DZD': 'د.ج',    // Algerian Dinar
    'UAH': '₴',      // Ukrainian Hryvnia
    'LKR': 'රු',     // Sri Lankan Rupee
    'BGN': 'лв',     // Bulgarian Lev
    'ARS': 'ARS$',   // Argentine Peso
    'IRR': '﷼',      // Iranian Rial
    'OMR': 'ر.ع.',   // Omani Rial
    'GHS': 'GH₵',    // Ghanaian Cedi
    'KES': 'KSh',    // Kenyan Shilling
    'TZS': 'TSh',    // Tanzanian Shilling
    'UGX': 'USh',    // Ugandan Shilling
    'NPR': 'रू',     // Nepalese Rupee
    'JOD': 'د.ا',    // Jordanian Dinar
    'LBP': 'ل.ل',    // Lebanese Pound
    'SDG': 'ج.س.',   // Sudanese Pound
    'BHD': 'ب.د',    // Bahraini Dinar
    'LYD': 'ل.د',    // Libyan Dinar
    'MUR': '₨',      // Mauritian Rupee
    'MMK': 'Ks',     // Myanmar Kyat
    'BND': 'B$',     // Brunei Dollar
    'MOP': 'MOP$',   // Macanese Pataca
    'KZT': '₸',      // Kazakhstani Tenge
    'AZN': '₼',      // Azerbaijani Manat
    'GEL': '₾',      // Georgian Lari
    'BYN': 'Br',     // Belarusian Ruble
    'XAF': 'FCFA',   // Central African CFA Franc
    'XOF': 'CFA',    // West African CFA Franc
    'XCD': 'EC$',    // East Caribbean Dollar
    'TTD': 'TT$',    // Trinidad and Tobago Dollar
    'BBD': 'Bds$',   // Barbadian Dollar
    'JMD': 'J$',     // Jamaican Dollar
    'BZD': 'BZ$',    // Belize Dollar
    'BSD': 'B$',     // Bahamian Dollar
    'GYD': 'G$',     // Guyanese Dollar
    'SRD': 'SRD$',   // Surinamese Dollar
    'HTG': 'G',      // Haitian Gourde
    'KYD': 'CI$',    // Cayman Islands Dollar
    'ANG': 'NAf',    // Netherlands Antillean Guilder
    'AWG': 'ƒ',      // Aruban Florin
    'BAM': 'KM',     // Bosnian Convertible Mark
    'MKD': 'ден',    // Macedonian Denar
    'ALL': 'L',      // Albanian Lek
    'ISK': 'kr',     // Icelandic Krona
    'MNT': '₮',      // Mongolian Tugrik
    'AMD': '֏',      // Armenian Dram
    'NAD': 'N$',     // Namibian Dollar
    'PGK': 'K',      // Papua New Guinean Kina
    'FJD': 'FJ$',    // Fijian Dollar
    'SBD': 'SI$',    // Solomon Islands Dollar
    'TOP': 'T$',     // Tongan Paʻanga
    'WST': 'WS$',    // Samoan Tala
    'KGS': 'сом',    // Kyrgyzstani Som
    'UZS': 'сўм',    // Uzbekistani Som
    'MGA': 'Ar',     // Malagasy Ariary
    'MWK': 'MK',     // Malawian Kwacha
    'ZMW': 'ZK',     // Zambian Kwacha
    'CDF': 'FC',     // Congolese Franc
    'DJF': 'Fdj',    // Djiboutian Franc
    'GNF': 'FG',     // Guinean Franc
    'RWF': 'FRw',    // Rwandan Franc
    'BIF': 'FBu',    // Burundian Franc
    'KMF': 'CF',     // Comorian Franc
    'SCR': '₨',      // Seychellois Rupee
    'MVR': 'Rf',     // Maldivian Rufiyaa
    'BTN': 'Nu.',    // Bhutanese Ngultrum
    'AFN': '؋',      // Afghan Afghani
    'SOS': 'Sh.so.', // Somali Shilling
    'SZL': 'E',      // Swazi Lilangeni
    'LSL': 'L',      // Lesotho Loti
    'BWP': 'P',      // Botswana Pula
    'ZWL': 'Z$',     // Zimbabwean Dollar
    'ERN': 'Nkf',    // Eritrean Nakfa
    'ETB': 'ብር',     // Ethiopian Birr
    'SLL': 'Le',     // Sierra Leonean Leone
    'GMD': 'D',      // Gambian Dalasi
    'MZN': 'MT',     // Mozambican Metical
    'AOA': 'Kz',     // Angolan Kwanza
    'TMT': 'm',      // Turkmenistani Manat
    'KPW': '₩',      // North Korean Won
    'LAK': '₭',      // Lao Kip
    'KHR': '៛',      // Cambodian Riel
  };

  export const currencyNames : Record<string, string> = {
    'USD': 'US Dollar',
    'EUR': 'Euro',
    'GBP': 'British Pound',
    'JPY': 'Japanese Yen',
    'AUD': 'Australian Dollar',
    'CAD': 'Canadian Dollar',
    'CHF': 'Swiss Franc',
    'CNY': 'Chinese Yuan',
    'SEK': 'Swedish Krona',
    'NZD': 'New Zealand Dollar',
    'KRW': 'South Korean Won',
    'SGD': 'Singapore Dollar',
    'NOK': 'Norwegian Krone',
    'MXN': 'Mexican Peso',
    'INR': 'Indian Rupee',
    'RUB': 'Russian Ruble',
    'ZAR': 'South African Rand',
    'BRL': 'Brazilian Real',
    'TRY': 'Turkish Lira',
    'HKD': 'Hong Kong Dollar',
    'IDR': 'Indonesian Rupiah',
    'PHP': 'Philippine Peso',
    'DKK': 'Danish Krone',
    'THB': 'Thai Baht',
    'MYR': 'Malaysian Ringgit',
    'HUF': 'Hungarian Forint',
    'CZK': 'Czech Koruna',
    'ILS': 'Israeli New Shekel',
    'PLN': 'Polish Zloty',
    'TWD': 'New Taiwan Dollar',
    'SAR': 'Saudi Riyal',
    'AED': 'United Arab Emirates Dirham',
    'CLP': 'Chilean Peso',
    'KWD': 'Kuwaiti Dinar',
    'QAR': 'Qatari Riyal',
    'COP': 'Colombian Peso',
    'PEN': 'Peruvian Sol',
    'RON': 'Romanian Leu',
    'HRK': 'Croatian Kuna',
    'VND': 'Vietnamese Dong',
    'BDT': 'Bangladeshi Taka',
    'PKR': 'Pakistani Rupee',
    'EGP': 'Egyptian Pound',
    'NGN': 'Nigerian Naira',
    'MAD': 'Moroccan Dirham',
    'DZD': 'Algerian Dinar',
    'UAH': 'Ukrainian Hryvnia',
    'LKR': 'Sri Lankan Rupee',
    'BGN': 'Bulgarian Lev',
    'ARS': 'Argentine Peso',
    'IRR': 'Iranian Rial',
    'OMR': 'Omani Rial',
    'GHS': 'Ghanaian Cedi',
    'KES': 'Kenyan Shilling',
    'TZS': 'Tanzanian Shilling',
    'UGX': 'Ugandan Shilling',
    'NPR': 'Nepalese Rupee',
    'JOD': 'Jordanian Dinar',
    'LBP': 'Lebanese Pound',
    'SDG': 'Sudanese Pound',
    'BHD': 'Bahraini Dinar',
    'LYD': 'Libyan Dinar',
    'MUR': 'Mauritian Rupee',
    'MMK': 'Myanmar Kyat',
    'BND': 'Brunei Dollar',
    'MOP': 'Macanese Pataca',
    'KZT': 'Kazakhstani Tenge',
    'AZN': 'Azerbaijani Manat',
    'GEL': 'Georgian Lari',
    'BYN': 'Belarusian Ruble',
    'XAF': 'Central African CFA Franc',
    'XOF': 'West African CFA Franc',
    'XCD': 'East Caribbean Dollar',
    'TTD': 'Trinidad and Tobago Dollar',
    'BBD': 'Barbadian Dollar',
    'JMD': 'Jamaican Dollar',
    'BZD': 'Belize Dollar',
    'BSD': 'Bahamian Dollar',
    'GYD': 'Guyanese Dollar',
    'SRD': 'Surinamese Dollar',
    'HTG': 'Haitian Gourde',
    'KYD': 'Cayman Islands Dollar',
    'ANG': 'Netherlands Antillean Guilder',
    'AWG': 'Aruban Florin',
    'BAM': 'Bosnian Convertible Mark',
    'MKD': 'Macedonian Denar',
    'ALL': 'Albanian Lek',
    'ISK': 'Icelandic Krona',
    'MNT': 'Mongolian Tugrik',
    'AMD': 'Armenian Dram',
    'NAD': 'Namibian Dollar',
    'PGK': 'Papua New Guinean Kina',
    'FJD': 'Fijian Dollar',
    'SBD': 'Solomon Islands Dollar',
    'TOP': 'Tongan Paʻanga',
    'WST': 'Samoan Tala',
    'KGS': 'Kyrgyzstani Som',
    'UZS': 'Uzbekistani Som',
    'MGA': 'Malagasy Ariary',
    'MWK': 'Malawian Kwacha',
    'ZMW': 'Zambian Kwacha',
    'CDF': 'Congolese Franc',
    'DJF': 'Djiboutian Franc',
    'GNF': 'Guinean Franc',
    'RWF': 'Rwandan Franc',
    'BIF': 'Burundian Franc',
    'KMF': 'Comorian Franc',
    'SCR': 'Seychellois Rupee',
    'MVR': 'Maldivian Rufiyaa',
    'BTN': 'Bhutanese Ngultrum',
    'AFN': 'Afghan Afghani',
    'SOS': 'Somali Shilling',
    'SZL': 'Swazi Lilangeni',
    'LSL': 'Lesotho Loti',
    'BWP': 'Botswana Pula',
    'ZWL': 'Zimbabwean Dollar',
    'ERN': 'Eritrean Nakfa',
    'ETB': 'Ethiopian Birr',
    'SLL': 'Sierra Leonean Leone',
    'GMD': 'Gambian Dalasi',
    'MZN': 'Mozambican Metical',
    'AOA': 'Angolan Kwanza',
    'TMT': 'Turkmenistani Manat',
    'KPW': 'North Korean Won',
    'LAK': 'Lao Kip',
    'KHR': 'Cambodian Riel'
  };
  