import { Form } from "antd";
import CustomInputField from "components/common/CustomInputField";
import CustomSelector from "components/common/CustomSelector";
import { saveTopupItems } from "redux/slices/dashboardSlice";
import useToolkit from "utils/hooks/misc/useToolkit";
import { numbersOnlyRule } from "utils/appData/dashboardMisc";

type Props = {
	onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	setConfirmShown: React.Dispatch<React.SetStateAction<boolean>>;
	userWallets: UserWallet[];
	submitRef: React.RefObject<HTMLButtonElement>;
	isVirtual: boolean;
};

const TopupForm = ({
	onChange,
	setConfirmShown,
	submitRef,
	isVirtual,
}: Props) => {
	const { dispatch, toastError, useState } = useToolkit();
	const [amount, setAmount] = useState("");

	return (
		<Form
			onFinish={(vals) => {
				if (!amount?.length || !Number(amount) || Number(amount) < 1) {
					toastError("Enter a valid amount");
				} else {
					dispatch(
						saveTopupItems({ ...vals, amount, payment_method: "bank_transfer" })
					);
					setConfirmShown(true);
				}
			}}>
			<div className="w-full flex flex-col relative mb-5">
				{!isVirtual && (
					<CustomInputField
						type="number"
						isMapped
						value={amount}
						onValChange={(e: any) => setAmount(e.target.value)}
						customRule={numbersOnlyRule}
						label="Amount to deposit"
						placeholder="Enter amount"
					/>
				)}

				{/* <div className="absolute right-3 top-[18px] px-3 py-1 bg-[#201e28] rounded-md">
					<span className="text-xs twite">Fee: $20</span>
				</div> */}
			</div>
			<CustomSelector onChange={onChange} isUserWallets />
			<button className="hidden" ref={submitRef} type="submit">
				Submit
			</button>
		</Form>
	);
};

export default TopupForm;
