import OptionalRender from 'components/misc/OptionalRender';
import React, { Dispatch, SetStateAction } from 'react'
import { TextMenu, groupNotificationsByDate, todayFormatted } from './utils';
import useToolkit from 'utils/hooks/misc/useToolkit';
import { Dropdown } from "antd";
import { IoMdMore } from 'react-icons/io';

type Props = {
   notifications: any;
   loading: boolean;
   setNotifications: Dispatch<SetStateAction<NotificationItem[] | undefined>>;
}

const TransactionsTab = ({ notifications, loading, setNotifications }: Props) => {
   const { axiosInstance, userId, moment, handleRequestError } = useToolkit();
   const noItems = loading === false && notifications?.length === 0;
   const alertGroup = groupNotificationsByDate(notifications);

   const markItemAsRead = async (id: string) => {
      try {
         const { data } = await axiosInstance.patch(`notification`, { status: 1, notyId: id, });

         if (data?.status === true) {
            setNotifications((notifications?.map((item: any) => item?.notyId === id ? { ...item, status: 1, isUnread: false } : item)))
         }

         // const itemIndex = notifications?.findIndex((item: NotificationItem) => item?.notyId === id);

         // if (itemIndex !== -1) {
         //    const notificationClone = [...notifications];

         //    const notificationItem = {
         //       ...notificationClone[itemIndex],
         //       status: 1,
         //       isUnread: false,
         //    };

         //    notificationClone?.splice(itemIndex, 1, notificationItem);
         //    setNotifications(notificationClone);
         // }
      } catch (error) {
         handleRequestError(error);
      }
   };

   const markAllNotificationsRead = async () => {
      try {
         await axiosInstance.patch(`notification/unseen/${userId}`, { status: 1 });

         setNotifications(
            notifications?.map((item: NotificationItem) => ({
               ...item,
               status: 1,
               isUnread: false,
            }))
         );
      } catch (error) {
         handleRequestError(error);
      }
   };

   const deleteNotification = async (id: string) => {
      try {
         await axiosInstance({
            method: "delete",
            url: "notification",
            data: { notyId: id, userId, },
         });

         const itemIndex = notifications?.findIndex((item: NotificationItem) => item?.notyId === id);

         if (itemIndex !== -1) {
            const notificationClone = [...notifications];

            notificationClone.splice(itemIndex, 1);
            setNotifications(notificationClone);
         }
      } catch (error) {
         handleRequestError(error);
      }
   };


   return (
      <div className='w-full'>

         <OptionalRender condition={noItems}>
            <div className="flexed flex-col py-8">
               <img
                  alt="empty"
                  src="/assets/dashboard/no-notifications.svg"
               />

               <div className="font-medium text-qwid-dark-50 py-3">No notifications yet</div>

               <div className="text-qwid-gray-50 text-sm text-center">
                  You haven't received any updates. Check <br />
                  back later for important information
               </div>
            </div>
         </OptionalRender>

         <OptionalRender condition={noItems === false}>

            <div className="w-full flex items-center justify-end mt-px">
               <OptionalRender condition={noItems === false}>
                  <button
                     onClick={markAllNotificationsRead}
                     className="border-r pr-2.5 mx-3 text-[12px] text-deep-grey font-medium disabled:cursor-not-allowed disabled:text-gray-100">
                     Mark all as read
                  </button>

                  <button
                     onClick={() => setNotifications([])}
                     className="text-[12px] text-deep-grey font-medium disabled:cursor-not-allowed disabled:text-gray-100">
                     Clear All
                  </button>
               </OptionalRender>
            </div>

            <div className="pt-4">

               {alertGroup?.map(({ date, notifications }: any) => {
                  const isToday = date === todayFormatted;

                  return (
                     <div key={date} className='mb-4 w-full'>

                        <div className="flexed font-medium text-qwid-dark-50 relative">
                           <div className='h-px w-10 bg-qwid-gray-700' />
                           <span className='mx-4'>
                              {isToday && '🎉  '}
                              {isToday ? " You're up to date" : date}
                           </span>
                           <div className='h-px w-10 bg-qwid-gray-700' />
                        </div>

                        <div className="flex flex-col w-full py-4">
                           {notifications?.map(
                              ({ notyType, notyDesc, notyId, status, created_at, category, icon }: NotificationItem) => {

                                 const isRead = status === 1;
                                 // const isTrxCateg = category === 'transaction';
                                 // const isOtherCateg = category === 'others';

                                 return (
                                    <div onClick={(e: any) => e.stopPropagation()} key={notyId} className={`w-full flex items-start mb-2 p-2`}>

                                       <div className="mr-2.5 h-8 w-8 rounded-full bg-qwid-gray-700 flexed">
                                          <img
                                             width={17}
                                             src={icon}
                                             alt='notification'
                                          />
                                       </div>

                                       <div className="w-5/6">

                                          <p className={`mb-1 text-sm capt
                                          ${isRead ? 'text-qwid-gray-50' : 'text-qwid-dark-50 font-medium'}`}>
                                             {notyType}
                                          </p>

                                          <div className={`${isRead ? 'text-qwid-gray-50' : 'text-qwid-dark-50 font-medium'}`}>
                                             {notyDesc}

                                             <p className="mb-0 text-[10px] twide mt-2">
                                                {moment(created_at).format("h:mma")}
                                             </p>
                                          </div>

                                       </div>

                                       {/* <Dropdown
                                          overlay={
                                             <TextMenu
                                                id={notyId}
                                                status={status}
                                                markItemAsRead={markItemAsRead}
                                                deleteNotification={deleteNotification}
                                             />
                                          }
                                          trigger={["click"]}>

                                          <div className="mr-2">
                                             <IoMdMore className="cursor-pointer text-2xl" />
                                          </div>
                                       </Dropdown> */}
                                    </div>
                                 )
                              }

                           )}
                        </div>
                     </div>
                  )
               })}

            </div>
         </OptionalRender>

      </div>
   )
}

export default TransactionsTab