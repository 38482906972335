import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useAuth from 'utils/hooks/auth/useAuth';
import useToolkit from 'utils/hooks/misc/useToolkit';
import { setShowProductUpdate } from 'redux/slices/productsUpdateSlice';
import { setIsLoggedIn } from 'redux/slices/userSlice';

const getPageAnimXPrev = (currentPage: number): string => {
  return currentPage === 1
    ? '100%'
    : currentPage % 2 === 0
    ? '100%'
    : currentPage & 1
    ? '-100%'
    : '100%';
};

const getPageAnimX = (currentPage: number): string => {
  return currentPage === 1
    ? '-100%'
    : currentPage % 2 === 0
    ? '-100%'
    : currentPage & 1
    ? '100%'
    : '-100%';
};

const getPageAnimY = (currentPage: number): string => {
  return currentPage === 1
    ? '300'
    : currentPage % 2 === 0
    ? '-100%'
    : currentPage & 1
    ? '100%'
    : '-100%';
};

export enum LocalStorageKeys {
  LastClosedTimestamp = 'lastClosedTimestamp',
}

const TIME_TO_SHOW_NOTIFICATION = 2 * 7 * 24 * 60 * 60 * 1000; //2 weeks;

interface ProductUpdate {
  id: number;
  image: string;
  title: string;
  description: string;
}

interface ProductUpdateProps {
  data: ProductUpdate[];
}

const ProductUpdate = ({ data }: ProductUpdateProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isPrev, setIsPrev] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { getIsLoggedIn, isLoggedIn } = useAuth();
  const { useAppSelector, dispatch, router } = useToolkit();

  const { showProductUpdate } = useAppSelector(
    (state) => state.productUpdateSlice
  );
  const { referralCodeData } = useAppSelector(
    (state) => state.marketing_referral
  );
  const totalPages = data.length;

  const goToNextPage = () => {
    setIsPrev(false);
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPrevPage = () => {
    setIsPrev(true);
    setCurrentPage((prevPage) => prevPage - 1);
  };

  useEffect(() => {
    const lastClosedTimestamp = localStorage.getItem(
      LocalStorageKeys.LastClosedTimestamp
    );

    if (shouldShowNotificationAgain(lastClosedTimestamp)) {
      setShowModal(true);
    }
  }, []);

  const shouldShowNotificationAgain = (lastClosedTimestamp: string | null) => {
    if (!lastClosedTimestamp) {
      return true;
    }

    const twoWeeksInMillis = TIME_TO_SHOW_NOTIFICATION;
    const currentTime = new Date().getTime();
    const lastClosedTime = parseInt(lastClosedTimestamp, 10);

    return currentTime - lastClosedTime >= twoWeeksInMillis;
  };

  const handleClose = () => {
    localStorage.setItem(
      LocalStorageKeys.LastClosedTimestamp,
      new Date().getTime().toString()
    );
    setShowModal(false);
    dispatch(setShowProductUpdate(false));
  };

  if (!router.pathname.includes('/user')) return null;

  // User would be prompted to download the mobile app so there is no point showing this here
  if (referralCodeData?.isIOS) return null;

  if ((!showModal || !getIsLoggedIn) && !showProductUpdate) return null;

  const title = data?.[currentPage - 1]?.title;

  const shouldDisablePrevPage = currentPage <= 1;
  const shouldDisableNextPage = currentPage === data?.length;

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        className="fixed inset-0 items-start flex justify-center bg-black bg-opacity-50"
        style={{ zIndex: 8988988 }}
      >
        <motion.div
          initial={{ opacity: 0, y: '-100%' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 1, y: '100%' }}
          transition={{ duration: 0.5 }}
          className="bg-white mt-[20%] md:mt-[7%] lg:mt-[7%] xl:mt-[7%] 2xl:mt-[7%] px-4 md:px-10 lg:px-10 xl:px-10 2xl:px-10 py-5 rounded-lg w-full sm:w-full md:w-full lg:w-9/12 xl:w-7/12 2xl:w-8/12 overflow-hidden"
        >
          <header className="flex justify-between items-start">
            <h3 className="font-semibold text-[1.2em] md:text-[1.3em] lg:text-[1.3em] xl:text-[1.3em] 2xl:text-[1.3em]">
              What's new?
            </h3>
            <button
              className="hover:opacity-50 duration-200 cursor-pointer px-1 py-1 md:py-2 lg:py-2 xl:py-2 2xl:py-2"
              onClick={handleClose}
            >
              <img src="/assets/common/close.svg" alt="close notification" />
            </button>
          </header>

          <motion.div
            key={currentPage}
            initial={{
              opacity: 0,
              x: isPrev
                ? getPageAnimXPrev(currentPage)
                : getPageAnimX(currentPage),
              y: getPageAnimY(currentPage),
            }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            exit={{
              opacity: 0,
              x: isPrev
                ? getPageAnimXPrev(currentPage)
                : getPageAnimX(currentPage),
              y: getPageAnimY(currentPage),
            }}
            transition={{ duration: 0.35, ease: 'easeInOut' }}
          >
            <div className="relative mb-4">
              <h3 className="mt-6 font-semibold text-[1em] md:text-[1.1em] lg:text-[1.1em] xl:text-[1.1em] 2xl:text-[1.1em]">
                {title}
              </h3>
              <h3 className="font-light text-[0.95em] md:text-[1em] lg:text-[1em] xl:text-[1em] 2xl:text-[1em] inline-block">
                {data?.[currentPage - 1]?.description}
              </h3>
              <div className="pt-4 pb-3 md:pb-5 lg:pb-5 xl:pb-5 2xl:pb-5">
                <img
                  src={data?.[currentPage - 1]?.image}
                  alt={`Product Update - ${title}`}
                  className="w-full h-[23em] object-cover rounded-md"
                />
              </div>
            </div>
          </motion.div>

          <footer className="flex items-center justify-between pb-3">
            <button
              onClick={goToPrevPage}
              className={`text-[#0D1016] font-medium hover:opacity-80 duration-150 ease-in-out disabled:opacity-40 ${
                shouldDisablePrevPage ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              disabled={currentPage <= 1}
            >
              Previous
            </button>
            <div className="flex items-end text-gray-600">
              <div className="font-medium">{currentPage}</div>{' '}
              <div className="text-[0.87em] px-[0.3em]">of</div>{' '}
              <div className="text-[0.87em]">{totalPages}</div>
            </div>
            <button
              onClick={goToNextPage}
              className={`bg-[#48BBED] text-white px-5 py-2 rounded-md font-medium hover:opacity-80 duration-150 ease-in-out disabled:opacity-60 ${
                shouldDisableNextPage ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              disabled={shouldDisableNextPage}
            >
              Next
            </button>
          </footer>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ProductUpdate;
