import { Form, Tooltip } from "antd";
import React, { ChangeEvent } from "react";
import CurrencyFormat from "react-currency-format";
import { setWalletFlag, toggleQwidTransfer } from "redux/slices/dashboardSlice";

import {
	useToolkit,
	CenterModal,
	CustomInputField,
	QwidButton,
	CustomSelector,
	useBeneficiary,
	Spinner,
	useSendFunds,
	useTransactions,
} from "../../components";

type Props = {};

const QwidTransferModal = (props: Props) => {
	const {
		useAppSelector,
		dispatch,
		useRef,
		useEffect,
		useState,
		toastError,
		getSymbol,
		toastWarn,
		userId,
	} = useToolkit();
	const { isQwidTrfOpen, userWallets } = useAppSelector(
		(state) => state.dashboard
	);
	const { formatTrxAmount } = useTransactions();
	const [fromWallet, setFromWallet] = useState<any>();
	const [rcvWallet, setRcvWallet] = useState<any>();
	const [userMail, setUserMail] = useState("");

	const {
		verifLoading,
		verifyQwidUser,
		isVerified,
		initQwidTransfer,
		trfLoading,
		rcpId,
	} = useBeneficiary();

	const { getConversionRates, rateVals, sendValue, setSendValue } =
		useSendFunds();

	const submitRef = useRef<HTMLButtonElement>(null);
	const subWallets = userWallets?.map((item: any) => ({
		title: `${item?.cur} wallet`,
		value: item?.cur,
	}));

	const onWalletChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = e.target.value;
		setFromWallet(value);
		dispatch(setWalletFlag(value));
	};

	function makeTransfer(values: any) {
		const amount = parseInt(values?.amount);
		if (amount < 1) {
			toastError("Enter a valid amount");
		} else {
			const payload = {
				fromCur: fromWallet,
				toCur: rcvWallet,
				fromAmount: sendValue,
				toAmount: rateVals?.receiving?.split(" ")[1],
				charges: 0,
				receiver: rcpId,
			};
			initQwidTransfer(payload);
		}
	}

	const initVerification = () => {
		if (!userMail?.length) {
			toastWarn("Please enter a valid email");
		} else {
			verifyQwidUser(userMail);
		}
	};

	useEffect(() => {
		const ratePayload: ConversionPayload = {
			from: fromWallet || "USD",
			to: rcvWallet || "NGN",
			userId,
			transType: "convert",
		};
		getConversionRates(ratePayload);
	}, [fromWallet, rcvWallet, sendValue]);

	return (
		<CenterModal
			darkBg
			title={
				<>
					<span className="mb-1 twide med lg:text-lg">Internal Transfer</span>
					<br />
					<span className="text-sm font-normal mb-0">
						Transfer funds to Qwid user
					</span>
				</>
			}
			control={isQwidTrfOpen}
			onClose={() => dispatch(toggleQwidTransfer())}>
			{trfLoading ? (
				<Spinner />
			) : (
				<div className="w-full mt-0">
					<Form onFinish={makeTransfer} className="space-ys-7">
						<CustomSelector isUserWallets onChange={onWalletChange} />

						<div className="w-full relative pt-1 mt-5">
							<span className="absolute top-7 left-2.5 t6 text-[#384048] z-20">
								{getSymbol(fromWallet)}
							</span>
							<div className="form-custom-input-wrap">
								<label className="form-item__label">Sending </label>
								<CurrencyFormat
									style={{ paddingLeft: "30px" }}
									name="amount"
									value={sendValue}
									required
									onValueChange={({ floatValue }) => setSendValue(floatValue)}
									thousandSeparator
									placeholder="Enter amount"
									className="form-custom-input"
								/>
							</div>
						</div>
						<div className="my-4" />

						<p className="mb-1 med">Recipient</p>

						<div className="w-full relative mb-10">
							<CustomSelector
								itemLabel="Wallet"
								firstOption="Select Wallet"
								options={subWallets}
								onChange={(e) => setRcvWallet(e.target.value)}
							/>
							<span className="text-xs absolute right-0 twide -bottom-5">
								1 {fromWallet || "USD"}&nbsp; = &nbsp;
								{formatTrxAmount(rateVals?.rate)} {rcvWallet || "NGN"}
							</span>
						</div>

						<div className="w-full relative pb-5">
							<div className="flexed absolute right-2 top-2 z-20">
								<Tooltip
									title={
										<span className="text-xs">
											Click to verify recipient's email
										</span>
									}>
									<button
										type="button"
										className="bg-[#194153] text-white px-3 py-1 rounded-sm active:scale-90 transition-all"
										onClick={initVerification}>
										Verify
									</button>
								</Tooltip>
							</div>
							<CustomInputField
								isMapped
								value={userMail}
								onValChange={(e: ChangeEvent<HTMLInputElement>) =>
									setUserMail(e.target.value)
								}
								placeholder="Enter email address"
								name="email"
								label={verifLoading ? "Verifying..." : "Send to"}
							/>
						</div>

						<div className="my-3" />

						<CustomInputField
							nonEditable
							placeholder=" "
							value={rateVals?.receiving || ""}
							label="Receiving"
						/>

						<button className="hidden" ref={submitRef} type="submit">
							Submit
						</button>
					</Form>

					<div className="flex items-center justify-end space-x-3 mt-8 mb-2">
						<QwidButton
							onClick={() => dispatch(toggleQwidTransfer())}
							transparent
							text={<span className="px-2">Cancel</span>}
						/>

						<QwidButton
							disabled={verifLoading || !isVerified}
							onClick={() => submitRef.current?.click()}
							text={<span className="px-4">Transfer</span>}
						/>
					</div>
				</div>
			)}
		</CenterModal>
	);
};

export default QwidTransferModal;
