import QwidButton from 'components/misc/QwidButton';

export type ButtonTrayProps = {
  trayOrientation?: 'col' | 'row';
  handleBack?: () => void;
  handleContinue?: () => void;
  continueButtonLabel?: string;
  disableContinue?: boolean;
  loading?: boolean;
};

export const ButtonTray = ({
  trayOrientation = 'row',
  handleBack,
  handleContinue,
  continueButtonLabel,
  disableContinue,
  loading
}: ButtonTrayProps) => {
  let flexItemPosition = trayOrientation === 'col' ? 'justify-center' : 'justify-between';
  if (!handleBack) flexItemPosition = 'justify-end';
  return (
    <div
      className={`w-full flex ${
        trayOrientation === 'col' ? 'flex-col-reverse' : 'flex-row'
      } gap-6 items-center ${flexItemPosition}`}>
      {handleBack && (
        <div
          onClick={loading ? () => {} : handleBack}
          tabIndex={0}
          role='button'
          className='text-[#194153] font-semibold cursor-pointer'>
          Back
        </div>
      )}
      <div className={`w-full${trayOrientation === 'col' ? '' : ' max-w-[9rem]'}`}>
        <QwidButton
          disabled={!!disableContinue || loading}
          loading={loading}
          text={continueButtonLabel || 'Continue'}
          isFull
          type={!!handleContinue ? 'button' : 'submit'}
          darkBlueBg
          onClick={handleContinue}
        />
      </div>
    </div>
  );
};
