import { useToolkit } from "../../../components";
import {
  saveUserBankAccounts,
  setWithdrawalTransId,
  toggleWithdrawalRecipientStatus,
} from "redux/slices/sendflowSlice";

export enum BankField {
  IBAN = "IBAN",
  SWIFT = "SWIFT",
  ROUTINE = "ROUTINE",
}

export type WithdrawalFeeResp = {
  amount: string;
  created_at: string;
  id: number;
  mode: string;
  peer: string;
  toPay: number;
  transType: string;
  updated_at: string;
  userType: number;
  value: string;
};

const useSendFlow = () => {
  const {
    useState,
    axiosInstance,
    userId,
    dispatch,
    useAppSelector,
    handleRequestError,
    isPersonalAccount,
    useMutation,
    toastSuccess,
  } = useToolkit();
  const {
    withdrawalInfo: {
      isCreatingNewRecipient,
      withdrawalCountry,
      withdrawalCurrency,
    },
  } = useAppSelector((state) => state.sendflow);

  const [payoutOptionShown, setPayoutOptionShown] = useState(false);
  const [loading, setLoading] = useState(false);

  // getting user's saved bank accounts
  const [accountsLoading, setAccountsLoading] = useState(true);

  const getSavedBankAccounts = async () => {
    try {
      const {
        data: {
          content: { data },
        },
      } = await axiosInstance.get(`bank/${userId}`);
      dispatch(saveUserBankAccounts(data));
      if (data?.length === 0 && isCreatingNewRecipient === false) {
        dispatch(toggleWithdrawalRecipientStatus());
      }
      setAccountsLoading(false);
    } catch (error) {
      handleRequestError(error);
      setAccountsLoading(false);
      return error;
    }
  };

  // get API withdrawal charges
  const [wdlFee, setWdlFee] = useState<WithdrawalFeeResp | null>();
  const withdrawalChargeFn = async (payload: any) => {
    try {
      const data = await axiosInstance.get(
        `fee?transType=withdraw&userType=${isPersonalAccount ? 1 : 2}&peer=${
          payload?.cur
        }&amount=${payload?.amount}`
      );

      const response = data?.data?.content?.data[0];
      if (response?.amount?.length) {
        setWdlFee(response);
      } else {
        setWdlFee(null);
      }

      return data;
    } catch (error) {
      handleRequestError(error);
      return error;
    }
  };
  const useWithdrawalCharge = () => useMutation(withdrawalChargeFn);

  // get withdrawal channel
  const withdrawalChannelFn = async (payload: any) => {
    try {
      const data = await axiosInstance.post(
        "transaction-support/get-channel",
        payload
      );
      const response = data?.data?.content?.data || [];

      return response;
    } catch (error) {
      handleRequestError(error);
      return error;
    }
  };
  const useWithdrawalChannel = () => useMutation(withdrawalChannelFn);

  // get withdrawal options
  const withdrawalOptionsFn = async (category: string) => {
    try {
      const data = await axiosInstance.post(
        "transaction-support/get-payout-option",
        {
          country: withdrawalCountry,
          cur: withdrawalCurrency,
          category,
        }
      );

      const response = data?.data?.content?.data;
      if (response?.length > 0) {
        setPayoutOptionShown(true);
      } else if (response?.length === 0) {
        setPayoutOptionShown(false);
        getBeneficiaryForms("");
      }

      return response;
    } catch (error) {
      handleRequestError(error);
      return error;
    }
  };
  const useWithdrawalOptions = () => useMutation(withdrawalOptionsFn);

  // get beneficiary forms
  const [beneficiaryFields, setBeneficiaryFields] = useState([]);
  const [fieldsLoading, setFieldsLoading] = useState(false);
  const beneficiaryFormsFn = async (payload?: any) => {
    setFieldsLoading(true);
    try {
      const data = await axiosInstance.post("beneficiary-bank-forms", {
        country: withdrawalCountry,
        cur: withdrawalCurrency,
        category: payload?.category,
        option: payload?.option,
      });
      const response = data?.data?.data;
      setBeneficiaryFields(response);
      setFieldsLoading(false);
      return response;
    } catch (error) {
      handleRequestError(error);
      setFieldsLoading(false);
      return error;
    }
  };
  const useBeneficiaryForms = () => useMutation(beneficiaryFormsFn);

  const { mutate: getBeneficiaryForms } = useBeneficiaryForms();

  // initiate withdrawal to user's account
  const [wdlLoading, setWdlLoading] = useState(false);
  const initiateWithdrawal = async (payload: any) => {
    setWdlLoading(true);
    try {
      const data = await axiosInstance.post(
        `transaction/initiate-withdrawal/${userId}`,
        payload
      );

      setWdlLoading(false);
      toastSuccess(data?.data?.message);

      const response = data?.data?.content?.data;
      dispatch(setWithdrawalTransId(response?.transId));
      return response;
    } catch (error) {
      handleRequestError(error);
      setWdlLoading(false);
      setLoading(false);
      return error;
    }
  };

  // verify user's transaction pin with api
  const [pinVerifyLoading, setPinVerifyLoading] = useState(false);
  const [pinVerifyData, setPinVerifyData] = useState(null);
  const verifyTxnPin = async (pin: any, handleStateExternally?: boolean) => {
    setPinVerifyLoading(true);
    try {
      const data = await axiosInstance.post("pin/verify", {
        userId,
        pin: pin.toString(),
      });
      const response = data;
      setPinVerifyLoading(false);
      return response;
    } catch (error) {
      setPinVerifyLoading(false);
      if (!handleStateExternally) {
        handleRequestError(error);
        return error;
      }
      throw error;
    }
  };

  // add recipient bank account
  const [addBankLoading, setAddBankLoading] = useState(false);

  const addWithdrawalBankAccount = async (payload: any) => {
    setAddBankLoading(true);

    try {
      const data = await axiosInstance.post(`bank/${userId}`, payload);
      const response = data?.data;
      setAddBankLoading(false);
      return response;
    } catch (error) {
      handleRequestError(error);
      setAddBankLoading(false);
    }
  };

  return {
    getSavedBankAccounts,
    accountsLoading,
    setAccountsLoading,
    wdlFee,
    useWithdrawalCharge,
    useWithdrawalChannel,
    useWithdrawalOptions,
    payoutOptionShown,
    useBeneficiaryForms,
    initiateWithdrawal,
    wdlLoading,
    fieldsLoading,
    beneficiaryFields,
    pinVerifyLoading,
    pinVerifyData,
    verifyTxnPin,
    addWithdrawalBankAccount,
    addBankLoading,
    loading,
    setLoading,
  };
};

export default useSendFlow;
