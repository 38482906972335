type Props = {};

type ItemProps = {
	title: string;
	subtitle: string;
	iconPath: string;
	imgClass: string;
	isLarge?: boolean;
};

const FinancialPossibilities = (props: Props) => {
	const items: ItemProps[] = [
		{
			title: "Withdraw to your local bank",
			subtitle:
				"Easily transfer money from your qwid account to a local bank account",
			iconPath: "/assets/business/finpos-tl.svg",
			isLarge: true,
			imgClass: "w-[250px] lg:w-[300px]",
		},
		{
			title: "Withdraw to your local bank",
			subtitle:
				"Easily transfer money from your qwid account to a local bank account",
			iconPath: "/assets/business/finpos-tr.svg",
			imgClass: "w-[250px] lg:w-[280px]",
		},
		{
			title: "Create a virtual account",
			subtitle:
				"Manage multiple currencies with ease, using our convenient and flexible virtual accounts",
			iconPath: "/assets/business/finpos-bl.svg",
			imgClass: "w-[250px] lg:w-[250px]",
		},
		{
			title: "Pay overseas contractors and suppliers",
			subtitle:
				"Whether it's Chinese Yuan, Canadian Dollars or Colombian Pesos, send money to your contractors and suppliers and have them receive funds in their local currencies",
			iconPath: "/assets/business/finpos-br.svg",
			isLarge: true,
			imgClass: "w-[150px] lg:w-[200px]",
		},
	];

	return (
		<div className="bg-[#eaf7fd] py-8 lg:py-16 side-pad">
			<h2 className="mx-auto text-lg lg:text-3xl font-semibold mb-16 text-center">
				Unlock new financial possibilities
			</h2>

			<div className="w-full flex items-center flex-wrap">
				{items.map(
					(
						{ title, subtitle, iconPath, isLarge, imgClass }: ItemProps,
						idx
					) => {
						const isOddPosition = (idx + 1) % 2;

						return (
							<div
								key={title}
								className={`p-5 w-full mb-5 h-[320px] lg:h-[350px] rounded-md shadow-md ${
									isLarge
										? "bg-white lg:w-[68%] text-[#121212] flex lg:justify-start"
										: "bg-[#1994ca] lg:w-[30%] text-[#f4f4f4] flexed"
								} ${isOddPosition && "lg:mr-4"}`}>
								<div className={`w-full h-full relative`}>
									<p className="mb-3 lg:text-lg font-medium">{title}</p>
									<p
										className={`mb-0 text-justify text-sm w-full ${
											isLarge ? "lg:w-3/5" : "lg:w-3/4"
										}`}>
										{subtitle}
									</p>
									<div className="w-full lg:w-max absolute bottom-3 flexed mts-auto">
										<img src={iconPath} alt={title} className={imgClass} />
									</div>
								</div>
							</div>
						);
					}
				)}
			</div>
		</div>
	);
};

export default FinancialPossibilities;
