import { Collapse, Switch } from "antd";
import { BsChevronDown } from "react-icons/bs";
import {
	toggleCardDetails,
	toggleDisableCardModal,
} from "redux/slices/virtualsSlice";
import styled from "styled-components";
import { useToolkit, useTransactions, Tooltip } from "../../components";

type Props = {
	selectedVCard: VirtualCard | null;
	transData: VirtualCardTrans[];
	cardData: any;
	cardDisabled: boolean;
	transLoading: boolean;
	detailsShown: boolean;
	toggleCardStatus: (payload: any) => Promise<unknown>;
	toggleCardSecurity: () => void;
	deleteOnly: boolean;
	isFreezable: boolean;
};

type ActionBtnProps = {
	danger?: boolean;
	text: string;
	onClick: () => void;
};

const CardTransDetails = ({
	cardData,
	toggleCardStatus,
	cardDisabled,
	detailsShown,
	deleteOnly,
	isFreezable,
}: Props) => {
	const { Panel } = Collapse;
	const {
		getSymbol,
		avail,
		moment,
		useAppSelector,
		dispatch,
		globalCardDetailsHidden: detailsHidden,
		spacifyCardNumber,
		getCardExpiry,
		CopyIcon,
	} = useToolkit();
	const { formatTransStatus } = useTransactions();
	const { cardInfo } = useAppSelector((state) => state.virtuals);
	const card = cardInfo?.cardDetails;

	const container_class = "w-full md:w-[48.5%] container my-5 md:my-0";

	const formattedCardNumber = cardData?.cardNumber?.length
		? cardData?.cardNumber?.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " - ")
		: "0000 0000 0000";

	const switchCardDetailsHidden = () => dispatch(toggleCardDetails());

	const isCardFrozen = card?.virtualCardFreeze == true;

	const ActionBtn = ({ danger, text, onClick }: ActionBtnProps) => {
		return (
			<button
				onClick={onClick}
				className={`px-3 py-1 flexed outline-0 active:scale-90 trall border rounded-md hover:text-white ${
					danger
						? "text-red-500 border-red-500 hover:bg-red-500"
						: "text-[#368CB2] border-[#368CB2] hover:bg-[#368CB2]"
				}`}>
				{text}
			</button>
		);
	};

	const fullCardAddress = `${cardData?.address}, ${cardData?.city} ${cardData?.state} ${cardData?.zipcode}, ${cardData?.country}`;

	const toggleStatus = () => {
		const payload = {
			virtualCardId: card?.virtualCardId,
			mode: "freeze",
			status: isCardFrozen ? 1 : 2,
		};
		toggleCardStatus(payload);
	};

	function customTransHeader({
		transactionPurpose: purpose,
		virtualCardCur,
		created_at,
		amount,
	}: VirtualCardTrans) {
		return (
			<div className="w-full flex-btw">
				<div className="flect">
					<div className="w-10 h-10 lg:text-lg flexed smb rounded-full bg-[#D9D9D9] mr-5">
						{purpose[0]}
					</div>
					<div className="flex-col">
						<p className="med twide mb-1">{avail(purpose)}</p>
						<p className="text-sm text-gray-400 mb-0">
							{moment(created_at).format("ddd, Do MMM. YYYY")}
						</p>
					</div>
				</div>
				<div className="flect">
					<p className="mb-0 med lg:text-lg">
						{getSymbol(virtualCardCur)}&nbsp;{avail(amount)}
					</p>
					<BsChevronDown className="ml-3" size="1.2rem" />
				</div>
			</div>
		);
	}

	return (
		<Wrapper className="w-full my-5 flex items-start justify-center md:justify-between flex-wrap md:flex-nowrap">
			<div className={container_class}>
				<p className="container-title">TRANSACTIONS</p>
				<div className="w-full">
					<Collapse
						style={{
							border: "none",
						}}
						accordion
						expandIconPosition="end"
						defaultActiveKey={["1"]}>
						{cardInfo?.cardTransactions?.map((trx: VirtualCardTrans) => {
							const {
								id,
								presentBalance,
								virtualTransId,
								created_at,
								transactionPurpose,
								virtualTransType,
								virtualTransStatus,
								previousBalance,
								virtualCardCur,
								amount,
								virtualCardDesc,
								virtualCardReason,
							} = trx;
							const isDebit = virtualTransType === "debit";
							parseFloat(presentBalance) - parseFloat(previousBalance);
							return (
								<Panel
									style={{
										backgroundColor: "#fff",
										border: "0px",
										marginBottom: "20px",
									}}
									showArrow={false}
									header={customTransHeader(trx)}
									key={id}>
									<div className="w-full py-5">
										<div className="detail-wrap flex-btw">
											<div className="detail-key">
												<h4>Transaction ID</h4>
											</div>
											<div className="detail-value">
												{avail(virtualTransId)}
											</div>
										</div>

										<div className="detail-wrap flex-btw">
											<div className="detail-key">
												<h4>Amount</h4>
											</div>
											<div
												className={`detail-value med twide ${
													isDebit ? "text-red-500" : "text-green-500"
												}`}>
												{avail(` ${getSymbol(virtualCardCur)} ${amount}`)}
											</div>
										</div>

										<div className="detail-wrap flex-btw">
											<div className="detail-key">
												<h4>Date</h4>
											</div>
											<div className="detail-value">
												{avail(moment(created_at).format("ddd, Do MMM. YYYY"))}
											</div>
										</div>

										<div className="detail-wrap flex-btw">
											<div className="detail-key">
												<h4>Purpose</h4>
											</div>
											<div className="detail-value">
												{avail(transactionPurpose)}
											</div>
										</div>

										<div className="detail-wrap flex-btw">
											<div className="detail-key">
												<h4>Payment Method</h4>
											</div>
											<div className="detail-value">N/A</div>
										</div>

										<div className="detail-wrap flex-btw">
											<div className="detail-key">
												<h4>Transaction Type</h4>
											</div>
											<div className="detail-value capt">
												{avail(virtualTransType)}
											</div>
										</div>

										<div className="detail-wrap flex-btw">
											<div className="detail-key">
												<h4>Description</h4>
											</div>
											<div className="detail-value capt">
												{avail(virtualCardDesc)}
											</div>
										</div>

										{virtualCardReason && (
											<div className="detail-wrap flex-btw">
												<div className="detail-key">
													<h4>Reason</h4>
												</div>
												<div className="detail-value capt">
													{avail(virtualCardReason)}
												</div>
											</div>
										)}

										<div className="detail-wrap flex-btw">
											<div className="detail-key">
												<h4>Status</h4>
											</div>
											<div className="detail-value">
												{avail(formatTransStatus(virtualTransStatus))}
											</div>
										</div>
									</div>
								</Panel>
							);
						})}
					</Collapse>
				</div>
			</div>
			<div className={container_class}>
				<p className="container-title">CARD DETAILS</p>
				<div className="w-full border border-gray-300 rounded-lg py-5 px-7">
					<div className="mb-8 flect">
						<p className="mb-0 med lg:text-lg mr-7">
							{detailsShown ? "Hide" : "Show"} Details
						</p>
						<Switch
							defaultChecked={detailsShown}
							onChange={switchCardDetailsHidden}
						/>
					</div>
					<div className="detail-wrap2 flex-btw">
						<div className="detail-key">
							<p className="mb-0">Card Balance</p>
						</div>
						<p className="detail-value2 pry-text">
							{card && getSymbol(card?.virtualCardCur)}{" "}
							{card?.virtualCardBalance.toLocaleString()}
						</p>
					</div>

					<div className="detail-wrap2 flex-btw">
						<div className="detail-key">
							<p className="mb-0">Name</p>
						</div>
						<p className="detail-value2">{card?.virtualCardName}</p>
					</div>

					<div className="detail-wrap2 flex-btw">
						<div className="detail-key">
							<p className="mb-0">Card Status</p>
						</div>
						<p
							className={`detail-value2 upc twide ${
								card?.virtualCardFreeze ? "text-red-500" : "text-green-500"
							}`}>
							{card?.virtualCardFreeze ? "Inactive" : "Active"}
						</p>
					</div>

					<div className="detail-wrap2 flex-btw">
						<div className="detail-key">
							<p className="mb-0">Date Created</p>
						</div>
						<p className="detail-value2">
							{moment(card?.created_at).format("ddd Do MMM, YYYY")}
						</p>
					</div>

					<div className="detail-wrap2 flex-btw">
						<div className="detail-key">
							<p className="mb-0 nowrap">Billing Address</p>
						</div>
						<p className="detail-value2 flect text-right pl-8">
							{detailsShown ? avail(fullCardAddress) : "- - -"}
							{detailsShown && (
								<CopyIcon item={fullCardAddress} title="Billing Address" />
							)}
						</p>
					</div>

					<div className="detail-wrap2 flex-btw">
						<div className="detail-key">
							<p className="mb-0">Card Number</p>
						</div>
						<p className="detail-value2 flex items-center">
							{detailsShown
								? formattedCardNumber
								: spacifyCardNumber(cardData?.cardNumber)}
							{detailsShown && (
								<CopyIcon item={cardData?.cardNumber} title="Card Number" />
							)}
						</p>
					</div>

					<div className="detail-wrap2 flex-btw">
						<div className="detail-key">
							<p className="mb-0">Expiry Date</p>
						</div>
						<p className="detail-value2 flect">
							{detailsShown
								? getCardExpiry(cardData?.expiryMonth, cardData?.expiryYear)
								: "- - -"}{" "}
							{detailsShown && (
								<CopyIcon
									item={`${cardData?.expiryMonth}/${cardData?.expiryYear}`}
									title="Expiry Date"
								/>
							)}
						</p>
					</div>

					<div className="detail-wrap2 flex-btw">
						<div className="detail-key">
							<p className="mb-0">CVV</p>
						</div>
						<p className="detail-value2 flect">
							{detailsShown ? cardData?.cvv : "- - -"}
							{detailsShown && <CopyIcon item={cardData?.cvv} title="CVV" />}
						</p>
					</div>
					<div className="mt-7 lg:mt-10 flect space-x-5">
						{deleteOnly ? (
							<ActionBtn
								onClick={() => dispatch(toggleDisableCardModal())}
								danger
								text="Delete card"
							/>
						) : (
							<ActionBtn
								onClick={() => dispatch(toggleDisableCardModal())}
								danger
								text={cardDisabled ? "Enable card" : "Disable card"}
							/>
						)}
						{isFreezable && (
							<ActionBtn
								onClick={toggleStatus}
								text={card?.virtualCardFreeze ? "Unfreeze card" : "Freeze card"}
							/>
						)}
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default CardTransDetails;

const Wrapper = styled.div`
	.container-title {
		color: #252d3e;
		font-weight: 600;
		font-size: 20px;
		line-height: 130%;
		letter-spacing: 0.013em;
		margin-bottom: 30px;
	}

	.detail-wrap {
		margin-bottom: 10px;
	}

	.detail-wrap2 {
		margin-bottom: 18px;
	}

	.detail-key {
		font-size: 16px;
	}

	.detail-value {
		font-size: 14px;
	}

	.detail-value2 {
		font-size: 16px;
		margin-bottom: 0;
		font-weight: 500;
	}
`;
