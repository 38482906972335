import styled from "styled-components";
import { useToolkit, Spinner, TextTruncator } from "../../components";
import { CgTrashEmpty } from "react-icons/cg";
import { BsBookmarkFill } from "react-icons/bs";
import { setNotifications } from "redux/slices/layoutSlice";

function NotificationsUI() {
	const {
		useAppSelector,
		useQuery,
		axiosInstance,
		userId,
		moment,
		dispatch,
		useEffect,
	} = useToolkit();
	const { isNotificationsOpen } = useAppSelector((state) => state.layout);

	const getNotifications = async () => {
		const {
			data: {
				content: { data },
			},
		} = await axiosInstance.post(`notification/unseen/${userId}`);
		dispatch(setNotifications(data));
		return data;
	};

	const {
		isLoading: loading,
		data: notifications,
		refetch: fetchNotifications,
	} = useQuery("getNotifications", getNotifications, { staleTime: 120000 });

	const isEmpty = notifications?.length === 0;

	useEffect(() => {
		if (isNotificationsOpen) {
			// const getItems = setInterval(() => fetchNotifications(), 120000);
			// return () => clearInterval(getItems);
			fetchNotifications();
		}
	}, []);

	return (
		<Container
			className={`absolute right-0 md:right-1 min-h-[300px] max-h-[500px] overflow-y-auto top-1 bg-white shadow-lg w-11/12 md:w-3/5 lg:w-[33%] rounded-lg ${
				isNotificationsOpen ? "show-container" : "hide-container"
			}`}>
			<div className="sticky top-0 left-0 w-full mb-6 bg-white p-3 rounded-t-lg border-b border-b-[#E0E0E0]">
				<p className="mb-0 font-semibold lg:text-lg">Notifications</p>
			</div>
			<div className="flexed flex-col w-full h-full">
				{loading ? (
					<Spinner msg="One sec" />
				) : isEmpty ? (
					<div className="w-full flexed flex-col mt-10">
						<CgTrashEmpty className="text-4xl mb-1" />
						<p className="tcent pry-text mb-0">No new items</p>
					</div>
				) : (
					<div className="flex flex-col w-full px-2">
						{notifications?.map(
							({
								notyType,
								notyDesc,
								notyId,
								created_at,
							}: NotificationItem) => (
								<div key={notyId} className="w-full flex px-3 mb-4 pb-3">
									<div className="mr-3">
										<BsBookmarkFill className="text-xl pry-text" />
									</div>
									<div>
										<p className="mb-2 text-[#252D3E] text-sm med capt">
											{notyType}
										</p>
										<div>
											<TextTruncator
												onSeeMore={() => {}}
												id={notyId}
												className="mb-0 twide text-[#1A1F36] text-sm text-justify">
												{notyDesc}
											</TextTruncator>
											<p className="mb-0 text-xs smb twide mt-2 text-[#9C9C9C]">
												{moment(created_at).calendar()}
											</p>
										</div>
									</div>
								</div>
							)
						)}
					</div>
				)}
			</div>
		</Container>
	);
}

export default NotificationsUI;

const Container = styled.div`
	&.hide-container {
		opacity: 0;
		z-index: 0;
	}

	&.show-container {
		z-index: 500 !important;
		opacity: 1;
	}

	::-webkit-scrollbar {
		width: 5px;
	}

	::-webkit-scrollbar-track {
		background-color: transparent;
		margin-top: 5px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #48bbed;
		border-radius: 2px;
	}
`;
