import isNode from "detect-node";

const firebaseConfig = {
	apiKey: "AIzaSyB-tcDMtmp0aq5M63rFfIXSW05UUDQDIRc",
	authDomain: "qwid-9d4c7.firebaseapp.com",
	projectId: "qwid-9d4c7",
	storageBucket: "qwid-9d4c7.appspot.com",
	messagingSenderId: "647759373568",
	appId: "1:647759373568:web:0a37ed58279925c2e4bfe5",
	measurementId: "G-PHKDPN068Q",
};

const firebaseUtils = (() => {
	// Ensure it's not a node environment and that service workers are supported
	if (!isNode && 'serviceWorker' in navigator) {
		const { initializeApp } = require("firebase/app");
		const { getMessaging, getToken, onMessage } = require("firebase/messaging");

		// Initialize Firebase
		const app = initializeApp(firebaseConfig);
		const messaging = getMessaging(app);

		/**
		 * Used to request push notification permission from user.
		 * Returns true if user grants the permission else, it returns false.
		 */
		const sendRequestPermission = async (): Promise<boolean> => {
			const permission = await Notification.requestPermission();
			return ["granted", "default"].includes(permission);
		};

		const getFCMToken = async () => {
			return getToken(messaging, {
				vapidKey: "BKyBKokPk7indVpGdslju5LbYxMFjj_3H1OLlrP20Oz-y35xOj3HlMmv39cROEY5LWvS7yXbn-6XgVVuBNd2nWY",
			});
		};

		const onMessageListener = async (cb?: (res: any) => void): Promise<any> => {
			if (cb) {
				onMessage(messaging, (payload: any) => {
					cb(payload);
				});
			} else {
				return new Promise((resolve) => {
					onMessage(messaging, (payload: any) => {
						resolve(payload);
					});
				});
			}
		};

		return {
			sendRequestPermission,
			onMessageListener,
			getFCMToken,
		};
	}

	return {};
})();

export const sendRequestPermission = firebaseUtils.sendRequestPermission;
export const onMessageListener = firebaseUtils.onMessageListener;
export const getFCMToken = firebaseUtils.getFCMToken;
