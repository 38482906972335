import { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri"
import Placeholder from "react-select/dist/declarations/src/components/Placeholder";

interface SearchbarProps {
    placeholder? : string;
    searchFunction? : (value: string)=> void;
}
const Searchbar = ({
    placeholder,
    searchFunction
}:SearchbarProps) => {
    const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

    useEffect(() => {
    const debounceTimeout = setTimeout(() => {
        searchFunction &&  searchFunction(inputValue);
    }, 800);

    return () => clearTimeout(debounceTimeout);
  }, [inputValue]);

  return (
    <div className="p-3 pl-4 pr-4 text-gray-500 rounded-[10px] border-[1px] flex items-center gap-4">
        <RiSearchLine className="!w-[1.2rem] !h-[1.2rem] pointer-events-none" />
        <input type="text"
         className="outline-none text-[0.85rem] border-0 w-full" 
         placeholder={placeholder}
         value={inputValue}
         onChange={handleInputChange}
         />
    </div>
  )
}

export default Searchbar