import React from 'react'

type Props = {}

const UpdatesTab = (props: Props) => {
   return (
      <div>
         <div className="flexed flex-col py-8">
            <img
               alt="empty"
               src="/assets/dashboard/no-notifications.svg"
            />

            <div className="font-medium text-qwid-dark-50 py-3">No notifications yet</div>

            <div className="text-qwid-gray-50 text-sm text-center">
               You haven't received any updates. Check <br />
               back later for important information
            </div>
         </div>
      </div>
   )
}

export default UpdatesTab