import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { KYCStatus } from '../../custom-types';

export enum PersonalOnboardingPageViews {
  PERSONAL_INFORMATION = 0,
  CONTACT_INFORMATION = 1,
  VERIFY_IDENTITY = 2,
  SUMMARY = 3,
  UPLOAD_DOCS = 4
}

export enum BusinessOnboardingPageViews {
  REP_INFORMATION = 0,
  BUSINESS_INFORMATION = 1,
  DIRECTORS = 2,
  SUMMARY = 3,
  UPLOAD_DOCS = 4
}

interface OnboardingCommon {
  currentTab?: number;
}

export type PersonalInformationPayload = {
  modified: boolean;
  completed: boolean;
  payload: {
    firstName?: string;
    lastName?: string;
    dob?: string;
    sex?: string;
    employmentStatus?: string;
    pep?: string;
    nationality?: string;
  };
};

export type ContactInformationPayload = {
  modified: boolean;
  completed: boolean;
  payload: {
    country?: string;
    countryCode?: string;
    mobileCode?: string;
    address?: string;
    state?: string;
    city?: string;
    house_number?: number;
    mobile?: string;
    zipcode?: string;
  };
};
export type VerifyIdentityInformationPayload = {
  modified: boolean;
  completed: boolean;
  payload: {
    country?: string;
    identityType?: string;
  } & { [key: string]: string };
};

export type UploadDocsInformationPayload = {
  modified: boolean;
  completed: boolean;
  payload: {
    kycStatus?: KYCStatus;
  };
};
export type PersonalSummaryPayload = {
  completed: boolean;
};

type IdentityField = {
  id: string;
  name: string;
  other_fields: {
    fieldName: string;
    title: string;
    required: string;
    verification?: {
      dataType: string;
    };
  }[];
};

type IdentityFieldsByCountry = {
  [key: string]: IdentityField[];
};

type BusinessPayload = {
  modified: boolean;
  completed: boolean;
  allowed: boolean;
  kycStatus?: KYCStatus;
};

export interface PersonalState extends OnboardingCommon {
  [PersonalOnboardingPageViews.PERSONAL_INFORMATION]: PersonalInformationPayload;
  [PersonalOnboardingPageViews.CONTACT_INFORMATION]: ContactInformationPayload;
  [PersonalOnboardingPageViews.VERIFY_IDENTITY]: VerifyIdentityInformationPayload;
  [PersonalOnboardingPageViews.SUMMARY]: PersonalSummaryPayload;
  [PersonalOnboardingPageViews.UPLOAD_DOCS]: UploadDocsInformationPayload;
  identityVerificationFields: IdentityFieldsByCountry;
}

export interface BusinessState extends OnboardingCommon {
  [BusinessOnboardingPageViews.REP_INFORMATION]: BusinessPayload;
  [BusinessOnboardingPageViews.BUSINESS_INFORMATION]: BusinessPayload;
  [BusinessOnboardingPageViews.DIRECTORS]: BusinessPayload;
  [BusinessOnboardingPageViews.SUMMARY]: BusinessPayload;
  [BusinessOnboardingPageViews.UPLOAD_DOCS]: BusinessPayload;
}

interface OnboardingState {
  personal: PersonalState;
  business: BusinessState | any;
  businessIndex: number;
  businessChangeModalOpen: boolean;
}

const initialState: OnboardingState = {
  personal: {
    currentTab: undefined,
    [PersonalOnboardingPageViews.PERSONAL_INFORMATION]: {
      modified: false,
      completed: false,
      payload: {}
    },
    [PersonalOnboardingPageViews.CONTACT_INFORMATION]: {
      modified: false,
      completed: false,
      payload: {}
    },
    [PersonalOnboardingPageViews.VERIFY_IDENTITY]: {
      modified: false,
      completed: false,
      payload: {}
    },
    [PersonalOnboardingPageViews.SUMMARY]: {
      completed: false
    },
    [PersonalOnboardingPageViews.UPLOAD_DOCS]: {
      modified: false,
      completed: false,
      payload: {}
    },
    identityVerificationFields: {}
  },
  business: {
    currentTab: 0,
    [BusinessOnboardingPageViews.REP_INFORMATION]: {
      modified: false,
      completed: false,
      allowed: false
    },
    [BusinessOnboardingPageViews.BUSINESS_INFORMATION]: {
      modified: false,
      completed: false,
      allowed: false
    },
    [BusinessOnboardingPageViews.DIRECTORS]: {
      modified: false,
      completed: false,
      allowed: false
    },
    [BusinessOnboardingPageViews.SUMMARY]: {
      modified: false,
      completed: false,
      allowed: false
    },
    [BusinessOnboardingPageViews.UPLOAD_DOCS]: {
      modified: false,
      completed: false,
      allowed: false
    }
  },
  businessIndex: 0,
  businessChangeModalOpen: false
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    incrementCurrentTab: (state, { payload }: PayloadAction<{ type: 'personal' | 'business' }>) => {
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          currentTab: state[payload.type].currentTab! + 1
        }
      };
    },

    decrementCurrentTab: (state, { payload }: PayloadAction<{ type: 'personal' | 'business' }>) => {
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          currentTab: state[payload.type].currentTab! - 1
        }
      };
    },

    setCurrentTab: (
      state,
      {
        payload
      }: PayloadAction<{
        type: 'personal' | 'business';
        page: PersonalOnboardingPageViews | BusinessOnboardingPageViews;
      }>
    ) => {
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          currentTab: payload.page
        }
      };
    },
    setPersonalDefaultState: (state, { payload }: PayloadAction<Partial<PersonalState>>) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          ...payload
        }
      };
    },
    setPersonalInfo: (state, { payload }: PayloadAction<Partial<PersonalInformationPayload>>) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          [PersonalOnboardingPageViews.PERSONAL_INFORMATION]: {
            ...state.personal[PersonalOnboardingPageViews.PERSONAL_INFORMATION],
            ...payload
          }
        }
      };
    },
    setContactInfo: (state, { payload }: PayloadAction<Partial<ContactInformationPayload>>) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          [PersonalOnboardingPageViews.CONTACT_INFORMATION]: {
            ...state.personal[PersonalOnboardingPageViews.CONTACT_INFORMATION],
            ...payload
          }
        }
      };
    },
    updateVerificationFieldsPerCountry: (
      state,
      { payload }: PayloadAction<IdentityFieldsByCountry>
    ) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          identityVerificationFields: {
            ...state.personal.identityVerificationFields,
            ...payload
          }
        }
      };
    },

    setVerifyIdentityInfo: (
      state,
      { payload }: PayloadAction<Partial<VerifyIdentityInformationPayload>>
    ) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          [PersonalOnboardingPageViews.VERIFY_IDENTITY]: {
            ...state.personal[PersonalOnboardingPageViews.VERIFY_IDENTITY],
            ...payload
          }
        }
      };
    },
    setPersonalSummaryAsCompleted: (state) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          [PersonalOnboardingPageViews.SUMMARY]: { completed: true }
        }
      };
    },
    setUploadDocsInfo: (
      state,
      { payload }: PayloadAction<Partial<UploadDocsInformationPayload>>
    ) => {
      return {
        ...state,
        personal: {
          ...state.personal,
          [PersonalOnboardingPageViews.UPLOAD_DOCS]: {
            ...state.personal[PersonalOnboardingPageViews.UPLOAD_DOCS],
            ...payload
          }
        }
      };
    },
    nextBusinessPage: (state) => {
      return {
        ...state,
        businessIndex: 1
      };
    },
    previousBusinessPage: (state) => {
      return {
        ...state,
        businessIndex: 0
      };
    },
    setRepCompleted: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.REP_INFORMATION]: {
            ...state.business[BusinessOnboardingPageViews.REP_INFORMATION],
            completed: true
          }
        }
      };
    },
    setBusInfoCompleted: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.BUSINESS_INFORMATION]: {
            ...state.business[BusinessOnboardingPageViews.BUSINESS_INFORMATION],
            completed: true
          }
        }
      };
    },
    setDirectorsCompleted: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.DIRECTORS]: {
            ...state.business[BusinessOnboardingPageViews.DIRECTORS],
            completed: true
          }
        }
      };
    },
    setDirectorsIncompleted: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.DIRECTORS]: {
            ...state.business[BusinessOnboardingPageViews.DIRECTORS],
            completed: false
          }
        }
      };
    },
    setSummaryCompleted: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.SUMMARY]: {
            ...state.business[BusinessOnboardingPageViews.SUMMARY],
            completed: true
          }
        }
      };
    },
    setSummaryIncompleted: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.SUMMARY]: {
            ...state.business[BusinessOnboardingPageViews.SUMMARY],
            completed: false
          }
        }
      };
    },

    setBusUploadCompleted: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.UPLOAD_DOCS]: {
            ...state.business[BusinessOnboardingPageViews.UPLOAD_DOCS],
            completed: true
          }
        }
      };
    },
    setBusInfoAllowed: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.BUSINESS_INFORMATION]: {
            ...state.business[BusinessOnboardingPageViews.BUSINESS_INFORMATION],
            allowed: true
          }
        }
      };
    },
    setDirectorsAllowed: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.DIRECTORS]: {
            ...state.business[BusinessOnboardingPageViews.DIRECTORS],
            allowed: true
          }
        }
      };
    },
    setSummaryAllowed: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.SUMMARY]: {
            ...state.business[BusinessOnboardingPageViews.SUMMARY],
            allowed: true
          }
        }
      };
    },
    setSummaryDisallowed: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.SUMMARY]: {
            ...state.business[BusinessOnboardingPageViews.SUMMARY],
            allowed: false
          }
        }
      };
    },
    setBusUploadAllowed: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.UPLOAD_DOCS]: {
            ...state.business[BusinessOnboardingPageViews.UPLOAD_DOCS],
            allowed: true
          }
        }
      };
    },
    setBusUploadDisallowed: (state) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.UPLOAD_DOCS]: {
            ...state.business[BusinessOnboardingPageViews.UPLOAD_DOCS],
            allowed: false
          }
        }
      };
    },
    clearOnbState: (state) => {
      return initialState;
    },
    toggleBusinessChangeModal: (state) => {
      return {
        ...state,
        businessChangeModalOpen: !state.businessChangeModalOpen
      };
    },
    setBusinessKycStatus: (state, { payload }: PayloadAction<Partial<BusinessPayload>>) => {
      return {
        ...state,
        business: {
          ...state.business,
          [BusinessOnboardingPageViews.UPLOAD_DOCS]: {
            ...state.business[BusinessOnboardingPageViews.UPLOAD_DOCS],
            kycStatus: payload.kycStatus
          }
        }
      };
    }
  }
});

export const {
  setCurrentTab,
  incrementCurrentTab,
  decrementCurrentTab,
  setPersonalDefaultState,
  setContactInfo,
  setPersonalInfo,
  updateVerificationFieldsPerCountry,
  setVerifyIdentityInfo,
  setUploadDocsInfo,
  nextBusinessPage,
  previousBusinessPage,
  setRepCompleted,
  setBusInfoCompleted,
  setDirectorsCompleted,
  setDirectorsIncompleted,
  setSummaryCompleted,
  setSummaryIncompleted,
  setBusUploadCompleted,
  setBusInfoAllowed,
  setBusUploadAllowed,
  setBusUploadDisallowed,
  setDirectorsAllowed,
  setSummaryAllowed,
  setSummaryDisallowed,
  clearOnbState,
  toggleBusinessChangeModal,
  setBusinessKycStatus,
  setPersonalSummaryAsCompleted
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
