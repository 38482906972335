import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InternalTransfStateProp {
  internalTransferDetails: InternalTransferProps;
  internalCurrentpage: number;
  transitionDirection: string;
  isNewFromBenefs: boolean;
  isSendFromBenefs: boolean;
  newRecipientstate: boolean;
}

interface transactionsState {
  isSingleTransOpen: boolean;
  selectedTransaction: any;
  isSingleBenefOpen: boolean;
  isDeleteBenefOpen: boolean;
  selectedBeneficiary: any;
  isStatementModalOpen: boolean;
  deletedBeneficiaryCount: number;
  internalTransfer: InternalTransfStateProp;
}

const initialState: transactionsState = {
  isSingleTransOpen: false,
  selectedTransaction: null,
  isSingleBenefOpen: false,
  selectedBeneficiary: null,
  isDeleteBenefOpen: false,
  isStatementModalOpen: false,
  deletedBeneficiaryCount: 0,
  internalTransfer: {
    internalTransferDetails: {} as InternalTransferProps,
    internalCurrentpage: 1,
    transitionDirection: 'right',
    isNewFromBenefs: false,
    isSendFromBenefs: false,
    newRecipientstate: false,
  },
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,

  reducers: {
    viewSingleTransaction: (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        selectedTransaction: payload,
        isSingleTransOpen: true
      };
    },

    closeSingleTransaction: (state) => {
      return {
        ...state,
        isSingleTransOpen: false,
        selectedTransaction: null
      };
    },

    viewSingleBeneficiary: (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        selectedBeneficiary: payload,
        isSingleBenefOpen: true
      };
    },

    closeSingleBeneficiary: (state) => {
      return {
        ...state,
        isSingleBenefOpen: false,
        selectedBeneficiary: null
      };
    },

    toggleDeleteBenef: (state) => {
      return {
        ...state,
        isDeleteBenefOpen: !state.isDeleteBenefOpen
      };
    },

    toggleStatementModal: (state) => {
      return {
        ...state,
        isStatementModalOpen: !state.isStatementModalOpen
      };
    },
    setDeleteBeneficiaryCallCount: (state) => {
      return {
        ...state,
        deletedBeneficiaryCount: state.deletedBeneficiaryCount + 1
      };
    },
    openNewRecipientState: (state) => {
      return {
        ...state,
        internalTransfer: {
          ...state.internalTransfer,
          newRecipientstate: true
        }
      }
    },
    closeNewRecipientState: (state) => {
      return {
        ...state,
        internalTransfer: {
          ...state.internalTransfer,
          newRecipientstate: false
        }
      }
    },
    setInternalTransferDetails: (state, { payload }: PayloadAction<InternalTransferProps>) => {
      return {
        ...state,
        internalTransfer: {
          ...state.internalTransfer,
          internalTransferDetails: {
            ...state.internalTransfer.internalTransferDetails,
            ...payload
          }
        }
      }
    },
    clearInternalTransferDetails: (state) => {
      return {
        ...state,
        internalTransfer: {
          ...state.internalTransfer,
          internalTransferDetails: {} as InternalTransferProps
        }

      }
    },
    setInternalCurrentPage: (state, { payload }: PayloadAction<number>) => {
      return {
        ...state,
        internalTransfer: {
          ...state.internalTransfer,
          internalCurrentpage: payload
        }
      }
    },
    setTransitionDirection: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        internalTransfer: {
          ...state.internalTransfer,
          transitionDirection: payload
        }
      }
    },
    setIsNewFromBenefs: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        internalTransfer: {
          ...state.internalTransfer,
          isNewFromBenefs: payload
        }
      }
    },
    setIsSendFromBenefs: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        internalTransfer: {
          ...state.internalTransfer,
          isSendFromBenefs: payload
        }
      }
    },
  }
});

export const {
  viewSingleTransaction,
  closeSingleTransaction,
  viewSingleBeneficiary,
  closeSingleBeneficiary,
  toggleDeleteBenef,
  toggleStatementModal,
  setDeleteBeneficiaryCallCount,
  openNewRecipientState,
  closeNewRecipientState,
  setInternalTransferDetails,
  clearInternalTransferDetails,
  setInternalCurrentPage,
  setTransitionDirection,
  setIsNewFromBenefs,
  setIsSendFromBenefs,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
