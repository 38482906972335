import { Form, message } from "antd";
import {
	CustomInputField,
	QwidButton,
	useToolkit,
	CustomSelector,
} from "components";
import { Moment } from "moment";
import { updateBusinessDetails } from "redux/slices/settingsSlice";

type BusinessInput = {
	label?: string | undefined;
	name?: string;
	placeholder?: string;
	required?: boolean;
	Component?: JSX.Element;
};

type Props = {
	PreviousButton: () => JSX.Element;
	countriesLoading: boolean;
	countriesData: {}[];
	current?: number;
	setCurrent: (value: React.SetStateAction<number>) => void;
};

const StakeholderInfo = ({
	PreviousButton,
	countriesData,
	countriesLoading,
	current,
	setCurrent,
}: Props) => {
	const { useState, moment, useAppSelector, dispatch } = useToolkit();
	const { isFirstTimeUser } = useAppSelector((state) => state.settings);
	const [appointmentDate, setAppointmentDate] = useState("");
	const [terminationDate, setTerminationDate] = useState("");
	const [shDob, setShDob] = useState("");
	const disabledDate = (current: any) =>
		current > moment().subtract(1, "month");

	const dateFormat = "YYYY-MM-DD";

	const inputItems: BusinessInput[] = [
		{ label: "Last Name", name: "stakeholder1LastName" },
		{ label: "First Name", name: "stakeholder1FirstName" },
		{ label: "Email", name: "stakeholder1Email" },
		{ label: "Position", name: "stakeholder1Designation" },
		{
			name: "designation",
			Component: (
				<CustomInputField
					label="Date of Appointment (Position)"
					isDatePicker
					disabledDate={disabledDate}
					onDateChange={(value: Moment | null) =>
						setAppointmentDate(moment(value).format("YYYY-MM-DD"))
					}
				/>
			),
		},
		{
			name: "termination",
			Component: (
				<CustomInputField
					label="Date of Termination (optional)"
					isDatePicker
					disabledDate={(current: Moment) => current.isBefore(moment(), "day")}
					onDateChange={(value: Moment | null) =>
						setTerminationDate(moment(value).format(dateFormat))
					}
				/>
			),
		},
		{
			label: "Shares Percentage",
			placeholder: "Enter value",
			name: "stakeholder1ShareVolume",
		},
		{
			name: "stakeholder1DOB",
			Component: (
				<CustomInputField
					label="Date of Birth"
					isDatePicker
					disabledDate={(current: any) =>
						current > moment().subtract(10, "year")
					}
					onDateChange={(value: Moment | null) =>
						setShDob(moment(value).format(dateFormat))
					}
				/>
			),
		},
		{ label: "Official Address", name: "stakeholder1Address" },
		{ label: "City", name: "stakeholder1City" },
		{ label: "State", name: "stakeholder1State" },
		{
			label: "Zip / Postal code",
			placeholder: "Enter code",
			name: "stakeholder1Zipcode",
		},
		{
			name: "stakeholder1Nationality",
			Component: (
				<CustomSelector
					customName="stakeholder1Nationality"
					customLabel="Nationality"
					isAnt
					required={isFirstTimeUser}
					options={countriesData}
					placeholder={
						countriesLoading ? "Fetching countries..." : "Select Country"
					}
				/>
			),
		},
		{ label: "Contact Number", name: "stakeholder1MobileCode" },
		{ label: "Tax Number", name: "stakeholder1TaxNumber" },

		{
			Component: (
				<CustomSelector
					customName="stakeholder1TaxCountry"
					customLabel="Tax Issuing Country"
					isAnt
					required={isFirstTimeUser}
					options={countriesData}
					placeholder={
						countriesLoading ? "Fetching countries..." : "Select Country"
					}
				/>
			),
		},
		{
			Component: (
				<CustomSelector
					isAnt
					required={isFirstTimeUser}
					customName="stakeholder1IsPep"
					customLabel="Is the application staff a PEP?"
					options={[
						{ title: "Yes", value: "Yes" },
						{ title: "No", value: "no" },
					]}
					placeholder="Select"
				/>
			),
		},
	];

	const onSubmit = (values: any) => {
		if (isFirstTimeUser && !appointmentDate.length) {
			message.warning("Please provide appointment date");
		} else if (isFirstTimeUser && !shDob?.length) {
			message.warning("Please enter a date of birth");
		} else {
			const payload = {
				...values,
				stakeholder1PostStartDate: appointmentDate,
				stakeholder1PostEndDate: terminationDate,
				stakeholder1DOB: shDob,
			};
			dispatch(updateBusinessDetails(payload));
			setCurrent((prev) => prev + 1);
		}
	};

	return (
		<Form onFinish={onSubmit}>
			<div className="w-full flex items-end justify-center md:justify-between flex-wrap">
				{inputItems?.map(
					({ label, name, placeholder, Component }: BusinessInput) =>
						Component ? (
							<div key={name || Math.random()} className="form-child3 mb-8">
								{Component}
							</div>
						) : (
							<div key={name || Math.random()} className="form-child3 mb-8">
								<CustomInputField
									required={isFirstTimeUser}
									label={label || ""}
									name={name}
									placeholder={placeholder || `Enter ${label}`}
								/>
							</div>
						)
				)}
			</div>
			<div className="mt-10 flex justify-end space-x-5">
				<PreviousButton />

				<QwidButton
					type="submit"
					text={<span className="px-5">Next</span>}
					darkBlueBg
				/>
			</div>
		</Form>
	);
};

export default StakeholderInfo;
