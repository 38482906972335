import { Form, Popover, Steps, StepsProps } from "antd";
import {
	CenterModal,
	CustomSelector,
	QwidButton,
	CustomInputField,
	useSendFunds,
	useToolkit,
	beneficiaryCategoryOptions as category,
	beneficiaryTypeOptions
} from "components";
import { useMemo } from "react";
import { setBeneficiaryAccountName, setWalletFlag } from "redux/slices/dashboardSlice";
import BeneficaryBankForm from "./BeneficiaryBankForm";
import { countryCodes } from "../../../utils/appData/countryCodes";
import { AnyAction } from "@reduxjs/toolkit";
import { setDeleteBeneficiaryCallCount } from "redux/slices/transactionsSlice";


export enum BeneficiaryFormActionEnum {
	EDIT = 'edit',
	CREATE = 'create'
  }

type Props = {
	toggleBenefModal: () => void;
	selectedCountry?: string;
	selectedCurrency?: string;
	noRedirect?: boolean;
	beneficiaryPayload: {
		currency?: string;
		country?: string;
		beneficiaryType?: number;
		category: string;
	};
	setBeneficiaryPayload: (v: any) => void;
	action?: BeneficiaryFormActionEnum;
	selectedBeneficiaryId?: string
};

const AddBeneficiaryModal = ({
	toggleBenefModal,
	beneficiaryPayload,
	setBeneficiaryPayload,
	action,
	selectedBeneficiaryId,
	noRedirect,
}: Props) => {
	// To be removed during refactoring
	const {
		useAppSelector,
		dispatch,
		toastInfo,
		useRef,
		useEffect,
		useState,
	} = useToolkit();
	const { Step } = Steps;
	const [current, setCurrent] = useState(0);
	const [wallet, setWallet] = useState("");
	const [country, setCountry] = useState(beneficiaryPayload.country);
	const [currency, setCurrency] = useState(beneficiaryPayload.currency);
	const [bankFormFields, setBankFormFields] = useState([]);
	const [getBankFailed, setGetBanksFailed] = useState(false);
	const [bankList, setBankList] = useState([]);
	const [bankInputData, setBankInputData] = useState<Record<string,string>>({});
	const [beneficiaryType, setBeneficiaryType] = useState('');
	const { getAllRecipientCountries, countriesData, countriesLoading, curData, curLoading, getCountryCurrencies, getBeneficiaryBankFormFields, bankFormFieldsLoading, useGetCities, useGetStates, useAddBeneficiary, useUpdateBeneficiary } =
		useSendFunds();
	const { isAddingBeneficiary, existingStatesPerCountry, existingCitiesPerState, userBeneficiaries, isBenefModalOpen } = useAppSelector((state) => state.dashboard);
	const [previous, setPrevious] = useState(0);
	const [beneficaryDetails, setBeneficiaryDetails] = useState<Record<string,string>>({});
	const [selectedBankCode,setSelectedBankCode]=useState('');
	const [mobileCodeFound, setMobileCodeFound] = useState(false);
	const increment = () => setCurrent((prev) => prev + 1);
	const decrement = () => setCurrent((prev) => prev - 1);
	const goBack = () => {
		setPrevious(current);
		decrement();
	};
	const {
		mutate: getStatesOptions,
		isLoading: isGettingStateOptions
	} = useGetStates;
	const {
		mutate: getCitiesOptions,
		isLoading: isGettingCitiesOptions
	} = useGetCities;

	const {
		mutateAsync: addBeneficiaryApi,
		isLoading: isUploadingBeneficiary,
	} = useAddBeneficiary;

	const {
		mutate: updateBeneficiaryApi,
		isLoading: isUpdatingBeneficiary
	} = useUpdateBeneficiary;

	const [stepOneForm] = Form.useForm();

	useEffect(() => {
		if(current === 0) {
				// The assumption here is that the category would always exist on the payload so the length would alway be atleast one
				if (Object.keys(beneficiaryPayload)?.length > 1)
					stepOneForm.setFieldsValue({
						...beneficiaryPayload,
						beneficiaryType: beneficiaryPayload.beneficiaryType === 1 ? "Individual" : "Business"
					});
		} else if (current === 2) {
			const countryCode = beneficaryDetails?.beneficiaryCountry || beneficiaryPayload.country
			if (countryCode && !beneficaryDetails?.code) {
				const mobileCode = countryCodes?.find(v => v.code === (beneficaryDetails?.beneficiaryCountry || beneficiaryPayload.country))?.phone!;
				if (mobileCode) {
					setMobileCodeFound(true);
					setBeneficiaryDetails(details => (
						{
							...details,
							beneficiaryCountry: beneficiaryPayload?.country as string,
							mobileCode: `+${mobileCode}`
						}
					))
				} else {
					setMobileCodeFound(false);
				}
			}
			const states = existingStatesPerCountry[countryCode as string] || [];
			if (!states?.length) {
				// Get States
	getStatesOptions({country:countryCode});
			}
			if (countryCode && beneficaryDetails?.beneficiaryState) {
				const cityTag = `${beneficaryDetails?.beneficiaryCountry}-${beneficaryDetails?.beneficiaryState}`;
				const cities = existingCitiesPerState?.[cityTag] || [];
				if (!cities?.length && !isGettingCitiesOptions) getCitiesOptions({country: beneficaryDetails?.beneficiaryCountry, state: beneficaryDetails?.beneficiaryState});
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [current]);


	const onSelectBeneficiaryCountry = (e: any) => {
		const countryCode = e.target.value;
		const mobileCode = countryCodes.find(v => v.code === countryCode)?.phone!;
		if(!!mobileCode) setMobileCodeFound(true);
		else  setMobileCodeFound(false);
		setBeneficiaryDetails(details => (
			{...details, 
			...(mobileCode && {mobileCode: `+${mobileCode}`}),
			beneficiaryCountry: countryCode
		}));
		// Get States
		getStatesOptions({country:countryCode});
	}

	const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setWallet(e.target.value);
		dispatch(setWalletFlag(e.target.value));
	};
	
	useEffect(() => {
		if ((action === BeneficiaryFormActionEnum.EDIT) && selectedBeneficiaryId && userBeneficiaries?.length) {
			const beneficiary = userBeneficiaries.find(({id}: Record<string, number>) => String(id) === String(selectedBeneficiaryId));
			if  (beneficiary) {
				// Get state if state options for the selected country does not exist
				if (beneficiary.beneficiaryCountry && !existingStatesPerCountry[beneficiary.beneficiaryCountry]?.length) {
					getStatesOptions({country: beneficiary.beneficiaryCountry});
				};
				setBeneficiaryDetails(b => ({
					...b,
					...beneficiary,
					beneficiaryPhone: beneficiary.mobile,
					beneficiaryState: beneficiary.state,
					beneficiaryAddress: beneficiary.address,
					beneficiaryCity: beneficiary.city,
					beneficiaryZipCode: beneficiary.zipcode,
				}));
				setBankInputData(b => ({
					...b,
					...beneficiary
				}));
			}
		}
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [action, selectedBeneficiaryId, userBeneficiaries]);
	  
	
	const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
		setCountry(e.target.value);

	const onStepOneFinish = (values: any) => {
		const beneficiaryTypeId = values.beneficiaryType === "Individual" ? 1 : 2;
		setBeneficiaryPayload({
			...beneficiaryPayload,
			currency,
			country,
			beneficiaryType: beneficiaryTypeId,
		});
		if (beneficiaryTypeId !== beneficiaryPayload.beneficiaryType || currency !== beneficiaryPayload.currency || country !== beneficiaryPayload.country) {
			setBankList([]);
			setBankInputData({});
			getBeneficiaryBankFormFields({ cur: currency, country }).then((data) => {
				if (data) {
					setBankFormFields(data);
					increment();
				}
			});
		} else if (action === BeneficiaryFormActionEnum.EDIT && !bankFormFields?.length) {
				getBeneficiaryBankFormFields({ cur: currency, country }).then((data) => {
					if (data) {
						setBankFormFields(data);
						increment();
					}
				});
		}
		else {
			increment();
		}
		
	};

	const onStepTwoFinish = (values: any) => {

		increment();
		// setCurrent((prev) => prev + 1);
	};
	const onStepThreeFinish = (values: any) => {
		const payload = {
			country: beneficiaryPayload.country || country,
			beneficiaryType: beneficiaryPayload.beneficiaryType,
			cur: beneficiaryPayload.currency || currency,
			bankCode: bankInputData.bankCode,
			accountName: bankInputData.accountName,
			accountNo: bankInputData.accountNo,
			state: beneficaryDetails.beneficiaryState,
			address: beneficaryDetails.beneficiaryAddress,
			city: beneficaryDetails.beneficiaryCity,
			zipcode: beneficaryDetails.beneficiaryZipCode,
			bankSortCode: bankInputData.bankSortCode,
			bankRoutingNo: bankInputData.bankRoutingNo,
			iban: bankInputData.iban,
			swift: bankInputData.swift,
			bankAddress: bankInputData.bankAddress,
			accountType: bankInputData.accountType,
			mobile: beneficaryDetails.beneficiaryPhone,
			mobileCode: beneficaryDetails.mobileCode?.includes('+') ? beneficaryDetails.mobileCode : `+${beneficaryDetails.mobileCode}`,
			correspondentBankName: bankInputData.correspondentBankName,
			correspondentBankCode: bankInputData.correspondentBankCode,
			correspondentAccountNumber: bankInputData.correspondentAccountNumber,
			correspondentRoutine: bankInputData.correspondentRoutine,
			bankState: bankInputData.bankState,
			bankCity: bankInputData.bankCity,
			bankName: bankInputData.bankName,
			bankZipcode: bankInputData.bankZipcode,
			beneficiaryCountry: beneficaryDetails.beneficiaryCountry,
			category: beneficiaryPayload.category,
		}
		// 
		dispatch(setBeneficiaryAccountName({
			beneficiaryAccountName: bankInputData.accountName,
			beneficiaryActionType: action as string,
			}));
		if (action === BeneficiaryFormActionEnum.CREATE) {
			addBeneficiaryApi({ payload, noRedirect }).then((_value) => {
				if (noRedirect) {
					toggleBenefModal()
					dispatch(setDeleteBeneficiaryCallCount());
				}
			}).catch((_reason)=> {});
		}
		else updateBeneficiaryApi({
			data: payload,
			id: selectedBeneficiaryId
		})
		// setCurrent((prev) => prev + 1);
	};

	useEffect(()=>{
		if (country)
	       getCountryCurrencies(country);	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[country])
	
	const countriesOptions = useMemo(()=> {
		const countryToUse = beneficiaryPayload.country || country;
		if (countryToUse) {
			const selectCountryIndex = countriesData.findIndex(option => option.value === countryToUse);
			if (selectCountryIndex === -1) return countriesData;
			const copyCountriesData: Record<string, string | boolean>[] = [...countriesData];
			const selectedCountryOption = copyCountriesData.splice(
				selectCountryIndex,
				1
			);
			copyCountriesData.unshift({...selectedCountryOption[0], selected: true});
			return copyCountriesData;
		}
		return countriesData;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[countriesData, countriesData.length, beneficiaryPayload.country, country]);
	
	const beneficiaryCountriesOptions = useMemo(()=> {
		const countryToUse = beneficaryDetails.beneficiaryCountry || beneficiaryPayload.country;
		if (countryToUse) {
			const selectCountryIndex = countriesData.findIndex(option => option.value === countryToUse);
			if (selectCountryIndex === -1) return countriesData;
			const copyCountriesData: Record<string, string | boolean>[] = [...countriesData];
			const selectedCountryOption = copyCountriesData.splice(
				selectCountryIndex,
				1
			);
			copyCountriesData.unshift({...selectedCountryOption[0], selected: true});
			return copyCountriesData;
		}
		return countriesData;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[countriesData, countriesData.length, beneficiaryPayload.country, country, beneficaryDetails.beneficiaryCountry]);

	const currencyOptions = useMemo(()=> {
		const currencyToUse = beneficiaryPayload.currency || currency;
		if (currencyToUse) {
			const selectedCurrencyIndex = curData.findIndex(option => option.value === currencyToUse);
			if (selectedCurrencyIndex === -1) return curData;
			const copyCurrenciesData: Record<string, string | boolean>[] = [...curData];
			const selectedCurrencyOption = copyCurrenciesData.splice(
				selectedCurrencyIndex,
				1
			);
			copyCurrenciesData.unshift({...selectedCurrencyOption[0], selected: true});
			return copyCurrenciesData;
		}
		return curData;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[curData, beneficiaryPayload.currency, currency]);

	const beneficiaryTypeOpt = useMemo(()=> {
		let valueToUse: string | number = beneficiaryPayload.beneficiaryType as number;
		if (valueToUse) {
			valueToUse = beneficiaryPayload.beneficiaryType === 1 ? "Individual" : "Business";
		} else if (!valueToUse && beneficiaryType){
			valueToUse = beneficiaryType;
		}
		
		if (valueToUse) {
			const selectedBeneficiaryIndex = beneficiaryTypeOptions.findIndex(option => option.value === valueToUse);
			if (selectedBeneficiaryIndex === -1) return beneficiaryTypeOptions;
			const copyBeneficiaryTypeData: Record<string, string | boolean>[] = [...beneficiaryTypeOptions];
			const selectedCurrencyOption = copyBeneficiaryTypeData.splice(
				selectedBeneficiaryIndex,
				1
			);
			copyBeneficiaryTypeData.unshift({...selectedCurrencyOption[0], selected: true});
			return copyBeneficiaryTypeData;
		}
		return beneficiaryTypeOptions;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[beneficiaryPayload.beneficiaryType, beneficiaryType]);

	const beneficiaryCityOptions = useMemo(()=> {
		if (isGettingCitiesOptions) return [];
		const tag = `${beneficaryDetails?.beneficiaryCountry}-${beneficaryDetails?.beneficiaryState}`;
		const cities = existingCitiesPerState?.[tag] || [];
		if (cities?.length && beneficaryDetails?.beneficiaryCity) {
			return cities.map(v => {
				return v.value === beneficaryDetails?.beneficiaryCity ? {...v, selected: true} : v;
			})
		}
		return cities;
	},[beneficaryDetails?.beneficiaryCountry, beneficaryDetails?.beneficiaryState, existingCitiesPerState, isGettingCitiesOptions, beneficaryDetails?.beneficiaryCity]);
	
	const beneficiaryStateOptions = useMemo(() => {
		if (isGettingStateOptions) return [];
		const states = existingStatesPerCountry[beneficaryDetails?.beneficiaryCountry] || [];
		if (states?.length && beneficaryDetails?.beneficiaryState){
			return states.map(v => {
				return [v.value,v.title].includes(beneficaryDetails?.beneficiaryState) ? {...v, selected: true} : v;
			})
		}
		return states;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficaryDetails?.beneficiaryCountry, beneficaryDetails?.beneficiaryState, existingStatesPerCountry?.[beneficaryDetails?.beneficiaryCountry], isGettingStateOptions]);

	const steps = [
		{
			title: "",
			content: (
				<Form onFinish={onStepOneFinish} form={stepOneForm}>
					<div className="">
					<div className="flex flex-col xl:flex-row justify-between gap-4 xl:space-x-1 xl:mt-6">
							<div className="xl:w-[100%]">
								<CustomSelector
									required={false}
									itemName="category"
									itemLabel="Beneficiary category"
									options={category}
									ignoreFirstOption
									defaultValue={"bank"}
									errorMessageRequired="Select Beneficiary category"
								/>
							</div>
							<div className="xl:w-[100%]">
								<CustomSelector
									onChange={handleCountryChange}
									required={!!!beneficiaryPayload.country}
									itemName="country"
									itemLabel="Country"
									options={countriesOptions}
									// ignoreFirstOption={!!beneficiaryPayload.country}
									firstOption={
										countriesLoading ? "Fetching..." : "Select Country"
									}
								/>
							</div>
						</div>
						<div className="flex flex-col xl:flex-row justify-between gap-4 xl:space-x-1 xl:mt-6">
							<div className="xl:w-[100%]">
								<CustomSelector
									required={!beneficiaryPayload.beneficiaryType && !beneficiaryType}
									itemName="beneficiaryType"
									itemLabel="Beneficiary Type"
									options={beneficiaryTypeOpt}
									// ignoreFirstOption={!!beneficiaryPayload.beneficiaryType}
									onChange={(e) => setBeneficiaryType(e.target.value)}
									errorMessageRequired="Beneficiary Type required"
									firstOption={"Select Beneficiary Type"}
								/>
							</div>
							<div className="xl:w-[100%]">
								<CustomSelector
									disabled={!!!country}
									onChange={(e) => setCurrency(e.target.value)}
									// ignoreFirstOption={
									// 	!!beneficiaryPayload.country && beneficiaryPayload.country === country
									// }
									required={!!!currency}
									itemName="currency"
									itemLabel="Receiving Currency"
									options={!curLoading ? currencyOptions : []}
									errorMessageRequired="Currency is required"
									firstOption={curLoading ? "Fetching..." : "Select Currency"}
								/>
							</div>
						</div>
					</div>

					<div className="mt-10 flect justify-end">
						<div className="mr-4">
							<QwidButton
								type="button"
								plainBordered
								onClick={toggleBenefModal}
								text=" Cancel "
							/>
						</div>

						<div>
							<QwidButton
								type="submit"
								text="Continue"
								loading={bankFormFieldsLoading}
								disabled={bankFormFieldsLoading}
							/>
						</div>
					</div>
				</Form>
			),
		},
		{
			title: "",
			content: (
				(Object.keys(beneficiaryPayload)?.length > 1) ? <BeneficaryBankForm 
				onSubmit={onStepTwoFinish}
				 goBack={goBack}
				  formFields={bankFormFields}
				  bankList={bankList}
				  setBankList={setBankList}
				  country={country!}
				cur={currency!}
				category={beneficiaryPayload.category as string}
				beneficiaryType={beneficiaryType as string}
				inputDump={bankInputData}
				setInputDump={setBankInputData}
				getBankFailed={getBankFailed}
				setGetBanksFailed={setGetBanksFailed}
				prevPage={previous}
				selectedBankCode={selectedBankCode}
				setSelectedBankCode={setSelectedBankCode}
				currentPage={current}
				  /> : <></>
			),
		},
		{
			title: "",
			content: (
				<Form onFinish={onStepThreeFinish}>
					<p>Beneficiary Address Details</p>
					<div className="space-y-5">
					<div className="flex flex-col xl:space-x-1 xl:flex-row xl:gap-4 justify-between">
					<div className="xl:w-[100%]">
					<CustomSelector
									onChange={onSelectBeneficiaryCountry}
									required={!beneficaryDetails?.beneficiaryCountry}
									itemName="beneficiaryCountry"
									itemLabel="Country"
									options={beneficiaryCountriesOptions}
									firstOption={countriesLoading ? "Fetching..." :  "Select Country"}
								/>
						</div>
						<div className="xl:w-[100%]">
						<CustomSelector 
						itemName="beneficiaryState"
						itemLabel="Province/State"
						onChange={(e) => {
							setBeneficiaryDetails((v) => ({...v, beneficiaryState: e.target.value}))
							if (!isGettingCitiesOptions) getCitiesOptions({country: beneficaryDetails?.beneficiaryCountry, state: e.target.value})
						}}
						required={!beneficaryDetails?.beneficiaryState}
						firstOption={isGettingStateOptions ? "Fetching..." : "Select State"}
						options={beneficiaryStateOptions}
						/>
						</div>
						</div>
						<div className="flex flex-col xl:space-x-1 xl:gap-4 xl:flex-row justify-between">
						<div className="xl:w-[100%]">
						{beneficaryDetails?.beneficiaryCountry && beneficaryDetails?.beneficiaryState && !beneficiaryCityOptions?.length && !isGettingCitiesOptions ?
						<CustomInputField 
						name="beneficiaryCity"
						label="City"
						value={beneficaryDetails?.beneficiaryCity || ''}
						onValChange={(e:any) => {
							setBeneficiaryDetails((v) => ({...v, beneficiaryCity: e.target.value}));
						}}
						required={!beneficaryDetails?.beneficiaryCity}
						/>:
						<CustomSelector 
						itemName="beneficiaryCity"
						itemLabel="City"
						onChange={(e) => {
							setBeneficiaryDetails((v) => ({...v, beneficiaryCity: e.target.value}));
						}}
						required={!beneficaryDetails?.beneficiaryCity}
						firstOption={isGettingCitiesOptions ? "Fetching..." : "Select City"}
						options={beneficiaryCityOptions}
						/>
						}
						</div>
						<div className="xl:w-[100%]">
								<CustomInputField
									name="beneficiaryZipCode"
									label="Zipcode/Postal code"
									customAntRules={!beneficaryDetails?.beneficiaryZipCode ? [{required: true, message: "Required"},{type:'string', min: 2, message: 'should be more one character '}]:[{type:'string', min: 2, message: 'should be more than one character'}]}
									required={!beneficaryDetails?.beneficiaryZipCode}
									value={beneficaryDetails?.beneficiaryZipCode || ''}
									onValChange={(e: any) => setBeneficiaryDetails((v) => ({...v, beneficiaryZipCode: e.target.value}))}
								/>
							</div>
						</div>
						<div>
							<CustomInputField
								name="beneficiaryAddress"
								label="Address"
								placeholder=""
								required={!beneficaryDetails?.beneficiaryAddress}
								value={beneficaryDetails?.beneficiaryAddress || ''}
								onValChange={(e: any) => setBeneficiaryDetails((v) => ({...v, beneficiaryAddress: e.target.value}))}
							/>
						</div>
						<div className="flex flex-col xl:space-x-1 xl:flex-row justify-start">
							<div className="xl:w-[100%] flex flex-row">
							<div className="w-[20%]">
							<CustomInputField
									name="mobileCode"
									label="Code"
									nonEditable={mobileCodeFound}
									value={beneficaryDetails?.mobileCode || ''}
									required={!beneficaryDetails?.mobileCode}
									onValChange={(e: any) => setBeneficiaryDetails((v) => ({...v, mobileCode: e.target.value}))}
								/>
								</div>
								<div className="w-[80%]">
								<CustomInputField
									name="beneficiaryPhone"
									label="Mobile Number"
									required={!beneficaryDetails?.beneficiaryPhone}
									value={beneficaryDetails?.beneficiaryPhone || ''}
									customAntRules={!beneficaryDetails?.beneficiaryPhone ? [{required: true, message: "Required"},{type:'string', max: 14, min: 5, message: 'Only numbers are allowed'},{pattern: new RegExp(/^[0-9]+$/), message: 'should be numbers'}]
									:[{type:'string', max: 11, min: 9, message: 'Should be between 9 and 11 characters'},{pattern: new RegExp(/^[0-9]+$/), message: 'should be numbers'}]
								}
									onValChange={(e: any) => setBeneficiaryDetails((v) => ({...v, beneficiaryPhone: e.target.value}))}
								/>
								</div>
								
							</div>
						</div>
					</div>
					<div className="mt-10 flect justify-end">
						<div className="mr-5">
							<QwidButton
								type="button"
								plainBordered
								onClick={goBack}
								text="Back"
							/>
						</div>
						<QwidButton type="submit" disabled={isUploadingBeneficiary || isUpdatingBeneficiary} loading={isUploadingBeneficiary || isUpdatingBeneficiary} text="Save" />
					</div>
				</Form>
			),
		},
	];

	const CustomDot: StepsProps["progressDot"] = (dot) => (
		<Popover>{dot}</Popover>
	);
	// May not be needed and could be removed once confirmed
	useEffect(() => {
		if (!countriesData.length) getAllRecipientCountries();
	}, [countriesData, getAllRecipientCountries]);

	return (
		<CenterModal
			isCustom
			className="w-full md:w-3/4 p-10 lg:w-2/5 md:mx-auto"
			control={isAddingBeneficiary || isBenefModalOpen}
			onClose={toggleBenefModal}>
			<div className="w-full">
				<div className="">
					<p className="mb-2 med lg:text-lg">{action === BeneficiaryFormActionEnum.EDIT ? 'Edit': 'Add'} Beneficiary</p>
					<div className="my-7 hidden md:block">
						<Steps
							progressDot={CustomDot}
							size="small"
							labelPlacement="vertical"
							current={current}>
							{steps.map(({ title }) => (
								<Step key={title} />
							))}
						</Steps>
					</div>
					<div className="steps-content">{steps[current].content}</div>
				</div>
			</div>
		</CenterModal>
	);
};

export default AddBeneficiaryModal;
