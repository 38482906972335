import { useState } from "react";
import { setUserVerificationData } from "redux/slices/userSlice";
import useToolkit from "../misc/useToolkit";

const useSecurity = () => {
	const {
		handleRequestError,
		dispatch,
		useState,
		axiosInstance,
		toastSuccess,
		userId,
	} = useToolkit();
	const [status, setStatus] = useState("");
	const [loading, setLoading] = useState(false);
	const [gettingStatus, setGettingStatus] = useState(false);
	const [start2fa, setStart2fa] = useState(false);
	const [enableAuthRequest, setEnableAuthRequest] = useState(false);
	const [confirming, setConfirming] = useState(false);
	const initiate2fa = async () => {
		try {
			setLoading(true);
			const { data } = await axiosInstance.post(
				`googleAuth/initiate/${userId}`
			);
			dispatch(setUserVerificationData(data.content.data));
			setStart2fa(true);
			setLoading(false);
		} catch (error) {
			handleRequestError(error);
			setLoading(false);
			return error;
		}
	};
	const get2faStatus = async () => {
		try {
			setGettingStatus(true);
			const { data } = await axiosInstance.get(`googleAuth/status/${userId}`);
			setStatus(data.content.data.status);
			setGettingStatus(false);
		} catch (error) {
			handleRequestError(error);
			setStatus("");
			setGettingStatus(false);
			return error;
		}
	};
	const confirmAuthCode = async (payload: any) => {
		try {
			setConfirming(true);
			const { data } = await axiosInstance.patch(
				`login/googleAuth/${userId}`,
				payload
			);
			setEnableAuthRequest(true);
			setConfirming(false);
		} catch (error) {
			handleRequestError(error);
			setConfirming(false);
			return error;
		}
	};

	const UpdateAuthCode = async (payload: any, cb: any) => {
		try {
			setConfirming(true);
			await axiosInstance.patch(`googleAuth/${userId}`, payload);
			toastSuccess("2FA enabled successfully");
			setConfirming(false);
			cb(false);
		} catch (error) {
			handleRequestError(error);
			setConfirming(false);
			return error;
		}
	};

	const Deactivate2fa = async () => {
		try {
			setLoading(true);
			await axiosInstance.get(`googleAuth/status/deactivate/${userId}`);
			dispatch(setUserVerificationData(null));
			setLoading(false);
		} catch (error) {
			handleRequestError(error);
			setLoading(false);
			return error;
		}
	};
	return {
		initiate2fa,
		status,
		loading,
		get2faStatus,
		gettingStatus,
		start2fa,
		enableAuthRequest,
		confirmAuthCode,
		confirming,
		UpdateAuthCode,
		Deactivate2fa,
		toastSuccess,
	};
};

export default useSecurity;
