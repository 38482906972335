import { Empty } from "antd";
import { RiFileCopyFill } from "react-icons/ri";
import { setWalletFlag, toggleTopupModal } from "redux/slices/dashboardSlice";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";
import {
	useToolkit,
	CenterModal,
	useDashboard,
	QwidButton,
	Spinner,
	TopupForm,
	ConfirmTopup,
} from "../../components";

type Props = {
	detailsOnly?: boolean;
	loading?: boolean;
	acctEmpty?: boolean;
	isVirtual?: boolean;
};

const TopupModal = ({
	detailsOnly,
	loading,
	acctEmpty: empty,
	isVirtual: isVirt,
}: Props) => {
	const {
		useAppSelector,
		dispatch,
		toastInfo,
		useRef,
		useEffect,
		useState,
		clearFlag,
		CopyIcon,
		router,
	} = useToolkit();

	const {
		isTopupModalOpen,
		userWallets,
		trfDetails: det,
	} = useAppSelector((state) => state.dashboard);

	const [wallet, setWallet] = useState("");
	const [confirmShown, setConfirmShown] = useState(false);

	const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setWallet(e.target.value);
		dispatch(setWalletFlag(e.target.value));
	};

	const {
		getTrfDetails,
		detailsLoading,
		getTransId,
		idLoading,
		idData,
		acctEmpty,
		trfDetails,
		isVirtual,
	} = useDashboard();

	const submitRef = useRef<HTMLButtonElement>(null);

	const IDContainer = () => (
		<div className="trans-details-wrap">
			<p className="trans-details-key">Transaction ID </p>
			{idLoading ? (
				"..."
			) : (
				<p className="trans-details-value">
					{idData}
					<span className="ml-1">
						<RiFileCopyFill
							onClick={() => {
								navigator.clipboard.writeText(idData);
								toastInfo("Copied");
							}}
							className="icon-clr text-lg curp"
						/>
					</span>
				</p>
			)}
		</div>
	);

	useEffect(() => {
		getTransId();
		clearFlag();
	}, []);

	useEffect(() => {
		getTrfDetails(wallet);
	}, [wallet]);

	const formattedNumber =
		det?.accountNo?.length > 10
			? `${det?.accountNo?.substring(0, 10)}...`
			: det?.accountNo;

	const routingExists =
		trfDetails?.routingNo?.length > 0 && det?.routingNo?.length > 0;
	const ibanExists = trfDetails?.IBAN?.length > 0 && det?.IBAN?.length > 0;
	const swiftExists = trfDetails?.swift?.length > 0 && det?.swift?.length > 0;
	const isPounds = trfDetails?.cur === "GBP" || trfDetails?.cur === "EUR";

	return detailsOnly ? (
		<div className="mt-8 bg-[#FFF9ED] rounded-sm p-4 space-y-3 lg:space-y-4">
			{loading ? (
				<Spinner isSmall msg="Fetching details" />
			) : empty ? (
				<Empty
					description={
						<span>No Bank Available - Select another wallet </span>
					}></Empty>
			) : (
				<>
					{router.pathname === DASHBOARD_ROUTES.SEND_SAVED_BENEFICIARY && (
						<IDContainer />
					)}
					<div className="trans-details-wrap">
						<p className="trans-details-key">Bank Name</p>
						<p className="trans-details-value">
							{det?.bankName} - {det?.cur || ""}
						</p>
					</div>
					<div className="trans-details-wrap">
						<p className="trans-details-key">Holder's Name</p>
						<p className="trans-details-value">{det?.accountName}</p>
					</div>

					<div className="trans-details-wrap">
						<p className="trans-details-key">Account Number</p>
						{det?.accountNo?.length && (
							<p className="trans-details-value">
								{formattedNumber}
								<CopyIcon item={det?.accountNo} title="Account Number" />
							</p>
						)}
					</div>

					{routingExists && (
						<div className="trans-details-wrap">
							<p className="trans-details-key">
								{isPounds ? "Sort Code" : "Routing Number"}
							</p>
							<p className="trans-details-value">
								{det?.routingNo}
								<CopyIcon
									item={det?.routingNo}
									title={isPounds ? "Sort Code" : "Routing Number"}
								/>
							</p>
						</div>
					)}

					{ibanExists && (
						<div className="trans-details-wrap">
							<p className="trans-details-key">IBAN</p>
							<p className="trans-details-value">
								{det?.IBAN?.substring(0, 10)}*
								<CopyIcon item={det?.IBAN} title="IBAN" />
							</p>
						</div>
					)}

					{swiftExists && (
						<div className="trans-details-wrap">
							<p className="trans-details-key">Swift</p>
							<p className="trans-details-value">
								{det?.swift}
								<CopyIcon item={det?.swift} title="Swift" />
							</p>
						</div>
					)}

					<p className="mb-0 mt-4 text-xs twide dpgry-txt">
						<strong>
							NOTE: Ensure you use the transaction ID as reference / description
							/ remark while making the transfer.
						</strong>
					</p>
				</>
			)}
		</div>
	) : (
		<CenterModal
			darkBg
			title={confirmShown ? "Confirm Transaction" : "Top up Wallet"}
			control={isTopupModalOpen}
			onClose={() => dispatch(toggleTopupModal())}>
			{confirmShown ? (
				<ConfirmTopup
					idData={idData}
					trfDetails={trfDetails}
					setConfirmShown={setConfirmShown}
				/>
			) : (
				<div className="w-full mt-4">
					<TopupForm
						isVirtual={isVirtual}
						submitRef={submitRef}
						setConfirmShown={setConfirmShown}
						onChange={onChange}
						userWallets={userWallets}
					/>
					<div className="bg-[#FFF9ED] rounded-sm p-4 space-y-3 lg:space-y-4 mt-7">
						{detailsLoading ? (
							<Spinner isSmall msg="Fetching details" />
						) : acctEmpty ? (
							<Empty
								description={
									<span>No Bank Available - Select another wallet </span>
								}></Empty>
						) : (
							<>
								{!isVirtual && <IDContainer />}
								<div className="trans-details-wrap">
									<p className="trans-details-key">Bank Name</p>
									<p className="trans-details-value">{trfDetails?.bankName}</p>
								</div>
								<div className="trans-details-wrap">
									<p className="trans-details-key">Holder's Name</p>
									<p className="trans-details-value">
										{trfDetails?.accountName}
									</p>
								</div>

								<div className="trans-details-wrap">
									<p className="trans-details-key">Account Number</p>
									{trfDetails?.accountNo?.length && (
										<p className="trans-details-value">
											{trfDetails?.accountNo?.substring(0, 10)}*
											<CopyIcon item={det?.accountNo} title="Account Number" />
										</p>
									)}
								</div>

								{routingExists && (
									<div className="trans-details-wrap">
										<p className="trans-details-key">
											{isPounds ? "Sort Code" : "Routing Number"}
										</p>
										<p className="trans-details-value">
											{det?.routingNo}
											<CopyIcon
												item={det?.routingNo}
												title={isPounds ? "Sort Code" : "Routing Number"}
											/>
										</p>
									</div>
								)}

								{ibanExists && (
									<div className="trans-details-wrap">
										<p className="trans-details-key">IBAN</p>
										<p className="trans-details-value">
											{det?.IBAN?.substring(0, 10)}*
											<CopyIcon item={det?.IBAN} title="IBAN" />
										</p>
									</div>
								)}

								{swiftExists && (
									<div className="trans-details-wrap">
										<p className="trans-details-key">Swift</p>
										<p className="trans-details-value">
											{det?.swift}
											<CopyIcon item={det?.swift} title="Swift" />
										</p>
									</div>
								)}

								<p className="mb-0 mt-4 text-xs twide dpgry-txt">
									<strong>
										{isVirtual
											? "Please make a transfer to the above account details to get credited instantly"
											: "NOTE: Ensure you use the transaction ID as reference / description / remark while making the transfer."}
									</strong>
								</p>
							</>
						)}
					</div>

					<div className="flex items-center justify-end space-x-3 mt-5 mb-2">
						<QwidButton
							onClick={() => dispatch(toggleTopupModal())}
							transparent
							text="Close"
						/>

						<QwidButton
							disabled={acctEmpty || isVirtual}
							onClick={() => submitRef.current?.click()}
							text={<span className="px-4">Top Up</span>}
						/>
					</div>
				</div>
			)}
		</CenterModal>
	);
};

export default TopupModal;
