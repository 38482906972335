import { Skeleton } from "antd";
import React from "react";

type Props = {
	single?: boolean;
};

const SkeletonLoader = ({ single }: Props) => {
	return single ? (
		<Skeleton active />
	) : (
		<div className="pt-10 pb-7">
			<div className="min-w-full flex flex-wrap lg:flex-nowrap md:space-x-4 lg:space-x-5 space-y-5 lg:space-y-0 lg:w-1/4">
				{[1, 2, 3].map((item) => (
					<div key={item} className="w-full md:w-3/4 lg:w-2/6">
						<Skeleton active />
					</div>
				))}
			</div>
		</div>
	);
};

export default SkeletonLoader;
