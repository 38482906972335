import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { KYCStatus } from "../../custom-types";

interface userState {
	user: null | any;
	endUser: null | any;
	businessInfo: null | any;
	userDetails: {} | any;
	userVerificationData: {} | any;
	isEmailVerified: boolean;
	plainUserInfo: null | PlainUserProps;
	isUserPinSet: boolean;
	kycStatus: KYCStatus | undefined;
	deviceId?: string;
	fcmToken?: string;
	isLoggedIn: boolean;
	eleganza?: string;
	mollusca?: string;
	isNotificationsModalOpen: boolean;
}

const initialState: userState = {
	user: null,
	endUser: null,
	businessInfo: null,
	userDetails: null,
	userVerificationData: null,
	isEmailVerified: false,
	plainUserInfo: null,
	isUserPinSet: false,
	kycStatus: undefined,
	deviceId: undefined,
	fcmToken: undefined,
	isLoggedIn: false,
	eleganza: undefined,
	mollusca: undefined,
	isNotificationsModalOpen: false,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setIsLoggedIn: (state, { payload }: PayloadAction<boolean>) => {
			state.isLoggedIn = payload;
			localStorage.setItem('isLoggedIn', payload ? 'yes' : 'no')
		},
		setUser: (state, { payload }: PayloadAction<any>) => {
			return {
				...state,
				user: payload,
				plainUserInfo: payload?.endUser ? payload?.endUser[0] : payload?.enduser[0],
			};
		},

		setUserDetails: (state, { payload }: PayloadAction<any>) => {
			return {
				...state,
				userDetails: payload,
			};
		},

		setPlainUser: (state, { payload }: PayloadAction<PlainUserProps>) => {
			return {
				...state,
				plainUserInfo: payload,
			};
		},

		setEmailVerified: (state) => {
			return {
				...state,
				isEmailVerified: true,
			};
		},

		setUserVerificationData: (state, { payload }: PayloadAction<any>) => {
			return {
				...state,
				userVerificationData: payload,
			};
		},

		pinSetupComplete: (state) => {
			return {
				...state,
				isUserPinSet: true,
			};
		},
		logoutUser: (state) => {
			return {
				...state,
				user: null,
				plainUserInfo: null,
				userDetails: null,
				isUserPinSet: false,
				eleganza: undefined,
				mollusca: undefined,
				isNotificationsModalOpen: false,
			};
		},
		setKycDocumentsStatus: (
			state,
			{ payload }: PayloadAction<{ kycStatus: KYCStatus }>
		) => {
			return {
				...state,
				kycStatus: payload.kycStatus,
			};
		},
		setFcmDetails: (
			state,
			{ payload }: PayloadAction<{ deviceId: string; fcmToken: string }>
		) => {
			return {
				...state,
				...payload,
			};
		},
		setEleganza: (state, { payload }: PayloadAction<string>) => {
			return {
				...state,
				eleganza: payload
			}
		},
		setMollusca: (state, { payload }: PayloadAction<string>) => {
			return {
				...state,
				mollusca: payload
			}
		},

		toggleNotifications: (state) => {
			return {
				...state,
				isNotificationsModalOpen: !state.isNotificationsModalOpen,
			}
		},

	},
});

export const {
	setIsLoggedIn,
	setUser,
	setUserDetails,
	setUserVerificationData,
	logoutUser,
	setEmailVerified,
	setPlainUser,
	pinSetupComplete,
	setKycDocumentsStatus,
	setFcmDetails,
	setEleganza,
	setMollusca,
	toggleNotifications,
} = userSlice.actions;

export default userSlice.reducer;
