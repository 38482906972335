import { CenterModal, useToolkit, QwidButton } from "components";
import { toggleDeleteBenef } from "redux/slices/transactionsSlice";

type Props = {};

const DeleteBeneficiaryModal = (props: Props) => {
	const { useAppSelector, dispatch } = useToolkit();
	const { isDeleteBenefOpen } = useAppSelector((state) => state.transactions);

	return (
		<CenterModal
			onClose={() => dispatch(toggleDeleteBenef())}
			control={isDeleteBenefOpen}>
			<div className="ps-6">
				<p className="my-4 med twide text-lg lg:text-xl tcent">
					Are you sure you want to delete this beneficiary?
				</p>
				<div className="flexed space-x-5 mt-9 lg:mt-12">
					<QwidButton plainBordered text={<p className="mb-0 px-4">Yes</p>} />
					<QwidButton
						onClick={() => dispatch(toggleDeleteBenef())}
						danger
						text={<p className="mb-0 px-4">Cancel</p>}
					/>
				</div>
			</div>
		</CenterModal>
	);
};

export default DeleteBeneficiaryModal;
