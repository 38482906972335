import { ImSpinner9 } from "react-icons/im";
import { IoMdAdd } from "react-icons/io";
import styled from "styled-components";

type Props = {
	whiteBg?: boolean;
	secondary?: boolean;
	text: string | JSX.Element;
	onClick?: (e?: any) => void;
	type?: "button" | "submit" | "reset" | undefined;
	disabled?: boolean;
	loading?: boolean;
	isFull?: boolean;
	greyBg?: boolean;
	transparent?: boolean;
	danger?: boolean;
	darkBg?: boolean;
	darkBlueBg?: boolean;
	blueTxt?: boolean;
	actionType?: boolean;
	isDarkBordered?: boolean;
	plainBordered?: boolean;
	businessCta?: boolean;
	className?: string;
	customBg?: string;
};

const QwidButton = ({
	whiteBg,
	secondary,
	text,
	type,
	disabled,
	loading,
	onClick,
	isFull,
	greyBg,
	darkBg,
	transparent,
	darkBlueBg,
	blueTxt,
	actionType,
	isDarkBordered,
	danger,
	plainBordered,
	businessCta,
	className, 
	customBg
}: Props) => {
	return actionType ? (
		<button
			disabled={disabled}
			type="button"
			onClick={onClick}
			className={`flexed  space-x-2 w-max focus:outline-none ${disabled && " cursor-not-allowed"}`}>
			<div className={`bg-[#E4F5FC] h-7 w-7 rounded-full flexed ${disabled && "bg-gray-100"}`}>
				<IoMdAdd className={`${disabled && "text-gray-400"} text-xl text-[#2B708E]  `} />
			</div>
			<p className={`mb-0 pry-text med ${disabled && "text-gray-400"} `}>{text}</p>
		</button>
	) : (
		<Button
			disabled={loading || disabled}
			type={type}
			onClick={onClick}
			style={{backgroundColor: customBg? customBg: undefined}}
			className={`px-2 py-2 md:px-2 flexed nowrap rounded-[5px]  med text-[12px] md:t4 tracking-wide active:scale-90 transition-all durationn-150 ${greyBg
					? "bg-[#E9E6E6] text-black"
					: darkBlueBg
						? "bg-[#194153] text-white py-3"
						: plainBordered
							? "pry-text border border-[#3A96BE] hover:pry-bg hover:text-white"
							: transparent
								? "bg-transparent text-[#2B708E]"
								: danger
									? "text-white bg-[#E11C00]"
									: darkBg
										? "bg-[#212938] text-white"
										: blueTxt
											? "bg-white pry-text hover:text-white hover:pry-bg border border-gray-400"
											: whiteBg
												? "bg-white text-black"
												: secondary
													? "bg-blue-500 text-white"
													: businessCta
														? "border border-black text-[#121212]"
														: isDarkBordered
															? "border border-[#20546B] bg-white text-black"
															: "bg-[#48BBED] text-white"
				} ${isFull && "w-full"} ${className}`}>
			{loading && (
				<ImSpinner9 className="text-white text-xl animate-spin mr-3" />
			)}
			{text}
		</Button>
	);
};

export default QwidButton;

const Button = styled.button`
	&:disabled {
		cursor: not-allowed;
		background: #f2f2f2;
		color: #aeaeae;
		border: 1px solid rgba(31, 31, 31, 0.12);
	}
`;
