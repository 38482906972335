import { DatePicker, Form, message } from "antd";
import {
	CustomInputField,
	CustomSelector,
	CenterModal,
	QwidButton,
	useToolkit,
	Spinner,
	useSendFunds,
} from "components";
import useUser from "utils/hooks/settings/useUser";
import { Moment } from "moment";
import { genders } from "utils/appData/dashboardMisc";
import {
	beginBusinessDetailsUpdate,
	toggleProfileModal,
} from "redux/slices/settingsSlice";
import { countryCodes } from "utils/appData/countryCodes";

type Props = {};

const UpdateProfileModal = ({}: Props) => {
	const {
		useAppSelector,
		useState,
		moment,
		toastError,
		dispatch,
		isBusinessAccount,
		userObj: userDetails,
		useEffect,
		HALF_WIDTH_CHILD,
		HALF_WIDTH_WRAPPER,
	} = useToolkit();

	const { sourceLoading, sourceData, getFundSource } = useSendFunds();

	const [error, setError] = useState(false);
	const [mobileNumber, setMobileNumber] = useState('');

	const {
		isFirstTimeUser,
		isProfileModalOpen,
		isProfileCancelBtnShown: cancelShown,
	} = useAppSelector((state) => state.settings);

	const {
		updateUserDetails,
		updLoading,
		updateFirstTimePersonalDetails,
		getUserDetails,
	} = useUser();

	useEffect(() => {
		getFundSource();
		getUserDetails();
	}, []);

	const [selectedCountry, setSelectedCountry] = useState<
		CountryProps | undefined
	>();
	const [dob, setDob] = useState("");

	const disabledDate = (current: any) =>
		current && current > moment().subtract(18, "year");

	const onDateChange = (value: Moment | null) => {
		const scheduleDate = moment(value).format("YYYY-MM-DD");
		setDob(scheduleDate);
	};

	const alertError = (message: string) => toastError(`Please ${message}`);

	const onSubmit = (values: any) => {
		const phone = mobileNumber;

		const formatMobile = () => {
			if (phone && phone[0] === "0") {
				const sliced = phone.slice(1);
				return sliced;
			} else {
				return phone;
			}
		};

		if (!dob?.length && !userDetails?.dob?.length) {
			message.error("Please enter a date of birth");
		} else {
			const payload = {
				...values,
				dob,
				mobile: formatMobile(),
				mobileCode: selectedCountry?.phone
					? "+" + selectedCountry?.phone?.toString()
					: null,
				countryCode: selectedCountry?.code,
			};

			if (isFirstTimeUser && isBusinessAccount) {
				dispatch(beginBusinessDetailsUpdate(payload));
			} else if (isFirstTimeUser && !isBusinessAccount) {
				updateFirstTimePersonalDetails(payload);
			} else {
				updateUserDetails(payload);
			}
		}
	};

	const rangeOptions = [
		{ title: "0 - 1,000", value: "0 - 1,000" },
		{ title: "10,000 - 100,000", value: "10,000 - 100,000" },
		{
			title: "100,000 - 500,000",
			value: "100,000 - 500,000",
		},
		{
			title: "500,000 and above",
			value: "500,000 and above",
		},
	];

	const handleModalClose = () => {
		if (cancelShown === false) {
			setError(true);
		} else {
			setError(false);
			dispatch(toggleProfileModal());
		}
	};

	const selectedGender = genders.find(
		(item) => item.title === userDetails?.sex
	);

	return (
		<CenterModal
			isAntd
			darkBg
			title={<span className="mb-1 twide med lg:text-lg">Update Profile</span>}
			control={isProfileModalOpen}
			onClose={handleModalClose}>
			{updLoading ? (
				<Spinner msg="Updating" />
			) : (
				<div className="w-full mt-0">
					{error && (
						<div className="w-full lg:w-3/4 bg-red-200 text-red-500 tcent mx-auto mb-3 py-2">
							<p className="mb-0 med-twide">Please fill in all details!</p>
						</div>
					)}
					<Form onFinish={onSubmit}>
						<div className="w-full flex gap-4 flex-col relative">
							<div className={HALF_WIDTH_WRAPPER}>
								<div className={HALF_WIDTH_CHILD}>
									<CustomInputField
										label="First Name"
										name="firstName"
										required={!userDetails?.firstName}
										placeholder={userDetails?.firstName}
									/>
								</div>

								<div className={HALF_WIDTH_CHILD}>
									<CustomInputField
										label="Last Name"
										name="lastName"
										required={!userDetails?.lastName}
										placeholder={userDetails?.lastName}
									/>
								</div>
							</div>

							<div className="flexed flex-col space-y-3">
								<div className={HALF_WIDTH_WRAPPER}>
									<div className={HALF_WIDTH_CHILD}>
										<CustomSelector
											required={!userDetails?.sex}
											itemName="sex"
											itemLabel="Gender"
											options={genders}
											firstOption={selectedGender?.title || "Gender"}
										/>
									</div>

									<div className={HALF_WIDTH_CHILD}>
										<Form.Item
											name="dob"
											rules={[
												{
													required: false,
													message: "Enter date",
												},
											]}>
											<div className="form-custom-input-wrap">
												<label
													style={{
														top: "-8.5px",
														zIndex: 10,
														fontSize: 10,
													}}
													className="form-item__label">
													Date of Birth
												</label>
												<DatePicker
													allowClear={false}
													placeholder={userDetails?.dob || "Select a date"}
													onChange={onDateChange}
													style={{ padding: "8.5px 10px", width: "100%" }}
													className="py-8 w-full"
													format="YYYY-MM-DD"
													disabledDate={disabledDate}
												/>
											</div>
										</Form.Item>
									</div>
								</div>

								<div className="w-full flex-btw items-end space-x-4">
									<div className="w-[65%]">
										<CustomSelector
											onChange={(e) => {
												const value = e.target.value;
												const countryObj = countryCodes.find(
													(item) => item.name === value
												);
												setSelectedCountry(countryObj);
											}}
											itemName="country"
											itemLabel="Country"
											options={[
												...countryCodes.map((item: CountryProps) => ({
													title: item.name,
													value: item.name,
												})),
											]}
											firstOption={userDetails?.country || "Select Country"}
										/>
									</div>

									<div className="w-[35%]">
										<CustomInputField
											nonEditable
											required={false}
											label="Country Code"
											placeholder={userDetails?.countryCode || ""}
											value={selectedCountry?.code ? selectedCountry?.code : ""}
										/>
									</div>
								</div>

								<div className="w-full flex-btw space-x-4">
									<div className="w-[35%]">
										<CustomInputField
											nonEditable
											placeholder={userDetails?.mobileCode || ""}
											required={false}
											label="Phone Code"
											value={
												selectedCountry?.phone
													? "+" + selectedCountry?.phone
													: ""
											}
										/>
									</div>

									<div className="w-[65%]">
										<CustomInputField
											isNumberValidated
											value={mobileNumber}
											valueSetter={setMobileNumber}
											required={!userDetails?.mobile}
											label="Phone Number"
											name="mobile"
											placeholder={userDetails?.mobile || ""}
										/>
									</div>
								</div>
							</div>

							<div className={HALF_WIDTH_WRAPPER}>
								<div className={HALF_WIDTH_CHILD}>
									<CustomInputField
										label="Address"
										name="address"
										required={!userDetails?.address}
										placeholder={userDetails?.address?.substring(0, 25) || ""}
									/>
								</div>

								<div className={HALF_WIDTH_CHILD}>
									<CustomInputField
										label="State"
										name="state"
										required={!userDetails?.state}
										placeholder={userDetails?.state || ""}
									/>
								</div>
							</div>

							<div className={HALF_WIDTH_WRAPPER}>
								<div className={HALF_WIDTH_CHILD}>
									<CustomInputField
										label="City"
										name="city"
										required={!userDetails?.city}
										placeholder={userDetails?.city || ""}
									/>
								</div>

								<div className={HALF_WIDTH_CHILD}>
									<CustomInputField
										required={!userDetails?.zipcode}
										placeholder={userDetails?.zipcode || ""}
										label="Zip / Postal code"
										name="zipcode"
									/>
								</div>
							</div>

							<div className={HALF_WIDTH_WRAPPER}>
								<div className={HALF_WIDTH_CHILD}>
									<CustomSelector
										required={!userDetails?.employmentStatus}
										itemName="employmentStatus"
										itemLabel="Employment Status"
										options={[
											{ title: "Employed", value: "employed" },
											{ title: "Unemployed", value: "unemployed" },
										]}
										firstOption={
											userDetails?.employmentStatus?.toUpperCase() || "Select"
										}
									/>
								</div>
								<div className={HALF_WIDTH_CHILD}>
									<CustomSelector
										required={!userDetails?.sourceOfFund}
										itemName="sourceOfFund"
										itemLabel="Source of Funds"
										options={[
											...sourceData?.map((item: any) => ({
												title: item?.title,
												value: item?.title?.toLowerCase(),
											})),
										]}
										firstOption={
											sourceLoading
												? "Fetching..."
												: userDetails?.sourceOfFund?.toUpperCase() || "Select"
										}
									/>
								</div>
							</div>

							<div className={HALF_WIDTH_WRAPPER}>
								<div className={HALF_WIDTH_CHILD}>
									<CustomSelector
										required={!userDetails?.accountDesignation}
										itemName="accountDesignation"
										itemLabel="Account Designation"
										options={[
											{
												title: "Personal",
												value: "personal",
												disabled: isBusinessAccount,
											},
											{ title: "Business", value: "business" },
										]}
										firstOption={
											userDetails?.accountDesignation?.toUpperCase() || "Select"
										}
									/>
								</div>
								<div className={HALF_WIDTH_CHILD}>
									<CustomSelector
										required={!userDetails?.incomeRange}
										itemName="incomeRange"
										itemLabel="Income Range"
										options={rangeOptions}
										firstOption={userDetails?.incomeRange || "Select"}
									/>
								</div>
							</div>

							<div className="w-full">
								<CustomInputField
									label="Occupation"
									name="occupation"
									required={!userDetails?.occupation}
									placeholder={userDetails?.occupation?.toUpperCase() || ""}
								/>
							</div>
						</div>

						<div className="flex items-center justify-end space-x-4 mt-8 mb-2">
							{cancelShown && (
								<QwidButton
									type="button"
									onClick={handleModalClose}
									transparent
									text={<span className="px-2">Cancel</span>}
								/>
							)}

							<QwidButton
								type="submit"
								disabled={false}
								text={<span className="px-4">Save</span>}
							/>
						</div>
					</Form>
				</div>
			)}
		</CenterModal>
	);
};

export default UpdateProfileModal;
