import { Select } from "antd";
import { useQuery } from "react-query";
import { setConvertedAmount } from "redux/slices/conversionsSlice";
import styled from "styled-components";
import { useToolkit, useDashboard } from "../../components";

type ItemProps = {
	isSending?: boolean;
	isReceiving?: boolean;
};

const useCurrencySelector = () => {
	const {
		useState,
		useAppSelector,
		useEffect,
		toastSuccess,
		axiosInstance,
		userId,
		dispatch,
	} = useToolkit();

	const { userWallets } = useAppSelector((state: any) => state.dashboard);

	const [rcvCur, setRcvCur] = useState<string>("NGN");
	const { fetchUserWallets, walletsData, walletsLoading } = useDashboard();
	const [fromCur, setFromCur] = useState<string>("USD");
	const [currsArray, setCurrsArray] = useState<string[]>([]);

	const usdWallet = walletsData?.find(
		(wallet: UserWallet) => wallet?.cur === "USD"
	);

	const ngnWallet = walletsData?.find(
		(wallet: UserWallet) => wallet?.cur === "NGN"
	);

	const [fromCurObj, setFromCurObj] = useState<ConvCurr | undefined>(usdWallet);
	const [rcvCurObj, setRcvCurObj] = useState<ConvCurr | undefined>(ngnWallet);

	useEffect(() => {
		fetchUserWallets();
	}, []);

	useEffect(() => {
		if (userWallets?.length > 0) {
			const supportedCurrs: string[] = userWallets.map(
				(item: UserWallet) => item.cur
			);
			setCurrsArray(supportedCurrs);
		}
	}, [userWallets]);

	const sendOptions: ConvCurr[] = walletsData?.map(
		({ cur, currency, flag }: UserWallet) => ({
			label: cur,
			title: currency,
			value: cur,
			cur,
			flag,
		})
	);

	const Items = ({ isSending }: ItemProps) => {
		return (
			<div className="w-full lg:w-[200px]">
				<CustomSelect
					showSearch
					optionFilterProp="children"
					filterOption={(input: any, option: any) =>
						(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
					}
					options={isSending ? sendOptions : currs}
					value={
						isSending
							? fromCur?.substring(0, 10) + " " || "USD"
							: rcvCur || "NGN"
					}
					onChange={(value: any) => {
						if (isSending) {
							const data = currs?.find((item: ConvCurr) => item.cur === value);
							setFromCur(value);
							setFromCurObj(data);
						} else {
							const data = currs?.find((item: ConvCurr) => item.cur === value);
							setRcvCurObj(data);
							setRcvCur(value);
						}
					}}
				/>
			</div>
		);
	};

	// handling conversion request
	const [convLoading, setConvLoading] = useState(false);
	const [convSuccess, setConvSuccess] = useState(false)
    const [convError, setConvError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [transID, setTransID] = useState('')

	const initConversion = async (payload: any) => {
		setConvLoading(true);
		try {
			const { data } = await axiosInstance.post(
				`transaction/convert/${userId}`,
				payload
			);

			const { fromCur, fromAmount, transId } = data?.content?.data[1];
			if (fromCur?.length && fromAmount?.length) {
				const amountConverted = `${fromCur} ${fromAmount}`;
				dispatch(
					setConvertedAmount({
						result: amountConverted,
						actualValue: Number(fromAmount),
					})
					
				);
				setTransID(transId)
				setConvSuccess(true)

			} else {
				toastSuccess("Conversion initiated");
				setConvSuccess(true)
			}
			setConvLoading(false);
		} catch (error: any) {
			setErrorMsg(error?.response?.data?.message)
			setConvLoading(false);
			setConvError(true)
			return error;
		}
	};

	// fetch all conversion currencies
	const getCurrencies = async () => {
		const { data } = await axiosInstance.post("countryCur");
		const endResult: IConvCurr[] = [];

		const objectsAreEqual = (first: IConvCurr, second: IConvCurr) =>
			first.CURR[0]?.I === second.CURR[0]?.I;
		data?.forEach((item: IConvCurr) => {
			const alreadyExists = endResult.find((existingItem: any) =>
				objectsAreEqual(item, existingItem)
			);
			if (!alreadyExists) {
				endResult.push(item);
			}
		});
		const currs: ConvCurr[] | undefined = endResult?.map((item: IConvCurr) => ({
			value: item.CURR[0].I,
			label: `${item.CURR[0].N} - ${item.CURR[0].I}`,
			title: `${item.CURR[0].N} - ${item.CURR[0].I}`,
			cur: item.CURR[0].I,
			flag:
				item.CURR[0].I === "USD"
					? "/assets/flags/us.svg"
					: item.CURR[0].I === "GBP"
					? "/assets/flags/gb-nir.svg"
					: item.CURR[0].I === "RUB"
					? "/assets/flags/ru.svg"
					: item.CURR[0].I === "EUR"
					? "/assets/flags/eu.svg"
					: `/assets/flags/${item.I?.toLowerCase()}.svg`,
		}));
		return currs;
	};

	const { isLoading: currsLoading, data: currs } = useQuery(
		"getConversionCurrs",
		getCurrencies,
		{
			refetchOnMount: false,
		}
	);

	const onSwap = () => {
		setFromCur(rcvCur);
		setFromCurObj(rcvCurObj || ngnWallet);
		setRcvCur(fromCur);
		setRcvCurObj(fromCurObj || usdWallet);
	};

	return {
		Items,
		fromCur,
		rcvCur,
		initConversion,
		convLoading,
		convError,
		convSuccess,
		transID,
		errorMsg,
		currsLoading,
		currsArray,
		fromCurObj,
		rcvCurObj,
		onSwap,
		walletsData,
		walletsLoading,
		currs,
		setFromCurObj,
		setRcvCurObj,
		setRcvCur,
		setFromCur,
	};
};

export default useCurrencySelector;

const CustomSelect = styled(Select)`
	font-size: 14px !important;
	min-width: 100% !important;
	padding-left: 36px !important;
	outline-width: 0 !important;
	border: 1px solid #194153 !important;
	border-radius: 2px;

	&.ant-select-focused:not(.ant-select-disabled).ant-select:not(
			.ant-select-customize-input
		)
		.ant-select-selector {
		border-color: none !important;
		outline: 0 !important;
		box-shadow: none !important;
	}

	&.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border: none;
	}

	.ant-select-selector {
		border: 0px !important;
	}
`;
