import React, { useEffect, useState } from "react";

const Countdown = ({ hours = 0, minutes = 0, seconds = 0, onExpiry }) => {
	const [over, setOver] = useState(false);
	const [[h, m, s], setTime] = useState([hours, minutes, seconds]);

	const tick = () => {
		if (over && onExpiry) {
			return onExpiry();
		}
		if (h === 0 && m === 0 && s === 0) setOver(true);
		else if (m === 0 && s === 0) {
			setTime([h - 1, 59, 59]);
		} else if (s == 0) {
			setTime([h, m - 1, 59]);
		} else {
			setTime([h, m, s - 1]);
		}
	};

	useEffect(() => {
		const timerID = setInterval(() => tick(), 1000);
		return () => clearInterval(timerID);
	});

	return over ? null : (
		<span className="smb twide lg:text-lg text-red-400">
			{`${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`}
		</span>
	);
};

export default Countdown;
