import styled from "styled-components";
import useToolkit from "../misc/useToolkit";

const useTransactions = () => {
  const { useState, axiosInstance, userId, handleRequestError } = useToolkit();

  // all user transactions
  const [transLoading, setTransLoading] = useState(false);
  const [transData, setTransData] = useState([]);
  const getUserTransactions = async () => {
    setTransLoading(true);
    try {
      const { data } = await axiosInstance.get(`transaction/user/${userId}`);
      const response = data?.content?.data?.transaction;
      setTransData(response);
      setTransLoading(false);
    } catch (error) {
      handleRequestError(error);
      setTransLoading(false);
      return error;
    }
  };

  const getTransType = (type: number) => {
    switch (type) {
      case 1:
        return "Deposit";
      case 2:
        return "Withdrawal";
      case 3:
        return "Remittance";
      case 4:
        return (
          <p className="mb-0">
            Qwid Transfer&nbsp;
            <span className="text-red-500">(DR)</span>
          </p>
        );
      case 5:
        return (
          <p className="mb-0">
            Qwid Transfer&nbsp;
            <span className="text-green-500">(CR)</span>
          </p>
        );
      case 6:
        return (
          <p className="mb-0">
            Conversion&nbsp;
            <span className="text-red-500">(DR)</span>
          </p>
        );
      case 7:
        return (
          <p className="mb-0">
            Conversion&nbsp;
            <span className="text-green-500">(CR)</span>
          </p>
        );
      case 8:
        return "Credit";
      case 9:
        return "Debit";
      case 12:
        return (
          <p className="mb-0">
            Referral Reward&nbsp;
            <span className="text-green-500">(CR)</span>
          </p>
        );
      default:
        return type;
    }
  };

  const getTransStatus = (status: number) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Confirmed";
      case 2:
        return "Declined";
      case 3:
        return "Checking";
      case 4:
        return "On hold";
      case 5:
        return "Processing";
      default:
        return status;
    }
  };

  const transTypeFilters = [
    { text: "Deposit", value: 1 },
    { text: "Withdrawal", value: 2 },
    { text: "Remittance", value: 3 },
    { text: "Qwid Trf (DR)", value: 4 },
    { text: "Qwid Trf (CR)", value: 5 },
    { text: "Conversion (DR)", value: 6 },
    { text: "Conversion (CR)", value: 7 },
    { text: "Credit", value: 8 },
    { text: "Debit", value: 9 },
  ];

  const transStatusFilters = [
    { text: "Pending", value: 0 },
    { text: "Successful", value: 1 },
    { text: "Declined", value: 2 },
    { text: "Checking", value: 3 },
    { text: "On hold", value: 4 },
    { text: "Processing", value: 5 },
  ];

  function getTransConfirmationStatus(status: number) {
    switch (status) {
      case 0:
        return <P className="bg-[#FFF9ED] text-[#2c2b81]">Pending payment</P>;
      case 1:
        return <P className="bg-[#F0F7F0] text-[#17C509]">Payment confirmed</P>;
      case 2:
        return (
          <P className="bg-[#FDF5F4] text-[#B41600]">Declined / refunded</P>
        );
      default:
        return 'N/A';
    }
  }

  function formatTransStatus(status: number) {
    switch (status) {
      case 0:
        return <P className="bg-[#FFF9ED] text-[#BC953B]">Pending</P>;
      case 1:
        return <P className="bg-[#F0F7F0] text-[#17C509]">Confirmed</P>;
      case 2:
        return <P className="bg-[#FDF5F4] text-[#B41600]">Declined</P>;
      case 3:
        return <P className="bg-[#E4F1F1] text-[#1194F2]">Checking</P>;
      case 4:
        return <P className="bg-[#FFF9ED] text-[#BC953B]">On hold</P>;
      case 5:
        return <P className="bg-[#FFF9ED] text-[#2c2b81]">Processing</P>;
      default:
        return status;
    }
  }

  const formatTrxAmount = (amount: any, decimalPlace?: number) => {
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: decimalPlace || 3,
      maximumFractionDigits: decimalPlace || 3,
    });
  };

  const getInitials = (text: string, bgColor?: string, textColor?: string) => {
    const formatted = text?.toUpperCase();

    const isSingleName = formatted?.split(" ")?.length === 1;
    const isDoubleName = formatted?.split(" ")?.length > 1;

    return (
      <div
        style={{
          backgroundColor: bgColor || "#EEECEC",
          color: textColor || "#252D3E",
        }}
        className="flexed h-10 w-10 rounded-full text-xs font-medium"
      >
        {!text
          ? "N/A"
          : isSingleName
            ? `${formatted[0]}`
            : isDoubleName
              ? `${formatted[0]}${formatted[1]}`
              : ""}
      </div>
    );
  };

  const formatBalanceWithoutRoundUp = (balance: any) => {
    const splitValue = balance?.toString()?.split(".", 2);

    if (!splitValue[1])
      return parseFloat(`${splitValue[0]}.00`)?.toLocaleString();

    return parseFloat(
      `${splitValue[0]}.${splitValue[1]?.substring(0, 2)}`
    )?.toLocaleString();
  };

  return {
    transLoading,
    transData,
    getUserTransactions,
    getTransType,
    transTypeFilters,
    transStatusFilters,
    formatTransStatus,
    getTransStatus,
    getTransConfirmationStatus,
    formatTrxAmount,
    getInitials,
    formatBalanceWithoutRoundUp,
  };
};

export default useTransactions;

const P = styled.p`
  margin-bottom: 0px;
  font-size: 12px;
  padding: 4px 6px;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  border-radius: 4px;
`;
