import { Form } from "antd";
import FlaggedDropdown from "components/common/InputElements/FlaggedDropdown";
import {
	QwidButton,
	useToolkit,
	useDashboard,
	useLocation,
	useSendFlow,
} from "components";
import Layout from "../internal-transfer/Layout";
import { cancelNewAccountWithdrawal, setWithdrawalCurrency, toggleWithdrawalRecipientStatus } from "redux/slices/sendflowSlice";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";

const WalletSendingFrom = ({
	pageDecrement,
	pageIncrement,
}: ComponentPageNavigator) => {
	const {
		useAppSelector,
		toastError,
		dispatch,
		useState,
		useEffect,
		formatWalletDropdownBalance,
		router
	} = useToolkit();
	const [from, setFrom] = useState("");
	const [form] = Form.useForm();
	const { fetchUserWallets } = useDashboard();
	const { getDynCountries } = useLocation();
	const { getSavedBankAccounts } = useSendFlow();

	const { userWallets } = useAppSelector((state: any) => state.dashboard);
	const { withdrawalInfo } = useAppSelector((state: any) => state.sendflow);

	const wallets: DropdownProps[] = userWallets?.map((item: any) => {
		return {
			title: item.currency,
			label: item.cur.slice(0, 2),
			value: item.cur,
			balance: formatWalletDropdownBalance(item.cur, item.balance),
		};
	});

	const initialValues = {
		from: from || withdrawalInfo?.withdrawalCurrency,
	};

	const onProceed = () => {
		if (!from?.length) {
			toastError("Please select a wallet to send from");
		} else {
			dispatch(setWithdrawalCurrency(from));
			pageIncrement && pageIncrement();
		}
	};

	useEffect(() => {
		if (withdrawalInfo?.withdrawalCurrency?.length) {
			setFrom(withdrawalInfo?.withdrawalCurrency);
		}
	}, []);

	useEffect(() => {
		fetchUserWallets();
		getDynCountries();
		getSavedBankAccounts();
	}, []);

	return (
		<Layout>
			<div className="w-full">
				<Form
					form={form}
					initialValues={initialValues}
					onFinish={onProceed}
					className="flex flex-col gap-7">
					<FlaggedDropdown
						onChange={(e: any) => setFrom(e)}
						name="from"
						options={wallets}
						noShowBalance
						getFlagBy="label"
						label="Select Wallet To Send From"
						placeholder="Select a wallet"
					/>

					<div className="flex items-center justify-between mt-[1.2rem]">
						<div
							onClick={() => {
								if(withdrawalInfo?.isCreatingNewRecipient) {
									dispatch(toggleWithdrawalRecipientStatus());
									dispatch(cancelNewAccountWithdrawal());
								} else {
									pageDecrement && pageDecrement();
								}
							}}
							className="text-[#194153] font-semibold cursor-pointer">
							Back
						</div>
						<div className="w-full max-w-[9rem]">
							<QwidButton text="Continue" isFull darkBlueBg type="submit" />
						</div>
					</div>
				</Form>
			</div>
		</Layout>
	);
};

export default WalletSendingFrom;
