import { Col, Row } from "antd";
import { QwidButton } from "components";
import CheckBox from "components/misc/CheckBox";

const Notifications = () => {
  return (
    <Row className=" flex gap-20 w-full">
        <div className="flex flex-col w-full gap-8">
          <div className="flex flex-col gap-8 w-full bg-white rounded-[1rem] border-[#EDEDED] border-[1px] px-5 sm:px-8 py-5 sm:py-8">
            <div>
              <p className="font-medium text-[#090909] text-base mt-0 mb-4">
                Login alerts
              </p>
              <p className="text-[#626262] text-xs font-normal mb-2">
              Get prompted for every time you log into your Qwid account.    </p>
            </div>
            <div className="flex gap-4">
              <div className="flex items-center gap-2"><CheckBox disabled /> Email</div>
              <div className="flex items-center gap-2"><CheckBox disabled /> Push Notifications</div>
            </div>
          </div>
          <div className="flex flex-col w-full gap-8 bg-white rounded-[1rem] border-[#EDEDED] border-[1px] px-5 sm:px-8 py-5 sm:py-8">
            <div>
              <p className="font-medium text-[#090909] text-base mt-0 mb-4">
                Email Notications
              </p>
              <p className="text-[#626262] text-xs font-normal mb-2">
              Turn on notifications for every transaction you make on your Qwid account.  </p>
              
            </div>
            <div className="flex gap-4">
              <div className="flex items-center gap-2"><CheckBox disabled usesChecked checked /> Email</div>
              <div className="flex items-center gap-2"><CheckBox disabled usesChecked checked /> Push notifications</div>
            </div>
          </div>

        </div>
    </Row>
  );
};

export default Notifications;
