import { Checkbox, Form, Skeleton } from "antd";
import {
	OptionalRender,
	QwidButton,
	useDashboard,
	useSendFlow,
	useToolkit,
} from "components";
import Layout from "../internal-transfer/Layout";
import DropdownInput from "components/common/InputElements/DropdownInput";
import {
	setDynamicFormValues,
	setWithdrawalPayoutInfo,
	setWithdrawalRecipient,
	toggleBeneficiarySaved,
} from "redux/slices/sendflowSlice";
import DynamicBankForm from "../common/local-components/DynamicBankForm";
import { MutableRefObject, useContext } from "react";
import { RemittanceContext } from "../remittance/context";

type ComponentPageNavigator = {
	pageIncrement?: () => void;
	pageDecrement?: () => void;
	navigateToPage?: (page: number) => void;
	narration?: MutableRefObject<string | null>;
};

const PayoutChannel = ({
	pageDecrement,
	pageIncrement,
	narration,
	navigateToPage
}: ComponentPageNavigator) => {
	const {
		dispatch,
		useAppSelector,
		toastError,
		useState,
		useEffect,
		useRef,
		trxPinExists,
		toastInfo,
		toastSuccess
	} = useToolkit();
	const [fieldData, setFieldData] = useState({});
	const { fetchUserWallets } = useDashboard();

	const {
		useWithdrawalChannel,
		useWithdrawalOptions,
		useBeneficiaryForms,
		payoutOptionShown,
		fieldsLoading,
		beneficiaryFields,
		addWithdrawalBankAccount,
		addBankLoading,
	} = useSendFlow();

	const {
		mutate: getChannels,
		isLoading: channelsLoading,
		data: channelsData,
	} = useWithdrawalChannel();

	const {
		mutate: getWithdrawalOptions,
		isLoading: withdrawalOptionsLoading,
		data: withdrawalOptionsData,
	} = useWithdrawalOptions();

	const { mutate: getBeneficiaryForms } = useBeneficiaryForms();

	const [form] = Form.useForm();
	const [payoutDetails, setPayoutDetails] = useState({
		channel: "",
		option: "",
	});

	const { withdrawalInfo } = useAppSelector((state: any) => state.sendflow);
	const { internalTransfer } = useAppSelector((state) => state.transactions);
	const beneficiaryFieldsValid =
		fieldsLoading === false && beneficiaryFields?.length > 0;

	const payoutChannels =
		channelsData?.length > 0
			? channelsData?.map((item: any) => ({
					title: item?.value,
					value: item?.id,
			  }))
			: [];

	const payoutOptions =
		withdrawalOptionsData?.length > 0
			? withdrawalOptionsData?.map((item: any) => ({
					title: item?.text,
					value: item?.id,
			  }))
			: [];

	const dynamicFormRef = useRef<
		| Record<
				string,
				(
					cb: (formValues: Record<string, string>, formFieldDump: any) => void
				) => void
		  >
		| undefined
	>();

	const onProceed = () => {
		if (
			!payoutDetails?.channel &&
			payoutOptionShown &&
			!payoutDetails?.option
		) {
			toastError("Please fill all fields");
		} else {
			if(withdrawalInfo?.isCreatingNewRecipient) {
				const dynamicFormOnSubmit = dynamicFormRef.current?.submit;

				if (dynamicFormOnSubmit) {
					dynamicFormOnSubmit((values) => {
						const recipient = {
							cur: withdrawalInfo?.withdrawalCurrency,
							bankName: values?.bankName,
							bankCode: values?.bankCode,
							accountName: values?.accountName,
							accountNo: values?.accountNo,
							country: withdrawalInfo?.withdrawalCountry,
							address: values?.address || '',
							zipcode: values?.zipcode || '',
							bankRoutingNo: values?.bankRoutingNo || '',
							accountType: values?.accountType || '',
							is_beneficiary: true,
						};
						addWithdrawalBankAccount(recipient).then((response: any) => {
							if(response?.success === true ) {
								if(withdrawalInfo?.isNewAccountWithdrawal) {
									const recipient = {
										cur: withdrawalInfo?.withdrawalCurrency,
										country: withdrawalInfo?.withdrawalCountry,
										amount: withdrawalInfo?.withdrawalAmount,
										accountName: values?.accountName,
										pin: trxPinExists ? internalTransfer?.internalTransferDetails?.pin : "",
										accountNo: values?.accountNo,
										narration: narration?.current,
										bankCode: values?.bankCode,
										bankName: values?.bankName,
									};
									dispatch(setWithdrawalRecipient(recipient));
									dispatch(setDynamicFormValues(values));
									pageIncrement && pageIncrement();
								} else {
									navigateToPage && navigateToPage(5)
								}
							}
						})
					});
				}
				
			} else {
				const dynamicFormOnSubmit = dynamicFormRef.current?.submit;
				if (dynamicFormOnSubmit) {
					dynamicFormOnSubmit((values, formFieldDump) => {
						const recipient = {
							cur: withdrawalInfo?.withdrawalCurrency,
							country: withdrawalInfo?.withdrawalCountry,
							amount: withdrawalInfo?.withdrawalAmount,
							accountName: values?.accountName,
							pin: trxPinExists ? internalTransfer?.internalTransferDetails?.pin : "",
							accountNo: values?.accountNo,
							narration: narration?.current,
							bankCode: values?.bankCode,
							bankName: values?.bankName,
						};
						setFieldData(formFieldDump);
						dispatch(setWithdrawalPayoutInfo(payoutDetails));
						dispatch(setWithdrawalRecipient(recipient));
						dispatch(setDynamicFormValues(values));
						
						pageIncrement && pageIncrement();
					});
				}
			}

		}
	};

	const initialValues = {
		channel: withdrawalInfo?.channel,
		option: withdrawalInfo?.option,
	};

	useEffect(() => {
		fetchUserWallets();
		getChannels({
			cur: withdrawalInfo?.withdrawalCurrency,
			country: withdrawalInfo?.withdrawalCountry,
		});
	}, []);

	useEffect(() => {
		if (payoutDetails?.channel?.length) {
			getWithdrawalOptions(payoutDetails?.channel);
		}
	}, [payoutDetails?.channel]);

	useEffect(() => {
		if (payoutDetails?.channel?.length && payoutDetails?.option?.length) {
			getBeneficiaryForms({
				category: payoutDetails?.channel?.toUpperCase(),
				option: payoutDetails?.option || "",
			});
		}
	}, [payoutDetails]);

	return (
		<Layout>
			<div className="w-full">
				<Form
					form={form}
					initialValues={initialValues}
					onFinish={onProceed}
					className="flex flex-col gap-7">
					<DropdownInput
						onChange={(e: any) =>
							setPayoutDetails({
								...payoutDetails,
								channel: e,
							})
						}
						name="payoutChannel"
						options={payoutChannels}
						label="Select a payout channel"
						placeholder={
							channelsLoading ? "Fetching channels..." : "Select channel"
						}
						loading={channelsLoading}
					/>

					<OptionalRender
						condition={
							payoutDetails?.channel?.length > 0 && payoutOptionShown === true
						}>
						<DropdownInput
							onChange={(e: any) =>
								setPayoutDetails({
									...payoutDetails,
									option: e,
								})
							}
							name="payoutOption"
							options={payoutOptions}
							label="Select a payout mode"
							nonEditable={withdrawalOptionsLoading}
							noSubtitleText
							placeholder={
								withdrawalOptionsLoading ? "Fetching modes..." : "Select option"
							}
						/>
					</OptionalRender>

					<OptionalRender condition={fieldsLoading}>
						{[...Array(3)].map((_, i) => (
							<Skeleton key={i} active avatar paragraph={{ rows: 1 }} />
						))}
					</OptionalRender>

					<OptionalRender condition={beneficiaryFieldsValid}>
						<div className="mb-5">
							<DynamicBankForm
								ref={dynamicFormRef}
								formFields={beneficiaryFields}
								fetchingFields={fieldsLoading}
								initalValues={withdrawalInfo}
								fieldData={fieldData}
								recipientCountry={withdrawalInfo?.withdrawalCountry}
							/>
						</div>
					</OptionalRender>

					<OptionalRender condition={beneficiaryFieldsValid && !withdrawalInfo?.isCreatingNewRecipient}>
						<div className="">
							<Checkbox
								checked={withdrawalInfo?.isBeneficiarySaved}
								onChange={() => dispatch(toggleBeneficiarySaved())}>
								Save as recipient
							</Checkbox>
						</div>
					</OptionalRender>

					<div className="flex items-center justify-between mt-[1.2rem]">
						<div
							onClick={() => pageDecrement && pageDecrement()}
							className="text-[#194153] font-semibold cursor-pointer">
							Back
						</div>
						<div className="w-full max-w-[9rem]">
							<QwidButton disabled={addBankLoading} text="Continue" isFull darkBlueBg type="submit" />
						</div>
					</div>
				</Form>
			</div>
		</Layout>
	);
};

export default PayoutChannel;
