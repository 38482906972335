import { toggleConfirmOffshore } from "redux/slices/dashboardSlice";
import styled from "styled-components";
import {
	useToolkit,
	CenterModal,
	QwidButton,
	Spinner,
	useSendFunds,
} from "../../components";

type Props = {
	detailsOnly?: boolean;
	loading?: boolean;
	acctEmpty?: boolean;
	trfPayload: any;
};

const ConfirmOffshoreModal = ({ trfPayload: payload }: Props) => {
	const { useAppSelector, dispatch, avail } = useToolkit();

	const {
		isConfirmOffshoreOpen,
		trfDetails: det,
		userBeneficiaries,
	} = useAppSelector((state) => state.dashboard);

	const { initSendRequest, trfLoading } = useSendFunds();

	const {
		fromCur,
		fromAmount,
		beneficiaryId,
		fundSource,
		remittancePurpose,
		receiveAmount,
		paymentType,
	} = payload;

	const beneficiary = userBeneficiaries?.find(
		(item: any) => item?.id == beneficiaryId
	);
	const recp = beneficiary;

	const getPaymentMethod = () => {
		if (paymentType == 1) {
			return "Wallet";
		} else if (paymentType == 2) {
			return "Blinqpay";
		} else if (paymentType == 3) {
			return "Bank Transfer";
		} else {
			return "N/A";
		}
	};

	return (
		<CenterModal
			isAntd
			darkBg
			title="Confirm Transaction"
			control={isConfirmOffshoreOpen}
			onClose={() => dispatch(toggleConfirmOffshore())}>
			{trfLoading ? (
				<Spinner msg="Processing" />
			) : (
				<div className="w-full mt-2 pt-4 lg:pr-3">
					<div className="mb-4">
						<Div>
							<p className="confirm-left">
								{fromCur?.length
									? `${fromCur} ${fromAmount?.toLocaleString()}`
									: "N/A"}
							</p>
							<p className="confirm-right">Amount Sending</p>
						</Div>

						<Div>
							<p className="confirm-left">
								{receiveAmount?.length
									? avail(`${receiveAmount?.toLocaleString()}`)
									: "N/A"}
							</p>
							<p className="confirm-right">Recipient Gets</p>
						</Div>

						<Div>
							<p className="confirm-left">{avail(recp?.accountName)}</p>
							<p className="confirm-right">Recipient</p>
						</Div>

						<Div>
							<p className="confirm-left">{avail(recp?.bankName)}</p>
							<p className="confirm-right">Recipient Bank</p>
						</Div>

						<Div>
							<p className="confirm-left">{avail(recp?.accountNo)}</p>
							<p className="confirm-right">Recipient Acct No.</p>
						</Div>

						<Div>
							<p className="confirm-left">
								{avail(remittancePurpose?.split("-")[1])}
							</p>
							<p className="confirm-right">Remittance Purpose</p>
						</Div>

						<Div>
							<p className="confirm-left">{avail(fundSource?.split("-")[1])}</p>
							<p className="confirm-right">Fund Source</p>
						</Div>

						<Div>
							<p className="confirm-left">{getPaymentMethod()}</p>
							<p className="confirm-right">Payment Method</p>
						</Div>
					</div>

					<div className="mb-4 bg-[#FFF9ED] p-4 rounded-md text-[#58461C]">
						<p className="mb-2 smb twide">Please note</p>
						<p className="mb-0">
							Transactions usually reflect within 5 to 10 minutes. If it takes
							longer, please contact our customer care centre:&nbsp;
							<span className="pry-text">
								<a href="mailto:support@qwid.io">support@qwid.io</a>
							</span>{" "}
						</p>
					</div>

					<div className="flexed flex-col space-y-3 mt-7">
						<QwidButton
							onClick={() => initSendRequest(payload)}
							text={<span className="px-9">Confirm Payment</span>}
						/>
						<QwidButton
							onClick={() => dispatch(toggleConfirmOffshore())}
							transparent
							text={<span className="px-2">Cancel</span>}
						/>
					</div>
				</div>
			)}
		</CenterModal>
	);
};

export default ConfirmOffshoreModal;

const Div = styled.div`
	margin-bottom: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.confirm-right {
		font-size: 12px;
		color: #62666e;
		letter-spacing: 0.025em;
		margin-bottom: 0;
	}

	.confirm-left {
		color: #252d3e;
		font-weight: 500;
		margin-bottom: 0;
	}
`;
