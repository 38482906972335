import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Skeleton } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { motion } from "framer-motion";
import useFramer from "utils/animations/useFramer";
import useToolkit from "utils/hooks/misc/useToolkit";
import Head from 'next/head'
const Stories = () => {
	const storiesHead = [
		"Product Updates",
		"Personal Finance",
		"Inside Qwid",
		"User Spotlight",
		"Guides",
	];

	const [selected, setSelected] = useState("Home");
	const [data, setData] = useState<BlogProps[]>([]);
	const [firstItem, setFirstItem] = useState<BlogProps | undefined>();
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [displayedItems, setDisplayedItems] = useState(pageSize);
	const [totalItems, setTotalItems] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const { zoomWrap, flashIn } = useFramer();
	const startItem = (currentPage - 1) * pageSize;
	const endItem = startItem + pageSize;
	const { axiosBlogInstance } = useToolkit();
	const truncateContent = (summary: string, limit: number) => {
		if (summary === null || summary === undefined) {
			return "";
		}

		const words = summary.split(" ");
		if (words.length > limit) {
			const truncated = words.slice(0, limit).join(" ") + "...";
			return truncated;
		}

		return summary;
	};

	const handleBlog = async () => {
		try {
			const res = await axiosBlogInstance.get(
				"/blogs/active?perPage=100&page=1"
			);
			setTotalItems(res.data.posts.length);
			setFirstItem(res.data.posts[res.data.posts.length - 1]);
			setData(res.data.posts.reverse());
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};
	const handlePageChange = (page: number, pageSize?: number) => {
		setCurrentPage(page);
	};

	const handleLoadMore = () => {
		setDisplayedItems(displayedItems + pageSize);
	};

	useEffect(() => {
		handleBlog();
	}, [currentPage, pageSize]);
	const filteredStories = data.filter(
		(item) => selected === "Home" || item.tags.includes(selected)
	);
	return (
	<>
	 <Head>
	    <title>{firstItem?.title}</title>
		<meta name="description" content={firstItem?.summary}  key="summary" />
        <meta property="og:title" content={firstItem?.title}  key="title" />
        <meta property="og:image" content={firstItem?.image}  key="image" />
      </Head>
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="  sm:h-[38rem] sm:h-[39.4rem]  lg:h-[49rem]  xl:h-[37rem]  flex flex-col xl:flex-row">
				{/* First Item */}
				{isLoading ? (
					<div className=" w-4/5 md:w-full  mx-auto mt-20 md:mt-0 flex justify-center items-center md:ml-14">
						<Skeleton active />
					</div>
				) : (
					<div className="md:w-full sm:h-[25rem] h-[19rem] xl:w-2/3  xl:h-full lg:h-[35rem]  flex justify-center items-center">
						<div className="h-[15rem] w-4/5 md:w-10/12 w-5/6 h-5/6 md:h-4/5 lg:w-5/6   xl:h-[82%]  xl:w-[86%]">
							<Link href={`/blog/${firstItem?.id}`}>
								<img
									alt="blog"
									src={firstItem?.image}
									className="object-cover rounded-lg cursor-pointer h-full w-full"
								/>
							</Link>
						</div>
					</div>
				)}

				{/* First Item Content */}
				{isLoading ? (
					<div className="w-4/5 md:w-3/5 mx-auto mt-20 md:mt-0 flex justify-center items-center md:ml-14">
						<Skeleton active />
					</div>
				) : (
					<>
						<div className="md:w-full   md:mb-10  xl:h-full xl:w-1/2 flex   items-center justify-center ">
							<Link href={`/blog/${firstItem?.id}`}>
								<div className="w-4/5 h-3/5  md:w-10/12 xl:w-full  lg:h-full lg:w-5/6 flex cursor-pointer justify-center flex-col">
									<div className="my-2 flex ">
										<div className="w-4 h-[2px] bg-gray-300 mt-[0.6rem] mr-2"></div>
										<p className="text-[#48bbed] text-sm font-medium">
											{firstItem && firstItem?.tags[0]}
										</p>
									</div>
									<div>
										<p className="text-[0.6rem] flex items-center">
											{moment(firstItem?.createdAt).format("D MMMM YYYY")}{" "}
											<div className="bg-black mx-2 h-1 w-1 rounded"></div>{" "}
											{firstItem?.readTime}
										</p>
									</div>
									<div>
										<h1 className="font-semibold font-bold tracking-wide xl:w-[90%] sm:text-xl">
											{firstItem && firstItem?.title}
										</h1>
									</div>
									<div className="">
										<p className="font-medium text-[0.7rem] md:text-[0.8rem] lg:w-4/5">
											{" "}
											{truncateContent(firstItem?.summary ?? "", 25)}
										</p>
									</div>
									<div>
										<p className="text-[0.7rem]">
											By {firstItem && firstItem?.author}
										</p>
									</div>
								</div>
							</Link>
						</div>
					</>
				)}
			</motion.div>

			{/* Other Items */}
			<div className="w-4/5 md:w-10/12    lg:w-10/12  xl:w-11/12 mx-auto my-6 md:my-0">
				<div className="">
					<p className="text-md md:text-lg  font-semibold lg:ml-0">
						Latest stories
					</p>
				</div>
				<div className="flex w-full   overflow-x-auto h-10 scrollbar-hide w-full">
					{["Home", ...storiesHead].map((item, index) => (
						<div
							key={index}
							onClick={() => setSelected(item)}
							className={`${
								selected === item
									? "underline decoration-2 decoration-[#48bbed] underline-offset-8 font-medium text-sm "
									: ""
							} whitespace-nowrap text-sm py-2 mr-6 font-medium cursor-pointer text-xs md:text-sm `}>
							{item}
						</div>
					))}
				</div>
				<div className="flex  flex-col justify-center   items-center lg:flex-wrap lg:justify-start    lg:w-full lg:flex-row w-full  mt-7">
					{isLoading ? (
						<>
							<Skeleton active />
							<Skeleton active />
						</>
					) : filteredStories.length > 0 ? (
						filteredStories
							.filter(
								(item) => selected === "Home" || item.tags.includes(selected)
							)
							.map((item, index) => (
								<Link href={`/blog/${item?.id}`} key={index}>
									<div className="  w-full md:h-[35rem] lg:mr-11 lg:w-[27rem]  lg:w-2/5  lg:mr-20 xl:justify-between  xl:h-[28rem] cursor-pointer xl:w-[26%] lg:h-[27rem]    my-2">
										<div className=" mb-0 h-3 flex ">
											<div className="w-4 h-[2px]  bg-gray-300 mt-[0.6rem] mr-2"></div>
											<p className="text-[#48bbed] text-sm font-medium">
												{item.tags[0]}
											</p>
										</div>
										<div className="overflow-hidden  md:h-[22rem] rounded w-full  sm:w-[35rem]  md:w-full my-4 lg:w-full lg:h-52  xl:w-full h-[13rem]">
											<img
												src={item?.image}
												alt="blog picture"
												className="object-cover rounded-lg cursor-pointer h-full w-full"
											/>
										</div>

										<div className="text-[0.6rem] mt-2 flex items-center">
											{moment(item?.createdAt).format("D MMMM YYYY")}{" "}
											<div className="bg-black mx-2 h-1 w-1 rounded"></div>{" "}
											{item?.readTime}
										</div>
										<div className="my-2 font-bold tracking-wide">
											<p>{item.title}</p>
										</div>
										<div className="w-full h-[4rem]  xl:h-[4rem]    my-2">
											<p className="font-medium text-[0.8rem]">
												{truncateContent(item?.summary, 20)}
											</p>
										</div>
										<div className="my-2 ">
											<p className="text-[0.7rem] ">By {item?.author}</p>
										</div>
									</div>
								</Link>
							))
					) : (
						<p className="text-xl">No stories yet</p>
					)}
				</div>
			</div>
		</>
	);
};

export default Stories;
