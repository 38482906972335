import QwidButton from "components/misc/QwidButton";
import { useToolkit } from "components";
import Layout from "./internal-transfer/Layout";

interface ErrorPageProps {
	errorMsg?: string;
	doneFxn?: () => void;
}

const ErrorPage = ({ errorMsg, doneFxn }: ErrorPageProps) => {
	// const { router, dispatch, useAppSelector } = useToolkit();
	const handleDone = async () => {
		doneFxn && doneFxn();
	};

	return (
		<Layout>
			<div className="m-auto flex flex-col p-4 pb-8 pt-8 sm:pb-[3rem] sm:pt-[3rem] max-w-[36rem]   items-center">
				<div className="m-auto flex w-full flex-col gap-1 items-center max-w-[26rem]">
					<img className="w-[7rem]" src="/assets/dashboard/failed.svg" alt="" />
					<h2 className="text-[1.02rem] font-semibold">Transaction failed</h2>
					<p className="text-center text-[0.85rem]">{errorMsg}</p>

					<div className="w-full">
						<QwidButton isFull darkBlueBg text={"Done"} onClick={handleDone} />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ErrorPage;
