export const SuccessVector = ()=>(
    <div className="relative  animate-pulse">
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="120" height="120" rx="60" fill="#16566D"/>
<rect x="12" y="12" width="96" height="96" rx="48" fill="#225F74"/>
<rect x="24" y="24" width="72" height="72" rx="36" fill="#386F82"/>
<rect x="36" y="36" width="48" height="48" rx="24" fill="#4C7D8E"/>
<path d="M49.125 71.43H73.125V47.43H49.125V71.43Z" fill="transparent"/>
</svg>
<div className="absolute  top-[50%] right-[50%] transform -translate-y-1/2 translate-x-1/2 text-[1.5rem]">🎉</div>
    </div>

)