import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type ConversionPayload = {
	result: string;
	actualValue: number;
};

interface conversionsState {
	isConvertSuccessOpen: boolean;
	amountConverted: string;
	conversionValue: number;
}

const initialState: conversionsState = {
	isConvertSuccessOpen: false,
	amountConverted: "",
	conversionValue: 0,
};

const conversionsSlice = createSlice({
	name: "conversions",
	initialState,
	reducers: {
		toggleConvertSuccess: (state) => {
			return {
				...state,
				isConvertSuccessOpen: !state.isConvertSuccessOpen,
			};
		},

		setConvertedAmount: (
			state,
			{ payload }: PayloadAction<ConversionPayload>
		) => {
			return {
				...state,
				amountConverted: payload?.result,
				conversionValue: payload?.actualValue,
				isConvertSuccessOpen: true,
			};
		},
	},
});

export const { toggleConvertSuccess, setConvertedAmount } =
	conversionsSlice.actions;

export default conversionsSlice.reducer;
