import React, { ReactNode } from "react";
import useFramer from "../../../utils/animations/useFramer";
import { motion } from "framer-motion";

type Props = {};
type FeatureProps = {
	title: string | ReactNode;
	imgPath: string;
	subtitle: string;
	padded?: boolean;
	topPadding?: boolean;
};

const WhyQwid = (props: Props) => {
	return (
		<div className="bg-white py-3 lg:py-16">
			<p className="text-center smb text-lg lg:text-3xl blk">Why Qwid? </p>

			<div className="lp-duplicate-wrapper lg:pt-8">
				<div className="w-full lg:w-5/6 lg:mx-auto flex items-center justify-center lg:justify-between flex-wrap lg:flex-nowrap">
					<div className="w-full lg:w-1/4 flexed flex-col">
						<FeatureWrapper
							title="Highly Trusted"
							imgPath="/assets/landing/whyqwid-ht.svg"
							subtitle="With a 99% success rate on cross-border transfers, we're reliable"
						/>
						<FeatureWrapper
							title={
								<div>
									Secure <br /> Transactions
								</div>
							}
							imgPath="/assets/landing/whyqwid-st.svg"
							subtitle="Our military-grade encryption protocols provide you with secured transactions you can trust"
						/>
					</div>

					<div className="w-full lg:w-2/4 flexed">
						<img
							src="/assets/landing/why-qwid-shot.svg"
							alt="why-qwid"
							className="w-[300px] lg:w-[500px]"
						/>
					</div>

					<div className="w-full lg:w-1/4 flexed flex-col">
						<FeatureWrapper
							title="Super Speed"
							imgPath="/assets/landing/whyqwid-ss.svg"
							subtitle="With remittance done in real-time, we provide the speed needed to support day-to-day business transactions"
						/>
						<FeatureWrapper
							title="Convenient"
							imgPath="/assets/landing/whyqwid-st.svg"
							subtitle="Recieve funds in your qwid wallet or local bank account, whatever works for you"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhyQwid;

const FeatureWrapper = ({ title, imgPath, subtitle }: FeatureProps) => {
	const { pushLeft } = useFramer();
	return (
		<motion.div variants={pushLeft} className="w-full my-4 flexed flex-col">
			<img src={imgPath} width={30} alt="icon" className="mb-2 mx-auto" />
			<p className="smb mb-3 lg:text-lg text-center">{title}</p>

			<div className="flex flex-col lg:flex-row items-center lg:items-start space-x-3">
				<p className="mb-0 w-3/4 text-center mx-auto">{subtitle}.</p>
			</div>
		</motion.div>
	);
};
