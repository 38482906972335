import { Checkbox as AntCheck } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

type Props = {
  onChange?: (e: CheckboxChangeEvent) => null;
  checked?: boolean
  usesChecked?: boolean;
  onCheckChange?: (e?: CheckboxChangeEvent) => void;
  disabled?: boolean
};

const CheckBox = ({ onChange, checked, usesChecked, onCheckChange, disabled }: Props) => {
  return !usesChecked ? <AntCheck disabled={disabled} onChange={onChange} /> : <AntCheck disabled={disabled} checked={checked} onChange={onCheckChange} />;
};

export default CheckBox;
