const countryInfo = [
	{ name: "Nigeria", code: "NG", id: 164, phone: 234 },
	{ name: "Afghanistan", code: "AF", id: 1, phone: 93 },
	{ name: "Aland Islands", code: "AX", id: 2, phone: 358 },
	{ name: "Albania", code: "AL", id: 3, phone: 355 },
	{ name: "Algeria", code: "DZ", id: 4, phone: 213 },
	{ name: "American Samoa", code: "AS", id: 5, phone: 1684 },
	{ name: "Andorra", code: "AD", id: 6, phone: 376 },
	{ name: "Angola", code: "AO", id: 7, phone: 244 },
	{ name: "Anguilla", code: "AI", id: 8, phone: 1264 },
	{ name: "Antarctica", code: "AQ", id: 9, phone: 672 },
	{ name: "Antigua and Barbuda", code: "AG", id: 10, phone: 1268 },
	{ name: "Argentina", code: "AR", id: 11, phone: 54 },
	{ name: "Armenia", code: "AM", id: 12, phone: 374 },
	{ name: "Aruba", code: "AW", id: 13, phone: 297 },
	{ name: "Australia", code: "AU", id: 14, phone: 61 },
	{ name: "Austria", code: "AT", id: 15, phone: 43 },
	{ name: "Azerbaijan", code: "AZ", id: 16, phone: 994 },
	{ name: "Bahamas", code: "BS", id: 17, phone: 1242 },
	{ name: "Bahrain", code: "BH", id: 18, phone: 973 },
	{ name: "Bangladesh", code: "BD", id: 19, phone: 880 },
	{ name: "Barbados", code: "BB", id: 20, phone: 1246 },
	{ name: "Belarus", code: "BY", id: 21, phone: 375 },
	{ name: "Belgium", code: "BE", id: 22, phone: 32 },
	{ name: "Belize", code: "BZ", id: 23, phone: 501 },
	{ name: "Benin", code: "BJ", id: 24, phone: 229 },
	{ name: "Bermuda", code: "BM", id: 25, phone: 1441 },
	{ name: "Bhutan", code: "BT", id: 26, phone: 975 },
	{ name: "Bolivia", code: "BO", id: 27, phone: 591 },
	{ name: "Bonaire, Sint Eustatius and Saba", code: "BQ", id: 28, phone: 599 },
	{ name: "Bosnia and Herzegovina", code: "BA", id: 29, phone: 387 },
	{ name: "Botswana", code: "BW", id: 30, phone: 267 },
	{ name: "Bouvet Island", code: "BV", id: 31, phone: 55 },
	{ name: "Brazil", code: "BR", id: 32, phone: 55 },
	{ name: "British Indian Ocean Territory", code: "IO", id: 33, phone: 246 },
	{ name: "Brunei Darussalam", code: "BN", id: 34, phone: 673 },
	{ name: "Bulgaria", code: "BG", id: 35, phone: 359 },
	{ name: "Burkina Faso", code: "BF", id: 36, phone: 226 },
	{ name: "Burundi", code: "BI", id: 37, phone: 257 },
	{ name: "Cambodia", code: "KH", id: 38, phone: 855 },
	{ name: "Cameroon", code: "CM", id: 39, phone: 237 },
	// { name: "Canada", code: "CA", id: 40, phone: 1 },
	{ name: "Cape Verde", code: "CV", id: 41, phone: 238 },
	{ name: "Cayman Islands", code: "KY", id: 42, phone: 1345 },
	{ name: "Central African Republic", code: "CF", id: 43, phone: 236 },
	{ name: "Chad", code: "TD", id: 44, phone: 235 },
	{ name: "Chile", code: "CL", id: 45, phone: 56 },
	{ name: "China", code: "CN", id: 46, phone: 86 },
	{ name: "Christmas Island", code: "CX", id: 47, phone: 61 },
	{ name: "Cocos (Keeling) Islands", code: "CC", id: 48, phone: 672 },
	{ name: "Colombia", code: "CO", id: 49, phone: 57 },
	{ name: "Comoros", code: "KM", id: 50, phone: 269 },
	{ name: "Congo", code: "CG", id: 51, phone: 242 },
	{
		name: "Congo, Democratic Republic of the Congo",
		code: "CD",
		id: 52,
		phone: 242,
	},
	{ name: "Cook Islands", code: "CK", id: 53, phone: 682 },
	{ name: "Costa Rica", code: "CR", id: 54, phone: 506 },
	{ name: "Cote D'Ivoire", code: "CI", id: 55, phone: 225 },
	{ name: "Croatia", code: "HR", id: 56, phone: 385 },
	{ name: "Cuba", code: "CU", id: 57, phone: 53 },
	{ name: "Curacao", code: "CW", id: 58, phone: 599 },
	{ name: "Cyprus", code: "CY", id: 59, phone: 357 },
	{ name: "Czech Republic", code: "CZ", id: 60, phone: 420 },
	{ name: "Denmark", code: "DK", id: 61, phone: 45 },
	{ name: "Djibouti", code: "DJ", id: 62, phone: 253 },
	{ name: "Dominica", code: "DM", id: 63, phone: 1767 },
	{ name: "Dominican Republic", code: "DO", id: 64, phone: 1809 },
	{ name: "Ecuador", code: "EC", id: 65, phone: 593 },
	{ name: "Egypt", code: "EG", id: 66, phone: 20 },
	{ name: "El Salvador", code: "SV", id: 67, phone: 503 },
	{ name: "Equatorial Guinea", code: "GQ", id: 68, phone: 240 },
	{ name: "Eritrea", code: "ER", id: 69, phone: 291 },
	{ name: "Estonia", code: "EE", id: 70, phone: 372 },
	{ name: "Ethiopia", code: "ET", id: 71, phone: 251 },
	{ name: "Falkland Islands (Malvinas)", code: "FK", id: 72, phone: 500 },
	{ name: "Faroe Islands", code: "FO", id: 73, phone: 298 },
	{ name: "Fiji", code: "FJ", id: 74, phone: 679 },
	{ name: "Finland", code: "FI", id: 75, phone: 358 },
	{ name: "France", code: "FR", id: 76, phone: 33 },
	{ name: "French Guiana", code: "GF", id: 77, phone: 594 },
	{ name: "French Polynesia", code: "PF", id: 78, phone: 689 },
	{ name: "French Southern Territories", code: "TF", id: 79, phone: 262 },
	{ name: "Gabon", code: "GA", id: 80, phone: 241 },
	{ name: "Gambia", code: "GM", id: 81, phone: 220 },
	{ name: "Georgia", code: "GE", id: 82, phone: 995 },
	{ name: "Germany", code: "DE", id: 83, phone: 49 },
	{ name: "Ghana", code: "GH", id: 84, phone: 233 },
	{ name: "Gibraltar", code: "GI", id: 85, phone: 350 },
	{ name: "Greece", code: "GR", id: 86, phone: 30 },
	{ name: "Greenland", code: "GL", id: 87, phone: 299 },
	{ name: "Grenada", code: "GD", id: 88, phone: 1473 },
	{ name: "Guadeloupe", code: "GP", id: 89, phone: 590 },
	{ name: "Guam", code: "GU", id: 90, phone: 1671 },
	{ name: "Guatemala", code: "GT", id: 91, phone: 502 },
	{ name: "Guernsey", code: "GG", id: 92, phone: 44 },
	{ name: "Guinea", code: "GN", id: 93, phone: 224 },
	{ name: "Guinea-Bissau", code: "GW", id: 94, phone: 245 },
	{ name: "Guyana", code: "GY", id: 95, phone: 592 },
	{ name: "Haiti", code: "HT", id: 96, phone: 509 },
	{ name: "Heard Island and Mcdonald Islands", code: "HM", id: 97, phone: 0 },
	{ name: "Holy See (Vatican City State)", code: "VA", id: 98, phone: 39 },
	{ name: "Honduras", code: "HN", id: 99, phone: 504 },
	{ name: "Hong Kong", code: "HK", id: 100, phone: 852 },
	{ name: "Hungary", code: "HU", id: 101, phone: 36 },
	{ name: "Iceland", code: "IS", id: 102, phone: 354 },
	{ name: "India", code: "IN", id: 103, phone: 91 },
	{ name: "Indonesia", code: "ID", id: 104, phone: 62 },
	{ name: "Iran, Islamic Republic of", code: "IR", id: 105, phone: 98 },
	{ name: "Iraq", code: "IQ", id: 106, phone: 964 },
	{ name: "Ireland", code: "IE", id: 107, phone: 353 },
	{ name: "Isle of Man", code: "IM", id: 108, phone: 44 },
	{ name: "Israel", code: "IL", id: 109, phone: 972 },
	{ name: "Italy", code: "IT", id: 110, phone: 39 },
	{ name: "Jamaica", code: "JM", id: 111, phone: 1876 },
	{ name: "Japan", code: "JP", id: 112, phone: 81 },
	{ name: "Jersey", code: "JE", id: 113, phone: 44 },
	{ name: "Jordan", code: "JO", id: 114, phone: 962 },
	{ name: "Kazakhstan", code: "KZ", id: 115, phone: 7 },
	{ name: "Kenya", code: "KE", id: 116, phone: 254 },
	{ name: "Kiribati", code: "KI", id: 117, phone: 686 },
	{
		name: "Korea, Democratic People's Republic of",
		code: "KP",
		id: 118,
		phone: 850,
	},
	{ name: "Korea, Republic of", code: "KR", id: 119, phone: 82 },
	{ name: "Kosovo", code: "XK", id: 120, phone: 381 },
	{ name: "Kuwait", code: "KW", id: 121, phone: 965 },
	{ name: "Kyrgyzstan", code: "KG", id: 122, phone: 996 },
	{ name: "Lao People's Democratic Republic", code: "LA", id: 123, phone: 856 },
	{ name: "Latvia", code: "LV", id: 124, phone: 371 },
	{ name: "Lebanon", code: "LB", id: 125, phone: 961 },
	{ name: "Lesotho", code: "LS", id: 126, phone: 266 },
	{ name: "Liberia", code: "LR", id: 127, phone: 231 },
	{ name: "Libyan Arab Jamahiriya", code: "LY", id: 128, phone: 218 },
	{ name: "Liechtenstein", code: "LI", id: 129, phone: 423 },
	// { name: "Lithuania", code: "LT", id: 130, phone: 370 },
	{ name: "Luxembourg", code: "LU", id: 131, phone: 352 },
	{ name: "Macao", code: "MO", id: 132, phone: 853 },
	{
		name: "Macedonia, the Former Yugoslav Republic of",
		code: "MK",
		id: 133,
		phone: 389,
	},
	{ name: "Madagascar", code: "MG", id: 134, phone: 261 },
	{ name: "Malawi", code: "MW", id: 135, phone: 265 },
	{ name: "Malaysia", code: "MY", id: 136, phone: 60 },
	{ name: "Maldives", code: "MV", id: 137, phone: 960 },
	{ name: "Mali", code: "ML", id: 138, phone: 223 },
	{ name: "Malta", code: "MT", id: 139, phone: 356 },
	{ name: "Marshall Islands", code: "MH", id: 140, phone: 692 },
	{ name: "Martinique", code: "MQ", id: 141, phone: 596 },
	{ name: "Mauritania", code: "MR", id: 142, phone: 222 },
	{ name: "Mauritius", code: "MU", id: 143, phone: 230 },
	{ name: "Mayotte", code: "YT", id: 144, phone: 269 },
	{ name: "Mexico", code: "MX", id: 145, phone: 52 },
	{ name: "Micronesia, Federated States of", code: "FM", id: 146, phone: 691 },
	{ name: "Moldova, Republic of", code: "MD", id: 147, phone: 373 },
	{ name: "Monaco", code: "MC", id: 148, phone: 377 },
	{ name: "Mongolia", code: "MN", id: 149, phone: 976 },
	{ name: "Montenegro", code: "ME", id: 150, phone: 382 },
	{ name: "Montserrat", code: "MS", id: 151, phone: 1664 },
	{ name: "Morocco", code: "MA", id: 152, phone: 212 },
	{ name: "Mozambique", code: "MZ", id: 153, phone: 258 },
	{ name: "Myanmar", code: "MM", id: 154, phone: 95 },
	{ name: "Namibia", code: "NA", id: 155, phone: 264 },
	{ name: "Nauru", code: "NR", id: 156, phone: 674 },
	{ name: "Nepal", code: "NP", id: 157, phone: 977 },
	{ name: "Netherlands", code: "NL", id: 158, phone: 31 },
	{ name: "Netherlands Antilles", code: "AN", id: 159, phone: 599 },
	{ name: "New Caledonia", code: "NC", id: 160, phone: 687 },
	{ name: "New Zealand", code: "NZ", id: 161, phone: 64 },
	{ name: "Nicaragua", code: "NI", id: 162, phone: 505 },
	{ name: "Niger", code: "NE", id: 163, phone: 227 },
	{ name: "Nigeria", code: "NG", id: 164, phone: 234 },
	{ name: "Niue", code: "NU", id: 165, phone: 683 },
	{ name: "Norfolk Island", code: "NF", id: 166, phone: 672 },
	{ name: "Northern Mariana Islands", code: "MP", id: 167, phone: 1670 },
	{ name: "Norway", code: "NO", id: 168, phone: 47 },
	{ name: "Oman", code: "OM", id: 169, phone: 968 },
	{ name: "Pakistan", code: "PK", id: 170, phone: 92 },
	{ name: "Palau", code: "PW", id: 171, phone: 680 },
	{ name: "Palestinian Territory, Occupied", code: "PS", id: 172, phone: 970 },
	{ name: "Panama", code: "PA", id: 173, phone: 507 },
	{ name: "Papua New Guinea", code: "PG", id: 174, phone: 675 },
	{ name: "Paraguay", code: "PY", id: 175, phone: 595 },
	{ name: "Peru", code: "PE", id: 176, phone: 51 },
	{ name: "Philippines", code: "PH", id: 177, phone: 63 },
	{ name: "Pitcairn", code: "PN", id: 178, phone: 64 },
	{ name: "Poland", code: "PL", id: 179, phone: 48 },
	{ name: "Portugal", code: "PT", id: 180, phone: 351 },
	{ name: "Puerto Rico", code: "PR", id: 181, phone: 1787 },
	{ name: "Qatar", code: "QA", id: 182, phone: 974 },
	{ name: "Reunion", code: "RE", id: 183, phone: 262 },
	{ name: "Romania", code: "RO", id: 184, phone: 40 },
	{ name: "Russian Federation", code: "RU", id: 185, phone: 70 },
	{ name: "Rwanda", code: "RW", id: 186, phone: 250 },
	{ name: "Saint Barthelemy", code: "BL", id: 187, phone: 590 },
	{ name: "Saint Helena", code: "SH", id: 188, phone: 290 },
	{ name: "Saint Kitts and Nevis", code: "KN", id: 189, phone: 1869 },
	{ name: "Saint Lucia", code: "LC", id: 190, phone: 1758 },
	{ name: "Saint Martin", code: "MF", id: 191, phone: 590 },
	{ name: "Saint Pierre and Miquelon", code: "PM", id: 192, phone: 508 },
	{
		name: "Saint Vincent and the Grenadines",
		code: "VC",
		id: 193,
		phone: 1784,
	},
	{ name: "Samoa", code: "WS", id: 194, phone: 684 },
	{ name: "San Marino", code: "SM", id: 195, phone: 378 },
	{ name: "Sao Tome and Principe", code: "ST", id: 196, phone: 239 },
	{ name: "Saudi Arabia", code: "SA", id: 197, phone: 966 },
	{ name: "Senegal", code: "SN", id: 198, phone: 221 },
	{ name: "Serbia", code: "RS", id: 199, phone: 381 },
	{ name: "Serbia and Montenegro", code: "CS", id: 200, phone: 381 },
	{ name: "Seychelles", code: "SC", id: 201, phone: 248 },
	{ name: "Sierra Leone", code: "SL", id: 202, phone: 232 },
	{ name: "Singapore", code: "SG", id: 203, phone: 65 },
	// { name: "Sint Maarten", code: "SX", id: 204, phone: 1 },
	{ name: "Slovakia", code: "SK", id: 205, phone: 421 },
	{ name: "Slovenia", code: "SI", id: 206, phone: 386 },
	{ name: "Solomon Islands", code: "SB", id: 207, phone: 677 },
	{ name: "Somalia", code: "SO", id: 208, phone: 252 },
	{ name: "South Africa", code: "ZA", id: 209, phone: 27 },
	{
		name: "South Georgia and the South Sandwich Islands",
		code: "GS",
		id: 210,
		phone: 500,
	},
	{ name: "South Sudan", code: "SS", id: 211, phone: 211 },
	{ name: "Spain", code: "ES", id: 212, phone: 34 },
	{ name: "Sri Lanka", code: "LK", id: 213, phone: 94 },
	{ name: "Sudan", code: "SD", id: 214, phone: 249 },
	{ name: "Suriname", code: "SR", id: 215, phone: 597 },
	{ name: "Svalbard and Jan Mayen", code: "SJ", id: 216, phone: 47 },
	{ name: "Swaziland", code: "SZ", id: 217, phone: 268 },
	{ name: "Sweden", code: "SE", id: 218, phone: 46 },
	{ name: "Switzerland", code: "CH", id: 219, phone: 41 },
	{ name: "Syrian Arab Republic", code: "SY", id: 220, phone: 963 },
	{ name: "Taiwan, Province of China", code: "TW", id: 221, phone: 886 },
	{ name: "Tajikistan", code: "TJ", id: 222, phone: 992 },
	{ name: "Tanzania, United Republic of", code: "TZ", id: 223, phone: 255 },
	{ name: "Thailand", code: "TH", id: 224, phone: 66 },
	{ name: "Timor-Leste", code: "TL", id: 225, phone: 670 },
	{ name: "Togo", code: "TG", id: 226, phone: 228 },
	{ name: "Tokelau", code: "TK", id: 227, phone: 690 },
	{ name: "Tonga", code: "TO", id: 228, phone: 676 },
	{ name: "Trinidad and Tobago", code: "TT", id: 229, phone: 1868 },
	{ name: "Tunisia", code: "TN", id: 230, phone: 216 },
	{ name: "Turkey", code: "TR", id: 231, phone: 90 },
	{ name: "Turkmenistan", code: "TM", id: 232, phone: 7370 },
	{ name: "Turks and Caicos Islands", code: "TC", id: 233, phone: 1649 },
	{ name: "Tuvalu", code: "TV", id: 234, phone: 688 },
	{ name: "Uganda", code: "UG", id: 235, phone: 256 },
	{ name: "Ukraine", code: "UA", id: 236, phone: 380 },
	{ name: "United Arab Emirates", code: "AE", id: 237, phone: 971 },
	{ name: "United Kingdom", code: "GB", id: 238, phone: 44 },
	{ name: "United States", code: "US", id: 239, phone: 1 },
	// {
	// 	name: "United States Minor Outlying Islands",
	// 	code: "UM",
	// 	id: 240,
	// 	phone: 1,
	// },
	{ name: "Uruguay", code: "UY", id: 241, phone: 598 },
	{ name: "Uzbekistan", code: "UZ", id: 242, phone: 998 },
	{ name: "Vanuatu", code: "VU", id: 243, phone: 678 },
	{ name: "Venezuela", code: "VE", id: 244, phone: 58 },
	{ name: "Viet Nam", code: "VN", id: 245, phone: 84 },
	{ name: "Virgin Islands, British", code: "VG", id: 246, phone: 1284 },
	{ name: "Virgin Islands, U.s.", code: "VI", id: 247, phone: 1340 },
	{ name: "Wallis and Futuna", code: "WF", id: 248, phone: 681 },
	{ name: "Western Sahara", code: "EH", id: 249, phone: 212 },
	{ name: "Yemen", code: "YE", id: 250, phone: 967 },
	{ name: "Zambia", code: "ZM", id: 251, phone: 260 },
	{ name: "Zimbabwe", code: "ZW", id: 252, phone: 263 },
];

const businessCategories = [
	{ title: "Sole Trader", value: "sole trader" },
	{ title: "Private Limited Company", value: "private limited company" },
	{ title: "Public Company", value: "Public Company" },
	{ title: "Partnership", value: "partnership" },
	{ title: "LLP Firm", value: "llp firm" },
	{ title: "Government body", value: "government body" },
	{ title: "Association", value: "association" },
	{ title: "trust", value: "trust" },
	{ title: "Regulated Trust", value: 'regulated trust"},' },
	{ title: "Unregulated Trust", value: "unregulated trust" },
];

export { countryInfo, businessCategories };
