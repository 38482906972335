import { Form } from "antd";
import React, { useEffect, useMemo } from "react";
import Select from "react-select";
import QwidButton from "./QwidButton";
import countryList from "react-select-country-list";
import useLocation from "utils/hooks/dashboard/useLocation";

type Props = {
	onProceed: (values: any) => void;
};

const SignUpCountrySelector = ({ onProceed }: Props) => {
	const {getDynCountries, ctrData} = useLocation()
	useEffect(()=>{
		getDynCountries()
	}, [])
	//const options = useMemo(() => countryList().getData(), []);

	return (
		<Form onFinish={onProceed}>
			<div className="my-10 relative">
				<p className="form-label text-xs px-2 absolute -top-2 left-3 bg-white z-50">
				Country of Residence<span className="text-asterisk">&nbsp;*&nbsp;</span>
				</p>
				<Form.Item
					name="country"
					rules={[
						{
							required: true,
							message: "Required",
						},
					]}>
					<Select
						placeholder=""
						classNamePrefix="form-select"
						className="form-input-select"
						options={ctrData}
					/>
				</Form.Item>
			</div>

			<QwidButton text="Continue" type="submit" isFull />
		</Form>
	);
};

export default SignUpCountrySelector;
