import {
	CenterModal,
	useToolkit,
	Spinner,
	PersonnelDetailsForm,
	BusinessRegInformation,
	StakeholderInfo,
	OtherRelevantInfo,
	useSendFunds,
	QwidButton,
} from "components";
import {
	clearBusinessDetails,
	toggleBusinessUpdateModal,
} from "redux/slices/settingsSlice";
import { message, Steps } from "antd";
import styled from "styled-components";
import { FaBusinessTime, FaUserAlt } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { GiAbstract091 } from "react-icons/gi";
import useUser from "utils/hooks/settings/useUser";

function UpdateBusinessDetModal() {
	const { Step } = Steps;
	const { useAppSelector, useState, dispatch, user, useEffect } = useToolkit();
	const { countriesLoading, countriesData, getAllRecipientCountries } =
		useSendFunds();
	const { updLoading } = useUser();
	const { isBusinessDetModalOpen, isFirstTimeUser } = useAppSelector(
		(state) => state.settings
	);

	const [current, setCurrent] = useState(0);

	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const PreviousButton = () => (
		<QwidButton
			type="button"
			onClick={() => {
				// dispatch(clearBusinessDetails());
				setCurrent((prev) => prev - 1);
			}}
			text={<span className="px-5">Previous</span>}
			isDarkBordered
		/>
	);

	useEffect(() => {
		getAllRecipientCountries();
	}, []);

	const steps = [
		{
			title: "Personnel Info",
			content: (
				<PersonnelDetailsForm
					countriesLoading={countriesLoading}
					countriesData={countriesData}
					setCurrent={setCurrent}
				/>
			),
			Icon: FaUserAlt,
		},
		{
			title: "Business Reg. Info",
			content: (
				<BusinessRegInformation
					PreviousButton={PreviousButton}
					countriesLoading={countriesLoading}
					countriesData={countriesData}
					setCurrent={setCurrent}
				/>
			),
			Icon: FaBusinessTime,
		},

		{
			title: "Stakeholder's Info",
			content: (
				<StakeholderInfo
					PreviousButton={PreviousButton}
					countriesLoading={countriesLoading}
					countriesData={countriesData}
					setCurrent={setCurrent}
				/>
			),
			Icon: HiUserGroup,
		},
		{
			title: "Other Info",
			content: (
				<OtherRelevantInfo
					PreviousButton={PreviousButton}
					countriesLoading={countriesLoading}
					countriesData={countriesData}
				/>
			),
			Icon: GiAbstract091,
		},
	];

	const handleModalClose = () =>
		isFirstTimeUser
			? message.warning("Profile must be updated")
			: dispatch(toggleBusinessUpdateModal());

	return (
		<CenterModal
			width={1000}
			title={`Hello ${
				user?.endUser
					? user?.endUser[0]?.companyName
					: user?.enduser[0]?.companyName
			}, please update your business details`}
			isAntd
			control={isBusinessDetModalOpen}
			onClose={handleModalClose}>
			{updLoading ? (
				<Spinner msg="Updating details" />
			) : (
				<Wrapper className="p-8 flex flex-col">
					<Steps labelPlacement="vertical" current={current}>
						{steps.map(({ title, Icon }) => (
							<Step
								icon={<Icon />}
								key={title}
								title={<span className="text-sm nowrap">{title}</span>}
							/>
						))}
					</Steps>
					<div className="steps-content">{steps[current].content}</div>
				</Wrapper>
			)}
		</CenterModal>
	);
}

export default UpdateBusinessDetModal;

const Wrapper = styled.div`
	.steps-content {
		margin-top: 30px;
		padding: 30px 20px;
		border: 1px dashed #e9e9e9;
		border-radius: 2px;
	}
`;
