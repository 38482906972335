export const fromWallets = [
   {
      cur: "NGN",
      currency: "Nigerian Naira",
      country: "Nigeria",
      flag: "https://partners.qwid.io/assets/svg/flag/ng.svg",
   },
   {
      cur: "EUR",
      currency: "Euro",
      country: "Europe",
      flag: "https://partners.qwid.io/assets/svg/flag/eu.svg",
   },
   {
      cur: "CAD",
      currency: "Canadian Dollar",
      country: "Canada",
      flag: "https://partners.qwid.io/assets/svg/flag/ca.svg",
   },
   {
      cur: "GBP",
      currency: "British Pound Sterling",
      country: "United Kingdom",
      flag: "https://partners.qwid.io/assets/svg/flag/gb.svg",
   },
]


export const toWallets = [
   {
      cur: "NGN",
      currency: "Nigerian Naira",
      country: "Nigeria",
      flag: "https://partners.qwid.io/assets/svg/flag/ng.svg",
   },
   {
      cur: "USD",
      currency: "Dollar",
      country: "United States",
      flag: "https://partners.qwid.io/assets/svg/flag/us.svg",
   },
   {
      cur: "EUR",
      currency: "Euro",
      country: "Europe",
      flag: "https://partners.qwid.io/assets/svg/flag/eu.svg",
   },
   {
      cur: "CAD",
      currency: "Canadian Dollar",
      country: "Canada",
      flag: "https://partners.qwid.io/assets/svg/flag/ca.svg",
   },
   {
      cur: "GBP",
      currency: "British Pound Sterling",
      country: "United Kingdom",
      flag: "https://partners.qwid.io/assets/svg/flag/gb.svg",
   },
   {
      cur: "CNY",
      currency: "Chinese Yuan",
      country: "China",
      flag: "https://partners.qwid.io/assets/svg/flag/cn.svg",
   },
   {
      cur: "TRY",
      currency: "Turkish Lira",
      country: "Turkey",
      flag: "https:\/\/partners.qwid.io\/assets\/svg\/flag\/tr.svg",
   }
]