import React from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import QwidButton from 'components/misc/QwidButton';
import Image from 'next/image';
const DocumentList = ({ note, documents, buttonText, onButtonClick ,docsItems } : any) => {
  return (
    <div className="bg-[#FFFAF1] flex justify-between px-5 py-5 rounded-[8px]">
      <div className="flex flex-col gap-[0.6rem] font-medium">
        <div className="flex items-center gap-2">
          <AiFillInfoCircle className="text-[#F6A004]" />
          {note}
        </div>
        <div>{docsItems}:</div>
        <ul className="list-none pl-2">
          {documents.map((doc :string, index :any) => (
            <li key={index} className="font-normal py-1">
              ● &nbsp;{doc}
            </li>
          ))}
        </ul>
        <div className="w-full mt-3 max-w-[16rem]">
          <QwidButton 
            isFull 
            // darkBlueBg
            text={buttonText}  
            onClick={onButtonClick} 
          />
        </div>
      </div>
      <div className=" hidden md:flex items-center"> 

<Image
  width={120}
  height={120}
  src="/assets/common/uploadDoc.svg"
  alt="upload"

/>
</div>
    </div>
  );
};

export default DocumentList;