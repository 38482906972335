import React from 'react'
import { AiOutlineCheck } from 'react-icons/ai';
import { FaCircle } from 'react-icons/fa';

interface StepperProps {
    current: number;
    steps: number;
    completedSteps?: number;
    isFull?: boolean;
}
const CurrentIcon = ({completed}: {completed?: boolean})=>
    (
        <div className='text-[#48BBED] rounded-[50%] p-[0.35rem] border-[1.5px] border-[#48BBED]'>
            {completed? <AiOutlineCheck className='!w-[1rem] !h-[1rem]'/> : <FaCircle className='!w-[1rem] !p-[0.2rem] !h-[1rem]'/>}
        </div>
    )

const CompletedIcon = ()=>
    (
        <div className='text-white rounded-[50%] p-[0.4rem] bg-[#48BBED]'><AiOutlineCheck className='!w-[1rem] !h-[1rem]' /></div>
    )

const IncompletedIcon = ({number}: {number: number})=>(
        <div className='border-[#D1D5DB]  rounded-[50%] border-[1.5px] p-[0.35rem]'><div className='w-[1rem] h-[1rem] flex items-center justify-center text-[0.8rem] m-auto'>{number}</div></div>
)

const ProgressStepper = ({current, steps, completedSteps, isFull}:StepperProps) => {
    let array: number[] = []
    for (let i= 1; i <= steps; i++) {
        array.push(i)
    }
    
    let completedArray: number[] = []
   if(completedSteps){
    for (let i= 1; i <= completedSteps; i++) {
        completedArray.push(i-1)
    }
   }
    
  return (
    <div className='flex items-center w-full'>
        <div className={`${isFull ? 'w-full' : 'w-full max-w-[18rem]'} m-auto justify-center flex items-center gap-2 `}>
            {array.map((item)=>{
                if(current === item){
                    if(completedArray.includes(item)){
                        return(
                            <div key={item} className={`flex ${(item != array[array.length - 1]) ? 'w-full' : ''} items-center`}>
                                <CurrentIcon completed  />
                                {(item != array[array.length - 1]) && <div className='w-[90%] bg-[#D1D5DB] h-[1.5px]'></div>}
                            </div>
                        )
                    }
                    else{
                       return (
                        <div key={item} className={`flex ${(item != array[array.length - 1]) ? 'w-full' : ''} items-center`}>
                        <CurrentIcon   />
                        {(item != array[array.length - 1]) && <div className='w-[90%] bg-[#D1D5DB] h-[1.5px]'></div>}
                    </div>
                       )
                    }
                }
                else if(current > item){
                    return (
                        <div key={item} className={`flex ${(item != array[array.length - 1]) ? 'w-full' : ''} items-center`}>
                                <CompletedIcon/>
                                {(item != array[array.length - 1]) && <div className='w-[90%] bg-[#48BBED] h-[1.5px]'></div>}
                            </div>
                    )
                }
                else {
                    if(completedArray.includes(item)){
                        return (
                            <div key={item} className={`flex ${(item != array[array.length - 1]) ? 'w-full' : ''} items-center`}>
                                    <CompletedIcon/>
                                    {(item != array[array.length - 1]) && <div className='w-[90%] bg-[#D1D5DB] h-[1.5px]'></div>}
                                </div>
                        )
                    }
                    else{
                        return (
                            <div key={item} className={`flex ${(item != array[array.length - 1]) ? 'w-full' : ''} items-center`}>
                        <IncompletedIcon number={item} />
                        {(item != array[array.length - 1]) && <div className='w-[90%] bg-[#D1D5DB] h-[1.5px]'></div>}
                    </div>
                        )
                    }
                    
                }
            })}
        </div>
    </div>
  )
}

export default ProgressStepper;
