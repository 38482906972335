import React, { useEffect, useState } from 'react'
import { fromWallets, toWallets } from './exports';
import { useToasts } from 'react-toast-notifications';
import CurrencyFormat from 'react-currency-format';
import useSendFunds from 'utils/hooks/dashboard/useSendFunds';
import DropdownHandler from './DropdownHandler';
import { currencySymbols } from 'utils/hooks/misc/currencySymbols';

type Props = {}

const LandingPageConverter = (props: Props) => {
   const { addToast } = useToasts();
   const [toCur, setToCur] = useState(toWallets[1]);
   const [fromCur, setFromCur] = useState(fromWallets[0]);

   const {
      rateVals,
      getConversionRates,
      sendValue,
      setSendValue,
      editingLeft,
      setEditingLeft,
   } = useSendFunds();

   const toastError = (message: string) => addToast(`${message}!`, { appearance: 'error', })
   const returnedValue = rateVals?.receiving?.split(" ")[1] || rateVals?.rate.toFixed(2);

   const allowedFrom = fromWallets?.filter((item: any) => item?.cur !== fromCur?.cur)
   const allowedTo = toWallets?.filter((item: any) => item?.cur !== toCur?.cur)

   const fromRef = currencySymbols[fromCur?.cur];
   const toRef = currencySymbols[toCur?.cur];

   const handleSwitch = () => {
      const fromCurs = ['NGN', 'EUR', 'CAD', 'GBP'];

      if (!fromCurs?.includes(toCur?.cur)) {
         toastError(`Sorry, we currently don't support conversions from ${toCur?.cur}`)
      }

      else {
         setFromCur(toCur)
         setToCur(fromCur)
      }
   }

   useEffect(() => {
      if (!fromCur?.cur?.length || !toCur?.cur?.length) return;

      else if (fromCur?.cur === toCur?.cur) {
         toastError("Cannot convert same currencies");
      } else {
         const ratePayload: ConversionPayload = {
            from: fromCur?.cur,
            to: toCur?.cur,
            userId: '',
            transType: 'send',
            amount: editingLeft ? sendValue : returnedValue
         };

         getConversionRates(ratePayload);
      }
      // eslint-disable-next-line
   }, [fromCur, toCur, sendValue, returnedValue]);


   return (
      <div className="w-full">

         <div className='w-full flexed flex-col relative'>

            <p className="text-white lg:text-xl font-bold mb-5">
               How much do you want to convert?
            </p>

            <div className="bg-white rounded-xl w-full lg:w-[72%] px-3.5 py-3 relative border-2 border-blue-500">

               <p className="lg:t4 text-qwid-gray-50 mb-0">You Send</p>

               <CurrencyFormat
                  placeholder="1"
                  decimalScale={2}
                  thousandSeparator={true}
                  onValueChange={({ floatValue }) => {
                     if (floatValue && !Number(floatValue)) {
                        toastError("Cannot convert negative values");
                     } else {
                        setEditingLeft(true);
                        setSendValue(floatValue);
                     }
                  }}
                  value={!Number(sendValue) ? "" : editingLeft ? sendValue : returnedValue}
                  className='outline-none placeholder:text-black text-[16px] font-medium'
               />

               <DropdownHandler
                  reference={fromCur}
                  currenciesList={allowedFrom}
                  onSelect={(item: any) => {
                     if (item?.cur === toCur?.cur) {
                        toastError('Cannot convert same currencies')
                     } else {
                        setFromCur(item)
                     }
                  }
                  }
               />

            </div>

            <div className='pt-5 srelative'>
               <div className="z-[1]">

                  <img
                     alt="divider"
                     src="/assets/landing/conv-divider.svg"
                     className="absolute left-2/4 transform -translate-x-2/4 top-[123px] !z-[1]"
                  />
               </div>

               <button
                  onClick={handleSwitch}
                  className='text-qwid-dark-50 font-medium flexed space-x-2 bg-white border-2 border-qwid-gray-600 rounded-2xl text-xs px-3 py-1 active:scale-90 transition-transform absolute left-2/4 transform -translate-x-2/4 !z-10'>

                  <span>
                     <img
                        alt="switch"
                        className='mr-1'
                        src="/assets/landing/inline-conv-switch.svg"
                     />
                  </span>

                  Switch
               </button>
            </div>


            <div className="mt-10 bg-white rounded-xl w-full lg:w-[72%] px-3.5 py-3 relative border-2 border-blue-500">

               <p className="lg:t4 text-qwid-gray-50 mb-0">Recipient Gets</p>

               <CurrencyFormat
                  decimalScale={2}
                  thousandSeparator={true}
                  onValueChange={({ floatValue }) => {
                     if (floatValue && !Number(floatValue)) {
                        toastError("Cannot convert negative values");
                     } else {
                        setEditingLeft(false);
                        setSendValue(floatValue);
                     }
                  }}
                  placeholder={rateVals?.rate?.toString() || ""}
                  value={!Number(sendValue) ? "" : editingLeft ? returnedValue : sendValue}
                  className='outline-none placeholder:text-black text-[16px] font-medium'
               />

               <DropdownHandler
                  reference={toCur}
                  currenciesList={allowedTo}
                  onSelect={(item: any) => {
                     if (item?.cur === fromCur?.cur) {
                        toastError('Cannot convert same currencies')
                     } else {
                        setToCur(item)
                     }
                  }
                  }
               />

            </div>
         </div>

         <div className="w-full lg:w-[72%] mx-auto pt-3">

            <div className="flex-btw items-center text-white font-medium">
               <span>Our Rates</span>

               <span className='font-medium'>
                  {fromRef}1 = {toRef}{rateVals?.rate}
               </span>
            </div>

            <div className="flex-btw items-center text-white font-medium mt-2">
               <span>Total</span>

               <span className='font-medium'>
                  {fromRef}{rateVals?.toPay?.toLocaleString()}
               </span>
            </div>
         </div>

      </div>

   )
}

export default LandingPageConverter