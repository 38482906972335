
import React from 'react';

interface InfoProp {
  name: string;
  value: string | number | undefined | unknown;
}

const Info = ({ name, value }: InfoProp) => {
  function replaceUnderscores(text: string) {
    // Use a regular expression to globally replace underscores with spaces
    return text.replace(/_/g, ' ');
  }

  const displayValue = (val: string | number | undefined | unknown) => {
    if (val === undefined || val === null || val === "") {
      return "--";
    }
    return val as string | number;
  };

  return (
    <div className="flex flex-row sm:flex-col w-full gap-[0.5rem]">
      <div className="item-label text-[0.8rem] md:text-[0.9rem]">
        {replaceUnderscores(name)}:
      </div>
      <div className="text-[0.8rem] md:text-[0.9rem]">
        {displayValue(value)}
      </div>
    </div>
  );
};

export default Info;