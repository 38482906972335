import { Form } from "antd";
import CurrencyFormat from "react-currency-format";
import {
	setWalletFlag,
	toggleWithdrawalModal,
} from "redux/slices/dashboardSlice";
import { toggleCreateBankModal } from "redux/slices/settingsSlice";

import {
	useToolkit,
	CenterModal,
	CustomInputField,
	QwidButton,
	CustomSelector,
	useBeneficiary,
	Spinner,
	useDashboard,
} from "../../components";

type Props = {};

const WithdrawalModal = (props: Props) => {
	const {
		useAppSelector,
		dispatch,
		useRef,
		useEffect,
		useState,
		toastError,
		clearFlag,
		roundUp,
		getSymbol,
	} = useToolkit();

	const { isWithdrawalModalOpen, userWallets } = useAppSelector(
		(state) => state.dashboard
	);

	const [wallet, setWallet] = useState<any>();
	const [walletRef, setWalletRef] = useState<any>();
	const [amount, setAmount] = useState<number>();

	const {
		curBankData,
		curBankLoading,
		fetchUserCurrencyBanks,
		invalidAcc,
		initLoading,
		initiateWithdrawal,
		withdrawalBanks,
		useWithdrawalCharge,
		wdlFee,
	} = useBeneficiary();

	const { mutate: getCharges } = useWithdrawalCharge();

	const submitRef = useRef<HTMLButtonElement>(null);

	const onWalletChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = e.target.value;
		setWallet(value);
		const selected = userWallets?.find((item) => item?.cur === value);
		setWalletRef(selected);
		dispatch(setWalletFlag(value));
	};

	function onWithdraw(values: any) {
		const refAmount = walletRef?.balance;

		if (amount && amount > refAmount) {
			toastError("Amount cannot exceed wallet balance");
		} else if (amount && amount < 1) {
			toastError("Enter a valid amount");
		} else {
			const payload = {
				cur: values?.wallet,
				bankId: values?.bankId,
				amount,
				narration: values?.narration,
			};
			initiateWithdrawal(payload);
		}
	}

	useEffect(() => {
		fetchUserCurrencyBanks(walletRef?.cur);
	}, [walletRef?.cur]);

	useEffect(() => {
		clearFlag();
	}, []);

	useEffect(() => {
		if (wallet && amount && amount > 0) {
			const payload = { cur: wallet, amount };
			getCharges(payload);
		}
	}, [amount, wallet]);

	return (
		<CenterModal
			darkBg
			title="Withdrawals"
			action={
				!curBankData?.includes("No account found") ? (
					""
				) : (
					<QwidButton
						onClick={() => dispatch(toggleCreateBankModal())}
						actionType
						text="Add bank details"
					/>
				)
			}
			control={isWithdrawalModalOpen}
			onClose={() => dispatch(toggleWithdrawalModal())}>
			{initLoading ? (
				<Spinner />
			) : (
				<div className="w-full mt-4">
					<Form onFinish={onWithdraw} className="space-y-7">
						<CustomSelector isUserWallets onChange={onWalletChange} />
						<div className="w-full">
							<div className="form-custom-input-wrap">
								<label
									style={{
										fontSize: "11px",
									}}
									className="form-item__label">
									Amount to withdraw
								</label>
								<CurrencyFormat
									required
									placeholder="Enter amount"
									thousandSeparator={true}
									value={!Number(amount) ? "" : amount}
									onValueChange={({ floatValue }) => setAmount(floatValue)}
									className="form-custom-input"
								/>
								{walletRef?.cur?.length && (
									<div className="absolute right-0 text-right">
										<span className="text-[11px] nowrap text-[#194153]">
											Available balance: {walletRef?.cur}&nbsp;
											{roundUp(walletRef?.balance, 3)}
										</span>
										<br />
										{wdlFee?.value && (
											<span className="text-[11px] twide nowrap text-red-500">
												Fee is:&nbsp;
												<strong>
													{wdlFee?.mode === "percentage"
														? `${wdlFee?.value}%`
														: `${getSymbol(wallet)}${wdlFee?.value}`}
												</strong>
												, and we'll debit:&nbsp;
												<strong>
													{getSymbol(wallet)}
													{wdlFee?.toPay}
												</strong>
											</span>
										)}
									</div>
								)}
							</div>
						</div>

						<div style={{ marginTop: 45, marginBottom: 15 }}>
							<Form.Item
								rules={[
									{
										required: true,
										message: "Please select a destination account",
									},
								]}
								name="bankId">
								<CustomSelector
									itemLabel="Destination Account"
									required
									options={withdrawalBanks?.map((item: any) => ({
										title: `${item?.accountName} | ${item?.accountNo}`,
										value: item?.id,
									}))}
									firstOption={
										!walletRef?.cur
											? "Select a wallet first"
											: curBankLoading
											? "Fetching available accounts..."
											: "Select account"
									}
								/>
							</Form.Item>
						</div>

						<div>
							<CustomInputField
								maxLength={200}
								name="narration"
								placeholder="Enter narration"
								label="Narration"
							/>
						</div>

						<button className="hidden" ref={submitRef} type="submit">
							Submit
						</button>
					</Form>
					<div className="flex items-center justify-end space-x-3 mt-8 mb-2">
						<QwidButton
							onClick={() => dispatch(toggleWithdrawalModal())}
							transparent
							text={<span className="px-2">Cancel</span>}
						/>
						<QwidButton
							disabled={curBankLoading || invalidAcc}
							onClick={() => submitRef.current?.click()}
							text={<span className="px-4">Withdraw</span>}
						/>
					</div>
				</div>
			)}
		</CenterModal>
	);
};

export default WithdrawalModal;
