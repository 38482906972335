import { useFramer, useToolkit } from "../../..";
import { motion } from "framer-motion";

type Props = {};

const SecurityGuaranteed = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();
	const { PryText } = useToolkit();

	return (
		<div className="bg-[#2E6D88] text-white py-10 lg:py-12">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper">
				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-2/4 p-3 lg:pl-6">
					<p className="mb-0 med text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						Security Guaranteed{" "}
					</p>
					<p className="my-5 text-center lg:t6 md:text-left lg:w-3/4">
						With military-grade security encryption protocol, we guarantee safe
						money transfers, guarded well enough to put your mind at ease.
					</p>
				</motion.div>
				<motion.div
					variants={flashIn}
					className="rounded-lg w-full md:w-2/4 lg:w-2/4 h-[300px] md:h-[400px] flex items-start justify-center">
					<img
						alt="how-to-send"
						src="/assets/features/locked-funds.svg"
						className="object-cover lg:h-[350px]"
					/>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default SecurityGuaranteed;
