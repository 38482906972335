import { Form } from "antd";
import {
	CustomInputField,
	QwidButton,
	useToolkit,
	CustomSelector,
	Spinner,
} from "components";
import useUser from "utils/hooks/settings/useUser";

type BusinessInput = {
	label?: string | undefined;
	name?: string;
	placeholder?: string;
	required?: boolean;
	Component?: JSX.Element;
};

type Props = {
	PreviousButton: () => JSX.Element;
	countriesLoading: boolean;
	countriesData: {}[];
};

const dummyPH = "Enter value";

const OtherRelevantInfo = ({
	PreviousButton,
	countriesData,
	countriesLoading,
}: Props) => {
	const { useAppSelector } = useToolkit();
	const { isFirstTimeUser, businessDetails, profileDetails } = useAppSelector(
		(state) => state.settings
	);
	const { updateFirstTimeUserDetails, updLoading } = useUser();

	const inputItems: BusinessInput[] = [
		{
			label: "Purpose of using Qwid",
			name: "reason",
			placeholder: dummyPH,
		},
		{
			label: "Total Employees",
			name: "totalEmployees",
			placeholder: dummyPH,
		},
		{
			Component: (
				<CustomSelector
					isAnt
					required={false}
					customName="businessScale"
					customLabel="Business Scale (optional)"
					options={[
						{ title: "Small Scale", value: "Yes" },
						{ title: "Medium Scale", value: "Medium Scale" },
						{ title: "Large Scale", value: "Large Scale" },
					]}
				/>
			),
		},
		{
			label: "Monthly Estimated Volume",
			name: "monthlyVolume",
			placeholder: dummyPH,
		},
		{
			label: "Annual Turnover",
			name: "annualTurnover",
			placeholder: dummyPH,
		},
		{
			label: "Industry / Sector",
			name: "industrySector",
			required: false,
			placeholder: "Enter Industry",
		},
		{
			label: "Payment Flow Description",
			name: "paymentFlowDescription",
			placeholder: dummyPH,
		},
		{
			label: "Business Activity Description",
			name: "businessActivityDescription",
			placeholder: dummyPH,
		},
		{
			Component: (
				<CustomSelector
					customName="countryOfOperation"
					customLabel="Country of Operation"
					isAnt
					required={false}
					options={countriesData}
					placeholder={
						countriesLoading ? "Fetching countries..." : "Select Country"
					}
				/>
			),
		},
	];

	const onSubmit = (values: any) => {
		const payload = {
			...businessDetails,
			...profileDetails,
			...values,
		};
		updateFirstTimeUserDetails(payload);
	};

	return updLoading ? (
		<Spinner />
	) : (
		<Form onFinish={onSubmit}>
			<div className="w-full flex items-end justify-center md:justify-between flex-wrap">
				{inputItems?.map(
					({ label, name, placeholder, Component }: BusinessInput) =>
						Component ? (
							<div key={name || Math.random()} className="form-child3 mb-8">
								{Component}
							</div>
						) : (
							<div key={name || Math.random()} className="form-child3 mb-8">
								<CustomInputField
									required={isFirstTimeUser}
									label={label || ""}
									name={name}
									placeholder={placeholder || `Enter ${label}`}
								/>
							</div>
						)
				)}
			</div>
			<div className="mt-10 flex justify-end space-x-5">
				<PreviousButton />
				<QwidButton
					disabled={updLoading}
					type="submit"
					text={<span className="px-5">Save Details</span>}
					darkBlueBg
				/>
			</div>
		</Form>
	);
};

export default OtherRelevantInfo;
