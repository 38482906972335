import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CountryAmountProps = {
	country: string;
	amount: string;
};

export type PayoutInfoProps = {
	channel: string;
	option: string;
};

interface sendflowState {
	withdrawalInfo: {
		withdrawalCurrency: string;
		withdrawalCountry: string;
		withdrawalAmount: string;
		channel: string;
		option: string;
		isCreatingNewRecipient: boolean;
		savedAccounts: SavedAccountProps[];
		selectedRecipient: SavedAccountProps | null;
		transId: any;
		isBeneficiarySaved: boolean;
		dynamicFormValues: SavedAccountProps | any;
		isNewAccountWithdrawal: boolean;
	};
}

const initialState: sendflowState = {
	withdrawalInfo: {
		withdrawalCurrency: "",
		withdrawalCountry: "",
		withdrawalAmount: "",
		channel: "",
		option: "",
		isCreatingNewRecipient: false,
		savedAccounts: [],
		selectedRecipient: null,
		transId: null,
		isBeneficiarySaved: false,
		dynamicFormValues: {},
		isNewAccountWithdrawal: false,
	},
};

export const sendflowSlice = createSlice({
	name: "sendflow",
	initialState,
	reducers: {
		setWithdrawalCurrency: (state, { payload }: PayloadAction<string>) => {
			if (payload === "NGN") {
				return {
					...state,
					withdrawalInfo: {
						...state.withdrawalInfo,
						withdrawalCountry: "NG",
						withdrawalCurrency: payload,
					},
				};
			} else {
				return {
					...state,
					withdrawalInfo: {
						...state.withdrawalInfo,
						withdrawalCurrency: payload,
						withdrawalCountry: state.withdrawalInfo.withdrawalCountry,
					},
				};
			}
		},

		setWithdrawalCountryAmount: (
			state,
			{ payload }: PayloadAction<CountryAmountProps>
		) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					withdrawalCountry: payload.country,
					withdrawalAmount: payload.amount,
				},
			};
		},

		setDynamicFormValues: (state, { payload }: PayloadAction<any>) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					dynamicFormValues: payload,
				},
			};
		},

		setWithdrawalPayoutInfo: (
			state,
			{ payload }: PayloadAction<PayoutInfoProps>
		) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					channel: payload?.channel,
					option: payload?.option,
				},
			};
		},

		toggleWithdrawalRecipientStatus: (state) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					isCreatingNewRecipient: !state.withdrawalInfo.isCreatingNewRecipient,
				},
			};
		},

		createNewRecipient: (state) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					isCreatingNewRecipient: true,
				},
			};
		},

		saveUserBankAccounts: (
			state,
			{ payload }: PayloadAction<SavedAccountProps[]>
		) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					savedAccounts: payload,
				},
			};
		},

		setWithdrawalRecipient: (
			state,
			{ payload }: PayloadAction<SavedAccountProps | any>
		) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					withdrawalCountry: payload?.country,
					withdrawalCurrency: payload?.cur,
					selectedRecipient: payload,
				},
			};
		},

		setWithdrawalTransId: (state, { payload }: PayloadAction<string>) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					transId: payload,
				},
			};
		},

		toggleBeneficiarySaved: (state) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					isBeneficiarySaved: !state.withdrawalInfo.isBeneficiarySaved,
				},
			};
		},

		clearSendflowRecords: (state) => {
			return {
				...state,
				withdrawalInfo: {
					withdrawalCurrency: "",
					withdrawalCountry: "",
					withdrawalAmount: "",
					channel: "",
					option: "",
					isCreatingNewRecipient: false,
					savedAccounts: [],
					selectedRecipient: null,
					transId: null,
					isBeneficiarySaved: false,
					dynamicFormValues: {},
					isNewAccountWithdrawal: false,
				},
			};
		},


		withdrawToNewAccount: (state) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					isNewAccountWithdrawal: true,
				},
			};
		},

		cancelNewAccountWithdrawal: (state) => {
			return {
				...state,
				withdrawalInfo: {
					...state.withdrawalInfo,
					isNewAccountWithdrawal: false,
				},
			};
		},
	},
});

export const {
	setWithdrawalCurrency,
	clearSendflowRecords,
	setWithdrawalCountryAmount,
	setWithdrawalPayoutInfo,
	toggleWithdrawalRecipientStatus,
	saveUserBankAccounts,
	setWithdrawalRecipient,
	setWithdrawalTransId,
	toggleBeneficiarySaved,
	setDynamicFormValues,
	createNewRecipient,
	withdrawToNewAccount,
	cancelNewAccountWithdrawal
} = sendflowSlice.actions;

export default sendflowSlice.reducer;
