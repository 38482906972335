import Flag from 'components/common/Flag';
import {
  CenterModal,
  QwidButton,
  useToolkit,
  useTransactions,
} from 'components';
import { closeSingleTransaction } from 'redux/slices/transactionsSlice';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';

type Props = {
  isForWalletBalance?: boolean;
};

const DetailedTransactionView = ({ isForWalletBalance }: Props) => {
  const { useAppSelector, dispatch, avail, getSymbol, openPdfUrl } =
    useToolkit();
  const { isSingleTransOpen, selectedTransaction: trx } = useAppSelector(
    (state) => state.transactions
  );
  const {
    getTransType,
    formatTransStatus,
    getTransConfirmationStatus,
    formatTrxAmount,
  } = useTransactions();

  const validReason = trx?.reason?.length > 0;
  const validDestination = trx?.externalName?.length > 0;

  const amountWithCharges = Number(trx?.fromAmount) + Number(trx?.charges);
  const actualAmount = Number(trx?.fromAmount);

  return (
    <CenterModal
      title={
        <div className="flex items-center w-full justify-between tracking-normal">
          Transaction Details
        </div>
      }
      action={
        <IoMdClose
          onClick={() => dispatch(closeSingleTransaction())}
          className="text-[1.5rem]"
        />
      }
      control={isSingleTransOpen}
      onClose={() => dispatch(closeSingleTransaction())}
    >
      <div className="w-full">
        <div className="w-full">
          <p className="med twide mb-7">
            Transaction ID: {avail(trx?.transId)}{' '}
          </p>

          <Container>
            <div className="wrapper">
              <p className="mb-0 trans-key">Type</p>
              <p className="mb-0 trans-value">{getTransType(trx?.transType)}</p>
            </div>

            <div className="wrapper">
              <p className="mb-0 trans-key">Purpose</p>
              <p className="mb-0 trans-value pl-3 md:pl-8 text-right">
                {avail(trx?.purpose)}
              </p>
            </div>

            <div className="wrapper">
              <p className="mb-0 trans-key">Description</p>
              <p className="mb-0 trans-value flect text-right pl-3 md:pl-8">
                {avail(trx?.desc)}
              </p>
            </div>

            {validDestination && (
              <div className="wrapper">
                <p className="mb-0 trans-key">Destination</p>
                <p className="mb-0 trans-value flect text-right pl-3 md:pl-8">
                  {avail(trx?.externalName)}
                </p>
              </div>
            )}

            <div className="wrapper">
              <p className="mb-0 trans-key">Amount</p>
              <div className="flex items-center text-sm">
                {avail(getSymbol(trx?.fromCur))}&nbsp;
                <p className="mb-0 trans-value">
                  {formatTrxAmount(actualAmount)}
                </p>
                &nbsp;&nbsp;
                <Flag sqFlags currency={trx?.fromCur} />
              </div>
            </div>

            <div className="wrapper">
              <p className="mb-0 trans-key">Charges</p>
              <div className="flex items-center text-sm">
                {getSymbol(trx?.fromCur)}&nbsp;
                <p className="mb-0 trans-value">{avail(trx?.charges)}</p>
              </div>
            </div>

            <div className="wrapper">
              <p className="mb-0 trans-key">Channel</p>
              <div className="flex items-center text-sm">
                <p className="mb-0 trans-value">{avail(trx?.channel)}</p>
              </div>
            </div>

            <div className="wrapper">
              <p className="mb-0 trans-key">Total Amount</p>
              <div className="flex items-center text-sm">
                {avail(getSymbol(trx?.fromCur))}&nbsp;
                <p className="mb-0 trans-value">
                  {formatTrxAmount(amountWithCharges)}
                </p>
              </div>
            </div>

            <div className="wrapper">
              <p className="mb-0 trans-key">Payment Status</p>
              <div className="flex items-center text-sm">
                <div className="trans-value">
                  {getTransConfirmationStatus(trx?.confirmation)}
                </div>
              </div>
            </div>
            <div className="wrapper">
              <p className="mb-0 trans-key">Transaction Status</p>
              <div className="flex items-center text-sm">
                <div className="trans-value">
                  {formatTransStatus(trx?.status)}
                </div>
              </div>
            </div>

            {trx?.transRef && (
              <div className="wrapper">
                <p className="mb-0 trans-key">Transaction Reference</p>
                <p className="mb-0 trans-value flect text-right pl-3 md:pl-8">
                  {trx?.transRef}
                </p>
              </div>
            )}

            {validReason && (
              <div className="wrapper">
                <p className="mb-0 trans-key">Reason</p>
                <p className="mb-0 trans-value flect text-right pl-3 md:pl-8">
                  {avail(trx?.reason)}
                </p>
              </div>
            )}
          </Container>
        </div>

        <div className="flexed pb-3">
          <QwidButton
            onClick={() => openPdfUrl(trx?.transId)}
            text={<p className="mb-0 px-8">Download Receipt</p>}
          />
        </div>
      </div>
    </CenterModal>
  );
};

export default DetailedTransactionView;

const Container = styled.div`
  margin-bottom: 30px;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
  }

  .trans-key {
    color: #252d3e;
    font-weight: 500;
  }

  .trans-value {
    color: #62666e;
  }
`;
