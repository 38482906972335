import React from "react";
import BoardLayout from "./BoardLayout";

type Props = {
	children: React.ReactNode;
	title?: string | React.ReactNode;
	action?: string | React.ReactNode;
};

const NavlessBoardLayout = ({ children, title, action }: Props) => {
	return (
		<BoardLayout title={title} action={action} noSidebar>
			{children}
		</BoardLayout>
	);
};

export default NavlessBoardLayout;
