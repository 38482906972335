import "../styles/globals.css";
import type { AppProps } from "next/app";
import ProgressBar from "@badrap/bar-of-progress";
import { persistor, store } from "../src/redux/store";
import { motion, AnimatePresence } from "framer-motion";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import Router, { useRouter } from "next/router";
import { PersistGate } from "redux-persist/integration/react";
import { useTawk, useFramer } from "../src/components";
import { QueryClient, QueryClientProvider } from "react-query";

import TagManager, { TagManagerArgs } from "react-gtm-module";
import React, { useEffect } from "react";
import Notification from "components/notification/Notification";
import ZohoWidget from "utils/hooks/misc/useZoho";


const progress = new ProgressBar({
  size: 3,
  color: "#48BBED",
  className: "z-50",
  delay: 30,
});

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);

const queryClient = new QueryClient();

function MyApp({ Component, pageProps, router }: AppProps) {
  const { rootAnimationVars } = useFramer();
  const { Widget } = useTawk();

  const gtmID = process.env.NEXT_PUBLIC_GTAG_MGR_ID;
  const PIXEL_ID = process.env.NEXT_PUBLIC_FBOOK_PIXEL_ID;

  const isLocalEnv =
    process.env.NODE_ENV === "development" ||
    process.env.NEXT_PUBLIC_LBASE_URL?.includes("test");

  const tagManagerArgs: TagManagerArgs = {
    gtmId: gtmID || "",
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(PIXEL_ID || ""); // facebookPixelId
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
    // eslint-disable-next-line
  }, [router.events]);


  return (
    <ToastProvider autoDismiss autoDismissTimeout={3500} placement="top-center">
      <AnimatePresence>
        <div
          // key={router.route}
          // variants={rootAnimationVars}
          // initial="pageInitial"
          // animate="pageAnimate"
          // exit="pageExit"
          className="max-w-[2000px] mx-auto"
        >
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {!isLocalEnv && <ZohoWidget />}
                <Component {...pageProps} />
                <Notification />
                {/* <ProductUpdate data={productUpdateData} /> */}
              </PersistGate>
            </Provider>
          </QueryClientProvider>
        </div>
      </AnimatePresence>
    </ToastProvider>
  );
}

export default MyApp;
