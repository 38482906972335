import { QwidButton, useFramer, useToolkit } from "../../../components";
import { motion } from "framer-motion";
import { AUTH_ROUTES } from "utils/appData/appRoutes";

type Props = {};

const CheckoutPayments = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();
	const { router, logout, isValidUser } = useToolkit();

	return (
		<div className="bg-[#fafafa] py-8 lg:py-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper lg:items-start">
				<motion.div
					variants={flashIn}
					className="bg-[#e3f3fa] rounded-lg w-full md:w-2/4 lg:w-2/4 h-[350px] md:h-[450px] flex items-start justify-center relative">
					<div className="w-3/4 -bottom-1.5 mx-auto flex justify-center items-end h-full">
						<img
							alt="how-to-send"
							src="/assets/business/checkout.svg"
							className="object-cover lg:h-[350px]"
						/>
					</div>
				</motion.div>

				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-9 btn-text">CHECKOUT</p>
					<p className="mb-0 smb text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-snug">
						Collect payments in multiple currencies with online checkout
					</p>

					<p className="my-7 text-center lg:t6 lg:text-left">
						Take your business global by collecting payments in NGN, USD, GBP
						and EUR on your website or app, streamlining your checkout process
						and enhancing your customers' shopping experience.
					</p>

					<QwidButton
						text={<span className="px-4">Get started</span>}
						onClick={() => {
							if (isValidUser) {
								logout();
							}
							router.push(AUTH_ROUTES.SIGN_UP);
						}}
					/>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default CheckoutPayments;
