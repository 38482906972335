import { Input } from "antd";
import { CenterModal, useToolkit, QwidButton } from "components";
import React from "react";
import { toggleCardDetails } from "redux/slices/virtualsSlice";

type Props = {
	isCardSecModalOpen: boolean;
	selectedVCard: VirtualCard | null;
	toggleCardSecurity: () => void;
};

const CardSecurityModal = ({
	isCardSecModalOpen,
	toggleCardSecurity,
	selectedVCard: card,
}: Props) => {
	const { useState, toastError, dispatch } = useToolkit();
	const [password, setPassword] = useState<string>("");

	const cardId = card?.id;

	return (
		<CenterModal
			darkBg
			title={
				<span className="mb-1 twide med lg:text-lg">View Card Details</span>
			}
			control={isCardSecModalOpen}
			onClose={toggleCardSecurity}>
			<div className="w-full">
				<Input
					type="text"
					value={password}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setPassword(e.target.value)
					}
				/>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum sapiente
				ut facilis quam ducimus asperiores blanditiis consectetur dicta vero
				veniam unde dolore tempore aliquid iusto reiciendis fuga itaque, natus
				laboriosam!
				<div className="my-5 flexed">
					<QwidButton
						// onClick={revealDetails}
						text="Approve"
						disabled={!password.length}
					/>
				</div>
			</div>
		</CenterModal>
	);
};

export default CardSecurityModal;
