import { Col, Row } from "antd";
import { Spinner } from "components";

const ApiKeys = () => {
	return (
		<Row className="mt-20 ">
			<Col span={24} md={24}>
				<div className="w-full bg-[#EDEDED] rounded-md p-5 lg:p-12 mb-5 lg:mb-10">
					<Spinner msg="Coming soon" />
				</div>
			</Col>
		</Row>
	);
};

export default ApiKeys;
