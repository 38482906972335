import React from "react";

type Props = {};

const useFramer = () => {
	// page transitions
	const rootAnimationVars = {
		pageInitial: { scale: 0.7, opacity: 0 },
		pageAnimate: {
			scale: 1,
			opacity: 1,
			transition: {
				type: "spring",
				duration: 0.3,
			},
		},
		pageExit: {
			scale: 0.7,
			opacity: 0,
		},
	};

	// center modal - wrapper
	const modalWrapperVars = {
		initial: { opacity: 0, x: "-100vw" },
		animate: { opacity: 1, x: 0 },
	};

	// children
	const modalChildVars = {
		initial: { opacity: 0, y: "200px" },
		animate: {
			opacity: 1,
			y: 0,
			transition: {
				delay: 0.4,
				duration: 0.2,
			},
		},
	};

	// personal banner text

	const headerTxt = {
		hidden: {
			opacity: 0,
			x: "-100vw",
		},
		visible: {
			opacity: 1,
			x: 0,
			color: "rgba(255, 255, 255, 1)",
			transition: { type: "spring", stiffness: 50, duration: 1, delay: 1.1 },
		},
	};

	const empWrap = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: { staggerChildren: 0.5, delay: 0.8 },
		},
	};

	const empText = {
		hidden: {
			opacity: 0,
			y: "-400px",
		},
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				type: "spring",
				stiffness: 280,
				duration: 2.8,
			},
		},
	};

	// personal banner images
	const absWrap = {
		hidden: { opacity: 0, x: "-100vw" },
		visible: {
			opacity: 1,
			x: 0,
			transition: { staggerChildren: 0.5, delay: 1 },
		},
	};

	const absChildren = {
		hidden: { opacity: 0 },
		visible: { opacity: 1 },
	};

	const zoomWrap = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: { staggerChildren: 0.4, delay: 0.3 },
		},
	};

	const zoomContent = {
		hidden: { opacity: 0, zoom: "1%" },
		visible: {
			opacity: 1,
			zoom: "100%",
			transition: { duration: 1 },
		},
	};

	const pushUp = {
		hidden: { opacity: 0, y: "100vh" },
		visible: {
			opacity: 1,
			y: 0,
			transition: { type: "spring", stiffness: 300, duration: 1 },
		},
	};

	const pushDown = {
		hidden: { opacity: 0, y: "-100vh" },
		visible: {
			opacity: 1,
			y: 0,
			transition: { type: "spring", stiffness: 300, duration: 1 },
		},
	};

	const dropdown = {
		hidden: { opacity: 0, y: -200 },
		visible: {
			opacity: 1,
			y: 0,
			transition: { type: "spring", stiffness: 200, duration: 1 },
		},
	};

	const pushLeft = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: { type: "spring", stiffness: 40, duration: 0.3 },
		},
	};

	const flashIn = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: { type: "spring", stiffness: 40, duration: 0.3 },
		},
	};

	const pushRight = {
		hidden: { opacity: 0, y: "-100vh" },
		visible: {
			opacity: 1,
			y: 0,
			transition: { type: "spring", stiffness: 300, duration: 0.3 },
		},
	};

	return {
		rootAnimationVars,
		modalWrapperVars,
		modalChildVars,

		headerTxt,
		empWrap,
		empText,

		dropdown,

		absWrap,
		absChildren,

		// LP sections
		zoomContent,
		zoomWrap,
		pushUp,
		pushDown,
		pushLeft,
		flashIn,
	};
};

export default useFramer;
