import { QwidButton, useFramer, useToolkit } from "../../..";
import { motion } from "framer-motion";
import { AUTH_ROUTES } from "utils/appData/appRoutes";

type Props = {
	title: string;
	subtitle: string;
};

const DynamicSpiralPhone = ({ title, subtitle }: Props) => {
	const { zoomWrap, flashIn } = useFramer();
	const { router } = useToolkit();

	return (
		<div
			style={{
				background: "rgba(72, 187, 237, 0.02)",
			}}
			className="py-10 lg:py-12">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper">
				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-2/4 p-3 lg:pl-6">
					<p className="mb-0 smb text-center mx-auto md:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						{title}
					</p>
					<p className="my-5 text-center mb-10 lg:t6 md:text-left lg:w-3/5">
						{subtitle}
					</p>
					<QwidButton
						text={<span className="px-5">Get started</span>}
						onClick={() => router.push(AUTH_ROUTES.SIGN_UP)}
					/>
				</motion.div>
				<motion.div
					variants={flashIn}
					className="rounded-lg pt-10 lg:mt-0 w-full md:w-2/4 lg:w-2/4 flexed">
					<img
						alt="how-to-send"
						src="/assets/features/spiral-phone-2.png"
						className="object-cover "
					/>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default DynamicSpiralPhone;
