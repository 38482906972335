import { AiFillCaretDown } from "react-icons/ai";
import { Form, Select as SelectEl } from "antd";
import Select from "react-select";
import { Select as AntSelect } from "antd";
import { useToolkit, useDashboard } from "../../components";
import styled from "styled-components";

type Props = {
	hasImgs?: boolean;
	label?: string;
	header?: string;
	subtitle?: string;
	placeholder?: any;
	isUserWallets?: boolean;
	isUserWallets2?: boolean;
	isSearchable?: boolean;
	isSearchable2?: boolean;
	isMapped?: boolean;
	noArrow?: boolean;
	isAnt?: boolean;
	required?: boolean;
	disabled?: boolean;
	paddedLeft?: boolean;
	noCurrencyFlag?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	options?: DropdownProps[];
	customDefaultValue?: DropdownProps;
	className?: string;
	trailText?: string;
	itemName?: string;
	itemLabel?: string;
	customLabel?: string;
	firstOption?: string | number;
	customName?: string;
	defaultValue?: string;
	flgCur?: string;
	value?: any;
	hasDefault?: boolean;
	filterFn?: any;
	customFirstOption?: boolean;
	onCustomChange?: (selectedOption: any) => void;
	ignoreFirstOption?: boolean;
	errorMessageRequired?: string;
	isTestFirstOption?: boolean;
	firstOptionValue?: any;
	filterUserWallet?: boolean;
	loading?: boolean;
};

const CustomSelector = ({
	label,
	isUserWallets,
	isUserWallets2,
	onChange,
	options,
	className,
	trailText,
	itemName,
	itemLabel,
	firstOption,
	isSearchable,
	isSearchable2,
	noArrow,
	placeholder,
	customLabel,
	onCustomChange,
	customName,
	isMapped,
	value,
	isAnt,
	required,
	noCurrencyFlag,
	paddedLeft,
	filterFn,
	customFirstOption,
	header,
	subtitle,
	defaultValue,
	customDefaultValue,
	hasDefault,
	disabled,
	ignoreFirstOption,
	errorMessageRequired,
	isTestFirstOption,
	firstOptionValue,
	filterUserWallet,
	loading,
}: Props) => {
	const { useAppSelector, useEffect } = useToolkit();
	const { fetchUserWallets, walletsLoading } = useDashboard();
	const { userWallets, walletFlag: flag } = useAppSelector(
		(state) => state.dashboard
	);

	const isNgn = flag === "NGN";
	const isUsd = flag === "USD";
	const isGbp = flag === "GBP";
	const isEur = flag === "EUR";

	const Nig = "/assets/icons/wallet/nig-sq.svg";
	const Us = "/assets/icons/wallet/usd-sq.svg";
	const Gb = "/assets/icons/wallet/gbp-sq.svg";
	const Eu = "/assets/icons/wallet/eur-sq.svg";

	useEffect(() => {
		if (isUserWallets || isUserWallets2) {
			fetchUserWallets();
		}
	}, [isUserWallets, isUserWallets2]);
	const filterCustomFunction = ({ fiterData, dataToExclude }: any) => {
		return fiterData.filter(
			(data: any) => data.cur?.toString() !== dataToExclude?.toString()
		);
	};

	return isAnt ? (
		<div className={className || "w-full relative"}>
			{customLabel && <span className="form-custom-label">{customLabel}</span>}
			<Form.Item
				name={customName || itemName}
				rules={[{ required: required, message: "Required" }]}>
				<StyledAntSelect
					showSearch
					suffixIcon={<AiFillCaretDown className="text-[#79747E] t4" />}
					optionFilterProp="children"
					size="large"
					filterOption={() => {
						if (filterFn) {
							return filterFn();
						} else {
							return (input: any, option: any) =>
								(option!.children as unknown as string)
									?.toLowerCase()
									?.includes(input.toLowerCase());
						}
					}}
					placeholder={placeholder || "Select"}
					onChange={onCustomChange}
					style={{ width: "100%", paddingLeft: paddedLeft ? "48px" : "" }}>
					{options?.length == 0 ? (
						<AntSelect.Option value="" disabled>
							No Data Found
						</AntSelect.Option>
					) : (
						options?.map(({ title, value, disabled }) => (
							<AntSelect.Option value={value} key={value} disabled={disabled}>
								{title}
							</AntSelect.Option>
						))
					)}
				</StyledAntSelect>
			</Form.Item>
		</div>
	) : hasDefault ? (
		<div className={className || "w-full relative"}>
			{customLabel && <span className="form-custom-label">{customLabel}</span>}
			<Form.Item
				name={customName || itemName}
				rules={[{ required: required, message: "Required" }]}>
				<StyledAntSelect
					suffixIcon={<AiFillCaretDown className="text-[#79747E] t4" />}
					optionFilterProp="children"
					defaultValue={value}
					size="large"
					filterOption={() => {
						if (filterFn) {
							return filterFn();
						} else {
							return (input: any, option: any) =>
								(option!.children as unknown as string)
									?.toLowerCase()
									?.includes(input.toLowerCase());
						}
					}}
					placeholder={placeholder || "Select"}
					onChange={onCustomChange}
					style={{ width: "100%", paddingLeft: paddedLeft ? "48px" : "" }}>
					{options?.length == 0 ? (
						<AntSelect.Option value="" disabled>
							No Data Found
						</AntSelect.Option>
					) : (
						options?.map(({ title, value, disabled }) => (
							<AntSelect.Option value={value} key={value} disabled={disabled}>
								{title}
							</AntSelect.Option>
						))
					)}
				</StyledAntSelect>
			</Form.Item>
		</div>
	) : isSearchable ? (
		<div className={className || "w-full relative"}>
			{!noArrow && <AiFillCaretDown className="form-sel__icon" />}
			{label && <span className="form-sel__label ">{label}</span>}
			{customLabel && <span className="form-custom-label">{customLabel}</span>}
			<Form.Item
				className="z-50"
				name={customName || itemName}
				rules={[{ required: true, message: "Required" }]}>
				<Select
					minMenuHeight={500}
					defaultValue={customDefaultValue}
					className="form-custom-select react-select"
					placeholder={placeholder}
					options={options}
					onChange={onCustomChange}
				/>
			</Form.Item>
		</div>
	) : isSearchable2 ? (
		<div className={className || "w-full relative"}>
			{!noArrow && <AiFillCaretDown className="form-sel__icon z-[101]" />}
			{label && <span className="form-sel__label z-[600]  ">{label}</span>}
			{customLabel && <span className="form-custom-label">{customLabel}</span>}
			<Form.Item
				className="z-50"
				name={customName || itemName}
				rules={[{ required: true, message: "Required" }]}>
				<Select
					components={{
						DropdownIndicator: () => null,
						IndicatorSeparator: () => null,
					}}
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							border: "0px",
							height: "2.75rem",
							cursor: "pointer",
							borderColor: "transparent",
							outline: "none",
							boxShadow: "0px",
						}),
					}}
					isLoading={loading}
					minMenuHeight={500}
					defaultValue={customDefaultValue}
					className="form-sel__wrap z-[100] p-0 "
					placeholder={placeholder}
					options={options}
					onChange={onCustomChange}
				/>
			</Form.Item>
		</div>
	) : isUserWallets ? (
		<div className={className || "w-full relative"}>
			<AiFillCaretDown className="form-sel__icon" />
			<span className="form-sel__label">{header || "Wallet"}</span>
			{!noCurrencyFlag && (
				<img
					width={35}
					src={isNgn ? Nig : isUsd ? Us : isGbp ? Gb : isEur ? Eu : ""}
					alt=""
					className="absolute top-[20px] z-50 left-4"
				/>
			)}
			<Form.Item
				name="wallet"
				rules={[{ required: true, message: "Required" }]}>
				<select
					disabled={disabled}
					onChange={onChange}
					className={`form-sel__wrap ${!noCurrencyFlag && "pl-16"} ${
						disabled && "cursor-not-allowed"
					}`}>
					<option value="">
						{firstOption
							? firstOption
							: walletsLoading
							? "Fetching..."
							: subtitle || "Select Wallet"}
					</option>
					{userWallets?.map(({ cur }) => (
						<option value={cur} key={cur}>
							{cur} {trailText || ""}
						</option>
					))}
				</select>
			</Form.Item>
		</div>
	) : isUserWallets2 ? (
		<div className={className || "w-full relative"}>
			<AiFillCaretDown className="form-sel__icon" />
			<span className="form-sel__label">{header || "Wallet"}</span>
			{!noCurrencyFlag && (
				<img
					width={35}
					src={isNgn ? Nig : isUsd ? Us : isGbp ? Gb : isEur ? Eu : ""}
					alt=""
					className="absolute top-[22px] z-50 left-4"
				/>
			)}
			<Form.Item
				name="wallet"
				rules={[{ required: required, message: "Required" }]}>
				<select
					disabled={disabled}
					defaultValue={firstOption}
					onChange={onChange}
					className={`form-sel__wrap ${
						!!(isNgn ? Nig : isUsd ? Us : isGbp ? Gb : isEur ? Eu : false) &&
						"pl-16"
					} ${disabled && "cursor-not-allowed"}`}>
					<option value={firstOption}>
						{firstOption
							? firstOption
							: walletsLoading
							? "Fetching..."
							: subtitle || "Select Wallet"}
					</option>
					{filterUserWallet
						? filterCustomFunction({
								fiterData: userWallets,
								dataToExclude: firstOption,
						  }).map(({ cur }: any) => (
								<option value={cur} key={cur}>
									{cur} {trailText || ""}
								</option>
						  ))
						: userWallets?.map(({ cur }) => (
								<option value={cur} key={cur}>
									{cur} {trailText || ""}
								</option>
						  ))}
				</select>
			</Form.Item>
		</div>
	) : isMapped ? (
		<div className={className || "w-full relative"}>
			<AiFillCaretDown className="form-sel__icon" />
			<span className="form-sel__label">{itemLabel}</span>
			<Form.Item
				name={itemName}
				rules={[{ required: true, message: "Required" }]}>
				<select
					disabled={disabled}
					onChange={onChange}
					className="form-sel__wrap">
					<option value="">{firstOption}</option>
					{options?.length == 0 ? (
						<option value="" disabled>
							No Data Found
						</option>
					) : (
						options?.map(({ title, value, disabled }) => (
							<option value={value} key={value} disabled={disabled}>
								{title}
							</option>
						))
					)}
				</select>
			</Form.Item>
		</div>
	) : customFirstOption ? (
		<div className={className || "w-full relative"}>
			<AiFillCaretDown className="form-sel__icon" />
			<span className="form-sel__label">{itemLabel}</span>
			<Form.Item
				name={itemName}
				rules={[{ required: required, message: "Required" }]}>
				<select
					disabled={disabled}
					defaultValue={firstOption}
					onChange={onChange}
					className={`form-sel__wrap ${paddedLeft && "pl-12"}`}>
					{options?.length == 0 ? (
						<option value="" disabled>
							No Data Found
						</option>
					) : (
						options?.map(({ title, value, disabled }) => (
							<option value={value} key={value} disabled={disabled}>
								{title} {trailText || ""}
							</option>
						))
					)}
				</select>
			</Form.Item>
		</div>
	) : isTestFirstOption ? (
		<div
			className={
				className || `w-full relative ${disabled ? "cursor-not-allowed" : ""} `
			}>
			<AiFillCaretDown className="form-sel__icon" />
			<span className="form-sel__label">{itemLabel}</span>
			<Form.Item
				name={itemName}
				rules={[
					{ required: required, message: errorMessageRequired || "Required" },
				]}>
				<select
					value={firstOptionValue}
					disabled={disabled}
					onChange={onChange}
					className={`form-sel__wrap ${disabled ? "cursor-not-allowed" : ""} ${
						paddedLeft && "pl-12"
					}`}>
					<option value={firstOptionValue ? firstOptionValue : ""}>
						{firstOption}
					</option>
					{options?.length == 0 ? (
						<option value="" disabled>
							No Data Found
						</option>
					) : (
						options?.map(({ title, value, disabled }) => (
							<option value={value} key={value} disabled={disabled}>
								{title} {trailText || ""}
							</option>
						))
					)}
				</select>
			</Form.Item>
		</div>
	) : (
		<div
			className={
				className || `w-full relative ${disabled ? "cursor-not-allowed" : ""} `
			}>
			<AiFillCaretDown className="form-sel__icon" />
			<span className="form-sel__label">{itemLabel}</span>
			<Form.Item
				name={itemName}
				rules={[
					{ required: required, message: errorMessageRequired || "Required" },
				]}>
				<select
					value={defaultValue}
					disabled={disabled}
					onChange={onChange}
					className={`form-sel__wrap  ${disabled ? "cursor-not-allowed" : ""} ${
						paddedLeft && "pl-12"
					}`}>
					{!ignoreFirstOption && <option value="">{firstOption}</option>}
					{options?.length == 0 ? (
						<option value="" disabled>
							No Data Found
						</option>
					) : (
						options?.map(({ title, value, disabled, selected }) => (
							<option
								value={value}
								key={value}
								disabled={disabled}
								selected={selected}>
								{title} {trailText || ""}
							</option>
						))
					)}
				</select>
			</Form.Item>
		</div>
	);
};

export default CustomSelector;

const StyledAntSelect = styled(AntSelect)`
	font-size: 13px !important;

	/* margin-bottom: 12px; */
	outline-width: 0 !important;
	border: 1px solid #194153 !important;
	border-radius: 2px;

	&.ant-select-focused:not(.ant-select-disabled).ant-select:not(
			.ant-select-customize-input
		)
		.ant-select-selector {
		border-color: none !important;
		outline: 0 !important;
		box-shadow: none !important;
	}

	&.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border: none;
	}
`;
