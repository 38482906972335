import { motion } from "framer-motion";
import useFramer from "utils/animations/useFramer";
import useToolkit from "utils/hooks/misc/useToolkit";

const VirtualCardsBanner = () => {
	const { isMobile } = useToolkit();
	const { headerTxt, empWrap, empText } = useFramer();

	return (
		<div className="flex items-center flex-wrap lg:flex-nowrap h-full lg:pt-20 pb-10 lg:pb-0">
			<div className="w-full lg:w-2/4 mx-auto h-fit lg:h-full">
				<motion.p
					variants={headerTxt}
					initial="hidden"
					animate="visible"
					style={{ lineHeight: isMobile ? "40px" : "70px" }}
					className="mb-2 pt-4 lg:pt-0 smb text-center lg:text-left twite tracking-wide text-3xl w-5/6 lg:w-full lg:text-6xl mx-auto">
					Smarter Payments With Qwid Virtual Cards
				</motion.p>
				<motion.div
					variants={empWrap}
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					className="w-full">
					<motion.p
						variants={empText}
						className="twite text-center lg:text-left t6 mt-5 mb-5 lg:mb-10 tracking-wide lg:w-4/5 lg:pr-8">
						Our virtual cards are designed to help you make smart payments.
						Now you can spend online & make payments to Google & Apple Pay
					</motion.p>
				</motion.div>
			</div>

			{/* screenshot wrapper */}
			<div className="w-full lg:w-2/4 mx-auto flex items-center lg:items-start justify-center lg:pt-16 lg:h-full">
				<img
					src="/assets/features/virtual-card.png"
					alt="screenshot"
					className="w-[200px] lg:w-[380px] object-cover"
				/>
			</div>
		</div>
	);
};

export default VirtualCardsBanner;
