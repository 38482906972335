import { motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import {
	clearRemittanceDetails,
	setCurrentStep,
	setRemittanceDetails,
	switchRemittancePage,
	toggleQwidTransfer,
	togglePinAuth,
	toggleSendOptions,
} from "redux/slices/dashboardSlice";
import useFramer from "utils/animations/useFramer";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";
import useToolkit from "utils/hooks/misc/useToolkit";
import { setCreatePinNavigator } from "redux/slices/dashboardSlice";

type Props = {
	isInline?: boolean;
};

const SendFundOptions = ({ isInline }: Props) => {
	const { dropdown } = useFramer();
	const { router, dispatch, useAppSelector, toastInfo } = useToolkit();
	const { user, isUserPinSet } = useAppSelector((state) => state.user);
	const trxPinExists = user?.transactionPin || isUserPinSet;

	const previewItems = [
		{
			title: "Remittance",
			onClick: async () => {
				// if(!trxPinExists){
				// 	const payload: createPinNavigatorProps ={
				// 		backRoute: DASHBOARD_ROUTES.DASHBOARD,
				// 		redirectRoute: DASHBOARD_ROUTES.SEND_BY_REMITTANCE
				// 	}
				// 	toastInfo('PIN setup required')
				// 	dispatch(setCreatePinNavigator(payload))
				// 	setTimeout(()=>router.push(DASHBOARD_ROUTES.CREATE_TRX_PIN), 1000)
				// }
				// else{

				dispatch(setCurrentStep(0));
				dispatch(clearRemittanceDetails());
				dispatch(switchRemittancePage("stepFields"));
				router.push(DASHBOARD_ROUTES.SEND_BY_REMITTANCE);
				// }
			},
			subtitle: "Send funds to beneficiaries across borders",
		},

		{
			title: "Qwid Internal Transfer",
			subtitle: "Send money to friends and family using qwid",
			onClick: () => dispatch(toggleQwidTransfer()),
		},
	];

	return (
		<motion.div
			variants={dropdown}
			initial="hidden"
			animate="visible"
			className={`w-full lg:w-[40%] mb-7 p-4 pb-9 bwite shadow-xl rounded-md ${
				isInline && "absolute top-3 left-3 z-20"
			}`}>
			<div className="w-full flex-btw">
				<p className="med twide text-lg lg:text-xl med my-3">Select a method</p>
				{isInline && (
					<IoMdClose
						className="text-2xl curp"
						onClick={() => dispatch(toggleSendOptions())}
					/>
				)}
			</div>
			<div className="flexed flex-col space-y-5">
				{previewItems.map(({ title, subtitle, onClick }) => (
					<div
						onClick={() => {
							dispatch(toggleSendOptions());
							onClick();
						}}
						key={title}
						className="py-3 px-5 bgrn w-full rounded-md border border-[#252D3E] curp hover:shadow-md trall">
						<p className="mb-1 dpgry-txt lg:text-lg med">{title}</p>
						<p className="text-sm text-[#7c7c7c] mb-0">{subtitle}</p>
					</div>
				))}
			</div>
		</motion.div>
	);
};

export default SendFundOptions;
