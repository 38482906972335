import { QwidButton, useFramer, useToolkit } from "../../../components";
import { motion } from "framer-motion";
import { AUTH_ROUTES } from "utils/appData/appRoutes";

type Props = {};

const SendToHundreds = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();
	const { router, logout, isValidUser } = useToolkit();

	return (
		<div className="bg-[#fafafa] py-8 lg:py-16 lg:pt-24">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper lg:relative">
				<motion.div variants={flashIn} className="w-full md:w-2/4 lg:w-2/5 p-3">
					<p className="mb-0 smb text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-5xl lg:leading-[1.1]">
						Send money to 100+ countries
					</p>

					<p className="my-7 text-center lg:t6 lg:text-left">
						Initiate direct payouts from your qwid wallet to other qwid accounts
						and bank accounts worldwide
					</p>

					<div className="flex items-center justify-center lg:justify-start">
						<QwidButton
							text={<span className="px-4">Get started</span>}
							onClick={() => {
								if (isValidUser) {
									logout();
								}
								router.push(AUTH_ROUTES.SIGN_UP);
							}}
						/>
					</div>
				</motion.div>

				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-3/5 lg:absolute lg:right-8 flex items-center">
					<img
						alt="how-to-send"
						src="/assets/business/send-money-map2.png"
						className="object-cover"
					/>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default SendToHundreds;
