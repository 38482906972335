import Flag from "components/common/Flag";
import { toggleInitVac } from "redux/slices/virtualsSlice";
import {
	useToolkit,
	CenterModal,
	QwidButton,
	useVirtualAccounts,
	Spinner,
} from "../../components";

type Props = {};

const InitVacRequestModal = (props: Props) => {
	const { useAppSelector, dispatch, getSymbol, useState } = useToolkit();
	const { isInitVacOpen } = useAppSelector((state) => state.virtuals);
	const {
		getAvailableVirtualAccts,
		acctsData,
		acctsLoading,
		limitReached,
		requestVirtualAcct,
	} = useVirtualAccounts();

	const [selected, setSelected] = useState("");

	const InitialUI = () => {
		return (
			<div>
				<h4 className="tcent text-[#252d3e]">
					SETUP AN INTERNATIONAL VIRTUAL <br /> BANK ACCOUNT
				</h4>
				<div className="mt-10 mb-7 flexed">
					<img src="/assets/dashboard/init-vac.svg" alt="initialize-vac" />
				</div>
				<p className="text-sm tcent mb-8">
					This works just like any regular bank account; you will get all
					relevant details which would enable you transact with international
					currencies.
				</p>
				<div className="flexed">
					<QwidButton
						onClick={() => getAvailableVirtualAccts()}
						text={<span className="px-3 md:px-7 mb-0">Continue</span>}
					/>
				</div>
			</div>
		);
	};

	const LimitReachedUI = () => (
		<div>
			<h4 className="tcent text-[#252d3e]">SETUP VIRTUAL BANK ACCOUNT</h4>
			<div className="mb-7 flexed">
				<img src="/assets/dashboard/empty-vac.svg" alt="empty-vac" />
			</div>
			<p className="text-sm tcent mb-8">
				You have reached the maximum accounts available at this time
			</p>
		</div>
	);

	return (
		<CenterModal
			control={isInitVacOpen}
			onClose={() => dispatch(toggleInitVac())}>
			<div>
				{acctsLoading ? (
					<Spinner />
				) : limitReached ? (
					<LimitReachedUI />
				) : acctsData?.length > 0 ? (
					<div className="flexed flex-col w-3/4 lg:w-full mx-auto">
						{acctsData?.map((acct: any) => (
							<div
								key={acct?.cur}
								onClick={() => setSelected(acct?.cur)}
								className={`w-full flex-btw mb-5 border curp px-2 py-2.5 rounded-lg trall hover:shadow-lg hover:scale-105 ${
									acct?.cur === selected
										? "border-2 border-[#41A8D5]"
										: "border-[#888888]"
								}`}>
								<div className="flex items-center">
									<Flag currency={acct?.cur} />
									<h4 className="mb-0 ml-4 text-[#0D0D0D]">
										{acct?.cur} Account
									</h4>
								</div>
								<p className="bg-[#194153] mb-0 text-white text-[10px] px-2.5 py-1.5 rounded-lg">
									Fee: {getSymbol(acct?.cur)}&nbsp;{acct?.amount || 0}
								</p>
							</div>
						))}

						<div className="flexed mt-3">
							<QwidButton
								disabled={!selected?.length}
								onClick={() => requestVirtualAcct(selected)}
								text={<span className="px-3 md:px-8">Request Account</span>}
							/>
						</div>
					</div>
				) : (
					<InitialUI />
				)}
			</div>
		</CenterModal>
	);
};

export default InitVacRequestModal;
