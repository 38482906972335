import CenterModal from 'components/misc/CenterModal'
import React, { useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import PinSet from 'components/common/InputElements/PinSet';
import QwidButton from 'components/misc/QwidButton';
import useToolkit from 'utils/hooks/misc/useToolkit';


interface PinSetupModalProps{
    controller: boolean;
    onClose?: ()=>void;
    actuatorFxn?: (pin: number | string) => void;
}
function PinSetupModal({controller, onClose, actuatorFxn}: PinSetupModalProps) {
    const {toastError} = useToolkit()
    const [pin, setPin] = useState("");
	const [pinConfirm, setPinConfirm] = useState("");
	const [confirm, setConfirm] = useState(false);
  return (
    <CenterModal
        isCustom
        control={controller}
        onClose={()=>{
			setPin("")
			setConfirm(false)
			setPinConfirm("")
			onClose && onClose()
		}}
        className='w-full md:w-2/4 p-10 lg:w-[45%] max-w-[36rem] md:mx-auto'

    >
				<div className=' md:px-[1rem]'>
                <div>
						<img src="/assets/dashboard/lock-img.svg" alt="lock" width={90} />

						<div className="my-5">
							<h5 className="lg:text-lg mb-3">Set up Transaction PIN</h5>
							{confirm
							? <motion.p
								initial={{x: '-10%', opacity: 0}}
								animate={{x: '0', opacity: 1}}
								exit={{x: '100%', opacity: 0}}
								transition={{duration: 0.2, ease: 'easeIn'}}
							 className="twide flex items-center text-[#62666e] mb-0 text-sm">
								Please confirm your PIN</motion.p>
							:<p className="twide text-[#62666e] mb-0 text-sm">
								You will use this PIN to authorize transactions on both the web
								and mobile apps
							</p>}
						</div>

						<div className="flex flex-col w-full m-auto max-w-[20rem] pt-6 my-7">
							
							{confirm
							? <motion.div
							initial={{x: '-20%', opacity: 0}}
							animate={{x: '0', opacity: 1}}
							transition={{duration: 0.3, ease: 'easeIn'}}>
								<PinSet value={pinConfirm} valueSetter={setPinConfirm} />
							</motion.div>
							: <PinSet value={pin} valueSetter={setPin} />}

							
						</div>
					</div>
					<div className="w-full flexed">
						{!confirm
						?<QwidButton 
                        darkBlueBg
						onClick={() => setConfirm(true)}
						text="Continue"
						isFull
						disabled={pin?.toString().length < 4}
					/>
					: <div className="w-full flex flex-col gap-2">
						<QwidButton
					onClick={() => {
						if(pin === pinConfirm){
							 actuatorFxn && actuatorFxn(pin)
							 setPin("")
							setConfirm(false)
							setPinConfirm("")
							 /* the function to create pin can be found in useAuth() */
						}
						else{
							toastError('Pin does not match')
						}
					}}
					text="Set PIN"
                    darkBlueBg
					isFull
					disabled={pinConfirm?.length < 4}
				/>
				<span onClick={()=> {
					setPinConfirm("")
					setConfirm(false)}} className="font-semibold w-full text-center text-[#2B708E] cursor-pointer  mt-4" >
					Back
					</span>
					</div>
				}
					
					</div>
                </div>
				
    </CenterModal>
  )
}

export default PinSetupModal