import React from "react";
import { Carousel } from "antd";
import { RiDoubleQuotesR } from "react-icons/ri";

type Props = {};

const Testimonials = (props: Props) => {
	const slideData: SlideProps[] = [
		{
			message:
				"Qwid gives this rare kind of freedom. I can make payments to clients in several countries without having to worry about how it will get to them. I feel limitless.",
			name: "Kingsley Eze",
			job: "Importer",
			imgPath: "/assets/landing/review-img-0.png",
		},
		{
			message:
				"I have searched for a reliable cross-border payment platform and Qwid was just the solution I needed. The speed and success rate has made me love it.",
			name: "Queen Eddy",
			job: "Vendor",
			imgPath: "/assets/landing/review-img1.png",
		},
		{
			message:
				"As a freelancer, receiving payments is sometimes an issue but with Qwid, I no longer worry about where my clients are or how they will pay. Qwid ensures I get my money right in my bank account.",
			name: "Toyin Adams",
			job: "Freelancer",
			imgPath: "/assets/landing/review-img2.png",
		},
		{
			message:
				"It was love at first sight with Qwid. The simple interface and ease makes me appreciate it and see it as a simple but very efficient way to make and receive payments.",
			name: "Samuel Kabari",
			job: "Student",
			imgPath: "/assets/landing/review-img3.png",
		},
	];

	type SlideProps = {
		message: string;
		name: string;
		job: string;
		imgPath: string;
	};

	const Slide = ({ message, name, job, imgPath }: SlideProps) => {
		return (
			<div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center w-full flex-wrap lg:flex-nowrap pb-8 lg:pb-16">
				<div className="w-full lg:w-auto">
					<img src={imgPath} alt="review" />
				</div>
				<div className="w-3/4 lg:w-4/5 lg:pl-6 text-center lg:text-left">
					<p className="mb-3 lg:mb-10 t6">{message}</p>
					<p className="mb-5 lg:mb-3 smb tracking-wide text-xl lg:text-4xl">
						{name}
					</p>
					<p className="mb-4 lg:mb-0 text-lg">{job}</p>
				</div>
			</div>
		);
	};

	return (
		<div className="bg-[#F6F6FB] lg:bg-[#A9E1F980] h-pad flex lg:justify-end items-center lg:py-20">
			{/* Slider for PC */}

			<div className="bg-[#F6F6FB] lg:h-[550px] w-full md:w-7/12 md:pl-16 hidden lg:flexed lg:relative">
				<div className="hidden lg:flexed h-14 w-14 rounded-full shadow-md pry-bg absolute left-[130px] top-28 z-10">
					<RiDoubleQuotesR className="text-2xl text-white" />
				</div>
				<div className="lg:absolute lg:-left-[250px] lg:w-full">
					<Carousel dots={false} autoplay autoplaySpeed={5000}>
						{slideData.map(({ message, name, job, imgPath }) => (
							<Slide
								key={name}
								message={message}
								name={name}
								job={job}
								imgPath={imgPath}
							/>
						))}
					</Carousel>
				</div>
			</div>

			{/* Slider for mobile */}

			<div className="w-full lg:hidden">
				<Carousel dots={false} autoplay autoplaySpeed={3000}>
					{slideData.map(({ message, name, job, imgPath }) => (
						<Slide
							key={name}
							message={message}
							name={name}
							job={job}
							imgPath={imgPath}
						/>
					))}
				</Carousel>
			</div>
		</div>
	);
};

export default Testimonials;
