import { useFramer } from "../../../../components";
import { motion } from "framer-motion";
import Image from "next/image";

type Props = {};

const Exchange = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();

	return (
		<div className=" flex flex-col relative w-[100%]  bg-[#EFF6FE] lg:flex-row  md:flex-row  sm:flex-col">
			<div className="overflow-auto ">
				<Image
					className="overflow-auto"
					src={"/assets/features/morph-bg.png"}
					alt="bg"
					layout="fill"
					priority
					objectFit="cover"
				/>
			</div>
			<div className="flex-1 flex  items-center justify-center">
				<div className="md:w-[80%] p-10 md:p-0 flex flex-col items-start justify-start lg:[60%] w-[100%]">
					<p className="  font-semibold tracking-wide mb-0 mt-5 lg:mt-0 smb text-center mx-auto relative lg:text-left text-2xl w-full lg: md:text-3xl lg:text-4xl lg:leading-normal ">
						Giving More Power To Your Money With{" "}
						<span className="text-[#48bbed]">Currency Exchange</span>{" "}
					</p>
					<p className="md:tracking-wide text-center my-7 text-[14px] leading-8  lg:t6 lg:text-left ">
						Convert between different currencies and get credited in an instant.
						Conversion has never been easier.
					</p>
				</div>
			</div>
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				className="flex-1 flex  md:justify-start lg:justify-start md  items-center justify-center">
				<img
					className="h-[300px] object-contain w-[88%] my-[63px] z-10"
					src="/assets/features/money-convert2.png"
					alt=""
				/>
			</motion.div>
		</div>
	);
};

export default Exchange;
