import { Tooltip } from "antd";
import React from "react";
import { IconType } from "react-icons";
import { BiChevronDown } from "react-icons/bi";
import { MERCHANT_ROUTES } from "utils/appData/appRoutes";
import useToolkit from "utils/hooks/misc/useToolkit";

type Props = {
	childRoutes: SdbChildRouteProps[] | undefined;
	title: string;
	homePath: string;
	isSidebarExpanded: boolean;
	isActive: boolean;
	Icon: IconType;
};

const SidebarLinkDropdown = ({
	childRoutes,
	title,
	isSidebarExpanded,
	isActive,
	Icon,
	homePath,
}: Props) => {
	const { useState, router } = useToolkit();
	const [childRoutesShown, setChildRoutesShown] = useState(true);

	const isDropdownValid =
		childRoutesShown && childRoutes && childRoutes?.length > 0;

	return (
		<Tooltip
			placement="right"
			title={
				isSidebarExpanded ? "" : <span className="text-[10px]">{title}</span>
			}>
			<li
				onClick={() => {
					if (isSidebarExpanded === false && router.pathname === homePath)
						return;
					if (router.pathname === homePath) {
						setChildRoutesShown(!childRoutesShown);
					} else {
						router.push(homePath);
						setChildRoutesShown(!childRoutesShown);
					}
				}}
				// onClick={() => router.push(path)}
				className={`flect w-full py-3 pl-2 lg:pl-3 curp tracking-wide text-xs t4 lg:t4 hover:shadow-md hover:text-white hover:bg-[#7C7C7C] transition-all duration-200 ${
					isActive ? "bg-[#7C7C7C] text-white" : "text-[#252D3E]"
				}`}>
				<Icon className="mr-2.5 text-lg md:text-xl lg:text-xl" />{" "}
				{isSidebarExpanded && <span className="mr-1.5">{title}</span>}
				{isSidebarExpanded && <BiChevronDown size="1.7rem" />}
			</li>

			{isDropdownValid && (
				<div className="flexed pt-3 flex-col">
					{childRoutes?.map(({ title, path }: SdbChildRouteProps) => (
						<p
							onClick={() => router.push(path)}
							key={title}
							className="mb-2 med curp">
							{title}
						</p>
					))}
				</div>
			)}
		</Tooltip>
	);
};

export default SidebarLinkDropdown;
