import { QwidButton } from "components";
import { NextRouter } from "next/router";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";

type Props = {
	router: NextRouter;
	loadingCards: boolean;
};

const EmptyCardsUI = ({ loadingCards, router }: Props) => {
	return (
		<div className="bwite rounded-md w-full lg:w-2/5 py-5 md:py-10 lg:py-14 shadow-md px-4 md:px-8 lg:px-10 flexed flex-col">
			<img src="/assets/dashboard/create-card.png" alt="create-card" />

			<h3 className="twide text-lg lg:text-xl tcent lg:w-3/4">
				Make your life easier with virtual cards
			</h3>
			<div className="flexed my-5 lg:my-7">
				<QwidButton
					disabled={loadingCards}
					darkBlueBg
					onClick={() => router.push(DASHBOARD_ROUTES.VIRTUAL_CARDS_CONFIGURE)}
					text={<span className="px-5">Create Virtual Card</span>}
				/>
			</div>
		</div>
	);
};

export default EmptyCardsUI;
