import { beginKycLoading } from "redux/slices/layoutSlice";
import { useKyc, QwidButton, useToolkit } from "../../components";

function KycButton() {
	const { getKycStatus } = useKyc();
	const { flowID, userId, isPersonalAccount, dispatch, userEmail } =
		useToolkit();
	const userType = isPersonalAccount ? 1 : 2;

	let met = '{"user_id": "' + userEmail + '","userType": "' + userType + '"}';
	const clientId = process.env.NEXT_PUBLIC_METAMAP_CLIENT_ID;

	const handle = () => {
		const mt = document.getElementById("mt");
		if (mt) {
			mt.click();
			let matbtn = document.getElementById("matbtn");
			matbtn.disabled = true;
			matbtn.style.opacity = "0.5";
			matbtn.innerHTML = "loading....";
			mt.addEventListener("mati:loaded", ({ detail }) => {});
		}

		mt.addEventListener("mati:userFinishedSdk", ({ detail }) => {
			try {
				dispatch(beginKycLoading());
				setTimeout(() => {
					getKycStatus(userId);
				}, 30000);
				let matbtn = document.getElementById("matbtn");
				matbtn.disabled = true;
				matbtn.style.opacity = "0.85";
				matbtn.innerHTML = "Verification Completed sucessfully";
			} catch (error) {
				const msg = error?.response?.data?.message;
			}
		});

		mt.addEventListener("mati:exitedSdk", ({ detail }) => {
			let matbtn = document.getElementById("matbtn");
			matbtn.disabled = false;
			matbtn.style.opacity = "1";
			matbtn.innerHTML = "Begin Verification";
			window.addEventListener("beforeunload", function (e) {
				var confirmationMessage =
					"Are you sure you want to leave this page without finishing the verification process?";
				(e || window.event).returnValue = confirmationMessage;

				return confirmationMessage;
			});
		});
	};

	return (
		<div className="flexed">
			<QwidButton
				onClick={handle}
				text={<span className="px-7">Begin Verification</span>}
				darkBlueBg
			/>

			<div className="hidden">
				<button
					id="matbtn"
					onClick={handle}
					className="bg-green-500 text-white px-5 py-2 flexed">
					Start your Verification
				</button>
				<mati-button
					id="mt"
					clientid={clientId}
					flowId={flowID}
					color="#48bbed"
					metadata={met.toString()}
					buttonColor="#48bbed"
				/>
			</div>
		</div>
	);
}

export default KycButton;
