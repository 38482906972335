import { MdOutlineMenu } from "react-icons/md";
import { OptionalRender, QwidButton, qwidLogo, useCurrencySelector, useToolkit } from "../../components";
import { toggleDashMobileMenu } from "../../redux/slices/layoutSlice";
import { toggleExpandedSidebar } from "redux/slices/dashboardSlice";
import { IoMenuOutline } from "react-icons/io5";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";
import Image from "next/image";
import { AiOutlineSearch } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "redux/store";
import { setSearchTransactionQuery } from "redux/slices/transactionSearchSlice";
import { Dropdown } from "antd";
import NotificationsMenu from "./NotificationsMenu";
import { useEffect, useState } from "react";
import NotificationsSideModal from "./revamp/notifications/NotificationsSideModal";
import { toggleNotifications } from "redux/slices/userSlice";

const SearchBox = () => {
	const query = useAppSelector(
		(state) => state.transaction_search.searchTransactionQuery
	);

	const { isNotificationsModalOpen } = useAppSelector((state) => state.user)

	const dispatch = useAppDispatch();


	const handleChange = ({
		target: { value },
	}: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setSearchTransactionQuery(value));
	};

	return (
		<div className="ml-7 border-[1px] border-gray-200 bg-transparent rounded-md w-[250px] relative hidden lg:inline">
			<AiOutlineSearch className="text-xl absolute top-2 left-1.5 text-[#666666]" />{" "}
			<input
				type="text"
				className="py-1.5 pl-8 w-full bg-transparent !outline-none placeholder-[#666666] text-[#666666]"
				placeholder="Search transactions"
				onChange={handleChange}
				value={query}
			/>
		</div>
	);
};

type Props = {
	noSidebar?: boolean;
};

const BoardNav = ({ noSidebar }: Props) => {
	const { dispatch, router, user, isPersonalAccount, isTierOneUser, isTierTwoUser, isTierThreeUser, axiosInstance, userId, isLocalEnv, handleRequestError } = useToolkit();
	const [hasUnread, setHasUnread] = useState(false);
	const [loading, setLoading] = useState(false);
	const [notifications, setNotifications] = useState<NotificationItem[] | undefined>([]);
	const { initConversion } = useCurrencySelector()

	const getNotifications = async () => {
		setLoading(true);

		try {
			const { data } = await axiosInstance.get(`notification/${userId}?pageSize=100&pageNum=1&sortBy=id&sortOrder=desc`);

			const response = data?.content?.data?.notifications?.data;

			if (response?.length > 0 && Array.isArray(response)) {
				const formatted = response?.map((item: NotificationItem) => {
					if (item?.notyDesc === "#") {
						return {
							...item,
							notyDesc: item?.notyType,
							notyType: "Admin",
							isUnread: item?.status === 0,
						};
					}

					return {
						...item,
						isUnread: item?.status === 0,
					};
				});
				setNotifications(formatted);
			}

			setLoading(false);
			return data;
		} catch (error) {
			handleRequestError(error);
			setLoading(false);
			return error;
		}
	};
	useEffect(() => {
		getNotifications();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		try {
			const numberofUnread = notifications?.filter((item) => item.isUnread)
			if (numberofUnread && numberofUnread?.length > 0) {
				setHasUnread && setHasUnread(true);
			}
			else {
				setHasUnread && setHasUnread(false);
			}

		} catch (error) {
			handleRequestError(error);
		}

	}, [notifications]);

	const toggleSidebar = () => dispatch(toggleExpandedSidebar());

	const handleDummy = () => {
		const payload = {
			purpose: "convert",
			fromCur: "NGN",
			toCur: "USD",
			fromAmount: "5"
		}
		initConversion(payload)
	}

	return (
		<>
			<nav
				style={{ borderBottom: "1px solid rgba(238, 238, 238, 0.8)" }}
				className="sticky top-0 left-0 transition-all bg-white h-[60px] z-50 flect justify-between px-5 lg:pr-8">
				<span
					onClick={() => dispatch(toggleDashMobileMenu())}
					className="pry-text text-2xl curp lg:hidden w-max rounded-full p-1.5 bg-[#F4F8FD]">
					<MdOutlineMenu />
				</span>

				{!noSidebar && (
					<IoMenuOutline
						className="hidden lg:inline curp text-2xl opacity-50 hover:opacity-100 transition-opacity"
						onClick={toggleSidebar}
					/>
				)}

				{router.pathname === "/user/transactions" && <SearchBox />}

				{noSidebar && (
					<div>
						<Image alt="qwid-logo" src={qwidLogo} />
					</div>
				)}

				<div className="flect space-x-6 ml-auto">
					{/* <Dropdown
						onVisibleChange={() => { }}
						trigger={["click"]}
						className="cursor-pointer"
						overlayClassName="absolute w-[250px] md:w-[450px] !top-14"
						overlay={
							<NotificationsMenu
								loading={loading}
								notifications={notifications}
								setNotifications={setNotifications}
								setHasUnread={(bool) => setHasUnread(bool)}
							/>
						}> */}
					<div onClick={() => dispatch(toggleNotifications())} className="cursor-pointer h-10 w-10 flexed rounded-full bg-[#d3f2ff] relative">
						<OptionalRender condition={hasUnread}>
							<div className="h-2 w-2 rounded-full bg-[#d90429] absolute right-3 top-2" />
						</OptionalRender>
						<img src="/assets/dashboard/bell.svg" alt="bell" />
					</div>
					{/* </Dropdown> */}

					<div
						className="h-10 w-10 rounded-full flexed curp"
						onClick={() => router.push(DASHBOARD_ROUTES.SETTINGS)}>
						{user?.enduser && user?.enduser[0]?.pix ? (
							<img
								src={user?.enduser[0]?.pix}
								alt="user"
								className="object-cover h-full rounded-full"
							/>
						) : (
							<img src="/assets/dashboard/profile.svg" width={35} alt="profile" />
						)}
					</div>

					<div className="rounded-full hidden lg:flexed border border-[#48BBED] px-2 py-1 pry-text">
						{isPersonalAccount ? "Personal" : "Business"} account
					</div>

					<OptionalRender condition={isTierOneUser}>
						<div className="hidden lg:inline">
							<img src="/assets/onboarding/tier-1.svg" alt="tier-1" />
						</div>
					</OptionalRender>

					<OptionalRender condition={isTierTwoUser}>
						<div className="hidden lg:inline">
							<img src="/assets/onboarding/tier-2.svg" alt="tier-2" />
						</div>
					</OptionalRender>

					<OptionalRender condition={isTierThreeUser}>
						<div className="hidden lg:inline">
							<img src="/assets/onboarding/tier-3.svg" alt="tier-3" />
						</div>
					</OptionalRender>
					{/* 
					<OptionalRender condition={true}>
						<QwidButton
							text="Dummy Conversion"
							onClick={handleDummy}
						/>
					</OptionalRender> */}
				</div>
			</nav>

			<OptionalRender condition={true}>
				<NotificationsSideModal
					loading={loading}
					notifications={notifications}
					setNotifications={setNotifications}
					setHasUnread={(bool) => setHasUnread(bool)}

				/>
			</OptionalRender>
		</>
	);
};

export default BoardNav;
