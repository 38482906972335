import { Skeleton, Form, Checkbox } from 'antd';
import TextInput from 'components/common/InputElements/TextInput';
import React, { ReactNode, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useCallback } from 'react';
import useSendFunds from 'utils/hooks/dashboard/useSendFunds';
import useToolkit from 'utils/hooks/misc/useToolkit';
import { countryCodes } from 'utils/appData/countryCodes';
import InputTypes from 'components/common/InputElements/InputElements';
import DropdownInput from 'components/common/InputElements/DropdownInput';
import { RemittanceContext } from '../../remittance/context';
import OptionalRender from 'components/misc/OptionalRender';
import AlipayCallout from '../AlipayCallout';

type PopulateOnClickData = {
  fieldName: string;
  populateData: any | null;
};

type ValidateOnBlurData = {
  fieldName: string;
  validationData: any | null;
};

export type DynamicBankFormProps = {
  formFields: Record<string, any>[];
  fetchingFields: boolean;
  fieldData: Record<string, DynamicFieldProps>;
  initalValues?: Record<string, unknown>;
  recipientCountry?: string;
  category?: string | undefined;
};
interface CustomElementProps extends InputTypes.DropdownInputProps {
  isInput: boolean;
}

const SwitchSelectInput = ({ isInput, ...rest }: CustomElementProps) => {
  return !isInput ? (
    <DropdownInput {...rest} />
  ) : (
    <TextInput
      required={rest.required}
      name={rest.name}
      label={rest.label}
      key={rest.name}
    />
  );
};

const DynamicBankForm = (
  {
    formFields,
    fetchingFields,
    initalValues = {},
    fieldData: dump,
    recipientCountry,
    category
  }: DynamicBankFormProps,
  ref: React.Ref<
    | Record<string, (cb: (formValues: Record<string, string>, formFieldDump: any) => void) => void>
    | undefined
  >
) => {
  const { toastError, toastInfo } = useToolkit();
  const [fieldData, setFieldData] = useState<Record<string, DynamicFieldProps>>(dump || {});
  const [form] = Form.useForm();
  const { setStaticParams, sendMoneyDetails } = useContext(RemittanceContext)

  const [providerName, setProviderName] = useState('');
  const isAlipay = sendMoneyDetails?.recipientCountry === 'CN' && sendMoneyDetails?.recipientCurrency === 'CNY' && category === 'WALLET'
    && providerName === 'alipay'


  useEffect(() => {
    const keys = Object.keys(dump || {});
    if (keys?.length === 0 || keys[0] === 'mobileCode') {
      form.resetFields();
      setFieldData(dump);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields]);
  const submit = useCallback(
    (cb: (formValues: Record<string, string>, formFieldDump: any) => void) => {
      let currentField = '';
      let isError = false;
      const isValidatingOrError = Object.entries(fieldData || {})?.some(([key, value]) => {
        const formField = (formFields || []).find((field) => field?.fieldName === key);
        currentField = formField?.title;
        if (value?.validating) return true;
        if (formField?.required && value.isValid === false) {
          isError = true;
          return true;
        }
        return false;
      });
      if (isValidatingOrError === true && isError) {
        const message = currentField
          ? `Invalid ${currentField} provided`
          : 'Invalid detail provided';
        toastError(message);
      } else if (isValidatingOrError === true && !isError) {
        const message = currentField
          ? `Please wait while we validate the ${currentField} provided`
          : 'Please wait while we validate the detail provided';
        toastInfo(message);
      } else {
        form.validateFields().then((values) => {
          cb(values, fieldData);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldData, form, formFields]
  );


  useEffect(() => {

    const defaultFields = formFields?.filter((field: any) => field?.hasDefaultValue === true && field?.defaultValue?.length > 0)

    if (defaultFields?.length > 0) {
      let newState = { ...fieldData };

      defaultFields?.forEach((field: any) => {
        newState[field.fieldName] = field.defaultValue;
      });

      setStaticParams(newState);
    }

    // eslint-disable-next-line
  }, [formFields])

  useImperativeHandle(ref, () => ({
    submit,
    getInfo: form.getFieldsValue
  }));
  const setValidateStatus = useCallback((isRequired: boolean, isValid?: boolean) => {
    if (isValid !== undefined) {
      if (isValid) return 'success';
      return isRequired && !isValid ? 'error' : 'warning';
    }
    return undefined;
  }, []);
  const {
    handleGetBeneficiaryBanks,
    getAllRecipientCountries,
    countriesData,
    useGetCities,
    useGetStates,
    useVerifyNigeriaAccountNo,
    useVerifyBankDetail
  } = useSendFunds();
  const [invisibleFields, setInvisibleFields] = useState<Record<string, any>>({});
  const getHasNonReachableFields = useCallback(
    (payload: Record<string, string>[]) => {
      let firstNonReachable = '';
      const isNonReachable = payload?.some((value) => {
        firstNonReachable = value.value;
        return value.valueType === 'fieldName' && !form.getFieldValue(value.value);
      });
      return {
        firstNonReachable,
        isNonReachable
      };
    },
    [form]
  );

  const { mutateAsync: getStates } = useGetStates;
  const { mutateAsync: getCities } = useGetCities;
  const { mutateAsync: verifyNGAccountNo } = useVerifyNigeriaAccountNo;
  const { mutateAsync: verifyBankDetail } = useVerifyBankDetail;
  const populateCallback = useCallback(
    (field: string, value?: any) => {
      setFieldData({
        ...fieldData,
        [field]: {
          ...fieldData[field],
          ...(value && { options: value }),
          fetchingOptions: false,
          fetched: true
        }
      });
    },
    [fieldData, setFieldData]
  );

  const populateErrorCallback = useCallback(
    (field: string, message?: string) => {
      if (message) toastError(message);
      populateCallback(field);
    },
    [populateCallback, toastError]
  );

  const validateCallback = useCallback(
    (
      field: string,
      isValid: boolean,
      autoPopulatedFields?: { field: string; markAsPopulated: boolean }[]
    ) => {
      const data = {
        ...fieldData,
        [field]: {
          ...fieldData[field],
          isValid,
          validating: false
        }
      };
      if (autoPopulatedFields?.length) {
        autoPopulatedFields.forEach(({ field, markAsPopulated }) => {
          data[field] = {
            ...(data[field] || {}),
            populated: markAsPopulated
          };
        });
      }
      setFieldData(data);
    },
    [fieldData, setFieldData]
  );

  const validateErrorCallback = useCallback(
    (
      field: string,
      autoPopulatedFields: { field: string; markAsPopulated: boolean }[],
      message?: string
    ) => {
      if (message) toastError(message);
      validateCallback(field, false, autoPopulatedFields);
    },
    [toastError, validateCallback]
  );
  const fetchAlternativeValidationNodes = useCallback(
    (fieldName: string) => {
      // validate
      // special case with bank name and bank code
      let currField = fieldName;
      if (fieldName === 'bankName') {
        currField = 'bankCode';
      }
      const validateOnAlternativeNodes = formFields.filter(
        (field) =>
          !!field?.validation?.payload?.find(
            (payload: Record<string, string>) =>
              field.fieldName !== currField &&
              payload.field === currField &&
              payload.valueType === 'fieldName'
          )
      );
      return (
        validateOnAlternativeNodes?.map((node) => ({
          ...node.validation,
          fieldName: node.fieldName
        })) || []
      );
    },
    [formFields]
  );

  const handleClick = useCallback(
    ({ fieldName, populateData }: PopulateOnClickData) => {
      if (fieldData[fieldName]?.options) return;
      if (populateData) {
        // endpoint scenarios to be covered later
        if (populateData?.isEndpoint) {
          const { firstNonReachable, isNonReachable } = getHasNonReachableFields(
            populateData.payload as Record<string, string>[]
          );
          const supportingPayload: Record<string, string> = {}; // prefilled from existing data
          if (isNonReachable) {
            if (firstNonReachable === 'country')
              supportingPayload.country = recipientCountry as string;
            else {
              let fieldTitle =
                firstNonReachable === 'bankCode'
                  ? 'Bank Name'
                  : formFields.find((v) => v?.fieldName === firstNonReachable)?.title ||
                  firstNonReachable;
              toastInfo(`Provide/Select a ${fieldTitle}`);
              return;
            }
          }
          // generate request payload
          let payload = populateData.payload?.reduce(
            (finalPayload: Record<string, string>, value: Record<string, string>) => {
              return {
                ...finalPayload,
                [value.field]:
                  value.valueType === 'data' ? value.value : form.getFieldValue(value.value)
              };
            },
            {}
          );
          payload = {
            ...payload,
            ...supportingPayload
          };
          // handle loading state
          setFieldData({
            ...fieldData,
            [fieldName]: {
              ...fieldData[fieldName],
              fetchingOptions: true,
              fetched: false
            }
          });
          // handle endpoint
          switch (populateData?.endpoint) {
            case '/otherBanks':
              handleGetBeneficiaryBanks(payload)
                .then((value) => {
                  populateCallback(fieldName, value);
                })
                .catch((_) => populateErrorCallback(fieldName, 'Error fetching banks'));
              break;
            case '/countryCur':
              if (countriesData?.length) populateCallback(fieldName, countriesData);
              else
                getAllRecipientCountries()
                  .then((value) => populateCallback(fieldName, value))
                  .catch((_) => populateErrorCallback(fieldName, 'Error fetching countries'));
              break;
            case '/vtn/listState':
              getStates(payload)
                .then((value) => populateCallback(fieldName, value))
                .catch((_) => populateErrorCallback(fieldName));
              break;
            case '/vtn/listCity':
              getCities({
                ...payload,
                country:
                  countryCodes?.find((country) => country.code === payload.country)?.name ||
                  payload.country
              })
                .then((value) => populateCallback(fieldName, value))
                .catch((_) => populateErrorCallback(fieldName));
              break;
            default:
              break;
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      countriesData,
      fieldData,
      form,
      formFields,
      getHasNonReachableFields,
      handleGetBeneficiaryBanks,
      populateCallback,
      populateErrorCallback,
      setFieldData
    ]
  );

  const handleBlur = useCallback(
    ({ fieldName, validationData }: ValidateOnBlurData) => {
      if (!validationData) {
        const validationPayloadArr = fetchAlternativeValidationNodes(fieldName);
        validationPayloadArr?.forEach(({ fieldName: field, ...rest }) =>
          handleBlur({ fieldName: field, validationData: rest })
        );
        return;
      }
      const { payload, endpoint } = validationData;
      const { isNonReachable } = getHasNonReachableFields(payload as Record<string, string>[]);
      if (isNonReachable) return;
      // generate request payload
      const reqPayload = payload?.reduce(
        (finalPayload: Record<string, string>, value: Record<string, string>) => {
          return {
            ...finalPayload,
            [value.field]:
              value.valueType === 'data' ? value.value : form.getFieldValue(value.value)
          };
        },
        {}
      );
      // handle loading state
      setFieldData({
        ...fieldData,
        [fieldName]: {
          ...fieldData[fieldName],
          validating: true
        }
      });
      // handle endpoints
      if (endpoint)
        switch (endpoint) {
          case '/bank-verify':
            verifyNGAccountNo(reqPayload)
              .then((value) => {
                const accountName = value?.accountName;
                form.setFieldValue('accountName', accountName);
                if (accountName)
                  validateCallback(fieldName, true, [
                    { field: 'accountName', markAsPopulated: true }
                  ]);
                else
                  validateCallback(fieldName, true, [
                    { field: 'accountName', markAsPopulated: false }
                  ]);
              })
              .catch((_) =>
                validateErrorCallback(fieldName, [{ field: 'accountName', markAsPopulated: false }])
              );
            break;
          case '/swift-iban-verify':
            verifyBankDetail(reqPayload)
              .then((value) => {
                const autoPopulated: any[] = [];
                // populate
                const fieldsToPopulateObj = invisibleFields[fieldName];
                if (fieldsToPopulateObj) {
                  if (value) {
                    Object.keys(fieldsToPopulateObj).forEach((key) => {
                      if (value[key]) {
                        form.setFieldValue(fieldsToPopulateObj[key], value[key]);
                        autoPopulated.push({
                          field: fieldsToPopulateObj[key],
                          markAsPopulated: true
                        });
                      } else if (fieldData[fieldsToPopulateObj[key]]?.populated === true) {
                        form.setFieldValue(fieldsToPopulateObj[key], undefined);
                        autoPopulated.push({
                          field: fieldsToPopulateObj[key],
                          markAsPopulated: false
                        });
                      }
                    });
                  }
                }
                validateCallback(fieldName, true, autoPopulated);
              })
              .catch((_) => {
                // un-populate
                const autoPopulated: any[] = [];
                const fieldsToPopulateObj = invisibleFields[fieldName];
                Object.keys(fieldsToPopulateObj || {}).forEach((key) => {
                  if (fieldData[fieldsToPopulateObj[key]]?.populated === true) {
                    form.setFieldValue(fieldsToPopulateObj[key], undefined);
                    autoPopulated.push({
                      field: fieldsToPopulateObj[key],
                      markAsPopulated: false
                    });
                  }
                });
                validateErrorCallback(fieldName, autoPopulated);
              });
            break;
          default:
            break;
        }
    },
    [
      fieldData,
      form,
      getHasNonReachableFields,
      setFieldData,
      validateCallback,
      validateErrorCallback,
      verifyBankDetail,
      verifyNGAccountNo,
      invisibleFields,
      fetchAlternativeValidationNodes
    ]
  );

  const handleChange = useCallback(
    (value: string, fieldName: string, fieldType: string) => {
      if (['select', 'select2'].includes(fieldType)) {
        // populate
        const fieldsToPopulateObj = invisibleFields?.[fieldName];
        if (fieldsToPopulateObj) {
          const options = fieldData[fieldName]?.options;
          if (options?.length) {
            const option = options.find((opt: Record<string, string>) => opt.value === value);
            if (option) {
              Object.keys(fieldsToPopulateObj).forEach((key) => {
                if (option[key]) {
                  form.setFieldValue(fieldsToPopulateObj[key], String(option[key]));
                }
              });
            }
          }
        }

        const validationPayloadArr = fetchAlternativeValidationNodes(fieldName);
        validationPayloadArr?.forEach(({ fieldName, ...rest }) =>
          handleBlur({ fieldName, validationData: rest })
        );
      }
    },
    [fetchAlternativeValidationNodes, fieldData, form, handleBlur, invisibleFields]
  );

  useEffect(() => {
    if (formFields?.length) {
      const invisibleFormFields = formFields.reduce(
        (accFields: Record<string, any>, field: Record<string, any>) => {
          if (field.inherit?.inheritFrom && field.inherit?.value)
            accFields[field.inherit.inheritFrom] = {
              ...(accFields[field.inherit.inheritFrom] || {}),
              [field.inherit.value]: field.fieldName
            };
          return accFields;
        },
        {}
      );
      setInvisibleFields(invisibleFormFields);
    }
  }, [formFields]);

  const DynamicForm = useCallback(() => {
    if (!formFields?.length) return <></>;
    const formElements = formFields.reduce((formatted: ReactNode[], data: Record<string, any>) => {
      const { fieldName, required, title, verification, validation, fieldType, populate, defaultValue } = data;
      // handle non-visible fields
      if (!data.visibility) {
        formatted.push(
          <TextInput
            required={required && !defaultValue}
            name={fieldName}
            hidden
            type={'hidden'}
            key={fieldName}
            {...(defaultValue && { defaultValue, disabled: true })}
          />
        );
        return formatted;
      }
      // Determine field type
      if (fieldType === 'input') {
        let isNumberValidated = undefined;
        let customRules: Record<string, unknown>[] = [];
        if (verification) {
          isNumberValidated = verification?.dataType === 'numeric';
          if (verification?.min) {
            customRules.push({
              min: verification.min,
              message: `should be at least ${verification.min} characters`
            });
          }
          if (verification?.max) {
            customRules.push({
              max: verification.max,
              message: `should be at most ${verification.max} characters`
            });
          }
          if (verification?.regex) {
            customRules.push({ pattern: new RegExp(verification.regex), message: `invalid entry` });
          }
        }
        formatted.push(
          <div className='w-full md:w-[48.5%]'>
            <TextInput
              required={required && !defaultValue}
              name={fieldName}
              label={title}
              key={fieldName}
              disabled={fieldData?.[fieldName]?.populated === true}
              isNumberValidated={isNumberValidated}
              customRules={customRules}
              validating={fieldData?.[fieldName]?.validating}
              validateStatus={setValidateStatus(required, fieldData?.[fieldName]?.isValid)}
              onBlurHandler={
                validation
                  ? (e) => handleBlur({ fieldName, validationData: validation })
                  : undefined
              }
              {...(defaultValue && { defaultValue, disabled: true })}
            />
          </div>
        );
      } else {
        formatted.push(
          <div className='w-full md:w-[48.5%]'>
            <SwitchSelectInput
              isInput={
                populate?.isEndpoint &&
                fieldData?.[fieldName]?.fetched &&
                !fieldData?.[fieldName]?.options?.length
              }
              loading={fieldData?.[fieldName]?.fetchingOptions}
              required={required && !defaultValue}
              name={fieldName}
              label={title}
              key={fieldName}
              options={
                populate?.populateData?.length
                  ? populate?.populateData?.map((val: any) => ({
                    value: val.id,
                    title: val.text
                  }))
                  : fieldData?.[fieldName]?.options
              }
              onChange={(val: any) => handleChange(val, fieldName, fieldType)}
              isSearchable={fieldType === 'select2'}
              handleClick={(_val) =>
                handleClick({
                  fieldName,
                  populateData: populate as PopulateOnClickData['populateData']
                })
              }
              {...(defaultValue && { defaultValue, disabled: true })}
            />
          </div>
        );
      }
      return formatted;
    }, [] as ReactNode[]);
    return (
      <Form
        onValuesChange={(_, changed: any) => {
          if (changed?.bankName !== undefined) {
            setProviderName(changed?.bankName?.toLowerCase())
          }
        }}
        initialValues={initalValues}
        className='w-full flex flex-wrap justify-between sm:flex-row flex-col gap-y-5'
        form={form}>
        {formElements}
      </Form>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields, fieldData]);

  return (
    <div className='w-full'>
      {fetchingFields ? (
        <div className='flex flex-col gap-4'>
          {[...Array(3)].map((_, i) => (
            <Skeleton
              key={i}
              active
              avatar
              paragraph={{ rows: 1 }}
            />
          ))}
        </div>
      ) : (
        <>
          <DynamicForm />

          <OptionalRender condition={isAlipay}>
            <AlipayCallout />
          </OptionalRender>
        </>
      )}
    </div>
  );
};

export default React.forwardRef(DynamicBankForm);
