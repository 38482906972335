import {
	CenterModal,
	useToolkit,
	QwidButton,
	Spinner,
	useVirtualCards,
	useDashboard,
} from "components";
import Flag from "components/common/Flag";
import React from "react";
import CurrencyFormat from "react-currency-format";
import { GiAbstract113 } from "react-icons/gi";
import { toggleFundCardModal } from "redux/slices/virtualsSlice";

type CardProps = {
	title: string;
	isEditable?: boolean;
	subtag: string;
};

const FundCardModal = () => {
	const { useAppSelector, dispatch, getSymbol, useState, useEffect, roundUp } =
		useToolkit();
	const { isFundCardModalOpen, selectedVCard: card } = useAppSelector(
		(state) => state.virtuals
	);
	const [fundAmount, setFundAmount] = useState("");
	const { useFundCard } = useVirtualCards();
	const { isLoading: fundLoading, mutate: fundCard } = useFundCard();
	const { walletsLoading, walletsData, fetchUserWallets } = useDashboard();

	const closeModal = () => dispatch(toggleFundCardModal());
	const totalBalance =
		parseFloat(fundAmount) + parseFloat(card?.virtualCardBalance || "0");

	const symbol = getSymbol(card?.virtualCardCur || "USD");
	const currency = card?.virtualCardCur || "USD";
	const INPUT_CLASS =
		"py-2 text-lg lg:text-2xl twide focus:outline-none font-bold w-4/5 placeholder:text-sm lg:placeholder:text-2xl  placeholder:med placeholder:text-black";
	const FlagDiv = () => (
		<div className="w-3/5 flect space-x-3">
			<Flag sqFlags currency={currency} />
			<span className="med twide">{currency}</span>
		</div>
	);

	const CardUI = ({ isEditable }: CardProps) => {
		return (
			<div className="conv-wrap w-full md:w-[45%]">
				<p className="mb-3 med twide text-xs">
					{isEditable ? `From ${card?.virtualCardCur} Wallet` : "Card Wallet"}{" "}
				</p>
				<div className="w-full flex-btw">
					<div className="flect space-x-1">
						<span className="text-lg lg:text-2xl twide font-bold">
							{symbol}
						</span>
						<input
							value={!Number(fundAmount) ? "" : fundAmount}
							placeholder="1"
							className={`${INPUT_CLASS} cursor-not-allowed`}
						/>
					</div>
					<FlagDiv />
				</div>
				<p className="mb-0 mt-6 text-xs">
					Card balance after funding:&nbsp;{" "}
					{!Number(fundAmount) ? "" : totalBalance?.toLocaleString()}
				</p>
			</div>
		);
	};

	useEffect(() => {
		fetchUserWallets();
	}, []);

	const onFund = () => {
		const payload = {
			virtualCardId: card?.virtualCardId,
			amount: fundAmount,
		};
		fundCard(payload);
	};

	const cardCur = card?.virtualCardCur;

	const walletInfo: any = walletsData?.find(
		(wallet: UserWallet) => wallet?.cur === cardCur
	);

	return (
		<CenterModal
			control={isFundCardModalOpen}
			onClose={closeModal}
			isCustom
			className="w-full md:w-3/4 min-h-[200px] p-5 pb-14 lg:pb-16 bg-[#FBFBFB]">
			<div className="w-full">
				<p className="tcent lg:text-lg med my-5">Fund Card</p>
				{fundLoading || walletsLoading ? (
					<Spinner />
				) : (
					<div className="flex items-center justify-center md:justify-between flex-wrap md:flex-nowrap space-y-6 md:space-y-0">
						<div className="conv-wrap w-full md:w-[45%]">
							<p className="mb-3 med twide text-xs">{cardCur}&nbsp;Wallet</p>
							<div className="w-full flex-btw">
								<div className="flect space-x-1">
									<span className="text-lg lg:text-2xl twide font-bold">
										{getSymbol(card?.virtualCardCur || "USD")}
									</span>
									<CurrencyFormat
										value={!fundAmount ? "" : fundAmount}
										placeholder="1"
										thousandSeparator={true}
										onValueChange={({ floatValue }) =>
											setFundAmount(floatValue.toString())
										}
										className={INPUT_CLASS}
									/>
								</div>
								<FlagDiv />
							</div>
							<p className="mb-0 mt-6 text-xs">
								{cardCur} Wallet Balance:&nbsp;{" "}
								{walletInfo !== undefined && roundUp(walletInfo.balance, 3)}
							</p>
						</div>
						{/* swap */}
						<div>
							<GiAbstract113 className="text-lg md:text-xl lg:text-4xl pry-text" />
						</div>
						<CardUI title="Card Wallet" subtag="Subtag" />
					</div>
				)}

				<div className="mt-10 lg:mt-12 flexed">
					<QwidButton
						onClick={onFund}
						loading={fundLoading}
						disabled={!fundAmount?.length || parseFloat(fundAmount) < 0.01}
						text="Fund Card"
					/>
				</div>
			</div>
		</CenterModal>
	);
};

export default FundCardModal;
