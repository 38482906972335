import { useToolkit, useFramer } from "../../../components";
import { motion } from "framer-motion";
import LandingPageConverter from "./conversion/LandingPageConverter";

type Props = {};

const PersonalBanner = (props: Props) => {
	const { isMobile, toastInfo } = useToolkit();
	const { headerTxt, empWrap, empText } = useFramer();
	return (
		<div className="flex flex-wrap lg:flex-nowrap h-full lg:pt-40 lg:px-8">

			<div className="w-full lg:w-2/4 mx-auto h-fit lg:h-full">
				<motion.p
					variants={headerTxt}
					initial="hidden"
					animate="visible"
					style={{ lineHeight: isMobile ? "40px" : "70px" }}
					className="mb-2 smb text-center lg:text-left twite tracking-wide text-3xl w-5/6 lg:w-full lg:text-6xl mx-auto">
					Global Payments, <br /> Made Easy
				</motion.p>
				<motion.div
					variants={empWrap}
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true }}
					className="w-full">
					<motion.p
						variants={empText}
						className="twite text-center lg:text-left t6 mt-5 mb-5 lg:mb-10 tracking-wide lg:w-4/5 lg:pr-8">
						Providing Reliable Payment Solutions From Anywhere in the World.
					</motion.p>
					{/* <motion.div
						variants={empText}
						className="hidden lg:flex items-end justify-start space-x-3">
						<img src="/assets/landing/qrcode.png" width={65} alt="qr-code" />
						<p className="twite mb-0">
							Heyy <br /> scan to download on IOS and andriod
						</p>
					</motion.div> */}
				</motion.div>
			</div>

			{/* store buttons */}
			<div className="lg:hidden w-full mt-6 mb-10 flexed space-x-2">
				<div>
					<img
						onClick={() =>
							window.open(
								"https://play.google.com/store/apps/details?id=io.blinqpay.qwid",
								"_blank"
							)
						}
						width={120}
						src="/assets/landing/gplay-light.svg"
						alt="store-btn"
						className="store-btn"
					/>
				</div>
				<div>
					<img
						onClick={() =>
							window.open(
								"https://apps.apple.com/us/app/qwid/id6444584444",
								"_blank"
							)
						}
						width={120}
						src="/assets/landing/appstore-light.svg"
						alt="store-btn"
						className="store-btn"
					/>
				</div>
			</div>

			{/* screenshot wrapper */}
			<div className="w-full lg:w-2/4 mx-auto pb-10 lg:pb-0s">

				<LandingPageConverter />

				{/* <div className="flex items-end">
					<img
						src="/assets/landing/lphero-abstract.png"
						alt="abstract"
						className="hidden lg:inline h-[150px] lg:h-[250px] object-cover"
					/>
					<img
						src="/assets/landing/banner-shot.svg"
						alt="screenshot"
						className="h-[200px] lg:h-[450px] object-cover"
					/>
				</div> */}
			</div>
		</div>
	);
};

export default PersonalBanner;
