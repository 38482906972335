import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface MarketingReferralDataState {
  referralCodeData: {
    name?: string;
    referCode: string | undefined;
    isIOS?: boolean;
  };
}

const initialState: MarketingReferralDataState = {
  referralCodeData: {
    name: '',
    referCode: '',
    isIOS: false
  }
};

const marketingReferralDataSlice = createSlice({
  name: 'referral-data',
  initialState,
  reducers: {
    setReferralData: (
      state,
      action: PayloadAction<MarketingReferralDataState['referralCodeData']>
    ) => {
      return {
        referralCodeData: action.payload
      };
    }
  }
});

export const { setReferralData } = marketingReferralDataSlice.actions;

export default marketingReferralDataSlice.reducer;
