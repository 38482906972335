import QwidButton from 'components/misc/QwidButton'
import React from 'react'
import useOnboarding from "utils/hooks/onboarding/useOnboarding";
import {TiWarningOutline} from 'react-icons/ti'
const DeleteShareHolder = ({ closeModal , deleteProsp ,setDeleteModalOpen }: any) => {
    const { deleteDirectorInfo , deleteDirectorInfoLoading, 
} = useOnboarding('business')
const deleteDirector = async () => {
    const token = deleteProsp?.token;
    try {
      await deleteDirectorInfo(token);  
      setTimeout(() => {
        setDeleteModalOpen(false);
      }, 1000); 
    } catch (error) {
   
    }
  };
  
  return (
    <div>
        <div className='flex justify-center my-4'>
          <div className='text-[#FF2905] p-2 rounded-[50%]  bg-[#F9EAEA]'><TiWarningOutline className='!w-7 !h-7' /></div>
                
        </div>
        <div className='flex justify-center my-2'>
            <p className="text-lg font-semibold">Are you sure ?</p>
        </div>
        <div  className="flex justify-center">
            <p className="w-[90%] " > This action cannot be undone. All values associated with this will be lost </p>
        </div>
        <div  className='flex justify-end my-4'>
        <div>
          <QwidButton transparent text={"Cancel"}
        onClick={closeModal}
        darkBg
           />
        </div>
        <div>
          <QwidButton
            onClick={deleteDirector}
            danger
            // disabled={isButtonDisabledAddBtn}
            // actionType
            text={deleteDirectorInfoLoading ?  "Delete...." : "Delete"}
          />
        </div>
        </div>
    </div>
  )
}
export default DeleteShareHolder