import React from "react";
import styled from "styled-components";
import { useToolkit } from "../../components";

type Props = { BannerChildren: JSX.Element; bgPath?: string };

type WProps = {
	isMobile?: boolean;
	bgPath?: string;
};

const HeroSection = ({ BannerChildren, bgPath }: Props) => {
	const { isMobile } = useToolkit();

	return (
		<Wrapper
			isMobile={isMobile}
			bgPath={bgPath}
			className="side-pad h-pad pb-0">
			{BannerChildren}
		</Wrapper>
	);
};

export default HeroSection;

const Wrapper = styled.section<WProps>`
	height: calc(100vh - 70px);
	background: ${({ bgPath }) => bgPath || "blue"};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@media (max-width: 800px) {
		height: auto;
	}
`;
