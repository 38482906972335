import { useMutation } from "react-query";
import useToolkit from "utils/hooks/misc/useToolkit";

type Props = {
	children: string;
	className: string;
	id: string;
	onSeeMore: () => void;
};

function TextTruncator({ children, className, id, onSeeMore }: Props) {
	const text = children;
	const { useState, axiosInstance } = useToolkit();
	const [isTruncated, setIsTruncated] = useState(true);

	const resultString = isTruncated ? text.slice(0, 35) : text;

	const toggleStatusFn = async (id: string) => {
		await axiosInstance.patch(`notification`, {
			status: 1,
			notyId: id,
		});
	};

	const useNotificationAction = () => useMutation(toggleStatusFn);

	const { mutate: toggleStatus } = useNotificationAction();

	const handleTruncate = (id: string) => {
		// if (isTruncated) {
		// API call to mark item as read
		// 	toggleStatus(id);
		// }
		setIsTruncated(!isTruncated);
	};

	return (
		<p className={className}>
			{resultString}.&nbsp;
			<span
				className="font-semibold pry-text curp text-[11.5px]"
				onClick={() => {
					onSeeMore();
					handleTruncate(id);
				}}>
				See {isTruncated ? "More" : "Less"}
			</span>
		</p>
	);
}

export default TextTruncator;
