import { CenterModal, useToolkit } from "components";

type Props = {
	onClose: () => void;
	control: boolean;
	ref?: React.MutableRefObject<null>;
};

const SumSubInitModal = ({ onClose, control }: Props) => {
	const { isPersonalAccount } = useToolkit();

	return (
		<CenterModal
			isCustom
			className="w-full md:w-2/4 mx-auto overflow-y-scroll max-h-[500px]"
			title={isPersonalAccount ? "KYC Information" : "KYB Information"}
			control={control}
			onClose={onClose}>
			<div id="sumsub-websdk-container"></div>
		</CenterModal>
	);
};

export default SumSubInitModal;
