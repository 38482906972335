import { Menu } from "antd";
import OptionalRender from "components/misc/OptionalRender";
import { BiEnvelope } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
// import { MdOutlineCheck } from "react-icons/md";

type TextMenuProps = {
   status: number;
   id: string;
   markItemAsRead: (id: string) => void;
   deleteNotification: (id: string) => void;
}

const formatDate = (dateString: any): string => {
   const date = new Date(dateString);

   const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
   const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December",
   ];

   const dayOfWeek = days[date.getDay()];
   const day = date.getDate();
   const month = months[date.getMonth()];
   const year = date.getFullYear();

   const ordinalSuffix = (n: number) => {
      if (n > 3 && n < 21) return "th";
      switch (n % 10) {
         case 1: return "st";
         case 2: return "nd";
         case 3: return "rd";
         default: return "th";
      }
   };

   return `${dayOfWeek}, ${day}${ordinalSuffix(day)} ${month}, ${year}`;
};

export const todayFormatted = formatDate(new Date());

export const groupNotificationsByDate = (notifications: any) => {
   const groups: Record<string, Notification[]> = {};

   notifications?.forEach((notification: any) => {
      const date = formatDate(notification.created_at);
      if (!groups[date]) {
         groups[date] = [];
      }
      groups[date].push(notification);
   });

   return Object.entries(groups)?.map(([date, notifications]) => ({
      date,
      notifications: notifications?.sort(
         (a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      ),
   }))?.sort((a: any, b: any) =>
      new Date(b.notifications[0].created_at).getTime() -
      new Date(a.notifications[0].created_at).getTime()
   );
};

export const TextMenu = ({ id, status, markItemAsRead, deleteNotification }: TextMenuProps) => (
   <Menu style={{
      borderRadius: '8px',
   }} className="!p-1">
      <OptionalRender condition={status === 0}>
         <Menu.Item key={'1'}>
            <div onClick={(e: any) => { e.stopPropagation(); markItemAsRead(id) }} className="flex items-center">
               <BiEnvelope className="text-qwid-blue-100 text-lg" />{" "}
               <span className="ml-2 text-dark-blue font-medium">Mark as read</span>
            </div>
         </Menu.Item>
      </OptionalRender>

      <Menu.Item key={'2'}>
         <div
            onClick={(e: any) => { e.stopPropagation(); deleteNotification(id) }}
            className="flex items-center">
            <FiTrash2 className="text-qwid-red-50 text-lg" />
            <span className="ml-2 text-dark-blue font-medium">Delete</span>
         </div>
      </Menu.Item>
   </Menu>
);
