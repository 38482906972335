import { MdOutlineMenu } from "react-icons/md";
import {
	AUTH_ROUTES,
	DASHBOARD_ROUTES,
	LANDING_ROUTES,
} from "utils/appData/appRoutes";
import {
	qwidLogo,
	useToolkit,
	QwidButton,
	FeaturesDropdown,
	HelpDropdown,
} from "../../components";
import { toggleMobileNav } from "../../redux/slices/layoutSlice";

type Props = {
	transparentNav?: boolean;
	greyBg?: boolean;
};

const LandingNav = ({ transparentNav, greyBg }: Props) => {
	const {
		Image,
		router,
		useEffect,
		dispatch,
		PersonalBtn,
		BusinessBtn,
		useState,
		isValidUser,
		user,
		logout,
		BlogBtn
	} = useToolkit();

	const [fixedNav, setFixedNav] = useState(false);

	const switchNav = function () {
		if (window.scrollY >= 20) {
			setFixedNav(true);
		} else {
			setFixedNav(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", switchNav);
		return () => window.removeEventListener("scroll", switchNav);
	}, []);

	return (
		<nav
			style={{
				background: transparentNav
					? "url('/assets/business/banner.png')"
					: greyBg
					? "#e5e5e5"
					: "",
			}}
			className={`side-pad h-[70px] bg-[#08111a] flex-btw z-50 ${
				transparentNav && "bg-opacity-60"
			} ${
				fixedNav
					? "sticky top-0 left-0 transition-all backdrop-blur-xl bg-opacity-70"
					: "bg-opacity-90"
			}`}>
			<div className="flect">
				<div onClick={() => router.push(LANDING_ROUTES.PERSONAL_HOMEPAGE)}>
					<Image alt="qwid-logo" className="curp" src={qwidLogo} />
				</div>
				<div className="flect hidden lg:flex space-x-5 ml-8">
					<PersonalBtn darkText={transparentNav} />
					<BusinessBtn darkText={transparentNav} />
				</div>
			</div>

			<div className="flect space-x-10">
				<div className="flect hidden lg:flex space-x-5">

					<BlogBtn darkText={transparentNav} />
					<FeaturesDropdown darkText={transparentNav} />
					<HelpDropdown darkText={transparentNav} />
				</div>

				<div className="flect hidden lg:flex space-x-4">
					<QwidButton
						greyBg={transparentNav}
						text={
							<span className="px-3">
								{isValidUser ? "Dashboard" : "Login"}
							</span>
						}
						whiteBg
						onClick={() =>
							router.push(
								isValidUser ? DASHBOARD_ROUTES.DASHBOARD : AUTH_ROUTES.SIGN_IN
							)
						}
					/>

					<QwidButton
						text={
							<span className="px-3">
								{user ? "Logout" : "Create an account"}
							</span>
						}
						onClick={() => (user ? logout() : router.push(AUTH_ROUTES.SIGN_UP))}
					/>
				</div>

				<span className="pry-text text-3xl curp lg:hidden">
					<MdOutlineMenu onClick={() => dispatch(toggleMobileNav())} />
				</span>
			</div>
		</nav>
	);
};

export default LandingNav;
