import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface layoutState {
  isMobileNavOpen: boolean;
  isDashMenuOpen: boolean;
  isSidebarOpen: boolean;
  isNotificationsOpen: boolean;
  isUserVerified: boolean;
  isKycLoading: boolean;
  userNotifications: NotificationItem[];
  isLogoutModalOpen: boolean;
  kycStatus: string | undefined;
  isNewDirectorModalOpen: boolean;
  // onboarding - directors
  autofillDirForm: boolean;
  existingDirShown: boolean;
  isKycModalOpen: boolean;
  kycMessage: string;
}

const initialState: layoutState = {
  isMobileNavOpen: false,
  isSidebarOpen: true,
  isNotificationsOpen: false,
  isDashMenuOpen: false,
  isKycLoading: false,
  isUserVerified: false,
  userNotifications: [],
  isLogoutModalOpen: false,
  kycStatus: undefined,
  isNewDirectorModalOpen: false,
  autofillDirForm: false,
  existingDirShown: true,
  isKycModalOpen: false,
  kycMessage: ''
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleMobileNav: (state) => {
      return {
        ...state,
        isMobileNavOpen: !state.isMobileNavOpen
      };
    },

    setKycStatus: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        kycStatus: payload
      };
    },

    toggleLogoutModal: (state) => {
      return {
        ...state,
        isLogoutModalOpen: !state.isLogoutModalOpen
      };
    },

    beginKycLoading: (state) => {
      return {
        ...state,
        isKycLoading: true
      };
    },

    stopKycLoading: (state) => {
      return {
        ...state,
        isKycLoading: false
      };
    },

    toggleSidebar: (state) => {
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen
      };
    },

    setNotifications: (state, { payload }: PayloadAction<NotificationItem[]>) => {
      return {
        ...state,
        userNotifications: payload
      };
    },

    toggleNotifications: (state) => {
      return {
        ...state,
        isNotificationsOpen: !state.isNotificationsOpen
      };
    },

    toggleDashMobileMenu: (state) => {
      return {
        ...state,
        isDashMenuOpen: !state.isDashMenuOpen
      };
    },

    toggleVerifStatus: (state) => {
      return {
        ...state,
        isUserVerified: true
      };
    },

    toggleNewDirectorModal: (state) => {
      return {
        ...state,
        isNewDirectorModalOpen: !state.isNewDirectorModalOpen
      };
    },

    toggleDirAutofill: (state) => {
      return {
        ...state,
        autofillDirForm: !state.autofillDirForm
      };
    },

    toggleExistingDir: (state) => {
      return {
        ...state,
        existingDirShown: !state.existingDirShown
      };
    },

    toggleKycModal: (state) => {
      return {
        ...state,
        isKycModalOpen: !state.isKycModalOpen
      };
    },

    closeKycModal: (state) => {
      return {
        ...state,
        isKycModalOpen: false
      };
    },
    setKycCompleteMessage: (state, { payload }: PayloadAction<string>) => {
      return { ...state, kycMessage: payload };
    },
    clearLayoutSlice: ()=> {
      return initialState
    }
  }
});

export const {
  toggleMobileNav,
  toggleSidebar,
  toggleNotifications,
  toggleDashMobileMenu,
  toggleVerifStatus,
  beginKycLoading,
  stopKycLoading,
  setNotifications,
  toggleLogoutModal,
  setKycStatus,
  toggleNewDirectorModal,
  toggleDirAutofill,
  toggleExistingDir,
  toggleKycModal,
  closeKycModal,
  setKycCompleteMessage,
  clearLayoutSlice
} = layoutSlice.actions;

export default layoutSlice.reducer;
