import useToolkit from "./useToolkit";
import { useReactToPrint } from "react-to-print";

function usePdf(docName: string) {
	const { toastSuccess, useRef, useEffect } = useToolkit();
	const targetRef = useRef(null);

	const downloadDoc = useReactToPrint({
		content: () => targetRef.current,
		documentTitle: docName,
		pageStyle: `
  width: '70%',
  margin: '20px auto 0 auto',
		padding: '50px 25px',
		`,
	});

	return { targetRef, downloadDoc };
}

export default usePdf;
