import { SetStateAction, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import OTPInput from "react-otp-input";

interface PinSetupProps {
    value: any;
    valueSetter: SetStateAction<any>;
    onChange?: ()=> void;
}
const PinSet = ({value, valueSetter, onChange}: PinSetupProps )=> {
    const [show, setShow] = useState(false)
    return (
    <div>
        <OTPInput
                    value={value}
                    onChange={(otp)=> valueSetter(otp.toString())}
                    inputStyle="otp-field"
                    numInputs={4}
                    shouldAutoFocus
                    containerStyle="otpinput-container"
                    renderInput={(props) => (
                        <input
                        onKeyPress={(e) => {
                            const keyCode = e.which || e.keyCode;
                            const keyValue = String.fromCharCode(keyCode);
                            const regex = /^[0-9.]$/; // Regex to match numbers and dot (.)
                            if (!regex.test(keyValue)) {
                              e.preventDefault();
                            }
                          }}
                            {...props}
                            type={show ? "pin" : "password"}
                            
                            className="border-[1px] text-[1.5rem] !h-[4.8rem] !w-[3.5rem] outline-none  focus:!border-[#48BBED]  rounded-[2rem]"
                        />
                    )}
                />
                <div className="w-[100%] flex justify-end mt-[-0.5rem] cursor-pointer">
                    <div
                        className="text-[1.6rem] text-gray-500"
                        onClick={() => setShow((prev) => !prev)}>
                        {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </div>
                </div>
    </div> 
    )
}
export default PinSet