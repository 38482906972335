import { useEffect } from 'react'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import useSendFunds from 'utils/hooks/dashboard/useSendFunds';
import OptionalRender from 'components/misc/OptionalRender';
import Spinner from 'components/misc/Spinner';
import { BsDot } from 'react-icons/bs';

const AlipayCallout = () => {
   const { getBankNote, bankNoteLoading: isLoading, bankNoteData: note } = useSendFunds()

   useEffect(() => {
      getBankNote({ cur: 'CNY', bankName: 'alipay' })
      // eslint-disable-next-line
   }, [])

   const formattedNOte = note?.content?.split('-> ')

   return (
      <div className={`w-full bg-[#FDF6EC] p-3 rounded-md mt-4 flex items-start ${isLoading && 'justify-center'}`}>

         <OptionalRender condition={isLoading}>
            <div className="!w-full flexed">
               <Spinner
                  rise
                  isSmall
                  msg='Please wait'
                  customColor='#194153'
               />
            </div>
         </OptionalRender>

         <OptionalRender condition={!isLoading && note?.content?.length > 0}>

            <div className="text-[#0D1016]">
               <div className="flex items-start pb-2">
                  <div className='pt-1'>
                     <IoIosInformationCircleOutline size='1.2rem' />
                  </div>
                  <p className="mb-0 font-semibold lg:text-[16px] ml-1.5">
                     {note?.title}
                  </p>
               </div>

               <div className='space-y-1.5'>
                  {formattedNOte?.map((note: any, index: number) => note?.length > 0 && (
                     <div key={index} className="text-[#0D1016] flex items-start">

                        <span>
                           <BsDot size="1.5rem" />
                        </span>

                        <span className="text-sm">
                           {note}.
                        </span>
                     </div>
                  ))}
               </div>
            </div>
         </OptionalRender>

      </div>
   )
}

export default AlipayCallout