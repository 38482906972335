import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

type recipientDetailsState = {
  userDetails: { [key: string]: any } | null;
};

const initialState: recipientDetailsState = {
  userDetails: null,
};

const recipientDetailsSlice = createSlice({
  name: "recipientDetailsSlice",
  initialState,
  reducers: {
    setRecipientDetails: (
      state,
      { payload }: PayloadAction<{ [key: string]: any } | null>
    ) => {
      state.userDetails = payload;
    },
  },
});

export const { setRecipientDetails } = recipientDetailsSlice.actions;

export const selectRecipientDetails = (state: RootState) =>
  state.recipients_details.userDetails;

export default recipientDetailsSlice.reducer;
