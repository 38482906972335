export enum KYCStatus {
  INCOMPLETE = 'incomplete',
  PENDING = 'pending',
  REJECTED = 'rejected',
  COMPLETED = 'completed',
  UNKNOWN = 'unknown'
}

export enum RemittanceDirective {
  ADD_RECIPIENT = 'add-recipient',
  SEND_RECIPIENT = 'send-funds'
}