import { ReactNode, useMemo } from "react"
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes"
import useToolkit from "utils/hooks/misc/useToolkit"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { motion, AnimatePresence } from 'framer-motion';
import useInternalSendFlow from "utils/hooks/transactions/useInternalSendFlow";

interface LayoutProps {
    children: ReactNode
}
const Layout : React.FC<LayoutProps> = ({ children}) => {
    const {router, useAppSelector} = useToolkit()
    const {transitionDirection} = useInternalSendFlow()
    const direction = useMemo(()=>{
        switch(transitionDirection){
          case 'right':
            return '100%'
          case 'left':
            return '-100%'
          default: '100%'
        }
    }, [transitionDirection])
  return (
    
     <motion.div
     initial={{ x: direction, opacity: 0 }}
     animate={{ x: 0, opacity: 1 }}
     exit={{ x: '-100%' }}
     transition={{ type: 'spring', stiffness: 260, damping: 25 }}
     className="w-full"
   >
     {children}
   </motion.div>
  )
}

export default Layout;