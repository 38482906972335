import { RemittanceDirective } from 'custom-types';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { RecipientTypes } from 'redux/slices/recipientSlice';
import { DASHBOARD_ROUTES } from 'utils/appData/appRoutes';
import useToolkit from 'utils/hooks/misc/useToolkit';

export type HeaderProps = {
  handleCancelCleanup?: () => void;
  recieverType: string;
  remittanceDirective?: RemittanceDirective;
  onlyCancel?: boolean;
};

export const Header = ({ handleCancelCleanup, recieverType, remittanceDirective, onlyCancel }: HeaderProps) => {
  const { router } = useToolkit();
  const handleCancel = () => {
    if (handleCancelCleanup) handleCancelCleanup();
    router.push(
      remittanceDirective
        ? `${DASHBOARD_ROUTES.RECIPIENTS}?type=${RecipientTypes.EXTERNAL}`
        : DASHBOARD_ROUTES.DASHBOARD
    );
  };
  return (
    <div className='w-full max-w-[56.125rem] mr-auto ml-auto'>
      <div className='w-full flex justify-end mb-3'>
        <div
          onClick={handleCancel}
          role='button'
          tabIndex={0}
          className='flex gap-2 cursor-pointer mb-2 items-center'>
          <AiOutlineCloseCircle /> Cancel
        </div>
      </div>
      {!onlyCancel && (
        <div className='w-full px-[1rem] py-[0.75rem] bg-white border-[1px] border-[#E7E9EB] rounded-[0.875rem] flex-col flex justify-center'>
        <p className='text-[0.8rem] mb-1'>who do you want to send money to?</p>
        <h1 className='font-semibold text-[1rem] mb-0'>{recieverType}</h1>
      </div>
      )}
    </div>
  );
};
