import React from 'react';
import { BoardLayout, Spinner, useCurrencySelector, useSendFunds, useToolkit } from 'components';
import { AiFillCaretDown } from 'react-icons/ai';
import { IconType } from 'react-icons';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import {
  convertCurrency,
  toggleKycMagnifier,
  toggleSelectedWallets
} from 'redux/slices/dashboardSlice';
import CurrencyFormat from 'react-currency-format';
import { RiArrowDownSLine } from 'react-icons/ri';
import { DASHBOARD_ROUTES } from 'utils/appData/appRoutes';
import { KYCStatus } from 'custom-types';
import SpecialDropdown, { getFlag } from 'components/common/InputElements/SpecialDropdown';
type ShortcutProps = {
  title: string;
  imgPath: string;
  Icon?: IconType;
  onClick: () => void;
};
type Props = {
  loading: boolean;
  userWallets: WalletProps[];
  selectedWallet: UserWallet;
  kycState: any;
};
type WalletDropdownProps = {
  options: WalletProps[];
  handleMenuClick: (e: any) => void;
  selected: UserWallet;
};

const WalletConvert = ({ userWallets, selectedWallet, kycState }: Props) => {
  const {
    dispatch,
    getSymbol,
    roundUp,
    useAppSelector,
    toastError,
    useState,
    useEffect,
    userId,
    router
  } = useToolkit();

  const { convertDetails } = useAppSelector((state: any) => state.dashboard);

  const handleSelect = (e: any) => dispatch(toggleSelectedWallets(e.key));
  const { rateVals, getConversionRates, sendValue, setSendValue, editingLeft, setEditingLeft } =
    useSendFunds();
  const {
    fromCur,
    rcvCur,
    initConversion,
    convLoading,
    currsArray,
    fromCurObj,
    rcvCurObj,
    onSwap,
    walletsLoading,
    currs,
    currsLoading,
    setRcvCurObj,
    setRcvCur,
    walletsData,
    setFromCurObj,
    setFromCur
  } = useCurrencySelector();
  const initialize = () => {
    const payload = {
      purpose: 'convert',
      fromCur: fromCur,
      toCur: rcvCur,
      fromAmount: editingLeft ? sendValue : Number(returnedValue)
    };
    dispatch(convertCurrency(payload));
  };
  const shortcuts: ShortcutProps[] = [
    {
      title: 'Convert funds',
      imgPath: '/assets/icons/wallet/empty-wallet-change.svg',
      onClick: () => {
        if (kycState === KYCStatus.COMPLETED) {
          initialize();
          router.push(DASHBOARD_ROUTES.CONVERT);
        } else {
          dispatch(toggleKycMagnifier(true));
          setTimeout(() => dispatch(toggleKycMagnifier(false)), 1000);
        }
      }
      // onClick: () => dispatch(toggleTopupModal()),
    }
  ];

  const returnedValue = rateVals?.receiving?.split(' ')[1] || rateVals?.rate.toFixed(2);
  const currsArentEqual = fromCur !== rcvCur;
  const [dropShown, setDropShown] = useState(false);
  const [dropShownRc, setRcDropShown] = useState(false);
  const toggleDropdown = () => {
    setDropShown(!dropShown);
    setRcDropShown(false);
  };
  const toggleRceDropdown = () => {
    setRcDropShown(!dropShownRc);
    setDropShown(false);
  };
  const sameCurrency = fromCur === rcvCur;
  const onCurrencySelect = (item: UserWallet) => {
    setFromCurObj(item);
    setFromCur(item.cur);
    toggleDropdown();
  };
  const onCurrencySelectRc = (item: any) => {
    setRcvCurObj(item);
    setRcvCur(item.cur);
    toggleRceDropdown();
  };

  useEffect(() => {
    if (!fromCur?.length || !rcvCur?.length) return;
    else if (fromCur === rcvCur) {
      toastError('Cannot convert same currencies');
    } else {
      const ratePayload: ConversionPayload = {
        from: fromCur || 'USD',
        to: rcvCur || 'NGN',
        userId,
        transType: ''
      };
      getConversionRates(ratePayload);
    }
  }, [fromCur, rcvCur, sendValue]);

  const WalletsDropdown = () => (
    <div className='absolute  top-30 space-y-4 py-4 pl-3 w-[100px] bwite shadow-lg z-20'>
      {walletsData?.map((item: UserWallet) => (
        <div
          onClick={() => onCurrencySelect(item)}
          className='flect curp'
          key={item.cur}>
          <img
            src={item.flag}
            className='h-5 w-5  rounded-full object-cover'
            alt={item.cur}
            width={30}
          />{' '}
          <p className='mb-0 text-xs font-medium ml-4 '>{item.cur}</p>
        </div>
      ))}
    </div>
  );

  const WalletsDropdownRce = () => (
    <div className='absolute   top-30 space-y-4 py-4 pl-3 w-[100px] bwite shadow-lg z-20'>
      {walletsData?.map((item: UserWallet) => (
        <div
          onClick={() => onCurrencySelectRc(item)}
          className='flect curp'
          key={item.cur}>
          <img
            src={item.flag}
            className='h-5 w-5  rounded-full object-cover'
            alt={item.cur}
            width={30}
          />{' '}
          <p className='mb-0 text-xs font-medium ml-4 '>{item.cur}</p>
        </div>
      ))}
    </div>
  );


  const wallet: DropdownProps[] = walletsData?.map((item: any) => {
    return {
      title: item?.cur + ' - ' + item.currency,
      value: item.cur,
      label: item?.cur.slice(0, 2)
    };
  });

  return (
    <div className='bg-[#EDF8FD] min-h-[250px] py-3 px-2 md:px-8  w-full justify-center flex items-center rounded-xl'>
      <div className='w-[100%]   '>
        <div className=' flex  md:mb-2 '>
          <div className='grow '>
            <div className='font-semibold mt-1 md:mt-0 md:text-sm'>Our rates</div>
            <div className='text-[0.7rem] hidden md:flex'>
              {' '}
              Please note that rates for transactions may vary.
            </div>
          </div>
          <div className='flex items-center justify-end '>
            <div>
              {shortcuts.map(({ title, imgPath, onClick, Icon }) => (
                <button
                  onClick={onClick}
                  key={title}
                  disabled={
                    !currsArray.includes(fromCur) ||
                    !currsArray.includes(rcvCur) ||
                    !sendValue ||
                    sendValue < 1 ||
                    sameCurrency
                  }
                  className={`group w-max bg-[#2B708E] text-white  mb-2 sm:mb-1  lg:mb-0 rounded-md flex items-center px-2.5 py-1 sm:py-1 curp hover:scale-105 transition-transform ${(sendValue && sendValue < 1) ||
                      !sendValue ||
                      sameCurrency ||
                      !currsArray.includes(fromCur) ||
                      !currsArray.includes(rcvCur)
                      ? 'cursor-not-allowed '
                      : ''
                    }`}>
                  <img
                    className='w-6'
                    src={imgPath}
                    alt={title}
                  />
                  <span className='ml-2 nowrap text-xs'>{title}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className=' w-full rounded-xl flex justify-center items-center  bg-[#DAEFF8]'>
          <div className='w-full p-2 pl-4 '>
            <div className='flex  '>
              <div className='flex-1'>
                <div className=' mb-[0.2rem] font-medium'>From</div>
                <div className=' w-[6.5rem] flex justify-start flex-col relative'>
                  <SpecialDropdown
                    options={wallet}
                    isFlagged
                    getFlagBy={getFlag.LABEL}
                    displayISOOnly
                    initialValue={fromCur || 'USD'}
                    valueSetter={setFromCur}
                  />
                </div>
              </div>
              <div className='flex-1  flex flex-col  justify-center items-end'>
                <div className='mb-2  sm:mr-10 font-medium text-[0.8rem]  hidden sm:flex'>
                  Enter amount{' '}
                </div>
                <div className='flex  md:mt-0  w-full md:w-32 justify-end md:justify-center  items-center '>
                  {/* <div className="h-full flex  items-center  text-[0.9rem] font-medium">
                  {getCurrencySymbol()}
                 </div> */}
                  <div className='h-full flex items-center'>
                    <CurrencyFormat
                      value={!Number(sendValue) ? '' : editingLeft ? sendValue : returnedValue}
                      placeholder='0.00'
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      onValueChange={({ floatValue }) => {
                        if (floatValue && !Number(floatValue)) {
                          toastError('Cannot convert negative values');
                        } else {
                          setEditingLeft(true);
                          setSendValue(floatValue);
                        }
                      }}
                      className='conv-input-new'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className=' h-8 mt-4 md:mt-2 mb-2 md:mb-0 flex'>
              <div className='flex-1 relative flex items-center justify-center'>
                <div className='h-[0.4px] w-full bg-[#BFDDEA]' />
                <img
                  src='/assets/icons/wallet/convert-vector.svg '
                  className='absolute top-2 cursor-pointer md:left-10 '
                  onClick={onSwap}
                  alt='swap'
                />
              </div>
              <div className='h-full rounded-2xl flex items-center  justify-center bg-[#BFDDEA] '>
                <div>
                  <p className=' mt-3 px-2 text-black text-[0.7rem] md:text-[0.8rem] md:font-medium'>
                    {fromCur}&nbsp;1 = &nbsp; {rcvCur} {rateVals?.rate}{' '}
                  </p>
                </div>
              </div>
              <div className='flex-1  flex items-center justify-center'>
                <div className='h-[0.4px] w-full bg-[#BFDDEA]' />
              </div>
            </div>
            <div className='flex'>
              <div className='flex-1'>
                <div className='mb-[0.2rem] font-medium'>To</div>

                <div className=' w-[6.5rem] flex justify-start flex-col relative'>
                  <SpecialDropdown
                    options={wallet}
                    isFlagged
                    getFlagBy={getFlag.LABEL}
                    displayISOOnly
                    initialValue={rcvCur || 'NGN'}
                    valueSetter={setRcvCur}
                  />
                </div>
              </div>
              <div className='flex-1   flex flex-col  justify-center items-end'>
                <div className='font-medium mb-2 sm:mr-10 hidden text-[0.8rem] sm:flex'>
                  Enter amount{' '}
                </div>
                <div className='flex  md:mt-0   w-full md:w-32 justify-end md:justify-center  items-center'>
                  {/* <div className="h-full flex  items-center   text-[0.9rem] font-medium">
                   {getCurrencyRcSymbol()}  
                  </div> */}
                  <div className='h-full flex items-center'>
                    <CurrencyFormat
                      placeholder={'0.00'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      onValueChange={({ floatValue }) => {
                        if (floatValue && !Number(floatValue)) {
                          toastError('Cannot convert negative values');
                        } else {
                          setEditingLeft(false);
                          setSendValue(floatValue);
                        }
                      }}
                      value={!Number(sendValue) ? '' : editingLeft ? returnedValue : sendValue}
                      type='text'
                      className='conv-input-new'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-[0.7rem] mt-1 md:mt-0 md:hidden'>
          {' '}
          Please note that rates for transactions may vary.
        </div>
      </div>
    </div>
  );
};

export default WalletConvert;
