import { motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import { toggleSendOptions } from "redux/slices/dashboardSlice";
import useFramer from "utils/animations/useFramer";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";
import useDashboard from "utils/hooks/dashboard/useDashboard";
import useToolkit from "utils/hooks/misc/useToolkit";

type Props = {
	isInline?: boolean;
};

const TopupOptions = ({ isInline }: Props) => {
	const { dropdown } = useFramer();
	const { router, dispatch, useEffect, useAppSelector, toastError } =
		useToolkit();
	const { topupCurrency, adminTopupPayload } = useAppSelector(
		(state) => state.dashboard
	);
	const { useGetTopup } = useDashboard();
	const { isLoading, mutate: getTopupInfo } = useGetTopup();

	const dpiUnavailable = !adminTopupPayload?.payin_source?.length;

	const previewItems = [
		{
			title: "Bank Transfer",
			onClick: () => router.push(DASHBOARD_ROUTES.TOPUP_TRANSFER),
			subtitle:
				"Seamlessly transfer funds with Qwid's user-friendly bank transfer feature",
			disabled: false,
		},

		// {
		// 	title: "Direct Pay In",
		// 	subtitle: isLoading
		// 		? `Checking availability for ${topupCurrency}`
		// 		: dpiUnavailable
		// 		? "Currently Unavailable"
		// 		: "Effortlessly accept and process deposits with Direct Pay In, your user-friendly solution",

		// 	disabled: isLoading || dpiUnavailable,
		// 	loading: isLoading,
		// 	onClick: () =>
		// 		isLoading || dpiUnavailable
		// 			? toastError("Not Allowed")
		// 			: router.push(DASHBOARD_ROUTES.TOPUP_DIRECT),
		// },
	];

	useEffect(() => getTopupInfo(), []);

	return (
		<motion.div
			variants={dropdown}
			initial="hidden"
			animate="visible"
			className={`w-full lg:w-[40%] mb-7 p-4 pb-9 bwite shadow-xl rounded-md ${
				isInline && "absolute top-3 left-3 z-20"
			}`}>
			<div className="w-full flex-btw">
				<p className="med twide text-lg lg:text-xl med my-3">Top up by</p>
				{isInline && (
					<IoMdClose
						className="text-2xl curp"
						onClick={() => dispatch(toggleSendOptions())}
					/>
				)}
			</div>
			<div className="flexed flex-col space-y-5">
				{previewItems.map(({ title, subtitle, onClick, disabled }) => (
					<div
						style={{
							cursor: disabled ? "not-allowed" : "pointer",
							backgroundColor: disabled ? "#f1f7f8" : "",
						}}
						onClick={onClick}
						key={title}
						className={`py-3 px-5 w-full rounded-md border border-[#252D3E] hover:shadow-md trall ${
							disabled ? "cursor-not-allowed bg-red-500" : "curp"
						}}`}>
						<p className="mb-1 dpgry-txt lg:text-lg med">{title}</p>
						<p className="text-sm text-[#7c7c7c] mb-0">{subtitle}.</p>
					</div>
				))}
			</div>
		</motion.div>
	);
};

export default TopupOptions;
