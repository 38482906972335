import {
	CenterModal,
	Spinner,
	useToolkit,
	Tooltip,
	QwidButton,
	useBeneficiary,
} from "components";
import React from "react";
import { closeSingleBeneficiary } from "redux/slices/transactionsSlice";
import styled from "styled-components";

type Props = {};

const DetailedBeneficiaryView = (props: Props) => {
	const { useAppSelector, dispatch, avail } = useToolkit();
	const { isSingleBenefOpen, selectedBeneficiary: benef } = useAppSelector(
		(state) => state.transactions
	);
	const { deleteBeneficiary, dltLoading } = useBeneficiary();

	return (
		<CenterModal
			onClose={() => dispatch(closeSingleBeneficiary())}
			control={isSingleBenefOpen}
			title="Manage Beneficiary">
			{dltLoading ? (
				<Spinner />
			) : (
				<>
					<Container>
						<div className="wrapper">
							<p className="mb-0 trans-value">Name</p>
							<p className="mb-0 trans-key">{avail(benef?.accountName)}</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">Type</p>
							<p className="mb-0 trans-key">
								{benef?.beneficiaryType === 1 ? "Individual" : "Business"}
							</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">Currency</p>
							<p className="mb-0 trans-key">{avail(benef?.cur)}</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">Bank Code</p>
							<p className="mb-0 trans-key">{avail(benef?.bankCode)}</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">Account No.</p>
							<p className="mb-0 trans-key">{avail(benef?.accountNo)}</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">SWIFT / BIC</p>
							<p className="mb-0 trans-key">{avail(benef?.swift)}</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">Beneficiary Address</p>
							<p className="mb-0 trans-key flect">
								{avail(benef?.address?.substring(0, 18))}
								<span className="ml-1">
									<Tooltip text={benef?.address} />
								</span>
							</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">Bank Address</p>
							<p className="mb-0 trans-key flect">
								{avail(benef?.bankAddress?.substring(0, 18))}
								<span className="ml-1">
									<Tooltip text={benef?.bankAddress} />
								</span>
							</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">Province / State</p>
							<p className="mb-0 trans-key">{avail(benef?.state)}</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">City</p>
							<p className="mb-0 trans-key">{avail(benef?.city)}</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">Country</p>
							<p className="mb-0 trans-key">{avail(benef?.country)}</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">Sort Code</p>
							<p className="mb-0 trans-key">{avail(benef?.bankSortCode)}</p>
						</div>

						<div className="wrapper">
							<p className="mb-0 trans-value">Routing Number</p>
							<p className="mb-0 trans-key">{avail(benef?.bankRoutingNo)}</p>
						</div>
					</Container>
					<div className="flexed">
						<QwidButton
							onClick={() => deleteBeneficiary(benef?.id)}
							danger
							text={<p className="mb-0 px-4">Delete</p>}
						/>
					</div>
				</>
			)}
		</CenterModal>
	);
};

export default DetailedBeneficiaryView;

const Container = styled.div`
	margin-bottom: 30px;
	margin-top: 15px;

	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 18px;
	}

	.trans-key {
		color: #252d3e;
		font-weight: 500;
	}

	.trans-value {
		color: #62666e;
	}
`;
