import { useMemo, useState } from 'react';
import useToolkit from '../misc/useToolkit';
import {
  BusinessOnboardingPageViews,
  ContactInformationPayload,
  PersonalInformationPayload,
  PersonalOnboardingPageViews,
  incrementCurrentTab,
  setCurrentTab,
  setPersonalDefaultState,
  setPersonalInfo,
  setContactInfo,
  updateVerificationFieldsPerCountry,
  VerifyIdentityInformationPayload,
  setVerifyIdentityInfo,
  setUploadDocsInfo,
  UploadDocsInformationPayload,
  decrementCurrentTab,
  setRepCompleted,
  setBusInfoCompleted,
  setDirectorsCompleted,
  setBusInfoAllowed,
  setDirectorsAllowed,
  setSummaryAllowed,
  setSummaryDisallowed,
  setBusUploadAllowed,
  setSummaryCompleted,
  setDirectorsIncompleted,
  setSummaryIncompleted,
  setBusUploadDisallowed,
  setBusinessKycStatus,
  setPersonalSummaryAsCompleted
} from 'redux/slices/onboardingSlice';
import { MutationFunction, useMutation, useQuery, useQueryClient } from 'react-query';
import useKyc from '../settings/useKyc';
import useUser from '../settings/useUser';
import { setBusinessDetails } from 'redux/slices/onboardingDataSlice';
import { countryCodes } from 'utils/appData/countryCodes';
import useLocation from '../dashboard/useLocation';
import { setKycCompleteMessage } from 'redux/slices/layoutSlice';
import { businessTypeValues } from 'components/onboarding/shared/Options';
import { vtnCountries } from 'utils/appData/vtnCountries';
import { KYCStatus } from 'custom-types';

const personalOnboardingStageFields = {
  // fields gotten from endpoint
  // key are the api field for existing data whilst the value is the api field for the post/update endpoints
  [PersonalOnboardingPageViews.PERSONAL_INFORMATION]: {
    firstName: { apiField: 'firstName', required: true },
    lastName: { apiField: 'lastName', required: true },
    dob: { apiField: 'dob', required: true },
    sex: { apiField: 'sex', required: true },
    employmentStatus: { apiField: 'employmentStatus', required: true },
    pep: {
      apiField: 'pep', // Todo: The fields have not been updated to the api so this must be updated once the API is ready for this field
      required: false
    },
    nationality: {
      apiField: 'nationality', // Todo: The field should be for the nationality input but has been set to country until its logic is determined by the backend
      required: false
    }
  },
  [PersonalOnboardingPageViews.CONTACT_INFORMATION]: {
    country: { apiField: 'country', required: true },
    countryCode: { apiField: 'countryCode', required: false },
    mobileCode: { apiField: 'mobileCode', required: false },
    email: { apiField: 'email', required: true },
    house_number: { apiField: 'house_number', required: false },
    address: { apiField: 'address', required: true },
    state: { apiField: 'state', required: true },
    city: { apiField: 'city', required: true },
    zipcode: { apiField: 'zipcode', required: true },
    mobile: { apiField: 'mobile', required: true }
  },
  [PersonalOnboardingPageViews.VERIFY_IDENTITY]: {
    country: { apiField: 'country', required: true },
    identity_type: { apiField: 'identityType', required: true }
  },
  [PersonalOnboardingPageViews.SUMMARY]: {},
  [PersonalOnboardingPageViews.UPLOAD_DOCS]: {
    kycStatus: {
      apiField: 'kycStatus',
      required: true
    }
  }
};

/**
 * Todo: Replace all type - "any" with actual type values
 */
const useOnboarding = <T>(type: 'personal' | 'business') => {
  const {
    useAppSelector,
    dispatch,
    userObj,
    axiosInstance,
    userType,
    camelToSnakeCasing,
    toastError,
    userId,
    toastSuccess
  } = useToolkit();
  const { getDynStates, stateData } = useLocation();
  const { verifyUserKyc, determineUserKycStatus } = useKyc();
  const { getUserDetails } = useUser();
  const [loadingPersonalDefaultState, setLoadingPersonalDefaultState] = useState(false);
  const state = useAppSelector((state) => state.onboarding);
  const dashboardState = useAppSelector((state) => state.dashboard);
  const { businessDetails, shareHoldersInfo } = useAppSelector((state) => state.onboardingData);
  const userState = useAppSelector((state) => state.user);
  const queryClient = useQueryClient();
  const incrementOnboardingView = () => {
    dispatch(incrementCurrentTab({ type }));
  };

  const decrementOnboardingView = () => {
    dispatch(decrementCurrentTab({ type }));
  };

  const setOnboardingView = (page: PersonalOnboardingPageViews | BusinessOnboardingPageViews) => {
    dispatch(setCurrentTab({ type, page }));
  };
  const setPersonalOnboardingInfo = (payload: Partial<PersonalInformationPayload>) => {
    dispatch(setPersonalInfo(payload));
  };
  const setContactOnboardingInfo = (payload: Partial<ContactInformationPayload>) => {
    dispatch(setContactInfo(payload));
  };
  const setSummaryAsCompletedPersonal = () => {
    dispatch(setPersonalSummaryAsCompleted());
  };

  const useGetVerificationForm = useMutation({
    mutationFn: (async (payload: { country: string }) => {
      let verifyIdentityFieldData = state?.personal?.identityVerificationFields?.[payload.country];
      if (verifyIdentityFieldData?.length) {
        return verifyIdentityFieldData;
      }
      const { data } = await axiosInstance.post('verification-tool/form', {
        ...payload,
        userType
      });
      verifyIdentityFieldData = data?.content?.data?.data;
      dispatch(
        updateVerificationFieldsPerCountry({
          [payload.country]: verifyIdentityFieldData
        })
      );
      return verifyIdentityFieldData;
    }) as MutationFunction<any>,
    onError: (error: any) => {
      const message =
        error?.response?.data?.message || error?.message || 'Unable to fetch verification fields';
      toastError(message);
      return error;
    },
    onSuccess: (data) => data
  });

  const setPersonalOnboardingDefaultState = async (): Promise<void> => {
    dispatch(setKycCompleteMessage(''));
    setLoadingPersonalDefaultState(true);
    // Determine current tab based on user's existing info
    try {
      const userData = await getUserDetails();
      let currentTab: number | undefined = undefined;
      const currentState: Record<string, any> = {};
      // copying working stages fields
      let fieldsMap = {
        ...personalOnboardingStageFields
      };
      const userCopyObj: PlainUserProps & { kycStatus?: KYCStatus } = {
        ...userData
      };
      const verification = userCopyObj?.verification || {};
      const { country: issuingCountry, identity_type } = verification as Record<string, string>;
      // Determine verify identity fields if exists
      if (issuingCountry && identity_type) {
        const { data } = await axiosInstance.post('verification-tool/form', {
          country: issuingCountry,
          userType
        });
        const verifyIdentityFieldData = data?.content?.data?.data;
        // set identity fields by current issuing country
        currentState.identityVerificationFields = {
          [issuingCountry]: verifyIdentityFieldData
        };

        // find field map matching selected identity type
        const identityTypeFields: Record<string, string>[] = verifyIdentityFieldData?.find(
          (idType: any) => idType.id === identity_type
        )?.other_fields;
        if (identityTypeFields?.length) {
          const identityFieldsToUse = identityTypeFields.reduce(
            (acc, field): Record<string, Record<string, unknown>> => {
              acc[camelToSnakeCasing(field.fieldName)] = {
                apiField: field.fieldName,
                required: field.required
              };
              return acc;
            },
            {} as Record<string, Record<string, unknown>>
          );
          fieldsMap = {
            ...fieldsMap,
            [PersonalOnboardingPageViews.VERIFY_IDENTITY]: {
              ...fieldsMap[PersonalOnboardingPageViews.VERIFY_IDENTITY],
              ...identityFieldsToUse
            }
          };
        }
      }
      // Determine document upload status
      let kycStatus = userState.kycStatus;
      if (kycStatus === undefined) {
        const verifyKycData = await verifyUserKyc();
        kycStatus = determineUserKycStatus(verifyKycData);
      }
      userCopyObj.kycStatus = kycStatus;
      // Determine current default tab and set existing kyc field values for each form
      Object.entries(fieldsMap).forEach(([tab, fieldMap]) => {
        const tabPayload: Record<string, string> = {}; // tab values
        Object.entries(fieldMap).forEach(([field, rules]) => {
          const currentValue =
            (+tab as unknown as PersonalOnboardingPageViews) ===
              PersonalOnboardingPageViews.VERIFY_IDENTITY
              ? userCopyObj?.verification?.[field as keyof PlainUserProps['verification']]
              : userCopyObj[field as keyof PlainUserProps];
          if (currentTab === undefined && !currentValue && rules.required) {
            currentTab = Number(tab);
          }
          tabPayload[rules.apiField] = !currentValue ? undefined : (currentValue as any);
        });
        currentState[tab] = {
          completed: currentTab === undefined || (currentTab as number) > Number(tab),
          modified: false,
          payload: tabPayload
        };
        // special case for upload docs page
        if (+tab === PersonalOnboardingPageViews.UPLOAD_DOCS) {
          currentState[tab].completed = [KYCStatus.COMPLETED, KYCStatus.PENDING].includes(
            kycStatus as KYCStatus
          );
        }
      });

      const finalState = {
        ...currentState,
        [PersonalOnboardingPageViews.SUMMARY]: {
          completed:
            currentTab === undefined || (currentTab as number) > PersonalOnboardingPageViews.SUMMARY
        },
        currentTab: currentTab == undefined ? PersonalOnboardingPageViews.UPLOAD_DOCS : currentTab
      };

      dispatch(setPersonalDefaultState(finalState));
      setLoadingPersonalDefaultState(false);
    } catch (error) {
      toastError('Oops! Error initializing steps');
      setLoadingPersonalDefaultState(false);
    }
  };

  const useUpdateBasicPersonalInfo = useMutation({
    mutationFn: (async (payload: PersonalInformationPayload | ContactInformationPayload) => {
      const data = await axiosInstance.patch(`endUsers/profile/${userId}`, payload);

      return data;
    }) as MutationFunction<any>,
    onError: (error: any) => {
      const message = error?.response?.data?.message || error?.message || 'Error updating record';
      toastError(message);
      return error;
    },
    onSuccess: (data) => data
  });

  const useUpdateIdentityInfo = useMutation({
    mutationFn: (async (payload: VerifyIdentityInformationPayload) => {
      const requestHandler =
        userObj?.verification && Object.keys(userObj?.verification || {}).length
          ? axiosInstance.patch
          : axiosInstance.post;
      await requestHandler(`verification/${userId}`, payload);
    }) as MutationFunction<any>,
    onError: (error: any) => {
      const message = error?.response?.data?.message || error?.message || 'Error identity record';
      toastError(message);
      return error;
    },
    onSuccess: (data) => data
  });

  // To be moved
  const isPayloadsTheSame = (
    current: Record<string, unknown>,
    initial: Record<string, unknown>
  ) => {
    return Object.keys(current).some((key) => current[key] !== initial[key]);
  };

  const setVerifyIdentityInfoState = (data: Partial<VerifyIdentityInformationPayload>) =>
    dispatch(setVerifyIdentityInfo(data));

  const stateUploadDocumentState = (data: Partial<UploadDocsInformationPayload>) =>
    dispatch(setUploadDocsInfo(data));

  // Business onboarding utilities
  const {
    mutateAsync: updateBusinessUserDetails,
    isLoading: updatingDetails,
    data: UserData
  } = useMutation({
    mutationFn: async (payload: BusinessAccountProp) => {
      const myPayload = {
        is_applicant_a_director: businessDetails?.is_applicant_a_director,
        ...payload
      };
      const {
        data: { data, message }
      } = await axiosInstance.patch(`endUsers/profile/${userId}`, myPayload);
      return { data, message };
    },
    onError: (error: any) => {
      const message = error?.response?.data?.message || error?.message || 'something went wrong';
      toastError(message);
    },
    onSuccess: ({ message }) => toastSuccess(message)
  });

  /* const {
    mutateAsync: getBusinessUserInfo,
    isLoading : gettingUserDetails,
    data : businessUserData
   } = useMutation({
    mutationFn: (async ()=>{
      const {data :{content : {data}}} = await axiosInstance.get(`endUsers/${userId}`)
    	
      return data[0] 
    }),
    onError: (error: any)=>{
      const message = error?.response?.data?.message || error?.message || 'something went wrong';
      if(message){
        toastError(message)
      }
      else toastError('Network error')
      },
    onSuccess: (data)=>{
    	
      dispatch(setBusinessDetails(data))
      dispatch (setBusinessDetails(data.businessinfo))
    	
    }
    
   }) */
  const getBusinessInfo = async () => {
    try {
      const response = await axiosInstance.get(`endUsers/${userId}`);
      const userData = response.data.content.data[0];

      if (response.status !== 200) {
        throw new Error();
      }
      if (response.data.success === true) {
        await Promise.all([
          dispatch(setBusinessDetails(userData)),
          dispatch(setBusinessDetails(userData.businessinfo))
        ]);
        if (businessDetails) {
        }
      }
      return userData;
    } catch { }
  };
  const getDirNumber = async () => {
    try {
      const response = await axiosInstance.get(`director-info/${userId}`);
      const dirArr = response?.data?.content?.data;
      dispatch(setBusinessDetails({ directorNumber: dirArr ? dirArr.length : '' }));
    } catch (error: any) {
      throw error;
    }
  };

  const capitalizeFirstLetters = (text: string) => {
    text = text.toLowerCase();
    const words = text.split(/[-_ ]+/);

    const capitalizedWords = words.map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(' ');
  };

  const { mutateAsync: sendDirectorInfo, isLoading: sendDirectorInfoLoading } = useMutation({
    mutationFn: (async (payload: any) => {
      const { data } = await axiosInstance.post(`director-info/${userId}`, payload);
      return data;
    }) as MutationFunction<any>,
    onError: (error: any) => {
      const message = error?.response?.data?.message || error?.message || 'Error Sending ';
      toastError(message);
      return error;
    },
    onSuccess: (data: any) => {
      // const message = data.response?.data?.message
      // toastSuccess(message)
      queryClient.invalidateQueries();
      const message = data?.message;
      toastSuccess(message);
    }
  });
  const { mutateAsync: sendDirectorEditInfo, isLoading: sendDirectorEditInfoLoading } = useMutation(
    {
      mutationFn: (async (payload: any) => {
        const { data } = await axiosInstance.patch(`director-info/${userId}`, payload);
        return data;
      }) as MutationFunction<any>,
      onError: (error: any) => {
        const message = error?.response?.data?.message || error?.message || 'Error Sending ';
        toastError(message);
        return error;
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries();
        const message = data?.message;
        toastSuccess(message);
      }
    }
  );
  const useDirectorData = () => {
    const { data: directorData, isLoading: directorDataLoading } = useQuery({
      queryKey: ['getDirector'],
      queryFn: async () => {
        const response = await axiosInstance.get(`director-info/${userId}`);
        return response.data;
      }
    });
    return { directorData, directorDataLoading };
  };
  const { mutateAsync: deleteDirectorInfo, isLoading: deleteDirectorInfoLoading } = useMutation({
    mutationFn: async (token: string) => {
      try {
        const { data } = await axiosInstance.delete(`director-info/${token}`);
        return data;
      } catch (error) { }
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries();
      const message = data?.message;
      toastSuccess(message);
    }
  });
  const { mutateAsync: clearDirectors, isLoading: clearingDirectors } = useMutation({
    mutationFn: async (mode?: string) => {
      try {
        const { data } = await axiosInstance.delete(
          `director-info/clear/${userId}${mode && `?mode=${mode?.toLowerCase()}`}`
        );
        return data;
      } catch (error) { }
    },
    onSuccess: (data: any) => {
      const message = data?.message;
      toastSuccess(message);
    }
  });

  const btypeArray = [
    businessTypeValues.LLC,
    businessTypeValues.CORPORATION,
    businessTypeValues.PUBLIC_LLC,
    businessTypeValues.PRIVATE_LLC,
    businessTypeValues.FREE_TRADE
  ];
  const isSoleProprietor = businessDetails?.businessCategory === businessTypeValues.SOLE_PROPRIETOR;
  const isCorporative =
    businessDetails?.businessCategory === businessTypeValues.COORPERATIVE_SOCIETY;
  const isPatnership = businessDetails?.businessCategory === businessTypeValues.PARTNERSHIP;
  const isNGO = businessDetails?.businessCategory === businessTypeValues.NG0;
  const isDirectorShareholder = btypeArray.includes(
    businessDetails?.businessCategory ? businessDetails?.businessCategory : ''
  );
  const additionalArray = ['director', 'shareholder', 'both'];

  const hasAdditional = useMemo(
    () =>
      !isSoleProprietor && btypeArray.includes(businessDetails?.businessCategory as string)
        ? additionalArray.includes(
          businessDetails?.is_applicant_a_director
            ? businessDetails?.is_applicant_a_director
            : 'xyz'
        )
        : businessDetails?.is_applicant_a_director === 'yes',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [businessDetails?.is_applicant_a_director, businessDetails?.businessCategory]
  );

  const countryNameFromISO = (iso: string | undefined) => {
    return countryCodes.find((item) => item.code.toLowerCase() === iso?.toLowerCase())?.name;
  };
  const countryCodeFromName = (name: string | undefined) => {
    return vtnCountries.find((item) => item.name.toLowerCase() === name?.toLowerCase())
      ?.code as string;
  };
  const stateNamefromISO = (countryISO: string | undefined, stateISO: string | undefined) => {
    //getDynStates(countryISO? countryISO.toUpperCase() : '')
    return stateData.find((item) => (item.value as string) === stateISO?.toUpperCase())?.title;
  };

  const [initializing, setInitializing] = useState(false);
  const initializeBusinessData = async () => {
    setInitializing(true);
    try {
      await getDirNumber();
      await getBusinessInfo();

      if (businessDetails) {
        if (
          !isSoleProprietor &&
          businessDetails?.businessZipcode &&
          businessDetails?.directorNumber &&
          businessDetails?.directorNumber > 0
        ) {
          dispatch(setRepCompleted());
          dispatch(setBusInfoCompleted());
          dispatch(setDirectorsCompleted());
          dispatch(setDirectorsAllowed());
          dispatch(setSummaryAllowed());
          dispatch(setBusUploadAllowed());
          dispatch(setCurrentTab({ type: 'business', page: 3 }));
          dispatch(setSummaryCompleted());
          setInitializing(false);
        } else if (businessDetails?.zipcode && businessDetails.businessZipcode) {
          dispatch(setRepCompleted());
          dispatch(setDirectorsAllowed());
          if (isSoleProprietor) {
            dispatch(setSummaryAllowed());
            dispatch(setCurrentTab({ type: 'business', page: 3 }));
          } else {
            dispatch(setSummaryDisallowed());
            dispatch(setDirectorsAllowed());
            dispatch(setCurrentTab({ type: 'business', page: 2 }));
          }
          dispatch(setBusInfoCompleted());
          setInitializing(false);
        } else if (businessDetails?.zipcode && businessDetails?.zipcode?.length > 0) {
          dispatch(setRepCompleted());
          dispatch(setBusInfoAllowed());
          dispatch(setCurrentTab({ type: 'business', page: 1 }));
          setInitializing(false);
        } else {
          /* else if (dirArr && dirArr.length > 0){
          dispatch(setRepCompleted())
          dispatch(setBusInfoCompleted())
          dispatch(setDirectorsCompleted())
          setInitializing(false)
        } */
          setInitializing(false);
        }
      } else {
        setInitializing(false);
      }
    } catch (error) {
      toastError('Error Initializing');
      setInitializing(false);
    }
  };
  const initializeData2 = async () => {
    setInitializing(true);
    try {
      const { data } = await axiosInstance.get(`endUsers/${userId}`);
      const { data: data2 } = await axiosInstance.get(`director-info/${userId}`);
      const dirArr = data2?.content?.data;
      const userInitialData = data.content.data[0];
      // Determine document upload status
      let kycStatus = userState?.kycStatus;
      if (kycStatus === undefined) {
        const verifyKycData = await verifyUserKyc();
        kycStatus = determineUserKycStatus(verifyKycData);
      }
      dispatch(setBusinessKycStatus({ kycStatus }));
      const businessData = userInitialData.businessinfo;
      const busUserDetails: BusinessAccountProp = {
        ...userInitialData,
        businessinfo: '',
        ...businessData,
        directorNumber: dirArr ? dirArr?.length : 0
      };
      const isSoleProprietor2 =
        busUserDetails.businessCategory === businessTypeValues.SOLE_PROPRIETOR;

      dispatch(setBusinessDetails(busUserDetails));
      if (
        !isSoleProprietor2 &&
        busUserDetails.businessZipcode &&
        busUserDetails?.directorNumber &&
        busUserDetails?.directorNumber > 0
      ) {
        dispatch(setRepCompleted());
        dispatch(setBusInfoCompleted());
        dispatch(setDirectorsCompleted());
        dispatch(setDirectorsAllowed());
        dispatch(setSummaryAllowed());
        dispatch(setBusUploadAllowed());
        dispatch(setCurrentTab({ type: 'business', page: 3 }));
        dispatch(setSummaryCompleted());
        setInitializing(false);
      } else if (busUserDetails?.zipcode && busUserDetails.businessZipcode) {
        dispatch(setRepCompleted());
        dispatch(setDirectorsAllowed());
        if (isSoleProprietor2) {
          dispatch(setSummaryAllowed());
          dispatch(setCurrentTab({ type: 'business', page: 3 }));
        } else if (dirArr?.length === 0) {
          dispatch(setDirectorsIncompleted());
          dispatch(setSummaryIncompleted());
          dispatch(setDirectorsAllowed());
          dispatch(setSummaryDisallowed());
          dispatch(setBusUploadDisallowed());
          dispatch(setCurrentTab({ type: 'business', page: 2 }));
        } else {
          dispatch(setSummaryDisallowed());
          dispatch(setDirectorsAllowed());
          dispatch(setCurrentTab({ type: 'business', page: 2 }));
        }
        dispatch(setBusInfoCompleted());
        setInitializing(false);
      } else if (busUserDetails?.zipcode && busUserDetails?.zipcode?.length > 0) {
        dispatch(setRepCompleted());
        dispatch(setBusInfoAllowed());
        dispatch(setCurrentTab({ type: 'business', page: 1 }));
        setInitializing(false);
      } else {
        /* else if (dirArr && dirArr.length > 0){
        dispatch(setRepCompleted())
        dispatch(setBusInfoCompleted())
        dispatch(setDirectorsCompleted())
        setInitializing(false)
      } */
        setInitializing(false);
      }
      setInitializing(false);
    } catch (error) {
      setInitializing(false);
      throw error;
    }
  };

  return {
    type,
    state: state[type] as unknown as T,
    citiesOptsPerState: dashboardState?.existingCitiesPerState,
    stateOptsPerCities: dashboardState?.existingStatesPerCountry,
    incrementOnboardingView,
    decrementOnboardingView,
    setOnboardingView,
    loadingPersonalDefaultState,
    setPersonalOnboardingDefaultState,
    useUpdateBasicPersonalInfo,
    isPayloadsTheSame,
    setPersonalOnboardingInfo,
    setContactOnboardingInfo,
    useGetVerificationForm,
    useUpdateIdentityInfo,
    setVerifyIdentityInfoState,
    stateUploadDocumentState,
    updateBusinessUserDetails,
    updatingDetails,
    userData: UserData?.data,
    hasAdditional,
    isSoleProprietor,
    isCorporative,
    isPatnership,
    isNGO,
    isDirectorShareholder,
    btypeArray,
    sendDirectorInfo,
    sendDirectorInfoLoading,
    capitalizeFirstLetters,
    useDirectorData,
    sendDirectorEditInfo,
    sendDirectorEditInfoLoading,
    deleteDirectorInfo,
    deleteDirectorInfoLoading,
    countryNameFromISO,
    countryCodeFromName,
    stateNamefromISO,
    getBusinessInfo,
    initializeBusinessData,
    initializeData2,
    initializing,
    clearDirectors,
    clearingDirectors,
    setSummaryAsCompletedPersonal
  };
};

export default useOnboarding;
