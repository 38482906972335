import { Tooltip } from 'antd';
import { BiLogOutCircle } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { toggleSidebar } from 'redux/slices/layoutSlice';
import { LANDING_ROUTES } from 'utils/appData/appRoutes';
import {
  useToolkit,
  useDashboardRoutes,
  SidebarLinkDropdown,
} from '../../components';
import Image from 'next/image';

function BoardSidebar() {
  const { router, dispatch, useAppSelector, logout, useState, Link } =
    useToolkit();
  const { isSidebarExpanded } = useAppSelector((state) => state.dashboard);

  const { dashboardRoutes } = useDashboardRoutes();
  const [childRoutesShown, setChildRoutesShown] = useState(false);

  const LogoutBtn = ({ onClick, isForMobile }: LogoutBtnBtnProps) => {
    return (
      <li
        onClick={onClick}
        className="flect w-full py-3 pl-2 lg:pl-3 curp tracking-wide text-xs transition-all duration-200 hover:scale-105 text-dark-grey"
      >
        <Image
          width={24}
          height={24}
          priority
          src="/assets/icons/sidebar/logout.svg"
          alt="logout"
          className={`${isForMobile ? 'mr-3 text-2xl' : 'mr-2.5 text-xl'}`}
        />
        {isSidebarExpanded && <span>Log out</span>}
      </li>
    );
  };

  return (
    <div
      style={{
        width: isSidebarExpanded ? '18%' : '5%',
        transition: 'all .2s linear',
        borderRight: '1px solid rgba(238, 238, 238, 0.8)',
      }}
      className="bg-white hidden lg:flex flex-col sticky top-0 h-screen bottom-0 overflow-hidden"
    >
      <div className="mt-9 mb-3 w-max curp text-2xl ml-3 lg:hidden">
        <MdClose onClick={() => dispatch(toggleSidebar())} />
      </div>

      {isSidebarExpanded && (
        <div
          onClick={() => router.push(LANDING_ROUTES.PERSONAL_HOMEPAGE)}
          className="w-max mx-auto flexed curp mt-7"
        >
          <img src="/assets/landing/qwidLogo.svg" alt="logo" />
        </div>
      )}

      <div>
        <div
          className={`flex flex-col space-y-4 md:space-y-5 lg:space-y-4 px-2 lg:px-3 mb-7 ${
            isSidebarExpanded ? 'mt-7' : 'mt-14'
          }`}
        >
          {dashboardRoutes.map(
            ({
              title,
              path,
              Icon,
              isDropdown,
              childRoutes,
              iconReference,
            }: RouteProps) => {
              const isActive =
                router.pathname.includes(path) || router.asPath.includes(path);
              return isDropdown ? (
                <SidebarLinkDropdown
                  homePath={path}
                  key={title}
                  childRoutes={childRoutes}
                  title={title}
                  isActive={isActive}
                  isSidebarExpanded={isSidebarExpanded}
                  Icon={Icon}
                />
              ) : (
                <Tooltip
                  key={title}
                  placement="right"
                  title={
                    isSidebarExpanded ? (
                      ''
                    ) : (
                      <span className="text-[12px]">{title}</span>
                    )
                  }
                >
                  <span>
                    <Link href={path}>
                      <a
                        className={`sidebar-route flect w-full py-3 pl-2 lg:pl-3 curp tracking-wide hover:scale-105 hover:text-dark-grey transition-transform ${
                          isActive
                            ? 'text-primary hover:text-primary font-medium'
                            : 'text-dark-grey'
                        }`}
                      >
                        <img
                          src={`/assets/icons/sidebar/${iconReference}-${
                            isActive ? 'active' : 'inactive'
                          }.svg`}
                          alt={title}
                          className="mr-2.5 text-lg md:text-xl lg:text-xl"
                        />
                        {isSidebarExpanded && <span>{title}</span>}
                      </a>
                    </Link>
                  </span>
                </Tooltip>
              );
            }
          )}
          <Tooltip
            placement="right"
            title={
              isSidebarExpanded ? (
                ''
              ) : (
                <span className="text-[12px]">Logout</span>
              )
            }
          >
            <span>
              <LogoutBtn onClick={() => logout()} />
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default BoardSidebar;
