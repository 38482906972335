import { Dropdown } from "antd";
import { GrDown } from "react-icons/gr";
import { useToolkit } from "../../components";
import { toggleMobileNav } from "../../redux/slices/layoutSlice";
import { LANDING_ROUTES } from "utils/appData/appRoutes";

type HelpDropdownProps = {
	hasIcon?: boolean;
	isForMobile?: boolean;
	darkText?: boolean;
};

const HelpDropdown = ({
	hasIcon,
	isForMobile,
	darkText,
}: HelpDropdownProps) => {
	const { router, toastInfo, isMobile, dispatch } = useToolkit();

	const helpItems: {
		title: string;
		subtitle: string;
		imgPath: string;
		route?: string;
		onClick?: () => void;
	}[] = [
		{
			title: "FAQ",
			subtitle: "Providing remittance and financial services",
			imgPath: "/assets/features/faqs.png",
			route: LANDING_ROUTES.FAQS,
			onClick: () => {
				if (isMobile) {
					dispatch(toggleMobileNav());
					router.push(LANDING_ROUTES.FAQS);
				} else {
					router.push(LANDING_ROUTES.FAQS);
				}
			},
		},
		// {
		// 	title: "Contact Us",
		// 	subtitle: "Reach us anytime and anywhere",
		// 	imgPath: "/assets/features/contact.png",
		// 	onClick: () => toastInfo(),
		// },
	];

	const hoverMenu = () => (
		<div
			style={{ zIndex: "999" }}
			className={`px-8 py-5 lg:mr-[15%] flexed flex-col space-y-3 mx-auto bwite ${
				isForMobile
					? "w-full absolute mt-[20px]"
					: "mt-3 w-3/4 md:w-2/4 lg:w-3/4 shadow-2xl rounded-lg"
			}`}>
			{helpItems.map(({ title, subtitle, imgPath, onClick }) => (
				<div
					onClick={onClick}
					className="flex items-center space-x-3.5 w-full curp py-2 group"
					key={imgPath}>
					<img
						src={imgPath}
						alt="icon"
						width={30}
						height={30}
						className="group-hover:scale-110 transition-all duration-250"
					/>
					<div className="">
						<h5 className="mb-0 t6 blk med group-hover:text-[#035C83] transition-colors duration-250">
							{title}
						</h5>
						<p className="mb-0 text-xs blk tracking-wide">{subtitle}</p>
					</div>
				</div>
			))}
		</div>
	);

	return (
		<Dropdown
			className={isForMobile ? "relative" : ""}
			overlay={hoverMenu}
			trigger={["click"]}
			placement="bottom">
			<span
				className={`curp t6 tracking-wide hover:opacity-80 trall med ${
					darkText ? "blk med" : isForMobile ? "softext flect" : "text-white"
				}`}>
				Help {isForMobile && <GrDown className="text-lg ml-auto" />}
			</span>
		</Dropdown>
	);
};

export default HelpDropdown;
