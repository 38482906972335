import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface settingsState {
	isFirstTimeUser: boolean;
	isProfileModalOpen: boolean;
	isBusinessDetModalOpen: boolean;
	businessDetails: null | {};
	profileDetails: null | {};
	isProfileCancelBtnShown: boolean;
	isCreateBankModalOpen: boolean;
}

const initialState: settingsState = {
	isFirstTimeUser: false,
	isProfileModalOpen: false,
	isBusinessDetModalOpen: false,
	businessDetails: null,
	profileDetails: null,
	isProfileCancelBtnShown: true,
	isCreateBankModalOpen: false,
};

const settingsSlice = createSlice({
	name: "settings",
	initialState,
	reducers: {
		toggleProfileModal: (state) => {
			return {
				...state,
				isProfileModalOpen: !state.isProfileModalOpen,
			};
		},

		openProfileModal: (state) => {
			return {
				...state,
				isProfileModalOpen: true,
			};
		},

		toggleFirstTimeStatus: (state) => {
			return {
				...state,
				isFirstTimeUser: !state.isFirstTimeUser,
			};
		},

		kycComplete: (state) => {
			return {
				...state,
				isFirstTimeUser: false,
			};
		},

		beginPersonalProfileUpdate: (state) => {
			return {
				...state,
				isFirstTimeUser: true,
				isProfileModalOpen: true,
				isBusinessDetModalOpen: false,
			};
		},

		completePersonalProfileUpdate: (state) => {
			return {
				...state,
				isFirstTimeUser: false,
				isProfileModalOpen: false,
			};
		},

		toggleBusinessUpdateModal: (state) => {
			return {
				...state,
				isBusinessDetModalOpen: !state.isBusinessDetModalOpen,
			};
		},

		toggleCreateBankModal: (state) => {
			return {
				...state,
				isCreateBankModalOpen: !state.isCreateBankModalOpen,
			};
		},

		beginBusinessDetailsUpdate: (state, { payload }: PayloadAction<any>) => {
			return {
				...state,
				isFirstTimeUser: true,
				isProfileModalOpen: false,
				isBusinessDetModalOpen: true,
				profileDetails: payload,
			};
		},

		completeBusinessDetailsUpdate: (state) => {
			return {
				...state,
				isFirstTimeUser: false,
				isBusinessDetModalOpen: false,
			};
		},

		updateBusinessDetails: (state, { payload }: PayloadAction<any>) => {
			return {
				...state,
				businessDetails: {
					...state.businessDetails,
					...payload,
				},
			};
		},

		clearBusinessDetails: (state) => {
			return {
				...state,
				businessDetails: null,
			};
		},

		hideProfileCancelBtn: (state) => {
			return {
				...state,
				isProfileCancelBtnShown: false,
			};
		},

		showProfileCancelBtn: (state) => {
			return {
				...state,
				isProfileCancelBtnShown: true,
			};
		},
	},
});

export const {
	toggleProfileModal,
	toggleFirstTimeStatus,
	beginPersonalProfileUpdate,
	completePersonalProfileUpdate,
	beginBusinessDetailsUpdate,
	completeBusinessDetailsUpdate,
	toggleBusinessUpdateModal,
	updateBusinessDetails,
	clearBusinessDetails,
	kycComplete,
	openProfileModal,
	hideProfileCancelBtn,
	showProfileCancelBtn,
	toggleCreateBankModal,
} = settingsSlice.actions;

export default settingsSlice.reducer;
