const accTypes = [
	{ title: "Checkings", value: 1 },
	{ title: "Savings", value: 2 },
	{ title: "Ordinary", value: 3 },
];

const methodOptions: DropdownProps[] = [
	{ title: "Wallet", value: 1 },
	{ title: "Bank Transfer", value: 3 },
	{ title: "Blinqpay", value: 2, disabled: true },
];

const nonNigBenefOptions = [
	{ label: "Swift / BIC", name: "swift" },
	{ label: "Bank Name", name: "bank_name" },
	{ label: "Account / IBAN", name: "iban" },
	{ label: "Routing Number", name: "routing" },
];

const benefTypes = [
	{ title: "Individual Beneficiary", value: "1" },
	{ title: "Business Beneficiary", value: "2" },
];

const genders = [
	{ title: "Male", value: "Male" },
	{
		title: "Female",
		value: "Female",
	},
	{ value: 'other', title: "Other" },

];

const employmentStatus = [
	{ title: "Employed", value: "EMPLOYED" },
	{ title: "Unemployed", value: "UNEMPLOYED" },
];

const employmentOptions = [
	{ title: "Employed", value: "employed" },
	{ title: "Unemployed", value: "unemployed" },
];

const lowerCaseRegex = new RegExp("(?=.*[a-z])");
const uppercaseRegex = /(?=.*[A-Z])/;
const numberRegex = /(?=.*[0-9])/;
const symbolRegex = /[^A-Za-z0-9]/;
const eightCharactersRegex = new RegExp("(?=.{8,})");

//  const upper = new RegExp("(?=.*[A-Z])");
//  const number = new RegExp("(?=.*[0-9])");
//  const special = new RegExp("(?=.*[!@#$%^&*])");

const alphaNumericRegexPlusCompoundSupport =
	/^[0-9A-Z]+(( |-)|[0-9A-Z])[0-9A-Z]+$/gi; //supports compound names like Ayo-king and ayo mide

const nameRegex = /^[A-Za-z]+(-[A-Za-z]+)?(\s[A-Za-z]+(-[A-Za-z]+)?)?$/;
// for validating inputs against letters
const numbersOnlyRule = {
	validator: (_: any, value: any) => {
		if (value && !Number(value)) {
			return Promise.reject("Invalid characters");
		}
	},
};

const cardPresets: CardPreset[] = [
	{
		label: "Blue",
		imgPath: "/assets/dashboard/vc-blue.svg",
		color: "#48BBED",
	},
	{
		label: "Black",
		imgPath: "/assets/dashboard/vc-black.svg",
		color: "#000000",
	},
	{
		label: "Deep Blue",
		imgPath: "/assets/dashboard/vc-dpblue.svg",
		color: "#194153",
	},
	{
		label: "Orange",
		imgPath: "/assets/dashboard/vc-orange.svg",
		color: "#F3941F",
	},
	{
		label: "Pink",
		imgPath: "/assets/dashboard/vc-pink.svg",
		color: "#F98AC6",
	},
	{
		label: "Purple",
		imgPath: "/assets/dashboard/vc-purp.svg",
		color: "#7E3D88",
	},
];

export const merchantCategories = [
	"Agriculture",
	"Fintech",
	"Financial services",
	"Gaming",
	"Automobiles",
	"Hospitality",
	"Luxury",
	"Practise",
	"Real Estate",
	"Media",
];

export const merchantTypes = [
	"Corporation",
	"Limited company",
	"Corporative",
	"Nonprofit",
	"Privately held company",
	"Company",
	"Social enterprise",
	"Limited partnerships",
	"Limited liability company",
	"Kommanditgesellschaft",
	"Publicly listed company",
	"Small business",
	"Joint venture",
	"Private limited company",
	"Holding company",
	"Partnership",
	"General partnership",
	"S corporation",
	"C corporation",
	"Limited liability partnership",
	"Franchising",
	"Private company limited",
	"Benefit corporation",
	"Sole proprietorship",
];
function getErrorMsg(firstName: string, lastName: string): string  {
	const nameRegex = /^[A-Za-z]+(-[A-Za-z]+)?$/;
  
	const generateErrorMessage = (name: string, fieldName: string): string | null => {
	  if (name.length === 0) {
		return `${fieldName} cannot be empty.`;
	  }
	  if (/\d/.test(name)) {
		return `${fieldName} must not contain numbers.`;
	  }
	  if (/[^\w\s-]/.test(name)) {
		return `${fieldName} must not contain special characters.`;
	  }
	  if ((name.match(/-/g) || []).length > 1) {
		return `${fieldName} must not contain more than one hyphen.`;
	  }
	  if (!nameRegex.test(name)) {
		return `${fieldName} is invalid.`;
	  }
	  return null; // No error
	};
  
	// Validate first name
	const firstNameError = generateErrorMessage(firstName, 'First name');
	if (firstNameError) {
	  return firstNameError;
	}
  
	// Validate last name
	const lastNameError = generateErrorMessage(lastName, 'Last name');
	if (lastNameError) {
	  return lastNameError;
	}
  
	return ''; // Both names are valid
  }

export {
	accTypes,
	nonNigBenefOptions,
	methodOptions,
	benefTypes,
	lowerCaseRegex,
	eightCharactersRegex,
	uppercaseRegex,
	symbolRegex,
	numberRegex,
	numbersOnlyRule,
	genders,
	cardPresets,
	employmentStatus,
	alphaNumericRegexPlusCompoundSupport,
	nameRegex,
	getErrorMsg
};
