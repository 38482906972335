import styled from 'styled-components';
import { AuthNav, useToolkit } from '../components';
import { ReactNode } from 'react';
import { DASHBOARD_ROUTES, ONBOARDING_ROUTES } from 'utils/appData/appRoutes';

type Props = {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  noAnimation?: boolean;
  hasDashboardButton?: boolean;
  noCompanySVG?: boolean;
  isTieredOnboarding?: boolean;
  hideAuthNavButton?: boolean;
  tieredOnboardingTitle?: string | ReactNode;
  greyBg?: boolean;
};

const AuthLayout = ({
  children,
  title,
  subtitle,
  noAnimation,
  hasDashboardButton,
  noCompanySVG,
  isTieredOnboarding,
  tieredOnboardingTitle,
  hideAuthNavButton,
  greyBg,
}: Props) => {
  const {
    HeadEl,
    useEffect,
    isNigPersonalAccount,
    isLocalEnv,
    router,
    isTierZeroUser,
    isTierOneUser,
    isTierTwoUser,
    toastError
  } = useToolkit();

  const isTierZeroRoute = router.pathname === ONBOARDING_ROUTES.TIERED_ONBOARDING.ADDITIONAL_INFORMATION;
  const isTierOneRoute = router.pathname === ONBOARDING_ROUTES.TIERED_ONBOARDING.IDENTITY_SUBMISSION;
  const isTierTwoRoute = router.pathname === ONBOARDING_ROUTES.TIERED_ONBOARDING.ADDRESS_VERIFICATION;

  useEffect(() => {
    if (isNigPersonalAccount && !isLocalEnv) {

      if ((isTierZeroRoute && !isTierZeroUser) || (isTierOneRoute && !isTierOneUser) || (isTierTwoRoute && !isTierTwoUser)) {
        toastError('Access denied for this page');
        router.push(DASHBOARD_ROUTES.DASHBOARD)
      }

    }
    // eslint-disable-next-line
  }, [isLocalEnv, isNigPersonalAccount, router.pathname])


  return (
    <>
      <HeadEl isAuth />
      <AuthNav
        hideAuthNavButton={hideAuthNavButton}
        isTieredOnboarding={isTieredOnboarding}
        hasDashboardButton={hasDashboardButton}
      />
      <Main className="flex flex-wrap lg:flex-nowrap">
        <section
          className={`flex flex-col items-center justify-center min-h-full px-4 sm:px-8 lg:pl-14 py-7 lg:py-10 ${noAnimation
            ? `bg-[#FFFFFF] w-full`
            : `${greyBg ? 'bg-[#FAFAFA]' : ''} w-full `
            } ${isTieredOnboarding && '!w-full'}`}>

          <p className="mb-7 lg:mb-12 smb tracking-wide text-lg mt-2 lg:mt-0 lg:text-2xl text-[#121212] font-semibold w-full lg:w-4/6 mr-auto">
            {title}
            {subtitle && (
              <span className="mt-3 font-normal block text-sm text-[#667085]">
                {subtitle}
              </span>
            )}
          </p>

          <div
            className={`${isTieredOnboarding || noCompanySVG ? 'w-full max-w-[32rem] mx-auto lg:pb-40' : 'w-full'
              }`}>
            <p className="mb-5 text-qwid-dark-200 font-semibold text-[1rem] lg:text-xl">
              {tieredOnboardingTitle}
            </p>
            {children}
          </div>
        </section>

        {!noCompanySVG && !isTieredOnboarding && (
          <section className={`flex items-start justify-center min-h-full w-full lg:w-2/4 ${greyBg && 'bg-qwid-gray-500'}`}>
            <img src="/assets/features/auth-img.png" alt="icon" />
          </section>
        )}
      </Main>
    </>
  );
};

export default AuthLayout;

const Main = styled.main`
  min-height: calc(100vh - 70px);
`;
