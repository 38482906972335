import {
	CustomInputField,
	CustomSelector,
	useSendFunds,
	useToolkit,
	useBeneficiary,
	Spinner,
	QwidButton,
} from "../../components";
import { Form, message } from "antd";
import { setWalletFlag } from "redux/slices/dashboardSlice";
import { toggleCreateBankModal } from "redux/slices/settingsSlice";
import { accTypes } from "utils/appData/dashboardMisc";

type Props = {};

const AddBankDetailsForm = ({}: Props) => {
	const { useState, useEffect, useRef, dispatch, clearFlag } = useToolkit();

	const {
		useBankParams,
		useAddBank,
		verifyBankDetails,
		verifyLoading,
		paramsData,
		setParamsData,
		banksLoading,
		getNgnBanks,
		banksData,
		verifyData,
		accountNameFound,
		accountNumFound,
		bankNameFound,
	} = useBeneficiary();

	const { isLoading, mutate: getBankParams } = useBankParams();

	const { isLoading: addBankLoading, mutate: addBank } = useAddBank();
	const [rcvCountry, setRcvCountry] = useState("");
	const [rcvCurrency, setRcvCurrency] = useState("");
	const [formDetails, setFormDetails] = useState<any>({});

	const isNairaCur = rcvCurrency === "NGN";
	const isNgnNaira = rcvCountry === "NG" && rcvCurrency === "NGN";

	const isUSDCur = rcvCurrency === "USD";

	const isNigerianBank =
		paramsData?.length > 0
			? rcvCountry === "NG" && rcvCurrency === "NGN"
			: false;

	// const isNigerianBank =
	// 	paramsData?.length > 0
	// 		? rcvCountry === "NG" &&
	// 		  rcvCurrency === "NGN" &&
	// 		  paramsData[0]?.fieldName === "bankCode"
	// 		: false;

	const { countriesLoading, countriesData, getAllRecipientCountries } =
		useSendFunds();

	const HALF_WIDTH_WRAPPER =
		"w-full flexed flex-col md:flex-row md:justify-between md:items-start flex-wrap";
	const HALF_WIDTH_CHILD = "w-full md:w-[45%] lg:w-[47%] md:mr-5 lg:mr-0";

	const updateAdditionalDetails = (fieldName: string, value: string) => {
		setFormDetails({
			...formDetails,
			[fieldName]: value,
		});
	};

	const validAccName = verifyData?.current?.accountName;
	const validAccNum = verifyData?.current?.accountNo;
	const validBankName = verifyData?.current?.bankName;

	const onSubmit = (values: any) => {
		if (!formDetails?.accountType) {
			message.error("Please select an account type");
		} else {
			const payload = {
				cur: rcvCurrency,
				bankName: bankNameFound
					? validBankName
					: isNairaCur
					? values.bankName?.title
					: formDetails?.bankName || values?.bankName,
				accountType: formDetails?.accountType,
				bankCode: isNairaCur ? values?.bankName?.value : null,
				accountNo: values?.accountNo,
				accountName: accountNameFound ? validAccName : values?.accountName,
				country: rcvCountry,
				address: values?.address || values?.bankAddress,
				state: values?.state || values?.bankState,
				zipcode: values?.zipcode,
				swift: values?.swift,
				iban: values?.iban,
				bankRoutingNo: values?.routine || null,
			};
			addBank(payload);
		}
	};

	const handleCountryChange = (selectedOption: any) => {
		setRcvCountry(selectedOption?.value);
	};
	const submitRef = useRef<HTMLButtonElement>(null);

	const selectedNGBank: any = banksData?.find(
		(item: any) => item?.value === formDetails?.bankName
	);

	const handleCurrencyChange = (e: any) => {
		const val = e.target.value;
		setRcvCurrency(val);
		dispatch(setWalletFlag(val));
	};

	useEffect(() => {
		getAllRecipientCountries();
		getNgnBanks();
		clearFlag();
	}, []);

	useEffect(() => {
		if (accountNameFound) {
			const newParams = paramsData?.filter(
				(item: any) => item?.fieldName !== "accountName"
			);
			setParamsData(newParams);
		}

		if (bankNameFound) {
			const newParams = paramsData?.filter(
				(item: any) => item?.fieldName !== "bankName"
			);
			setParamsData(newParams);
		}
	}, [verifyData.current]);

	useEffect(() => {
		if (rcvCountry?.length && rcvCurrency?.length) {
			const payload = {
				cur: rcvCurrency,
				country: rcvCountry,
			};
			getBankParams(payload);
		}
	}, [rcvCountry, rcvCurrency]);

	const verifyNgnAccount = () => {
		if (formDetails?.accountNo?.length !== 10) {
			message.error("Account number must be 10 digits");
		} else if (!formDetails?.bankName?.length) {
			message.error("Please select a bank");
		} else {
			const ngnPayload = {
				country: rcvCountry,
				accountNo: formDetails?.accountNo,
				bankCode: selectedNGBank?.value,
			};
			verifyBankDetails(ngnPayload, "bank-verify");
		}
	};

	const verifyWithSwift = () => {
		if (!formDetails?.swift || formDetails?.swift?.length < 8) {
			message.error("Please enter valid swift details");
		} else {
			const payload = {
				account: formDetails?.swift,
				mode: "SWIFT",
			};
			verifyBankDetails(payload, "swift-iban-verify");
		}
	};

	const verifyWithIban = () => {
		if (!formDetails?.iban || formDetails?.iban?.length < 8) {
			message.error("Please enter valid iban details");
		} else {
			const payload = {
				account: formDetails?.iban,
				mode: "IBAN",
			};
			verifyBankDetails(payload, "swift-iban-verify");
		}
	};

	const AccountTypeSelector = () => (
		<div className="w-full pb-2">
			<CustomSelector
				required
				customName="accountType"
				customLabel="Account Type"
				noArrow
				isSearchable
				options={accTypes?.map((item: any) => ({
					title: item.title,
					label: item.title,
					value: item.value,
				}))}
				onCustomChange={(e) => {
					setFormDetails({
						...formDetails,
						accountType: e.value,
					});
				}}
				placeholder="Select Account Type"
			/>
		</div>
	);

	const NgnBanksSelector = () => (
		<div className="w-full pb-2">
			<CustomSelector
				required
				customName="bankName"
				customLabel="Bank name"
				noArrow
				isSearchable
				options={banksData}
				onCustomChange={(e) => {
					setFormDetails({
						...formDetails,
						bankName: e.value,
					});
				}}
				placeholder={banksLoading ? "Fetching banks..." : "Select Bank"}
			/>
		</div>
	);

	const handleModalClose = () => dispatch(toggleCreateBankModal());

	const swiftIncluded = paramsData?.find((item: any) =>
		item?.fieldName === "swift" ? true : false
	);

	const ibanIncluded = paramsData?.find((item: any) =>
		item?.fieldName === "iban" ? true : false
	);

	const VerifyAccNumberBtn = () => (
		<div className="absolute right-1 top-5">
			<button
				type="button"
				disabled={verifyLoading}
				className="bg-[#194153] text-white px-3 py-1 rounded-sm active:scale-90 transition-all"
				onClick={verifyNgnAccount}>
				Verify
			</button>
		</div>
	);

	const VerifySwiftBtn = () => (
		<div className="absolute right-0 top-[15px]">
			<QwidButton
				loading={verifyLoading}
				type="button"
				darkBlueBg
				text={<span className="px-2">Verify</span>}
				onClick={verifyWithSwift}
			/>
		</div>
	);

	const VerifyIbanBtn = () => (
		<div className="absolute right-0 top-[15px]">
			<QwidButton
				loading={verifyLoading}
				type="button"
				darkBlueBg
				text={<span className="px-2">Verify</span>}
				onClick={verifyWithIban}
			/>
		</div>
	);

	const Placeholder = ({ title, value }: { title: string; value: string }) => {
		return (
			<div className="flex-btw space-x-4 lg:space-x-6">
				<p className="mb-0">{title}:</p>
				<p className="mb-0 med">{value}</p>
			</div>
		);
	};

	return (
		<Form
			onFinish={onSubmit}
			autoComplete="off"
			className="space-y-5 my-7 w-full">
			{(accountNameFound || accountNumFound) && (
				<div className="mx-auto mb-3 bg-green-50 w-max px-4 py-2">
					{accountNumFound && (
						<Placeholder
							title={swiftIncluded ? "Swift" : "Account Number"}
							value={validAccNum}
						/>
					)}
					{accountNameFound && (
						<Placeholder title="Account Name" value={validAccName} />
					)}
					{bankNameFound && (
						<Placeholder title="Bank Name" value={validBankName} />
					)}
				</div>
			)}
			<div style={{ marginBottom: 35 }} className="form-wrap">
				<div className={HALF_WIDTH_CHILD}>
					<CustomSelector
						header="Currency"
						subtitle="Select Currency"
						isUserWallets
						onChange={handleCurrencyChange}
					/>
				</div>
				<div style={{ zIndex: 50 }} className={HALF_WIDTH_CHILD}>
					<CustomSelector
						itemName="country"
						customName="country"
						customLabel="Bank Country"
						noArrow
						isSearchable
						options={countriesData}
						onCustomChange={handleCountryChange}
						placeholder={
							countriesLoading ? "Fetching countries..." : "Select Country"
						}
					/>
				</div>
			</div>
			{isLoading ? (
				<Spinner />
			) : (
				<>
					<div className={HALF_WIDTH_WRAPPER}>
						{paramsData?.map(
							({ fieldName, required, title }: BankParamProps) => {
								const useNigBanks = isNigerianBank && fieldName === "bankName";
								const isAccountType = fieldName === "accountType";
								const isBankCode = fieldName === "bankCode";
								const isAccountNo = fieldName === "accountNo";
								const isSwift = fieldName === "swift";
								const isIban = fieldName === "iban";
								const isNumSwift = isAccountNo || isSwift;

								const accountNameFound =
									fieldName === "accountName" &&
									verifyData?.accountName &&
									verifyData?.accountName?.length > 0;
								return (
									<div
										key={title}
										className={`${HALF_WIDTH_CHILD} mb-5 relative ${
											isBankCode && "hidden"
										} ${isNumSwift && ""}`}>
										{useNigBanks ? (
											<NgnBanksSelector />
										) : accountNameFound ? (
											<CustomInputField
												nonEditable
												label="Account Name"
												value={verifyData?.accountName || ""}
											/>
										) : isAccountType ? (
											<AccountTypeSelector />
										) : (
											<CustomInputField
												onValChange={(e: any) =>
													updateAdditionalDetails(fieldName, e?.target.value)
												}
												required={required && !isBankCode}
												name={fieldName}
												label={title}
												placeholder={
													fieldName === "accountName"
														? verifyData?.accountName || "Enter Account Name"
														: `Enter ${title}`
												}
											/>
										)}
										{isNgnNaira && isAccountNo && <VerifyAccNumberBtn />}
										{swiftIncluded && isSwift && <VerifySwiftBtn />}
										{/* {ibanIncluded && isIban && <VerifyIbanBtn />} */}
									</div>
								);
							}
						)}
						{/* {paramsData?.length > 0 && (
							<>
								<div className={HALF_WIDTH_CHILD}>
									<CustomInputField
										required
										name="address"
										label="Bank Address"
										placeholder="Enter Bank address"
									/>
								</div>

								<div className={HALF_WIDTH_CHILD}>
									<CustomInputField
										required
										name="state"
										label="Bank State"
										placeholder="Enter State"
									/>
								</div>

								<div
									style={{
										marginTop: accountNameFound || accountNumFound ? 24 : 0,
									}}
									className="w-full md:w-[45%] lg:w-[47%]">
									<CustomInputField
										required
										name="zipcode"
										label="Zip Code"
										placeholder="Enter Zipcode"
									/>
								</div>
							</>
						)} */}
					</div>
					<div className="flex items-center justify-end space-x-4 mt-8 mb-2">
						<QwidButton
							type="button"
							onClick={handleModalClose}
							transparent
							text={<span className="px-2">Cancel</span>}
						/>

						<QwidButton
							darkBlueBg
							type="submit"
							disabled={!accountNameFound && !formDetails?.accountName?.length}
							loading={addBankLoading}
							text={<span className="px-4">Save</span>}
						/>
					</div>
				</>
			)}
			<button ref={submitRef} type="submit" className="hidden">
				Submit
			</button>
		</Form>
	);
};

export default AddBankDetailsForm;
