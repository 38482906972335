import {
  saveUserBeneficiaries,
  toggleConfirmOffshore,
  setCountriesData,
  setVisitedCountryCurrencies,
  setExistingStatesPerCountry,
  setExistingCitiesPerState,
  switchRemittancePage,
  setBeneficiaryBanks
} from '../../../redux/slices/dashboardSlice';
import { DASHBOARD_ROUTES } from '../../../utils/appData/appRoutes';
import { useToolkit } from '../../../components';
import { MutationFunction, useMutation } from 'react-query';

export enum BankField {
  IBAN = 'IBAN',
  SWIFT = 'SWIFT',
  ROUTINE = 'ROUTINE'
}

const useSendFunds = () => {
  const {
    useState,
    axiosInstance,
    userType,
    userId,
    toastSuccess,
    dispatch,
    router,
    toastError,
    useAppSelector,
    toastWarn,
    handleRequestError
  } = useToolkit();
  const {
    countriesData,
    visitedCountryCurrencies,
    existingStatesPerCountry,
    existingCitiesPerState,
    beneficiaryBanks
  } = useAppSelector((state) => state.dashboard);

  // handle fetch recipient countries
  const handleFetchRecipientCountries = async () => {
    const { data } = await axiosInstance.post(`countryCur`);
    return data?.length
      ? data?.map((item: any) => ({
        value: item?.I,
        label: item?.N,
        title: item?.N
      }))
      : [];
  };
  // getting all recipient countries
  const [countriesLoading, setCountriesLoading] = useState(false);
  const getAllRecipientCountries = async () => {
    setCountriesLoading(true);
    try {
      const formatted = await handleFetchRecipientCountries();
      // To be removed and replaced with the new dashboard state (countriesData)
      // setCountriesData(formatted);
      dispatch(setCountriesData(formatted));
      setCountriesLoading(false);
      return formatted;
    } catch (error) {
      handleRequestError(error);
      setCountriesLoading(false);
      return error;
    }
  };

  const handleFetchCountryCurrencies = async (country: string) => {
    if (!country) return [];
    const { data } = await axiosInstance.post(`countryCur`, {
      country
    });
    return data?.CURR?.length
      ? data?.CURR?.map((item: any) => ({
        title: item?.N + ' - ' + `(${item?.I})`,
        label: item?.N + ' - ' + `(${item?.I})`,
        value: item?.I,
        name: item?.N,
        code: item?.I
      }))
      : [];
  };

  // getting available currencies by country
  const [curLoading, setCurLoading] = useState(false);
  const [curData, setCurData] = useState<Record<string, string>[]>([]);
  const getCountryCurrencies = async (value: string, custom?: string) => {
    if (value) {
      setCurLoading(true);
      let currencies = visitedCountryCurrencies[value];
      if (currencies?.length) {
        setCurData(currencies);
        setCurLoading(false);
      } else {
        try {
          currencies = await handleFetchCountryCurrencies(value);
          dispatch(setVisitedCountryCurrencies({ [value]: currencies }));
          setCurData(currencies);
          setCurLoading(false);
        } catch (error) {
          handleRequestError(error);
          setCurLoading(false);
          return error;
        }
      }
    }
  };

  const {
    mutateAsync: getBeneficiaryBankFormFields,
    isLoading: bankFormFieldsLoading,
    data: bankFormFieldsPayload
  } = useMutation({
    mutationFn: (async (payload: { country: string; cur: string }) => {
      const {
        data: { data }
      } = await axiosInstance.post('beneficiary-bank-forms', payload);
      return data;
    }) as MutationFunction<any>,
    onError: (error: any) => {
      const message = error?.response?.data?.message || error?.message || 'something went wrong';
      toastError(message);
      return error;
    },
    onSuccess: (data) => data
  });

  const {
    mutateAsync: getBeneficiaryBanks,
    isLoading: fetchingBanks,
    isError: getBenBanksFailed
  } = useMutation({
    mutationFn: (async (payload: {
      country: string;
      cur: string;
      category: string;
      transactionType: string;
    }) => {
      const { data } = await axiosInstance.post('otherBanks', payload);
      return data?.content?.data;
    }) as MutationFunction<any>,
    onError: (error: any) => {
      const message = error?.response?.data?.message || error?.message || 'something went wrong';
      toastWarn(message);
      return error;
    },
    onSuccess: (data) => data
  });

  const useGetStates = useMutation({
    mutationFn: (async (payload: { country: string }) => {
      const states = existingStatesPerCountry[payload.country];
      if (states?.length) return states;
      const { data } = await axiosInstance.post('vtn/listState', payload);

      const response = data?.content?.data;
      const formatted = response?.map((item: any) => ({
        title: item?.name,
        value: item?.name
      }));

      dispatch(setExistingStatesPerCountry({ [payload.country]: formatted }));
      return formatted;
    }) as MutationFunction<any>,
    onError: (error: any) => {
      const message = error?.response?.data?.message || error?.message || 'states not found';
      toastWarn(message);
      return error;
    },
    onSuccess: (data) => data
  });

  const useGetCities = useMutation({
    mutationFn: (async (payload: { country: string; state: string }) => {
      const { state, country } = payload;
      const tag = `${country}-${state}`;
      const cities = existingCitiesPerState[tag];
      if (cities?.length) return cities;
      const { data } = await axiosInstance.post('vtn/listCity', payload);
      const response = data?.content?.data || [];
      const formatted = response?.map((item: any) => ({
        title: item,
        value: item
      }));
      dispatch(setExistingCitiesPerState({ [tag]: formatted }));
      return formatted;
    }) as MutationFunction<any>,
    onError: (error: any) => {
      const message = 'cities not found' || error?.response?.data?.message?.msg || error?.message; // Todo: error from the backend was replaced because it is wrong, fix when backend error is fixed
      toastWarn(message);
      return error;
    },
    onSuccess: (data) => data
  });

  const useVerifyBankDetail = useMutation({
    mutationFn: async (payload: { mode: BankField; account: string }) => {
      const { data } = await axiosInstance.post('swift-iban-verify', payload);
      return data?.content?.data;
    },
    onError: (_error: any, { mode }) => {
      toastWarn(`Oops!!! Could not verify your ${mode}`);
    },
    onSuccess: (data) => data
  });

  const useVerifyNigeriaAccountNo = useMutation({
    mutationFn: async (payload: { bankCode: string; accountNo: string }) => {
      const { data } = await axiosInstance.post('bank-verify', {
        country: 'NG',
        ...payload
      });
      return data?.content?.data;
    },
    onError: (_error: any) => {
      toastError(`Your account number is invalid!`);
    },
    onSuccess: (data) => data
  });

  const useAddBeneficiary = useMutation({
    mutationFn: (async ({
      payload,
      noRedirect,
      noAction
    }: {
      payload: Record<string, string>;
      noRedirect?: boolean;
      noAction?: boolean;
    }) => {
      const result = await axiosInstance.post(`beneficiary/${userId}`, payload);
      if (!noAction) {
        if (!noRedirect) dispatch(switchRemittancePage('beneficiary-success'));
        else {
          toastSuccess('Successfully added beneficiary');
        }
      }
      return result;
    }) as MutationFunction<any>,
    onError: (error: any) => {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.message?.msg ||
        error?.message ||
        'Error adding beneficiary, Check details provided!';
      toastError(message);
      return error;
    },
    onSuccess: (data) => data
  });

  const useUpdateBeneficiary = useMutation({
    mutationFn: (async (payload: Record<string, string>) => {
      await axiosInstance.patch(`beneficiary/${payload.id}`, payload.data);
      dispatch(switchRemittancePage('beneficiary-success'));
    }) as MutationFunction<any>,
    onError: (error: any) => {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.message?.msg ||
        error?.message ||
        'Error updating beneficiary, Check details provided!';
      toastError(message);
      return error;
    },
    onSuccess: (data) => data
  });

  // get  conversion rates
  const [ratesLoading, setRatesLoading] = useState(false);
  const [ratesData, setRatesData] = useState([]);
  const [sendValue, setSendValue] = useState<number>();
  const [rateVals, setRateVals] = useState({
    fromCur: '',
    rate: 0,
    fee: 0,
    totalDbt: 0,
    receiving: '',
    toPay: ''
  });
  const [editingLeft, setEditingLeft] = useState(true);

  const getConversionRates = async ({ from, to, country, transType, amount }: ConversionPayload) => {
    setRatesLoading(true);
    try {
      const { data } = await axiosInstance.post(`rate`, {
        from: from,
        to: to,
        country: country || 'NG',
        transType: transType || 'send',
        userId,
        amount
      });
      const response = data?.content?.data;
      const rate = response?.rate;
      const isPercent = response?.feeMode === 'percentage';
      const isSum = response?.feeMode === 'sum';
      const feeVal = response?.fee;
      const toPay = response?.toPay;
      setRateVals({
        ...rateVals,
        fee: feeVal,
        rate: response?.rate,
        toPay
      });

      if (sendValue && isPercent) {
        const pct = (feeVal / 100) * sendValue;
        const total = pct + sendValue;
        const recipientAmt = editingLeft ? sendValue * rate : (1 / rate) * sendValue;
        setRateVals({
          ...rateVals,
          fee: response?.fee,
          rate: response?.rate,
          totalDbt: total,
          receiving: `${to} ${recipientAmt.toFixed(6)}`,
          toPay,
        });
      } else if (sendValue && isSum) {
        const amountToAdd = response?.fee;
        const totalDbt = amountToAdd + sendValue;
        const recipientAmt = editingLeft ? sendValue * rate : (1 / rate) * sendValue;
        setRateVals({
          ...rateVals,
          fee: response?.fee,
          rate: response?.rate,
          totalDbt: totalDbt,
          receiving: `${to} ${recipientAmt.toFixed(6)}`,
          toPay,
        });
      } else {
        setRateVals({
          ...rateVals,
          fee: response?.fee,
          rate: response?.rate,
          toPay,
        });
      }
      setRatesLoading(false);
    } catch (error) {
      handleRequestError(error);
      setRatesLoading(false);
      return error;
    }
  };

  // get fund source
  const [sourceLoading, setSourceLoading] = useState(false);
  const [sourceData, setSourceData] = useState<
    { Id: number; Name: string; value?: string; title?: string }[]
  >([]);
  const getFundSource = async () => {
    setSourceLoading(true);
    try {
      const { data } = await axiosInstance.get(`vtn/fundSource`);
      const response = data?.content?.data;
      const formatted = response?.map((item: any) => ({
        title: item?.Name,
        value: `${item?.Id}-${item.Name}`
      }));
      setSourceData(formatted);
      setSourceLoading(false);
    } catch (error) {
      handleRequestError(error);
      setSourceLoading(false);
      return error;
    }
  };

  // get remittance purpose
  const [purpLoading, setPurpLoading] = useState(false);
  const [purpose, setPurpose] = useState<
    { Id: number; Name: string; value?: string; title?: string }[]
  >([]);
  const getRemittancePurpose = async (iso: string) => {
    setPurpLoading(true);
    try {
      const { data } = await axiosInstance.get(`vtn/remittancePurpose/${iso || 'NG'}`);
      const response = data?.content?.data;
      const formatted = response?.map((item: any) => ({
        title: item?.Name.toUpperCase(),
        value: `${item?.Id}-${item.Name}`
      }));
      setPurpose(formatted);
      setPurpLoading(false);
    } catch (error) {
      handleRequestError(error);
      setPurpLoading(false);
      return error;
    }
  };

  // all user beneficiaries
  const [benefsLoading, setBenefsLoading] = useState(false);
  const [benefs, setBenefs] = useState<DropdownProps[]>([]);
  const getallBeneficiaries = async (custom?: string) => {
    setBenefsLoading(true);
    try {
      const { data } = await axiosInstance.post(`beneficiary/user/${userId}`, {
        source: '',
        limit: ''
      });
      const response = data?.content?.data?.beneficiary;
      dispatch(saveUserBeneficiaries(response));
      if (custom === 'remittance') {
        const formatted = response?.map((item: any) => ({
          title: `${item?.accountName} (${item?.bankName})`,
          label: `${item?.accountName} (${item?.bankName})`,
          value: item?.id
        }));
        setBenefs(formatted);
      } else if (custom) {
        const formatted = response?.map((item: any) => ({
          title: `${item?.accountName} | ${item?.accountNo}`,
          value: item?.id
        }));
        setBenefs(formatted);
      } else {
        setBenefs(response);
      }
      setBenefsLoading(false);
    } catch (error) {
      handleRequestError(error);
      setBenefsLoading(false);
      return error;
    }
  };

  // beneficiaries by currency
  const [curBenefLoading, setCurBenefLoading] = useState(false);
  const [curBenef, setCurBenef] = useState([]);
  const [rawBenefs, setRawBenefs] = useState<SavedAccountProps[]>();
  const getCurrencyBeneficiaries = async (cur: string) => {
    if (cur?.length) {
      setBenefsLoading(true);
      try {
        const { data } = await axiosInstance.post(`beneficiary/currency/${userId}`, {
          start: 0,
          limit: 100,
          cur
        });
        const response = data?.content?.data?.beneficiary;
        const formatted = response?.map((item: any) => ({
          title: `${item?.accountName} | ${item?.accountNo}`,
          value: item?.id
        }));
        setRawBenefs(response);
        setBenefs(formatted);
        setBenefsLoading(false);
      } catch (error) {
        handleRequestError(error);
        setBenefsLoading(false);
        return error;
      }
    }
  };
  // getting transaction fee
  const [feesDataLoading, setFeesDataLoading] = useState(false);
  const [feesError, setFeesError] = useState(false);
  const [zeroRate, setZeroRate] = useState(false);
  const [feesData, setFeesData] = useState({
    rate: 0,
    toReceive: 0,
    toReceiveCur: '',
    toPay: 0,
    toPayCur: '',
    fee: 0 || '',
    maxLimit: '',
    minLimit: '',
    amount: ''
  });

  const getWithdrawalFee = async ({ from, to, transType, amount, country }: payoutPayload) => {
    if (amount) {
      setFeesError(false);
      setFeesDataLoading(true);
      setZeroRate(false);
      try {
        const { data } = await axiosInstance.post(`rate`, {
          from: from,
          to: to,
          country: country || 'NG',
          transType: transType || 'send',
          amount: amount,
          userId: userId
        });

        const response = data?.content?.data;
        const isPercent = response?.feeMode === 'percentage';
        const isSum = response?.feeMode === 'sum';
        if (response?.rate === 0 && from !== to) {
          toastError(`Sorry, conversion rate for ${from} to ${to} not available at the moment`);
          setZeroRate(true);
        }
        const mainFee = isPercent ? (Math.floor((response?.fee / 100) * amount * 100) / 100).toFixed(2) : response?.fee.toFixed(2)
        setFeesData({
          rate: response?.rate,
          toReceive: response?.toReceive,
          toReceiveCur: response?.toReceiveCur,
          toPay: response?.toPay,
          toPayCur: response?.toPayCur,
          maxLimit: response?.maxLimit,
          minLimit: response?.minLimit,
          fee: mainFee,
          amount: response?.amount,
        });
        setFeesDataLoading(false);
      } catch (error) {
        setFeesError(true);
        handleRequestError(error);
        setFeesDataLoading(false);
        return error;
      }
    }
  };

  // initiating send request
  const [trfLoading, setTrfLoading] = useState(false);

  const initSendRequest = async (payload: any) => {
    setTrfLoading(true);
    try {
      await axiosInstance.post(`transaction/offshore/${userId}`, payload);
      toastSuccess('Transfer initiated');
      setTimeout(() => {
        dispatch(toggleConfirmOffshore());
        router.push(DASHBOARD_ROUTES.DASHBOARD);
      }, 1500);
      setTrfLoading(false);
    } catch (error) {
      handleRequestError(error);
      setTrfLoading(false);
      return error;
    }
  };

  const handleFetchPayoutChannel = async (country: string, currency: string) => {
    const data = await axiosInstance.post('transaction-support/get-channel', {
      country: country,
      cur: currency
    });
    return data?.data?.content?.data || [];
  };

  const handleFetchPayoutOption = async (country: string, currency: string, category: string) => {
    const data = await axiosInstance.post('/transaction-support/get-payout-option', {
      country: country,
      cur: currency,
      category
    });
    return data?.data?.content?.data || [];
  };

  const handleFetchDynamicFormFields = async ({ country, currency, category, option, beneficiaryType }: {
    country: string;
    currency: string;
    category: string;
    option?: string;
    beneficiaryType: string;
  }

  ) => {
    const { data } = await axiosInstance.post('/beneficiary-bank-forms', {
      country: country,
      cur: currency,
      category,
      beneficiaryType,
      option
    });
    return data?.data || [];
  };

  // implements data caching
  const handleGetBeneficiaryBanks = async (payload: Record<string, string>) => {
    const queryKey = Object.values(payload).join('-');
    let res = beneficiaryBanks[queryKey];
    if (res) return res;
    const { data } = await axiosInstance.post('otherBanks', payload);
    try {
      res = (data?.content?.data || [])
        .filter(
          (bank: any, index: number, arr: any) =>
            index === arr?.findIndex((v: any) => v.bankName === bank.bankName)
        )
        ?.map((bank: any) => ({
          value: bank.bankName,
          title: bank.bankName,
          bankCode: bank.bankCode,
          bankAddress: bank.bankAddress,
          bankCity: bank.bankCity
        }));
    } catch (error) {
    }

    dispatch(setBeneficiaryBanks({ [queryKey]: res }));
    return res;
  };
  const fetchReasonForSendingMoney = async (country: string) => {
    const { data } = await axiosInstance.get(`vtn/remittancePurpose/${country}`);
    const response = data?.content?.data;
    const formatted = response?.map((item: any) => ({
      title: item?.Name.toUpperCase(),
      value: `${item?.Id}-${item.Name}`,
      Id: `${item?.Id}-${item.Name}`,
    })).filter(
      (reason: any, index: number, arr: any) =>
        index === arr?.findIndex((v: any) => v.title === reason.title)
    );
    return formatted || [];
  }
  const fetchSourceOfFunds = async () => {
    const { data } = await axiosInstance.get(`vtn/fundSource`);
    const response = data?.content?.data;
    const formatted = response?.map((item: any) => ({
      title: item?.Name.toUpperCase(),
      value: `${item?.Id}-${item.Name}`
    })).filter(
      (source: any, index: number, arr: any) =>
        index === arr?.findIndex((v: any) => v.value === source.value)
    );
    return formatted || [];
  }

  const postTransactionsQwid2Outside = async (payload: Record<string, unknown>) => {
    const { data } = await axiosInstance.post(`initiate-remittance/${userId}`, payload);
    const response = data?.content?.data;
    return response;
  }

  const [bankNoteLoading, setBankNoteLoading] = useState(false);
  const [bankNoteData, setBankNoteData] = useState<any>(null);

  const getBankNote = async (payload: any) => {
    setBankNoteLoading(true);
    try {
      const { data } = await axiosInstance.post('/bank-notes', payload);
      setBankNoteData(data?.content?.data)
      setBankNoteLoading(false);
    } catch (error) {
      handleRequestError(error);
      setBankNoteLoading(false);

    }
  };

  return {
    countriesLoading,
    countriesData,
    getAllRecipientCountries,
    getCountryCurrencies,
    curLoading,
    curData,
    getConversionRates,
    ratesLoading,
    ratesData,
    rateVals,
    sendValue,
    setSendValue,
    sourceLoading,
    sourceData,
    getFundSource,
    purpLoading,
    purpose,
    getRemittancePurpose,
    getallBeneficiaries,
    benefs,
    rawBenefs,
    benefsLoading,
    getCurrencyBeneficiaries,
    curBenef,
    initSendRequest,
    trfLoading,
    editingLeft,
    setEditingLeft,
    feesData,
    feesDataLoading,
    feesError,
    zeroRate,
    getWithdrawalFee,
    getBeneficiaryBankFormFields,
    bankFormFieldsLoading,
    bankFormFieldsPayload,
    getBeneficiaryBanks,
    fetchingBanks,
    getBenBanksFailed,
    useGetCities,
    useGetStates,
    useAddBeneficiary,
    useVerifyBankDetail,
    useVerifyNigeriaAccountNo,
    useUpdateBeneficiary,
    handleFetchRecipientCountries,
    handleFetchCountryCurrencies,
    handleFetchPayoutChannel,
    handleFetchPayoutOption,
    handleFetchDynamicFormFields,
    handleGetBeneficiaryBanks,
    fetchReasonForSendingMoney,
    fetchSourceOfFunds,
    postTransactionsQwid2Outside,
    getBankNote,
    bankNoteLoading,
    bankNoteData,
  };
};

export default useSendFunds;
