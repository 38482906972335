import React from "react";
import { IoCheckboxOutline } from "react-icons/io5";
import { useFramer } from "../..";
import { motion } from "framer-motion";

type Props = {};

const ShopGlobalMarkets = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();

	const checkOptions: string[] = [
		"Shop from stores around the world",
		"Pay for all your subscriptions in a blink",
		"Make payments at your convenience",
	];

	return (
		<div className="bg-[#fafafa] py-8 lg:py-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper">
				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-0 smb text-center mx-auto lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-snug">
						Shop Global Markets, Pay like a Local
					</p>

					<p className="my-7 text-center lg:t6 lg:text-left">
						Do more with your finances using qwid's virtual cards. Break free
						from traditional limitations and embrace a world of easy payments
						and limitless possibilities.
					</p>

					<div className="flex flex-col space-y-4">
						{checkOptions.map((item, idx) => (
							<div className="flex items-center space-x-2" key={idx}>
								<IoCheckboxOutline className="text-xl text-[#1994ca]" />
								<p className="mb-0 med">{item}</p>
							</div>
						))}
					</div>
				</motion.div>

				<motion.div
					variants={flashIn}
					className="bg-[#a9e1f9] rounded-lg w-full md:w-2/4 lg:w-2/4 h-[300px] md:h-[400px] flex justify-center relative">
					<div className="-bottom-5 mx-auto flex items-end justify-center lg:pl-14">
						<img
							alt="how-to-send"
							src="/assets/landing/global-markets-shot.svg"
							className="object-cover h-[250px] lg:h-[350px]"
						/>
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default ShopGlobalMarkets;
