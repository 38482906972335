import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface transactionsState {
    searchTransactionQuery: string;
}

const initialState: transactionsState = {
    searchTransactionQuery: ""
};

export const transactionSearchSlice = createSlice({
    name: 'transactions',
    initialState,

    reducers: {
        setSearchTransactionQuery: (state, { payload }: PayloadAction<string>) => {
            state.searchTransactionQuery = payload
        }
    }
});

export const {
    setSearchTransactionQuery
} = transactionSearchSlice.actions;

export default transactionSearchSlice.reducer;
