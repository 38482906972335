import React, { useState, useMemo, useEffect } from "react";
import { Form } from "antd";
import QwidButton from "components/misc/QwidButton";
import CustomInput from 'components/onboarding/formComponents/CustomInput';
import { vtnCountries } from "utils/appData/vtnCountries";
import useToolkit from "utils/hooks/misc/useToolkit";
import { countryCodes } from "utils/appData/countryCodes";
import useLocation from "utils/hooks/dashboard/useLocation";
import useOnboarding from "utils/hooks/onboarding/useOnboarding";
import { pepText } from "components/onboarding/shared/Options";
import moment from "moment";
const AddDirectorShareHolder = () => {
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [selectedState, setSelectedState] = useState<any>();
  const { useAppSelector, dispatch, toastInfo } = useToolkit();
  const { capitalizeFirstLetters, countryCodeFromName } = useOnboarding('business')
  const [form] = Form.useForm();
  const [editData, setEditData] = useState<any>([]);
  const {
    ctrLoading,
    ctrData,
    getDynCountries,
    getDynStates,
    statesLoading,
    stateData,
    getDynCities,
    citiesData,
    citiesLoading,
  } = useLocation();
  const radioOptions: any = [
    { value: 'director', title: "Director", titleTag: "director" },
    { value: 'shareholder', title: "Shareholder", titleTag: "shareholder" },
    { value: 'both', title: "Both", titleTag: "both" },
  ];

  const pepOptions: any = [
    { value: 'yes', title: "Yes" },
    { value: 'no', title: "No" },
  ];

  const genderOptions: any = [
    { value: 'male', title: "Male" },
    { value: 'female', title: "Female" },
    { value: 'other', title: "Other" },

  ]

  const nationalityOptions = useMemo(() => countryCodes.map(nationality => ({
    title: nationality.nationality,
    value: nationality.nationality,
    Id: nationality.code as any,
  })), []);
  const newCountryData: DropdownProps[] = useMemo(() => {
    return ctrData.map(({ title }) => {
      return {
        title: capitalizeFirstLetters(title as string),
        value: capitalizeFirstLetters(title as string)
      }

    })
  }, [ctrData])
  useEffect(() => {
    getDynCountries();

  }, []);

  useEffect(() => {

    getDynStates(countryCodeFromName(selectedCountry));
  }, [selectedCountry]);
  useEffect(() => {

    //const countryName = vtnCountries.find((item ) => selectedCountry?.toUpperCase() === item.code )?.name
    const payload = { country: selectedCountry, state: selectedState };

    getDynCities(payload);
  }, [selectedState]);
  useEffect(() => {

    getDynStates(countryCodeFromName(selectedCountry));
  }, [selectedCountry]);
  const newStateData = useMemo(() => {
    if (stateData) {
      return stateData.map((item) => {
        return {
          title: item.title,
          value: item.title
        }
      }) as DropdownProps[]
    }
  }, [stateData, selectedCountry])
  const handleCountryChange = (e: any) => {
    setSelectedCountry(e)
    const updatePayload: Record<string, string | undefined> = {};
    if (form.getFieldValue('state')) {
      let message = "Select a new State";
      updatePayload.state = undefined;
      if (form.getFieldValue('city')) {
        updatePayload.city = undefined;
        message = 'Select a new State and City';
      }
      form.setFieldsValue(updatePayload);
      toastInfo(message);
    }
    const phoneCode = vtnCountries.find((item) => item.name.toLowerCase() === e.toLowerCase())?.phone
    form.setFieldsValue({ mobileCode: phoneCode, ...updatePayload })
  }

  const handleSelectedState = (e: any) => {
    setSelectedState(e)
    if (form.getFieldValue('city')) {
      form.setFieldsValue({
        city: undefined
      });
      toastInfo('Select a new City');
    }
  }

  const editFormFun = (shareHoldersInfoData: any, index: number) => {
    setEditData(shareHoldersInfoData);
    // setFormSwitch(false);
    // setEditIndex(index);
    setSelectedCountry(capitalizeFirstLetters(shareHoldersInfoData?.country))
    form.setFieldsValue({
      radiogroup: shareHoldersInfoData?.mode || "",
      first_name: shareHoldersInfoData?.first_name || "",
      last_name: shareHoldersInfoData?.last_name || "",
      email_address: shareHoldersInfoData?.email || "",
      position: shareHoldersInfoData?.position || "",
      Share_percentage: shareHoldersInfoData?.shares_in_organization || "",
      house_no: shareHoldersInfoData?.house_number || "",
      address: shareHoldersInfoData?.address || "",
      phone_number: shareHoldersInfoData?.mobile || "",
      tax_number: shareHoldersInfoData?.tax_number || "",
      dob: moment(shareHoldersInfoData?.dob, "DD-MM-YYYY") || null,
      state: shareHoldersInfoData?.state || "",
      country: capitalizeFirstLetters(shareHoldersInfoData?.country) || "",
      city: shareHoldersInfoData?.city || "",
      pep: shareHoldersInfoData?.pep || "",
      mobileCode: shareHoldersInfoData?.mobileCode || "",
      id_issuing_country: shareHoldersInfoData?.tax_issuing_country.toUpperCase() || "",
      bank_verification_number: shareHoldersInfoData?.bvn || "",
      gender: shareHoldersInfoData?.gender,
      zip_code: shareHoldersInfoData?.zip_code,
      nationality: capitalizeFirstLetters(shareHoldersInfoData?.nationality)
    });
    const formElement = document.getElementById('yourFormId');

    if (formElement) {
      formElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }

  };

  const initialValues: any = {
    radiogroup: "",
    first_name: "",
    last_name: "",
    email_address: "",
    position: "",
    Share_percentage: "",
    house_no: "",
    address: "",
    phone_number: "",
    tax_number: "",
    bank_verification_number: "",
    dob: "",
    city: "",
    state: "",
    pep: "",
    country: "",
    gender: "",
    nationality: ""

  };

  return (
    <div className="mb-8">
      <p className=" italic text-[0.8rem] mt-4  " id="yourFormId" >
        All fields with{" "}
        <span className="text-red-500 text-[1.1rem]">*</span> are required
      </p>
      <p className="mt-[-0.2rem]">
        Is this person a director or shareholder? (Select both if both
        apply)
        <span className="text-red-500 text-[1.1rem]">*</span>
      </p>
      <Form

        // onFinish={onSubmit}
        className="onb-form-wrapper"
        //initial values go here
        initialValues={initialValues}
        form={form}
      >
        <CustomInput
          isRadio
          name="radiogroup"
          options={radioOptions}
          required
        // disabledIndexes={disableRadio()}
        />
        <div className="onb-form-item">
          <CustomInput label="First name" name="first_name" required />
          <CustomInput label="Last name" name="last_name" required />
        </div>
        <div className="onb-form-item">
          <CustomInput
            label="Email address"
            name="email_address"
            isEmailValidated
            required
          />
          <CustomInput label="Position" name="position" required />
        </div>
        <div className="onb-form-item">
          <CustomInput
            label="Share percentage"
            isNumberOnly
            name="Share_percentage"
            required
          />
          <CustomInput
            required
            label="Date of birth"
            isDob
            isDatePicker
            name="dob"
          />
        </div>
        <div className="onb-form-item">
          <CustomInput name='nationality' label='Nationality' isSelector required
            options={nationalityOptions} />

          <CustomInput
            isSelector
            label="Gender"
            name="gender"
            options={genderOptions}
            required

          />
        </div>

        <div className="onb-form-item">
          <CustomInput
            required
            isCombined
            unrequiredIndex={0}
            combinedNames={["house_no", "address"]}
            combinedLabels={["Address"]}
            combinedPlaceholders={["No"]}
          />
        </div>

        <div className="onb-form-item">
          <CustomInput
            isSelector
            required
            label="Country of Residence"
            name="country"
            options={newCountryData}
            placeholder={ctrLoading ? "Fetching...." : ""}
            onChange={handleCountryChange}
          />
          <CustomInput
            isSelector={!statesLoading && !!newStateData?.length && Array.isArray(newStateData)}
            label="State"
            name="state"
            required
            options={newStateData}
            placeholder={statesLoading ? "Fetching...." : ""}
            onChange={(e) => handleSelectedState(e)}
            nonEditableText="select a resident country first"
            // nonEditable={selectedCountry}
            nonEditable={selectedCountry ? false : editData?.nationality ? false : true}

          />
        </div>
        <div className="onb-form-item">
          <CustomInput
            isSelector={(!citiesLoading && Array.isArray(citiesData) && !!citiesData?.length) && !!newStateData?.length}
            label='City'
            name='city'
            nonEditable={!!!selectedState && !!!editData?.state}
            nonEditableText="select a state first"
            required
            placeholder={citiesLoading ? "Fetching..." : ""}
            loading={citiesLoading}
            options={citiesData}
          />
          <CustomInput
            label="Zip code"
            name="zip_code"
            required

          />
        </div>
        <div className="onb-form-item">
          <CustomInput
            name="phone_number"
            label="Phone Number"
            isPhoneNumber
            phoneCodeDisabled
            nonEditable={!selectedCountry}
            nonEditableText="Select a resident country first"
            required
          />
          <CustomInput
            isSelector
            label="ID-issuing country"
            name="id_issuing_country"
            options={ctrData}
            required
          />

        </div>
        <div className="onb-form-item">

          <CustomInput
            label="Tax number"
            name="tax_number"
            required={selectedCountry !== "Nigeria" && true}
            subtitle="This is compulsory for those outside Nigeria."
          />
          <CustomInput
            label="Bank verification number"
            name="bank_verification_number"
            required={selectedCountry === "Nigeria" && true}
            subtitle="This is compulsory for those in Nigeria."
          />

        </div>

        <div className='w-full sm:w-[50%] sm:pr-[1.05rem]'>

          <CustomInput
            isSelector
            label="Is the individual a PEP or associated to a PEP?"
            name="pep"
            options={pepOptions}
            required
            subtitle="What is PEP?"
            subtitleInvert
            subtitleTooltipText={pepText}
            subtitleTooltipPlacement='bottom'
          />
        </div>
      </Form>
    </div>
  )
}

export default AddDirectorShareHolder