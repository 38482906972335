import { motion } from "framer-motion";
import { useFramer } from "../../../../components";

type Props = {};

const Payment = (props: Props) => {
	const { zoomContent, zoomWrap, flashIn } = useFramer();

	return (
		<div className="bwite pb-8 lg:pb-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper2">
				<motion.div
					variants={flashIn}
					className="bg-[#C2BCFB] rounded-lg w-full md:w-2/4 lg:w-2/4 h-[280px] lg:h-[500px] md:h-[400px] flex items-end justify-center relative">
					<div className="w-[98%]  -bottom-1.5 mx-auto flexed">
						<img
							alt="how-to-send"
							src="/assets/features/payment.png"
							className="object-cover h-[250px] rounded-lg  md:h-[300px] lg:h-[400px]"
						/>
					</div>
				</motion.div>
				<motion.div
					variants={zoomContent}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-0 mt-5 lg:mt-0 smb text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						Transparent Rates,{" "}
						<span className="text-[#48bbed]">Swift Conversion.</span>
					</p>

					<p className="my-5 leading-8  text-center lg:t6 lg:text-left">
						With rates you can preview, qwid offers conversions you can trust.
						Our growing arsenal of major currencies around the world makes you
						limitless with the power to do more.{" "}
					</p>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default Payment;
