import useToolkit from "../misc/useToolkit";

const useLocation = () => {
	const { useState, axiosInstance, handleRequestError } = useToolkit();

	// dynamic countries
	const [ctrLoading, setCtrLoading] = useState(false);
	const [ctrData, setCtrData] = useState<DropdownProps[]>([]);

	const getDynCountries = async () => {
		setCtrLoading(true);
		try {
			const { data } = await axiosInstance.post(`countryCur`);
			const response = data;
			const formatted = response?.map((item: any) => ({
				label: item?.N,
				title: item?.N,
				value: item?.I,
			}));
			setCtrData(formatted || []);
			setCtrLoading(false);
		} catch (error) {
			handleRequestError(error);
			setCtrLoading(false);
			return error;
		}
	};

	// dynamic states
	const [statesLoading, setStatesLoading] = useState(false);
	const [stateData, setStateData] = useState<DropdownProps[]>([]);
	const getDynStates = async (country: string) => {
		setStatesLoading(true);
		try {
			const { data } = await axiosInstance.post(`vtn/listState`, {
				country: country,
			});
			const response = data?.content?.data;
			const formatted = response?.map((item: any) => ({
				title: item?.name,
				value: item?.state_code,
			}));
			setStateData(formatted || []);
			setStatesLoading(false);
		} catch (error) {
			handleRequestError(error);
			setStatesLoading(false);
			return error;
		}
	};

	// dynamic citites
	const [citiesLoading, setCitiesLoading] = useState(false);
	const [citiesData, setCitiesData] = useState([]);
	const getDynCities = async (payload: any, ignoreToast?: boolean) => {
		setCitiesLoading(true);
		try {
			const { data } = await axiosInstance.post(`vtn/listCity`, payload);
			const response = data?.content?.data
			const formatted = response?.map((item: any) => ({
				title: item,
				value: item,
			}));
			setCitiesData(formatted || []);
			setCitiesLoading(false);
		} catch (error) {
			if (!ignoreToast) handleRequestError(error);
			setCitiesLoading(false);
			
		}
	};

	return {
		ctrLoading,
		ctrData,
		getDynCountries,
		statesLoading,
		stateData,
		getDynStates,
		getDynCities,
		citiesData,
		citiesLoading,
	};
};

export default useLocation;
