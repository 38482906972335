import { CustomInputField } from "components";

type Props = {
	isUSDCAD: boolean;
	isUSDRcvCur: boolean;
	isUKBenef: any;
	isRussian: boolean;
	isArgent: boolean;
	isNotNigerianBenef: boolean;
	rcvCur: string;
	updateAdditionalDetails: (e: any) => void;
};

const NonNgnBenefForm = ({
	updateAdditionalDetails,
	isUSDCAD,
	isRussian,
	isNotNigerianBenef,
	isArgent,
	isUKBenef,
	rcvCur,
}: Props) => {
	const isOtherReceivingCurrs =
		rcvCur === "" || !rcvCur?.includes("GBP") || !rcvCur?.includes("EUR");
	const isUSDReceiver = rcvCur?.length && rcvCur === "USD";

	return (
		<div className="w-full space-y-5 lg:space-y-3">
			{isUSDReceiver ? (
				<>
					<div className="form-wrap">
						<div className="form-child">
							<CustomInputField
								isOptional
								required={false}
								onValChange={updateAdditionalDetails}
								name="accountName"
								label="Account Name"
								placeholder="Enter Name"
							/>
						</div>

						<div className="form-child">
							<CustomInputField
								isOptional
								required={isNotNigerianBenef}
								onValChange={updateAdditionalDetails}
								name="bankName"
								label="Bank Name"
								placeholder="Enter name"
							/>
						</div>
					</div>

					<div className="form-wrap">
						<div className="form-child">
							<CustomInputField
								isOptional
								required={false}
								onValChange={updateAdditionalDetails}
								name="accountNo"
								label="Account Number"
								placeholder="Enter Number"
							/>
						</div>

						<div className="form-child">
							<CustomInputField
								isOptional
								required={false}
								onValChange={updateAdditionalDetails}
								name="bankRoutingNo"
								label="Routing Number"
								placeholder="Enter number"
							/>
						</div>
					</div>

					<div className="form-wrap">
						<div className="form-child">
							<CustomInputField
								isOptional
								required={false}
								onValChange={updateAdditionalDetails}
								name="swift"
								label="Swift / BIC"
								placeholder="Enter an 8-10 digit number"
							/>
						</div>
					</div>
				</>
			) : isUKBenef ? (
				<>
					<div className="form-wrap">
						<div className="form-child">
							<CustomInputField
								isOptional
								required={false}
								onValChange={updateAdditionalDetails}
								name="accountName"
								label="Account Name"
								placeholder="Enter Name"
							/>
						</div>

						<div className="form-child">
							<CustomInputField
								isOptional
								required={isNotNigerianBenef}
								onValChange={updateAdditionalDetails}
								name="bankName"
								label="Bank Name"
								placeholder="Enter name"
							/>
						</div>
					</div>

					<div className="form-wrap">
						<div className="form-child">
							<CustomInputField
								isOptional
								required={false}
								onValChange={updateAdditionalDetails}
								name="accountNo"
								label="Account Number"
								placeholder="Enter Number"
							/>
						</div>

						<div className="form-child">
							<CustomInputField
								isOptional
								required={isNotNigerianBenef}
								onValChange={updateAdditionalDetails}
								name="bankSortCode"
								label="Sort Code"
								placeholder="Enter code"
							/>
						</div>
					</div>

					<div className="form-wrap">
						<div className="form-child">
							<CustomInputField
								isOptional
								required={false}
								onValChange={updateAdditionalDetails}
								name="iban"
								label="IBAN"
								placeholder="Enter IBAN"
							/>
						</div>

						<div className="form-child">
							<CustomInputField
								isOptional
								required={false}
								onValChange={updateAdditionalDetails}
								name="swift"
								label="Swift / BIC"
								placeholder="Enter an 8-10 digit number"
							/>
						</div>
					</div>
				</>
			) : isOtherReceivingCurrs ? (
				<div className="flex items-end justify-center md:justify-between flex-wrap space-y-5 lg:space-y-0;">
					<div className="form-child3">
						<CustomInputField
							isOptional
							required={isNotNigerianBenef}
							onValChange={updateAdditionalDetails}
							name="bankName"
							label="Bank Name"
							placeholder="Enter name"
						/>
					</div>

					<div className="form-child3">
						<CustomInputField
							isOptional
							required={false}
							onValChange={updateAdditionalDetails}
							name="accountNo"
							label="Account Number"
							placeholder="Enter Number"
						/>
					</div>

					<div className="form-child3">
						<CustomInputField
							isOptional
							required={false}
							onValChange={updateAdditionalDetails}
							name="accountName"
							label="Account Name"
							placeholder="Enter Name"
						/>
					</div>

					<div className="form-child3">
						<CustomInputField
							isOptional
							required={false}
							onValChange={updateAdditionalDetails}
							name="bankCode"
							label="Bank Code"
							placeholder="Enter Code"
						/>
					</div>

					<div className="form-child3">
						<CustomInputField
							isOptional
							required={false}
							onValChange={updateAdditionalDetails}
							name="iban"
							label="Iban / Transit"
							placeholder="Enter iban / transit"
						/>
					</div>

					<div className="form-child3">
						<CustomInputField
							isOptional
							required={false}
							onValChange={updateAdditionalDetails}
							name="swift"
							label="Swift"
							placeholder="Enter Swift"
						/>
					</div>
				</div>
			) : null}
			<div className="form-wrap"></div>

			<div className="form-wrap">
				{isArgent && (
					<div className="form-child">
						<CustomInputField
							isOptional
							required={isArgent}
							onValChange={updateAdditionalDetails}
							name="IDNumber"
							label="ID Number"
							placeholder="Enter  number"
						/>
					</div>
				)}
			</div>

			<div className="form-wrap">
				{isRussian && (
					<div className="form-child">
						<CustomInputField
							required={isRussian}
							name="cpf"
							label="CPF"
							placeholder="Enter number"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default NonNgnBenefForm;
