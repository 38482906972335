import { message } from "antd";
import { useRef } from "react";
import { useQuery } from "react-query";
import {
	closePinAuth,
	toggleBenefModal,
	toggleWithdrawalModal,
} from "redux/slices/dashboardSlice";
import { toggleCreateBankModal } from "redux/slices/settingsSlice";
import {
	clearInternalTransferDetails,
	closeNewRecipientState,
	closeSingleBeneficiary,
	setDeleteBeneficiaryCallCount,
	setInternalCurrentPage,
	setInternalTransferDetails,
} from "redux/slices/transactionsSlice";
import useToolkit from "../misc/useToolkit";
import useDashboard from "./useDashboard";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";
import { clearSendflowRecords } from "redux/slices/sendflowSlice";

interface GetBenefProps {
	name?: string | null;
	pageSize?: number | null;
	pageNum?: number | null;
  }
  
  export interface PaginatedResponse<T> {
	currentPage: number;
	pageSize: number;
	total: number;
	data: T[];
  }

type WithdrawalFeeResp = {
	amount: string;
	created_at: string;
	id: number;
	mode: string;
	peer: string;
	toPay: number;
	transType: string;
	updated_at: string;
	userType: number;
	value: string;
};

const useBeneficiary = () => {
	const {
		axiosInstance,
		userId,
		toastSuccess,
		dispatch,
		useState,
		toastWarn,
		toastError,
		useMutation,
		router,
		handleRequestError,
		isPersonalAccount,
	} = useToolkit();
	const { fetchUserWallets } = useDashboard();

	// getting all NGN banks
	const [banksLoading, setBanksLoading] = useState(false);
	const [banksData, setBanksData] = useState([]);

	const getNgnBanks = async () => {
		setBanksLoading(true);
		try {
			const { data } = await axiosInstance.get("nigBanks");
			const response = data?.content?.data;
			const formatted = response?.map((item: any) => ({
				title: item.bankName,
				label: item.bankName,
				value: item.bankCode,
			}));
			setBanksData(formatted);
			setBanksLoading(false);
		} catch (error) {
			handleRequestError(error);
			setBanksLoading(false);
			return error;
		}
	};

	// adding new beneficiary
	const [addLoading, setAddLoading] = useState(false);
	const addBeneficiary = async (payload: any) => {
		setAddLoading(true);
		try {
			await axiosInstance.post(`beneficiary/${userId}`, payload);
			toastSuccess("Beneficiary added successfully");
			dispatch(toggleBenefModal());
			dispatch(setDeleteBeneficiaryCallCount());
			setAddLoading(false);
		} catch (error: any) {
			handleRequestError(error);
			dispatch(toggleBenefModal());
			setAddLoading(false);
			return error;
		}
	};

	// adding new bank account
	const [createLoading, setCreateLoading] = useState(false);

	const createBank = async (payload: any) => {
		setCreateLoading(true);
		try {
			await axiosInstance.post(`bank/${userId}`, payload);
			toastSuccess("Bank added successfully");
			setCreateLoading(false);
		} catch (error: any) {
			handleRequestError(error);
			setCreateLoading(false);
			return error;
		}
	};

	// fetching existing bank accounts
	const [bnkLoading, setBnkLoading] = useState(false);
	const [bnkData, setBnkData] = useState([]);
	const fetchUserBanks = async () => {
		setBnkLoading(true);
		try {
			const { data } = await axiosInstance.get(`bank/${userId}`);
			const response = data?.content?.data;
			const items = response?.map((item: any) => ({
				title: `${item?.bankName} | ${item?.accountNo}`,
				value: item?.accountNo,
			}));
			setBnkData(items);
			setBnkLoading(false);
		} catch (error: any) {
			handleRequestError(error);
			setBnkLoading(false);
			return error;
		}
	};

	// fetching existing bank accounts by currency
	const [curBankLoading, setCurBankLoading] = useState(false);
	const [curBankData, setCurBankData] = useState("");
	const [withdrawalBanks, setWithdrawalBanks] = useState([]);
	const [invalidAcc, setInvalidAcc] = useState(false);
	const fetchUserCurrencyBanks = async (currency: string) => {
		if (currency) {
			setCurBankLoading(true);
			try {
				const { data } = await axiosInstance.get(`bank/${userId}/${currency}`);
				const response = data?.content?.data;
				if (response == undefined || response?.length < 1) {
					setInvalidAcc(true);
					toastWarn("No bank account found for this wallet");
					setCurBankData("No account found");
				} else {
					setInvalidAcc(false);
					const bankInfo = `${response?.accountName} | ${response?.accountNo}`;
					setWithdrawalBanks(response);
				}
				setCurBankLoading(false);
			} catch (error: any) {
				handleRequestError(error);
				setCurBankLoading(false);
				return error;
			}
		}
	};

	// initiate withdrawal request
	const [initLoading, setInitLoading] = useState(false);
	const initiateWithdrawal = async (payload: any) => {
		setInitLoading(true);
		try {
			const data = await axiosInstance.post(
				`transaction/initiate-withdrawal/${userId}`,
				payload
			);
			dispatch(toggleWithdrawalModal());
			if (data !== undefined) {
				toastSuccess(data?.data?.message);
				fetchUserWallets();
			}
			setInitLoading(false);
		} catch (error: any) {
			handleRequestError(error);
			setInitLoading(false);
			return error;
		}
	};

	// verify recipient email for internal transfer
	const [verifLoading, setVerifLoading] = useState(false);
	const [isVerified, setIsVerified] = useState(false);
	const [qwidUserDetails, setQwidUserDetails] = useState({
		userId: "",
		fullName: "",
		wallets: [],
	});
	const [rcpId, setRcpId] = useState(null);
	const verifyQwidUser = async (email: string) => {
		setVerifLoading(true);
		try {
			const { data } = await axiosInstance.get(`endUsers/search/${email}`);
			const response = data?.content?.data;
			if (response?.length == 0) {
				setIsVerified(false);
				toastError("User not found");
			} else {
				const actual = response[0];
				const fullName = `${actual?.firstName} ${actual?.lastName}`;
				const wallets = actual?.wallet;
				setQwidUserDetails({
					userId: actual?.userId,
					fullName: fullName,
					wallets: wallets,
				});
				setRcpId(actual?.userId);

				toastSuccess("Recipient verified");
				setIsVerified(true);
			}
			setVerifLoading(false);
		} catch (error) {
			setVerifLoading(false);
			return error;
		}
	};

	// initiating qwid transfer
	const [trfLoading, setTrfLoading] = useState(false);
	const initQwidTransfer = async (payload: any) => {
		setTrfLoading(true);
		try {
			const { data } = await axiosInstance.post(
				`transaction/transfer/${userId}`,
				payload
			);

			if (data?.success === true) {
				dispatch(closePinAuth());
				toastSuccess("successful");
				fetchUserWallets();

				const payload: InternalTransferProps = {
					transId: data?.content?.data[0]?.transId,
				};
				dispatch(setInternalTransferDetails(payload));
				dispatch(setInternalCurrentPage(4));
			}
			setTrfLoading(false);
		} catch (error: any) {
			dispatch(
				setInternalTransferDetails({
					errorMsg: error?.response?.data?.message,
				})
			);
			if (error?.response?.data?.message !== "Incorrect Transaction Pin") {
				dispatch(setInternalCurrentPage(5));
			}
			handleRequestError(error);
			setTrfLoading(false);
			return error;
		}
	};

	// deleting user beneficiary
	const [dltLoading, setDltLoading] = useState(false);
	const deleteBeneficiary = async (id: number) => {
		setDltLoading(true);
		try {
			await axiosInstance.delete(`beneficiary/${id}`);
			dispatch(closeSingleBeneficiary());
			toastSuccess("Beneficiary deleted");
			setDltLoading(false);
			dispatch(setDeleteBeneficiaryCallCount());
		} catch (error: any) {
			handleRequestError(error);
			setDltLoading(false);
			return error;
		}
	};

	// getting params for adding bank account
	const [paramsData, setParamsData] = useState<any>([]);
	const getBankParams = async (payload: any) => {
		verifyData.current = rawVerifyData;
		try {
			const {
				data: { data },
			} = await axiosInstance.post("beneficiary-bank-forms", payload);
			return data;
		} catch (error) {
			handleRequestError(error);
			return error;
		}
	};
	const useBankParams = () =>
		useMutation(getBankParams, {
			onSuccess: (data) => setParamsData(data),
		});

	// saving bank account
	const closeBankModal = () => dispatch(toggleCreateBankModal());

	const addBankDetails = async (payload: any) => {
		try {
			const {
				data: { data },
			} = await axiosInstance.post(`bank/${userId}`, payload);
			closeBankModal();
			toastSuccess("Bank details saved");
			return data;
		} catch (error) {
			handleRequestError(error);
			closeBankModal();
			return error;
		}
	};
	const useAddBank = () => useMutation(addBankDetails);

	// verify bank details
	const [verifyLoading, setVerifyLoading] = useState(false);
	const rawVerifyData = { accountName: "" };

	const verifyData: any = useRef<AccVerificationProps>(rawVerifyData);

	const verifyBankDetails = async (payload: any, path: string) => {
		message.info("Verifying...");
		setVerifyLoading(true);
		try {
			const { data } = await axiosInstance.post(path, payload);
			const response = data?.content?.data;
			message.success("Account details found!");
			verifyData.current = response;
			setVerifyLoading(false);
		} catch (error) {
			message.error("No details found!");
			verifyData.current = rawVerifyData;
			setVerifyLoading(false);
			return error;
		}
	};

	const accountNameFound = verifyData?.current?.accountName?.length > 3;
	const accountNumFound = verifyData?.current?.accountNo?.length > 3;
	const bankNameFound = verifyData?.current?.bankName?.length > 3;

	// fetching bank details
	const [details, setDetails] = useState([]);
	const getUserAccounts = async () => {
		try {
			const {
				data: {
					content: { data },
				},
			} = await axiosInstance.get(`bank/${userId}`);
			return data;
		} catch (error) {
			handleRequestError(error);
			return error;
		}
	};

	const {
		isLoading,
		isFetching,
		data: accounts,
		refetch: getExistingBanks,
	} = useQuery("getSavedAccounts", getUserAccounts, {
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		onError: () => toastError("Unable to retrieve Accounts"),
		onSuccess: (data) => setDetails(data),
	});

	// deleting saved bank account
	const deleteBankAccount = async (id: string) => {
		verifyData.current = rawVerifyData;
		try {
			const { data } = await axiosInstance
				.delete(`bank/${id}`)
				.then(() => getExistingBanks());
			toastSuccess(data.message || "Details deleted successfully");
			return data;
		} catch (error) {
			handleRequestError(error);
			return error;
		}
	};
	const useDeleteBank = () => useMutation(deleteBankAccount);
	interface GetBenefProps {
		name?: string | null;
		pageSize?: number | null;
		pageNum?: number | null;
	}
	const useGetQwidBeneficiaries = ({
		name,
		pageNum,
		pageSize,
	}: GetBenefProps) => {
		const getBenefs = async () => {
			try {
				const {
					data: {
						content: { data },
					},
				} = await axiosInstance.get(
					`beneficiary-transfer/${userId}?sortBy=first_name&sortOrder=ASC${name && `&name=${name}`}`
				);
				return data?.data;
			} catch (error) {
				return error;
			}
		};
		const {
			isLoading: fetchingBeneficiaries,
			isRefetching: refetching,
			data: qwidBeneficiaries,
			refetch: refetchBenefs,
		} = useQuery("getQwidbeneficiaries", getBenefs, {
			onError: () => toastError("Unable to get Beneficiaries"),
			refetchOnWindowFocus: false,
		});
		return {
			fetchingBeneficiaries,
			qwidBeneficiaries,
			refetchBenefs,
			refetching,
		};
	};

	// get API withdrawal charges
	const [wdlFee, setWdlFee] = useState<WithdrawalFeeResp | null>();
	const withdrawalChargeFn = async (payload: any) => {
		try {
			const data = await axiosInstance.get(
				`fee?transType=withdraw&userType=${isPersonalAccount ? 1 : 2}&peer=${
					payload?.cur
				}&amount=${payload?.amount}`
			);

			const response = data?.data?.content?.data[0];
			if (response?.amount?.length) {
				setWdlFee(response);
			} else {
				setWdlFee(null);
			}

			return data;
		} catch (error) {
			return error;
		}
	};
	const useWithdrawalCharge = () => useMutation(withdrawalChargeFn);
	const {
		mutateAsync: saveQwidRecipient,
		isLoading: savingQwidRecipient
	} = useMutation({
		mutationFn:  async( recipient_id: number | string)=>{
			const payload = {
				recipient_id
			}
			const {data} = await axiosInstance.post(`beneficiary-transfer/${userId}`, payload)
			return data
		},
		onSuccess: (data)=>{
			if(data.status === true){
				toastSuccess('Recipient saved')
				setTimeout(()=>{
					router.push(DASHBOARD_ROUTES.RECIPIENTS)
				},700)
			}
		},
		onError: (error: any)=>{
			/* const message =
				error?.response?.data?.message ||
				error?.message ||
				"something went wrong";
			toastError(message); */
			handleRequestError(error)
		}

		
	})

  const getQwid2OutsideBeneficiaries = async ({
    name,
    pageNum = 1,
    pageSize = 30
  }: GetBenefProps): Promise<PaginatedResponse<any>> => {
    let url = `/beneficiary/filter/${userId}?pageNum=${pageNum}&pageSize=${pageSize}`;
    const filter = name?.trim();
    if (filter) url += `&name=${filter}`;
    const { data } = await axiosInstance.get(url);
    const res = data?.content?.data
    return {
      currentPage: pageNum!,
      pageSize: pageSize!,
      total: Number(res?.total) || 0,
      data: (res?.data as any[]) || []
    };
  };

	return {
		banksLoading,
		getNgnBanks,
		banksData,
		addLoading,
		addBeneficiary,
		createLoading,
		createBank,
		bnkData,
		bnkLoading,
		fetchUserBanks,
		curBankData,
		withdrawalBanks,
		curBankLoading,
		fetchUserCurrencyBanks,
		invalidAcc,
		initLoading,
		initiateWithdrawal,
		verifLoading,
		verifyQwidUser,
		isVerified,
		initQwidTransfer,
		trfLoading,
		rcpId,
		deleteBeneficiary,
		dltLoading,
		useBankParams,
		useAddBank,
		verifyBankDetails,
		verifyLoading,
		verifyData,
		paramsData,
		setParamsData,
		accountNameFound,
		accountNumFound,
		bankNameFound,
		useDeleteBank,
		isLoading,
		isFetching,
		data: accounts,
		getExistingBanks,
		details,
		useWithdrawalCharge,
		wdlFee,
		useGetQwidBeneficiaries,
		qwidUserDetails,
		saveQwidRecipient,
		savingQwidRecipient,
    getQwid2OutsideBeneficiaries
	};
};

export default useBeneficiary;
