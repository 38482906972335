import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";

interface dashboardState {
	referralLink: string | null;
	userEmail: string;
	isSidebarOpen: boolean;
	isSidebarExpanded: boolean;
	isTopupModalOpen: boolean;
	isWithdrawalModalOpen: boolean;
	userWallets: UserWallet[];
	userVirtualAccts: any;
	topupItems: TopupItems | any;
	selectedWallet: UserWallet | {};
	transId: string;
	trfDetails: any;
	isBenefPending: boolean;
	isConfirmOffshoreOpen: boolean;
	userBeneficiaries: any;
	walletFlag: string;
	isBenefModalOpen: boolean;
	isQwidTrfOpen: boolean;
	isSendOptionsOpen: boolean;
	isKycBannerOpen: boolean;
	isTopupOptionsOpen: boolean;
	isAddingBeneficiary: boolean;
	isEpayModalOpen: boolean;
	topupCurrency: string;
	adminTopupPayload: AdminTopupProps;
	epayTransDetails: EpayTransProps;
	epayTransComplete: boolean;
	epaySuccessful: boolean;
	epayFailed: boolean;
	isEpayCancellationOpen: boolean;
	isPinAuthOpen: boolean;
	remittanceDetails: RemittanceProps;
	currentRemittancePage: string;
	currentStep: number;
	remittancefileName: string;
	countriesData: Record<string, string>[];
	visitedCountryCurrencies: Record<string, Record<string, string>[]>;
	existingStatesPerCountry: Record<string, Record<string, string>[]>;
	existingCitiesPerState: Record<string, Record<string, string>[]>;
	beneficiaryAccountName: string;
	beneficiaryActionType: string;
	createPinNavigator: createPinNavigatorProps;
	showKycCompletedBanner: boolean | undefined;
	convertDetails: any;
	addFundsDetails: any,
	beneficiaryBanks: Record<string, Record<string, string>[]>;
	isKycBannerMagnified: boolean;
	isWalletCreationOpen: boolean;
	userTier: UserTierProps | null;
}

const initialState: dashboardState = {
	userEmail: "",
	isSidebarOpen: true,
	isTopupModalOpen: false,
	isSidebarExpanded: true,
	isWithdrawalModalOpen: false,
	userWallets: [],
	userVirtualAccts: [],
	topupItems: {},
	selectedWallet: {},
	transId: "",
	trfDetails: {},
	isBenefPending: true,
	isConfirmOffshoreOpen: false,
	userBeneficiaries: [],
	isBenefModalOpen: false,
	isQwidTrfOpen: false,
	isSendOptionsOpen: false,
	isKycBannerOpen: false,
	// for dynamic flags on dashboard's wallet selector
	walletFlag: "",
	referralLink: null,
	isTopupOptionsOpen: false,
	isAddingBeneficiary: false,
	isEpayModalOpen: false,
	topupCurrency: "",
	adminTopupPayload: {
		id: 0,
		cur: "",
		bankName: "",
		accountName: "",
		accountNo: "",
		IBAN: "",
		swift: "",
		routingNo: "",
		payin_source: "",
		category: "",
		data: null,
		source: "",
		created_at: "",
		updated_at: "",
	},
	epayTransDetails: {
		transaction: { id: 0, userId: 0, transId: null },
		paymentLink: "",
	},
	epayTransComplete: false,
	epaySuccessful: false,
	epayFailed: false,
	isEpayCancellationOpen: false,
	isPinAuthOpen: false,
	remittanceDetails: {},
	remittancefileName: "",
	currentRemittancePage: "stepFields",
	currentStep: 0,
	countriesData: [],
	visitedCountryCurrencies: {},
	existingStatesPerCountry: {},
	existingCitiesPerState: {},
	beneficiaryAccountName: "",
	beneficiaryActionType: "create",
	createPinNavigator: {
		backRoute: DASHBOARD_ROUTES.SETTINGS,
		redirectRoute: DASHBOARD_ROUTES.DASHBOARD,
	},

	convertDetails: {
		fromCur: "",
		toCur: "",
		fromAmount: "",
	},

	addFundsDetails: {
		fundsData: []
	},

	showKycCompletedBanner: undefined,
	beneficiaryBanks: {},
	isKycBannerMagnified: false,
	isWalletCreationOpen: false,
	userTier: null,
};

export const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		convertCurrency: (state, { payload }: PayloadAction<any>) => {
			return {
				...state,
				convertDetails: {
					fromCur: payload.fromCur,
					toCur: payload.toCur,
					fromAmount: payload.fromAmount,
				},
			};
		},
		addFundsData: (state, { payload }: PayloadAction<any>) => {
			return {
				...state,
				addFundsDetails: {
					payload
				},
			};
		},
		setUserEmail: (state, { payload }: PayloadAction<string>) => {
			return {
				...state,
				userEmail: payload,
			};
		},

		deleteReferralLink: (state) => {
			return {
				...state,
				referralLink: null,
			};
		},

		saveReferralLink: (state, { payload }: PayloadAction<string>) => {
			return {
				...state,
				referralLink: payload,
			};
		},

		toggleEpayCancellation: (state) => {
			return {
				...state,
				isEpayCancellationOpen: !state.isEpayCancellationOpen,
			};
		},

		toggleTopupModal: (state) => {
			return {
				...state,
				isTopupModalOpen: !state.isTopupModalOpen,
			};
		},
		toggleTopupOptions: (state) => {
			return {
				...state,
				isTopupOptionsOpen: !state.isTopupOptionsOpen,
			};
		},

		toggleSendOptions: (state) => {
			return {
				...state,
				isSendOptionsOpen: !state.isSendOptionsOpen,
			};
		},

		toggleBenefModal: (state) => {
			return {
				...state,
				isBenefModalOpen: !state.isBenefModalOpen,
			};
		},
		togglePinAuth: (state) => {
			return {
				...state,
				isPinAuthOpen: !state.isPinAuthOpen,
			};
		},
		closePinAuth: (state) => {
			return {
				...state,
				isPinAuthOpen: false,
			};
		},

		setWalletFlag: (state, { payload }) => {
			return {
				...state,
				walletFlag: payload,
			};
		},

		setTopupCurrency: (state, { payload }) => {
			return {
				...state,
				topupCurrency: payload,
			};
		},

		clearWalletFlag: (state) => {
			return {
				...state,
				walletFlag: "",
			};
		},

		saveUserWallets: (state, { payload }: PayloadAction<UserWallet[]>) => {
			return {
				...state,
				userWallets: payload,
				selectedWallet: payload?.[0],
			};
		},

		saveUserBeneficiaries: (
			state,
			{ payload }: PayloadAction<UserWallet[]>
		) => {
			return {
				...state,
				userBeneficiaries: payload,
			};
		},

		saveVirtualAccounts: (state, { payload }: PayloadAction<any[]>) => {
			return {
				...state,
				userVirtualAccts: payload,
			};
		},

		saveTrfDetails: (state, { payload }: PayloadAction<any>) => {
			return {
				...state,
				trfDetails: payload,
			};
		},

		saveTopupItems: (state, { payload }: PayloadAction<TopupItems[]>) => {
			return {
				...state,
				topupItems: payload,
			};
		},

		toggleWithdrawalModal: (state) => {
			return {
				...state,
				isWithdrawalModalOpen: !state.isWithdrawalModalOpen,
			};
		},

		toggleConfirmOffshore: (state) => {
			return {
				...state,
				isConfirmOffshoreOpen: !state.isConfirmOffshoreOpen,
			};
		},
		toggleQwidTransfer: (state) => {
			return {
				...state,
				isQwidTrfOpen: !state.isQwidTrfOpen,
			};
		},

		toggleSelectedWallets: (state, { payload }: PayloadAction<string>) => {
			return {
				...state,
				selectedWallet: state.userWallets.filter(
					(item: UserWallet) => item.cur === payload
				)[0],
			};
		},

		toggleExpandedSidebar: (state) => {
			return {
				...state,
				isSidebarExpanded: !state.isSidebarExpanded,
			};
		},

		toggleKycBanner: (state) => {
			return {
				...state,
				isKycBannerOpen: !state.isKycBannerOpen,
			};
		},

		toggleAddingBeneficiary: (state) => {
			return {
				...state,
				isAddingBeneficiary: !state.isAddingBeneficiary,
			};
		},

		toggleEpayModal: (state) => {
			return {
				...state,
				isEpayModalOpen: !state.isEpayModalOpen,
			};
		},

		saveAdminTopupPayload: (
			state,
			{ payload }: PayloadAction<AdminTopupProps>
		) => {
			return {
				...state,
				adminTopupPayload: payload,
			};
		},

		clearAdminTopupPayload: (state) => {
			return {
				...state,
				adminTopupPayload: {
					id: 0,
					cur: "",
					bankName: "",
					accountName: "",
					accountNo: "",
					IBAN: "",
					swift: "",
					routingNo: "",
					payin_source: null,
					category: "",
					data: null,
					source: "",
					created_at: "",
					updated_at: "",
				},
			};
		},

		resetEpayParams: (state) => {
			return {
				...state,
				epaySuccessful: false,
				epayFailed: false,
				epayTransComplete: false,
			};
		},

		epayTrxSuccessful: (state) => {
			return {
				...state,
				epaySuccessful: true,
				epayFailed: false,
				epayTransComplete: true,
			};
		},

		epayTrxFailed: (state) => {
			return {
				...state,
				epayFailed: true,
				epaySuccessful: false,
				epayTransComplete: true,
			};
		},

		saveEpayTransDetails: (
			state,
			{ payload }: PayloadAction<EpayTransProps>
		) => {
			return {
				...state,
				epayTransDetails: payload,
				isEpayModalOpen: true,
			};
		},
		switchRemittancePage: (state, { payload }: PayloadAction<string>) => {
			return {
				...state,
				currentRemittancePage: payload,
			};
		},
		setCurrentStep: (state, { payload }: PayloadAction<number>) => {
			return {
				...state,
				currentStep: payload,
			};
		},
		setRemittanceDetails: (
			state,
			{ payload }: PayloadAction<RemittanceProps>
		) => {
			return {
				...state,
				remittanceDetails: {
					...state.remittanceDetails,
					...payload,
				},
			};
		},
		clearRemittanceDetails: (state) => {
			return {
				...state,
				remittanceDetails: {},
			};
		},

		setRemittanceFilename: (state, { payload }: PayloadAction<string>) => {
			return {
				...state,
				remittancefileName: payload,
			};
		},
		setCreatePinNavigator: (
			state,
			{ payload }: PayloadAction<createPinNavigatorProps>
		) => {
			return {
				...state,
				createPinNavigator: payload,
			};
		},

		setCountriesData: (
			state,
			{ payload }: PayloadAction<Record<string, string>[]>
		) => ({
			...state,
			countriesData: payload,
		}),
		setVisitedCountryCurrencies: (
			state,
			{ payload }: PayloadAction<Record<string, Record<string, string>[]>>
		) => ({
			...state,
			visitedCountryCurrencies: {
				...state.visitedCountryCurrencies,
				...payload,
			},
		}),
		setExistingStatesPerCountry: (
			state,
			{ payload }: PayloadAction<Record<string, Record<string, string>[]>>
		) => ({
			...state,
			existingStatesPerCountry: {
				...state.existingStatesPerCountry,
				...payload,
			},
		}),
		setExistingCitiesPerState: (
			state,
			{ payload }: PayloadAction<Record<string, Record<string, string>[]>>
		) => ({
			...state,
			existingCitiesPerState: { ...state.existingCitiesPerState, ...payload },
		}),
		setBeneficiaryAccountName: (
			state,
			{ payload }: PayloadAction<Record<string, string>>
		) => ({
			...state,
			beneficiaryAccountName: payload.beneficiaryAccountName,
			beneficiaryActionType: payload.beneficiaryActionType,
		}),
		setShowKycCompletedBanner: (
			state,
			{ payload }: PayloadAction<{ showKycCompletedBanner: boolean }>
		) => ({
			...state,
			showKycCompletedBanner: payload.showKycCompletedBanner,
		}),
		setBeneficiaryBanks: (state, { payload }: PayloadAction<Record<string, Record<string, string>[]>>) => ({
			...state,
			beneficiaryBanks: { ...state.beneficiaryBanks, ...payload }
		}),
		toggleKycMagnifier: (state, { payload }: PayloadAction<boolean>) => {
			return {
				...state,
				isKycBannerMagnified: payload
			}
		},
		clearConversionData: (state) => {
			return {
				...state,
				convertDetails: {
					fromCur: "",
					toCur: "",
					fromAmount: "",
				},
			}
		},

		toggleWalletCreationModal: (state) => {
			return {
				...state,
				isWalletCreationOpen: !state.isWalletCreationOpen,
			};
		},
		clearDashboardData: () => {
			return initialState;
		},

		saveUserTierInfo: (state, { payload }: PayloadAction<UserTierProps>) => {
			return {
				...state,
				userTier: payload
			}
		}
	},
});

export const {
	toggleTopupModal,
	saveUserWallets,
	saveTopupItems,
	toggleWithdrawalModal,
	saveVirtualAccounts,
	toggleSelectedWallets,
	saveTrfDetails,
	toggleConfirmOffshore,
	saveUserBeneficiaries,
	setWalletFlag,
	toggleBenefModal,
	toggleQwidTransfer,
	setUserEmail,
	toggleExpandedSidebar,
	clearWalletFlag,
	toggleSendOptions,
	saveReferralLink,
	deleteReferralLink,
	toggleKycBanner,
	toggleTopupOptions,
	toggleAddingBeneficiary,
	toggleEpayModal,
	setTopupCurrency,
	saveAdminTopupPayload,
	saveEpayTransDetails,
	epayTrxFailed,
	epayTrxSuccessful,
	resetEpayParams,
	toggleEpayCancellation,
	clearAdminTopupPayload,
	togglePinAuth,
	closePinAuth,
	switchRemittancePage,
	setCurrentStep,
	setRemittanceDetails,
	clearRemittanceDetails,
	setRemittanceFilename,
	setCountriesData,
	setVisitedCountryCurrencies,
	setExistingStatesPerCountry,
	setExistingCitiesPerState,
	setBeneficiaryAccountName,
	setCreatePinNavigator,
	setShowKycCompletedBanner,
	convertCurrency,
	setBeneficiaryBanks,
	toggleKycMagnifier,
	addFundsData,
	clearConversionData,
	toggleWalletCreationModal,
	clearDashboardData,
	saveUserTierInfo
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
