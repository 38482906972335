import { DatePicker, Form, Input } from "antd";
import { Moment } from "moment";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { MdOutlineCalendarToday } from "react-icons/md";

type Props = {
	name?: string;
	label: string | React.ReactNode;
	placeholder?: string;
	type?: string;
	loading?: boolean;
	className?: string;
	value?: string | number | undefined;
	valueSetter?: Dispatch<SetStateAction<string>>;
	isNumberValidated?: boolean;
	nonEditable?: boolean;
	hasBoldValue?: boolean;
	isOptional?: boolean;
	isMapped?: boolean;
	disabled?: boolean;
	isDatePicker?: boolean;
	required?: boolean;
	tag?: React.ReactNode;
	action?: React.ReactNode;
	customRule?: any;
	onValChange?: any;
	onDateChange?: (value: Moment | null) => void;
	disabledDate?: ((date: Moment) => boolean) | undefined;
	maxLength?: number;
	hasDefaultValue?: boolean;
	defaultValue?: any;
	customAntRules?: Record<string, string | number | boolean | RegExp>[];
	validationStatus?: "success" | "error" | "warn";
	onBlurChange?: (e: any) => void;
	showRequired?: boolean
	isCustom?: boolean
	useCustomDropdownArrow?: boolean;
};

const CustomInputField = ({
	name,
	label,
	placeholder,
	value,
	valueSetter,
	isNumberValidated,
	nonEditable,
	tag,
	customRule,
	onValChange,
	isMapped,
	hasBoldValue,
	disabled,
	isOptional,
	required,
	action,
	className,
	type,
	isDatePicker,
	onDateChange,
	disabledDate,
	maxLength,
	hasDefaultValue,
	defaultValue,
	customAntRules,
	loading,
	validationStatus,
	onBlurChange,
	showRequired,
	isCustom,
	useCustomDropdownArrow
}: Props) => {
	// Basic css style values for custom error state
	const validationStatusClassName = useMemo(() => {
		switch (validationStatus) {
			case "error":
				return "border-[#E00000]";
			case "success":
				return "border-[#17C509]";
			case "warn":
				return "border-[#e5cfa3]";
			default:
				return "";
		}
	}, [validationStatus]);
	const handleInput = (event: any) => {
		const numericValue = event.target.value.replace(/\D/g, "");
		valueSetter ? valueSetter(numericValue) : null;
	};

	return isMapped ? (
		<div className={`form-custom-input-wrap `}>
			<label
				style={{
					fontSize: "11px",
				}}
				className="form-item__label">
				{label}
			</label>
			<input
				required={required}
				disabled={disabled}
				style={{
					fontWeight: hasBoldValue ? 500 : "normal",
				}}
				onChange={onValChange}
				value={value}
				type="text"
				placeholder={placeholder || ""}
				className={`${className} ${disabled && "cursor-not-allowed"
					} form-custom-input`}
			/>
			{tag && <div className="absolute -bottom-8 right-0">{tag}</div>}
			{action && <div className="absolute -top-6 right-0">{action}</div>}
		</div>
	) : nonEditable ? (
		<Form.Item name={name} rules={[{ required: false }]}>
			<div className="form-custom-input-wrap">
				<label
					style={{
						fontSize: "11px",
					}}
					className="form-item__label">
					{label}
				</label>
				<input
					readOnly
					value={value}
					type="text"
					placeholder={placeholder || ""}
					className="form-custom-input cursor-not-allowed"
				/>
			</div>
		</Form.Item>
	) : isOptional ? (
		<div className="form-custom-input-wrap">
			<label
				style={{
					fontSize: "11px",
				}}
				className="form-item__label">
				{label}
			</label>
			<input
				required={required}
				name={name}
				onChange={onValChange}
				type={type || "text"}
				placeholder={placeholder}
				className={`${className} form-custom-input`}
			/>
			{tag && <div className="absolute -bottom-6 right-0">{tag}</div>}
			{action && <div className="absolute -top-6 right-0">{action}</div>}
		</div>
	) : isDatePicker ? (
		<Form.Item
			name={name || "dob"}
			className="w-full"
			rules={[{ required: required, message: "Enter date" }]}>
			<div className={`${isCustom ? 'relative' : 'form-custom-input-wrap'}`}>
				<label
					style={{
						fontSize: "11px",
						top: "-9px",
						zIndex: 10,
					}}
					className="form-item__label">
					{isCustom ? '' : label || "Date of Birth"}
				</label>
				<DatePicker
					onChange={onDateChange}
					disabledDate={disabledDate}
					style={{ padding: "8.5px 10px" }}
					className={`py-8 w-full ${className}`}
					format='DD/MM/YYYY'
					placeholder={isCustom ? placeholder : "DD/MM/YYYY"}
				/>

				{useCustomDropdownArrow &&
					<div className="absolute right-2 top-2.5 bg-[#eef3f7]">
						<MdOutlineCalendarToday size='1.3rem' className="text-qwid-gray-200" />
					</div>
				}
			</div>
		</Form.Item>
	) : isNumberValidated ? (
		<Form.Item
			name={name}
			rules={[{ required: true, message: "Required" }, customRule]}>
			<div className="form-custom-input-wrap">
				<label
					style={{
						fontSize: "11px",
					}}
					className="form-item__label">
					{label}
				</label>
				<input
					id="custom-input"
					maxLength={maxLength}
					onChange={onValChange}
					required={required}
					type={"text"}
					value={value}
					onInput={handleInput}
					placeholder={placeholder}
					className={`${className} form-custom-input`}
				/>
				{tag && <div className="absolute -bottom-6 right-0">{tag}</div>}
				{action && <div className="absolute -top-6 right-0">{action}</div>}
			</div>
		</Form.Item>
	) : (
		<Form.Item
			name={name}
			validateFirst
			style={{ ...(type === 'hidden' && { display: 'none' }) }}
			rules={
				customAntRules?.length
					? customAntRules
					: [{ required: required, message: "Required" }]
			}>
			<div
				className={`${!isCustom && 'form-custom-input-wrap'} ${disabled ? "cursor-not-allowed" : ""
					} ${validationStatusClassName}`.trim()}>
				<label
					style={{
						fontSize: "11px",
					}}
					className={`truncate form-item__label ${(required || showRequired) && label !== '' ? "required__field" : ""
						}`}>
					{label}
				</label>
				<input
					disabled={disabled}
					maxLength={maxLength}
					defaultValue={defaultValue}
					value={value}
					onChange={onValChange}
					onBlur={onBlurChange}
					// required={required}
					type={type || "text"}
					placeholder={placeholder}
					className={`${className} form-custom-input`}
				/>
				{loading && (
					<div className="absolute -bottom-5 right-0">
						<img src="/assets/dashboard/moving-dots.svg" alt="..." />
					</div>
				)}
				{action && <div className="absolute -top-6 right-0">{action}</div>}
				{tag && <div className="absolute -bottom-6 right-0">{tag}</div>}
			</div>
		</Form.Item>
	);
};

export default CustomInputField;
