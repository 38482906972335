import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState:  BusinessOnBoardingDataProps = {
    businessDetails: null,
    firstData: [],
    shareHoldersInfo: [],
    shareholderValue: 0,
    directorValue: 0,
    temporaryDirector: null
    
}
const onboardingDataSlice = createSlice({
  name: "onboarding-data",
  initialState,
  reducers: {
    setBusinessDetails: (state,{payload}: PayloadAction<BusinessAccountProp>) => {
        return {
            ...state,
            businessDetails: {
                ...state.businessDetails,
                ...payload
            }
        }
    },
    increaseDirector: (state) => {
      state.directorValue += 1;
    },
    increaseShareholder: (state) => {
      state.shareholderValue += 1;
    },
    setNumberOfDirectors:  (state, {payload} :any) => {
      return{
        ...state,
      firstData: payload
      }  
    },
    setDirectorsInfo: (state, { payload }: any) => {
      // Generate a unique ID for the new payload
      const newId = Date.now(); // You can use a better ID generation method
    
      // Create a new object with the payload and the generated ID
      const newPayload = {
        id: newId,
        ...payload,
      };
    
      // Filter out existing payloads with the same ID
      const filteredShareHoldersInfo = state.shareHoldersInfo.filter(
        (item :any) => item.id !== newId
      );
    
      // Add the new payload to the filtered array
      const updatedShareHoldersInfo = [...filteredShareHoldersInfo, newPayload];
    
      return {
        ...state,
        shareHoldersInfo: updatedShareHoldersInfo,
      };
    },
    setTemporaryDirector: (state,{payload}: PayloadAction<BusinessAccountProp>) => {
      return {
          ...state,
          temporaryDirector: {
              ...state.temporaryDirector,
              ...payload
          }
      }
  },
  clearOnbDataState: (state)=>{
    return initialState
  }
      
    
  }
});
export const { 
    setNumberOfDirectors,
    setDirectorsInfo,
    setBusinessDetails,
    increaseDirector,
    increaseShareholder,
    setTemporaryDirector,
    clearOnbDataState
} = onboardingDataSlice.actions

export default onboardingDataSlice.reducer
