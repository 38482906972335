import { OptionalRender, useToolkit, useTransactions } from 'components';
import { Table } from 'antd';
import useWalletBalance from 'utils/hooks/wallet-balance/useWalletBalance';
import { viewSingleTransaction } from 'redux/slices/transactionsSlice';
import styled from 'styled-components';
import { ImSpinner9 } from 'react-icons/im';
import { useQuery } from 'react-query';

type Props = {};

const WalletActivityTable = (props: Props) => {
  const { useAppSelector, axiosInstance, userId, useEffect, moment, getSymbol, dispatch, handleRequestError } =
    useToolkit();
  //const { fetchUserActivities, loading, userActivities } = useWalletBalance();
  const { selectedWallet } = useAppSelector((state: any) => state.dashboard);
  const cur = selectedWallet?.cur || 'NGN';

  const { data: userActivities, isLoading: loading, isRefetching } = useQuery({
    queryKey: ['getWalletActivity', selectedWallet],
    queryFn: async () => {
      try {

        const { data } = await axiosInstance.post(
          `transaction/user/cur/${userId}`,
          { cur, start: 0, limit: 20 }
        );
        const response = data?.content?.data?.transaction;
        return response
      } catch (error) {
        handleRequestError(error)
      }
    },
    // enabled: !!selectedWallet?.cur
  })
  const { getTransType, formatTransStatus, formatTrxAmount, getInitials } =
    useTransactions();


  const emptyTransactions = loading === false && userActivities?.length < 1;

  const columns = [
    {
      title: 'Name',
      key: 'externalName',
      render: (_: any, record: any) => getInitials(record?.externalName),
    },
    {
      title: 'Date',
      key: 'created_at',
      render: (_: any, record: any) => (
        <p className="mb-0">
          {moment(record?.created_at).format('DD/MM/YYYY | hh:mm:ss A')}
        </p>
      ),
    },

    {
      title: 'Type',
      dataIndex: 'transType',
      key: 'transType',
      render: (_: any, record: any) => (
        <p className="mb-0">{getTransType(record?.transType)}</p>
      ),
    },
    {
      title: 'Status',
      key: 'trx_status',
      render: (record: any) => <div>{formatTransStatus(record?.status)}</div>,
    },

    {
      title: <div className="text-center">Amount</div>,
      dataIndex: 'toAmount',
      key: 'toAmount',
      render: (_: any, record: any) => (
        <p className="mb-0 text-right">
          {getSymbol(record?.transType === 1 ? record?.toCur : record?.fromCur)}
          &nbsp;
          {formatTrxAmount(
            Number(
              record?.transType === 1 ? record?.toAmount : record?.fromAmount
            ),
            2
          )}
        </p>
      ),
    },

    {
      title: 'Transaction ID',
      dataIndex: 'transId',
      key: 'transId',
    },
  ];

  /* useEffect(() => {
    if (selectedWallet && selectedWallet?.cur) {
      fetchUserActivities(selectedWallet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWallet]); */

  return (
    <div className="w-full">
      <OptionalRender condition={emptyTransactions}>
        <div className="w-full flexed flex-col pb-10">
          <img
            src="/assets/dashboard/empty-transactions.svg"
            alt="empty-transactions"
          />

          <div className="mt-2 flexed flex-col">
            <p className="mb-2 font-medium capitalize text-dark-grey">
              No transactions yet
            </p>

            <p className="mb-0 text-[#0D0D0D] lg:w-3/4 text-center">
              Your transaction history will appear here after your first
              transaction.
            </p>
          </div>
        </div>
      </OptionalRender>

      {loading ? (
        <div className='text-[#20546B] font-medium text-center mx-auto my-[2rem] flex flex-col items-center gap-3'>
          <ImSpinner9 className=" text-xl animate-spin " />
          <div>Fetching {selectedWallet?.cur} transactions...</div>
        </div>
      ) : (
        <OptionalRender condition={userActivities?.length > 0}>
          <div className={`w-full ${isRefetching && 'opacity-30'}`}>
            <StyledTable
              rowClassName="curp w-full"
              onRow={(record) => {
                return {
                  onClick: () => dispatch(viewSingleTransaction(record)),
                };
              }}
              columns={columns}
              dataSource={userActivities?.slice(0, 10)}
              loading={loading}
              pagination={false}
              size="large"
            />
          </div>
        </OptionalRender>
      )}
    </div>
  );
};

export default WalletActivityTable;

const StyledTable = styled(Table)`
  th {
    font-weight: 500 !important;
    color: #010101 !important;
    background-color: #f5f5f5 !important;
  }
`;
