import React from "react";
import { useToolkit, useFramer } from "../../../components";
import { motion } from "framer-motion";

type Props = {};

const DigitalBank = (props: Props) => {
	const { isMobile } = useToolkit();
	const { zoomWrap, flashIn } = useFramer();

	return (
		<div className="bg-white side-pad pt-3 lg:pt-8 pb-10 lg:pb-20">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				viewport={{ once: true }}
				whileInView="visible"
				className="w-full flexed flex-col">
				<motion.p
					variants={flashIn}
					className="tcent med text-lg lg:text-2xl pry-text mt-5">
					Download qwid
				</motion.p>

				<motion.p
					variants={flashIn}
					style={{ lineHeight: isMobile ? "25px" : "46px" }}
					className="smb mb-6 text-lg lg:text-4xl tcent tracking-wide">
					The all-in-one answer to <br /> your payment needs
				</motion.p>

				<motion.p
					variants={flashIn}
					className="mb-8 tracking-wide lg:t6 tcent lg:w-2/6 mx-auto">
					Get qwid today and take the hassle out of payments
				</motion.p>
			</motion.div>

			<div className="w-full mt-6 mb-10 flexed space-x-4">
				<div>
					<img
						onClick={() =>
							window.open(
								"https://apps.apple.com/us/app/qwid/id6444584444",
								"_blank"
							)
						}
						width={120}
						src="/assets/landing/gplay-dark.svg"
						alt="store-btn"
						className="store-btn"
					/>
				</div>
				<div>
					<img
						onClick={() =>
							window.open(
								"https://play.google.com/store/apps/details?id=io.blinqpay.qwid",
								"_blank"
							)
						}
						width={120}
						src="/assets/landing/appstore-dark.svg"
						alt="store-btn"
						className="store-btn"
					/>
				</div>
			</div>

			<motion.div
				variants={zoomWrap}
				initial="hidden"
				viewport={{ once: true }}
				whileInView="visible"
				className="w-full flexed space-x-5 mt-16">
				<motion.img
					variants={flashIn}
					src="/assets/landing/howtosend.png"
					alt="screenshot"
					className="lg:w-[250px]"
				/>
				<motion.img
					variants={flashIn}
					src="/assets/landing/db-sendmoney2.png"
					alt="screenshot"
					className="hidden lg:inline lg:w-[250px]"
				/>
				<motion.img
					variants={flashIn}
					src="/assets/landing/db-addmoney3.png"
					alt="screenshot"
					className="hidden lg:inline lg:w-[250px]"
				/>
			</motion.div>
		</div>
	);
};

export default DigitalBank;
