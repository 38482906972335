import React from "react";
import { toggleFundCardModal } from "redux/slices/virtualsSlice";
import { useToolkit, QwidButton } from "../../components";

type Props = {
	selectedVCard: VirtualCard | null;
};

type ActionBtnProps = {
	isDark?: boolean;
	text: string;
	onClick: () => void;
};

const CardBalanceActions = ({ selectedVCard }: Props) => {
	const { getSymbol, dispatch, useAppSelector } = useToolkit();

	const { cardInfo } = useAppSelector((state) => state.virtuals);

	const { cardDetails } = cardInfo;

	const ActionButton = ({ isDark, text, onClick }: ActionBtnProps) => {
		return (
			<div
				onClick={onClick}
				className={`rounded-md curp px-3 py-1.5 active:scale-90 transition-transform flexed space-x-1 ${
					isDark
						? "bg-[#194153] text-white "
						: "bg-white text-[#194153] border border-[#194153]"
				}`}>
				<img
					className="mr-1.5"
					src={
						isDark
							? "/assets/dashboard/cr-card.svg"
							: "/assets/dashboard/db-card.svg"
					}
					alt="debit"
				/>
				{text}
			</div>
		);
	};

	return (
		<div className="w-full border-2 border-[#DFDFDF] rounded-lg mb-5 lg:mb-14">
			<div className="w-full border-b border-[#DFDFDF] lg:text-lg px-4 py-5">
				<h4 className="mb-0 twide">Card Information</h4>
			</div>
			<div className="px-4 py-5 lg:py-8 flex justify-between items-end">
				<div className="flex flex-col">
					<p className="mb-2 lg:mb-3.5 lg:text-lg">Balance</p>
					<h2 className="text-lg lg:text-3xl smb twide mb-0">
						{cardDetails && getSymbol(cardDetails.virtualCardCur)}&nbsp;
						{cardDetails?.virtualCardBalance.toLocaleString()}
					</h2>
				</div>
				{cardDetails && (
					<div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-4">
						<ActionButton
							text="Fund Card"
							isDark
							onClick={() => dispatch(toggleFundCardModal())}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default CardBalanceActions;
