type TabProps = {
	title: string;
	subtitle: string;
	imgpath: string;
	bgColor: string;
};

const tabs: TabProps[] = [
	{
		title: "seamless shopping experience",
		subtitle:
			"Shop anywhere in the world on the internet and experience seamless instant payments with our virtual cards.",
		imgpath: "/assets/features/seamless.png",
		bgColor: "#48BBED",
	},
	{
		title: "explore and pay globally",
		subtitle:
			"Explore the world in the palm of your hands with our virtual cards available in major currencies around the world.",
		imgpath: "/assets/features/explore.png",
		bgColor: "#222222",
	},
];

const SeamlessExplore = () => {
	return (
		<div className="bwite py-10 lg:md-16 side-pad flexed flex-wrap md:flex-nowrap space-y-7 md:space-y-0 md:flex-row md:space-x-7">
			{tabs.map(({ title, subtitle, imgpath, bgColor }: TabProps) => (
				<div
					style={{ background: bgColor }}
					className="rounded-lg flex flex-col items-start py-7 px-10 twite  h-[400px] md:h-[450px]"
					key={title}>
					<img
						src={imgpath}
						alt="icon"
						width={70}
						className="object-cover mt-10"
					/>
					<div className="mt-10 md:mt-12 lg:mt-16">
						<p className="mb-5 smb twide capitalize text-lg md:text-3xl">{title}</p>
						<p className="twide md:w-3/4">{subtitle}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default SeamlessExplore;
