import { Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";
import {
	useDashboard,
	useToolkit,
	QwidButton,
	Spinner,
} from "../../components";

type Props = {
	setConfirmShown: React.Dispatch<React.SetStateAction<boolean>>;
	trfDetails: any;
	idData: any;
};

const ConfirmTopup = ({ setConfirmShown, trfDetails: bank, idData }: Props) => {
	const { useAppSelector, router } = useToolkit();
	const { initiateTopup, reqLoading } = useDashboard();
	const { topupItems: item } = useAppSelector((state) => state.dashboard);

	const init = () => {
		const payload = {
			transId: idData,
			cur: item?.wallet,
			amount: item?.amount,
			bankId: bank?.id,
			source: bank?.source,
		};
		initiateTopup(payload).then(() => router.push(DASHBOARD_ROUTES.DASHBOARD));
	};

	return reqLoading ? (
		<Spinner msg="Processing" />
	) : (
		<div className="w-full mt-4">
			<p className="my-7 med twide">
				Transaction ID:&nbsp; {idData?.toUpperCase()}
			</p>
			<div className="my-7">
				<Div>
					<p className="confirm-left mb-0">
						{item?.wallet}&nbsp;{parseInt(item?.amount)?.toLocaleString()}
					</p>
					<p className="confirm-right t2 text-[#62666E] twide mb-0">
						Amount Depositing
					</p>
				</Div>
				<Div>
					<p className="confirm-left mb-0">{bank?.bankName}</p>
					<p className="confirm-right t2 text-[#62666E] twide mb-0">
						Bank Name
					</p>
				</Div>
				<Div>
					<p className="confirm-left mb-0">{bank?.accountName}</p>
					<p className="confirm-right t2 text-[#62666E] twide mb-0">
						Recipient Bank
					</p>
				</Div>
				<Div>
					<Tooltip title={bank?.accountNo}>
						<p className="confirm-left mb-0">
							{bank?.accountNo?.substring(0, 10)} ***
						</p>
					</Tooltip>
					<p className="confirm-right t2 text-[#62666E] twide mb-0">
						Recipient Acct. No
					</p>
				</Div>
			</div>

			<div className="my-5 bg-[#FFF9ED] p-4 rounded-md text-[#58461C]">
				<p className="mb-3 smb lg:text-lg twide">Please note</p>
				<p className="mb-0">
					Transactions usually reflect within 5 to 10 minutes. If it takes
					longer, please contact our customer care centre:&nbsp;
					<span className="pry-text">
						<a href="mailto:support@qwid.io">support@qwid.io</a>
					</span>
				</p>
			</div>

			<div className="flect justify-end mt-10 mb-2">
				<QwidButton
					onClick={() => setConfirmShown(false)}
					transparent
					text={<span className="px-2">Cancel</span>}
				/>{" "}
				&nbsp;&nbsp;&nbsp;&nbsp;
				<QwidButton
					onClick={init}
					text={<span className="px-2">Confirm transaction</span>}
				/>
			</div>
		</div>
	);
};

export default ConfirmTopup;

const Div = styled.div`
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.confirm-left {
		color: #252d3e;
		font-weight: 500;
	}
`;
