import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";


type SecurityModalState = {
  modalState: boolean;
  notNow: boolean; 
};

// Initial state
const initialState: SecurityModalState = {
  modalState: false,
  notNow: false, 
};

// Create the slice
const securityModalSlice = createSlice({
  name: "securityModal",
  initialState,
  reducers: {
    setSecurityModalState: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.modalState = action.payload;
    },
    setNotNowState: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.notNow = action.payload;
    },
  },
});

// Export the actions to change the modal and notNow states
export const { setSecurityModalState, setNotNowState } = securityModalSlice.actions;


export default securityModalSlice.reducer;
