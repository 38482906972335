import Script from "next/script";

const ZohoWidget = () => {
	const widgetCode = process.env.NEXT_PUBLIC_ZOHO_WIDGET_CODE;
	const domain = process.env.NEXT_PUBLIC_ZOHO_DOMAIN_URL;

	return (
		<Script
			id="zoho-widget"
			strategy="afterInteractive"
			dangerouslySetInnerHTML={{
				__html: `
     var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:"${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${domain}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
     `,
			}}
		/>
	);
};

export default ZohoWidget;
