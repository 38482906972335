import OptionalRender from "components/misc/OptionalRender";
import { useState, useRef, useEffect } from "react";
import { MdArrowDropUp } from "react-icons/md";


type DropdownProps = {
   reference: any;
   currenciesList: any[];
   onSelect: (value: any) => void;
}

const DropdownHandler = ({ currenciesList, reference, onSelect }: DropdownProps) => {
   const [isOpen, setIsOpen] = useState(false);
   const dropdownRef = useRef<HTMLDivElement>(null);

   const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
         setIsOpen(false);
      }
   };

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
      // eslint-disable-next-line
   }, []);

   return (
      <>
         <div onClick={() => setIsOpen(true)} className="absolute curp right-3 top-2/4 transform -translate-y-2/4 flex items-center">
            <img
               width={30}
               alt={reference?.cur}
               src={reference?.flag}
            />

            <div className='text-[16px] text-qwid-dark-50 font-medium ml-1.5'>
               {reference?.cur}
            </div>

            <MdArrowDropUp
               size="1.5rem"
               className={`transition-transform duration-300 transform rotate-180 
               ${isOpen && 'text-qwid-blue-50 rotate-0'}`}
            />
         </div>

         <OptionalRender condition={isOpen}>
            <div ref={dropdownRef} className="absolute rounded-xl right-4 top-14 shadow-md border-[3px] bg-white w-[300px] z-50">
               {currenciesList?.map((item: any) => {

                  return (
                     <div
                        key={item.cur}
                        onClick={() => { onSelect(item); setIsOpen(false) }}
                        className="w-full flex-btw cursor-pointer hover:bg-qwid-gray-600 p-4 hover:rounded-t-xl hover:rounded-b-xl">

                        <div className="flex items-center">
                           <img
                              width={30}
                              alt={item?.cur}
                              src={item?.flag}
                           />

                           <div className='text-[16px] text-qwid-dark-50 font-medium ml-1.5 whitespace-nowrap'>
                              {item?.country}
                           </div>
                        </div>

                        <div className='text-[16px] text-qwid-dark-50 font-medium'>
                           {item?.cur}
                        </div>

                     </div>
                  )
               })}
            </div>
         </OptionalRender>

      </>
   )
}

export default DropdownHandler