import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./slices/userSlice";
import dashboardReducer from "./slices/dashboardSlice";
import conversionsReducer from "./slices/conversionsSlice";
import transactionsReducer from "./slices/transactionsSlice";
import layoutReducer from "./slices/layoutSlice";
import virtualsReducer from "./slices/virtualsSlice";
import settingsReducer from "./slices/settingsSlice";
import onboardingReducer from "./slices/onboardingSlice";
import notificationReducer from "./slices/notificationSlice";
import onboardingDataReducer from "./slices/onboardingDataSlice";
import marketinReferalReducer from "./slices/marketingReferralSlice";
import sendflowReducer from "./slices/sendflowSlice";
import recipientSlice from "./slices/recipientSlice";
import recipientDetailsSlice from "./slices/recipientDetails";
import productUpdateSlice from './slices/productsUpdateSlice';
import securityModalSlice from './slices/securityModalSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import thunk from "redux-thunk";
import transactionSearchSlice from "./slices/transactionSearchSlice";

const rootReducer = combineReducers({
	user: userReducer,
	securityModal :securityModalSlice, 
	layout: layoutReducer,
	dashboard: dashboardReducer,
	conversions: conversionsReducer,
	transactions: transactionsReducer,
	virtuals: virtualsReducer,
	settings: settingsReducer,
	onboarding: onboardingReducer,
	onboardingData: onboardingDataReducer,
	notification: notificationReducer,
	sendflow: sendflowReducer,
	recipients: recipientSlice,
	recipients_details: recipientDetailsSlice,
	transaction_search: transactionSearchSlice,
	productUpdateSlice,
	marketing_referral : marketinReferalReducer
});

const persistConfig = {
	key: "root",
	storage,
	blacklist: [
		"dashboard",
		"layout",
		"conversions",
		"transactions",
		"virtuals",
		"settings",
		"onboardingData",
		"onboarding",
		"notification",
		'productUpdateSlice',
		"marketing_referral",
	],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
