import { motion } from "framer-motion";
import { useFramer, useToolkit } from "../../../../components";

type Props = {};

const ConnectingYou = (props: Props) => {
	const { zoomContent, zoomWrap } = useFramer();
	const { PryText } = useToolkit();

	return (
		<div className="py-8 lg:py-16 bg-[#EAE5FF]">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper2">
				<motion.div
					variants={zoomContent}
					className="rounded-lg w-full md:w-2/4 lg:w-2/4 md:h-[400px] flex items-center justify-center">
					<div className="w-3/4 mx-auto flexed">
						<img
							alt="how-to-send"
							src="/assets/features/rcv-gets.png"
							className="object-cover lg:h-[300px]"
						/>
					</div>
				</motion.div>

				<motion.div
					variants={zoomContent}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-0 mt-5 lg:mt-0 med text-center mx-auto md:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						Track transfers in <PryText text="real-time" /> online payments
					</p>

					<p className="my-5 text-center lg:t6 md:text-left">
						Have full control of your transfers. See how much the receiver gets,
						when the money arrives and in what currency just before you send it.
					</p>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default ConnectingYou;
