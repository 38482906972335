import React, { useCallback } from 'react';
import InputTypes from './InputElements';
import { Form, Input, InputNumber, Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Rule } from 'antd/lib/form';

const TextInput = ({
  label,
  name,
  placeholder,
  required,
  noErrorVibration,
  type,
  nonEditable,
  nonEditableText,
  subtitle,
  subtitleInvert,
  subtitleOnClick,
  subtitleTooltipText,
  subtitleTooltipPlacement,
  noSubIcon,
  className,
  isEmailValidated,
  isNumberValidated,
  customRules,
  onChange,
  hidden,
  validating,
  loading,
  onBlurHandler,
  hiddenRequiredLabel = false,
  disabled,
  isCommaSeperated,
  validateStatus,
  defaultValue
}: InputTypes.TextInputProps) => {
  const styleOne = `custom-input ${noErrorVibration ? '' : 'vibrate'}`;
  const getValidateStatusClass = useCallback(() => {
    switch (validateStatus) {
      case 'success':
        return 'border-green-500';
      case 'warning':
        return 'border-yellow-500';
      default:
        return '';
    }
  }, [validateStatus]);

  const validateEmail = (rule: any, value: any, callback: any) => {
    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Check if the entered value matches the email pattern
    const isEmailValid = emailRegex.test(value);

    if (isEmailValid) {
      callback(); // Valid email, no error
    } else {
      callback('Please enter a valid email address.'); // Invalid email, provide an error message
    }
  };

  const validateText = (rule: any, value: any, callback: any) => {
    if (!required) {
      callback();
    } else if (!value && required) {
      callback('Input cannot be empty'); // Empty input, provide an error message
    } else if (value.trim() === '') {
      callback('Input cannot be only spaces'); // Input with only spaces, provide an error message
    } else {
      callback(); // Valid input, no error
    }
  };
  const requiredRule = [
    {
      required,
      message: `Please enter ${label}`
    }
  ];
  const inputRules: Rule[] = customRules?.length
    ? [...requiredRule, ...customRules]
    : [
        {
          required,
          message: `Required`
        }
      ];
  const textRules: Rule[] = customRules?.length
    ? [...requiredRule, ...customRules]
    : [
        {
          required,
          message: `Required`,
          validator: validateText
        }
      ];
  const inputRulesEmail: Rule[] = [
    {
      required,
      message: `Required`,
      validator: validateEmail
    }
  ];

  const NumberOnlyInput = (
    <Form.Item
      name={name}
      rules={inputRules}
      validateStatus={validating ? 'validating' : validateStatus}
      help={false}>
      {isCommaSeperated?
        <InputNumber
        onKeyPress={(e) => {
          const keyCode = e.which || e.keyCode;
          const keyValue = String.fromCharCode(keyCode);
          const regex = /^[0-9.]$/; // Regex to match numbers and dot (.)
          if (!regex.test(keyValue)) {
            e.preventDefault();
          }
        }}
        controls={false}
        name={name}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
        disabled={nonEditable || disabled === true}
        className={`${styleOne} ${getValidateStatusClass()}`}
        placeholder={placeholder}
        onChange={onChange}
      />
       :<Input
       onKeyPress={(e) => {
         const keyCode = e.which || e.keyCode;
         const keyValue = String.fromCharCode(keyCode);
         const regex = /^[0-9.]$/; // Regex to match numbers and dot (.)
         if (!regex.test(keyValue)) {
           e.preventDefault();
         }
       }}
       type={type}
       name={name}
       onChange={onChange}
       defaultValue={defaultValue}
       disabled={nonEditable || disabled === true}
       placeholder={placeholder}
       className={`${styleOne} ${getValidateStatusClass()}`}
       onBlur={onBlurHandler}
     />
      }
    </Form.Item>
  );

  const RegularInput = (
    <Form.Item
      name={name}
      rules={isEmailValidated ? inputRulesEmail : textRules}
      hasFeedback={validating || validateStatus ? true : undefined}
      validateStatus={validating ? 'validating' : validateStatus}
      help={false}>
      <Input
        type={type}
        disabled={nonEditable || loading || disabled === true}
        placeholder={loading ? 'fetching data...' : placeholder}
        className={`${styleOne} ${getValidateStatusClass()}`}
        onChange={onChange}
        onBlur={onBlurHandler}
        defaultValue={defaultValue}
      />
    </Form.Item>
  );

  return (
    <div className={`custom-input-wrapper${hidden ? ' !hidden' : ''}`}>
      <span className='custom-input-label'>
        {label}{' '}
        {required && !hiddenRequiredLabel && <span className=' text-lg text-red-500'>*</span>}
      </span>
      {isNumberValidated ? NumberOnlyInput : RegularInput}
      {nonEditable && !subtitle && (
        <div className='text-[0.72rem] flex gap-2 items-center p-[0.2rem] mb-[-0.5rem]'>
          <AiOutlineInfoCircle className='!mt-[0.08rem] text-[0.9rem] ' />
          {nonEditableText ? nonEditableText : 'you cannot edit this'}
        </div>
      )}
      {/* {validating && (
        <div className='absolute -bottom-5 right-0'>
          <img
            src='/assets/dashboard/moving-dots.svg'
            alt='...'
          />
        </div>
      )} */}
      {subtitle && (
        <div
          onClick={subtitleOnClick}
          className={`text-[0.72rem] items-center justify-start  ${
            subtitleOnClick && 'cursor-pointer'
          }  flex gap-2 items-center p-[0.2rem] mb-[-0.5rem] ${className && className}`}>
          {!noSubIcon && !subtitleInvert && (
            <Tooltip
              placement={subtitleTooltipPlacement || 'top'}
              title={subtitleTooltipText}>
              <AiOutlineInfoCircle className='!mt-[0.08rem]  text-[0.9rem]' />
            </Tooltip>
          )}
          {subtitle}
          {!noSubIcon && subtitleInvert && (
            <Tooltip
              placement={subtitleTooltipPlacement || 'top'}
              title={subtitleTooltipText}>
              <AiOutlineInfoCircle className='!mt-[0.08rem]  text-[0.9rem]' />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default TextInput;
