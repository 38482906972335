import {
	AUTH_ROUTES,
	DASHBOARD_ROUTES,
	LANDING_ROUTES,
} from "utils/appData/appRoutes";
import {
	qwidLogo,
	useToolkit,
	QwidButton,
	OptionalRender,
} from "../../components";

type Props = {
	hasDashboardButton?: boolean;
	isTieredOnboarding?: boolean;
	hideAuthNavButton?: boolean;
};

const AuthNav = ({ hasDashboardButton, isTieredOnboarding, hideAuthNavButton }: Props) => {
	const { Image, router, user, logout } = useToolkit();
	const isSignInRoute = router.pathname === AUTH_ROUTES.SIGN_IN;
	const isVerifyRoute = router.pathname === AUTH_ROUTES.VERIFY_EMAIL;
	const isOnboardingRoute = router.pathname.includes('personal') || router.pathname.includes('business')


	return (
		<nav
			style={{ boxShadow: "0px 4.02325px 4.02325px rgba(229, 229, 229, 0.25)" }}
			className="side-pad h-[70px] flex-btw sticky top-0 left-0 transition-all z-[200] backdrop-blur-xl bwite border border-[#eeeeee]">
			<div
				onClick={() => router.push(LANDING_ROUTES.PERSONAL_HOMEPAGE)}
				className="curp mt-1">
				<Image alt="qwid-logo" src={qwidLogo} />
			</div>

			<OptionalRender condition={hasDashboardButton || false}>
				<QwidButton
					plainBordered
					text={<span className="px-4">Back to dashboard</span>}
					onClick={() => router.push(DASHBOARD_ROUTES.DASHBOARD)}
				/>
			</OptionalRender>

			<OptionalRender condition={!hideAuthNavButton && !isOnboardingRoute && !isTieredOnboarding}>

				<div className="flex items-center flex-row space-x-2">
					<p className="mb-0 text-qwid-dark-100 hidden md:inline text-sm">
						{isSignInRoute ? "Don't" : 'Already'} have an account?
					</p>

					<QwidButton
						text={<span className="px-2 !py-0">Sign {isSignInRoute ? 'up' : 'in'}</span>}
						onClick={() => router.push(isSignInRoute ? AUTH_ROUTES.SIGN_UP : AUTH_ROUTES.SIGN_IN)}
					/>
				</div>
			</OptionalRender>

		</nav>
	);
};

export default AuthNav;
