import {
	CenterModal,
	EpayCancellationModal,
	QwidButton,
	useDashboard,
	useToolkit,
} from "components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
	resetEpayParams,
	toggleEpayCancellation,
} from "redux/slices/dashboardSlice";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";

type Props = {
	toggleEpay: () => void;
};

const EpayModal = ({ toggleEpay }: Props) => {
	const { useAppSelector, isMobile, useEffect, router, dispatch } =
		useToolkit();
	const { useConfirmEpay, isConfirming } = useDashboard();
	const { mutate: confirmEpay } = useConfirmEpay();

	const {
		isEpayModalOpen,
		epayTransDetails,
		epayTransComplete,
		epayFailed,
		epaySuccessful,
	} = useAppSelector((state) => state.dashboard);

	const transId = epayTransDetails?.transaction?.transId;
	const returnHome = () => {
		toggleEpay();
		router.push(DASHBOARD_ROUTES.DASHBOARD);
	};

	useEffect(() => {
		if (isEpayModalOpen && transId && epayTransComplete === false) {
			const getItems = setInterval(() => confirmEpay(), 45000);
			return () => clearInterval(getItems);
		}
	}, [transId, epayTransComplete]);

	useEffect(() => {
		dispatch(resetEpayParams());
	}, []);

	const SuccessUI = () => {
		return (
			<div className="w-max mx-auto flexed flex-col pt-10">
				<img
					src="/assets/icons/success.svg"
					alt="success"
					width={isMobile ? 250 : 500}
				/>
				<p className="my-7 lg:text-lg">Your transaction was successful</p>
				<QwidButton text="Return to Dashboard" onClick={returnHome} />
			</div>
		);
	};

	const FailedUI = () => {
		return (
			<div className="w-max mx-auto flexed flex-col pt-10">
				<img
					src="/assets/icons/failed.svg"
					alt="failed"
					width={isMobile ? 250 : 500}
				/>
				<p className="my-7 lg:text-lg">
					Your transaction could not be confirmed
				</p>
				<QwidButton text="Return to Dashboard" onClick={returnHome} />
			</div>
		);
	};

	return (
		<CenterModal
			isCustom
			className="w-full min-h-screen lgs:h-[600px]"
			control={isEpayModalOpen}
			onClose={() => {}}>
			{epaySuccessful ? (
				<SuccessUI />
			) : epayFailed ? (
				<FailedUI />
			) : (
				<div className="p-5">
					<div className="mb-5 flex-btw">
						<p className="mb-0 font-semibold twide lg:text-lg">Select Bank</p>
						<div className="flect curp" onClick={toggleEpay}>
							<h5
								onClick={(e: any) => {
									e.stopPropagation();
									dispatch(toggleEpayCancellation());
								}}
								className="mb-0">
								Cancel
							</h5>
							<AiOutlineCloseCircle className="ml-2 text-lg" />
						</div>
					</div>

					<iframe
						style={{ width: "100%", height: isMobile ? "400px" : "700px" }}
						src={epayTransDetails?.paymentLink}></iframe>
				</div>
			)}
			<EpayCancellationModal />
		</CenterModal>
	);
};

export default EpayModal;
