import React from 'react'

const Heading = ({title, subtitle}:{title?: string; subtitle?: string | JSX.Element}) => {
  return (
    <div className='flex flex-col gap-0'>
        <h1 className='font-semibold text-[1.2rem]'>{title}</h1>
        <div className='text-[0.9rem] text-[#667085]'>{subtitle}</div>
    </div>
  )
}

export default Heading