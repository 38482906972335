import QwidButton from 'components/misc/QwidButton';
import Spinner from 'components/misc/Spinner';
import Heading from 'components/settings/helperComponents/Heading';
import useSettingsQueries, {
  AuthTypes,
  AuthenticatePayload,
} from 'components/settings/queries/settingsQueries';
import AuthLayout from 'layouts/AuthLayout';
import React, { useEffect, useMemo, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import OTPInput from 'react-otp-input';
import { useMutation } from 'react-query';
import { setMollusca } from 'redux/slices/userSlice';
import { DASHBOARD_ROUTES } from 'utils/appData/appRoutes';
import useToolkit from 'utils/hooks/misc/useToolkit';

export enum AuthStates {
    HAS2FA = 'has2fa',
    NO2FA = 'no2fa',
    VERIFIED2FA = 'verified2fa'
}

const TwoFactorAuth = () => {
  const [timer, setTimer] = useState(20);
  const { toastSuccess, axiosInstance, toastError, encryptData, router, dispatch } = useToolkit();
  const { useResendEmailCode, useShowUserAuthenticator } = useSettingsQueries();
  const { isRefetching: sendingEmail, refetch: sendEmail } = useResendEmailCode();
  const [userPin, setUserPin] = useState('');
  const [show, setShow] = useState<boolean>(false);
  var emailSendCount = 0;
  const { data: userAuthData, isLoading: userAuthLoading } =
    useShowUserAuthenticator();
    const {mutateAsync: confirmAuth, isLoading: confirmingAuth} =  useMutation({
          mutationKey: ['activateAuthenticator'],
          mutationFn: async(payload: AuthenticatePayload) => {
            const response = await axiosInstance.post('authenticator/confirm', payload)
            return response.data
          },
          onError: (error: Record<string, any>) => {
            toastError(error?.response?.data?.message);
          },
          onSuccess:()=> toastSuccess('Authenticated')
        });
  const userAuth: Record<string, any>[] = useMemo(() => {
    if (userAuthData?.content) {
      return userAuthData?.content?.data;
    }
  }, [userAuthData]);
  const twoFaAvailable = useMemo(() => {
    return userAuth?.find((item) => item.type === AuthTypes.TWO_FA)?.status === 1;;
  }, [userAuth]);
  const emailAvailable = useMemo(() => {
    return userAuth?.find((item) => item.type === AuthTypes.EMAIL)?.status === 1;
  }, [userAuth]);
  const handleSetPin = (value: string) => {
    setUserPin(value);
  };
  const [mode, setMode] = useState<AuthTypes | undefined>(emailAvailable ? AuthTypes.EMAIL : AuthTypes.TWO_FA);
  const initiateForEmail = async()=>{
    if(mode === AuthTypes.EMAIL && emailAvailable){
        await sendEmail()
        emailSendCount = 1
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
          }, 1000);
      
          return () => clearInterval(intervalId);
    }
  }
  useEffect(() => {
    if(emailSendCount === 0){
        initiateForEmail()
    }
  }, [mode]);

  useEffect(()=>{
    setMode(emailAvailable ? AuthTypes.EMAIL : AuthTypes.TWO_FA)
  },[userAuthData])
  const hasOtherOption = twoFaAvailable && emailAvailable;
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const handleResendCode = async () => {
    await sendEmail();
    toastSuccess('code has been resent');
  };
  const isEmail = mode === AuthTypes.EMAIL;
  const handleSwitchMode = () => {
    setMode((p) => {
      if (p === AuthTypes.EMAIL) {
        return AuthTypes.TWO_FA;
      } else return AuthTypes.EMAIL;
    });
    setUserPin("")
  };
  const handleSubmit = async ()=>{
    const payload: AuthenticatePayload = {
        type: mode as AuthTypes,
        code: userPin
    }
    await confirmAuth(payload)
    //store confirmation in redux or local storage
    //localStorage.setItem('mollusca', encryptData(AuthStates.VERIFIED2FA))
    dispatch(setMollusca(encryptData(AuthStates.VERIFIED2FA)))
    
    router.push(DASHBOARD_ROUTES.DASHBOARD)
  }
  return userAuthLoading? (<div className='w-screen h-screen my-auto flex flex-col justify-center'>
    <Spinner />
  </div>) : (
    <AuthLayout title="">
      <div
        style={{ boxShadow: '8px 4.02325px 50px rgba(0, 0, 0, 0.05' }}
        className="bwite w-full md:w-4/5 lg:w-9/12 mx-auto p-7 flex max-w-[32rem] flex-col gap-12 rounded-lg border border-[#EEEEEE]"
      >
        <Heading
          title="Kindly enter your verification code"
          subtitle={`To log in, kindly enter the verification code ${
            isEmail
              ? 'sent to your email address'
              : 'from your authenticator app'
          }`}
        />
        <div className="w-full max-w-[22rem] mx-auto">
          <OTPInput
            value={userPin}
            onChange={handleSetPin}
            inputStyle="otp-field"
            numInputs={6}
            //inputType="number"
            shouldAutoFocus
            containerStyle="otpinput-container"
            renderInput={(props) => (
              <input
                {...props}
                type={show ? 'tel' : 'password'}
                className="border-[1px] text-[1.5rem] !h-[4rem] !w-full !max-w-[15%] outline-none  focus:!border-[#48BBED]  rounded-[20px]"
              />
            )}
          />
          <div className='w-[100%] mb-[-1.5rem] mt-[-1rem] flex justify-end cursor-pointer'>
            <div
              className='text-[1.2rem] text-gray-500'
              onClick={() => setShow((prev) => !prev)}>
              {show ? <AiFillEye /> : <AiFillEyeInvisible />}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <QwidButton loading={confirmingAuth} onClick={handleSubmit} type='submit' isFull text={'Login'} />
          {isEmail && (
            <div className="w-full px-2 py-2 md:px-2 flexed nowrap rounded-[5px]  med text-[12px] md:t4 tracking-wide active:scale-90 transition-all duration-150 border-[1px] border-[#EDEDED] ">
              {timer > 0 ? (
                <div className="bg-transparent font-medium text-black w-auto   flex items-center gap-1 rounded-[0.625rem] px-2 py-1 ">
                  Resend code: &nbsp;(
                  {minutes.toString().padStart(2, '0')}:
                  {seconds.toString().padStart(2, '0')})
                </div>
              ) : (
                <div
                  className="font-medium whitespace-nowrap   cursor-pointer py-1  text-blue-dark px-4"
                  role="button"
                  onClick={handleResendCode}
                >
                  { sendingEmail ? 'Sending...' : 'Resend Code'}
                </div>
              )}
            </div>
          )}
          {hasOtherOption && (
            <div
              onClick={handleSwitchMode}
              className="text-[0.8rem] mx-auto mt-6"
            >
              Don’t have access to{' '}
              <span>{isEmail ? 'email' : 'Authenticator app'}</span>?{' '}
              <span className="font-semibold cursor-pointer text-dark-blue">
                {isEmail ? 'Use auth App' : 'use Email'}
              </span>
            </div>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default TwoFactorAuth;
