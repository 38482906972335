import { QwidButton, useToolkit, Spinner, CenterModal } from 'components';
import { togglePinAuth } from 'redux/slices/dashboardSlice';
import OtpInput from 'react-otp-input';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { setInternalTransferDetails } from 'redux/slices/transactionsSlice';

interface PinModalProps {
  continueFxn?: (pin: string) => void;
  loading?: boolean;
  loadingMessage?: string | JSX.Element;
}

const TrxpinModal = ({ continueFxn, loading, loadingMessage }: PinModalProps) => {
  const { dispatch, useAppSelector, useState } = useToolkit();
  const [userPin, setUserPin] = useState('');
  const [show, setShow] = useState<boolean>(false);

  const handleSetPin = (value: string) => {
	setUserPin(value);
      dispatch(
        setInternalTransferDetails({
          pin: value
        })
      );
  };

  const { isPinAuthOpen, remittanceDetails } = useAppSelector((state) => state.dashboard);
  const closeModal = () => {
    setUserPin('');
    dispatch(togglePinAuth());
  };

  const handleContinue = async () => {
    continueFxn && (await continueFxn(userPin));
  };

  return (
    <CenterModal
      isCustom
      className='w-full md:w-2/4 p-10 lg:w-[45%] max-w-[36rem] md:mx-auto'
      control={isPinAuthOpen}
      onClose={closeModal}>
      {loading ? (
        <Spinner msg={loadingMessage || 'Processing Transaction...'} />
      ) : (
        <div className=' m-auto flex flex-col w-full max-w-[19rem]'>
          <img
            width={100}
            src='/assets/dashboard/padlock.svg'
            alt='lock'
          />
          <h5 className='sm:mt-[2rem] mt-4 mb-4 sm:mb-[2rem]'>
            Please enter your transaction PIN to authorize payment
          </h5>
          <OtpInput
            value={userPin}
            onChange={handleSetPin}
            inputStyle='otp-field'
            numInputs={4}
			inputType='number'
            shouldAutoFocus
            containerStyle='otpinput-container'
            renderInput={(props) => (
              <input
                {...props}
                type={show ? 'tel' : 'password'}
                className='border-[1px] text-[1.5rem] !h-[4.8rem] !w-[3.5rem] outline-none  focus:!border-[#48BBED]  rounded-[2rem]'
              />
            )}
          />
          <div className='w-[100%] flex justify-end cursor-pointer'>
            <div
              className='text-[1.6rem] text-gray-500'
              onClick={() => setShow((prev) => !prev)}>
              {show ? <AiFillEye /> : <AiFillEyeInvisible />}
            </div>
          </div>
          <div className='m-auto mt-8'>
            <QwidButton
              disabled={userPin.length < 4}
              darkBlueBg
              text={'Authorize payment'}
              onClick={handleContinue}
            />
          </div>
        </div>
      )}
    </CenterModal>
  );
};

export default TrxpinModal;
