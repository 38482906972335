import {
	CustomInputField,
	CustomSelector,
	useSendFunds,
	useToolkit,
	NonNgnBenefForm,
	useBeneficiary,
	Spinner,
	useLocation,
} from "../../components";
import { Form, Modal } from "antd";
import { accTypes, benefTypes } from "utils/appData/dashboardMisc";
import { toggleBenefModal } from "redux/slices/dashboardSlice";

type Props = {};

const AddBeneficiaryInline = ({}: Props) => {
	const { useState, useEffect, useRef, isMobile, useAppSelector, dispatch } =
		useToolkit();
	const { isBenefModalOpen } = useAppSelector((state) => state.dashboard);

	const [benefType, setBenefType] = useState("");
	const [rcvCountry, setRcvCountry] = useState("");
	const isBusinessType = benefType === "2";
	const [rcvCurrency, setRcvCurrency] = useState("");
	const [formDetails, setFormDetails] = useState<any>({
		beneficiaryType: isBusinessType ? 2 : 1,
		beneficiaryName: "",
		bankName: "",
		iban: "",
		IDNumber: "",
		beneficiaryCity: "",
	});
	const [stateInput, setStateInput] = useState(false);

	// beneficiary legal details
	const [benefCountry, setBenefCountry] = useState("");
	const [benefState, setBenefState] = useState("");
	const [benefCity, setBenefCity] = useState("");

	// for dynamic state / city
	const {
		ctrLoading,
		ctrData,
		getDynCountries,
		statesLoading,
		stateData,
		getDynStates,
		getDynCities,
		citiesData,
		citiesLoading,
	} = useLocation();

	const isNigerianBenef = rcvCountry === "NG";
	const isUKBenef =
		(rcvCurrency?.length && rcvCurrency === "GBP") ||
		(rcvCurrency?.length && rcvCurrency === "EUR");
	const isNigUS = rcvCountry === "NG" && rcvCurrency === "USD";
	const isUSDRcvCur = rcvCurrency === "USD";
	const isRussian = rcvCountry === "RU";
	const isArgent = rcvCountry === "AR";
	const isUSDCAD = rcvCountry === "US" || rcvCountry === "CA";
	const isNotNigerianBenef = rcvCountry?.length && rcvCountry !== "NG";

	const {
		countriesLoading,
		countriesData,
		getAllRecipientCountries,
		getCountryCurrencies,
		curLoading,
		curData,
	} = useSendFunds();
	const { banksLoading, getNgnBanks, banksData, addLoading, addBeneficiary } =
		useBeneficiary();

	const updateTextField = (e: any, fieldName: string) => {
		const value = e?.target?.value;

		setFormDetails({
			...formDetails,
			fieldName: value,
		});
	};

	const updateAdditionalDetails = (e: any) => {
		const value = e.value || e.target.value;
		setFormDetails({
			...formDetails,
			[e.target.name]: value,
		});
	};

	const onSubmit = (values: any) => {
		const {
			beneficiaryName,
			email,
			country: { value: countryValue },
			cur,
			mobile,
			city,
			accountType,
			correspondentAccountNumber,
			correspondentBankCode,
			correspondentBankName,
			correspondentRoutine,
		} = values;
		const { beneficiaryType, bankName } = formDetails;

		const payload = {
			beneficiaryType: Number(benefType),
			bankName: bankName,
			bankCode: isNigerianBenef
				? values.bank_name?.value
				: formDetails?.bankCode,
			accountName: beneficiaryName,
			accountNo: formDetails?.accountNo || values?.account_number,
			address: formDetails?.address,
			state: stateInput ? formDetails?.state : benefState,
			city: formDetails?.city,
			swift: formDetails?.swift,
			iban: formDetails?.iban,
			bankSortCode: formDetails?.bankSortCode,
			bankRoutingNo: formDetails?.bankRoutingNo,
			zipcode: formDetails?.zipcode,
			bankState: formDetails?.bankState,
			bankCity: formDetails?.bankCity,
			bankZipcode: formDetails?.bankZipcode,
			bankAddress: formDetails?.bankAddress,
			beneficiaryCountry: benefCountry,
			cur: rcvCurrency,
			country: countryValue,
			mobile,
			cpf: values?.cpf || "",
			accountType,
			beneficiaryEmail: email || "",
			IDNumber: formDetails?.IDNumber || "",
			correspondentAccountNumber,
			correspondentBankCode,
			correspondentBankName,
			correspondentRoutine,
		};
		addBeneficiary(payload);
	};

	const handleCountryChange = (selectedOption: any) => {
		setRcvCountry(selectedOption?.value);
	};
	const submitRef = useRef<HTMLButtonElement>(null);

	const handleCurrencyChange = (e: any) => {
		const val = e.target.value;
		setRcvCurrency(val);
	};

	useEffect(() => {
		getAllRecipientCountries();
		getNgnBanks();
		getDynCountries();
	}, []);

	useEffect(() => {
		getDynStates(
			isNigerianBenef || !benefCountry?.length ? "NG" : benefCountry
		);
	}, [benefCountry]);

	useEffect(() => {
		const payload = {
			country: benefCountry || "NG",
			stateId: benefState,
		};
		if (benefState?.length) {
			// getDynCities(payload);
		}
	}, [benefState]);

	useEffect(() => {
		getCountryCurrencies(rcvCountry);
	}, [rcvCountry]);

	useEffect(() => {
		if (benefCountry?.length && stateData?.length < 1) {
			setStateInput(true);
		} else {
			setStateInput(false);
		}
	}, [stateData]);

	return (
		<Modal
			title="Add New Beneficiary"
			centered
			visible={isBenefModalOpen}
			onOk={() => submitRef?.current?.click()}
			onCancel={() => dispatch(toggleBenefModal())}
			okText={<span className="px-2">Add</span>}
			cancelText="Cancel"
			closeIcon={<div></div>}
			className="my-10"
			width={isMobile ? "100%" : "80%"}>
			{addLoading ? (
				<Spinner msg="Processing" />
			) : (
				<Form
					onFinish={onSubmit}
					autoComplete="off"
					className="space-y-5 my-7 w-full">
					<div className="form-wrap">
						<div className="form-child">
							<CustomSelector
								itemLabel="Beneficiary Type"
								firstOption="-- Select --"
								itemName="beneficiaryType"
								options={benefTypes}
								onChange={(e) => setBenefType(e?.target?.value)}
							/>
						</div>
						<div style={{ zIndex: 50 }} className="form-child">
							<CustomSelector
								itemName="country"
								customName="country"
								customLabel="Beneficiary Bank Country"
								noArrow
								isSearchable
								options={countriesData}
								onCustomChange={handleCountryChange}
								placeholder={
									countriesLoading ? "Fetching countries..." : "Select Country"
								}
							/>
						</div>
					</div>
					<div className="form-wrap">
						<div className="form-child3">
							<CustomInputField
								name="beneficiaryName"
								label="Beneficiary Name"
								placeholder="Enter name"
							/>
						</div>
						<div className="form-child3">
							{isBusinessType && (
								<CustomInputField
									required={isBusinessType}
									onValChange={updateAdditionalDetails}
									name="email"
									label="Beneficiary Email"
									placeholder="Enter email"
								/>
							)}
						</div>
						<div className="form-child3">
							<CustomSelector
								isMapped
								itemName="accountType"
								itemLabel="Account type"
								options={accTypes}
								firstOption="-- Select --"
							/>
						</div>
					</div>
					<div style={{ marginTop: "30px", zIndex: 50 }} className="form-wrap">
						<div className="form-child">
							<CustomSelector
								itemName="cur"
								itemLabel="Receiving Currency"
								options={curData}
								onChange={handleCurrencyChange}
								firstOption={
									!rcvCountry?.length
										? "Select a bank country first"
										: curLoading
										? "Fetching currencies..."
										: "Select Currency"
								}
							/>
						</div>
						<div className="form-child">
							<CustomInputField
								name="mobile"
								label="Mobile Number"
								placeholder="Enter Number"
							/>
						</div>
					</div>
					<div className="w-full">
						{isNigerianBenef ? (
							<>
								<p className="med lg:t6 mb-3">Bank Details</p>
								<div className="form-wrap">
									<div style={{ zIndex: 200 }} className="form-child">
										<CustomSelector
											customName="bank_name"
											customLabel="Bank name"
											noArrow
											isSearchable
											options={banksData}
											onCustomChange={(e) =>
												setFormDetails({
													...formDetails,
													bankName: e.value,
												})
											}
											placeholder={
												banksLoading ? "Fetching banks..." : "Select Bank"
											}
										/>
									</div>
									<div className="form-child">
										<CustomInputField
											required={isNigerianBenef}
											name="account_number"
											label="Account Number"
											placeholder="Enter number"
										/>
									</div>
								</div>
							</>
						) : isNotNigerianBenef ? (
							<>
								<p className="med lg:t6">Bank Details</p>
								<NonNgnBenefForm
									rcvCur={rcvCurrency}
									isUSDRcvCur={isUSDRcvCur}
									isUKBenef={isUKBenef}
									isNotNigerianBenef={isNotNigerianBenef}
									isRussian={isRussian}
									isArgent={isArgent}
									isUSDCAD={isUSDCAD}
									updateAdditionalDetails={updateAdditionalDetails}
								/>
							</>
						) : (
							""
						)}
					</div>
					{isNigUS ? (
						<div className="flexed flex-col w-full space-y-4">
							<div className="form-wrap w-full">
								<div className="form-child">
									<CustomInputField
										name="correspondentBankName"
										label="Correspondent Bank Name"
										placeholder="Enter name"
									/>
								</div>
								<div className="form-child">
									<CustomInputField
										name="correspondentBankCode"
										label="Correspondent Bank Code"
										placeholder="Enter code"
									/>
								</div>
							</div>
							<div className="form-wrap w-full">
								<div className="form-child">
									<CustomInputField
										name="correspondentAccountNumber"
										label="Correspondent Account Number"
										placeholder="Enter number"
									/>
								</div>
								<div className="form-child">
									<CustomInputField
										name="correspondentRoutine"
										label="Correspondent Routing Number"
										placeholder="Enter number"
									/>
								</div>
							</div>
						</div>
					) : null}
					<div className="flex items-start justify-center flex-wrap lg:flex-nowrap space-y-6 md:space-y-0 lg:space-x-16">
						<div className="w-full lg:w-2/4">
							<div className="w-full">
								<p className="med lg:t6 mb-5">Beneficiary Address Details</p>

								<div
									style={{ zIndex: "30 !important" }}
									className="w-full mb-4">
									<CustomSelector
										itemName="beneficiaryCountry"
										customName="beneficiaryCountry"
										customLabel="Country"
										noArrow
										isSearchable
										options={ctrData}
										onCustomChange={(e) => setBenefCountry(e?.value)}
										placeholder={
											ctrLoading ? "Fetching countries..." : "Select Country"
										}
									/>
								</div>

								<div className="form-wrap">
									<div className="form-child">
										{stateInput ? (
											<CustomInputField
												isOptional
												required={stateInput}
												onValChange={updateAdditionalDetails}
												label="Province / State"
												name="state"
												placeholder={
													statesLoading ? "Fetching states..." : "Enter State"
												}
											/>
										) : (
											<CustomSelector
												required={!stateInput}
												customName="beneficiaryState"
												customLabel="Province / State"
												isAnt
												options={stateData}
												onCustomChange={(e) => setBenefState(e)}
												placeholder={
													statesLoading ? "Fetching states..." : "Select State"
												}
											/>
										)}
									</div>

									<div className="form-child">
										<CustomInputField
											isOptional
											required
											onValChange={updateAdditionalDetails}
											label="City"
											name="city"
											placeholder="Enter city"
										/>
									</div>
								</div>
							</div>

							<div className="form-wrap mt-5">
								<div className="form-child">
									<CustomInputField
										isOptional
										required
										onValChange={updateAdditionalDetails}
										name="address"
										label="Address"
										placeholder="Enter address"
									/>
								</div>

								<div className="form-child">
									<CustomInputField
										isOptional
										required
										onValChange={updateAdditionalDetails}
										name="zipcode"
										label="Zip / Postal Code"
										placeholder="Enter code"
									/>
								</div>
							</div>
						</div>

						<div className="w-full lg:w-2/4">
							<p className="med lg:t6 mb-5">Bank Address Details</p>
							<div className="form-wrap mb-5">
								<div className="form-child pt-2.5">
									<CustomInputField
										isOptional
										required
										onValChange={updateAdditionalDetails}
										name="bankState"
										label="Province / State"
										placeholder="Enter state"
									/>
								</div>

								<div className="form-child pt-2.5">
									<CustomInputField
										isOptional
										required
										onValChange={updateAdditionalDetails}
										name="bankCity"
										label="City"
										placeholder="Enter city"
									/>
								</div>
							</div>
							<div className="form-wrap">
								<div className="form-child">
									<CustomInputField
										isOptional
										required
										onValChange={updateAdditionalDetails}
										name="bankAddress"
										label="Address"
										placeholder="Enter address"
									/>
								</div>

								<div className="form-child">
									<CustomInputField
										isOptional
										required
										onValChange={updateAdditionalDetails}
										name="bankZipcode"
										label="Zip / Postal Code"
										placeholder="Enter code"
									/>
								</div>
							</div>
						</div>
					</div>
					<button ref={submitRef} type="submit" className="hidden">
						Submit
					</button>
				</Form>
			)}
		</Modal>
	);
};

export default AddBeneficiaryInline;
