import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum RecipientTypes {
  QWID = "Qwid Recipients",
  EXTERNAL = "External Recipients",
  YOUR_ACCOUNT = "Your Account",
}

type recipientState = {
  recipientType: RecipientTypes;
  searchType: RecipientTypes;
  focused: boolean;
} & {
  [key in
    | RecipientTypes.QWID
    | RecipientTypes.EXTERNAL
    | RecipientTypes.YOUR_ACCOUNT]: string;
};

const initialState: recipientState = {
  recipientType: RecipientTypes.QWID,
  searchType: RecipientTypes.QWID,
  focused: false,
  [RecipientTypes.QWID]: "",
  [RecipientTypes.EXTERNAL]: "",
  [RecipientTypes.YOUR_ACCOUNT]: "",
};

const recipientsSlice = createSlice({
  name: "recipientsSlice",
  initialState,
  reducers: {
    setRecipientType: (state, { payload }: PayloadAction<RecipientTypes>) => {
      state.recipientType = payload;
    },

    setSearchType: (state, { payload }: PayloadAction<RecipientTypes>) => {
      state.searchType = payload;
    },

    setFocused: (state, { payload }) => {
      state.focused = payload;
    },

    setSearchInputValue: (
      state,
      {
        payload: { value, key },
      }: PayloadAction<{ key: RecipientTypes; value: string }>
    ) => {
      state[key] = value;
    },
  },
});

export const {
  setRecipientType,
  setSearchType,
  setFocused,
  setSearchInputValue,
} = recipientsSlice.actions;

export default recipientsSlice.reducer;
