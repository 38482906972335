import OptionalRender from 'components/misc/OptionalRender';
import React, { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { RiArrowDownSLine, RiSearchLine } from 'react-icons/ri';
export enum getFlag {
  VALUE = 'value',
  TITLE = 'title',
  ID = 'Id',
  LABEL = 'label'
}
interface SpecialDropdownProps {
  value?: any;
  valueSetter: SetStateAction<any>;
  options: DropdownProps[];
  initialValue?: any;
  onChange?: (value: any) => void;
  isFlagged?: boolean;
  squaredFlags?: boolean;
  getFlagBy?: getFlag;
  displayISOOnly?: boolean;
  topCurr?: string[];
}
const SpecialDropdown = ({
  valueSetter,
  options,
  initialValue,
  onChange,
  isFlagged,
  squaredFlags,
  getFlagBy,
  displayISOOnly,
  topCurr
}: SpecialDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [filter, setFilter] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedValue, setSelectedValue] = useState('');
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
      valueSetter(initialValue);
    }
  }, [initialValue]);
  function focus() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }
  const handleDropdownClick = () => {
    setShowDropdown((prev) => !prev);
    setFilter('');
    // Focus the input when the dropdown is clicked
  };
  useEffect(() => {
    focus();
  }, [showDropdown]);
  const filteredMenu = useMemo(() => {
    return options.filter((items) =>
      items.title?.toString().toLowerCase().includes(filter.toLowerCase())
    );
  }, [filter, options]);
  const topOptions = useMemo(() => {
    return filteredMenu.filter((item) => topCurr?.includes(item.value as string));
  }, [filteredMenu]);
  const otherOptions = useMemo(() => {
    return filteredMenu.filter((item) => !topCurr?.includes(item.value as string));
  }, [filteredMenu]);

  const selectedLabel = useMemo(() => {
    const label = options.find((item) => item.value === selectedValue)?.title;
    return label;
  }, [selectedValue, options]);
  const selectedISO = useMemo(() => {
    const iso = options.find((item) => item.value === selectedValue);

    return iso
      ? getFlagBy
        ? iso.value?.toString().slice(0, 2)
        : iso.value?.toString().slice(0, 2)
      : '';
  }, [selectedValue, options]);

  /* inside div
         both displayed will be a function of filtered menu but i would need to put them in optional renders
         if there is no top then just render filtered options  */
  const getISO = (option: DropdownProps) => {
    if (getFlagBy) {
      switch (getFlagBy?.toLowerCase()) {
        case 'title':
          return option.title as string;
        case 'id':
          return option.Id as unknown as string;
        case 'value':
          return option.value as string;
        case 'label':
          return option.label as string;
        default:
          return option.Id as unknown as string;
      }
    } else return option.Id as unknown as string;
  };
  return (
    <div
      ref={dropdownRef}
      className='w-full relative   cursor-pointer p-1 rounded-lg min-w-[3.5rem]'>
      <div
        onClick={handleDropdownClick}
        className='flex w-full justify-between items-center'>
        <div
          id='label-dispaly'
          className='max-w-[80%] w-full flex  whitespace-nowrap overflow-hidden'>
          {selectedValue && isFlagged ? (
            <div className='flex items-center gap-2'>
              <span
                className={`fi fi-${selectedISO?.toLowerCase()}  !w-[1.5rem] !h-[1.5rem] ${
                  squaredFlags ? '' : 'fis rounded-[50%]'
                } `}>
                {' '}
              </span>
              {displayISOOnly ? selectedLabel?.toString().slice(0, 3) : selectedLabel}
            </div>
          ) : (
            selectedLabel
          )}
        </div>
        <RiArrowDownSLine className='text-[1.2rem]' />
      </div>
      <OptionalRender condition={showDropdown}>
        <div className='max-w-[18.5rem]  absolute top-[2rem] z-[999] left-[0rem] rounded-xl p-3 w-[80vh] py-3 shadow-lg  bg-white'>
          <div className='rounded-lg flex items-center border-gray-700 gap-3 mb-3 border-[1px] bg-transparent p-[0.6rem] '>
            <RiSearchLine className='!w-[1.2rem] !h-[1.2rem]' />
            <input
              ref={inputRef}
              className='outline-none bg-transparent text-[0.8rem] w-full'
              placeholder='Search currency'
              type='text'
              value={filter}
              onChange={(e: any) => setFilter(e.target.value)}
            />
          </div>
          <div
            id='menu'
            className='flex h-full max-h-[16rem]  overflow-y-auto flex-col w-full'>
            {topOptions.length > 0 && (
              <div className='font-semibold text-gray-500 text-[0.8rem] pb-[0.4rem]'>
                Top currencies
              </div>
            )}
            {topOptions.map((option) => {
              const isSelected = selectedValue === option.value;

              return (
                <div
                  key={option.Id || option.value}
                  onClick={() => {
                    setSelectedValue(option.value as string);
                    valueSetter(option.value);
                    setTimeout(() => setShowDropdown(false), 150);
                    onChange && onChange(option.value);
                  }}
                  className={`p-[0.4rem] ${!isSelected && 'hover:bg-gray-100'} ${
                    isSelected && 'bg-[#368CB2] rounded-[4px] font-semibold text-white'
                  } text-[0.85rem] w-full flex`}>
                  {isFlagged ? (
                    <div className='flex items-center p-1 gap-3'>
                      <span
                        className={`fi fi-${getISO(
                          option
                        ).toLowerCase()}  !w-[1.5rem] !h-[1.5rem] ${
                          squaredFlags ? '' : 'fis rounded-[50%]'
                        } `}></span>
                      {option.title ? option.title : option.label}
                    </div>
                  ) : (
                    option.title
                  )}
                </div>
              );
            })}
            {topCurr && otherOptions.length > 0 && (
              <div className='font-semibold text-gray-500 text-[0.8rem] py-[0.4rem]'>
                Other currencies
              </div>
            )}
            {otherOptions.map((option) => {
              const isSelected = selectedValue === option.value;

              return (
                <div
                  key={option.Id || option.value}
                  onClick={() => {
                    setSelectedValue(option.value as string);
                    valueSetter(option.value);
                    setTimeout(() => setShowDropdown(false), 150);
                    onChange && onChange(option.value);
                  }}
                  className={`p-[0.4rem] ${!isSelected && 'hover:bg-gray-100'} ${
                    isSelected && 'bg-[#368CB2] rounded-[4px] font-semibold text-white'
                  } text-[0.85rem] w-full flex`}>
                  {isFlagged ? (
                    <div className='flex items-center p-1 gap-3'>
                      <span
                        className={`fi fi-${getISO(
                          option
                        ).toLowerCase()}  !w-[1.5rem] !h-[1.5rem] ${
                          squaredFlags ? '' : 'fis rounded-[50%]'
                        } `}></span>
                      {option.title ? option.title : option.label}
                    </div>
                  ) : (
                    option.title
                  )}
                </div>
              );
            })}
            <OptionalRender condition={!topOptions}>
              {filteredMenu.map((option) => {
                const isSelected = selectedValue === option.value;

                return (
                  <div
                    key={option.Id || option.value}
                    onClick={() => {
                      setSelectedValue(option.value as string);
                      valueSetter(option.value);
                      setTimeout(() => setShowDropdown(false), 150);
                      onChange && onChange(option.value);
                    }}
                    className={`p-[0.4rem] ${!isSelected && 'hover:bg-gray-100'} ${
                      isSelected && 'bg-[#368CB2] rounded-[4px] font-semibold text-white'
                    } text-[0.85rem] w-full flex`}>
                    {isFlagged ? (
                      <div className='flex items-center p-1 gap-3'>
                        <span
                          className={`fi fi-${getISO(
                            option
                          ).toLowerCase()}  !w-[1.5rem] !h-[1.5rem] ${
                            squaredFlags ? '' : 'fis rounded-[50%]'
                          } `}></span>
                        {option.title ? option.title : option.label}
                      </div>
                    ) : (
                      option.title
                    )}
                  </div>
                );
              })}
            </OptionalRender>
            {filteredMenu.length === 0 && (
              <div className='w-full text-center py-3 text-gray-400 font-bold'>No Match</div>
            )}
          </div>
        </div>
      </OptionalRender>
    </div>
  );
};
export default SpecialDropdown;
