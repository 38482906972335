import { QwidButton, useToolkit } from "components";
import OtpInput from "react-otp-input";
import useSecurity from "utils/hooks/settings/useSecurity";

type Props = {
	setIs2fa: React.Dispatch<React.SetStateAction<boolean>>;
};
const TwoFa = ({ setIs2fa }: Props) => {
	const { useAppSelector, toastInfo, useEffect, useState } = useToolkit();
	const [otp, setOtp] = useState("");
	const handleChangeCode = (value: string) => {
		setOtp(value);
	};
	const { userVerificationData: googleAuthData, user } = useAppSelector(
		(state: any) => state.user
	);
	const { enableAuthRequest, confirmAuthCode, confirming } = useSecurity();
	useEffect(() => {
		if (otp.length === 6) {
			const payload = {
				googleAuthCode: googleAuthData?.googleAuthDetails?.[0]?.googleAuthCode,
				authCode: otp,
				loginToken: user?.loginSession[0]?.loginToken,
			};
			toastInfo("Verifying");
			confirmAuthCode(payload);
		}
	}, [otp]);
	const handleUpdate = () => {
		setIs2fa(false);
	};
	return (
		<div className="twofa-layout">
			<div className="border-b text-center border-[#929292] h-24 flex justify-center items-center">
				<span className="text-[#0D0D0D] text-2xl font-semibold">
					Set Up Authenticator App
				</span>
			</div>
			<div className="flex pb-20 px-8 flex-col justify-center items-center">
				<p className="text-[#626262] text-sm text-center px-80 my-8 flex justify-center items-center">
					Install Google Authentication app on your phone. Andriod or IOS, and
					scan the QR code below.
				</p>
				<img
					width={200}
					height={200}
					src={googleAuthData?.googleAuthQRDisplay}
					alt="qr image"
				/>
				<div className="mt-8 mb-16">
					<p className="m-0 text-[#626262] text-sm text-center">
						If you cant scan the QR code, enter this code:
					</p>
					<p className="text-[#0D0D0D] font-semibold text-center">
						{googleAuthData?.googleAuthDetails?.[0]?.googleAuthCode}
					</p>
				</div>

				<div className="px-8">
					<p className="text-center m-0 text-[#0D0D0D] text-xl font-semibold">
						Enable Authenticator App
					</p>
					<p className="text-[#626262] text-sm text-center px-80 mb-6 mt-5 flex justify-center items-center">
						Enter the 6 digit code from the app once the scan is completed to
						activate your 2FA.
					</p>
				</div>
				<div className="w-1/4">
					<OtpInput
						renderInput={(props) => (
							<input {...props} className="otp-field-focus otp-field" />
						)}
						value={otp}
						onChange={handleChangeCode}
						inputStyle="otp-field small-field"
						numInputs={6}
						containerStyle="otpinput-container"
						// focusStyle="otp-field-focus "
					/>
				</div>
				<div className="flex gap-16 justify-between items-center">
					<QwidButton
						onClick={handleUpdate}
						disabled={!enableAuthRequest || confirming}
						loading={confirming}
						text={<span className="px-3 lg:t6">Enable</span>}
					/>
				</div>
			</div>
		</div>
	);
};

export default TwoFa;
