import { useFramer } from "../../../../components";
import { motion } from "framer-motion";

type Props = {};

const SeamlessPayments = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();

	return (
		<div className="bwite lg:py-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper">
				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-0 med text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						Open{" "}
						<span className="text-[#48bbed]">
							Accounts In Foreign Currencies
						</span>{" "}
						With qwid
					</p>
					<p className="my-7 text-[14px] leading-8 text-center  lg:t6 md:text-left">
						Why limit yourself to one currency when you can open virtual
						accounts in three major currencies? Unleash the power of qwid and
						set up virtual accounts to make receiving money, even easier.
					</p>
				</motion.div>
				<motion.div
					variants={flashIn}
					className="bg-[#BAC4F8] rounded-lg w-full md:w-2/4 lg:w-2/4 h-[300px] lg:h-[500px] md:h-[400px] flex items-end justify-center relative">
					<div className="w-[98%] -bottom-1.5 mx-auto flexed">
						<img
							alt="how-to-send"
							src="/assets/features/avail-wallets.jpeg"
							className="object-cover  h-[280px] rounded-lg  md:h-[350px] lg:h-[400px]"
						/>
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default SeamlessPayments;
