import { useFramer, useToolkit } from "../../../../components";
import { motion } from "framer-motion";

type Props = {};

const PayAnywhere = (props: Props) => {
	const { zoomWrap, flashIn } = useFramer();
	const { PryText } = useToolkit();

	return (
		<div className="bwite py-10 lg:py-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper">
				<motion.div
					variants={flashIn}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-0 smb text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-5xl lg:leading-normal">
						Pay with your <PryText text="virtual" /> card anywhere
					</p>
					<p className="my-5 text-center lg:t6 md:text-left lg:w-3/4">
						With zero restrictions, maximum freedom, become limitless with
						qwid's unique virtual cards and make payments anywhere on the
						internet.
					</p>
				</motion.div>

				<motion.div
					variants={flashIn}
					className="rounded-lg w-full md:w-2/4 lg:w-2/4 h-[300px] md:h-[400px] lg:h-[500px] flex items-start justify-center relative">
					<div className="w-[98%] -bottom-1.5 mx-auto flexed">
						<img
							alt="how-to-send"
							src="/assets/features/your-virt-cards.png"
							className="object-cover h-[80%] max-h-[350px]  rounded-lg  md:h-[300px] lg:h-[400px]"
						/>
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default PayAnywhere;
