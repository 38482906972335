'use client';
import OptionalRender from 'components/misc/OptionalRender';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toggleNotifications } from 'redux/slices/userSlice';
import useToolkit from 'utils/hooks/misc/useToolkit';
import TransactionsTab from './TransactionsTab';
import UpdatesTab from './UpdatesTab';

type Props = {
	setHasUnread?: (bool: boolean) => void;
	loading: boolean;
	notifications: any;
	setNotifications: Dispatch<SetStateAction<NotificationItem[] | undefined>>;
};

const NotificationsSideModal = ({ loading, notifications = [], setNotifications }: Props) => {
	const { useAppSelector, dispatch } = useToolkit();
	const [activeTab, setActiveTab] = useState('Transactions');
	const { isNotificationsModalOpen } = useAppSelector((state) => state.user);

	const enableScroll = () => document.body.style.overflow = 'auto';
	const disableScroll = () => document.body.style.overflow = 'hidden';
	const unreadCount = notifications?.filter?.((item: any) => item?.status === 0)?.length;

	const tabs = [
		{ title: 'Transactions', count: unreadCount },
		{ title: 'Updates', count: 0, }
	]

	useEffect(() => {
		if (isNotificationsModalOpen) disableScroll();
		else enableScroll();

		return () => {
			enableScroll();
		};
	}, [isNotificationsModalOpen]);

	return (
		<div
			onClick={() => dispatch(toggleNotifications())}
			className={`fixed inset-0 bg-black bg-opacity-[25%] backdrop-blur-[4px] transform 
			${isNotificationsModalOpen ? 'translate-x-0' : 'translate-x-full'}
			transition-transform duration-300 ease-in-out z-50 lg:pr-10 lg:py-3 flex items-center lg:items-start lg:pt-20 lg:justify-end`}>

			<div onClick={(e: any) => e?.stopPropagation()} className="w-full lg:w-[30%] h-3/4 bg-white rounded-2xl shadow-sm overflow-y-scroll scrollbar-hide">

				<div className="w-full bg-white z-20 sticky top-0">

					<div className="font-medium text-qwid-dark-50 text-center p-5 sticky top-0">
						Notifications
					</div>

					<div className="flex items-center justify-between border-b border-gray-100 sticky top-10">
						{tabs.map(({ title, count }: any) => {
							const isActive = activeTab === title;

							return (
								<div
									onClick={(e: any) => {
										e?.stopPropagation();
										setActiveTab(title);
									}}
									key={title} className={`flex items-center cursor-pointer w-1/2 flexed pb-3 
								${isActive && 'relative'}`}>

									<span className={`text-xs transition-all duration-300 
								${isActive ? 'text-qwid-dark-50 font-medium' : 'text-qwid-gray-50'}`}>
										{title}
									</span>

									<span className={`flexed text-[9px] ml-2 w-5 h-5 rounded-full 
								${isActive ? 'bg-qwid-dark-25 text-white font-bold' : 'bg-qwid-gray-700 text-qwid-gray-50 font-medium'}`}>
										{count}
									</span>

									{isActive &&
										<div className={`h-px transition-all duration-500 absolute w-full -bottom-px 
									${isActive ? 'bg-qwid-dark-50 opacity-100' : 'bg-gray-100 opacity-0'}`} />
									}

								</div>
							)
						})}
					</div>

				</div>


				<div className="p-5">

					<OptionalRender condition={activeTab === 'Transactions'}>
						<TransactionsTab
							loading={loading}
							setNotifications={setNotifications}
							notifications={notifications}
						/>
					</OptionalRender>

					<OptionalRender condition={activeTab === 'Updates'}>
						<UpdatesTab />
					</OptionalRender>

				</div>

			</div>
		</div>
	);
};


export default NotificationsSideModal;
