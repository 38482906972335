import React from "react";
import { IoCheckboxOutline } from "react-icons/io5";
import useFramer from "../../../utils/animations/useFramer";
import { motion } from "framer-motion";

type Props = {};

const MilitaryGradeEncryption = (props: Props) => {
	const { zoomWrap, zoomContent } = useFramer();

	const checkOptions: string[] = [
		"99% uptime",
		"Secured encryption",
		"Top priority given to security",
	];

	return (
		<div className="bwite pb-8 lg:pb-16">
			<motion.div
				variants={zoomWrap}
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				className="lp-duplicate-wrapper">
				<motion.div
					variants={zoomContent}
					className="bg-[#1338F842] rounded-lg w-full md:w-2/4 lg:w-2/4 h-[500px] md:h-[400px] flex items-end justify-center">
					<div className="w-3/4 -bottom-1.5 md:bottom-0 mx-auto flexed relative">
						{/* top-right element */}
						<img
							src="/assets/landing/mtgrade-top-right.svg"
							alt="icon"
							className="absolute -top-20 -right-[40px] lg:-top-20 lg:right-[55px]"
							width={140}
						/>

						{/* bottom-left element */}
						<img
							src="/assets/landing/cypurp-btm-left.svg"
							alt="icon"
							className="absolute hidden lg:inline -left-10 lg:bottom-4 lg:left-12"
							width={50}
						/>

						<img
							alt="how-to-send"
							src="/assets/landing/mtgrade-shot.png"
							className="object-cover lg:h-[300px]"
						/>
					</div>
				</motion.div>

				<motion.div
					variants={zoomContent}
					className="w-full md:w-2/4 lg:w-2/5 p-3 lg:pr-6">
					<p className="mb-0 mt-5 lg:mt-0 smb text-center mx-auto relative lg:text-left text-2xl w-full md:text-3xl lg:text-4xl lg:leading-normal">
						Protected With Military-Grade Encryption Protocols{" "}
						<span className="absolute bottom-0 top-12 hidden lg:inline lg:left-[28%] lg:top-[106px]">
							<img
								src="/assets/landing/zigline.png"
								alt="zig-line"
								className="h-2 w-24 lg:h-auto lg:w-auto"
							/>
						</span>
					</p>

					<p className="my-5 text-center lg:t6 lg:text-left">
						Building a payment solution and experience that users can trust
						comes with responsibility and we don't take it lightly. We
						prioritize safety and security of clients' funds by deploying
						military-grade security and data encryption protocols to ensure a
						foolproof platform for digital payments.
					</p>

					<div className="flex flex-col space-y-4">
						{checkOptions.map((item, idx) => (
							<div className="flex items-center space-x-2" key={idx}>
								<IoCheckboxOutline className="text-xl text-[#13035F]" />
								<p className="mb-0 med">{item}</p>
							</div>
						))}
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default MilitaryGradeEncryption;
