import InputTypes from "./InputElements";
import { Form, Select, Tooltip } from "antd";
import { AiFillCaretDown, AiOutlineInfoCircle } from "react-icons/ai";
import { Rule } from "antd/lib/form";
import OptionalRender from "components/misc/OptionalRender";

const { Option } = Select;

const DropdownInput = ({
	label,
	name,
	placeholder,
	options,
	required,
	noErrorVibration,
	type,
	nonEditable,
	nonEditableText,
	subtitle,
	subtitleInvert,
	subtitleOnClick,
	subtitleTooltipText,
	subtitleTooltipPlacement,
	noSubIcon,
	className,
	customRules,
	isSearchable,
	loading,
	onChange,
	noSubtitleText,
	handleClick,
	hiddenRequiredLabel = false
}: InputTypes.DropdownInputProps) => {
	const styleOne = `custom-input ${noErrorVibration ? "" : "vibrate"}`;
	const requiredRule = [
		{
			required,
			message: `Please enter ${label}`,
		},
	];

	const inputRules: Rule[] = customRules?.length
		? [...requiredRule, ...customRules]
		: [
				{
					required,
					message: `Required`,
				},
		  ];
	return (
		<div className="custom-input-wrapper">
			<span className="custom-input-label">
				{label} {(required && !hiddenRequiredLabel) && <span className=" text-lg text-red-500">*</span>}
			</span>
			<div className="relative">
				<Form.Item name={name} rules={inputRules} help={false}>
					<Select
						className={styleOne}
						showSearch={isSearchable}
						onChange={onChange}
						optionFilterProp="children"
						showArrow={!!loading}
						filterOption={isSearchable}
						loading={!!loading}
						disabled={nonEditable || !!loading}
						placeholder={loading ? 'loading....': placeholder}
						onClick={handleClick}
						>
						{options?.map((option) => (
							<Option key={option.Id || option.title} value={option.value}>
								{option.title ? option.title : option.label}
							</Option>
						))}
					</Select>
				</Form.Item>
				{(!nonEditable && !loading) && (
					<AiFillCaretDown className="absolute top-[1rem] right-[0.75rem] text-[#79747E] pointer-events-none" />
				)}
			</div>

			<OptionalRender condition={noSubtitleText ? false : true}>
				{nonEditable && !subtitle && (
					<div className="text-[0.72rem] flex gap-2 items-center p-[0.2rem] mb-[-0.5rem]">
						<AiOutlineInfoCircle className="!mt-[0.08rem] text-[0.9rem] " />
						{nonEditableText ? nonEditableText : "you cannot edit this"}
					</div>
				)}
			</OptionalRender>

			{subtitle && (
				<div
					onClick={subtitleOnClick}
					className={`text-[0.72rem]    items-center justify-start  ${
						subtitleOnClick && "cursor-pointer"
					}  flex gap-2 items-center p-[0.2rem] mb-[-0.5rem] ${
						className && className
					}`}>
					{!noSubIcon && !subtitleInvert && (
						<Tooltip
							placement={subtitleTooltipPlacement || "top"}
							title={subtitleTooltipText}>
							<AiOutlineInfoCircle className="!mt-[0.08rem]  text-[0.9rem]" />
						</Tooltip>
					)}
					{subtitle}
					{!noSubIcon && subtitleInvert && (
						<Tooltip
							placement={subtitleTooltipPlacement || "top"}
							title={subtitleTooltipText}>
							<AiOutlineInfoCircle className="!mt-[0.08rem]  text-[0.9rem]" />
						</Tooltip>
					)}
				</div>
			)}
		</div>
	);
};
 
export default DropdownInput;
