import QwidButton from "components/misc/QwidButton";
import { useRouter } from "next/router";
import { DASHBOARD_ROUTES } from "utils/appData/appRoutes";

type Props = {
	requestVac: () => void;
};

const VirtualShortcuts = ({ requestVac }: Props) => {
	const router = useRouter();

	return (
		<div className="w-full md:w-4/6 lg:w-2/5 flex flex-col">
			<div className="w-full bg-[#58461C] flex items-center justify-around h-2/4 p-3 rounded-tl-lg rounded-tr-lg">
				<div className="">
					<img
						width={130}
						src="/assets/dashboard/exchange.svg"
						className=""
						alt="exchange"
					/>
				</div>

				<div className="flex flex-col">
					<p className="mb-2 twide twite">Be limitless!</p>
					<QwidButton
						onClick={() => router.push(DASHBOARD_ROUTES.VIRTUAL_CARDS_HOME)}
						whiteBg
						text={<span className="px-3 lg:t6">Get Virtual Cards</span>}
					/>
				</div>
			</div>

			<div
				style={{
					background:
						"linear-gradient(112.02deg, #E0E8EF 15.47%, rgba(255, 255, 255, 0) 84.89%)",
				}}
				className="h-2/4 flex items-center justify-center rounded-br-md rounded-bl-md">
				<div className="flex flex-col pl-8">
					<p className="mb-3 twide text-[#0d0d0d] lg:w-4/5">
						Send and receive money from anywhere in an instant.
					</p>
					<div className="w-max">
						<QwidButton
							onClick={requestVac}
							darkBlueBg
							text={<span className="px-2 lg:t6">Create Virtual Account</span>}
						/>
					</div>
				</div>

				<div className="pb-4 pr-6">
					<img
						className="min-w-[150px] md:w-[300px]"
						src="/assets/dashboard/dollar.svg"
						alt="dollar"
					/>
				</div>
			</div>
		</div>
	);
};

export default VirtualShortcuts;
